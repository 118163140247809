import { DatabaseFilled } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Popover, Row, Select, Table, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { LayoutDateSelect, LayoutNav, LayoutPagination } from 'components/layout.component';
import { MemberLevelSelect } from 'components/member.component';
import { PopupAllpassPromotionRebate } from 'components/promotion.component';
import { RESPONSE_CODE_REBATE } from 'constants/response';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TYPE } from 'enum/date';
import { SETTLE_CYCLE } from 'enum/promotion';
import { PROMOTION_REBATE_STATE, RESPONSE_TYPE } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { CommissionticketList, CommissionticketReject } from 'services/commissionticket.service';
import * as common from 'utils/common';

// 返水優惠查詢

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();
  const [form] = useForm();
  const [allPass, setAllPass] = useState(false);
  const [allReject, setAllReject] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [reset, setReset] = useState(false);
  const [date, setDate] = useState<string[]>();
  const [isModalOpenAllpass, setIsModalOpenAllpass] = useState(false);
  const [startDate, setStartDate] = useState('CreateStartDate');
  const [endDate, setEndDate] = useState('CreateEndDate');
  const [hideColumns, setHideColumns] = useState({
    order: true,
    memberAccount: true,
    recommendedMembers: true,
    rebatePeriod: true,
    amount: true,
    rolloverRate: true,
    createTime: true,
    distributionTime: true,
    claimTime: true,
    status: true,
  })

  const [params, setParams] = useState<any>({
    CreateStartDate: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    CreateEndDate: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  });
  const { data: rebateList, isValidating, mutate } = CommissionticketList({
    ...params,
    PageIndex: page[0],
    PageSize: page[1],
  })

  const onFinish = (formData: any) => {
    if (date) {
      setParams({
        Code: formData.order,
        Account: formData.memberAccount,
        MemberLevel: formData.LevelId,
        Status: formData.status,
        UpMemberAccount: formData.recommendedMembers,
        AGAccount: formData.agentAccount,
        SAAccount: formData.masterAgent,
        [startDate]: date[0],
        [endDate]: date[1],
      })
    }
    mutate();
  }

  const onClear = () => {
    setPage([1, 10]);
    setReset(!reset);
    form.resetFields();
    setStartDate('CreateStartDate');
    setEndDate('CreateEndDate');
  }

  const handleDateType = (type: number) => {
    switch (type) {
      case 0:
        setStartDate('CreateStartDate');
        setEndDate('CreateEndDate')
        break;
      case 1:
        setStartDate('AuditedStartDate');
        setEndDate('AuditedEndDate')
        break;
      case 2:
        setStartDate('ReceivedStartDate');
        setEndDate('ReceivedEndDate')
        break;
    }
  }

  const colorChange = (type: number) => {
    switch (type) {
      case PROMOTION_REBATE_STATE.已領取:
      case PROMOTION_REBATE_STATE.已派發:
        return 'color-pass'
      case PROMOTION_REBATE_STATE.待派發:
        return 'color-padding'
      case PROMOTION_REBATE_STATE.拒絕:
        return 'color-reject'
    }
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  // 批次拒絕
  const handleAllReject = async () => {
    setAllReject(true);

    const response = await CommissionticketReject({
      Id: selectedRowKeys
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      setSelectedRowKeys([]);
      mutate();
    } else {
      message.error(RESPONSE_CODE_REBATE[response.Message as keyof typeof RESPONSE_CODE_REBATE]);
    }

    setAllReject(false);
  }

  const popoverKeys = Object.keys(hideColumns);
  const popoverValues = Object.values(hideColumns);
  const onChangeHideColumns = (e: any) => {
    setHideColumns({
      ...hideColumns,
      [e.target.name]: e.target.checked
    })
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish} initialValues={{
          TimeMode: 0,
        }}>
          <Row align="middle" gutter={10}>
            <Col className="w-12">
              <Form.Item name="order">
                <Input placeholder={`${i18n.t('order')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="memberAccount">
                <Input placeholder={`${i18n.t('memberAccount')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="LevelId">
                <MemberLevelSelect name="LevelId" form={form} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="status">
                <Select
                  className="w-full"
                  placeholder={i18n.t('status')}
                  options={[
                    { value: '', label: `${i18n.t('all')}` },
                    ...common.enumToOptions(PROMOTION_REBATE_STATE)
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="recommendedMembers">
                <Input placeholder={`${i18n.t('recommendedMembers')}`} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} className="mt-1">
            <Col className="w-12">
              <Form.Item name="agentAccount">
                <Input placeholder={`${i18n.t('agentAccount')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="masterAgent">
                <Input placeholder={`${i18n.t('masterAgent')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="TimeMode">
                <Select
                  className="w-full"
                  onChange={handleDateType}
                  options={[
                    { value: 0, label: `${i18n.t('createTime')}` },
                    { value: 1, label: `${i18n.t('distributionTime')}` },
                    { value: 2, label: `${i18n.t('claimTime')}` },
                  ]}
                />
              </Form.Item>
            </Col>
            <LayoutDateSelect width={375} onChange={setDate} reset={reset}
              dateFormatDefault={DATE_FORMAT.日期時間} hiddenDateType={[DATE_TYPE.上週]} />
            <Col>
              <Row align="middle" gutter={[12, 12]}>
                <Col><Button type="primary" htmlType="submit">{i18n.t('search')}</Button></Col>
                <Col><Button onClick={onClear}>{i18n.t('clear')}</Button></Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mt-1">
            <Col span={24}>

              <Table
                loading={isValidating}
                size="small"
                dataSource={
                  rebateList ?
                    [{
                      key: 1,
                      totalCount: rebateList.TotalRecord,
                      totalRebate: rebateList.TotalCommission
                    }] : []
                }
                columns={[
                  {
                    title: i18n.t('totalCount'),
                    dataIndex: 'totalCount',
                    align: "right",
                    width: '50%'
                  },
                  {
                    title: i18n.t('totalRebate'),
                    dataIndex: 'totalRebate',
                    align: "right",
                    width: '50%',
                    render: (_, { totalRebate }) => common.toFormatNumber(totalRebate)
                  },
                ]}
                pagination={false}
              />

            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mt-1" justify="space-between">
            <Col>
              {
                $p.includes('30402') &&
                <Row align="middle" gutter={[12, 12]}>
                  <Col>
                    <Button
                      type="primary" ghost danger
                      disabled={selectedRowKeys.length === 0}
                      loading={allReject}
                      onClick={handleAllReject}
                    >{i18n.t('batchReject')}</Button>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      loading={allPass}
                      onClick={() => setIsModalOpenAllpass(true)}
                    >{i18n.t('distributeAll')}
                    </Button>
                  </Col>
                </Row>
              }
            </Col>
            <Col>
              <Popover content={
                popoverKeys.map((item, i) => (
                  <div key={i}>
                    <Checkbox name={item} defaultChecked={popoverValues[i]} onChange={onChangeHideColumns}>
                      {i18n.t(item)}
                    </Checkbox>
                  </div>
                ))
              } placement="bottomRight" trigger="click">
                <Button><DatabaseFilled /></Button>
              </Popover>
            </Col>
          </Row>

          <Table
            loading={isValidating}
            size="middle"
            className="mt-1"
            scroll={{ x: 1500 }}
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange,
              getCheckboxProps: (record) => ({
                disabled:
                  record.status === PROMOTION_REBATE_STATE.已派發 ||
                  record.status === PROMOTION_REBATE_STATE.已領取 ||
                  record.status === PROMOTION_REBATE_STATE.拒絕
              })
            }}
            dataSource={
              rebateList?.Data?.map((item: any) => (
                {
                  key: item.Id,
                  orderNo: item.Code,
                  memberNo: item.MemberAccount,
                  UpMemberAccount: item.UpMemberAccount,
                  AGAccount: item.AGAccount,
                  SAAccount: item.SAAccount,
                  period: item.SettleCycle,
                  amount: item.Amount,
                  multiple: item.ValidBetMultiple,
                  createTime: item.CreateDate,
                  distributionTime: item.AuditedTime,
                  takeTime: item.ReceivedTime,
                  status: item.Status,
                  OperatorAccount: item.OperatorAccount
                }
              ))
            }
            columns={[
              {
                dataIndex: 'orderNo',
                title: i18n.t('order'),
                className: hideColumns.order ? '' : 'hide',
                width: 250,
                fixed: 'left',
              },
              {
                dataIndex: 'memberNo',
                title: i18n.t('memberAccount'),
                className: hideColumns.memberAccount ? '' : 'hide',
                width: 200,
              },
              {
                dataIndex: 'memberNo',
                title: `${i18n.t('recommendedMembers')}／${i18n.t('agent')}／${i18n.t('generalAgent')}`,
                className: hideColumns.recommendedMembers ? '' : 'hide',
                width: 250,
                render: (_, record) => (
                  <>
                    <span>{record.UpMemberAccount || '-'}</span><span> / </span>
                    <span>{record.AGAccount || '-'}</span><span> / </span>
                    <span>{record.SAAccount || '-'}</span>
                  </>
                )
              },
              {
                dataIndex: 'period',
                title: i18n.t('rebatePeriod'),
                className: hideColumns.rebatePeriod ? '' : 'hide',
                width: 80,
                render: (_, { period }) => SETTLE_CYCLE[period]
              },
              {
                dataIndex: 'amount',
                title: i18n.t('amount'),
                className: hideColumns.amount ? '' : 'hide',
                align: 'right',
                width: 200,
                // sorter: (a, b) => a.amount - b.amount,
                render: (_, { amount }) => common.toFormatNumber(amount)
              },
              {
                dataIndex: 'multiple',
                title: i18n.t('rolloverRate'),
                className: hideColumns.rolloverRate ? '' : 'hide',
                align: 'right',
                width: 100,
              },
              {
                dataIndex: 'createTime',
                title: i18n.t('createTime'),
                className: hideColumns.createTime ? '' : 'hide',
                width: 150,
              },
              {
                dataIndex: 'distributionTime',
                title: i18n.t('distributionTime'),
                className: hideColumns.distributionTime ? '' : 'hide',
                width: 150,
                render: (_, { distributionTime }) => distributionTime === '0000-00-00 00:00:00' ? '-' : distributionTime
              },
              {
                dataIndex: 'takeTime',
                title: i18n.t('claimTime'),
                className: hideColumns.claimTime ? '' : 'hide',
                width: 150,
                render: (_, { takeTime }) => takeTime === '0000-00-00 00:00:00' ? '-' : takeTime
              },
              {
                dataIndex: 'status',
                title: i18n.t('status'),
                className: hideColumns.status ? '' : 'hide',
                width: 200,
                render: (_, { status, OperatorAccount }) => (
                  <>
                    <div className={colorChange(status)}>
                      {PROMOTION_REBATE_STATE[status]}
                    </div>
                    <div>
                      {i18n.t('from')} {
                        OperatorAccount ? OperatorAccount : i18n.t('system')
                      } {i18n.t('operation')}
                    </div>
                  </>
                )
              },
            ]}
            pagination={false}
          />
          <LayoutPagination total={rebateList && rebateList.TotalRecord} setPage={setPage} page={page} />

        </Form>
      </Content>

      <PopupAllpassPromotionRebate isOpen={isModalOpenAllpass} close={() => setIsModalOpenAllpass(false)} mutate={mutate} setAllPass={setAllPass} setSelectedRowKeys={setSelectedRowKeys} />
      {/* <PopupAddPromotionRebate isOpen={isModalOpenAdd} close={() => setIsModalOpenAdd(false)} />
      <PopupAuditPromotionRebate isOpen={isModalOpenConfirm} close={() => setIsModalOpenConfirm(false)} />
      <PopupDetailPromotionRebate isOpen={isModalOpenDetail} close={() => setIsModalOpenDetail(false)} /> */}
    </div>
  );
};

export default PageMain;