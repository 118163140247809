import { AGENT_LOG } from "enum/cashlog";
import { SETTLE_CYCLE } from "enum/promotion";
import { ADJUST_TRANSFER_TYPE, AGENT_ADJUST_STATE, AGENT_STATE, DEPOSIT_STATE, MEMBER_FREEZE, MEMBER_STATE, WITHDRAW_STATE } from "enum/state";
import i18n from "i18n";
import * as common from 'utils/common';
import { toFormatNumber as $f } from 'utils/common';

export const ExcelColumns = {
  // 會員列表
  MemberList: [
    {
      title: i18n.t('memberAccount'),
      dataIndex: "Account",
      excelRender: (text: string, record: any, index: number) => text
    },
    {
      title: i18n.t('referralCode'),
      dataIndex: "RefCode",
    },
    {
      title: i18n.t('recommendedMembers'),
      dataIndex: "UPMemberAccount",
    },
    {
      title: i18n.t('agent'),
      dataIndex: "AGAccount",
    },
    {
      title: i18n.t('status'),
      dataIndex: 'IsFreeze',
      excelRender: (text: number) => i18n.t(MEMBER_FREEZE[text ? MEMBER_FREEZE.frozen : MEMBER_FREEZE.normal]),
    },
    {
      title: i18n.t('audit'),
      dataIndex: 'Status',
      excelRender: (text: number) => i18n.t(MEMBER_STATE[text]),
    },
    {
      title: i18n.t('memberLevel'),
      dataIndex: 'MemberLevelName',
    },
    {
      title: i18n.t('createTime'),
      dataIndex: "CreateDate",
    },
    {
      title: i18n.t('IP'),
      dataIndex: "RegisterIP",
    },
    {
      title: i18n.t('lastLoginTime'),
      dataIndex: "LastLoginDate",
    },
    {
      title: i18n.t('IP'),
      dataIndex: "IP",
    },
    {
      title: i18n.t('lastDepositTime'),
      dataIndex: "LastDepositTime",
    },
    {
      title: i18n.t('remark'),
      dataIndex: "RemarkFromAccount",
    },
  ],
  // 總代列表
  AgentList: [
    {
      title: i18n.t('account'),
      dataIndex: 'Account',
    },
    {
      title: i18n.t('agentTag'),
      dataIndex: 'TagsId',
    },
    {
      title: i18n.t('referralCode'),
      dataIndex: 'RefCode',
    },
    {
      title: i18n.t('teamAgentCount'),
      dataIndex: 'DownlineAgentCount',
    },
    {
      title: i18n.t('teamMemberCount'),
      dataIndex: 'DownlineMemberCount',
    },
    {
      title: i18n.t('walletBalance'),
      dataIndex: 'Balance',
      excelRender: (Balance: number) => $f(Balance)
    },
    {
      title: i18n.t('status'),
      dataIndex: 'Status',
      excelRender: (Status: number) => AGENT_STATE[Status]
    },
    {
      title: i18n.t('lastLoginTime'),
      dataIndex: 'LastLoginDate',
    },
    {
      title: i18n.t('createTime'),
      dataIndex: 'CreateDate',
    },
    {
      title: i18n.t('IP'),
      dataIndex: 'IP',
    },
    {
      title: i18n.t('rebate'),
      dataIndex: 'CommissionSettleCycle',
      render: (CommissionSettleCycle: number) => SETTLE_CYCLE[CommissionSettleCycle]
    },
    {
      title: i18n.t('commissionRate'),
      dataIndex: 'RevenueShareSettleCycle',
      render: (RevenueShareSettleCycle: number) => SETTLE_CYCLE[RevenueShareSettleCycle]
    }
  ],
  // 財務-調帳查詢
  AdjustList: [
    {
      title: i18n.t('order'),
      dataIndex: 'Code',
      __style__: {
        width: 30,
      },
    },
    {
      title: i18n.t('memberAccount'),
      dataIndex: 'Account',
    },
    {
      title: i18n.t('memberLevel'),
      dataIndex: 'MemberLevelName',
    },
    {
      title: i18n.t('transactionType'),
      dataIndex: 'Type',
      excelRender: (type: number) => (
        ADJUST_TRANSFER_TYPE[type]
      )
    },
    {
      title: i18n.t('previousBalance'),
      dataIndex: 'BeforeAmount',
      excelRender: (BeforeAmount: number) => common.toFormatNumber(BeforeAmount)
    },
    {
      title: i18n.t('amount'),
      dataIndex: 'Amount',
      excelRender: (Amount: number) => common.toFormatNumber(Amount)
    },
    {
      title: i18n.t('rolloverRate'),
      dataIndex: 'ValidBetMultiple',
    },
    {
      title: i18n.t('expectedValidBet'),
      dataIndex: 'expectedValidBet',
      excelRender: (_: any, record: any) => $f(parseFloat(record.Amount as any) * parseFloat(record.ValidBetMultiple as any))
    },
    {
      title: i18n.t('BalanceAfterModification'),
      dataIndex: 'AfterAmount',
      excelRender: (AfterAmount: number) => common.toFormatNumber(AfterAmount)
    },
    {
      title: i18n.t('createTime'),
      dataIndex: 'CreateDate',
    },
    {
      title: i18n.t('auditTime'),
      dataIndex: 'AuditedDate',
    },
    {
      title: i18n.t('status'),
      dataIndex: 'Status',
      excelRender: (Status: number) => AGENT_ADJUST_STATE[Status]
    },
  ],
  // 統計報表-輸贏報表
  GGRList: [
    {
      title: i18n.t('date'),
      dataIndex: 'ReportDate'
    },
    {
      title: i18n.t('betCounts'),
      dataIndex: 'TotalBetCount',
    },
    {
      title: i18n.t('betAmount'),
      dataIndex: 'TotalBetAmount',
      excelRender: (TotalBetAmount: number) => common.toFormatNumber(TotalBetAmount)
    },
    {
      title: i18n.t('payout'),
      dataIndex: 'TotalValidBetAmount',
      excelRender: (TotalValidBetAmount: number) => common.toFormatNumber(TotalValidBetAmount)
    },
    {
      title: i18n.t('totalWinLossAmounts'),
      dataIndex: 'TotalWinLossAmount',
      excelRender: (TotalWinLossAmount: number) => common.toFormatNumber(TotalWinLossAmount)
    },
  ],
  // 統計報表-輸贏報表-總覽
  GGRListOverview: [
    {
      title: i18n.t('totalBetCount'),
      dataIndex: 'TotalBetAmount',
    },
    {
      title: i18n.t('totalBetAmounts'),
      dataIndex: 'TotalBetCount',
      excelRender: (TotalBetCount: number) => common.toFormatNumber(TotalBetCount)
    },
    {
      title: i18n.t('totalWinLossAmount'),
      dataIndex: 'TotalWinLossAmount',
      excelRender: (TotalBetCount: number) => common.toFormatNumber(TotalBetCount)
    },
  ],
  // 代理錢包紀錄
  AgentWalletRecord: [
    {
      title: i18n.t('order'),
      dataIndex: 'Code',
      __style__: {
        width: 50,
      },
    },
    {
      title: i18n.t('agentAccount'),
      dataIndex: 'AgentAccount',
    },
    {
      title: i18n.t('transactionType'),
      dataIndex: 'KeyId',
      excelRender: (val: any, { Amount }: any) => (
        val === String(AGENT_LOG['代理轉帳-轉入']) && Amount < 0 ? AGENT_LOG['10312'] : AGENT_LOG[val as any]
      )
    },
    {
      title: i18n.t('amountBeforeTransaction'),
      dataIndex: 'BeforeAmount',
      excelRender: (BeforeAmount: number) => common.toFormatNumber(BeforeAmount)
    },
    {
      title: i18n.t('transactionAmount'),
      dataIndex: 'Amount',
      excelRender: (Amount: number) => common.toFormatNumber(Amount)
    },
    {
      title: i18n.t('amountAfterTransaction'),
      dataIndex: 'AfterAmount',
      excelRender: (AfterAmount: number) => common.toFormatNumber(AfterAmount)
    },
    {
      title: i18n.t('createTime'),
      dataIndex: 'CreateDate',
    },
    {
      title: i18n.t('updateTime'),
      dataIndex: 'UpdateDate',
    },
    {
      title: i18n.t('remark'),
      dataIndex: 'Remark',
    },
  ],
  // 財務提款查詢
  MemberWithdraw: [
    {
      title: i18n.t('order'),
      dataIndex: 'Code',
      __style__: {
        width: 50,
      },
    },
    {
      title: i18n.t('memberAccount'),
      dataIndex: 'MemberAccount',
    },
    {
      title: i18n.t('recommendedMembers'),
      dataIndex: 'UpMemberAccount',
    },
    {
      title: i18n.t('agent'),
      dataIndex: 'AGAccount',
    },
    {
      title: i18n.t('previousBalance'),
      dataIndex: 'BeforeAmount',
      excelRender: (BeforeAmount: number) => common.toFormatNumber(BeforeAmount)
    },
    {
      title: i18n.t('withdrawalAmount'),
      dataIndex: 'Amount',
      excelRender: (Amount: number) => common.toFormatNumber(Amount)
    },
    {
      title: i18n.t('actualWithdrawalAmount'),
      dataIndex: 'Amount',
      excelRender: (Amount: number) => common.toFormatNumber(Amount)
    },
    {
      title: i18n.t('BalanceAfterModification'),
      dataIndex: 'AfterAmount',
      excelRender: (AfterAmount: number) => common.toFormatNumber(AfterAmount)
    },
    {
      title: i18n.t('paymentProcessingFee'),
      dataIndex: 'HandlingFee',
      excelRender: (HandlingFee: number) => common.toFormatNumber(HandlingFee)
    },
    {
      title: i18n.t('exchangeRate'),
      dataIndex: 'ExchangeRate',
      excelRender: (ExchangeRate: number) => common.toFormatNumber(ExchangeRate, 2)
    },
    {
      title: i18n.t('createTime'),
      dataIndex: 'CreateDate',
    },
    {
      title: i18n.t('auditTime'),
      dataIndex: 'AuditedDate',
    },
    {
      title: i18n.t('status'),
      dataIndex: 'Status',
      excelRender: (Status: number) => WITHDRAW_STATE[Status]
    },
    {
      title: i18n.t('customerServiceReview'),
      dataIndex: 'ServiceAccount',
    },
    {
      title: i18n.t('customerServiceSupervisorReview'),
      dataIndex: 'AuditorAccount',
    },
    {
      title: i18n.t('financialAudit'),
      dataIndex: 'FinanceAccount',
    },
  ],
  // 財務入款查詢
  MemberDeposit: [
    {
      title: i18n.t('order'),
      dataIndex: 'Code',
      __style__: {
        width: 50,
      },
    },
    {
      title: i18n.t('memberAccount'),
      dataIndex: 'MemberAccount',
    },
    {
      title: i18n.t('recommendedMembers'),
      dataIndex: 'UpMemberAccount',
    },
    {
      title: i18n.t('agent'),
      dataIndex: 'AGAccount',
    },
    {
      title: i18n.t('depositMethod'),
      dataIndex: 'DepositMerchantName',
    },
    {
      title: i18n.t('previousBalance'),
      dataIndex: 'BeforeAmount',
      excelRender: (BeforeAmount: number) => common.toFormatNumber(BeforeAmount)
    },
    {
      title: i18n.t('depositAmount'),
      dataIndex: 'Amount',
      excelRender: (Amount: number) => common.toFormatNumber(Amount)
    },
    {
      title: i18n.t('paymentProcessingFee'),
      dataIndex: 'HandlingFee',
      excelRender: (HandlingFee: number) => common.toFormatNumber(HandlingFee)
    },
    {
      title: i18n.t('exchangeRate'),
      dataIndex: 'FeeRate',
      excelRender: (FeeRate: number) => common.toFormatNumber(FeeRate)
    },
    {
      title: i18n.t('BalanceAfterModification'),
      dataIndex: 'AfterAmount',
      excelRender: (AfterAmount: number) => common.toFormatNumber(AfterAmount)
    },
    {
      title: i18n.t('firstDeposit'),
      dataIndex: 'IsFirstDeposit',
      excelRender: (IsFirstDeposit: number) => IsFirstDeposit ? i18n.t('yes') : i18n.t('no')
    },
    {
      title: i18n.t('createTime'),
      dataIndex: 'CreateDate',
    },
    {
      title: i18n.t('auditTime'),
      dataIndex: 'AuditedDate',
    },
    {
      title: i18n.t('status'),
      dataIndex: 'Status',
      excelRender: (Status: number) => DEPOSIT_STATE[Status]
    },
  ],
  // 總代-代理提款查詢
  AgentWithdraw: [
    {
      title: i18n.t('order'),
      dataIndex: 'Code',
      __style__: {
        width: 50,
      },
    },
    {
      title: i18n.t('account'),
      dataIndex: 'AgentAccount',
    },
    {
      title: i18n.t('generalAgent'),
      dataIndex: 'SAAccount',
    },
    {
      title: i18n.t('previousBalance'),
      dataIndex: 'BeforeAmount',
      excelRender: (BeforeAmount: number) => common.toFormatNumber(BeforeAmount)
    },
    {
      title: i18n.t('withdrawalAmount'),
      dataIndex: 'Amount',
      excelRender: (Amount: number) => common.toFormatNumber(Amount)
    },
    {
      title: i18n.t('actualWithdrawalAmount'),
      dataIndex: 'RealAmount',
      excelRender: (RealAmount: number) => common.toFormatNumber(RealAmount)
    },
    {
      title: i18n.t('BalanceAfterModification'),
      dataIndex: 'AfterAmount',
      excelRender: (AfterAmount: number) => common.toFormatNumber(AfterAmount)
    },
    {
      title: i18n.t('paymentProcessingFee'),
      dataIndex: 'HeadlingFee',
      excelRender: (HeadlingFee: number) => common.toFormatNumber(HeadlingFee)
    },
    {
      title: i18n.t('exchangeRate'),
      dataIndex: 'ExchangeRate',
    },
    {
      title: i18n.t('createTime'),
      dataIndex: 'CreateDate',
    },
    {
      title: i18n.t('auditTime'),
      dataIndex: 'AuditedDate',
    },
    {
      title: i18n.t('status'),
      dataIndex: 'Status',
      excelRender: (Status: number) => WITHDRAW_STATE[Status]
    },
    {
      title: i18n.t('customerService'),
      dataIndex: 'ServiceAccount',
    },
    {
      title: i18n.t('customerServiceSupervisor'),
      dataIndex: 'AuditorAccount',
    },
    {
      title: i18n.t('financial'),
      dataIndex: 'FinanceAccount',
    },
  ],
  // 總代-代理入款查詢
  AgentDeposit: [
    {
      title: i18n.t('order'),
      dataIndex: 'Code',
      __style__: {
        width: 50,
      },
    },
    {
      title: i18n.t('account'),
      dataIndex: 'AgentAccount',
    },
    {
      title: i18n.t('generalAgent'),
      dataIndex: 'SAAccount',
    },
    {
      title: i18n.t('depositMethod'),
      dataIndex: 'DepositMerchantName',
    },
    {
      title: i18n.t('previousBalance'),
      dataIndex: 'BeforeAmount',
      excelRender: (BeforeAmount: number) => common.toFormatNumber(BeforeAmount)
    },
    {
      title: i18n.t('depositAmount'),
      dataIndex: 'Amount',
      excelRender: (Amount: number) => common.toFormatNumber(Amount)
    },
    {
      title: i18n.t('paymentProcessingFee'),
      dataIndex: 'HandlingFee',
      excelRender: (HandlingFee: number) => common.toFormatNumber(HandlingFee)
    },
    {
      title: i18n.t('exchangeRate'),
      dataIndex: 'FeeRate',
      excelRender: (FeeRate: number) => common.toFormatNumber(FeeRate)
    },
    {
      title: i18n.t('BalanceAfterModification'),
      dataIndex: 'AfterAmount',
      excelRender: (AfterAmount: number) => common.toFormatNumber(AfterAmount)
    },
    {
      title: i18n.t('auditTime'),
      dataIndex: 'AuditedDate',
    },
    {
      title: i18n.t('status'),
      dataIndex: 'Status',
      excelRender: (Status: number) => DEPOSIT_STATE[Status]
    },
    {
      title: i18n.t('financial'),
      dataIndex: 'FinanceAccount',
    },
  ],
  // 統計報表-會員排名
  ReportMemberRank: [
    {
      title: i18n.t('ranking'),
      dataIndex: 'key',
      excelRender: (text: string, record: any, index: number) => index + 1
    },
    {
      title: i18n.t('memberAccount'),
      dataIndex: 'MemberAccount',
    },
    {
      title: i18n.t('recommendedMembers'),
      dataIndex: 'UpMemberAccount',
    },
    {
      title: i18n.t('agent'),
      dataIndex: 'AGAccount',
    },
    {
      title: i18n.t('generalAgent'),
      dataIndex: 'SAAccount',
    },
    {
      title: i18n.t('name'),
      dataIndex: 'MemberName',
    },
    {
      title: i18n.t('recharge'),
      dataIndex: 'DepositAmount',
      excelRender: (DepositAmount: number) => common.toFormatNumber(DepositAmount)
    },
    {
      title: i18n.t('withdraw'),
      dataIndex: 'WithdrawAmount',
      excelRender: (WithdrawAmount: number) => common.toFormatNumber(WithdrawAmount)
    },
    {
      title: i18n.t('validBet'),
      dataIndex: 'ValidBetAmount',
      excelRender: (ValidBetAmount: number) => common.toFormatNumber(ValidBetAmount)
    },
    {
      title: i18n.t('memberWinLoss'),
      dataIndex: 'WinLossAmount',
      excelRender: (WinLossAmount: number) => common.toFormatNumber(WinLossAmount)
    },
  ],
  // 統計報表-遊戲統計分析
  ReportGame: [
    {
      title: i18n.t('gameProvider'),
      dataIndex: 'ProviderCode',
    },
    {
      title: i18n.t('bettingMembers'),
      dataIndex: 'TotalBetMemberCount',
    },
    {
      title: i18n.t('totalBets'),
      dataIndex: 'TotalBetCount',
    },
    {
      title: i18n.t('validBet'),
      dataIndex: 'TotalValidBetAmount',
      excelRender: (TotalValidBetAmount: number) => common.toFormatNumber(TotalValidBetAmount)
    },
    {
      title: i18n.t('totalBetAmount'),
      dataIndex: 'TotalBetAmount',
      excelRender: (TotalBetAmount: number) => common.toFormatNumber(TotalBetAmount)
    },
    {
      title: i18n.t('payoutTotal'),
      dataIndex: 'TotalSettleAmount',
      excelRender: (TotalSettleAmount: number) => common.toFormatNumber(TotalSettleAmount)
    },
    {
      title: i18n.t('companyWinLoss'),
      dataIndex: 'TotalWinLossAmount',
      excelRender: (TotalWinLossAmount: number) => common.toFormatNumber(TotalWinLossAmount)
    },
  ],
  // 營運報表
  ReportBusiness: [
    {
      title: i18n.t('date'),
      dataIndex: 'ReportDate',
    },
    {
      title: i18n.t('newUsers'),
      dataIndex: 'TotalNewMemberCount',
      excelRender: (val: any) => common.toFormatNumber(val, 0)
    },
    {
      title: i18n.t('activeMembers'),
      dataIndex: 'TotalActivityMemberCount',
      excelRender: (val: any) => common.toFormatNumber(val, 0)
    },
    {
      title: i18n.t('totalMemberCount'),
      dataIndex: 'TotalDepositRecordCount',
      excelRender: (val: any) => common.toFormatNumber(val, 0)
    },
    {
      title: i18n.t('totalWithdrawalCount'),
      dataIndex: 'TotalWithdrawMemberCount',
      excelRender: (val: any) => common.toFormatNumber(val, 0)
    },
    {
      title: i18n.t('firstDepositMembers'),
      dataIndex: 'TotalFirstTimeDepositMemberCount',
      excelRender: (val: any) => common.toFormatNumber(val, 0)
    },
    {
      title: i18n.t('redepositMembers'),
      dataIndex: 'TotalSecondTimeDepositMemberCount',
      excelRender: (val: any) => common.toFormatNumber(val, 0)
    },
    {
      title: i18n.t('firstDepositAmount'),
      dataIndex: 'TotalFirstTimeDepositAmount',
      excelRender: (val: any) => common.toFormatNumber(val)
    },
    {
      title: i18n.t('totalDepositAmount'),
      dataIndex: 'TotalDepositAmount',
      excelRender: (val: any) => common.toFormatNumber(val)
    },
    {
      title: i18n.t('totalAmountWithdrawal'),
      dataIndex: 'TotalWithdrawAmount',
      excelRender: (val: any) => common.toFormatNumber(val)
    },
    {
      title: i18n.t('bettingMembers'),
      dataIndex: 'TotalActivityMemberCount',
      excelRender: (val: any) => common.toFormatNumber(val, 0)
    },
    {
      title: i18n.t('totalBets'),
      dataIndex: 'TotalBetCount',
      excelRender: (val: any) => common.toFormatNumber(val, 0)
    },
    {
      title: i18n.t('validBet'),
      dataIndex: 'TotalValidBetAmount',
      excelRender: (val: any) => common.toFormatNumber(val)
    },
    {
      title: i18n.t('payout'),
      dataIndex: 'TotalSettleAmount',
      excelRender: (val: any) => common.toFormatNumber(val)
    },
    {
      title: i18n.t('gameProfit'),
      dataIndex: 'TotalWinLossAmount',
      excelRender: (val: any) => common.toFormatNumber(val)
    },
    {
      title: i18n.t('monthlyTotalWinLoss'),
      dataIndex: 'TotalMonthWinLossAmount',
      excelRender: (val: any) => common.toFormatNumber(val)
    },
    {
      title: i18n.t('bonusAmount'),
      dataIndex: 'TotalBonusAmount',
      excelRender: (val: any) => common.toFormatNumber(val)
    },
    {
      title: i18n.t('adjustmentAmount'),
      dataIndex: 'TotalAdjustPlusAmount',
      excelRender: (val: any, { TotalAdjustPlusAmount, TotalAdjustMinusAmount }: any) =>
        `${common.toFormatNumber(TotalAdjustPlusAmount)}／${common.toFormatNumber(TotalAdjustMinusAmount)}`
    },
  ]
}