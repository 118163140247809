import { Button, Col, Form, Input, Row, Select, Table, Tabs, TabsProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { LayoutDateSelect, LayoutNav } from 'components/layout.component';
import { ReportPromotionList } from 'components/report.component';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'enum/date';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { GetReportBonusstatAgList, GetReportBonusstatCategoryList, GetReportBonusstatMemberList } from 'services/report.service';
import { toFormatNumber as $f, agentLevelWord, enumToOptions } from 'utils/common';

// 代理優惠統計
// NOTE: 同步AG成本報表

enum TAB {
  代理 = '1',
  會員 = '2',
  優惠 = '3'
}

enum BONUS_TYPE {
  dailyCheckIn = 1,
  // totalSignIn = 2,
  referralReward = 3,
  promotionAdjustment = 4,
  promotionBonus = 5,
  weeklyBonus = 6,
  monthlyBonus = 7,
  otherPromotions = 8,
  rebate = 9,
}

const PageMain: React.FC = () => {
  const [activeKey, setActiveKey] = useState<string>(TAB.代理);
  const [form] = useForm();

  const items: TabsProps['items'] = [
    {
      key: TAB.代理,
      label: <div className="w-5 text-center">{i18n.t('agent')}</div>
    },
    {
      key: TAB.會員,
      label: <div className="w-5 text-center">{i18n.t('member')}</div>
    },
    {
      key: TAB.優惠,
      label: <div className="w-5 text-center">{i18n.t('promotion')}</div>
    },
  ];

  const onTabClick = (key: string) => {
    setParams({
      ...params,
      PageIndex: 1
    });
    setActiveKey(key)
    updateTotal(key);
  }

  const updateTotal = (key: any) => {
    switch (key) {
      case TAB.代理:
        setPromotionData({
          key: 1,
          count: _saList?.TotalCount,
          amount: _saList?.TotalAmount
        })
        break;
      case TAB.會員:
        setPromotionData({
          key: 1,
          count: _memberList?.TotalCount,
          amount: _memberList?.TotalAmount,
        })
        break;
      case TAB.優惠:
        setPromotionData({
          key: 1,
          count: _categoryList?.TotalCount,
          amount: _categoryList?.TotalAmount,
        })
        break;
    }
  }

  const searchAgent = (agent: any, tab: any) => {
    setActiveKey(tab);
    setParams({
      ...params,
      PageIndex: 1,
      AgentAccount: agent.Account,
      MemberAccount: null,
      BonusId: null,
    })
    form.setFieldsValue({
      AgentAccount: agent.Account,
      MemberAccount: null,
      BonusId: null,
    });
  };

  const searchMember = (member: any, tab: any) => {
    setActiveKey(tab);
    setParams({
      ...params,
      PageIndex: 1,
      AgentAccount: null,
      MemberAccount: member.Account,
      BonusId: null,
    })
    form.setFieldsValue({
      AgentAccount: null,
      MemberAccount: member.Account,
      BonusId: null,
    });
  };

  const [promotionData, setPromotionData] = useState({
    key: 1,
    amount: 0,
    count: 0,
  });

  const [date, setDate] = useState<string[]>();
  const [params, setParams] = useState<any>({
    StartDate: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    EndDate: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    PageIndex: 1,
    PageSize: 10,
  });
  const { data: _saList, mutate: mutateSa, isValidating: saValidating } = GetReportBonusstatAgList(params);
  const { data: _memberList, mutate: mutateMember, isValidating: memberValidating } = GetReportBonusstatMemberList(params);
  const { data: _categoryList, mutate: mutateCategory, isValidating: categoryValidating } = GetReportBonusstatCategoryList(params);
  useEffect(() => {
    updateTotal(activeKey)
  }, [_saList, _memberList, _categoryList]);

  const [reset, setReset] = useState<boolean>(false);
  const search = (formData: any) => {
    setParams({
      StartDate: date ? date[0] : dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      EndDate: date ? date[1] : dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      PageIndex: 1,
      PageSize: params.PageSize,
      AgentAccount: formData.AgentAccount,
      MemberAccount: formData.MemberAccount,
      BonusId: formData.BonusId,
    })
    mutateSa()
    mutateMember()
    mutateCategory()
  }

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setParams({
      ...params,
      PageSize: 10,
      PageIndex: 1,
    })
  }

  const columns: any = [
    {
      key: 1,
      dataIndex: 'Account',
      title: i18n.t('agentAccount'),
      className: 'size-12',
      width: 300,
      render: (val: any, { Account, Level }: any) => {
        const word = agentLevelWord(Level);
        return `${word} / ${Account}`
      }
    },
    {
      key: 2,
      dataIndex: 'up_count',
      title: i18n.t('teamPromotionCount'),
      className: 'size-12',
      align: 'right',
      width: 300,
      // sorter: (a, b) => a.teamPromotion - b.teamPromotion,
      render: (val: any) => $f(val, 0)
    },
    {
      key: 3,
      dataIndex: 'up_amount',
      title: i18n.t('teamPromotionAmount'),
      className: 'size-12',
      align: 'right',
      width: 300,
      // sorter: (a, b) => a.teamPromotionAmount - b.teamPromotionAmount,
      render: (val: any) => $f(val)
    },
    {
      key: 4,
      dataIndex: 'bonus_count',
      title: i18n.t('promotionCount'),
      className: 'size-12',
      align: 'right',
      width: 300,
      // sorter: (a, b) => a.promotionTotal - b.promotionTotal,
      render: (val: any) => $f(val, 0)
    },
    {
      key: 5,
      dataIndex: 'bonus_amount',
      title: i18n.t('promotionAmount'),
      className: 'size-12',
      align: 'right',
      width: 300,
      // sorter: (a, b) => a.promotionAmount - b.promotionAmount,
      render: (val: any) => $f(val)
    },
    {
      key: 6,
      title: i18n.t('operation'),
      className: 'size-12',
      width: 200,
      render: (val: any, record: any) => (
        <>
          <Button className="size-12" type="link" onClick={() => searchAgent(record, TAB.會員)}>
            {i18n.t('viewMember')}
          </Button>
          <Button className="size-12" type="link" onClick={() => searchAgent(record, TAB.優惠)}>
            {i18n.t('viewPromotion')}
          </Button>
        </>
      )
    },
  ]

  return (
    <div id="container">
      <LayoutNav />
      <Content className="p-2">
        {/* 搜尋列 */}
        <Form form={form} onFinish={search}>
          <Row align="middle" gutter={[12, 12]}>
            <Col className='w-12'>
              <Form.Item name="BonusId">
                <Select
                  placeholder={i18n.t("promotionCategory")}
                  options={[
                    { value: '', label: i18n.t('all') },
                    ...enumToOptions(BONUS_TYPE)
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className='w-11'>
              <Form.Item name="AgentAccount">
                <Input placeholder={`${i18n.t('agentAccount')}`} />
              </Form.Item>
            </Col>
            <Col className='w-11'>
              <Form.Item name="MemberAccount">
                <Input placeholder={`${i18n.t('memberAccount')}`} />
              </Form.Item>
            </Col>
            <LayoutDateSelect dateFormatDefault={DATE_FORMAT.日期時間} onChange={setDate} reset={reset} />
            <Col>
              <Button type="primary" htmlType="submit" loading={saValidating}>{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
        </Form>
        {/* 數據總覽 */}
        <Row className="mt-1" gutter={[12, 12]}>
          <Col span={24}>
            <Table
              size="small"
              dataSource={[promotionData]}
              columns={[
                {
                  key: 1,
                  dataIndex: 'count',
                  title: i18n.t('totalPromotionCount'),
                  className: 'size-12',
                  align: 'right',
                  render: (val) => $f(val, 0)
                },
                {
                  key: 2,
                  dataIndex: 'amount',
                  title: i18n.t('totalPromotionAmount'),
                  className: 'size-12',
                  align: 'right',
                  render: (val) => $f(val)
                },
              ]}
              pagination={false}
            />
          </Col>
        </Row>
        <Tabs className="mt-1" activeKey={activeKey} items={items} onTabClick={onTabClick} />
        {/* 代理 */}
        {
          activeKey === TAB.代理 &&
          <Row className="mt-1" gutter={[10, 10]}>
            <Col span={24}>

              <ReportPromotionList 
                agentSaData={_saList} isValidating={saValidating} 
                columns={columns} params={params} setParams={setParams} />

              {false && <Table
                size="middle"
                loading={saValidating}
                dataSource={_saList?.Data?.map((item: any) => ({ key: item.id, ...item }))}
                columns={columns}
                pagination={{
                  showSizeChanger: true,
                  showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
                  total: _saList?.TotalRecord,
                  current: params.PageIndex,
                  pageSize: params.PageSize
                }}
                onChange={p => setParams({ ...params, PageIndex: p.current, PageSize: p.pageSize })}
              />}
            </Col>
          </Row>
        }
        {/* 會員 */}
        {
          activeKey === TAB.會員 &&
          <Row className="mt-1" gutter={[10, 10]}>
            <Col span={24}>
              <Table
                size="middle"
                loading={memberValidating}
                dataSource={_memberList?.Data.map((item: any) => ({ key: item.id, ...item }))}
                columns={[
                  {
                    key: 1,
                    dataIndex: 'Account',
                    title: i18n.t('memberAccount'),
                    className: 'size-12',
                    width: 300,
                  },
                  {
                    key: 2,
                    dataIndex: 'up_count',
                    title: i18n.t('teamPromotionCount'),
                    className: 'size-12',
                    align: 'right',
                    width: 300,
                    // sorter: (a, b) => a.teamPromotion - b.teamPromotion,
                    render: (val) => $f(val, 0)
                  },
                  {
                    key: 3,
                    dataIndex: 'up_amount',
                    title: i18n.t('teamPromotionAmount'),
                    className: 'size-12',
                    align: 'right',
                    width: 300,
                    // sorter: (a, b) => a.teamPromotionAmount - b.teamPromotionAmount,
                    render: (val) => $f(val)
                  },
                  {
                    key: 4,
                    dataIndex: 'bonus_count',
                    title: i18n.t('promotionCount'),
                    className: 'size-12',
                    align: 'right',
                    width: 300,
                    // sorter: (a, b) => a.promotionTotal - b.promotionTotal,
                    render: (val) => $f(val, 0)
                  },
                  {
                    key: 5,
                    dataIndex: 'bonus_amount',
                    title: i18n.t('promotionAmount'),
                    className: 'size-12',
                    align: 'right',
                    width: 300,
                    // sorter: (a, b) => a.promotionAmount - b.promotionAmount,
                    render: (val) => $f(val)
                  },
                  {
                    key: 6,
                    title: i18n.t('operation'),
                    className: 'size-12',
                    width: 200,
                    render: (val, record) => (
                      <Button className="size-12" type="link" onClick={() => searchMember(record, TAB.優惠)}>
                        {i18n.t('viewPromotion')}
                      </Button>
                    )
                  },
                ]}
                pagination={{
                  showSizeChanger: true,
                  showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
                  total: _memberList?.TotalRecord,
                  current: params.PageIndex,
                  pageSize: params.PageSize
                }}
                onChange={p => setParams({ ...params, PageIndex: p.current, PageSize: p.pageSize })}
              />
            </Col>
          </Row>
        }
        {/* 優惠 */}
        {
          activeKey === TAB.優惠 &&
          <Row className="mt-1" gutter={[10, 10]}>
            <Col span={24}>
              <Table
                size="middle"
                loading={categoryValidating}
                dataSource={_categoryList?.Data.map((item: any) => ({ key: item.bonus_name, ...item }))}
                columns={[
                  {
                    key: 1,
                    dataIndex: 'bonus_name',
                    title: i18n.t('promotionName'),
                    className: 'size-12',
                    width: 600,
                  },
                  {
                    key: 2,
                    dataIndex: 'bonus_count',
                    title: i18n.t('promotionCount'),
                    className: 'size-12',
                    align: 'right',
                    width: 600,
                    // sorter: (a, b) => a.teamPromotion - b.teamPromotion,
                    render: (val) => $f(val, 0)
                  },
                  {
                    key: 3,
                    dataIndex: 'bonus_amount',
                    title: i18n.t('promotionAmount'),
                    className: 'size-12',
                    align: 'right',
                    width: 500,
                    // sorter: (a, b) => a.teamPromotionAmount - b.teamPromotionAmount,
                    render: (val) => $f(val)
                  },
                ]}
                pagination={{
                  showSizeChanger: true,
                  showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
                  total: _categoryList?.TotalRecord,
                  current: params.PageIndex,
                  pageSize: params.PageSize
                }}
                onChange={p => setParams({ ...params, PageIndex: p.current, PageSize: p.pageSize })}
              />
            </Col>
          </Row>
        }
      </Content>
    </div>
  );
};

export default PageMain;