import { Button, Card, Checkbox, Col, Form, Image, Input, Row, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { RESPONSE_CODE } from "constants/response";
import { RESPONSE_TYPE } from "enum/state";
import useAuth from "hooks/auth.hook";
import useIcon from 'hooks/icon.hook';
import useSite from "hooks/site.hook";
import Cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from 'react-router-dom';
import { GetLanguage } from "services/account.service";

import { postLogin } from "services/member.service";

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const icon = useIcon();

  // 聖經once
  const { errorCode } = useParams();
  const initialized = useRef(false)
  useEffect(() => {
    if (errorCode && !initialized.current) {
      initialized.current = true;
      message.error(RESPONSE_CODE[errorCode as keyof typeof RESPONSE_CODE]);
    }
  }, []);

  const [form] = useForm();
  const [rememberMe, setRememberMe] = useState(Cookies.get('rememberMe') === 'true');

  useEffect(() => {
    // NOTE: 應該要能用setFieldsValue就改變Checkbox
    const rememberMe = Cookies.get('rememberMe') === 'true'
    if (rememberMe) {
      form.setFieldsValue({
        account: Cookies.get('account'),
      })
      setRememberMe(rememberMe)
    }
  }, [])

  const { data: $s } = useSite();
  useEffect(() => {
    if ($s) {
      const lang = Cookies.get('lang') || $s.Language
      form.setFieldsValue({
        Language: lang
      })
      handleLangChange(lang);
    }
   }, [$s])
   const handleLangChange = (value: string) => {
    i18n.changeLanguage(value);
    Cookies.set("lang", value);
  }

  const { t, i18n } = useTranslation();
  const { data: languageList, isValidating } = GetLanguage();

  const onFinish = async (formData: any) => {
    const response = await postLogin({
      Account: formData.account,
      Password: formData.password
    })
    if (response.State === RESPONSE_TYPE.成功) {
      auth.login(response.Data.Token);

      if (rememberMe) {
        Cookies.set('account', formData.account);
      }
      Cookies.set('rememberMe', rememberMe ? 'true' : 'false');

      message.success(i18n.t('loginSuccess'));
      form.resetFields();
      navigate('/');
    } else {
      const msg = RESPONSE_CODE[response.Message as keyof typeof RESPONSE_CODE];
      message.error(msg || i18n.t('incorrectUsernameOrPassword'));
    }
  };

  return (
    <>
      <Helmet>
        <link rel="icon" href={$s && $s.Logo1} type="image/x-icon" />
      </Helmet >
      <Row
        className="login-background-image"
        justify="center"
        align="middle"
        style={{ height: "100vh" }}
      >
        <Card className="login-card">
          {/* logo */}
          <Row justify="center" align="middle"
            style={{ position: "absolute", zIndex: 1, top: '-20%', left: '50%', transform: 'translateX(-50%)' }}>
            <Col>
              <Image src={icon.logo01} preview={false} />
            </Col>
          </Row>
          <Form size="middle" onFinish={onFinish} form={form}>
            <Form.Item style={{ padding: '10px 0', textAlign: 'center', color: '#666' }}>
              <div>{i18n.t('webmasterDashboard')}</div>
            </Form.Item>
            <Form.Item name="lang">
              <Select
                loading={isValidating}
                onChange={handleLangChange}
                options={
                  languageList?.Data?.map((item: LanguageList) => ({
                    value: item.Code,
                    label: (
                      <Row align="middle" gutter={10}>
                        <Col><Image src={icon.icon01} width={20} height={20} preview={false} /></Col>
                        <Col>{i18n.t(item.Code)}</Col>
                      </Row>
                    )
                  })) 
                  // 暫時只顯示簡中與繁中
                  .filter((item: any) => item.value === 'zh-TW' || item.value === 'zh-CN')
                }
              />
            </Form.Item>
            <Form.Item
              name="account"
              className="mt-1"
              rules={[{ required: true, message: `${i18n.t("enterAccount")}` }]}
            >
              <Input
                prefix={
                  <Image rootClassName="center" src={icon.icon02} width={20} height={20} preview={false} />
                }
                placeholder={`${i18n.t("inputAccount")}`}
              />
            </Form.Item>
            <Form.Item
              name="password"
              className="mt-1"
              rules={[{ required: true, message: `${i18n.t("enterPassword")}` }]}
            >
              <Input.Password
                prefix={
                  <Image rootClassName="center" src={icon.icon03} width={20} height={20} preview={false} />
                }
                placeholder={`${i18n.t("inputPassword")}`}
              />
            </Form.Item>
            <Form.Item className="center mt-1" name="remember">
              <Checkbox checked={rememberMe} onChange={(e: any) => setRememberMe(e.target.checked)}>
                {i18n.t("rememberAccount")}
              </Checkbox>
            </Form.Item>
            <Form.Item className="center mt-1" name="login">
              <Button
                htmlType="submit"
                style={{ background: '#DABF8D' }}
                className="w-10 login-btn"
                size="large"
              >
                {i18n.t("login")}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Row>
    </>
  );
};

export default PageMain;
