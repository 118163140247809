import { Button, Col, Descriptions, Form, Input, Row, Select, Spin, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { UploadFile } from 'antd/lib';
import { LayoutNav, LayoutTabChatSetting, UploadImage } from 'components/layout.component';
import { RESPONSE_CODE_CHAT } from 'constants/response';
import { RESPONSE_TYPE, VIRTUAL_STATE } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { ChatSetting, UpdateChatSetting } from 'services/chat.service';
import { enumToOptions } from 'utils/common';

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();
  const [form] = useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [state, setState] = useState(false);
  const [loading, setLoading] = useState(false);
  const { data: SettingInfo, isValidating, mutate } = ChatSetting();
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);

  useEffect(() => {
    if (SettingInfo) {
      form.setFieldsValue({
        state: SettingInfo.WelcomeMessage ? 1 : 2,
        welcomeContent: SettingInfo.WelcomeMessage,
        defaultNickname: SettingInfo.DefaultNickname,
      })
      SettingInfo.DefaultAvatar && setImageData([
        {
          uid: SettingInfo.DefaultAvatar,
          name: SettingInfo.DefaultAvatar,
          url: SettingInfo.DefaultAvatar,
        }
      ])
    }
  }, [SettingInfo]);

  const onCancel = () => {
    setIsEdit(false);
    form.setFieldsValue({
      state: SettingInfo.WelcomeMessage ? 1 : 2,
      welcomeContent: SettingInfo.WelcomeMessage,
      defaultNickname: SettingInfo.DefaultNickname,
    })
    setImageData([
      {
        uid: SettingInfo.DefaultAvatar,
        name: SettingInfo.DefaultAvatar,
        url: SettingInfo.DefaultAvatar,
      }
    ])
    mutate();
  }

  const onFinish = async (value: {
    state: number;
    welcomeContent: string;
    defaultNickname: string;
  }) => {
    const response = await UpdateChatSetting({
      Id: SettingInfo.Id,
      WelcomeMessage: value.welcomeContent,
      DefaultNickname: value.defaultNickname,
      DefaultAvatar: imageData ? imageData[0].url : SettingInfo.DefaultAvatar
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('editSuccess'));
      mutate();
      setIsEdit(false);
    } else {
      message.error(RESPONSE_CODE_CHAT[response.Message as keyof typeof RESPONSE_CODE_CHAT]);
    }
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Form form={form} onFinish={onFinish}>
          <LayoutTabChatSetting activeKey='3' />
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row justify="end" gutter={[12, 12]}>
                {
                  !isEdit && $p.includes('70402') &&
                  <Col>
                    <Button type="primary" onClick={() => setIsEdit(true)}>{i18n.t('edit')}</Button>
                  </Col>
                }
                {
                  isEdit &&
                  <>
                    <Col>
                      <Button onClick={onCancel}>{i18n.t('cancel')}</Button>
                    </Col>
                    <Col>
                      <Button type="primary" loading={loading} htmlType="submit">{i18n.t('confirm')}</Button>
                    </Col>
                  </>
                }
              </Row>
            </Col>
            <Col span={24}>
              <Spin spinning={isValidating}>
                <Descriptions bordered size="small" labelStyle={{ width: 200 }}
                  contentStyle={{ width: 500 }}>
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('welcomeMessageStatus')}</>}>
                    <Form.Item name="state" rules={[{ required: true }]}>
                      <Select
                        className="w-full"
                        disabled={!isEdit}
                        placeholder={i18n.t('pleaseSelect')}
                        onChange={e => setState(e === 2 ? true : false)}
                        options={enumToOptions(VIRTUAL_STATE)}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('welcomeMessageContent')}</>}>
                    <Form.Item name="welcomeContent"
                      rules={[{ required: true, type: 'string', min: 4, max: 100, message: `${i18n.t('inputBetween4And100Characters')}` }]}>
                      <Input placeholder={`${i18n.t('enter')}`} disabled={state || !isEdit} />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('chatRoomDefaultNickname')}</>}>
                    <Form.Item name="defaultNickname" rules={[{ required: true, message: `${i18n.t('enter')}` }]}>
                      <Input placeholder={`${i18n.t('enter')}`} disabled={!isEdit} />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('defaultAvatar')}>
                    <Form.Item name="DefaultAvatar" valuePropName="fileList">
                      <UploadImage
                        name="DefaultAvatar"
                        url={'/upload/images/public/message'}
                        imageData={imageData}
                        setImageData={setImageData}
                        w={100}
                        h={100}
                        crop
                        cropShape="round"
                        disabled={!isEdit}
                      />
                    </Form.Item>
                    <div className="color-03 size-12">{i18n.t('onlyJpgOrPngFiles')}<span className="require">{i18n.t('imageMobileVersionWidth100pxHeight100px')}</span>{i18n.t('allowedAndSizeNotExceed500kb')}</div>
                  </Descriptions.Item>
                  <Descriptions.Item label={""}>
                  </Descriptions.Item>
                  <Descriptions.Item label={""}>
                  </Descriptions.Item>
                </Descriptions>
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;