import { Button, Col, Row, Table, Tag } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { PopupAddAgentTag, PopupAgentTagList, PopupEditAgentTag } from 'components/agent.component';
import { LayoutNav } from 'components/layout.component';
import { ENABLE } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { GetTagList } from 'services/tag.service';

// 代理標籤

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();
  const [tagId, setTagId] = useState<number | null>(null);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenTagList, setIsOpenTagList] = useState(false);
  const { data: TagList, isValidating, mutate } = GetTagList();

  const stateColor = (state: number) => {
    switch (state) {
      case ENABLE.啟用:
        return 'color-pass'
      case ENABLE.停用:
        return 'color-reject'
    };
  };

  const onEdit = (id: number) => {
    setTagId(id);
    setIsOpenEditModal(true);
  }

  const onAgentAccount = (id: number) => {
    setTagId(id);
    setIsOpenTagList(true);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="p-2">
        <Row gutter={[12, 20]}>
          {/* 新增 */}
          {
            $p.includes('41104') &&
            <Col span={24}>
              <Button type="primary" onClick={() => setIsOpenAddModal(true)}>{i18n.t('add')}</Button>
            </Col>
          }
          {/* 數據 */}
          <Col span={24}>
            <Table
              loading={isValidating}
              size="small"
              dataSource={
                TagList ?
                  TagList.Data.map((item: TagInfo) => (
                    {
                      key: item.Id,
                      sort: item.Position,
                      tag: {
                        name: item.Name,
                        color: item.TextColor,
                        background: item.Color,
                        IsShowName: item.IsShowName
                      },
                      content: item.Description,
                      state: item.Status,
                      users: item.AgentCount,
                    }
                  )) : []
              }
              columns={[
                {
                  title: i18n.t('sort'),
                  dataIndex: 'sort',
                  align: 'center',
                  width: 80,
                  sorter: (a, b) => a.sort - b.sort
                },
                {
                  title: i18n.t('tagName'),
                  dataIndex: 'tag',
                  width: 300,
                  render: (_, { tag }) => (
                    <Tag style={{
                      color: tag.color,
                      background: tag.background,
                      minWidth: 80,
                      minHeight: 20,
                      textAlign: 'center',
                    }}>
                      {tag.IsShowName ? tag.name : ''}
                    </ Tag>
                  )
                },
                {
                  title: i18n.t('description'),
                  dataIndex: 'content',
                  width: 500
                },
                {
                  title: i18n.t('status'),
                  dataIndex: 'state',
                  align: 'center',
                  width: 80,
                  render: (_, { state }) => <div className={stateColor(state)}>{ENABLE[state]}</div>
                },
                {
                  title: i18n.t('userCount'),
                  dataIndex: 'users',
                  width: 150,
                  align: 'right'
                },
                {
                  title: i18n.t('operation'),
                  width: 150,
                  render: (_, record) => (
                    <>
                      {
                        ($p.includes('41102') && !$p.includes('41103')) &&
                        <Button className="size-12" type="link" onClick={() => onEdit(record.key)}>{i18n.t('detail')}</Button>
                      }
                      {
                        $p.includes('41103') &&
                        <Button className="size-12" type="link" onClick={() => onEdit(record.key)}>{i18n.t('edit')}</Button>
                      }
                      {
                        ($p.includes('41105') || $p.includes('41106')) &&
                        <Button className="size-12" type="link" onClick={() => onAgentAccount(record.key)}>{i18n.t('agentAccount')}</Button>
                      }
                    </>
                  )
                },
              ]}
              pagination={false}
            />
          </Col>
        </Row>
        <PopupAddAgentTag isOpen={isOpenAddModal} close={() => setIsOpenAddModal(false)} mutate={mutate} />
        <PopupEditAgentTag isOpen={isOpenEditModal} close={() => setIsOpenEditModal(false)} id={tagId} mutate={mutate} />
        <PopupAgentTagList isOpen={isOpenTagList} close={() => setIsOpenTagList(false)} id={tagId} mutate={mutate} />
      </Content>
    </div >
  );
};

export default PageMain;