import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { ViewTransferBank, ViewTransferCrypto } from 'components/finance.component';
import { LayoutNav, LayoutPagination } from 'components/layout.component';
import { MemberLevelSelect } from 'components/member.component';
import { GATEWAY, TRANSFER_TYPE } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { GetDepositmerchantOfflineList, GetWithdrawmerchantOfflineList } from 'services/depositMerchant.service';

// 線下出入款管理

interface Search {
  GatewayCode?: string;
  CustomName?: string;
  LevelId?: number;
}

enum GATEWAY_CODE {
  入款銀行卡 = "DEPOSIT_GATE_BANK_OFFLINE",
  入款虛擬貨幣 = "DEPOSIT_GATE_CRYPTO_OFFLINE",
  出款銀行卡 = "WITHDRAW_GATE_BANK_OFFLINE",
  出款虛擬貨幣 = "WITHDRAW_GATE_CRYPTO_OFFLINE"
}

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();
  const [form] = useForm();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [type, setType] = useState(TRANSFER_TYPE.入款);
  const [gateway, setGateway] = useState(GATEWAY.銀行卡);
  const [params, setParams] = useState<Search>({
    GatewayCode: GATEWAY_CODE.入款銀行卡
  })
  useEffect(() => {
    if ($p.includes('20701')) {
      setType(TRANSFER_TYPE.入款);
      setParams({
        GatewayCode: GATEWAY_CODE.入款銀行卡
      })
    } else if ($p.includes('20403')) {
      setType(TRANSFER_TYPE.出款);
      setParams({
        GatewayCode: GATEWAY_CODE.出款銀行卡
      })
    }
  }, [$p]);
  const { data: DepositList, isValidating: depositLoading, mutate: refreshDeposit } = GetDepositmerchantOfflineList({
    ...params,
    PageIndex: page[0],
    PageSize: page[1],
  }, !!$p.includes('20701') && type === TRANSFER_TYPE.入款)

  const { data: Withdrawal, isValidating: withdrawalLoading, mutate: refreshWithdrawal } = GetWithdrawmerchantOfflineList({
    ...params,
    PageIndex: page[0],
    PageSize: page[1],
  }, !!$p.includes('20403') && type === TRANSFER_TYPE.出款)

  const [dataList, setDataList] = useState<any>([])
  useEffect(() => {
    if (DepositList) setDataList(DepositList)
    else if (Withdrawal) setDataList(Withdrawal)
  }, [DepositList, Withdrawal]);

  useEffect(() => {
    onClear();
    switch (type) {
      case TRANSFER_TYPE.入款:
        setParams({
          GatewayCode: gateway === GATEWAY.銀行卡 ? GATEWAY_CODE.入款銀行卡 : GATEWAY_CODE.入款虛擬貨幣
        })
        break;
      case TRANSFER_TYPE.出款:
        setParams({
          GatewayCode: gateway === GATEWAY.銀行卡 ? GATEWAY_CODE.出款銀行卡 : GATEWAY_CODE.出款虛擬貨幣
        })
        break;
    }
  }, [type, gateway]);

  const search = (formData: any) => {
    setPage([1, page[1]]);
    setParams({
      ...params,
      CustomName: formData.CustomName,
      LevelId: formData.LevelId,
    })
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setPage([1, 10]);
  }

  const mutate = () => {
    refreshDeposit();
    refreshWithdrawal();
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={search}>
          <Row gutter={[12, 12]}>
            {/* 搜尋欄位 */}
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  {
                    $p.includes('20701') && !$p.includes('20403') &&
                    <Select
                      className="w-12"
                      defaultValue={TRANSFER_TYPE.入款}
                      options={[
                        { value: TRANSFER_TYPE.入款, label: i18n.t('deposit') },
                      ]}
                      onChange={setType}
                    />
                  }
                  {
                    $p.includes('20403') && !$p.includes('20701') &&
                    <Select
                      className="w-12"
                      defaultValue={TRANSFER_TYPE.出款}
                      options={[
                        { value: TRANSFER_TYPE.出款, label: i18n.t('withdrawal') },
                      ]}
                      onChange={setType}
                    />
                  }
                  {
                    $p.includes('20403') && $p.includes('20701') &&
                    <Select
                      className="w-12"
                      defaultValue={TRANSFER_TYPE.入款}
                      options={[
                        { value: TRANSFER_TYPE.入款, label: i18n.t('deposit') },
                        { value: TRANSFER_TYPE.出款, label: i18n.t('withdrawal') },
                      ]}
                      onChange={setType}
                    />
                  }
                </Col>
                <Col>
                  <Select
                    className="w-12"
                    defaultValue={GATEWAY.銀行卡}
                    options={[
                      { value: GATEWAY.銀行卡, label: i18n.t('bankCard') },
                      { value: GATEWAY.虛擬貨幣, label: i18n.t('virtualCurrency') },
                    ]}
                    onChange={setGateway}
                  />
                </Col>
                <Col>
                  <Form.Item name="CustomName">
                    <Input placeholder={`${i18n.t('displayName')}`} />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="LevelId">
                    <MemberLevelSelect name="LevelId" form={form} />
                  </Form.Item>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            {/* 列表 */}
            <Col span={24}>
              {
                gateway === GATEWAY.銀行卡 &&
                <Spin spinning={depositLoading || withdrawalLoading}>
                  <ViewTransferBank data={dataList} mutate={mutate} type={type} />
                  <LayoutPagination total={dataList?.TotalRecord} setPage={setPage} page={page} />
                </Spin>
              }
              {
                gateway === GATEWAY.虛擬貨幣 &&
                <Spin spinning={depositLoading || withdrawalLoading}>
                  <ViewTransferCrypto data={dataList} mutate={mutate} type={type} />
                  <LayoutPagination total={dataList?.TotalRecord} setPage={setPage} page={page} />
                </Spin>
              }
            </Col>
          </Row>
        </Form>
      </Content >

    </div >
  );
};

export default PageMain;