import {
  axiosGetAllData,
  axiosGetAuth,
  axiosPostAuth,
  baseURL,
  useFetchDataCommon
} from "services";

export const GetPopupList = () => useFetchDataCommon(axiosGetAuth, `${baseURL}/fronpopsetting/list`);
export const UpdatePopup = (data: any) => axiosPostAuth(`${baseURL}/fronpopsetting/update`, data);

export const GetSlideList = () => useFetchDataCommon(axiosGetAuth, `${baseURL}/contents/mineslide/list`);
export const CreateMobileSlide = (data: any) => axiosPostAuth(`${baseURL}/contents/mobilemainslide/create`, data);
export const CreateWebSlide = (data: any) => axiosPostAuth(`${baseURL}/contents/webmainslide/create`, data);
export const DeleteSlide = (data: any) => axiosPostAuth(`${baseURL}/contents/delete`, data);
export const UpdateSlide = (data: any) => axiosPostAuth(`${baseURL}/contents/mainslide/update`, data);

export const GetFrontList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/contents/frontpage/list`, params);
export const CreateFront = (data: any) => axiosPostAuth(`${baseURL}/contents/frontpage/create`, data);
export const UpdateFront = (data: any) => axiosPostAuth(`${baseURL}/contents/frontpage/update`, data);
export const DeleteFront = (data: any) => axiosPostAuth(`${baseURL}/contents/frontpage/delete`, data);

export const GetFrontSubList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/contents/frontpage/sub/list`, params);
export const GetFrontSubOne = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/contents/frontpage/sub/one`, params);
export const CreateFrontSub = (data: any) => axiosPostAuth(`${baseURL}/contents/frontpage/sub/create`, data);
export const UpdateFrontSub = (data: any) => axiosPostAuth(`${baseURL}/contents/frontpage/sub/update`, data);
export const DeleteFrontSub = (data: any) => axiosPostAuth(`${baseURL}/contents/frontpage/sub/delete`, data);
