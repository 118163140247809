import { Button, Col, Descriptions, Form, Input, InputNumber, Popover, Row, Select, Spin, Table, Tag, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { SelectAgentPermission } from 'components/agent.component';
import { LayoutNav } from 'components/layout.component';
import { RESPONSE_CODE_AGENT } from 'constants/response';
import { SETTLE_CYCLE } from 'enum/promotion';
import { AGENT_STATE, RESPONSE_TYPE } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AgentRebateInfo, AgentRebateUpdate, FetchAgentDetail, FetchTagList, PostAgentUpdate, VerifyAgent } from 'services/agent.service';
import { decimalNumber as $cc, toFormatNumber as $f, convertedToPercentage01 as $g, enumToOptions, verify } from 'utils/common';

// 編輯總代 / 編輯代理

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = useForm();
  const [isAllDisabled, setIsAllDisabled] = useState(false);
  const [isOpenRebatePopover, setIsOpenRebatePopover] = useState(false);
  const [isOpenShareRatePopover, setIsOpenShareRatePopover] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [tagOptions, setTagOptions] = useState<TagInfo[]>([]);
  const [isEnterPassword, setIsEnterPassword] = useState(false);
  const [isDisConfirmPassword, setDisConfirmPassword] = useState(true);

  // 取得這代基本資料
  const { data: agent, mutate, isValidating } = FetchAgentDetail({ Id: id }, !!id);
  useEffect(() => {
    if (agent) {
      const Tags = JSON.parse(agent.TagsId);
      form.setFieldsValue({
        Account: agent.Account,
        Remark: agent.Remark,
        Status: agent.Status,
        TagsId: Tags,
        RefCode: agent.RefCode,

        // 返水占成週期
        CommissionSettleCycle: agent.CommissionSettleCycle,
        RevenueShareSettleCycle: agent.RevenueShareSettleCycle,
      })
    }
  }, [agent])

  // 取得上代返水(最大值)
  const [parentRebate, setParentRebate] = useState<any>({
    BonusCostRate: 100,
    CashFlowFeeRate: 100,

    EGamesCommissionRate: 100,
    LiveCommissionRate: 100,
    SportsCommissionRate: 100,
    ESportsCommissionRate: 100,
    PokerCommissionRate: 100,
    FishingCommissionRate: 100,
    CockfightCommissionRate: 100,
    LotteryCommissionRate: 100,
    SlotCommissionRate: 100,

    EGamesRevenueShareRate: 100,
    LiveRevenueShareRate: 100,
    SportsRevenueShareRate: 100,
    ESportsRevenueShareRate: 100,
    PokerRevenueShareRate: 100,
    FishingRevenueShareRate: 100,
    CockfightRevenueShareRate: 100,
    LotteryRevenueShareRate: 100,
    SlotRevenueShareRate: 100,
  });
  const { data: parentRebateInfo } = AgentRebateInfo({ AgentId: agent?.AGId }, !!agent?.AGId && agent?.Level !== 0);
  useEffect(() => {
    if (parentRebateInfo) {
      setParentRebate({
        BonusCostRate: $g(parentRebateInfo.BonusCostRate),
        CashFlowFeeRate: $g(parentRebateInfo.CashFlowFeeRate),

        EGamesCommissionRate: $g(parentRebateInfo.EGamesCommissionRate),
        LiveCommissionRate: $g(parentRebateInfo.LiveCommissionRate),
        SportsCommissionRate: $g(parentRebateInfo.SportsCommissionRate),
        ESportsCommissionRate: $g(parentRebateInfo.ESportsCommissionRate),
        PokerCommissionRate: $g(parentRebateInfo.PokerCommissionRate),
        FishingCommissionRate: $g(parentRebateInfo.FishingCommissionRate),
        CockfightCommissionRate: $g(parentRebateInfo.CockfightCommissionRate),
        LotteryCommissionRate: $g(parentRebateInfo.LotteryCommissionRate),
        SlotCommissionRate: $g(parentRebateInfo.SlotCommissionRate),

        EGamesRevenueShareRate: $g(parentRebateInfo.EGamesRevenueShareRate),
        LiveRevenueShareRate: $g(parentRebateInfo.LiveRevenueShareRate),
        SportsRevenueShareRate: $g(parentRebateInfo.SportsRevenueShareRate),
        ESportsRevenueShareRate: $g(parentRebateInfo.ESportsRevenueShareRate),
        PokerRevenueShareRate: $g(parentRebateInfo.PokerRevenueShareRate),
        FishingRevenueShareRate: $g(parentRebateInfo.FishingRevenueShareRate),
        CockfightRevenueShareRate: $g(parentRebateInfo.LiveRevenueShareRate),
        LotteryRevenueShareRate: $g(parentRebateInfo.LotteryRevenueShareRate),
        SlotRevenueShareRate: $g(parentRebateInfo.SlotRevenueShareRate),
      })
    }
  }, [parentRebateInfo]);

  // 取得這代返水
  const { data: RebateInfo, isValidating: rebateValidating } = AgentRebateInfo({ AgentId: id });
  useEffect(() => {
    if (RebateInfo) {
      form.setFieldsValue({
        BonusCostRate: $g(RebateInfo.BonusCostRate),
        CashFlowFeeRate: $g(RebateInfo.CashFlowFeeRate),

        EGamesCommissionRate: $g(RebateInfo.EGamesCommissionRate),
        LiveCommissionRate: $g(RebateInfo.LiveCommissionRate),
        SportsCommissionRate: $g(RebateInfo.SportsCommissionRate),
        ESportsCommissionRate: $g(RebateInfo.ESportsCommissionRate),
        PokerCommissionRate: $g(RebateInfo.PokerCommissionRate),
        FishingCommissionRate: $g(RebateInfo.FishingCommissionRate),
        CockfightCommissionRate: $g(RebateInfo.CockfightCommissionRate),
        LotteryCommissionRate: $g(RebateInfo.LotteryCommissionRate),
        SlotCommissionRate: $g(RebateInfo.SlotCommissionRate),

        EGamesRevenueShareRate: $g(RebateInfo.EGamesRevenueShareRate),
        LiveRevenueShareRate: $g(RebateInfo.LiveRevenueShareRate),
        SportsRevenueShareRate: $g(RebateInfo.SportsRevenueShareRate),
        ESportsRevenueShareRate: $g(RebateInfo.ESportsRevenueShareRate),
        PokerRevenueShareRate: $g(RebateInfo.PokerRevenueShareRate),
        FishingRevenueShareRate: $g(RebateInfo.FishingRevenueShareRate),
        CockfightRevenueShareRate: $g(RebateInfo.CockfightRevenueShareRate),
        LotteryRevenueShareRate: $g(RebateInfo.LotteryRevenueShareRate),
        SlotRevenueShareRate: $g(RebateInfo.SlotRevenueShareRate),
      })
    }
  }, [RebateInfo])

  const { data: tagList } = FetchTagList({
    Role: 1,
    Status: 0,
  });
  useEffect(() => {
    if (tagList) setTagOptions(tagList);
  }, [tagList]);

  const handleSetAllRebate = (value: string) => {
    if ($cc(value)) {
      form.setFieldsValue({
        EGamesCommissionRate: value,
        LiveCommissionRate: value,
        SportsCommissionRate: value,
        ESportsCommissionRate: value,
        PokerCommissionRate: value,
        FishingCommissionRate: value,
        CockfightCommissionRate: value,
        LotteryCommissionRate: value,
        SlotCommissionRate: value
      })
    }
  }

  const handleSetAllShareRate = (value: string) => {
    if ($cc(value)) {
      form.setFieldsValue({
        SlotRevenueShareRate: value,
        EGamesRevenueShareRate: value,
        LiveRevenueShareRate: value,
        SportsRevenueShareRate: value,
        ESportsRevenueShareRate: value,
        PokerRevenueShareRate: value,
        FishingRevenueShareRate: value,
        CockfightRevenueShareRate: value,
        LotteryRevenueShareRate: value
      })
    }
  }

  // 更新資料
  const [permissions, setPermissions] = useState([]);
  const save = async (formData: any) => {
    if (isEnterPassword && formData.confirmNewPassword === '') return message.error(i18n.t('pleaseEnterConfirmationPassword'));

    const info: ApiResult = await PostAgentUpdate({
      Id: id,
      Status: formData.Status,
      Password: (formData.password && formData.password !== '') ? formData.password : null,
      ConfirmPassword: (formData.confirmNewPassword && formData.confirmNewPassword !== '') ? formData.confirmNewPassword : null,
      TagsId: formData.TagsId.length !== 0 ? formData.TagsId : [],
      Remark: formData.Remark,
      RefCode: formData.RefCode,
      Permission: permissions
    })
    const rebate: ApiResult = await AgentRebateUpdate({
      AgentId: id,

      CashFlowFeeRate: parseFloat(formData.CashFlowFeeRate) / 100,
      BonusCostRate: parseFloat(formData.BonusCostRate) / 100,

      EGamesCommissionRate: parseFloat(formData.EGamesCommissionRate) / 100,
      SlotCommissionRate: parseFloat(formData.SlotCommissionRate) / 100,
      LiveCommissionRate: parseFloat(formData.LiveCommissionRate) / 100,
      SportsCommissionRate: parseFloat(formData.SportsCommissionRate) / 100,
      ESportsCommissionRate: parseFloat(formData.ESportsCommissionRate) / 100,
      PokerCommissionRate: parseFloat(formData.PokerCommissionRate) / 100,
      FishingCommissionRate: parseFloat(formData.FishingCommissionRate) / 100,
      CockfightCommissionRate: parseFloat(formData.CockfightCommissionRate) / 100,
      LotteryCommissionRate: parseFloat(formData.LotteryCommissionRate) / 100,
      EGamesRevenueShareRate: parseFloat(formData.EGamesRevenueShareRate) / 100,
      SlotRevenueShareRate: parseFloat(formData.SlotRevenueShareRate) / 100,
      LiveRevenueShareRate: parseFloat(formData.LiveRevenueShareRate) / 100,
      SportsRevenueShareRate: parseFloat(formData.SportsRevenueShareRate) / 100,
      ESportsRevenueShareRate: parseFloat(formData.ESportsRevenueShareRate) / 100,
      PokerRevenueShareRate: parseFloat(formData.PokerRevenueShareRate) / 100,
      FishingRevenueShareRate: parseFloat(formData.FishingRevenueShareRate) / 100,
      CockfightRevenueShareRate: parseFloat(formData.CockfightRevenueShareRate) / 100,
      LotteryRevenueShareRate: parseFloat(formData.LotteryRevenueShareRate) / 100,
    })
    if (info.State === RESPONSE_TYPE.成功 && rebate.State === RESPONSE_TYPE.成功) {
      mutate();
      message.success(i18n.t('updateSuccess'));
      setConfirm(true);
      setIsAllDisabled(true);
      setIsOpenRebatePopover(false);
      setIsOpenShareRatePopover(false);
    } else {
      // NOTE: 要分開打才能分別顯示Message
      const msg = RESPONSE_CODE_AGENT[info.Message as keyof typeof RESPONSE_CODE_AGENT];
      message.error(msg || i18n.t('processingFailed'));
    }

  }

  const handleClickManualVerification = async () => {
    const apiResult: ApiResult = await VerifyAgent({
      Id: agent.Id,
    })
    if (apiResult.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('verificationSuccess'));

      setTimeout(() => {
        mutate();
      }, 800);

    } else {
      message.error(RESPONSE_CODE_AGENT[apiResult.Message as keyof typeof RESPONSE_CODE_AGENT]);
    }
  }

  const handlePassword = () => {
    setDisConfirmPassword(true);
    form.setFieldValue('confirmNewPassword', '');
  }

  return (
    <div id="container">
      <LayoutNav account={agent?.Account} />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={save}>
          <Form.Item name="RefCode" style={{ display: 'none' }}></Form.Item>
          <Spin spinning={isValidating}>
            {/* 基本資料 */}
            <Row align="middle">
              <Col span={24}>
                <Row align="middle" justify="end" gutter={10}>
                  {confirm ?
                    <Col><Button onClick={() => navigate('/agent')}>{i18n.t('close')}</Button></Col> :
                    <>
                      <Col><Button onClick={() => navigate('/agent')}>{i18n.t('cancel')}</Button></Col>
                      {
                        $p.includes('40204') &&
                        <Col><Button type="primary" onClick={form.submit}>{i18n.t('confirm')}</Button></Col>
                      }
                    </>
                  }
                </Row>
              </Col>
              <Col span={24}>
                <Descriptions size="small" layout="horizontal" title={i18n.t('edit')} column={{ xs: 8 }} bordered
                  labelStyle={{ fontSize: 12, fontWeight: 500 }}>
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('account')}</>}>
                    <Form.Item name="Account">
                      <Input disabled />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<>{isEnterPassword && <span className="require">*</span>}{i18n.t('password')}</>} >
                    <Form.Item name="password" required={isEnterPassword} rules={[
                      { min: 4, message: `${i18n.t('supportSpecialCharacters')}` },
                      { max: 20, message: `${i18n.t('supportSpecialCharacters')}` },
                    ]}>
                      <Input.Password disabled={confirm || !$p.includes('40204')} placeholder={`${i18n.t('supportSpecialCharacters')}`}
                        onChange={e => {
                          setDisConfirmPassword(e.target.value === '');
                          setIsEnterPassword(e.target.value !== '');
                          e.target.value === '' && handlePassword()
                        }} />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<>{isEnterPassword && <span className="require">*</span>}{i18n.t('confirmNewPassword')}</>}>
                    <Form.Item name="confirmNewPassword" required={isEnterPassword} rules={[
                      { min: 4, message: `${i18n.t('supportSpecialCharacters')}` },
                      { max: 20, message: `${i18n.t('supportSpecialCharacters')}` },
                    ]}>
                      <Input.Password disabled={isDisConfirmPassword ? isDisConfirmPassword : confirm}
                        placeholder={`${i18n.t('supportSpecialCharacters')}`} />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('status')}</>}>
                    <Form.Item name="Status">
                      <Select
                        className="w-full"
                        disabled={agent?.Status === 0 || !$p.includes('40204')}
                        options={enumToOptions(AGENT_STATE)}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  {/* 手動驗證 */}
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('verificationDate')}</>}>
                    {agent?.Status === 0
                      ? <Button disabled={!$p.includes('40204') || !$p.includes('40205')} type="primary" onClick={handleClickManualVerification}>{i18n.t('manualVerification')}</Button>
                      : <Input value={agent?.VerifiedDate} disabled />
                    }
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('agentTag')}>
                    <Form.Item name="TagsId">
                      <Select
                        disabled={confirm || !$p.includes('40204')}
                        className="w-full"
                        placeholder={i18n.t('pleaseSelect')}
                        options={
                          tagOptions ?
                            tagOptions.map((option) => ({
                              value: option.Id,
                              label:
                                <Tag style={{ color: option.TextColor, background: option.Color }}>
                                  {option.Name}
                                </ Tag>
                            })) : []
                        }
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  {/* 優惠占成 */}
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('promotionCommission')}%</>}>
                    <Form.Item name="BonusCostRate" rules={[
                      ...verify({ max: parentRebate.BonusCostRate || 100 }),
                      // {
                      //   validator: async (r, value) => {
                      //     const pre = parentRebate.BonusCostRate;
                      //     if (Number(value) <= pre) {
                      //       return Promise.resolve();
                      //     }
                      //     return Promise.reject(`${i18n.t('mustBeLessThanOrEqualTo')}${pre}`);
                      //   }
                      // }
                    ]}>
                      <InputNumber className="w-full" disabled={confirm || !$p.includes('40204')}
                        placeholder={`${i18n.t('enter')}`} />
                    </Form.Item>
                  </Descriptions.Item>
                  {/* 金流費率 */}
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('paymentFeeRate')}</>}>
                    <Form.Item name="CashFlowFeeRate" rules={[
                      ...verify({ max: parentRebate.CashFlowFeeRate || 100 }),
                      // {
                      //   validator: async (r, value) => {
                      //     const pre = parentRebate.CashFlowFeeRate;
                      //     if (Number(value) <= pre) {
                      //       return Promise.resolve();
                      //     }
                      //     return Promise.reject(`${i18n.t('mustBeLessThanOrEqualTo')}${pre}`);
                      //   }
                      // }
                    ]}>
                      <InputNumber className="w-full" disabled={confirm || !$p.includes('40204')}
                        placeholder={`${i18n.t('enter')}`} />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('remark')}>
                    <Form.Item name="Remark">
                      <Input disabled={confirm || !$p.includes('40204')} placeholder={`${i18n.t('enter')}`} />
                    </Form.Item>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>

            {/* 後台權限 */}
            <Row align="middle" className="mt-1">
              <Col span={24}>
                <Col className="size-16 font-w-md">{i18n.t('backOfficePermissions')}</Col>
                <Col span={24} className="mt-1">
                  <SelectAgentPermission agent={agent} permissions={permissions} setPermissions={setPermissions} confirm={confirm} disabled={!$p.includes('40204')} />
                </Col>
              </Col>
            </Row>
          </Spin>
          {/* 週期設定 */}
          <Row align="middle" className="mt-1" gutter={30}>
            <Col span={12}>
              <Descriptions size="small" layout="vertical" title={i18n.t('cashbackCommissionSetting')} bordered
                labelStyle={{ fontSize: 12, fontWeight: 500 }}>
                <Descriptions.Item style={{ width: '50%' }} label={<><span className="require">*</span>{i18n.t('cashbackSettlementCycle')}</>}>
                  <Form.Item name="CommissionSettleCycle">
                    <Select
                      className="w-full"
                      placeholder={i18n.t('pleaseSelect')}
                      disabled
                      options={enumToOptions(SETTLE_CYCLE)}
                    />
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={12}>
              <Descriptions size="small" layout="vertical" title={i18n.t('cashbackCommissionSetting')} bordered
                labelStyle={{ fontSize: 12, fontWeight: 500 }}>
                <Descriptions.Item style={{ width: '50%' }} label={<><span className="require">*</span>{i18n.t('commissionSettlementCycle')}</>}>
                  <Form.Item name="RevenueShareSettleCycle">
                    <Select
                      className="w-full"
                      placeholder={i18n.t('pleaseSelect')}
                      disabled
                      options={enumToOptions(SETTLE_CYCLE)}
                    />
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          {/* 返水占成 */}
          <Row className="mt-1 mb-3">
            <Col span={24} className="mt-1">
              <Spin spinning={rebateValidating}>
                <Row align="middle" gutter={30}>
                  <Col span={12}>
                    <Row justify="end">
                      <Col>
                        <Popover placement="bottomLeft" trigger="click" open={isOpenRebatePopover}
                          content={
                            <Row gutter={[12, 12]}>
                              <Col span={24} className="w-6">
                                <Form.Item name="batchSetting01">
                                  <Input placeholder={`${i18n.t('enter')}`}
                                    onChange={e => handleSetAllRebate(e.target.value)} />
                                </Form.Item>
                              </Col>
                              <Col span={24} className="center">
                                <Button size="small" onClick={() => setIsOpenRebatePopover(false)}>
                                  {i18n.t('close')}
                                </Button>
                              </Col>
                            </Row>
                          }>
                          <Button type="primary" disabled={isAllDisabled || !$p.includes('40204')}
                            onClick={() => setIsOpenRebatePopover(!isOpenRebatePopover)}>
                            {i18n.t('batchSetting')}
                          </Button>
                        </Popover>
                      </Col>
                    </Row>
                    <Table
                      bordered
                      className="mt-1"
                      size="small"
                      columns={[
                        {
                          key: 1,
                          title: i18n.t('gameCategory'),
                          dataIndex: 'game',
                          className: 'size-12 color-03',
                          width: '25%',
                          render: (_, { game, name }) => `${game} % (max：${$f(parentRebate[name], 2)})`
                        },
                        {
                          key: 2,
                          title:
                            <>
                              <span className="require">*</span>{i18n.t('rebateRate')} %
                            </>,
                          className: 'size-12',
                          width: '25%',
                          render: (_, { name }) => (
                            <Form.Item name={name} rules={verify({ max: $f(parentRebate[name], 2) })}>
                              <Input className="size-12" disabled={isAllDisabled || !$p.includes('40204')} />
                            </Form.Item>
                          )
                        },
                      ]}
                      dataSource={[
                        {
                          key: 1,
                          game: i18n.t('EGAMES'),
                          name: 'EGamesCommissionRate',
                        },
                        {
                          key: 2,
                          game: i18n.t('LIVE'),
                          name: 'LiveCommissionRate',
                        },
                        {
                          key: 3,
                          game: i18n.t('SPORTS'),
                          name: 'SportsCommissionRate',
                        },
                        {
                          key: 4,
                          game: i18n.t('ESPORTS'),
                          name: 'ESportsCommissionRate',
                        },
                        {
                          key: 5,
                          game: i18n.t('POKER'),
                          name: 'PokerCommissionRate',
                        },
                        {
                          key: 6,
                          game: i18n.t('FISHING'),
                          name: 'FishingCommissionRate',
                        },
                        {
                          key: 7,
                          game: i18n.t('COCKFIGHT'),
                          name: 'CockfightCommissionRate',
                        },
                        {
                          key: 8,
                          game: i18n.t('BINGO'),
                          name: 'LotteryCommissionRate',
                        },
                        {
                          key: 9,
                          game: i18n.t('SLOT'),
                          name: 'SlotCommissionRate',
                        }
                      ]}
                      pagination={false}
                    />
                  </Col>
                  <Col span={12}>
                    <Row justify="end">
                      <Col>
                        <Popover placement="bottomLeft" trigger="click" open={isOpenShareRatePopover} content={
                          <Row gutter={[12, 12]}>
                            <Col span={24} className="w-6">
                              <Form.Item name="batchSetting02">
                                <Input placeholder={`${i18n.t('enter')}`}
                                  onChange={e => handleSetAllShareRate(e.target.value)} />
                              </Form.Item>
                            </Col>
                            <Col span={24} className="center">
                              <Button size="small" onClick={() => setIsOpenShareRatePopover(false)}>
                                {i18n.t('close')}
                              </Button>
                            </Col>
                          </Row>
                        }>
                          <Button type="primary" disabled={isAllDisabled || !$p.includes('40204')}
                            onClick={() => setIsOpenShareRatePopover(!isOpenShareRatePopover)}>
                            {i18n.t('batchSetting')}
                          </Button>
                        </Popover>
                      </Col>
                    </Row>
                    <Table
                      className="mt-1"
                      bordered
                      size="small"
                      columns={[
                        {
                          key: 1,
                          title: i18n.t('gameCategory'),
                          dataIndex: 'game',
                          className: 'size-12 color-03',
                          width: '25%',
                          render: (_, { game, name }) => `${game} % (max：${$f(parentRebate[name], 2)})`
                        },
                        {
                          key: 2,
                          title:
                            <><span className="require">*</span>{i18n.t('commissionRateRate')} %</>,
                          className: 'size-12',
                          width: '25%',
                          render: (_, { name }) => (
                            <Form.Item name={name} rules={verify({ max: $f(parentRebate[name], 2) })}>
                              <Input className="size-12" disabled={isAllDisabled || !$p.includes('40204')} />
                            </Form.Item>
                          )
                        },
                      ]}
                      dataSource={[
                        {
                          key: 1,
                          game: i18n.t('EGAMES'),
                          name: 'EGamesRevenueShareRate',
                        },
                        {
                          key: 2,
                          game: i18n.t('LIVE'),
                          name: 'LiveRevenueShareRate',
                        },
                        {
                          key: 3,
                          game: i18n.t('SPORTS'),
                          name: 'SportsRevenueShareRate',
                        },
                        {
                          key: 4,
                          game: i18n.t('ESPORTS'),
                          name: 'ESportsRevenueShareRate',
                        },
                        {
                          key: 5,
                          game: i18n.t('POKER'),
                          name: 'PokerRevenueShareRate',
                        },
                        {
                          key: 6,
                          game: i18n.t('FISHING'),
                          name: 'FishingRevenueShareRate',
                        },
                        {
                          key: 7,
                          game: i18n.t('COCKFIGHT'),
                          name: 'CockfightRevenueShareRate',
                        },
                        {
                          key: 8,
                          game: i18n.t('BINGO'),
                          name: 'LotteryRevenueShareRate',
                        },
                        {
                          key: 9,
                          game: i18n.t('SLOT'),
                          name: 'SlotRevenueShareRate',
                        }
                      ]}
                      pagination={false}
                    />
                  </Col>
                </Row>
              </Spin>

            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;