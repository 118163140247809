import { Button, Col, Form, Input, Row, Select, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { PopupEditMerchant } from 'components/finance.component';
import { LayoutNav, LayoutPagination, MemberLevelTableDisplay } from 'components/layout.component';
import { MemberLevelSelect } from 'components/member.component';
import { MERCHANT_TYPE } from 'enum/promotion';
import { TRANSFER_SETTING_STATUS } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DepositMerchantList, DepositMerchantName, WithdrawMerchantList, WithdrawMerchantName } from 'services/depositMerchant.service';
import * as common from 'utils/common';

// 三方出入款管理

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();
  const navigate = useNavigate();
  const [form] = useForm();
  const [Id, setId] = useState<number>(0);
  const [type, setType] = useState<MERCHANT_TYPE>(MERCHANT_TYPE.入款);
  const [editPop, setEditPop] = useState(false);
  const [params, setParams] = useState<any>({});
  const [page, setPage] = useState<number[]>([1, 10]);
  const [listDepositData, setListDepositData] = useState<any>([]);
  const [listWithdraw, setListWithdraw] = useState<any>([]);
  const { data: DepositMerchant } = DepositMerchantName();
  const { data: DepositList, isValidating: isDepositLoading, mutate: refreshDeposit } = DepositMerchantList({
    ...params,
    PageIndex: page[0],
    PageSize: page[1]
  })
  const { data: WithdrawMerchant } = WithdrawMerchantName();
  const { data: WithdrawList, isValidating: isWithdrawLoading, mutate: refreshWithdraw } = WithdrawMerchantList({
    ...params,
    PageIndex: page[0],
    PageSize: page[1]
  })
  const [merchantData, setMerchantData] = useState<any>(DepositMerchant);

  useEffect(() => {
    if ($p.includes('21201')) {
      setType(MERCHANT_TYPE.入款);
      setMerchantData(DepositMerchant)
    } else if ($p.includes('21001')) {
      setType(MERCHANT_TYPE.出款);
      setMerchantData(WithdrawMerchant)
    }
  }, [$p, DepositMerchant, WithdrawMerchant]);

  useEffect(() => {
    if (DepositList) {
      setListDepositData(DepositList?.Data)
    }
    if (WithdrawList) {
      setListWithdraw(WithdrawList?.Data);
    }
  }, [DepositList, WithdrawList]);


  const handleMerchantType = (type: number) => {
    setType(type);
    onClear();
    mutate();

    switch (type) {
      case MERCHANT_TYPE.入款:
        setListDepositData(DepositList?.Data)
        setMerchantData(DepositMerchant)
        break;
      case MERCHANT_TYPE.出款:
        setListWithdraw(WithdrawList?.Data)
        setMerchantData(WithdrawMerchant)
        break;
      default:
        break;
    }
  }

  const search = (formData: any) => {
    setPage([1, page[1]]);
    setParams({
      ...params,
      Name: formData.transferType01,
      CustomName: formData.displayName,
      LevelId: formData.LevelId
    })
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setPage([1, 10]);
  }

  const mutate = () => {
    refreshDeposit();
    refreshWithdraw();
  }

  const colorChange = (type: number) => {
    switch (type) {
      case TRANSFER_SETTING_STATUS.開啟:
        return 'color-pass'
      case TRANSFER_SETTING_STATUS.關閉:
        return 'color-reject'
      case TRANSFER_SETTING_STATUS.刪除:
        return 'color-padding'
    }
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2">
        <Form form={form} onFinish={search}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  {
                    $p.includes('21201') && !$p.includes('21001') &&
                    <Select
                      className="w-12"
                      defaultValue={MERCHANT_TYPE.入款}
                      options={[
                        { value: MERCHANT_TYPE.入款, label: i18n.t('deposit') },
                      ]}
                      onChange={handleMerchantType}
                    />
                  }
                  {
                    $p.includes('21001') && !$p.includes('21201') &&
                    <Select
                      className="w-12"
                      defaultValue={MERCHANT_TYPE.出款}
                      options={[
                        { value: MERCHANT_TYPE.出款, label: i18n.t('withdrawal') },
                      ]}
                      onChange={handleMerchantType}
                    />
                  }
                  {
                    $p.includes('21001') && $p.includes('21201') &&
                    <Select
                      className="w-12"
                      defaultValue={MERCHANT_TYPE.入款}
                      options={[
                        { value: MERCHANT_TYPE.入款, label: i18n.t('deposit') },
                        { value: MERCHANT_TYPE.出款, label: i18n.t('withdrawal') },
                      ]}
                      onChange={handleMerchantType}
                    />
                  }
                </Col>
                <Col>
                  <Form.Item name="transferType01" className="w-12">
                    <Select
                      placeholder={i18n.t('selectThirdPartyName')}
                      options={
                        merchantData?.map((item: any) => (
                          { value: item.ThirdMerchantName, label: item.ThirdMerchantName }
                        ))
                      }
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="displayName">
                    <Input placeholder={`${i18n.t('displayName')}`} />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="LevelId">
                    <MemberLevelSelect name="LevelId" form={form} />
                  </Form.Item>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Table
                size="middle"
                loading={isWithdrawLoading || isDepositLoading}
                scroll={{ x: 1500 }}
                dataSource={
                  type === MERCHANT_TYPE.入款 && $p.includes('21201') ?
                    listDepositData?.map((item: DepositMerchantList) => ({ key: item.Id, ...item }))
                    : (type === MERCHANT_TYPE.出款 && $p.includes('21001') ?
                      listWithdraw?.map((item: DepositMerchantList) => ({ key: item.Id, ...item }))
                      : []
                    )
                }
                columns={[
                  {
                    dataIndex: 'Position',
                    title: i18n.t('sort'),
                    fixed: 'left',
                    align: 'center',
                    width: 50
                  },
                  {
                    dataIndex: 'Name',
                    title: i18n.t('thirdPartyName'),
                    fixed: 'left',
                    width: 180
                  },
                  {
                    dataIndex: 'CustomName',
                    title: i18n.t('displayName'),
                    width: 180
                  },
                  {
                    dataIndex: 'GatewayName',
                    title: i18n.t('channelName'),
                    width: 180
                  },
                  {
                    dataIndex: 'LevelName',
                    title: i18n.t('applicableMemberLevel'),
                    width: 150,
                    render: (val) => <MemberLevelTableDisplay levelName={val} />
                  },
                  {
                    dataIndex: 'Rate',
                    title: i18n.t('paymentFeePercentageTransactionFee'),
                    align: 'right',
                    width: 150,
                    render: (val, { HandlingFee }) => <>{`${common.convertedToPercentage01(val)} %`} / {common.toFormatNumber(HandlingFee)}</>
                  },
                  {
                    dataIndex: 'TotalCount',
                    title: i18n.t('totalTransactionsToday'),
                    width: 150,
                    align: 'right',
                    render: (val, record) => (
                      <>
                        {
                          $p.includes('21301') && type === MERCHANT_TYPE.入款
                            ? <Button type="link" onClick={() => {
                              navigate(`/finance/payment-depositTotal/${record.GatewayId}`);
                            }}>
                              {common.toFormatNumber(val, 0)}
                            </Button>
                            : (
                              $p.includes('21101') && type === MERCHANT_TYPE.出款
                                ? <Button type="link" onClick={() => {
                                  navigate(`/finance/payment-withdrawTotal/${record.GatewayId}`);
                                }}>
                                  {common.toFormatNumber(val, 0)}
                                </Button>
                                : common.toFormatNumber(val, 0)
                            )
                        }
                      </>
                    )
                  },
                  {
                    dataIndex: 'TotalAmount',
                    title: type === MERCHANT_TYPE.入款 ? i18n.t('totalDepositsToday') : i18n.t('totalWithdrawalToday'),
                    width: 150,
                    align: 'right',
                    render: (val, record) => (
                      <>
                        {
                          $p.includes('21301') && type === MERCHANT_TYPE.入款
                            ? <Button type="link" onClick={() => {
                              navigate(`/finance/payment-depositTotal/${record.GatewayId}`);
                            }}>
                              {common.toFormatNumber(val, 0)}
                            </Button>
                            : (
                              $p.includes('21101') && type === MERCHANT_TYPE.出款
                                ? <Button type="link" onClick={() => {
                                  navigate(`/finance/payment-withdrawTotal/${record.GatewayId}`);
                                }}>
                                  {common.toFormatNumber(val, 0)}
                                </Button>
                                : common.toFormatNumber(val, 0)
                            )
                        }
                      </>
                    )
                  },
                  {
                    dataIndex: 'Status',
                    title: i18n.t('status'),
                    width: 100,
                    render: (val) => <div className={colorChange(val)}>{TRANSFER_SETTING_STATUS[val]}</div>
                  },
                  {
                    title: i18n.t('operation'),
                    fixed: 'right',
                    width: 100,
                    render: (_, record) => (
                      <>
                        {
                          $p.includes('21203') && type === MERCHANT_TYPE.入款 &&
                          <Button type="link" onClick={() => {
                            setEditPop(true);
                            setId(record.key);
                          }}>{i18n.t('edit')}</Button>
                        }
                        {
                          $p.includes('21003') && type === MERCHANT_TYPE.出款 &&
                          <Button type="link" onClick={() => {
                            setEditPop(true);
                            setId(record.key);
                          }}>{i18n.t('edit')}</Button>
                        }
                      </>
                    )
                  },
                ]}
                pagination={false}
              />
              <LayoutPagination total={DepositList ? DepositList.TotalRecord : 0} setPage={setPage} page={page} />
            </Col>
          </Row>
        </Form>
      </Content>
      <PopupEditMerchant isOpen={editPop} close={() => setEditPop(false)} id={Id} mutate={mutate} type={type} />
    </div >
  );
};

export default PageMain;