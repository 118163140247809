import {
  axiosGetAllData,
  axiosGetAuth,
  axiosPost,
  axiosPostAuth,
  baseURL,
  useFetchDataCommon
} from "services";

export const postLogin = (data: any) => axiosPost(`/common/auth/login`, data);

export const FetchMember = () => useFetchDataCommon(axiosGetAuth, `${baseURL}/member/profile`);
export const FetchMemberList = (params: any, allow?: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/member/list`, params, allow);
export const FetchMemberDetail = (params: any, allow: boolean) => useFetchDataCommon(axiosGetAuth, `${baseURL}/member/one`, params, allow);
export const FetchMemberWithdrawInfo = (params: any, allow: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/member/withdraw/info`, params, allow);
// 會員餘額
export const GetMemberBalanceInfo = (params: any, allow: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/member/balance/info`, params, allow);
// 會員注單
export const GetMemberCashlogList = (params: any, allow: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/member/cashlog/list`, params, allow);
export const CreateMember = (data: any) => axiosPostAuth(`${baseURL}/member/create`, data);
export const FetchMemberLoginHistory = (params: any, allow: boolean) => useFetchDataCommon(axiosGetAuth, `${baseURL}/member/login/history`, params, allow);

export const FetchBalance = () => useFetchDataCommon(axiosGetAuth, `${baseURL}/member/balance`);
export const FetchcContactList = (params: any, allow: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/member/contact/list`, params, allow);
export const CreateContact = (data: any) => axiosPostAuth(`${baseURL}/member/contact/create`, data);
export const PostMemberUpdate = (data: any) => axiosPostAuth(`${baseURL}/member/update`, data);
export const PostMemberUpdateStatus = (data: any) => axiosPostAuth(`${baseURL}/member/update/status`, data);
export const PostMemberUpdateFreeze = (data: any) => axiosPostAuth(`${baseURL}/member/update/freeze`, data);
export const ModifyContact = (data: any) => axiosPostAuth(`${baseURL}/member/contact/update`, data);
export const DeleteContact = (data: any) => axiosPostAuth(`${baseURL}/member/contact/delete`, data);

export const FetchMemberBlockipList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/blockip/member/list`, params);
export const FetchMemberBlockipInfo = (params: any, allow: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/blockip/member/info`, params, allow);
export const BlockIpList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/blockip/list`, params);
export const BlockIpOne = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/blockip/one`, params);

export const PostBlockipCreate = (data: any) => axiosPostAuth(`${baseURL}/blockip/create`, data);
export const PostBlockipDelete = (data: any) => axiosPostAuth(`${baseURL}/blockip/delete`, data);
export const PostBlockipUpdate = (data: any) => axiosPostAuth(`${baseURL}/blockip/update`, data);

export const PostMemberStatusUpdatebyip = (data: any) => axiosPostAuth(`${baseURL}/member/status/updatebyip`, data);

export const GetBonusMemberLevel = (params?: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/memberlevel/bonus/singin/list`, params);