import i18n from "i18next";
import Cookies from "js-cookie";
import { initReactI18next } from "react-i18next";
import enus from './locales/en-US.json';
import zhchs from './locales/zh-CHS.json';
import zhcht from "./locales/zh-CHT.json";

const resources = {
  "zh-TW": { translation: zhcht },
  "zh-CN": { translation: zhchs },
  "en-US": { translation: enus },
};

i18n.use(initReactI18next).init({
  resources,
  lng: Cookies.get('lang') || document.documentElement.lang,
  interpolation: {
    escapeValue: false,
  },
  debug: false,
});

export default i18n;
