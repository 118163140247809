import React, { useState } from "react";

export const StoreContext = React.createContext<any | null>(null);
export const StoreProvider = ({ children }: any) => {

  const store = {
    // 總代搜尋條件
    useSearchAgent: useState(null),
    // 總代展開項目
    useSaExpand: useState(null),
    useSaPageIndex: useState(null),
    useAgentList: useState(null),
    useSearchMember: useState(null),
    // 線下出入款管理帶值
    useBankName: useState(null),
    useBankDisplayName: useState(null),
  };

  return (
    <StoreContext.Provider value={store}>
      {children}
    </StoreContext.Provider>
  );
};