export enum SOCKET_ON {
  連線 = 'connect',
  斷線 = 'disconnect',
  未登入 = 'unauthorized',
  連線錯誤 = 'connect_error',
  客服訊息 = 'service_message',
  私人訊息 = 'private_message',
  客服通知 = 'service_notification',
  建立客服服務 = 'SESSION_CREATED',
  客服接聽服務 = 'SESSION_STATUS_START',
  客服轉接服務 = 'SESSION_STATUS_TRANSFER',
  客服結束對話 = 'SESSION_STATUS_END'
} 

export enum ROLE {
  訪客 = -1,
  會員 = 0,
  代理 = 1,
  客服 = 2,
  總代理 = 3
}
