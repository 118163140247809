import { Button, Card, Col, Form, Image, Input, Row, message } from "antd";
import { LayoutNav } from "components/layout.component";
import { RESPONSE_CODE } from "constants/response";
import { RESPONSE_TYPE } from "enum/state";
import useIcon from "hooks/icon.hook";
import i18n from "i18n";
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { PostSelfPasswordUpdate } from "services/account.service";

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  const icon = useIcon();
  const [loading, isLoading] = useState(false);

  const onSubmit = async (formData: any) => {
    if (formData.NewPassword !== formData.ConfirmNewPassword) {
      message.error(i18n.t('incorrectPasswordConfirmation'));
      return
    }
    isLoading(true)
    const send = {
      Password: formData.Password,
      NewPassword: formData.NewPassword,
      ConfirmNewPassword: formData.ConfirmNewPassword,
    };
    const response = await PostSelfPasswordUpdate(send);
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('modificationSuccessful'));
      navigate('/');
    } else {
      const msg = RESPONSE_CODE[response.Message as keyof typeof RESPONSE_CODE];
      message.error(msg || i18n.t('processingFailed'));
      isLoading(false)
    }
  };

  return (
    <>
      <LayoutNav hidden />
      <Row
        className="login-background-image"
        justify="center"
        align="middle"
        style={{ height: "100vh" }}
      >
        <Card className="login-card">
          <Row justify="center" align="middle"
            style={{ position: "absolute", zIndex: 1, top: '-20%', left: '50%', transform: 'translateX(-50%)' }}>
            <Col>
              <Image src={icon.logo01} preview={false} />
            </Col>
          </Row>
          <Form size="middle" onFinish={onSubmit}>
            <Form.Item style={{ padding: '10px 0', textAlign: 'center', color: '#666' }}>
              <div>{i18n.t('modificationPassword')}</div>
            </Form.Item>
            <Form.Item
              name="Password"
              rules={[{ required: true, message: `${i18n.t('currentPasswordRequired')}` }]}
            >
              <Input.Password placeholder={`${i18n.t('currentPassword')}`} />
            </Form.Item>
            <Form.Item
              className="mt-1"
              name="NewPassword"
              rules={[{ required: true, message: `${i18n.t('newPasswordRequired')}` }]}
            >
              <Input.Password placeholder={`${i18n.t('newPassword')}`} />
            </Form.Item>
            <Form.Item
              className="mt-1"
              name="ConfirmNewPassword"
              rules={[
                { required: true, message: `${i18n.t('confirmNewPasswordRequired')}` },
              ]}
            >
              <Input.Password placeholder={`${i18n.t('confirmNewPassword')}`} />
            </Form.Item>
            <Form.Item className="center mt-2" name="modify-password">
              <Button
                htmlType="submit"
                loading={loading}
                className="w-10 login-btn"
                style={{ background: '#DABF8D' }}
                size="large"
              >
                {i18n.t('confirm')}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Row>
    </>
  );
};

export default PageMain;
