import PageFront from "pages/admin/front";
import PageFrontCarousel from "pages/admin/front/carousel";
import PageFrontPopup from "pages/admin/front/popup";
import PageFrontSub from "pages/admin/front/sub";

export const frontModule = [
  { path: '/front', Component: PageFront },
  { path: '/front/sub', Component: PageFrontSub },
  { path: '/front/carousel', Component: PageFrontCarousel },
  { path: '/front/popup', Component: PageFrontPopup },
]