import { Button, Col, Form, Image, Input, Modal, Row, Table, message } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import { useForm } from 'antd/lib/form/Form';
import { RESPONSE_CODE_MEMBER } from 'constants/response';
import { MODE, RESPONSE_TYPE, VERIFICATION_STATE } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import { useEffect, useState } from 'react';
import { GetKycticketOne, ModifyVerificationStatus, PostKycticketUpdate, PostKycticketUpsert } from 'services/kycticket.service';
import { UploadImage } from './layout.component';

// 列表
export const ViewVerificationList = ({ data, mutate }: {
  data: {
    State: string;
    Data: VerificationList[];
    TotalRecord: number;
  };
  mutate: any
}) => {
  const { permissionCode: $p } = usePerm();
  const [id, setId] = useState<number>(0);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [popMode, setPopMode] = useState(MODE.新增);

  // 狀態顏色
  const stateColor = (state: number) => {
    switch (state) {
      case VERIFICATION_STATE.通過:
        return 'color-pass'
      case VERIFICATION_STATE.拒絕:
        return 'color-reject'
      case VERIFICATION_STATE.待審核:
        return 'color-padding'
      default: return ''
    };
  };

  return (
    <>
      <Row className="mb-1">
        {
          $p.includes('10803') &&
          <Col>
            <Button type="primary" onClick={() => {
              setPopMode(MODE.新增);
              setId(0);
              setIsOpenModal(true);
            }}>{i18n.t('add')}</Button>
          </Col>
        }
      </Row>
      <Table
        size="middle"
        scroll={{ x: 1500 }}
        dataSource={
          data &&
          data.Data.map(item => ({
            key: item.Id,
            MemberAccount: item.MemberAccount,
            Name: item.Name,
            CreateDate: item.CreateDate,
            AuditedTime: item.AuditedTime,
            OperatorAccount: item.OperatorAccount,
            Status: item.Status
          }))}
        columns={[
          {
            key: 1,
            dataIndex: 'MemberAccount',
            title: i18n.t('memberAccount'),
            className: 'size-12',
            width: 200,
          },
          {
            key: 2,
            dataIndex: 'Name',
            title: i18n.t('realName'),
            className: 'size-12',
            width: 200,
          },
          {
            key: 3,
            dataIndex: 'CreateDate',
            title: i18n.t('createTime'),
            className: 'size-12',
            width: 200,
          },
          {
            key: 4,
            dataIndex: 'AuditedTime',
            title: i18n.t('auditTime'),
            className: 'size-12',
            width: 200,
            render: (_, { AuditedTime }) => AuditedTime === "0000-00-00 00:00:00" ? '-' : AuditedTime
          },
          {
            key: 5,
            title: i18n.t('status'),
            className: 'size-12',
            width: 250,
            render: (_, record) => (
              <>
                <div className={stateColor(record.Status)}>{VERIFICATION_STATE[record.Status]}</div>
                {
                  record.OperatorAccount &&
                  <div>{`${i18n.t('from')} ${record.OperatorAccount} ${VERIFICATION_STATE[record.Status]}`}</div>
                }
              </>
            )
          },
          {
            key: 6,
            title: i18n.t('operation'),
            className: 'size-12',
            width: 100,
            fixed: 'right',
            render: (_, record) => <>
              {
                record.Status === VERIFICATION_STATE.待審核 && $p.includes('10804') &&
                <Button className="size-12" type="link" onClick={() => {
                  setPopMode(MODE.審核);
                  setId(record.key);
                  setIsOpenModal(true);
                }}>{i18n.t('audit')}</Button>
              }
              {
                record.Status === VERIFICATION_STATE.拒絕 && $p.includes('10805') &&
                <Button className="size-12" type="link" onClick={() => {
                  setPopMode(MODE.編輯);
                  setId(record.key);
                  setIsOpenModal(true);
                }}>{i18n.t('edit')}</Button>
              }
              {
                record.Status === VERIFICATION_STATE.通過 &&
                <Button className="size-12" type="link" onClick={() => {
                  setPopMode(MODE.詳情);
                  setId(record.key);
                  setIsOpenModal(true);
                }}>{i18n.t('detail')}</Button>
              }
            </>
          },
        ]}
        pagination={false}
      />
      <PopupVerificationAccount isOpen={isOpenModal} close={() => setIsOpenModal(false)} mutate={mutate} mode={popMode} id={id} />
    </>
  )
}

// 新增實名驗證
export const PopupVerificationAccount = ({ isOpen, close, mutate, id, mode }: any) => {
  const [form] = useForm();
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);
  const [approve, setApprove] = useState(false);

  const { data: memberData } = GetKycticketOne({
    Id: id
  }, !!id);
  useEffect(() => {
    if (memberData && isOpen) {
      form.setFieldsValue({
        Account: memberData.MemberAccount,
        IDNumber: memberData.IDNumber,
        Name: memberData.Name,
      })
      const ary: any = []
      memberData.Photo_1 && ary.push({
        uid: memberData.Photo_1,
        name: memberData.Photo_1,
        url: memberData.Photo_1
      })
      memberData.Photo_2 && ary.push({
        uid: memberData.Photo_2,
        name: memberData.Photo_2,
        url: memberData.Photo_2
      })
      setImageData(ary)
    }
  }, [memberData, isOpen]);

  const onFinish = async (formData: any) => {
    const send: any = {
      IDNumber: formData.IDNumber,
      Name: formData.Name,
      Photo_1: imageData[0] ? imageData[0].url : '',
      Photo_2: imageData[1] ? imageData[1].url : '',
      Photo_3: ''
    }

    let response: any = {};
    let successMsg = '';
    switch (mode) {
      case MODE.新增:
        send.Account = formData.Account;
        response = await PostKycticketUpsert(send)
        successMsg = i18n.t('addSuccess')
        break;
      case MODE.審核:
        response = await ModifyVerificationStatus({
          Id: id,
          Status: approve ? VERIFICATION_STATE.通過 : VERIFICATION_STATE.拒絕
        })
        successMsg = approve ? i18n.t('approved') : i18n.t('rejected');
        break;
      case MODE.編輯:
        send.Id = id;
        response = await PostKycticketUpdate(send);
        successMsg = i18n.t('addSuccess')
        break;
      default:
        break;
    }
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(successMsg);
      onClose();
      mutate();
    } else {
      message.error(RESPONSE_CODE_MEMBER[response.Message as keyof typeof RESPONSE_CODE_MEMBER]);
    }
  };

  const onClose = () => {
    form.resetFields();
    setImageData([]);
    close();
  };

  let title = '';
  switch (mode) {
    case MODE.新增:
      title = i18n.t('add');
      break;
    case MODE.審核:
      title = i18n.t('audit');
      break;
    case MODE.編輯:
      title = i18n.t('edit');
      break;
    case MODE.詳情:
      title = i18n.t('detail');
      break;
    default:
      break;
  }

  return (
    <Modal
      title={title}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="Account" label={i18n.t('account')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('inputAccount')}`} disabled={mode === MODE.審核 || mode === MODE.編輯 || mode === MODE.詳情} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="IDNumber" label={i18n.t('idNum')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('enterIdNumber')}`} disabled={mode === MODE.審核 || mode === MODE.詳情} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Name" label={i18n.t('name')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('enterName')}`} disabled={mode === MODE.審核 || mode === MODE.詳情} />
            </Form.Item>
          </Col>
          <Col span={24}>
            {(mode === MODE.新增 || mode === MODE.編輯) && <>
              <Form.Item name="Photo_1" valuePropName="fileList" label={i18n.t('uploadDocuments')} 
                rules={[{ required: true, message: `${i18n.t('uploadImage')}` }]}>
                <UploadImage
                  form={form}
                  name="Photo_1"
                  url={'/upload/images/public/depositmerchant'}
                  imageData={imageData}
                  maxCount={2}
                  setImageData={setImageData}
                  disabled={mode === MODE.審核 || mode === MODE.詳情}
                />
              </Form.Item>
              <div className="color-03 size-12">
                {i18n.t('onlyJpgOrPngFiles')}
                {/* <span className="require">{i18n.t('imageMobileVersionWidth414pxHeight170px')}</span> */}
                {i18n.t('allowedAndSizeNotExceed10MB')}
              </div>
            </>}
            {(mode === MODE.審核 || mode === MODE.詳情) && imageData[0] && <>
              <Row gutter={[12, 0]}>
                <Col span={24}>{i18n.t('uploadDocuments')}</Col>
                {imageData[0] && <Col><Image src={imageData[0].url} width={180} height={120} /></Col>}
                {imageData[1] && <Col><Image src={imageData[1].url} width={180} height={120} /></Col>}
              </Row>
            </>}
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              {
                (mode === MODE.新增 || mode === MODE.編輯) &&
                <>
                  <Col><Button onClick={onClose}>{i18n.t('cancel')}</Button></Col>
                  <Col><Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button></Col>
                </>
              }
              {
                mode === MODE.詳情 &&
                <Button type="primary" onClick={onClose}>{i18n.t('close')}</Button>
              }
              {
                mode === MODE.審核 && <>
                  <Col><Button type="primary" danger onClick={() => {
                    setApprove(false);
                    form.submit();
                  }}>{i18n.t('reject')}</Button></Col>
                  <Col><Button type="primary" onClick={() => {
                    setApprove(true);
                    form.submit();
                  }}>{i18n.t('pass')}</Button></Col>
                </>
              }
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal >
  )
}
