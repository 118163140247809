import { Col, Row, Select, Spin, Switch, Table, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { LayoutNav, LayoutTabMember } from 'components/layout.component';
import { PopupStatusModal } from 'components/wallet.component';
import { RESPONSE_CODE_MEMBER_PROVIDER_STATUS } from 'constants/response';
import { RESPONSE_TYPE } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FetchMemberDetail } from 'services/member.service';
import { MemberProvider, UpdateMemberProvider } from 'services/memberprovidersetting.service';

// 會員編輯 > 遊戲商資料

interface ProviderInfo {
  Id: number;
  MemberId: number;
  ProviderId: number;
  ProviderName: string;
  CategoryCode: string;
  Status: number;
}

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();
  const { id } = useParams();
  const [isOpenStatusModal, setIsOpenStatusModal] = useState(false);
  const [modalType, setModalType] = useState<number | null>();
  const [data, setData] = useState<ProviderInfo[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { data: MemberProviderList, mutate, isValidating } = MemberProvider({ MemberId: id });
  const { data: member } = FetchMemberDetail({ Id: id }, !!id);

  useEffect(() => {
    if (MemberProviderList) {
      setData(MemberProviderList);
    }
  }, [MemberProviderList])

  const handleStatus = async (checked: boolean, item: any) => {
    setIsLoading(true);

    const response = await UpdateMemberProvider({
      MemberId: member.Id,
      Id: item.Id || null,
      ProviderId: item.ProviderId,
      Status: checked ? 1 : 0
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      mutate();
    } else {
      message.error(RESPONSE_CODE_MEMBER_PROVIDER_STATUS[response.Message as keyof typeof RESPONSE_CODE_MEMBER_PROVIDER_STATUS]);
    }
    setIsLoading(false);

  };
  const handleAllStatus = (option: number) => {
    setModalType(option);
    setIsOpenStatusModal(true);
  }

  const [columns, setColumns] = useState<any>([]);
  useEffect(() => {
    if ($p) {
      setColumns([
        {
          dataIndex: 'ProviderCode',
          title: i18n.t('gameProvider'),
          render: (val: any, { CategoryCode }: any) => `${val}-${i18n.t(CategoryCode)}`
        },
        {
          dataIndex: 'MemberId',
          title: i18n.t('gameProviderAccount'),
          render: (val: any) => member?.NoCode
        },
        {
          dataIndex: 'Status',
          title: i18n.t('operation'),
          width: 150,
          render: (val: any, record: any) => (
            <Row justify="start" align="middle" gutter={12}>
              <Col>
                <Switch onChange={c => handleStatus(c, record)} checked={val} loading={isLoading}
                  disabled={!$p.includes('10302')} />
              </Col>
            </Row>
          )
        },
      ])
    }
  }, [$p]);

  return (
    <div id="container">
      <LayoutNav id={member ? member.Id : 0} account={member ? member.Account : ''} />
      <Content className="pr-2 pl-2 pb-2">
        <LayoutTabMember activeKey="2" id={member ? member.Id : 0} />
        <Row>
          <Row gutter={10}>
            {
              $p.includes('10303') && $p.includes('10302') &&
              <Col>
                <Select
                  className="w-12"
                  placeholder={i18n.t('gameStatus')}
                  onSelect={handleAllStatus}
                  options={[
                    { value: 1, label: i18n.t('enableAllGames') },
                    { value: 0, label: i18n.t('disableAllGames') }
                  ]}
                />
              </Col>
            }
          </Row>
          {/* 遊戲錢包資料 */}
          <Col span={24} className="mt-1">
            <Spin spinning={isValidating}>
              <Row>
                <Col span={8}>
                  <Table
                    size="small"
                    pagination={false}
                    dataSource={data?.filter((_, i) => i % 3 === 0).map(item => ({ ...item, key: item.Id }))}
                    columns={columns}
                  />
                </Col>
                <Col span={8}>
                  <Table
                    size="small"
                    className="border-04"
                    pagination={false}
                    dataSource={data?.filter((_, i) => i % 3 === 1).map(item => ({ ...item, key: item.Id }))}
                    columns={columns}
                  />
                </Col>
                <Col span={8}>
                  <Table
                    size="small"
                    pagination={false}
                    dataSource={data?.filter((_, i) => i % 3 === 2).map(item => ({ ...item, key: item.Id }))}
                    columns={columns}
                  />
                </Col>
              </Row>
            </Spin>
          </Col>
        </Row>
      </Content>
      <PopupStatusModal isOpen={isOpenStatusModal} close={() => setIsOpenStatusModal(false)}
        id={member ? member.Id : 0} type={modalType} mutate={mutate}
      />
    </div >
  );
};

export default PageMain;