import { Content } from 'antd/es/layout/layout';
import { ViewAgentCommissionReport, ViewAgentRevenueshareReport } from 'components/agent.component';
import { LayoutNav } from 'components/layout.component';
import React from 'react';
import { useParams } from 'react-router-dom';
import { GetAgentOne } from 'services/agent.service';

const PageMain: React.FC = () => {

  const { id = '' } = useParams();
  const { data: agent } = GetAgentOne({ Id: id }, !!id);

  return (
    <div id="container">
      <LayoutNav account={agent?.Account} />
      <Content className="ph-2 pt-1">
        <ViewAgentCommissionReport id={id} cycle={agent?.CommissionSettleCycle} />
        <ViewAgentRevenueshareReport id={id} cycle={agent?.RevenueShareSettleCycle} />
      </Content>
    </div>
  );
};

export default PageMain;