import {
  axiosGetAllData,
  axiosGetAuth,
  axiosPostAuth,
  baseURL,
  useFetchDataCommon
} from "services";


export const GameList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/game/list`, params);
export const GameOpenList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/game/cc/list`, params);
export const GameOne = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/game/one`, params);
export const GameUpdate = (data: any) => axiosPostAuth(`${baseURL}/game/update`, data);
export const GameUpdateForCC = (data: any) => axiosPostAuth(`${baseURL}/game/cc/update`, data);

export const BlockIpGameList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/blockip/member/game/list`, params);
export const GameIpMemberNote = (data: any) => axiosPostAuth(`${baseURL}/memo/member/create`, data);
export const GameOnLuck = (data: any) => axiosPostAuth(`${baseURL}/blockip/create`, data);
export const UpdateGameMulti = (params: any) => axiosPostAuth(`${baseURL}/game/multi/update`, params);
