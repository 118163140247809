import { Button, Col, Form, Image, Input, Modal, Row, Space, Switch, Table, Tag, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { ColumnsType } from "antd/es/table";
import TextArea from "antd/lib/input/TextArea";
import { RESPONSE_CODE_GAME_IP, RESPONSE_CODE_IP, RESPONSE_CODE_MEMBER } from "constants/response";
import { RESPONSE_TYPE } from "enum/state";
import useIcon from "hooks/icon.hook";
import usePerm from "hooks/permission.hook";
import i18n from "i18n";
import { useEffect, useState } from "react";
import { GameIpMemberNote, GameOnLuck } from "services/game.service";
import { BlockIpOne, FetchMemberBlockipInfo, PostBlockipCreate, PostBlockipDelete, PostBlockipUpdate, PostMemberStatusUpdatebyip, PostMemberUpdateFreeze } from "services/member.service";
import * as common from 'utils/common';
import { LayoutPagination } from "./layout.component";

// 會員IP
export const PopupIpInfoModal = ({ isOpen, ipInfo, close, mutate }: any) => {
  const { permissionCode: $p } = usePerm();
  const icon = useIcon();
  const [page, setPage] = useState<number[]>([1, 10]);
  const { data, isValidating, mutate: RefreshInfo } = FetchMemberBlockipInfo({
    IP: ipInfo?.IP,
    PageIndex: page[0],
    PageSize: page[1]
  }, !!ipInfo)

  useEffect(() => {
    if (isOpen) {
      RefreshInfo()
    }
  }, [isOpen]);

  const onUpdateStatus = async (mode: number, val?: boolean, id?: number) => {
    switch (mode) {
      case 1:
        // 凍結此ip
        const apiResponse01 = await PostMemberStatusUpdatebyip({
          IP: ipInfo?.IP,
          Status: 2
        })
        if (apiResponse01.State === RESPONSE_TYPE.成功) {
          message.success(i18n.t('updateSuccess'));
          RefreshInfo();
          mutate();
        } else {
          message.error(RESPONSE_CODE_MEMBER[apiResponse01.Message as keyof typeof RESPONSE_CODE_MEMBER]);
        }
        break;
      case 2:
        const apiResponse02 = await PostMemberUpdateFreeze({
          Ids: [id],
          freeze: val ? 0 : 1
        })
        if (apiResponse02.State === RESPONSE_TYPE.成功) {
          message.success(i18n.t('updateSuccess'));
          RefreshInfo();
          mutate();
        } else {
          message.error(RESPONSE_CODE_MEMBER[apiResponse02.Message as keyof typeof RESPONSE_CODE_MEMBER]);
        }
        break;
    }
  }

  const onLockIp = async () => {
    const response = await PostBlockipCreate({
      BlockIP: ipInfo?.IP,
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      mutate();
    } else {
      message.error(RESPONSE_CODE_IP[response.Message as keyof typeof RESPONSE_CODE_IP]);
    }
  }

  const unlockIp = async () => {
    const response = await PostBlockipDelete({
      IP: ipInfo?.IP,
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      mutate();
    } else {
      message.error(RESPONSE_CODE_IP[response.Message as keyof typeof RESPONSE_CODE_IP]);
    }
  }

  const onClose = () => {
    close();
  }

  return (
    <Modal
      title={
        <Row align="middle" gutter={5}>
          <Col>
            <Tag className={`w-3 ${common.numColor02(ipInfo?.MemberCount)}`} style={{ textAlign: 'center' }} bordered={false}>
              {ipInfo?.MemberCount}
            </Tag>
          </Col>
          <Col className="size-14 color-04">{ipInfo?.IP}</Col>
          <Col className="ml-1 color-04">
            {data?.Data?.length !== 0 ? i18n.t('open') : i18n.t('closed')}
          </Col>
        </Row>
      }
      open={isOpen}
      onCancel={onClose}
      centered
      width={1000}
      footer={
        <Row justify="center" >
          <Button onClick={close}>{i18n.t('close')}</Button>
        </Row>
      }
    >
      <Row className="mt-2" gutter={[12, 12]}>
        <Col span={24}>
          <Row align="middle">
            <Col>
              {
                $p.includes('11105') &&
                <Tag color="#F96868" onClick={() => onUpdateStatus(1)} style={{ cursor: 'pointer' }}>
                  {i18n.t('freezeAllMembersInList')}
                </Tag>
              }
            </Col>
            <Col>
              {
                $p.includes('11103') &&
                <Tag color="#F96868" onClick={onLockIp} style={{ cursor: 'pointer' }}>
                  {i18n.t('blockIP')}：{ipInfo?.IP}
                </Tag>
              }
            </Col>
            <Col>
              {
                $p.includes('11104') &&
                <Tag color="#F96868" onClick={unlockIp} style={{ cursor: 'pointer' }}>
                  {i18n.t('unblockIP')}：{ipInfo?.IP}
                </Tag>
              }
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Table
            loading={isValidating}
            size="small"
            dataSource={data?.Data?.map((item: any, i: number) => ({ key: i, ...item }))}
            columns={[
              {
                dataIndex: 'MemberAccount',
                title: i18n.t('account'),
                width: 150,
                // fixed: 'left'
              },
              {
                dataIndex: 'UPMemberAccount',
                title: `${i18n.t('recommendedMembers')}／${i18n.t('agent')}／${i18n.t('generalAgent')}`,
                width: 250,
                render: (val, record) => (
                  <>
                    <span>{record.UPMemberAccount || '-'}／</span>
                    <span>{record.AGAccount || '-'}／</span>
                    <span>{record.SAAccount || '-'}</span>
                  </>
                )
              },
              {
                title: `${i18n.t('online')}／${i18n.t('status')}`,
                width: 150,
                align: 'center',
                render: (_, { IsFreeze, IsOnline, MemberId }) => (
                  <Row align="middle" justify="center">
                    <Col className="center">
                      {icon.StatusTag(!!IsOnline)}
                    </Col>
                    <Col>
                      <Switch checked={!IsFreeze} onChange={newVal => onUpdateStatus(2, newVal, MemberId)}
                        disabled={!$p.includes('11105')} />
                    </Col>
                  </Row>
                )
              },
              {
                dataIndex: 'MemberName',
                title: i18n.t('name'),
                width: 200,
                render: (val) => val || '-'
              },
              {
                dataIndex: 'RegisterTime',
                title: `${i18n.t('createTime')}／${i18n.t('IP')}`,
                width: 200,
                render: (val, { RegisterIP }) => (
                  <Row>
                    <Col span={24} className="size-12">{val}</Col>
                    <Col span={24} className="size-12">{RegisterIP}</Col>
                  </Row>
                )
              },
              {
                dataIndex: 'LastLoginTime',
                title: `${i18n.t('lastLoginTime')}／${i18n.t('IP')}`,
                width: 200,
                render: (val, { LastLoginIp }) => (
                  <Row>
                    <Col span={24} className="size-12">{val}</Col>
                    <Col span={24} className="size-12">{LastLoginIp}</Col>
                  </Row>
                )
              },
            ]}
            pagination={false}
          />
          <LayoutPagination total={data?.TotalRecord || 0} setPage={setPage} page={page} />
        </Col>
      </Row>
    </Modal >
  )
}


export const PopupAddIpModal = ({ isOpen, close, mutate }: any) => {
  const [form] = useForm();

  const onFinish = async (formData: any) => {
    try {
      const response = await PostBlockipCreate({
        BlockIP: formData.ip,
        Remark: formData.remark
      })
      if (response.State === RESPONSE_TYPE.成功) {
        message.success(i18n.t('addSuccess'));
        onClose();
        mutate();
      } else {
        message.error(RESPONSE_CODE_IP[response.Message as keyof typeof RESPONSE_CODE_IP]);
      }
    } catch (error) {
      return;
    }
  }

  const onClose = () => {
    close();
    form.resetFields();
  }

  return (
    <Modal
      title={i18n.t('add')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={400}
      footer={false}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="ip" label={i18n.t('IP')} required
              rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('enterIP')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="remark" label={i18n.t('remark')}>
              <TextArea
                placeholder={`${i18n.t('enterRemark')}`}
                autoSize={{ minRows: 3, maxRows: 3 }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row gutter={[12, 12]} justify="center">
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
export const PopupEditIpModal = ({ isOpen, close, id, mutate }: any) => {
  const [form] = useForm();
  const { data: BlockInfo, mutate: mutateOne } = BlockIpOne({ Id: id });

  useEffect(() => {
    if (isOpen) {
      mutateOne();
    }
  }, [isOpen]);
  useEffect(() => {
    if (BlockInfo && isOpen) {
      form.setFieldsValue({
        ip: BlockInfo.IP,
        remark: BlockInfo.Remark
      })
    }
  }, [BlockInfo, isOpen]);

  const onEdit = async () => {
    const { ip, remark } = form.getFieldsValue();
    try {
      const response = await PostBlockipUpdate({
        Id: id,
        IP: ip,
        Remark: remark
      })
      if (response.State === RESPONSE_TYPE.成功) {
        message.success(i18n.t('addSuccess'));
        onClose();
        mutate();
      } else {
        message.error(RESPONSE_CODE_IP[response.Message as keyof typeof RESPONSE_CODE_IP]);
      }
    } catch (error) {
      return;
    }
  }

  const onClose = () => {
    form.resetFields();
    close();
  }

  return (
    <Modal
      title={i18n.t('edit')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="ip" label={i18n.t('IP')} required>
              <Input placeholder={`${i18n.t('enterIP')}`} disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="remark" label={i18n.t('remark')}>
              <TextArea
                placeholder={`${i18n.t('enterRemark')}`}
                autoSize={{ minRows: 3, maxRows: 3 }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" onClick={onEdit}>{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
// 有的
export const PopupDeleteIp = ({ isOpen, close, ip, mutate }: any) => {
  const icon = useIcon();

  const onDelete = async () => {

    const response = await PostBlockipDelete({ IP: ip })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('deleteSuccess'));
      onClose();
      mutate();
    } else {
      message.error(RESPONSE_CODE_IP[response.Message as keyof typeof RESPONSE_CODE_IP]);
    }

  }

  const onClose = () => {
    close();
  }

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={icon.icon12} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button onClick={close}>{i18n.t('cancel')}</Button>
          <Button type="primary" onClick={onDelete}>{i18n.t('confirm')}</Button>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmUnblockThisIP')}</div>
      </Space>
    </Modal>
  )
}
export const PopupInfoIpModal = ({ isOpen, close }: {
  isOpen: boolean;
  close: () => void;
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  //loading
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  }, [])

  //handle submit
  const handleSubmit = () => {
    setIsLoading(true);
    setTimeout(() => {
      close();
      message.success(i18n.t('editSuccess'));
      setIsLoading(false);
    }, 1000);
  }

  //get api
  const infoData: IpBlockInfo[] = [
    {
      key: 1,
      manager: '後台帳號',
      content: `IP：0.0.0.0 备注：观察`,
      date: '2022-06-02 13:00:50'
    },
    {
      key: 2,
      manager: '後台帳號',
      content: `IP：0.0.0.0 备注：观察`,
      date: '2022-06-01 14:00:00'
    },
  ]

  //table columns
  const infoColumns: ColumnsType<IpBlockInfo> = [
    {
      key: 1,
      title: i18n.t('administrator'),
      dataIndex: 'manager',
      className: 'size-12',
      width: '15%'
    },
    {
      key: 2,
      title: i18n.t('editContent'),
      dataIndex: 'content',
      className: 'size-12',
      width: '70%'
    },
    {
      key: 3,
      title: i18n.t('updateTime'),
      dataIndex: 'date',
      className: 'size-12',
      width: '15%'
    },
  ]

  return (
    <Modal
      title={i18n.t('detail')}
      open={isOpen}
      onOk={handleSubmit}
      onCancel={close}
      centered
      width={900}
      footer={
        <Row justify="center">
          <Button key="cancel" type="primary" onClick={close}>{i18n.t('close')}</Button>
        </Row>
      }
    >
      <Table
        size="middle"
        loading={isLoading}
        dataSource={infoData.slice(startIndex, endIndex)}
        columns={infoColumns}
        pagination={{
          showSizeChanger: true,
          className: 'size-14',
          total: infoData?.length || 0,
          pageSize: itemsPerPage,
          showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
          onChange: onPageChange,
        }}
      />
    </Modal>
  )
}


// 新增備註-遊戲IP檢核
export const PopupGameIpCommentModal = ({ isOpen, close, commentHistory, id, mutate }: any) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (commentHistory && isOpen) {
      form.setFieldsValue({
        remark: commentHistory,
      })
    }
  }, [commentHistory, isOpen])

  const handleSubmit = async (formData: any) => {
    const response = await GameIpMemberNote({
      Id: id,
      Noted: formData.remark ? formData.remark : commentHistory,
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('editSuccess'));
      onClose();
      mutate();
    } else {
      message.error(RESPONSE_CODE_GAME_IP[response.Message as keyof typeof RESPONSE_CODE_GAME_IP]);
    }
  }

  const onClose = () => {
    close();
    form.resetFields();
  };

  return (
    <Modal
      title={i18n.t('remark')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item name="remark" rules={[{ required: true, message: `${i18n.t("enterRemark")}` }]}>
          <Input.TextArea
            size="middle"
            autoSize={{ minRows: 4, maxRows: 4 }}
            maxLength={100}
            showCount
            placeholder={`${i18n.t('enter')}`}
          />
        </Form.Item>
        <Row className="mt-1" align="middle" justify="center" gutter={[12, 12]}>
          <Col><Button onClick={onClose}>{i18n.t('cancel')}</Button></Col>
          <Col><Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button></Col>
        </Row>
      </Form>
    </Modal>
  )
}
export const PopupBlockGameIpModal = ({ isOpen, close, data, mutate }: any) => {
  const [form] = useForm();

  useEffect(() => {
    if (data && isOpen) {
      form.setFieldsValue({
        ip: data.LastLogIP,
      })
    }
  }, [data, isOpen]);

  const onFinish = async (formData: any) => {
    const response = await GameOnLuck({
      BlockIP: formData.ip,
      Remark: formData.remark
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('lockIpSuccess'));
      onClose();
      mutate();
    } else {
      message.error(RESPONSE_CODE_IP[response.Message as keyof typeof RESPONSE_CODE_IP]);
    }
  }

  const onClose = () => {
    close();
    form.resetFields();
  }

  return (
    <Modal
      title={i18n.t('block')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="ip" label={i18n.t('IP')} required rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('enterIP')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="remark" label={i18n.t('remark')}>
              <TextArea
                autoSize={{ minRows: 4, maxRows: 4 }}
                maxLength={100}
                showCount
                placeholder={`${i18n.t('enterRemark')}`}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
