import { Col, Row, Spin, Switch, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { LayoutNav, LayoutTabPlatform } from 'components/layout.component';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React from 'react';
import { ProviderOpenList } from 'services/provider.service';

// 站台設定 遊戲商返水設定

interface ProviderInfoType {
  Id: number;
  CategoryId: number;
  CategoryCode: string;
  ProviderName: string;
  ProviderCode: string;
  Status: number;
  Account: string;
  UpdateDate: string;
}

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();
  const { data: ProviderList } = ProviderOpenList();

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <LayoutTabPlatform activeKey="6" />
        <Row align="middle" gutter={[10, 16]}>
          <Col span={24}>
            <Spin spinning={!ProviderList}>
              <Table
                size="middle"
                dataSource={
                  ProviderList &&
                  ProviderList.map((item: ProviderInfoType) => ({
                    key: item.Id,
                    gameProvider: item.ProviderCode,
                    gameCategory: item.CategoryCode,
                    cashbackState: item.Status,
                    updater: item.Account,
                    updateTime: item.UpdateDate
                  }))
                }
                columns={[
                  {
                    title: i18n.t('gameProvider'),
                    className: 'size-12',
                    width: '25%',
                    render: (_, record) =>
                      <>{record.gameProvider}-{i18n.t(record.gameCategory)}</>
                  },
                  {
                    title: i18n.t('cashbackState'),
                    dataIndex: 'cashbackState',
                    className: 'size-12',
                    width: '25%',
                    render: (_, { cashbackState }) =>
                      <Switch defaultChecked={cashbackState} disabled={!$p.includes('00503')} />
                  },
                  {
                    title: i18n.t('updater'),
                    dataIndex: 'updater',
                    className: 'size-12',
                    width: '25%'
                  },
                  {
                    title: i18n.t('updateTime'),
                    dataIndex: 'updateTime',
                    className: 'size-12',
                    width: '25%'
                  },
                ]}
                pagination={false}
              />
            </Spin>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default PageMain;