import { InfoCircleFilled } from '@ant-design/icons';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Button, Col, Form, Input, InputNumber, Row, Select, Space, Tooltip, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { UploadFile } from 'antd/lib/upload/interface';
import { LayoutDateSelect, LayoutNav, UploadImage } from 'components/layout.component';
import { MemberLevelSelect } from 'components/member.component';
import { RESPONSE_CODE_BONUS } from 'constants/response';
import { DATE_FORMAT } from 'enum/date';
import { PROMOTION_STATE } from 'enum/promotion';
import { RESPONSE_TYPE } from 'enum/state';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateBonus } from 'services/bonus.service';
import { ProviderOpenList } from 'services/provider.service';
import { enumToOptions, verify } from 'utils/common';

// 新增其他優惠

interface ProviderType {
  Id: number;
  CategoryCode: string;
  ProviderName: string;
}

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const { data: provider } = ProviderOpenList();
  const [editorContent, setEditorContent] = useState<string>();
  const [isPopupValue, setIsPopupValue] = useState(true);
  const [isOneTime, setIsOneTime] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      promotionType: '1',
      promotionName: '',
      popupDisplay: null,
      redirectToPromotionPage: null,
      cycle: 0,
      oneTimePromotion: null,
      expectedValidBet: '',
      promotionAmount: '',
      rolloverRate: '',
      applicationMethod: 1,
      distributionMethod: null,
      status: null,
      sort: null,
    })
    setImageData([])
  }, []);

  const [date, setDate] = useState<string[]>();

  const onFinish = async (formData: any) => {
    setLoading(true);
    const response = await CreateBonus({
      SettleMethod: formData.distributionMethod,
      ApplyMethod: formData.applicationMethod,
      Status: formData.status,
      Name: formData.promotionName,
      StartDate: formData.promotionTime[0],
      EndDate: formData.promotionTime[1],
      IsPopUp: formData.popupDisplay,
      IsRedirect: formData.redirectToPromotionPage,
      Period: formData.cycle || 1,
      IsOneTime: formData.oneTimePromotion,
      ProvidersId: formData.applicableGameProvider,
      LevelsId: formData.LevelId,
      ValidBet: Number(formData.expectedValidBet),
      Amount: Number(formData.promotionAmount),
      ValidBetTimes: Number(formData.rolloverRate),
      Position: Number(formData.sort),
      Content: editorContent,
      Photo: imageData[0] ? [imageData[0].url] : [],
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('addSuccess'));
      navigate('/promotion');
      setLoading(false);
    } else {
      setLoading(false);
      message.error(RESPONSE_CODE_BONUS[response.Message as keyof typeof RESPONSE_CODE_BONUS]);
    }
  }

  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2">
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Form.Item name="promotionType" className="w-12">
                    <Select
                      disabled
                      options={[
                        { value: '1', label: i18n.t('otherPromotions') }
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Space size="middle">
                    <Button onClick={() => navigate('/promotion')}>{i18n.t('cancel')}</Button>
                    <Button type="primary" htmlType="submit" loading={loading}>{i18n.t('confirm')}</Button>
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Form.Item name="promotionName" label={i18n.t('promotionName')}
                rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                <Input className="w-12" placeholder={`${i18n.t('enter')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]} align="middle">
                <Col>
                  <Form.Item name="popupDisplay" className="w-12" label={i18n.t('popupDisplay')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      onChange={e => {
                        setIsPopupValue(e === 0);
                        form.setFieldValue('redirectToPromotionPage', 0);
                      }}
                      placeholder={i18n.t('pleaseSelect')}
                      options={[
                        { value: 1, label: `${i18n.t('yes')}` },
                        { value: 0, label: `${i18n.t('no')}` },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="redirectToPromotionPage" className="w-12" label={i18n.t('redirectToPromotionPage')}
                    rules={[{ required: !isPopupValue, message: `${i18n.t('required')}` }]}>
                    <Select
                      disabled={isPopupValue}
                      placeholder={i18n.t('pleaseSelect')}
                      options={[
                        { value: 1, label: `${i18n.t('yes')}` },
                        { value: 0, label: `${i18n.t('no')}` },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col>
                  <Form.Item name="cycle" className="w-12" label={i18n.t('cycle')} required
                    rules={[{ required: true }]}>
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      options={[
                        { value: 0, label: i18n.t('promotionPeriod') },
                        { value: 1, label: i18n.t('day') },
                        { value: 2, label: i18n.t('week') }
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="oneTimePromotion" className="w-12" label={i18n.t('oneTimePromotion')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      onChange={e => {
                        setIsOneTime(e !== 1);
                        e === 0 &&
                          form.setFieldValue('distributionMethod', 1);
                      }}
                      placeholder={i18n.t('pleaseSelect')}
                      options={[
                        { value: 1, label: `${i18n.t('yes')}` },
                        { value: 0, label: `${i18n.t('no')}` },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col>
                  <Form.Item name="applicableGameProvider" className="w-12" label={i18n.t('applicableGameProvider')}>
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder={i18n.t('pleaseSelect')}
                      options={
                        provider &&
                        provider.map((option: ProviderType) => ({
                          value: option.Id,
                          label: `${option.ProviderName}-${i18n.t(option.CategoryCode)}`
                        }))
                      }
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="LevelId" className="w-12" label={i18n.t('applicableMemberLevel')}>
                    <MemberLevelSelect mode="multiple" name="LevelId" form={form} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]} align="middle">
                <Col>
                  <Form.Item name="expectedValidBet" label={
                    <Row gutter={5}>
                      <Col>{i18n.t('expectedValidBet')}</Col>
                      <Col>
                        <Tooltip placement="top" title={
                          i18n.t('membersCanClaimPromotionalAmountWhenTheyMeetThisSetConditionOfEffectiveBetting')
                        }>
                          <InfoCircleFilled />
                        </Tooltip>
                      </Col>
                    </Row>
                  } rules={verify({ required: false, point: 4 })}>
                    <Input className="w-12" placeholder={`${i18n.t('enter')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="promotionAmount" label={i18n.t('promotionAmount')} rules={verify({ point: 4 })}>
                    <Input className="w-12" placeholder={`${i18n.t('enter')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="rolloverRate" label={i18n.t('rolloverRate')} rules={verify({ point: 0 })}>
                    <Input className="w-12" placeholder={`${i18n.t('enter')}`} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]} align="middle">
                <Col>
                  <Form.Item name="applicationMethod" className="w-12" label={i18n.t('applicationMethod')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      disabled
                      placeholder={i18n.t('pleaseSelect')}
                      options={[
                        { value: 0, label: `${i18n.t('autoApplication')}` },
                        { value: 1, label: `${i18n.t('memberManualApplication')}` },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="distributionMethod" className="w-12" label={i18n.t('distributionMethod')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      disabled={isOneTime}
                      placeholder={i18n.t('pleaseSelect')}
                      options={[
                        { value: 0, label: `${i18n.t('systemDistribution')}` },
                        { value: 1, label: `${i18n.t('manualDistribution')}` },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]} align="middle">
                <Col>
                  <Form.Item name="status" className="w-12" label={i18n.t('status')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      options={enumToOptions(PROMOTION_STATE)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="sort" label={i18n.t('sort')} rules={verify({ point: 0 })}>
                    <InputNumber className="w-12" defaultValue={'0'} placeholder={`${i18n.t('enter')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="promotionTime" label={
                    `${i18n.t('promotionStartTime')} - ${i18n.t('promotionEndTime')}`
                  } rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <LayoutDateSelect onChange={setDate}
                      hidden="dateRadio" dateFormatDefault={DATE_FORMAT.日期時間} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Form.Item name="Photo" label={i18n.t('image')} rules={[{ required: !imageData[0] }]}>
                    <UploadImage
                      name="Photo"
                      url={'/upload/images/public/bonus'}
                      imageData={imageData}
                      setImageData={setImageData}
                      form={form}
                      w={1920}
                      h={560}
                      crop
                    />
                  </Form.Item>
                  <div className="color-03 size-12">{i18n.t('onlyJpgOrPngFiles')}<span className="require">{i18n.t('imageMobileVersionWidth1920pxHeight560px')}</span>{i18n.t('allowedAndSizeNotExceed500kb')}</div>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Form.Item name="internalContent" label={i18n.t('internalContent')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <CKEditor
                      editor={ClassicEditor}
                      onReady={editor => {

                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                      }}
                      onBlur={(event, editor) => {
                        const data = editor.getData();
                        setEditorContent(data);
                      }}
                      onFocus={(event, editor) => {

                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Content >
    </div >
  );
};

export default PageMain;