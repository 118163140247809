import { Button, Col, Form, Input, Modal, Row, Select, Table, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { RESPONSE_CODE_ADJUST } from "constants/response";
import { ADJUST_TRANSFER_TYPE, AGENT_ADJUST_STATE, RESPONSE_TYPE, VERIFICATION_STATE } from "enum/state";
import useAuth from "hooks/auth.hook";
import usePerm from "hooks/permission.hook";
import i18n from "i18n";
import { useEffect, useState } from "react";
import { AgentOrderPass, AgentOrderReject, CreateAdjust, CreateAgentAdjust, GetAdjustticketOne, OrderPass, OrderReject } from "services/adjustticket.service";
import { GetAgentOnelite } from "services/agent.service";
import { GetMemberBalanceInfo } from "services/member.service";
import * as common from "utils/common";
import { toFormatNumber as $f, verify } from 'utils/common';

// 總計
export const ViewAdjustOverview = ({ data }: {
  data: {
    TotalAdjustMinusAmount: number;
    TotalAdjustPlusAmount: number;
    TotalRecord: number;
  };
}) => {

  return (
    <Table
      className="w-full"
      size="middle"
      dataSource={
        data &&
        [{
          key: 1,
          count: data.TotalRecord,
          addBalanceTotal: data.TotalAdjustPlusAmount,
          deductBalanceTotal: data.TotalAdjustMinusAmount
        }]
      }
      columns={[
        {
          title: i18n.t('count'),
          align: 'right',
          dataIndex: 'count'
        },
        {
          title: i18n.t('addBalanceTotal'),
          align: 'right',
          dataIndex: 'addBalanceTotal',
          render: (_, { addBalanceTotal }) => common.toFormatNumber(addBalanceTotal)
        },
        {
          title: i18n.t('deductBalanceTotal'),
          align: 'right',
          dataIndex: 'deductBalanceTotal',
          render: (_, { deductBalanceTotal }) => common.toFormatNumber(deductBalanceTotal)
        }
      ]}
      pagination={false}
    />
  )
}

interface AdjustMemberList {
  Id: number;
  Account: string;
  AfterAmount?: number;
  Amount: number;
  AuditedDate: string;
  AuditorAccount: string;
  BeforeAmount?: number;
  Code: string;
  CreateDate: string;
  CreatorAccount: string;
  MemberLevelName?: string;
  Remark: string;
  RemarkForAuditor: string;
  Status: number;
  Type: number;
  ValidBetMultiple: number;
}

// 財務調帳列表
export const ViewAdjustList = ({ data, mutate }: {
  data: {
    State: string;
    Data: AdjustMemberList[];
    TotalRecord: number;
  }
  mutate: () => void;
}) => {
  const { permissionCode: $p } = usePerm();
  const [orderId, setOrderId] = useState<number>();
  const [isModalOpenConfirm, setIsModalOpenConfirm] = useState(false);
  const [isModalOpenDetail, setIsModalOpenDetail] = useState(false);

  // 狀態顏色
  const stateColor = (state: number) => {
    switch (state) {
      case AGENT_ADJUST_STATE.通過:
        return 'color-pass'
      case AGENT_ADJUST_STATE.拒絕:
      case AGENT_ADJUST_STATE.失敗:
        return 'color-reject'
      case AGENT_ADJUST_STATE.待審核:
        return 'color-padding'
      default: return ''
    };
  };

  const handleOpenDetail = (id: number) => {
    setIsModalOpenDetail(true);
    setOrderId(id);
  };

  const handleOpenConfirm = (id: number) => {
    setIsModalOpenConfirm(true);
    setOrderId(id);
  };

  return (
    <>
      <Table
        className="mt-1"
        size="small"
        scroll={{ x: 1500 }}
        dataSource={data?.Data?.map(item => ({ key: item.Id, ...item }))}
        columns={[
          {
            dataIndex: 'Code',
            title: i18n.t('order'),
            width: 250,
            fixed: 'left'
          },
          {
            dataIndex: 'Account',
            title: i18n.t('memberAccount'),
            width: 200,
            fixed: 'left'
          },
          {
            dataIndex: 'MemberLevelName',
            title: i18n.t('memberLevel'),
            width: 150,
            render: (val) => val || '-'
          },
          {
            dataIndex: 'Type',
            title: i18n.t('transactionType'),
            width: 150,
            render: (val) => <>{ADJUST_TRANSFER_TYPE[val]}</>
          },
          {
            dataIndex: 'BeforeAmount',
            title: i18n.t('previousBalance'),
            align: 'right',
            width: 200,
            render: (val) => common.toFormatNumber(val)
          },
          {
            dataIndex: 'Amount',
            title: i18n.t('amount'),
            align: 'right',
            width: 200,
            render: (val) => common.toFormatNumber(val)
          },
          {
            dataIndex: 'ValidBetMultiple',
            title: i18n.t('rolloverRate'),
            align: 'right',
            width: 200,
          },
          {
            dataIndex: 'expectedValidBet',
            title: i18n.t('expectedValidBet'),
            align: 'right',
            width: 200,
            render: (_, record) => $f(parseFloat(record.Amount as any) * parseFloat(record.ValidBetMultiple as any))
          },
          {
            dataIndex: 'AfterAmount',
            title: i18n.t('BalanceAfterModification'),
            align: 'right',
            width: 200,
            render: (val) => val ? common.toFormatNumber(val) : '-'
          },
          {
            dataIndex: 'CreateDate',
            title: i18n.t('createTime'),
            width: 150,
          },
          {
            dataIndex: 'AuditedDate',
            title: i18n.t('auditTime'),
            width: 150,
            render: (val) => val !== '0000-00-00 00:00:00' ? val : '-'
          },
          {
            dataIndex: 'Status',
            width: 150,
            title: i18n.t('status'),
            fixed: 'right',
            render: (val, record) => (
              <>
                <div className={stateColor(val)}>{AGENT_ADJUST_STATE[val]}</div>
                <div>
                  {`${record.CreatorAccount} `}
                  {
                    record.Status === AGENT_ADJUST_STATE.待審核
                      ? i18n.t('add')
                      : AGENT_ADJUST_STATE[record.Status]
                  }
                </div>
              </>
            )
          },
          {
            title: i18n.t('operation'),
            width: 100,
            fixed: 'right',
            render: (_, record) => (
              <>
                {
                  (record.Status === AGENT_ADJUST_STATE.通過 ||
                    record.Status === AGENT_ADJUST_STATE.拒絕) &&
                  <Button className="size-12" type="link" onClick={() => handleOpenDetail(record.key)}>{i18n.t('detail')}</Button>
                }
                {
                  record.Status === AGENT_ADJUST_STATE.待審核 && $p.includes('20304') &&
                  <Button className="size-12" type="link" onClick={() => handleOpenConfirm(record.key)}>{i18n.t('audit')}</Button>
                }
              </>
            ),
          },
        ]}
        pagination={false}
      />

      <PopupAdjust isOpen={isModalOpenConfirm} close={() => setIsModalOpenConfirm(false)} order={orderId} mutate={mutate} />
      <PopupAdjustDetail isOpen={isModalOpenDetail} close={() => setIsModalOpenDetail(false)} order={orderId} />
    </>
  )
}

// 財務新增調帳
export const PopupAddAdjust = ({ isOpen, close, mutate }: any) => {
  const [form] = useForm();
  const auth = useAuth();
  const { Account: Auditor } = auth.getAdmin();

  const onFinish = async (formData: any) => {
    const response = await CreateAdjust({
      Account: formData.memberAccount,
      Type: formData.transactionType,
      Amount: formData.amount,
      ValidBetMultiple: formData.rolloverRate,
      RemarkForAuditor: Auditor,
      Remark: formData.backOfficeRemarks
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('addSuccess'));
      onClose();
      mutate();
    } else {
      message.error(RESPONSE_CODE_ADJUST[response.Message as keyof typeof RESPONSE_CODE_ADJUST]);
    }
  };

  const onClose = () => {
    close();
    setTimeout(() => {
      form.resetFields();
      setMemberAccount('');
    }, 300);
  }

  const [memberAccount, setMemberAccount] = useState('');
  const memberBalanceInfo = GetMemberBalanceInfo({
    Account: memberAccount,
    DateType: 5,
  }, memberAccount !== '');
  useEffect(() => {
    if (memberBalanceInfo.data && memberBalanceInfo.data.data && isOpen) {
      const balance = parseFloat(memberBalanceInfo.data.data.Balance)
      form.setFieldsValue({
        currentBalance: $f(balance)
      })
    } else {
      form.setFieldsValue({
        currentBalance: $f(0)
      })
    }
  }, [memberBalanceInfo.data, isOpen]);

  return (
    <Modal title={i18n.t('add')}
      open={isOpen}
      centered={true}
      onCancel={onClose}
      footer={false}
      width={600}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[12, 12]} className="pb-1">
          <Col span={24}>
            <Form.Item name="memberAccount" label={i18n.t('memberAccount')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('enter')}`} onChange={(e) => setMemberAccount(e.target.value)} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]} className="pb-1">
          <Col span={12}>
            <Form.Item name="currentBalance" label={i18n.t('currentBalance')}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="transactionType" label={i18n.t('transactionType')} rules={[{ required: true }]}>
              <Select
                className="w-full"
                placeholder={i18n.t('pleaseSelect')}
                options={common.enumToOptions(ADJUST_TRANSFER_TYPE)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]} className="pb-1">
          <Col span={12}>
            <Form.Item name="amount" label={i18n.t('amount')} rules={verify({ zero: false })}>
              <Input typeof="number" placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="rolloverRate" label={i18n.t('rolloverRate')} rules={verify({ point: 0 })}>
              <Input placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]} className="pb-1">
          <Col span={24}>
            <Form.Item name="backOfficeRemarks" label={i18n.t('backOfficeRemarks')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" gutter={[12, 12]}>
          <Col><Button onClick={onClose}>{i18n.t('cancel')}</Button></Col>
          <Col><Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button></Col>
        </Row>
      </Form>
    </Modal>
  )
}
// 調帳訂單 詳情
export const PopupAdjustDetail = ({ isOpen, close, order }: any) => {
  const [form] = useForm();

  const { data: adjustInfo, mutate } = GetAdjustticketOne({
    Id: order,
  });

  useEffect(() => {
    if (isOpen) {
      mutate()
    }
  }, [isOpen]);

  useEffect(() => {
    if (adjustInfo && isOpen) {
      // setMemberAccount(adjustInfo.OwnerAccount);
      const balance = parseFloat(adjustInfo.BeforeAmount)
      form.setFieldsValue({
        memberAccount: adjustInfo.OwnerAccount,
        currentBalance: $f(balance),
        transactionType: adjustInfo.Type,
        amount: common.toFormatNumber(adjustInfo.Amount),
        rolloverRate: adjustInfo.ValidBetMultiple,
        backOfficeRemarks: adjustInfo.Remark,
      })
    }
  }, [adjustInfo, isOpen]);

  const onClose = () => {
    close();
    setTimeout(form.resetFields, 100);
  }

  return (
    <Modal title={i18n.t('detail')}
      open={isOpen}
      centered={true}
      onCancel={onClose}
      footer={false}
      width={600}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={[12, 12]} className="pb-1">
          <Col span={24}>
            <Form.Item name="memberAccount" label={i18n.t('memberAccount')} required>
              <Input placeholder={`${i18n.t('enter')}`} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]} className="pb-1">
          <Col span={12}>
            <Form.Item name="currentBalance" label={i18n.t('previousBalance')} required>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="transactionType" label={i18n.t('transactionType')} required>
              <Select
                className="w-full"
                disabled
                placeholder={i18n.t('pleaseSelect')}
                options={common.enumToOptions(ADJUST_TRANSFER_TYPE)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]} className="pb-1">
          <Col span={12}>
            <Form.Item name="amount" label={i18n.t('amount')} required>
              <Input placeholder={`${i18n.t('enter')}`} disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="rolloverRate" label={i18n.t('rolloverRate')} rules={verify({ point: 0 })}>
              <Input placeholder={`${i18n.t('enter')}`} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]} className="pb-1">
          <Col span={24}>
            <Form.Item name="backOfficeRemarks" label={i18n.t('backOfficeRemarks')} required>
              <Input placeholder={`${i18n.t('enter')}`} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Button type="primary" onClick={onClose}>{i18n.t('close')}</Button>
        </Row>
      </Form>
    </Modal>
  )
}
// 財務調帳訂單 審核調帳
export const PopupAdjust = ({ isOpen, close, order, mutate }: any) => {
  const [form] = useForm();
  const { data: adjustInfo, mutate: mutateOne } = GetAdjustticketOne({
    Id: order,
  });
  useEffect(() => {
    if (isOpen) {
      mutateOne()
    }
  }, [isOpen]);
  useEffect(() => {
    if (adjustInfo && isOpen) {
      setMemberAccount(adjustInfo.OwnerAccount);
      form.setFieldsValue({
        OwnerAccount: adjustInfo.OwnerAccount,
        Type: adjustInfo.Type,
        Amount: common.toFormatNumber(adjustInfo.Amount),
        ValidBetMultiple: adjustInfo.ValidBetMultiple,
        Remark: adjustInfo.Remark,
      })
    }
  }, [adjustInfo, isOpen]);

  // 餘額
  const [memberAccount, setMemberAccount] = useState('');
  const memberBalanceInfo = GetMemberBalanceInfo({
    Account: memberAccount,
    DateType: 5,
  }, memberAccount !== '');
  useEffect(() => {
    if (memberBalanceInfo.data && memberBalanceInfo.data.data && isOpen) {
      const balance = parseFloat(memberBalanceInfo.data.data.Balance)
      form.setFieldsValue({
        currentBalance: $f(balance)
      })
    } else {
      form.setFieldsValue({
        currentBalance: $f(0)
      })
    }
  }, [memberBalanceInfo.data, isOpen]);

  const [mode, setMode] = useState<any>();
  const handleSubmit = async (formData: any) => {
    const send: any = {
      Id: order,
      RemarkFromAuditor: formData.backOfficeRemarks,
    }
    let response: any = {};
    let msg = '';
    if (mode === VERIFICATION_STATE.通過) {
      send.Remark = formData.backOfficeRemarks
      response = await OrderPass(send);
      msg = i18n.t('pass');
    } else {
      response = await OrderReject(send);
      msg = i18n.t('reject');
    }

    if (response.State === RESPONSE_TYPE.成功) {
      message.success(msg);
      onClose();
      mutate();
    } else {
      message.error(RESPONSE_CODE_ADJUST[response.Message as keyof typeof RESPONSE_CODE_ADJUST]);
    }
  }

  const onClose = () => {
    close();
    setTimeout(form.resetFields, 100);
  };

  return (
    <Modal title={i18n.t('audit')}
      open={isOpen}
      centered={true}
      onCancel={onClose}
      footer={false}
      width={600}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Row gutter={[12, 12]} className="pb-1">
          <Col span={24}>
            <Form.Item name="OwnerAccount" label={i18n.t('memberAccount')} required>
              <Input placeholder={`${i18n.t('enter')}`} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]} className="pb-1">
          <Col span={12}>
            <Form.Item name="currentBalance" label={i18n.t('currentBalance')} required>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="Type" label={i18n.t('transactionType')} required>
              <Select
                className="w-full"
                disabled
                placeholder={i18n.t('pleaseSelect')}
                options={common.enumToOptions(ADJUST_TRANSFER_TYPE)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]} className="pb-1">
          <Col span={12}>
            <Form.Item name="Amount" label={i18n.t('amount')} required>
              <Input placeholder={`${i18n.t('enter')}`} disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="ValidBetMultiple" label={i18n.t('rolloverRate')} rules={verify({ point: 0 })}>
              <Input placeholder={`${i18n.t('enter')}`} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]} className="pb-1">
          <Col span={24}>
            <Form.Item name="Remark" label={i18n.t('backOfficeRemarks')} required>
              <Input placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" gutter={[12, 12]}>
          <Col><Button type="primary" danger onClick={() => {
            setMode(VERIFICATION_STATE.拒絕);
            form.submit();
          }}>{i18n.t('reject')}</Button></Col>
          <Col><Button type="primary" onClick={() => {
            setMode(VERIFICATION_STATE.通過);
            form.submit();
          }}>{i18n.t('pass')}</Button></Col>
        </Row>
      </Form>
    </Modal>
  )
}




// 代理總計
export const ViewAdjustAgentOverview = ({ data }: {
  data: {
    TotalRecord: number;
    TotalAdjustPlusAmount: number;
    TotalAdjustMinusAmount: number;
  };
}) => {

  return (
    <Table
      size="middle"
      dataSource={
        data && [
          {
            key: 1,
            amount: data.TotalRecord,
            addTotal: data.TotalAdjustPlusAmount,
            deductTotal: data.TotalAdjustMinusAmount
          }
        ]
      }
      columns={[
        {
          key: 1,
          title: i18n.t('count'),
          dataIndex: 'amount',
          className: 'size-12',
          width: '33%',
          align: 'right'
        },
        {
          key: 2,
          title: i18n.t('addBalanceTotal'),
          dataIndex: 'addTotal',
          className: 'size-12',
          width: '33%',
          align: 'right',
          render: (_, { addTotal }) => <>{common.toFormatNumber(addTotal)}</>
        },
        {
          key: 3,
          title: i18n.t('deductBalanceTotal'),
          dataIndex: 'deductTotal',
          className: 'size-12',
          width: '33%',
          align: 'right',
          render: (_, { deductTotal }) => <>{common.toFormatNumber(deductTotal)}</>
        },
      ]}
      pagination={false}
    />
  )
}

interface AgentListType {
  Id: number;
  Code: string;
  AgentLevel: number;
  AgentAccount: string;
  SAAccount: string;
  Type: number;
  BeforeAmount?: number;
  Amount: number;
  AfterAmount?: number;
  Status: number;
  RemarkFromAuditor: string;
  RemarkForAuditor: string;
  CreateDate: string;
  AuditedDate: string;
  CreatorAccount: string;
  AuditorAccount: string;
}

// 代理調帳
export const ViewAdjustAgentList = ({ data, mutate }: {
  data: {
    State: string;
    Data: AgentListType[];
    TotalRecord: number;
  };
  mutate: () => void;
}) => {
  const { permissionCode: $p } = usePerm();
  const [id, setId] = useState<number>(0);
  const [isOpenDetailModal, setIsOpenDetailModal] = useState(false);
  const [isOpenAdjustModal, setIsOpenAdjustModal] = useState(false);

  //color
  const handleStateColor = (type: number) => {
    switch (type) {
      case AGENT_ADJUST_STATE.通過:
        return 'color-pass'
      case AGENT_ADJUST_STATE.拒絕:
        return 'color-reject'
      case AGENT_ADJUST_STATE.待審核:
        return 'color-reviewing'
      default: return 'color-padding'
    }
  }

  const handleAdjust = (id: number) => {
    setIsOpenAdjustModal(true);
    setId(id);
  }

  const handleAdjustDetail = (id: number) => {
    setIsOpenDetailModal(true);
    setId(id);
  }

  return (
    <>
      <Table
        size="middle"
        scroll={{ x: 1500 }}
        dataSource={
          data && data.Data &&
          data.Data.map(item => (
            {
              key: item.Id,
              order: item.Code,
              account: item.AgentAccount,
              generalAgent: item.SAAccount,
              type: item.Type,
              previousBalance: item.BeforeAmount,
              amount: item.Amount,
              AfterAmount: item.AfterAmount,
              created: item.CreateDate,
              review: item.AuditedDate,
              state: item.Status,
              creatorAccount: item.CreatorAccount,
              auditorAccount: item.AuditorAccount
            }
          ))
        }
        columns={[
          {
            title: i18n.t('orderNumber'),
            dataIndex: 'order',
            className: 'size-12',
            width: 300,
            fixed: 'left'
          },
          {
            title: i18n.t('agentAccount'),
            dataIndex: 'account',
            className: 'size-12',
            width: 200
          },
          {
            title: i18n.t('generalAgent'),
            dataIndex: 'generalAgent',
            className: 'size-12',
            width: 200
          },
          {
            title: i18n.t('transactionType'),
            dataIndex: 'type',
            className: 'size-12',
            width: 100,
            render: (_, { type }) => ADJUST_TRANSFER_TYPE[type]
          },
          {
            title: i18n.t('previousBalance'),
            dataIndex: 'previousBalance',
            className: 'size-12',
            align: 'right',
            width: 200,
            render: (_, { previousBalance }) => common.toFormatNumber(previousBalance)
          },
          {
            title: i18n.t('amount'),
            dataIndex: 'amount',
            className: 'size-12',
            align: 'right',
            width: 200,
            render: (_, { amount }) => common.toFormatNumber(amount)
          },
          {
            title: i18n.t('BalanceAfterModification'),
            dataIndex: 'AfterAmount',
            className: 'size-12',
            align: 'right',
            width: 200,
            render: (_, { AfterAmount }) => AfterAmount ?
              common.toFormatNumber(AfterAmount) :
              '-'
          },
          {
            key: 7,
            title: i18n.t('createTime'),
            dataIndex: 'created',
            className: 'size-12',
            width: 150
          },
          {
            key: 8,
            title: i18n.t('auditTime'),
            dataIndex: 'review',
            className: 'size-12',
            width: 150,
            render: (_, { review }) => <>{(review && review !== '0000-00-00 00:00:00') ? review : '-'}</>
          },
          {
            key: 9,
            title: i18n.t('status'),
            className: 'size-12',
            width: 150,
            fixed: 'right',
            render: (_, record) => (
              <>
                <div className={handleStateColor(record.state)}>{AGENT_ADJUST_STATE[record.state]}</div>
                {
                  record.state === AGENT_ADJUST_STATE.待審核
                    ? <div>{record.creatorAccount} {i18n.t('add')}</div>
                    : <div>{record.auditorAccount} {AGENT_ADJUST_STATE[record.state]}</div>
                }
              </>
            )
          },
          {
            key: 10,
            title: i18n.t('operation'),
            className: 'size-12',
            width: 100,
            fixed: 'right',
            render: (_, record) => (
              <>
                {
                  (
                    record.state === AGENT_ADJUST_STATE.通過 ||
                    record.state === AGENT_ADJUST_STATE.失敗 ||
                    record.state === AGENT_ADJUST_STATE.拒絕
                  ) &&
                  <Button className="size-12" type='link' onClick={() => handleAdjustDetail(record.key)}>{i18n.t('detail')}</Button>
                }
                {
                  record.state === AGENT_ADJUST_STATE.待審核 && $p.includes('40704') &&
                  <Button className="size-12" type='link' onClick={() => handleAdjust(record.key)}>{i18n.t('audit')}</Button>
                }
              </>
            )
          },
        ]}
        pagination={false}
      />
      <PopupAgentDetailAdjust isOpen={isOpenDetailModal} close={() => setIsOpenDetailModal(false)} id={id} />
      <PopupAgentAdjustAgent isOpen={isOpenAdjustModal} close={() => setIsOpenAdjustModal(false)} id={id} mutate={mutate} />
    </>
  )
}

// 代理新增調帳
export const PopupAgentAddAdjust = ({ isOpen, close, mutate }: any) => {
  const [form] = useForm();
  const auth = useAuth();
  const { Account: admin } = auth.getAdmin();

  const onFinish = async (formData: any) => {
    const response = await CreateAgentAdjust({
      AgentAccount: formData.agentAccount,
      Type: formData.transactionType,
      Amount: formData.amount,
      ValidBetMultiple: 0,
      RemarkForAuditor: admin,
      Remark: formData.backOfficeRemarks
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('addSuccess'));
      onClose();
      mutate();
    } else {
      message.error(i18n.t('incorrectRequest'));
      // message.error(RESPONSE_CODE_ADJUST[response.Message as keyof typeof RESPONSE_CODE_ADJUST]);
    }
  }

  const onClose = () => {
    close();
    setTimeout(() => {
      form.resetFields();
      setAgentAccount('');
    }, 300);
  }

  const [agentAccount, setAgentAccount] = useState('');
  const { data: agentOne } = GetAgentOnelite({
    Account: agentAccount
  }, true);
  useEffect(() => {
    if (agentOne && isOpen) {
      const balance = parseFloat(agentOne.Balance)
      form.setFieldsValue({
        currentBalance: $f(balance)
      })
    } else {
      form.setFieldsValue({
        currentBalance: $f(0)
      })
    }
  }, [agentOne, isOpen]);

  return (
    <Modal
      title={i18n.t('add')}
      open={isOpen}
      onCancel={onClose}
      width={500}
      centered
      footer={false}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="agentAccount" label={i18n.t('agentAccount')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('enter')}`} onChange={(e) => setAgentAccount(e.target.value)} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="currentBalance" label={i18n.t('currentBalance')}>
              <Input placeholder={`${i18n.t('enter')}`} disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row align="middle" gutter={[12, 12]}>
              <Col span={12}>
                <Form.Item className="w-full" name="transactionType" label={i18n.t('transactionType')} rules={[{ required: true }]}>
                  <Select
                    className="w-full"
                    placeholder={i18n.t('pleaseSelect')}
                    options={[
                      { value: 1, label: `${i18n.t('addBalance')}` },
                      { value: -1, label: `${i18n.t('deductBalance')}` },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="amount" label={i18n.t('amount')} rules={verify({ zero: false })}>
                  <Input placeholder={`${i18n.t('enter')}`} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Form.Item name="backOfficeRemarks" label={i18n.t('backOfficeRemarks')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col><Button key="cancel" onClick={onClose}>{i18n.t('cancel')}</Button></Col>
              <Col>
                <Button key="confirm" type="primary" htmlType="submit">
                  {i18n.t('confirm')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

// 代理調帳 詳情
export const PopupAgentDetailAdjust = ({ isOpen, close, id }: any) => {
  const [form] = useForm();
  const { data: adjustInfo } = GetAdjustticketOne({ Id: id })

  useEffect(() => {
    if (adjustInfo && isOpen) {
      // setAgentAccount(adjustInfo.OwnerAccount)
      const balance = parseFloat(adjustInfo.BeforeAmount)
      form.setFieldsValue({
        agentAccount: adjustInfo.OwnerAccount,
        currentBalance: $f(balance),
        transactionType: adjustInfo.Type,
        amount: adjustInfo.Amount,
        backOfficeRemarks: adjustInfo.Remark
      })
    }
  }, [adjustInfo, isOpen])

  return (
    <Modal
      title={i18n.t('detail')}
      open={isOpen}
      onCancel={close}
      width={500}
      centered
      footer={
        <Row justify="center" gutter={12}>
          <Col><Button key="cancel" onClick={close}>{i18n.t('close')}</Button></Col>
        </Row>
      }
    >
      <Form layout="vertical" form={form}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="agentAccount" label={i18n.t('agentAccount')} required>
              <Input placeholder={`${i18n.t('enter')}`} disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="currentBalance" label={i18n.t('previousBalance')}>
              <Input placeholder={`${i18n.t('enter')}`} disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row align="middle" gutter={[12, 12]}>
              <Col span={12}>
                <Form.Item name="transactionType" label={i18n.t('transactionType')} required>
                  <Select
                    disabled
                    className="w-full"
                    placeholder={i18n.t('pleaseSelect')}
                    options={[
                      { value: 1, label: `${i18n.t('addBalance')}` },
                      { value: -1, label: `${i18n.t('deductBalance')}` },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="amount" label={i18n.t('amount')} required>
                  <Input placeholder={`${i18n.t('enter')}`} disabled />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Form.Item name="backOfficeRemarks" label={i18n.t('backOfficeRemarks')} required>
              <Input placeholder={`${i18n.t('enter')}`} disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

// 代理調帳 審核調帳
export const PopupAgentAdjustAgent = ({ isOpen, close, id, mutate }: any) => {
  const auth = useAuth();
  const { account } = auth.getAdmin();
  const [form] = useForm();
  const { data: adjustInfo, mutate: mutateOne } = GetAdjustticketOne({ Id: id })

  useEffect(() => {
    if (isOpen) mutateOne()
  }, [ isOpen])
  useEffect(() => {
    if (adjustInfo && isOpen) {
      setAgentAccount(adjustInfo.OwnerAccount)
      form.setFieldsValue({
        agentAccount: adjustInfo.OwnerAccount,
        currentBalance: '',
        transactionType: adjustInfo.Type,
        amount: adjustInfo.Amount,
        backOfficeRemarks: adjustInfo.Remark
      })
    }
  }, [adjustInfo, isOpen])

  const [agentAccount, setAgentAccount] = useState('');
  const { data: agentOne } = GetAgentOnelite({
    Account: agentAccount
  }, true);
  useEffect(() => {
    if (agentOne) {
      const balance = parseFloat(agentOne.Balance)
      form.setFieldsValue({
        currentBalance: $f(balance)
      })
    } else {
      form.setFieldsValue({
        currentBalance: $f(0)
      })
    }
  }, [agentOne, isOpen]);

  const handlePass = async () => {
    const remarks = form.getFieldValue('backOfficeRemarks');
    if (!remarks) return;

    const response = await AgentOrderPass({
      Id: id,
      RemarkFromAuditor: account,
      Remark: remarks
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('pass'));
      mutate();
      onClose();
    } else {
      message.error(RESPONSE_CODE_ADJUST[response.Message as keyof typeof RESPONSE_CODE_ADJUST]);
    }

  }

  const handleReject = async () => {
    const remarks = form.getFieldValue('backOfficeRemarks');
    if (!remarks) return;

    const response = await AgentOrderReject({
      Id: id,
      RemarkFromAuditor: remarks,
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('reject'));
      mutate();
      onClose();
    } else {
      message.error(RESPONSE_CODE_ADJUST[response.Message as keyof typeof RESPONSE_CODE_ADJUST]);
    }

  }

  const onClose = () => {
    form.resetFields();
    close();
  }

  return (
    <Modal
      title={i18n.t('audit')}
      open={isOpen}
      onCancel={close}
      width={500}
      centered
      footer={false}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="agentAccount" label={i18n.t('agentAccount')} required>
              <Input placeholder={`${i18n.t('enter')}`} disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="currentBalance" label={i18n.t('currentBalance')}>
              <Input placeholder={`${i18n.t('enter')}`} disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row align="middle" gutter={[12, 12]}>
              <Col span={12}>
                <Form.Item name="transactionType" label={i18n.t('transactionType')} required>
                  <Select
                    disabled
                    className="w-full"
                    placeholder={i18n.t('pleaseSelect')}
                    options={[
                      { value: 1, label: `${i18n.t('addBalance')}` },
                      { value: -1, label: `${i18n.t('deductBalance')}` },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="amount" label={i18n.t('amount')} required>
                  <Input placeholder={`${i18n.t('enter')}`} disabled />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Form.Item name="backOfficeRemarks" label={i18n.t('backOfficeRemarks')} required
              rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={12}>
              <Col><Button htmlType="submit" key="cancel" type="primary" danger onClick={handleReject}>{i18n.t('reject')}</Button></Col>
              <Col><Button htmlType="submit" key="confirm" type="primary" onClick={handlePass}>{i18n.t('pass')}</Button></Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
