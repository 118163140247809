import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Button, Col, Form, Input, Row, Select, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { LayoutNav } from 'components/layout.component';
import { RESPONSE_CODE_SEO } from 'constants/response';
import { RESPONSE_TYPE, SEO_PAGE } from 'enum/state';
import i18n from 'i18n';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateSEO } from 'services/seo.service';
import { enumToOptions } from 'utils/common';

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const [isDone, setIsDone] = useState(false);
  const [editorContent, setEditorContent] = useState<string>();

  const onFinish = async (formData: any) => {
    const response = await CreateSEO({
      Type: formData.type,
      Title: formData.title,
      KeyWord: formData.keyword,
      Description: formData.description,
      HeadMeta: formData.meta,
      HeadLink: formData.link,
      HtmlContent: editorContent
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('addSuccess'));
      navigate('/seo')
      // setIsDone(true);
    } else {
      message.error(RESPONSE_CODE_SEO[response.Message as keyof typeof RESPONSE_CODE_SEO]);
    }
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="p-2">
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col>
                  <Form.Item name="type" className="w-26" label={i18n.t('page')} required
                    rules={[{ required: true }]}>
                    <Select
                      disabled={isDone}
                      placeholder={i18n.t('pleaseSelect')}
                      options={enumToOptions(SEO_PAGE)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Row align="middle" gutter={[12, 12]}>
                    {
                      isDone
                        ?
                        <Col>
                          <Button onClick={() => navigate('/seo')}>{i18n.t('close')}</Button>
                        </Col>
                        :
                        <>
                          <Col>
                            <Button onClick={() => navigate('/seo')}>{i18n.t('cancel')}</Button>
                          </Col>
                          <Col>
                            <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
                          </Col>
                        </>
                    }
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Form.Item name="title" className="w-26" label={i18n.t('title')}
                rules={[{ type: 'string', max: 20 }]}>
                <Input disabled={isDone} placeholder={`${i18n.t('enter')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="keyword" className="w-26" label={i18n.t('keywords')}
                rules={[{ type: 'string', max: 20 }]}>
                <Input disabled={isDone} placeholder={`${i18n.t('pleaseUseCommaToSeparateTheItems')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="description" className="w-26" label={i18n.t('description')}
                rules={[{ type: 'string', max: 100 }]}>
                <Input.TextArea
                  disabled={isDone}
                  size="middle"
                  autoSize={{ minRows: 3, maxRows: 3 }}
                  placeholder={`${i18n.t('enter')}`}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="meta" className="w-26" label={i18n.t('head meta')}>
                <Input disabled={isDone} placeholder={`${i18n.t('enter')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="link" className="w-26" label={i18n.t('head link')}>
                <Input disabled={isDone} placeholder={`${i18n.t('enter')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="htmlContent" className="w-full" label={i18n.t('htmlContent')}>
                <CKEditor
                  disabled={isDone}
                  editor={ClassicEditor}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setEditorContent(data);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;