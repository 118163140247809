import { Button, Col, Form, Input, Row, Select, Spin, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { Export, LayoutDateSelect, LayoutNav, LayoutPagination, LayoutUpdateControl } from 'components/layout.component';
import { PopupAgentDeposit } from 'components/withdraw.component';
import { ExcelColumns } from 'constants/excel';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TYPE } from 'enum/date';
import { DEPOSIT_STATE, SEARCH_DATE_TYPE } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { AgentDepositticketList } from 'services/finance.service';
import { GetGatewayList } from 'services/withdrawmethod.service';
import * as common from 'utils/common';

// 代理入款查詢

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();
  const [form] = Form.useForm();
  const [startDate, setStartDate] = useState('CreateStartDate');
  const [endDate, setEndDate] = useState('CreateEndDate');
  const [isOpenDeposit, setIsOpenDeposit] = useState(false);
  const [reset, setReset] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [date, setDate] = useState<string[]>();
  const [id, setId] = useState(0);

  const [params, setParams] = useState<any>({
    CreateStartDate: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    CreateEndDate: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  })
  const { data: depositList, isValidating, mutate } = AgentDepositticketList({
    ...params,
    PageIndex: page[0],
    PageSize: page[1]
  })
  const { data: gatewayList } = GetGatewayList({
    Type: 0,
  })

  const handleTimeMode = (type: number) => {
    switch (type) {
      case SEARCH_DATE_TYPE.建立時間:
        setStartDate('CreateStartDate');
        setEndDate('CreateEndDate');
        break;
      case SEARCH_DATE_TYPE.審核時間:
        setStartDate('AuditedStartDate');
        setEndDate('AuditedEndDate');
        break;
    }
  }

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        Code: formData.code,
        Account: formData.agAccount,
        SAAccount: formData.saAccount,
        Status: formData.status,
        GatewayId: formData.depositMerchantName,
        [startDate]: date[0],
        [endDate]: date[1],
      })
    }
    mutate();
  };

  const onClear = () => {
    setReset(!reset);
    form.resetFields();
    setPage([1, 10]);
    setStartDate('CreateStartDate');
    setEndDate('CreateEndDate');
  }

  const stateColor = (type: number) => {
    switch (type) {
      case DEPOSIT_STATE.成功:
      case DEPOSIT_STATE.通過:
        return 'color-pass'
      case DEPOSIT_STATE.處理中:
        return 'color-padding'
      case DEPOSIT_STATE.付款中:
        return 'color-up'
      case DEPOSIT_STATE.待財務審核:
        return 'color-reviewing'
      case DEPOSIT_STATE.失敗:
      case DEPOSIT_STATE.拒絕:
        return 'color-reject'
    }
  }

  const handleDeposit = (id: number) => {
    setId(id);
    setIsOpenDeposit(true);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish} initialValues={{
          TimeMode: SEARCH_DATE_TYPE.建立時間,
        }}>
          <Row align="middle" gutter={[12, 12]}>
            <Col className="w-12">
              <Form.Item name="code" rules={[{
                type: 'string',
                min: 25,
                message: `${i18n.t('orderNumberMustBe25Characters')}`
              }]}>
                <Input maxLength={25} placeholder={`${i18n.t('order')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="agAccount">
                <Input placeholder={`${i18n.t('agentAccount')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="saAccount">
                <Input placeholder={`${i18n.t('masterAgent')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="depositMerchantName">
                <Select
                  className="w-full"
                  placeholder={i18n.t('depositMethod')}
                  options={[
                    { value: '', label: i18n.t('all') },
                    ...((gatewayList && gatewayList.Data) || []).map((item: any) => (
                      { value: item.Id, label: item.Name }
                    ))
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="status">
                <Select
                  className="w-full"
                  placeholder={i18n.t('status')}
                  options={[
                    { value: '', label: `${i18n.t('all')}` },
                    ...common.enumToOptions(DEPOSIT_STATE)
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="mt-1" justify="space-between">
            <Col>
              <Row align="middle" gutter={[12, 12]}>
                <Col className="w-12">
                  <Form.Item name="TimeMode">
                    <Select
                      className="w-full"
                      onChange={handleTimeMode}
                      options={[
                        { value: SEARCH_DATE_TYPE.建立時間, label: `${i18n.t('createTime')}` },
                        { value: SEARCH_DATE_TYPE.審核時間, label: `${i18n.t('auditTime')}` },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <LayoutDateSelect width={375} onChange={setDate} reset={reset}
                  dateFormatDefault={DATE_FORMAT.日期時間} hiddenDateType={[DATE_TYPE.上週]} />
                <Col>
                  <Row align="middle" gutter={[12, 12]}>
                    <Col>
                      <Button type="primary" htmlType="submit" loading={isValidating}>
                        {i18n.t('search')}
                      </Button>
                    </Col>
                    <Col>
                      <Button onClick={onClear}>{i18n.t('clear')}</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col>
              <LayoutUpdateControl callback={mutate} />
            </Col>
          </Row>
        </Form>
        <Spin spinning={isValidating}>
          <Table
            className="mt-1"
            size="middle"
            dataSource={
              depositList ? [
                {
                  key: 1,
                  count: depositList.TotalRecord,
                  total: depositList.TotalRealAmount
                }
              ] : []
            }
            columns={[
              {
                title: i18n.t('count'),
                dataIndex: 'count',
                align: 'right',
                width: '50%'
              },
              {
                title: i18n.t('total'),
                dataIndex: 'total',
                align: 'right',
                width: '50%',
                render: (_, { total }) => common.toFormatNumber(total)
              },
            ]}
            pagination={false}
          />
        </Spin>
        <Row gutter={[16, 16]} className="pb-1 mt-1">
          {
            $p.includes('40605') &&
            <Col>
              <Export url={'/depositticket/agent/list'} param={{
                ...params, PageSize: depositList && depositList.TotalRecord, PageIndex: 1
              }} sheetName={i18n.t('agentDepositQuery')} columns={ExcelColumns.AgentDeposit} />
            </Col>
          }
        </Row>
        <Table
          size="small"
          scroll={{ x: 1500 }}
          loading={isValidating}
          dataSource={depositList?.Data?.map((item: AgentDeposit) => ({ key: item.Id, ...item }))}
          columns={[
            {
              dataIndex: 'Code',
              title: i18n.t('order'),
              fixed: "left",
              width: 300
            },
            {
              dataIndex: 'AgentAccount',
              title: i18n.t('account'),
              width: 200
            },
            {
              dataIndex: 'SAAccount',
              title: i18n.t('generalAgent'),
              width: 200,
            },
            {
              dataIndex: 'GatewayName',
              title: i18n.t('depositMethod'),
              width: 150
            },
            {
              dataIndex: 'BeforeAmount',
              title: i18n.t('previousBalance'),
              align: 'right',
              width: 150,
              render: (val) => common.toFormatNumber(val)
            },
            {
              dataIndex: 'Amount',
              title: i18n.t('depositAmount'),
              align: 'right',
              width: 150,
              render: (val) => common.toFormatNumber(val)
            },
            {
              dataIndex: 'HandlingFee',
              title: i18n.t('paymentProcessingFee'),
              align: 'right',
              width: 150,
              render: (val) => common.toFormatNumber(val, 2)
            },
            {
              dataIndex: 'ExchangeRate',
              title: i18n.t('exchangeRate'),
              align: 'right',
              width: 100,
              render: (val) => val ? common.toFormatNumber(val, 2) : '-'
            },
            {
              dataIndex: 'AfterAmount',
              title: i18n.t('BalanceAfterModification'),
              align: 'right',
              width: 150,
              render: (val) => common.toFormatNumber(val)
            },
            {
              dataIndex: 'CreateDate',
              title: i18n.t('createTime'),
              width: 150,
            },
            {
              dataIndex: 'AuditedDate',
              title: i18n.t('auditTime'),
              width: 150,
              render: (val, { CompletedDate, Status }) => (
                Status === DEPOSIT_STATE.通過 ? val
                  : (Status === DEPOSIT_STATE.成功 ? CompletedDate : '-')
              )
            },
            {
              dataIndex: 'Status',
              title: i18n.t('status'),
              width: 150,
              fixed: 'right',
              render: (val, record) => (
                <>
                  <div className={stateColor(val)}>{DEPOSIT_STATE[val]}</div>
                  {
                    val === DEPOSIT_STATE.失敗 &&
                    <div>{i18n.t('from')} {i18n.t('system')} {i18n.t('operation')}</div>
                  }
                  {
                    val === DEPOSIT_STATE.成功 && !record.FinanceAccount &&
                    <div>{i18n.t('from')} {i18n.t('system')} {i18n.t('operation')}</div>
                  }
                  {
                    record.FinanceAccount &&
                    <div>{i18n.t('from')} {record.FinanceAccount} {i18n.t('operation')}</div>
                  }
                </>
              )
            },
            {
              title: i18n.t('operation'),
              width: 100,
              fixed: 'right',
              render: (_, { Status, key }) => (
                <>
                  {
                    (Status === DEPOSIT_STATE.待財務審核 && $p.includes('40603')) &&
                    <Button className="size-12" type="link" onClick={() => handleDeposit(key)}>{i18n.t('deposit')}</Button>
                  }
                  {
                    (Status !== DEPOSIT_STATE.待財務審核 || !$p.includes('40603')) &&
                    <Button className="size-12" type="link" onClick={() => handleDeposit(key)}>{i18n.t('detail')}</Button>
                  }
                </>
              ),
            },
          ]}
          pagination={false}
        />
        <LayoutPagination total={depositList ? depositList.TotalRecord : 0} setPage={setPage} page={page} />
      </Content>
      <PopupAgentDeposit isOpen={isOpenDeposit} close={() => setIsOpenDeposit(false)} id={id} mutate={mutate} />
    </div >
  );
};

export default PageMain;