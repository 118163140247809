import { Button, Col, Form, Image, Input, Row, Spin, Table, Tooltip } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { LayoutDateSelect, LayoutNav, LayoutPagination, LayoutTabChatLog } from 'components/layout.component';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TYPE } from 'enum/date';
import { ROLE } from 'enum/socket';
import i18n from 'i18n';
import React, { useState } from 'react';
import { ChatPrivateHistoryList } from 'services/chat.service';

interface SearchParams {
  MemberAccount?: string;
  AgentAccount?: string;
  NoCode?: string;
  StartDate?: string;
  EndDate?: string;
}

const PageMain: React.FC = () => {
  const [form] = useForm();
  const [date, setDate] = useState<string[]>();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [reset, setReset] = useState(false);
  const [params, setParams] = useState<SearchParams>({
    StartDate: date ? date[0] : dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    EndDate: date ? date[1] : dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  });
  const { data: PrivateList, isValidating, mutate } = ChatPrivateHistoryList({
    ...params,
    PageIndex: page[0],
    PageSize: page[1]
  })

  const onFinish = (value: {
    memberAccount: string;
    SAaccount: string;
    id: string;
  }) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        MemberAccount: value.memberAccount,
        AgentAccount: value.SAaccount,
        NoCode: value.id,
        StartDate: date[0],
        EndDate: date[1]
      })
    }
    mutate();
  };

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setPage([1, 10]);
    mutate();
  };

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <LayoutTabChatLog activeKey='2' />
        <Form form={form} initialValues={{}} onFinish={onFinish}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  <Form.Item name="memberAccount">
                    <Input className="w-12" placeholder={`${i18n.t('memberAccount')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="SAaccount">
                    <Input className="w-12" placeholder={`${i18n.t('masterAgentAndAgentAccount')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="id">
                    <Input className="w-12" placeholder={`${i18n.t('id')}`} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <LayoutDateSelect width={395} onChange={setDate} reset={reset}
                  dateFormatDefault={DATE_FORMAT.日期時間} hiddenDateType={[DATE_TYPE.本月, DATE_TYPE.上月]} />
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Spin spinning={isValidating}>
                <Table
                  size="middle"
                  scroll={{ x: 1500 }}
                  dataSource={
                    PrivateList ?
                      PrivateList.Data.map((item: ChatPrivateHistory) => (
                        {
                          key: item.Id,
                          sendMessageRole: item.SenderRole,
                          account: item.SenderAccount,
                          ReceiverAccount: item.ReceiverAccount,
                          SendName: item.SendName,
                          SendNoCode: item.SendNoCode,
                          receiveMessageRole: item.ReceiverRole,
                          backOfficeAccount: item.ReceiverAccount,
                          ReceiveName: item.ReceiveName,
                          ReceiveNoCode: item.ReceiveNoCode,
                          Type: item.Type,
                          Content: item.Content,
                          sendTime: item.SendTime,
                          ip: item.IP
                        }
                      )) : []
                  }
                  columns={[
                    {
                      title: i18n.t('sendMessageRole'),
                      dataIndex: 'sendMessageRole',
                      fixed: 'left',
                      width: 150,
                      render: (_, { sendMessageRole }) => ROLE[sendMessageRole]
                    },
                    {
                      title: i18n.t('account'),
                      dataIndex: 'account',
                      fixed: 'left',
                      width: 200,
                    },
                    {
                      title: <>{i18n.t('nickname')} / {i18n.t('id')}</>,
                      width: 200,
                      render: (_, record) => <>{record.SendName || '-'}／{record.SendNoCode || '-'}</>
                    },
                    {
                      title: i18n.t('receiveMessageRole'),
                      dataIndex: 'receiveMessageRole',
                      width: 150,
                      render: (_, { receiveMessageRole }) => ROLE[receiveMessageRole]
                    },
                    {
                      title: i18n.t('account'),
                      dataIndex: 'backOfficeAccount',
                      width: 200,
                    },
                    {
                      title: <>{i18n.t('nickname')} / {i18n.t('id')}</>,
                      width: 200,
                      render: (_, record) => <>{record.ReceiveName || '-'}／{record.ReceiveNoCode || '-'}</>
                    },
                    {
                      title: i18n.t('content'),
                      width: 300,
                      render: (_, record) => (
                        record.Type === 0
                          ? <Tooltip title={record.Content}>
                            <div style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden', textOverflow: 'ellipsis',
                            }}>
                              {record.Content}
                            </div>
                          </Tooltip>
                          : <Image src={record.Content} height={80} />
                      )
                    },
                    {
                      title: i18n.t('sendTime'),
                      dataIndex: 'sendTime',
                      width: 100,
                    },
                    {
                      title: i18n.t('IP'),
                      dataIndex: 'ip',
                      width: 150,
                    },
                  ]}
                  pagination={false}
                />
                <LayoutPagination total={PrivateList ? PrivateList.TotalRecord : 0}
                  setPage={setPage} page={page} />
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;