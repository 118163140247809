
// 權限資料

import { useEffect, useState } from "react";
import { GetPermissionOperationSelfList } from "services/account.service";

const usePerm = () => {
  const { data, mutate, isValidating } = GetPermissionOperationSelfList();
  const _data: any = data

  const [permissionCode, setPermissionCode] = useState<string[]>([]);

  useEffect(() => {
    if (data) {
      const ary = data.map((item: any) => item.OperationId)
      setPermissionCode(ary)
    }
  }, [data])
  
  return {
    data: _data,
    mutate,
    isValidating,
    permissionCode
  }
};

export default usePerm;
