import {
  axiosGetAllData,
  axiosGetAuth,
  axiosPostAuth,
  baseURL,
  useFetchDataCommon
} from "services";

export const PostKycticketUpsert = (data: any) => axiosPostAuth(`${baseURL}/kycticket/upsert`, data);
export const PostKycticketUpdate = (data: any) => axiosPostAuth(`${baseURL}/kycticket/update`, data);
export const ModifyVerificationStatus = (data: any) => axiosPostAuth(`${baseURL}/kycticket/verify`, data);
export const FetchVerificationList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/kycticket/list`, params);
export const GetKycticketOne = (params: any, allow: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/kycticket/one`, params, allow);
