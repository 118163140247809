import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { ViewWithdrawList } from 'components/finance.component';
import { LayoutDateSelect, LayoutNav, LayoutPagination } from 'components/layout.component';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TYPE } from 'enum/date';
import { SEARCH_DATE_TYPE, WITHDRAW_STATE_TYPE } from 'enum/state';
import i18n from 'i18n';
import React, { useState } from 'react';
import { WithdrawmethodVerifyList } from 'services/withdrawmethod.service';
import { enumToOptions, verify } from 'utils/common';

// 會員提款資訊審核

interface SearchParamsType {
  Account?: string;
  Status?: number;
  PhonePrefix?: string;
  PhoneNumber?: string;
  CreateStartDate?: string;
  CreateEndDate?: string;
  VerifiedStartDate?: string;
  VerufuedEndDate?: string;
}

const PageMain: React.FC = () => {
  const [form] = useForm();
  const [startType, setStartType] = useState('CreateStartDate');
  const [endType, setEndType] = useState('CreateEndDate');
  const [date, setDate] = useState<string[]>();
  const [reset, setReset] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);

  const [params, setParams] = useState<SearchParamsType>({
    CreateStartDate: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    CreateEndDate: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss')
  })
  const { data: verifyList, isValidating, mutate } = WithdrawmethodVerifyList({
    ...params,
    Role: 0,
    PageIndex: page[0],
    PageSize: page[1]
  })

  const handleDateType = (type: number) => {
    switch (type) {
      case 0:
        setStartType('CreateStartDate');
        setEndType('CreateEndDate');
        break;
      case 1:
        setStartType('VerifiedStartDate');
        setEndType('VerifiedEndDate');
        break;
    }
  }

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        Account: formData.Account,
        PhoneNumber: formData.PhoneNumber,
        PhonePrefix: formData.PhonePrefix ? `+${formData.PhonePrefix}` : '',
        Status: formData.Status,
        [startType]: date[0],
        [endType]: date[1]
      })
    }
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setPage([1, 10]);
    setReset(!reset);
    setStartType('CreateStartDate');
    setEndType('CreateEndDate');
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="p-2">
        <Form form={form} onFinish={onFinish} initialValues={{
          TimeMode: SEARCH_DATE_TYPE.建立時間,
        }}>
          {/* 搜尋列 */}
          <Row align="middle" gutter={10}>
            <Col>
              <Form.Item name="Account">
                <Input className="w-12" placeholder={`${i18n.t('memberAccount')}`} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="PhonePrefix" rules={verify({ point: 7, required: false })}>
                <Input className="w-12" autoComplete="off" placeholder={`${i18n.t('enterCountryCode')}`} addonBefore={'+'} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="PhoneNumber">
                <Input className="w-12" placeholder={`${i18n.t('phoneNumber')}`} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item className="w-12" name="Status">
                {/* 需求不一樣 */}
                <Select
                  placeholder={i18n.t('status')}
                  options={[
                    { value: "", label: `${i18n.t('all')}` },
                    ...enumToOptions(WITHDRAW_STATE_TYPE)
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="mt-1" align="middle" gutter={10}>
            <Col>
              <Form.Item className="w-12" name="TimeMode">
                <Select
                  onChange={handleDateType}
                  options={[
                    { value: SEARCH_DATE_TYPE.建立時間, label: `${i18n.t('createTime')}` },
                    { value: SEARCH_DATE_TYPE.審核時間, label: `${i18n.t('auditTime')}` },
                  ]}
                />
              </Form.Item>
            </Col>
            <LayoutDateSelect width={395} onChange={setDate}
              hiddenDateType={[DATE_TYPE.上週]} dateFormatDefault={DATE_FORMAT.日期時間} reset={reset} />
            <Col>
              <Button htmlType="submit" type="primary">{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
          {/* 搜尋結果 */}
          <Row className="mt-1">
            <Col span={24}>
              <Spin spinning={isValidating}>
                <ViewWithdrawList data={verifyList} mutate={mutate} />
                <LayoutPagination total={verifyList ? verifyList.TotalRecord : 0} setPage={setPage} />
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content>
    </div >
  );
};

export default PageMain;