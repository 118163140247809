import {
  axiosGetAuth,
  axiosPostAuth,
  baseURL,
  useFetchDataCommon
} from "services";

export const MemberProvider = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/memberprovidersetting/list`, params);
export const UpdateMemberProvider = (params: any) => axiosPostAuth(`${baseURL}/memberprovidersetting/update`, params)
export const UpdateAllMemberProvider = (params: any) => axiosPostAuth(`${baseURL}/memberprovidersetting/update/all`, params)
