import {
  axiosGetAuth,
  axiosPostAuth,
  baseURL,
  useFetchDataCommon
} from "services";

export const SettingList = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/systemsetting/list`, params);
export const SettingInfo = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/systemsetting/one`, params);
export const ModifySetting = (params: any) => axiosPostAuth(`${baseURL}/systemsetting/update`, params);
export const SystemInfo = () => useFetchDataCommon(axiosGetAuth, `${baseURL}/systemsetting/info`);