import { Button, Col, Form, Input, Row, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { PopupAgentBankDetail, PopupDeleteAgentBank } from 'components/agent.component';
import { LayoutNav, LayoutPagination } from 'components/layout.component';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { GetWithdrawmethodAgentList } from 'services/withdrawmethod.service';
import { agentLevelWord, verify } from 'utils/common';

// 銀行卡查詢

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [page, setPage] = useState<number[]>([1, 10]);
  const [withdrawmethodAgentListParams, setWithdrawmethodAgentListParams] = useState<any>({})
  const { data: withdrawmethodAgentList, mutate, isValidating } = GetWithdrawmethodAgentList({
    ...withdrawmethodAgentListParams,
    PageIndex: page[0],
    PageSize: page[1],
  });

  const [form] = useForm();
  const [reset, setReset] = useState<boolean>(false);
  const search = (formData: any) => {
    setPage([1, page[1]]);
    setWithdrawmethodAgentListParams({
      BankAccount: formData.BankAccount,
      PhonePrefix: formData.PhonePrefix ? `+${formData.PhonePrefix}` : '',
      PhoneNumber: formData.PhoneNumber,
      Account: formData.Account,
      CryptoAddress: formData.CryptoAddress
    })
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setPage([1, 10]);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        {/* 搜尋列 */}
        <Form form={form} onFinish={search}>
          <Row align="middle" gutter={10}>
            <Col>
              <Form.Item name="Account">
                <Input className="w-12" placeholder={`${i18n.t("agentAccount")}`} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="PhonePrefix" rules={verify({ point: 7, required: false })}>
                <Input className="w-12" placeholder={`${i18n.t('countryCode')}`} addonBefore={'+'} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="PhoneNumber">
                <Input className="w-12" placeholder={`${i18n.t('phoneNumber')}`} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="BankAccount">
                <Input className="w-12" placeholder={`${i18n.t('bankAccountNumber')}`} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="CryptoAddress">
                <Input className="w-12" placeholder={`${i18n.t('address')}`} />
              </Form.Item>
            </Col>
            <Col><Button type="primary" htmlType="submit">{i18n.t('search')}</Button></Col>
            <Col><Button onClick={onClear}>{i18n.t('clear')}</Button></Col>
          </Row>
        </Form>
        {/* 搜尋結果 */}
        <Row className="mt-1">
          <Col span={24}>
            <Table
              size='middle'
              loading={isValidating}
              dataSource={withdrawmethodAgentList?.Data.map((item: any) => ({ ...item, key: item.Id }))}
              columns={[
                {
                  key: 1,
                  dataIndex: 'AgentAccount',
                  title: i18n.t('agentAccount'),
                  className: 'size-12'
                },
                {
                  key: 2,
                  dataIndex: 'Level',
                  title: i18n.t('agentLevel'),
                  className: 'size-12',
                  render: (val) => agentLevelWord(val)
                },
                {
                  key: 3,
                  dataIndex: 'AGAccount',
                  title: i18n.t('upperAgentMasterAgent'),
                  className: 'size-12',
                  render: (val, { SAAccount }) => `${val}／${SAAccount}`
                },
                {
                  key: 4,
                  dataIndex: 'BankName',
                  title: i18n.t('bankName'),
                  className: 'size-12',
                  render: (val) => val || '-'
                },
                {
                  key: 5,
                  dataIndex: 'Branch',
                  title: i18n.t('openingBranch'),
                  className: 'size-12',
                  render: (val) => val || '-'
                },
                {
                  key: 6,
                  title: i18n.t('phoneNumberAccountNumber'),
                  className: 'size-12',
                  render: (_, { PhonePrefix, PhoneNumber, BankAccount, CryptoAddress }) => (
                    <>
                      <span>{PhoneNumber ? `${PhonePrefix} ${PhoneNumber}` : '-'}</span>／
                      <span>{BankAccount || '-'}</span>／
                      <span>{CryptoAddress || '-'}</span>
                    </>
                  )
                },
                {
                  key: 7,
                  dataIndex: 'Name',
                  title: i18n.t('accountName'),
                  className: 'size-12',
                  render: (val) => val || '-'
                },
                {
                  key: 8,
                  dataIndex: 'State',
                  title: i18n.t('bankProvince'),
                  className: 'size-12',
                  render: (val) => val || '-'
                },
                {
                  key: 9,
                  dataIndex: 'City',
                  title: i18n.t('city'),
                  className: 'size-12',
                  render: (val) => val || '-'
                },
                {
                  key: 10,
                  title: i18n.t('operation'),
                  className: 'size-12',
                  render: (val, record) => (
                    <>
                      <Button className="size-12" type='link' onClick={() => {
                        setIsOpenDetail(true);
                        setCurrentItem(record);
                      }}>{i18n.t('detail')}</Button>
                      {
                        $p.includes('40803') &&
                        <Button className="size-12" type="link" onClick={() => {
                          setIsOpenDelete(true);
                          setCurrentItem(record);
                        }}>{i18n.t('delete')}</Button>
                      }
                    </>
                  )
                },
              ]}
              pagination={false}
            />
            <LayoutPagination total={withdrawmethodAgentList?.TotalRecord} page={page} setPage={setPage} />
          </Col>
        </Row>
        <PopupAgentBankDetail isOpen={isOpenDetail} close={() => setIsOpenDetail(false)} currentItem={currentItem} mutate={mutate} />
        <PopupDeleteAgentBank isOpen={isOpenDelete} close={() => setIsOpenDelete(false)} currentItem={currentItem} mutate={mutate} />
      </Content>
    </div>
  );
};

export default PageMain;