import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { Export, LayoutDateSelect, LayoutNav, LayoutPagination, LayoutUpdateControl } from 'components/layout.component';
import { ViewAgentWithdrawList, ViewAgentWithdrawOverview } from 'components/withdraw.component';
import { ExcelColumns } from 'constants/excel';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TYPE } from 'enum/date';
import { SEARCH_DATE_TYPE, WITHDRAW_STATE } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { AgentWithdrawList } from 'services/withdrawticket.service';
import { enumToOptions } from 'utils/common';

// 代理提款查詢

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();
  const [form] = useForm();
  const [reset, setReset] = useState(false);
  const [date, setDate] = useState<string[]>();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [startDate, setStartDate] = useState('CreateStartDate');
  const [EndDate, setEndDate] = useState('CreateEndDate');

  const [params, setParams] = useState<any>({
    CreateStartDate: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    CreateEndDate: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  });
  const { data: withdrawList, isValidating, mutate } = AgentWithdrawList({
    ...params,
    PageIndex: page[0],
    PageSize: page[1],
  })

  const handleDateType = (type: number) => {
    switch (type) {
      case SEARCH_DATE_TYPE.建立時間:
        setStartDate('CreateStartDate');
        setEndDate('CreateEndDate')
        break;
      case SEARCH_DATE_TYPE.審核時間:
        setStartDate('AuditedStartDate');
        setEndDate('AuditedEndDate')
        break;
    }
  }

  const onFinish = (formData: any) => {
    if (date) {
      setParams({
        Code: formData.order,
        Account: formData.agentAccount,
        AGAccount: formData.masterAgent,
        Status: formData.status,
        [startDate]: date[0],
        [EndDate]: date[1],
      })
    }
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setPage([1, 10]);
    setStartDate('CreateStartDate');
    setEndDate('CreateEndDate');
  }

  return (
    <div id="container">
      <LayoutNav />
      <Form form={form} onFinish={onFinish} initialValues={{
        TimeMode: SEARCH_DATE_TYPE.建立時間
      }}>
        <Content className="ph-2 pt-1">
          {/* 搜尋條件 */}
          <Row align="middle" gutter={10}>
            <Col className="w-12">
              <Form.Item name="order">
                <Input placeholder={`${i18n.t('order')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="agentAccount">
                <Input placeholder={`${i18n.t('agentAccount')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="masterAgent">
                <Input placeholder={`${i18n.t('masterAgent')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="status">
                <Select
                  className="w-full"
                  placeholder={i18n.t('status')}
                  options={[
                    { value: '', label: `${i18n.t('all')}` },
                    ...enumToOptions(WITHDRAW_STATE)
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          {/* 搜尋條件 */}
          <Row className="mt-1" justify="space-between">
            <Col>
              <Row align="middle" gutter={[12, 12]}>
                <Col className="w-12">
                  <Form.Item name="TimeMode">
                    <Select
                      className="w-full"
                      onChange={handleDateType}
                      options={[
                        { value: SEARCH_DATE_TYPE.建立時間, label: `${i18n.t('createTime')}` },
                        { value: SEARCH_DATE_TYPE.審核時間, label: `${i18n.t('auditTime')}` },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <LayoutDateSelect width={375} onChange={setDate}
                  dateFormatDefault={DATE_FORMAT.日期時間} hiddenDateType={[DATE_TYPE.上週]} reset={reset} />
                <Col>
                  <Button type="primary" className="mr-1" htmlType="submit">{i18n.t('search')}</Button>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            <Col>
              <LayoutUpdateControl callback={mutate} />
            </Col>
          </Row>
          {/* 總計 */}
          <Spin spinning={isValidating}>
            <ViewAgentWithdrawOverview data={withdrawList} />
          </Spin>
          {/* 匯出 */}
          <Row gutter={[16, 16]} className="pb-1 mt-1">
            {
              $p.includes('40510') &&
              <Col>
                <Export url={'/withdrawticket/agent/list'} param={{
                  ...params, PageSize: withdrawList && withdrawList.TotalRecord, PageIndex: 1
                }} sheetName={i18n.t('agentWithdrawalQuery')} columns={ExcelColumns.AgentWithdraw} />
              </Col>
            }
          </Row>
          {/* 列表 */}
          <Spin spinning={isValidating}>
            <ViewAgentWithdrawList data={withdrawList} mutate={mutate} />
            <LayoutPagination total={withdrawList ? withdrawList.TotalRecord : 0} setPage={setPage} />
          </Spin>
        </Content>
      </Form>
    </div>
  );
};

export default PageMain;