import { Button, Col, Input, Radio, Row, Spin, Table, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { LayoutNav, LayoutTabPlatform } from 'components/layout.component';
import { RESPONSE_CODE_GAME } from 'constants/response';
import { RESPONSE_TYPE } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { GameCategoryList } from 'services/category.service';
import { ProviderList, ProviderUpdate } from 'services/provider.service';

// 站台設置 遊戲商排序

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();
  const [inputValue, setInputValue] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [editingKey, setEditingKey] = useState<number>(0);
  const [gameType, setGameType] = useState(1);
  const { data: gameCategory } = GameCategoryList({ Status: 1 });
  const { data: gameProvider, mutate } = ProviderList({
    Status: 1,
    CategoryId: gameType,
    PageSize: 100,
    PageIndex: 1
  })

  const onSave = async (info: GameProvider) => {
    try {
      const response = await ProviderUpdate({
        Id: info.Id,
        Name: info.Name,
        Status: info.Status,
        Position: inputValue ? inputValue : info.Position
      })
      if (response.State === RESPONSE_TYPE.成功) {
        message.success(i18n.t('operationSuccess'));
        setEditingKey(0);
        setIsEdit(false);
        setInputValue('');
        mutate();
      } else {
        message.error(RESPONSE_CODE_GAME[response.Message as keyof typeof RESPONSE_CODE_GAME]);
      }
    } catch (error) {
      return;
    }
  }

  const onEdit = (id: number) => {
    setEditingKey(id);
    setIsEdit(true);
    setInputValue('');
  }

  const onCancel = (info: GameProvider) => {
    if (info.Id === editingKey) {
      setIsEdit(false);
      setEditingKey(0);
      setInputValue('');
    }
  }

  const onChangeGameType = (id: number) => {
    setGameType(id);
    setIsEdit(false);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <LayoutTabPlatform activeKey="4" />
        <Row align="middle" gutter={[10, 16]}>
          <Col span={24}>
            <Radio.Group defaultValue={gameType} buttonStyle="solid">
              {
                gameCategory?.map((item: GameCategory) => (
                  <Radio.Button key={item.Id} value={item.Id}
                    onChange={() => onChangeGameType(item.Id)}>
                    {i18n.t(item.Name)}
                  </Radio.Button>
                ))
              }
            </Radio.Group>
          </Col>
          <Col span={24}>
            <Spin spinning={!gameProvider}>
              <Table
                size="middle"
                dataSource={gameProvider?.map((item: GameProvider) => ({ key: item.Id, ...item }))}
                columns={[
                  {
                    title: i18n.t('frontSort'),
                    width: '10%',
                    render: (_: any, record: GameProvider) => (
                      <>
                        {isEdit && record.Id === editingKey
                          ? <Input defaultValue={record.Position}
                            onChange={e => setInputValue(e.target.value)} /> : record.Position}
                      </>
                    )
                  },
                  {
                    dataIndex: 'Name',
                    title: i18n.t('gameProvider'),
                  },
                  {
                    title: i18n.t('operation'),
                    width: '10%',
                    render: (_: any, record: GameProvider) => (
                      <>
                        {
                          (isEdit && record.Id === editingKey)
                            ?
                            <>
                              <Button type="link" onClick={() => onSave(record)}>
                                {i18n.t('done')}
                              </Button>
                              <Button type="link" onClick={() => onCancel(record)}>
                                {i18n.t('cancel')}
                              </Button>
                            </>
                            :
                            <Button type="link" onClick={() => onEdit(record.Id)}>
                              {i18n.t('edit')}
                            </Button>
                        }
                      </>
                    )
                  }
                ].filter((item: any) => !(item.dataIndex === 'sort' && !$p.includes('00302')))}
                pagination={false}
              />
            </Spin>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default PageMain;