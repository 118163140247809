import { CloseOutlined, DownOutlined, EllipsisOutlined, NotificationFilled, SettingFilled, SoundFilled } from '@ant-design/icons';
import type { RadioChangeEvent, TabsProps, UploadFile } from 'antd';
import { Badge, Breadcrumb, Button, Col, DatePicker, Divider, Drawer, FloatButton, Form, Image, Input, InputNumber, Layout, List, Menu, Modal, Radio, Row, Select, Space, Spin, Tabs, Tooltip, Upload, message } from "antd";
import ImgCrop from 'antd-img-crop';
import { Excel } from 'antd-table-saveas-excel';
import { RcFile, UploadChangeParam } from "antd/es/upload";
import Pagination, { PaginationProps } from 'antd/lib/pagination';
import service from 'assets/image/service.png';
import { COOKIE } from 'constants/cookie';
import { RESPONSE_CODE } from 'constants/response';
import dayjs, { Dayjs } from 'dayjs';
import { DATE_FORMAT, DATE_TYPE } from 'enum/date';
import useAuth from 'hooks/auth.hook';
import useIcon from 'hooks/icon.hook';
import usePerm from 'hooks/permission.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import Cookies from 'js-cookie';
import QueryString from 'qs';
import React, { ReactNode, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainerProps } from "react-toastify";
import { useIntersection } from "react-use";
import { baseURL } from 'services';
import { GetChatroomServiceAccountSelf, GetLanguageList } from 'services/account.service';
import { ChatSetting } from 'services/chat.service';
import { GetContentsPublishList } from 'services/contents.service';
import { enumToOptions } from 'utils/common';
import $package from "../../package.json";

export const MainLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Layout style={{ background: "#fff" }}>
      {children}
      <FloatButton.BackTop visibilityHeight={1} />
    </Layout>
  )
};

export const pageModalStyle = {
  overlay: {
    zIndex: 999,
  },
  content: {
    border: "none",
    height: "100%",
    inset: 0,
    padding: 0,
    width: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
};

export const toastConfig: ToastContainerProps = {
  autoClose: 1500,
  closeOnClick: true,
  draggable: false,
  hideProgressBar: true,
  pauseOnHover: true,
  position: "top-center",
};

export const InviewBlock: React.FC<{
  callback: Function;
}> = ({ callback }) => {
  const intersectionRef = React.useRef(null);
  const intersection = useIntersection(intersectionRef, {
    rootMargin: "0px",
    threshold: 0.5,
  });
  useEffect(() => {
    if (intersection?.isIntersecting) {
      callback();
    }
  }, [intersection?.isIntersecting, callback]);
  return (
    <div
      ref={intersectionRef}
      style={{ width: "100%", height: "10px", backgroundColor: "grey" }}
    ></div>
  );
};

export const LoadingBlock: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "48rem",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ margin: "1rem 0", color: "#2991a7" }}>Loading</div>
      </div>
    </div>
  );
};

export const LayoutNav: React.FC<{
  id?: number;
  account?: string;
  hidden?: boolean;
}> = ({ id, account, hidden }) => {

  const navigate = useNavigate();
  const icon = useIcon();
  const auth = useAuth();
  const admin: AdminInfo = auth.getAdmin();
  const location = useLocation();

  // 網址變數
  const {
    id: _id,
    account: _account,
    type: _type,
    dateType: _dateType,
  } = useParams<any>();

  const [announcement, setAnnouncement] = useState();

  const [isOpenAnnouncement, setIsOpenAnnouncement] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);
  const { data: SystemAnnouncement, isValidating, mutate } = GetContentsPublishList({
    Lang: Cookies.get('lang'),
    PageIndex: page[0],
    PageSize: page[1]
  })

  const { data: LanguageList } = GetLanguageList();
  const [lang, setLang] = useState(Cookies.get('lang') || document.documentElement.lang);
  const { t, i18n } = useTranslation();

  const $n = (url: any) => navigate(url);
  const { data: $s, isCashVersion: $sc } = useSite();
  const { permissionCode: $p } = usePerm();

  const { data: SettingInfo } = ChatSetting();
  useEffect(() => {
    if (SettingInfo) {
      Cookies.set('defaultAvatar', SettingInfo.DefaultAvatar);
      Cookies.set('defaultNickname', SettingInfo.DefaultNickname);
    }
  }, [SettingInfo])

  // 進入聊天室的暱稱檢查
  const [callChat, setCallChat] = useState(false);
  const { data: chatAccount } = GetChatroomServiceAccountSelf({}, callChat)
  useEffect(() => {
    if (chatAccount) {
      if (chatAccount.NickName) {
        // 阻止同瀏覽器多開
        window.open('/#/chat/online-service', 'chat');
      } else {
        message.error(i18n.t('pleaseSetChatNickname'));
        navigate('/chat/account');
      }
      setCallChat(false)
    }
  }, [chatAccount])

  // 選單
  const menuItems: any = [
    {
      key: '/',
      label:
        <Row align="middle" gutter={10} onClick={() => $n('/')}>
          <Col>
            <Image src={Cookies.get('logo02') || icon.logo02} width={150} height={30} preview={false} />
          </Col>
        </Row >
    },
    ($p.includes('10101') || $p.includes('10701') || $p.includes('10801') || $p.includes('10901') ||
      $p.includes('11001') || $p.includes('11101') || $p.includes('11301')) && {
      key: 'member',
      label:
        <div className="item-style" >
          <span>{i18n.t('member')}</span>
          <span style={{ marginLeft: 2 }}><DownOutlined /></span>
        </div>,
      children: [
        $p.includes('10101') && { key: 11, label: i18n.t('memberList'), onClick: () => $n('/member') },
        $p.includes('10701') && { key: 12, label: i18n.t('accountRecords'), onClick: () => $n('/member/account') },
        ($p.includes('10801') && $sc) && { key: 13, label: i18n.t('realNameVerification'), onClick: () => $n('/member/verification') },
        ($p.includes('10901') && $sc) && { key: 14, label: i18n.t('withdrawalInfoAudit'), onClick: () => $n('/member/withdraw') },
        $p.includes('11001') && { key: 15, label: i18n.t('instantMessages'), onClick: () => $n('/member/message') },
        $p.includes('11101') && { key: 16, label: i18n.t('memberIPCheck'), onClick: () => $n('/member/ip-member') },
        $p.includes('11301') && { key: 17, label: i18n.t('gameIPCheck'), onClick: () => $n('/member/ip-game') },
      ],
    },
    ($p.includes('20101') || $p.includes('20201') || $p.includes('20301') || $p.includes('20403') ||
      $p.includes('20701') || $p.includes('21001') || $p.includes('21201')) && {
      key: 'finance',
      label:
        <div className="item-style">
          <span>{i18n.t('financial')}</span>
          <span style={{ marginLeft: 2 }}><DownOutlined /></span>
        </div>,
      children: [
        ($p.includes('20101') && $sc) && { key: 21, label: i18n.t('withdrawalQuery'), onClick: () => $n('/finance/withdraw') },
        ($p.includes('20201') && $sc) && { key: 22, label: i18n.t('depositQuery'), onClick: () => $n('/finance/deposit') },
        $p.includes('20301') && { key: 23, label: i18n.t('adjustmentQuery'), onClick: () => $n('/finance/adjust') },
        (($p.includes('20403') || $p.includes('20701')) && $sc) && { key: 24, label: i18n.t('offlineDepositWithdraw'), onClick: () => $n('/finance/transfer') },
        (($p.includes('21001') || $p.includes('21201')) && $sc) && { key: 25, label: i18n.t('paymentManagement'), onClick: () => $n('/finance/payment') },
      ],
    },
    ($p.includes('30101') || $p.includes('30201') || $p.includes('30301') || $p.includes('30401') ||
      $p.includes('30501')) && {
      key: 'promotion',
      label:
        <div className="item-style">
          <span>{i18n.t('promotion')}</span>
          <span style={{ marginLeft: 2 }}><DownOutlined /></span>
        </div>,
      children: [
        $p.includes('30101') && { key: 31, label: i18n.t('promotionManagement'), onClick: () => $n('/promotion') },
        $p.includes('30201') && { key: 32, label: i18n.t('memberLevelSetting'), onClick: () => $n('/promotion/level') },
        $p.includes('30301') && { key: 33, label: i18n.t('promotionQuery'), onClick: () => $n('/promotion/discount') },
        $p.includes('30401') && { key: 34, label: i18n.t('rebatePromotionQuery'), onClick: () => $n('/promotion/rebate') },
        $p.includes('30501') && { key: 35, label: i18n.t('memberLevelPromotionQuery'), onClick: () => $n('/promotion/vip') },
      ],
    },
    ($p.includes('40101') || $p.includes('40501') || $p.includes('40601') || $p.includes('40701') ||
      $p.includes('40801') || $p.includes('40901') || $p.includes('41001') || $p.includes('41101')) && {
      key: 'agent',
      label:
        <div className="item-style">
          <span>{i18n.t('agent')}</span>
          <span style={{ marginLeft: 2 }}><DownOutlined /></span>
        </div>,
      children: [
        $p.includes('40101') && { key: 41, label: i18n.t('generalAgentList'), onClick: () => $n('/agent') },
        ($p.includes('40501') && $sc) && { key: 42, label: i18n.t('agentWithdrawalQuery'), onClick: () => $n('/agent/withdraw') },
        ($p.includes('40601') && $sc) && { key: 43, label: i18n.t('agentDepositQuery'), onClick: () => $n('/agent/deposit') },
        $p.includes('40701') && { key: 44, label: i18n.t('agentAdjustmentQuery'), onClick: () => $n('/agent/adjust') },
        ($p.includes('40801') && $sc) && { key: 45, label: i18n.t('agentBankCardQuery'), onClick: () => $n('/agent/card') },
        $p.includes('40901') && { key: 46, label: i18n.t('agentReportAudit'), onClick: () => $n('/agent/confirm') },
        $p.includes('41001') && { key: 47, label: i18n.t('agentWalletRecord'), onClick: () => $n('/agent/wallet') },
        $p.includes('41101') && { key: 48, label: i18n.t('agentTag'), onClick: () => $n('/agent/tag') },
      ],
    },
    ($p.includes('50101') || $p.includes('50205') || $p.includes('50301') || $p.includes('50403')) && {
      key: 'front',
      label:
        <div className="item-style">
          <span>{i18n.t('frontendPage')}</span>
          <span style={{ marginLeft: 2 }}><DownOutlined /></span>
        </div>,
      children: [
        $p.includes('50101') && { key: 51, label: i18n.t('frontendText'), onClick: () => $n('/front') },
        $p.includes('50205') && { key: 52, label: i18n.t('carouselImage'), onClick: () => $n('/front/carousel') },
        $p.includes('50301') && { key: 53, label: i18n.t('popupSetting'), onClick: () => $n('/front/popup') },
        $p.includes('50403') && { key: 81, label: i18n.t('SEOSetting'), onClick: () => $n('/seo') },
      ],
    },
    ($p.includes('60101') || $p.includes('60301') || $p.includes('60401') || $p.includes('60501') ||
      $p.includes('60601') || $p.includes('60701') || $p.includes('60801')) && {
      key: 'report',
      label:
        <div className="item-style">
          <span>{i18n.t('statisticalReports')}</span>
          <span style={{ marginLeft: 2 }}><DownOutlined /></span>
        </div>,
      children: [
        $p.includes('60101') && { key: 61, label: i18n.t('bettingReports'), onClick: () => $n('/report') },
        { key: 62, label: i18n.t('dataOverview'), onClick: () => $n('/report/summary') },
        $p.includes('60301') && { key: 63, label: i18n.t('operationReports'), onClick: () => $n('/report/business') },
        $p.includes('60401') && { key: 64, label: i18n.t('gameStatisticalAnalysis'), onClick: () => $n('/report/game') },
        $p.includes('60501') && { key: 65, label: i18n.t('agentStatisticalAnalysis'), onClick: () => $n('/report/agent') },
        $p.includes('60601') && { key: 66, label: i18n.t('agentPromotionStatistical'), onClick: () => $n('/report/promotion') },
        $p.includes('60701') && { key: 67, label: i18n.t('memberRanking'), onClick: () => $n('/report/ladder') },
        $p.includes('60801') && { key: 68, label: i18n.t('winAndLossReports'), onClick: () => $n('/report/ggr') },
      ],
    },
    ($p.includes('70101') || $p.includes('70201')) && {
      key: 'chat',
      label:
        <div className="item-style">
          <span>{i18n.t('chatRoom')}</span>
          <span style={{ marginLeft: 2 }}><DownOutlined /></span>
        </div>,
      children: [
        $p.includes('70101') && { key: 71, label: i18n.t('chatRoomLog'), onClick: () => navigate('/chat') },
        $p.includes('70201') && { key: 72, label: i18n.t('chatRoomSettings'), onClick: () => navigate('/chat/account') },
      ],
    },
    ($p.includes('00101') || $p.includes('00701') || $p.includes('00901') || $p.includes('01101') ||
      $p.includes('01201') || $p.includes('01301')) && {
      key: 'advanced',
      label:
        <div className="item-style">
          <span>{i18n.t('advanced')}</span>
          <span style={{ marginLeft: 2 }}><DownOutlined /></span>
        </div>,
      children: [
        $p.includes('00101') && { key: 91, label: i18n.t('platformSettings'), onClick: () => $n('/advanced') },
        $p.includes('00701') && { key: 92, label: i18n.t('permissionManagement'), onClick: () => $n('/advanced/permission_name') },
        $p.includes('00901') && { key: 93, label: i18n.t('announcementSettings'), onClick: () => $n('/advanced/announcement') },
        $p.includes('01101') && { key: 94, label: i18n.t('maintenanceSettings'), onClick: () => $n('/advanced/maintenance') },
        $p.includes('01201') && { key: 95, label: i18n.t('gameManagement'), onClick: () => $n('/advanced/game') },
        $p.includes('01301') && { key: 96, label: i18n.t('operationRecords'), onClick: () => $n('/advanced/operation') },
      ],
    },
  ];

  const adminItems = [
    {
      key: 'service',
      label: (
        <Image src={service} width={25} height={20} preview={false} onClick={() => setCallChat(true)} />
      ),
    },
    {
      key: 'language',
      label:
        <div className="item-style">
          <span>{i18n.t(`${lang}`)}</span>
          <span style={{ marginLeft: 2 }}><DownOutlined /></span>
        </div>,
      children:
        LanguageList ?
          [...LanguageList
            // 暫時只顯示簡中與繁中
            .filter((item: LanguageList) => item.Code === 'zh-TW' || item.Code === 'zh-CN')
            .map((item: LanguageList) => {
              return {
                key: item.Id,
                label: i18n.t(item.Code),
                onClick: async () => {

                  await i18n.changeLanguage(item.Code);
                  setLang(item.Code);
                  Cookies.set('lang', item.Code);
                  window.location.reload();

                }
              }
            })] : []
    },
    {
      key: 'mailbox',
      label: (
        <Badge style={{ background: '#FF4242', boxShadow: 'none' }} offset={[1, 1]}>
          <Image src={icon.mailbox} width={25} height={25} preview={false} />
        </Badge>
      ),
      onClick: () => {
        mutate();
        setIsOpenAnnouncement(true);
      }
    },
    {
      key: 'admin',
      label:
        <Row align="middle">
          <Col>{admin.Account}</Col>
        </Row>,
      children: [
        {
          key: 'password',
          label: i18n.t('modificationPassword'),
          onClick: () => navigate('/password')
        },
        {
          key: 'logout',
          label:
            <Row align="middle" gutter={8}>
              <Col><Image src={icon.logout} preview={false} /></Col>
              <Col>{i18n.t('logout')}</Col>
            </Row>,
          onClick: () => {
            auth.logout();
            navigate('/login');
          }
        },
        {
          key: 'ver',
          label:
            <Row justify="center" style={{ borderTop: '1px solid #666666', color: '#FFF', cursor: 'default' }}>
              <Col>{`${i18n.t('版本')}(${$package.version})`}</Col>
            </Row>
        },
      ],
    }
  ]

  const breadcrumbItems = new Map();

  breadcrumbItems.set('/', [
    { title: i18n.t('home') },
  ]);
  breadcrumbItems.set(`/${_dateType}`, [
    { title: i18n.t('home') },
  ]);
  breadcrumbItems.set('/member', [
    { title: i18n.t('member') },
    { title: i18n.t('memberList') }
  ]);
  breadcrumbItems.set(`${'/member/info'}/${id}`, [
    { title: i18n.t('member') },
    { title: <Link to={'/member'}>{i18n.t('memberList')}</Link> },
    { title: account }
  ]);
  breadcrumbItems.set(`${'/member/provider'}/${id}`, [
    { title: i18n.t('member') },
    { title: <Link to={'/member'}>{i18n.t('memberList')}</Link> },
    { title: account }
  ]);
  breadcrumbItems.set(`${'/member/contact'}/${id}`, [
    { title: i18n.t('member') },
    { title: <Link to={'/member'}>{i18n.t('memberList')}</Link> },
    { title: account }
  ]);
  breadcrumbItems.set(`${'/member/bank'}/${id}`, [
    { title: i18n.t('member') },
    { title: <Link to={'/member'}>{i18n.t('memberList')}</Link> },
    { title: account }
  ]);
  breadcrumbItems.set(`${'/member/rebate'}/${id}`, [
    { title: i18n.t('member') },
    { title: <Link to={'/member'}>{i18n.t('memberList')}</Link> },
    { title: account }
  ]);
  breadcrumbItems.set('/member/account', [
    { title: i18n.t('member') },
    { title: i18n.t('accountRecords') }
  ]);
  breadcrumbItems.set(`${'/member/account/'}${_account}`, [
    { title: i18n.t('member') },
    { title: <Link to={'/member'}>{i18n.t('memberList')}</Link> },
    { title: i18n.t('accountRecord') }
  ]);
  breadcrumbItems.set('/member/verification', [
    { title: i18n.t('member') },
    { title: i18n.t('realNameVerification') }
  ]);
  breadcrumbItems.set('/member/withdraw', [{ title: i18n.t('member') }, { title: i18n.t('withdrawalInfoAudit') }]);
  breadcrumbItems.set('/member/message', [{ title: i18n.t('member') }, { title: i18n.t('instantMessages') }]);
  breadcrumbItems.set('/member/ip-member', [{ title: i18n.t('member') }, { title: i18n.t('memberIPCheck') }]);
  breadcrumbItems.set('/member/block-ip-member', [
    { title: i18n.t('member') },
    { title: <Link to={'/member/ip-member'}>{i18n.t('memberIPCheck')}</Link> },
    { title: i18n.t('IPBlockManagement') },
  ]);
  breadcrumbItems.set('/member/block-ip-game', [
    { title: i18n.t('member') },
    { title: <Link to={'/member/ip-game'}>{i18n.t('gameIPCheck')}</Link> },
    { title: i18n.t('IPBlockManagement') },
  ]);
  breadcrumbItems.set('/member/ip-game', [{ title: i18n.t('member') }, { title: <Link to={'/member/ip-game'}>{i18n.t('gameIPCheck')}</Link> }]);
  breadcrumbItems.set('/finance/withdraw', [{ title: i18n.t('financial') }, { title: i18n.t('withdrawalQuery') }]);
  breadcrumbItems.set('/finance/deposit', [{ title: i18n.t('financial') }, { title: i18n.t('depositQuery') }]);
  breadcrumbItems.set('/finance/adjust', [{ title: i18n.t('financial') }, { title: i18n.t('adjustmentQuery') }]);
  breadcrumbItems.set('/finance/payment', [{ title: i18n.t('financial') }, { title: i18n.t('paymentManagement') }]);
  breadcrumbItems.set(`${'/finance/payment-depositTotal/'}${_id}`, [
    { title: i18n.t('financial') },
    { title: <Link to={'/finance/payment'}>{i18n.t('paymentManagement')}</Link> },
    { title: i18n.t(`${_id}`) }
  ]);
  breadcrumbItems.set(`${'/finance/payment-withdrawTotal/'}${_id}`, [
    { title: i18n.t('financial') },
    { title: <Link to={'/finance/payment'}>{i18n.t('paymentManagement')}</Link> },
    { title: i18n.t(`${_id}`) }
  ]);
  breadcrumbItems.set('/finance/transfer', [{ title: i18n.t('financial') }, { title: i18n.t('offlineDepositWithdraw') }]);
  breadcrumbItems.set(`${'/finance/transfer-depositTotal/'}${_id}`, [
    { title: i18n.t('financial') },
    { title: <Link to={'/finance/transfer'}>{i18n.t('offlineDepositWithdraw')}</Link> },
    { title: i18n.t(`${account}`) }
  ]);
  breadcrumbItems.set(`${'/finance/transfer-withdrawTotal/'}${_id}`, [
    { title: i18n.t('financial') },
    { title: <Link to={'/finance/transfer'}>{i18n.t('offlineDepositWithdraw')}</Link> },
    { title: i18n.t(`${account}`) }
  ]);
  breadcrumbItems.set('/finance/virtual-deposit', [{ title: i18n.t('financial') }, { title: i18n.t('virtualCurrency') }]);
  breadcrumbItems.set('/finance/virtual-withdraw', [{ title: i18n.t('financial') }, { title: i18n.t('virtualCurrency') }]);
  breadcrumbItems.set(`${'/finance/virtual-info/'}${_id}`, [
    { title: i18n.t('financial') },
    { title: <Link to="" onClick={() => navigate(-1)}>{i18n.t('virtualCurrency')}</Link> },
    { title: i18n.t(`${_id}`) },
  ]);
  breadcrumbItems.set('/promotion', [{ title: i18n.t('promotion') }, { title: i18n.t('promotionManagement') }]);
  breadcrumbItems.set('/promotion/bonus', [{ title: i18n.t('promotion') }, { title: <Link to={'/promotion'}>{i18n.t('promotionManagement')}</Link> }, { title: i18n.t('referralReward') }]);
  breadcrumbItems.set(`/promotion/edit/mission/${_id}`, [{ title: i18n.t('promotion') }, { title: <Link to={'/promotion'}>{i18n.t('promotionManagement')}</Link> }, { title: i18n.t('dailyCheckIn') }, { title: i18n.t('edit') }, { title: _id }]);
  breadcrumbItems.set('/promotion/add/mission', [{ title: i18n.t('promotion') }, { title: <Link to={'/promotion'}>{i18n.t('promotionManagement')}</Link> }, { title: i18n.t('dailyCheckIn') }, { title: i18n.t('add') }]);
  breadcrumbItems.set('/promotion/other', [{ title: i18n.t('promotion') }, { title: <Link to={'/promotion'}>{i18n.t('promotionManagement')}</Link> }, { title: i18n.t('add') }]);
  breadcrumbItems.set(`${'/promotion/edit-other/'}${_id}`, [{ title: i18n.t('promotion') }, { title: <Link to={'/promotion'}>{i18n.t('promotionManagement')}</Link> }, { title: i18n.t('edit') }]);
  breadcrumbItems.set('/promotion/level', [{ title: i18n.t('promotion') }, { title: i18n.t('memberLevelSetting') }]);
  breadcrumbItems.set('/promotion/discount', [{ title: i18n.t('promotion') }, { title: i18n.t('promotionQuery') }]);
  breadcrumbItems.set('/promotion/rebate', [{ title: i18n.t('promotion') }, { title: i18n.t('rebatePromotionQuery') }]);
  breadcrumbItems.set('/promotion/vip', [{ title: i18n.t('promotion') }, { title: i18n.t('memberLevelPromotionQuery') }]);
  breadcrumbItems.set('/report', [{ title: i18n.t('statisticalReports') }, { title: i18n.t('bettingReports') }]);
  breadcrumbItems.set(`/report/${_account}`, [{ title: i18n.t('statisticalReports') }, { title: i18n.t('bettingReports') }]);
  breadcrumbItems.set('/report/summary', [{ title: i18n.t('statisticalReports') }, { title: i18n.t('dataOverview') }]);
  breadcrumbItems.set('/report/business', [{ title: i18n.t('statisticalReports') }, { title: i18n.t('operationReports') }]);
  breadcrumbItems.set('/report/game', [{ title: i18n.t('statisticalReports') }, { title: i18n.t('gameStatisticalAnalysis') }]);
  breadcrumbItems.set('/report/agent', [{ title: i18n.t('statisticalReports') }, { title: i18n.t('agentStatisticalAnalysis') }]);
  breadcrumbItems.set('/report/promotion', [{ title: i18n.t('statisticalReports') }, { title: i18n.t('agentPromotionStatistical') }]);
  breadcrumbItems.set('/report/ladder', [{ title: i18n.t('statisticalReports') }, { title: i18n.t('memberRanking') }]);
  breadcrumbItems.set('/report/ggr', [{ title: i18n.t('statisticalReports') }, { title: i18n.t('winAndLossReports') }]);
  breadcrumbItems.set('/agent', [{ title: i18n.t('agent') }, { title: i18n.t('generalAgentList') }]);
  breadcrumbItems.set('/agent/add', [{ title: i18n.t('agent') }, { title: <Link to={'/agent'}>{i18n.t('generalAgentList')}</Link> }, { title: i18n.t('add') }]);
  breadcrumbItems.set('/chat', [{ title: i18n.t('chatRoom') }, { title: i18n.t('chatRoomLog') }]);
  breadcrumbItems.set('/chat/private', [{ title: i18n.t('chatRoom') }, { title: i18n.t('chatRoomLog') }]);
  breadcrumbItems.set('/chat/account', [{ title: i18n.t('chatRoom') }, { title: i18n.t('chatRoomSettings') }]);
  breadcrumbItems.set('/chat/reply', [{ title: i18n.t('chatRoom') }, { title: i18n.t('chatRoomSettings') }]);
  breadcrumbItems.set('/chat/setting', [{ title: i18n.t('chatRoom') }, { title: i18n.t('chatRoomSettings') }]);
  breadcrumbItems.set(`/agent/edit/${_id}`, [
    { title: i18n.t('agent') },
    { title: <Link to={'/agent'}>{i18n.t('generalAgentList')}</Link> },
    { title: i18n.t('edit') }
  ]);
  breadcrumbItems.set(`/agent/settle/${_id}`, [
    { title: i18n.t('agent') },
    { title: <Link to={'/agent'}>{i18n.t('generalAgentList')}</Link> },
    { title: i18n.t('settlementReports') },
    { title: account }
  ]);
  breadcrumbItems.set(`/agent/daily/${_id}`, [
    { title: i18n.t('agent') },
    { title: <Link to={'/agent'}>{i18n.t('generalAgentList')}</Link> },
    { title: i18n.t('dailyReport') },
    { title: account }
  ]);
  breadcrumbItems.set(`/agent/add-agent/${_id}`, [
    { title: i18n.t('agent') },
    { title: <Link to={'/agent'}>{i18n.t('generalAgentList')}</Link> },
    { title: i18n.t('addAgent') }
  ]);
  breadcrumbItems.set('/agent/withdraw', [{ title: i18n.t('agent') }, { title: i18n.t('agentWithdrawalQuery') }]);
  breadcrumbItems.set('/agent/deposit', [{ title: i18n.t('agent') }, { title: i18n.t('agentDepositQuery') }]);
  breadcrumbItems.set('/agent/adjust', [{ title: i18n.t('agent') }, { title: i18n.t('agentAdjustmentQuery') }]);
  breadcrumbItems.set('/agent/card', [{ title: i18n.t('agent') }, { title: i18n.t('agentBankCardQuery') }]);
  breadcrumbItems.set('/agent/confirm', [{ title: i18n.t('agent') }, { title: i18n.t('agentReportAudit') }]);
  breadcrumbItems.set('/agent/wallet', [{ title: i18n.t('agent') }, { title: i18n.t('agentWalletRecord') }]);
  breadcrumbItems.set('/agent/confirm/report', [
    { title: i18n.t('agent') },
    { title: <Link to={'/agent/confirm'}>{i18n.t('agentReportAudit')}</Link> },
    { title: i18n.t('settlementReports') }
  ]);
  breadcrumbItems.set('/agent/tag', [{ title: i18n.t('agent') }, { title: i18n.t('agentTag') }]);
  breadcrumbItems.set('/front', [{ title: i18n.t('frontendPage') }, { title: i18n.t('frontendText') }]);
  breadcrumbItems.set('/front/sub', [{ title: i18n.t('frontendPage') }, { title: i18n.t('frontendText') }]);
  breadcrumbItems.set('/front/carousel', [{ title: i18n.t('frontendPage') }, { title: i18n.t('carouselImage') }]);
  breadcrumbItems.set('/front/popup', [{ title: i18n.t('frontendPage') }, { title: i18n.t('popupSetting') }]);
  breadcrumbItems.set('/seo', [{ title: i18n.t('frontendPage') }, { title: i18n.t('SEOSetting') }]);
  breadcrumbItems.set('/seo/add', [
    { title: i18n.t('frontendPage') },
    { title: <Link to={'/seo'}>{i18n.t('SEOSetting')}</Link> },
    { title: i18n.t('add') }
  ]);
  breadcrumbItems.set(`/seo/edit/${_type}`, [
    { title: i18n.t('frontendPage') },
    { title: <Link to={'/seo'}>{i18n.t('SEOSetting')}</Link> },
    { title: i18n.t('edit') }
  ]);
  breadcrumbItems.set('/advanced', [
    { title: i18n.t('advanced') },
    { title: i18n.t('platformSettings') },
    { title: i18n.t('platformData') },
  ]);
  breadcrumbItems.set('/advanced/platform_domain', [
    { title: i18n.t('advanced') },
    { title: i18n.t('platformSettings') },
    { title: i18n.t('domainSettings') },
  ]);
  breadcrumbItems.set('/advanced/platform_gameData', [
    { title: i18n.t('advanced') },
    { title: i18n.t('platformSettings') },
    { title: i18n.t('gameData') },
  ]);
  breadcrumbItems.set('/advanced/platform_providerSort', [
    { title: i18n.t('advanced') },
    { title: i18n.t('platformSettings') },
    { title: i18n.t('gameProviderSorting') },
  ]);
  breadcrumbItems.set('/advanced/platform_gameList', [
    { title: i18n.t('advanced') },
    { title: i18n.t('platformSettings') },
    { title: i18n.t('gameList') },
  ]);
  breadcrumbItems.set('/advanced/platform_license', [
    { title: i18n.t('advanced') },
    { title: i18n.t('platformSettings') },
    { title: i18n.t('uploadLicense') },
  ]);
  breadcrumbItems.set('/advanced/platform_gameRebate', [
    { title: i18n.t('advanced') },
    { title: i18n.t('platformSettings') },
    { title: i18n.t('gameProviderCashbackSettings') },
  ]);
  breadcrumbItems.set('/advanced/permission_name', [
    { title: i18n.t('advanced') },
    { title: i18n.t('permissionManagement') },
  ]);
  breadcrumbItems.set(`/advanced/permission_name_edit/${_id}`, [
    { title: i18n.t('advanced') },
    { title: <Link to={'/advanced/permission_name'}>{i18n.t('permissionManagement')}</Link> },
    { title: i18n.t('edit') },
  ]);
  breadcrumbItems.set(`/advanced/permission_backOfficeAccount`, [
    { title: i18n.t('advanced') },
    { title: i18n.t('permissionManagement') },
  ]);
  breadcrumbItems.set(`/advanced/permission_backOfficeAccount/${_id}`, [
    { title: i18n.t('advanced') },
    { title: i18n.t('permissionManagement') },
  ]);
  breadcrumbItems.set('/advanced/announcement', [
    { title: i18n.t('advanced') },
    { title: i18n.t('announcementSettings') },
  ]);
  breadcrumbItems.set('/advanced/announcement/add', [
    { title: i18n.t('advanced') },
    { title: <Link to={'/advanced/announcement'}>{i18n.t('announcementSettings')}</Link> },
    { title: i18n.t('add') }
  ]);
  breadcrumbItems.set(`/advanced/announcement/edit/${_id}`, [
    { title: i18n.t('advanced') },
    { title: <Link to={'/advanced/announcement'}>{i18n.t('announcementSettings')}</Link> },
    { title: i18n.t('edit') }
  ]);
  breadcrumbItems.set('/advanced/announcement/system/add', [
    { title: i18n.t('advanced') },
    { title: <Link to={'/advanced/announcement'}>{i18n.t('announcementSettings')}</Link> },
    { title: <Link to={'/advanced/announcement_system'}>{i18n.t('systemAnnouncement')}</Link> },
    { title: i18n.t('add') }
  ]);
  breadcrumbItems.set(`/advanced/announcement/system/edit/${_id}`, [
    { title: i18n.t('advanced') },
    { title: <Link to={'/advanced/announcement'}>{i18n.t('announcementSettings')}</Link> },
    { title: <Link to={'/advanced/announcement_system'}>{i18n.t('systemAnnouncement')}</Link> },
    { title: i18n.t('edit') }
  ]);
  breadcrumbItems.set('/advanced/announcement_system', [
    { title: i18n.t('advanced') },
    { title: <Link to={'/advanced/announcement'}>{i18n.t('announcementSettings')}</Link> },
    { title: i18n.t('systemAnnouncement') },
  ]);
  breadcrumbItems.set('/advanced/maintenance', [
    { title: i18n.t('advanced') },
    { title: i18n.t('maintenanceSettings') },
  ]);
  breadcrumbItems.set('/advanced/game', [
    { title: i18n.t('advanced') },
    { title: i18n.t('gameManagement') },
  ]);
  breadcrumbItems.set('/advanced/operation', [
    { title: i18n.t('advanced') },
    { title: i18n.t('operationRecords') },
  ]);

  return (
    <>
      <Helmet>
        <link rel="icon" href={$s && $s.Logo1} type="image/x-icon" />
      </Helmet >
      <Row
        align="middle"
        justify="space-between"
        className="bg-color-02 color-01"
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1000,
        }}
      >
        <Col span={18}>
          <Menu
            style={{ display: 'flex', alignItems: 'center' }}
            className="bg-color-02 color-01"
            mode="horizontal"
            theme="dark"
            items={menuItems}
          />
        </Col>
        <Col span={6}>
          <Menu
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
            className="bg-color-02 color-01"
            mode="horizontal"
            theme="dark"
            items={adminItems}
          />
        </Col>
      </Row>

      {
        !hidden &&
        <>
          <Breadcrumb
            className="pt-1 pl-2"
            separator=">"
            items={breadcrumbItems.get(location.pathname)}
          />
          <Divider className="mb-1 mt-1" />
        </>
      }
      {/* drawer */}
      {/* <Notification open={isOpenNotification} setNotifications={setNotifications}
        close={() => setIsOpenNotification(false)} /> */}
      <Announcement open={isOpenAnnouncement} setAnnouncement={setAnnouncement} loading={isValidating}
        close={() => setIsOpenAnnouncement(false)} SystemAnnouncement={SystemAnnouncement} page={page} setPage={setPage} />
    </>
  );
};

// 系統公告 (右側欄)
export const Announcement: React.FC<{
  open: boolean;
  close: () => void;
  setAnnouncement: any;
  SystemAnnouncement: {
    Data: any;
    TotalRecord: number;
  };
  loading: any;
  page: number[];
  setPage: any;
}> = ({ open, close, setAnnouncement, SystemAnnouncement, loading, page, setPage }) => {
  const icon = useIcon();
  const [isFirstOpen, setIsFirstOpen] = useState(true);
  const [data, setData] = useState<Contents[]>();
  const [details, setDetails] = useState<Contents>();
  const [childrenDrawer, setChildrenDrawer] = useState(false);

  useEffect(() => {
    if (SystemAnnouncement) {
      setData(SystemAnnouncement.Data);
    }
  }, [SystemAnnouncement])

  const showChildrenDrawer = (key: number) => {
    setChildrenDrawer(true);
    data?.map(item => (
      item.Id === key && setDetails(item)
    ))
  };

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };

  const onClose = () => {
    const isFirstLogin = Cookies.get(COOKIE.FIRST_LOGIN);
    if (!isFirstLogin) {
      Cookies.set(COOKIE.FIRST_LOGIN, 'false', { expires: 1 });
      setIsFirstOpen(false);
    }
    close();
  }

  return (
    <Drawer headerStyle={{ background: '#1E211D' }} title={
      <Row align="middle" gutter={10}>
        <Col><Image src={icon.mailbox} preview={false} /></Col>
        <Col className="size-16 color-02">{i18n.t('systemAnnouncement')}</Col>
      </Row >
    } width={450} closable={false} onClose={onClose} open={
      Cookies.get(COOKIE.FIRST_LOGIN) ? open : isFirstOpen
    }
    >
      <Spin spinning={loading}>
        <List
          style={{ marginBottom: 50 }}
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item, index) => (
            <List.Item key={item.Id} onClick={() => showChildrenDrawer(item.Id)}>
              <List.Item.Meta
                title={
                  <div className="size-18" style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden', textOverflow: 'ellipsis',
                  }}>
                    {<SettingFilled />} {item.Title}
                  </div>
                }
                description={
                  <div className="size-14" style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden', textOverflow: 'ellipsis'
                  }}>{item.Content.replace(/<\/?[^>]+(>|$)/g, "")}
                  </div>
                }
              />
            </List.Item>
          )}
        />
        <div style={{ position: 'fixed', bottom: 30, right: 30 }}>
          <LayoutPagination total={SystemAnnouncement ? SystemAnnouncement.TotalRecord : 0}
            setPage={setPage} page={page} hiddenSizeChange={false} />
        </div>
        {
          details &&
          <Drawer
            headerStyle={{ background: '#1E211D' }} width={400}
            title={
              <Row align="middle" justify="space-between">
                <Col>
                  <Row align="middle" gutter={10}>
                    <Col><NotificationFilled className="size-16 color-01" /></Col>
                    <Col className="size-16 color-02">{i18n.t('announcementDetail')}</Col>
                  </Row>
                </Col>
                <Col style={{ cursor: 'pointer' }} onClick={onChildrenDrawerClose}>
                  <CloseOutlined className="size-16 color-02" />
                </Col>
              </Row >
            }
            closable={false}
            onClose={onChildrenDrawerClose}
            open={childrenDrawer}
          >
            <Row style={{ padding: 20 }} gutter={[12, 12]}>
              <Col span={24} className="font-w-md color-pass size-18">{i18n.t('mainTitle')}：</Col>
              <Col span={24} className="size-16"
                style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                {details.Title}
              </Col>
              <Col span={24} className="size-18 font-w-md color-down">{i18n.t('content')}：</Col>
              <Col className="size-16" style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}
                dangerouslySetInnerHTML={{ __html: details.Content }} />
            </Row>
          </Drawer>
        }
      </Spin >
    </Drawer >
  )
}

export const LayoutTabPromotion: React.FC<{
  activeKey: string;
}> = ({ activeKey }) => {

  const navigate = useNavigate();

  const onTabClick = (key: string) => {

    switch (key) {
      case '1':
        navigate('/promotion/bouns');
        break;
      case '2':
        navigate('/promotion/mission');
        break;
      case '3':
        navigate('/promotion/other');
        break;
    }
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: i18n.t('referralReward'),
    },
    {
      key: '2',
      label: i18n.t('dailyCheckIn'),
    },
    {
      key: '3',
      label: i18n.t('otherPromotions'),
    },
  ];

  return (
    <>
      <Tabs defaultActiveKey={activeKey} items={items} onTabClick={onTabClick} />
    </>
  );
};

export const LayoutTabPayment: React.FC<{
  activeKey: string;
}> = ({ activeKey }) => {

  const navigate = useNavigate();

  const onTabClick = (key: string) => {

    switch (key) {
      case '1':
        navigate('/finance/payment-deposit');
        break;
      case '2':
        navigate('/finance/payment-withdraw');
        break;
    }
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: i18n.t('deposit'),
    },
    {
      key: '2',
      label: i18n.t('withdraw'),
    },
  ];

  return (
    <>
      <Tabs defaultActiveKey={activeKey} items={items} onTabClick={onTabClick} />
    </>
  );
};

export const LayoutTabMember: React.FC<any> = ({ activeKey, id }) => {
  const navigate = useNavigate();
  const { permissionCode: $p } = usePerm();

  const onTabClick = (key: any) => {
    switch (key) {
      case '1':
        navigate(`/member/info/${id}`);
        break;
      case '2':
        navigate(`/member/provider/${id}`);
        break;
      case '3':
        navigate(`/member/contact/${id}`);
        break;
      case '4':
        navigate(`/member/bank/${id}`);
        break;
      case '5':
        navigate(`/member/rebate/${id}`);
        break;
    }
  }

  const { isCashVersion: $sc } = useSite();

  const items: any = [
    $p.includes('10202') && { key: '1', label: i18n.t('basicInformation') },
    $p.includes('10301') && { key: '2', label: i18n.t('gameProviderData') },
    $p.includes('10401') && { key: '3', label: i18n.t('contactInformation') },
    ($p.includes('10501') && $sc) && { key: '4', label: i18n.t('bankCardList') },
    $p.includes('10601') && { key: '5', label: i18n.t('rebateList') },
  ];

  return (
    <Tabs className="color-03" size="small" activeKey={activeKey} items={items} onTabClick={onTabClick} />
  );
};

export const LayoutTabFront: React.FC<{
  activeKey: string;
}> = ({ activeKey }) => {

  const navigate = useNavigate();

  const onTabClick = (key: string) => {

    switch (key) {
      case '1':
        navigate('/front');
        break;
      case '2':
        navigate('/front/sub');
        break;
    }
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <div className='w-5 text-center'>{i18n.t('mainTitle')}</div>,
    },
    {
      key: '2',
      label: <div className='w-5 text-center'>{i18n.t('subTitle')}</div>,
    },
  ];

  return (
    <>
      <Tabs defaultActiveKey={activeKey} items={items} onTabClick={onTabClick} />
    </>
  );
};

export const LayoutTabPermission: React.FC<{
  activeKey: string;
}> = ({ activeKey }) => {
  const { permissionCode: $p } = usePerm();
  const [key, setKey] = useState('1');
  const navigate = useNavigate();

  useEffect(() => {
    if ($p && activeKey) {
      setKey(activeKey);
    }
  }, [$p, activeKey])

  const onTabClick = (key: string) => {

    switch (key) {
      case '1':
        navigate('/advanced/permission_name');
        break;
      case '2':
        navigate('/advanced/permission_backOfficeAccount');
        break;
    }
  }

  const items: any = [
    { key: '1', label: <div className='w-8 text-center'>{i18n.t('permissionName')}</div> },
    $p.includes('00801') && { key: '2', label: <div className='w-8 text-center'>{i18n.t('backOfficeAccount')}</div> },
  ];

  return (
    <>
      <Tabs activeKey={key} items={items} onTabClick={onTabClick} />
    </>
  );
};

export const LayoutTabPlatform: React.FC<{
  activeKey: string;
}> = ({ activeKey }) => {
  const [key, setKey] = useState('1');
  const { permissionCode: $p } = usePerm();
  const navigate = useNavigate();

  useEffect(() => {
    if ($p && activeKey) {
      setKey(activeKey);
    }
  }, [$p, activeKey])

  const onTabClick = (key: string) => {

    switch (key) {
      case '1':
        navigate('/advanced');
        break;
      case '3':
        navigate('/advanced/platform_gameData');
        break;
      case '4':
        navigate('/advanced/platform_providerSort');
        break;
      case '5':
        navigate('/advanced/platform_gameList');
        break;
      case '6':
        navigate('/advanced/platform_gameRebate');
        break;
      case '7':
        navigate('/advanced/platform_license');
        break;
    }
  }

  // 聖經Tabs
  const items: any = [
    { key: '1', label: <div className='text-center'>{i18n.t('platformData')}</div> },
    $p.includes('00201') && { key: '3', label: <div className='text-center'>{i18n.t('gameData')}</div> },
    $p.includes('00301') && { key: '4', label: <div className='text-center'>{i18n.t('gameProviderSorting')}</div> },
    $p.includes('00401') && { key: '5', label: <div className='text-center'>{i18n.t('gameList')}</div> },
    $p.includes('00501') && { key: '6', label: <div className='text-center'>{i18n.t('gameProviderCashbackSettings')}</div> },
    $p.includes('00601') && { key: '7', label: <div className='text-center'>{i18n.t('uploadLicense')}</div> },
  ];

  return (
    <>
      <Tabs activeKey={key} items={items} onTabClick={onTabClick} />
    </>
  );
};

export const LayoutTabTransfer = ({ activeKey }: {
  activeKey: string;
}) => {
  const navigate = useNavigate();

  const onTabClick = (key: string) => {
    switch (key) {
      case '1':
        navigate(`/finance/transfer-deposit`);
        break;
      case '2':
        navigate(`/finance/transfer-withdraw`);
        break;
    }
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: i18n.t('offlineDeposit'),
    },
    {
      key: '2',
      label: i18n.t('offlineWithdraw'),
    },
  ];

  return (
    <>
      <Tabs className="color-03" size="small" activeKey={activeKey} items={items} onTabClick={onTabClick} />
    </>
  );
};

export const LayoutTabChatLog = ({ activeKey }: {
  activeKey: string;
}) => {
  const { permissionCode: $p } = usePerm();
  const navigate = useNavigate();

  const onTabClick = (key: string) => {
    switch (key) {
      case '1':
        navigate(`/chat`);
        break;
      case '2':
        navigate(`/chat/private`);
        break;
    }
  }

  const items: any = [
    { key: '1', label: i18n.t('customerService') },
    $p.includes('70103') && { key: '2', label: i18n.t('privateMessage') },
  ];

  return (
    <Tabs size="small" activeKey={activeKey} items={items} onTabClick={onTabClick}
    // indicatorSize={(origin) => 150} tabBarGutter={150} 
    />
  );
};

export const LayoutTabChatSetting = ({ activeKey }: {
  activeKey: string;
}) => {
  const { permissionCode: $p } = usePerm();
  const navigate = useNavigate();

  const onTabClick = (key: string) => {
    switch (key) {
      case '1':
        navigate(`/chat/account`);
        break;
      case '2':
        navigate(`/chat/reply`);
        break;
      case '3':
        navigate(`/chat/setting`);
        break;
    }
  }

  const items: any = [
    { key: '1', label: i18n.t('accountLookup') },
    $p.includes('70301') && { key: '2', label: i18n.t('quickReply') },
    $p.includes('70401') && { key: '3', label: i18n.t('featureSettings') },
  ];

  return (
    <>
      <Tabs className="color-03" size="small" activeKey={activeKey} items={items} onTabClick={onTabClick} />
    </>
  );
};

export const LayoutTabVirtualCurrency = ({ activeKey }: {
  activeKey: string;
}) => {
  const navigate = useNavigate();

  const onTabClick = (key: string) => {
    switch (key) {
      case '1':
        navigate(`/finance/virtual-deposit`);
        break;
      case '2':
        navigate(`/finance/virtual-withdraw`);
        break;
    }
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: i18n.t('virtualCurrencyDeposit'),
    },
    {
      key: '2',
      label: i18n.t('virtualCurrencyWithdraw'),
    },
  ];

  return (
    <>
      <Tabs className="color-03" size="small" activeKey={activeKey} items={items} onTabClick={onTabClick} />
    </>
  );
};

export const CopyButton: React.FC<{
  text: string;
}> = ({ text }) => {
  const Icon = useIcon();

  //copy text
  const handleCopyAccount = (text: string) => {
    navigator.clipboard.writeText(text);
    message.success(i18n.t('copySuccess'));
  };

  return (
    <Button onClick={() => handleCopyAccount(text)} className="center" size="small" type="link">
      <Image className="center" src={Icon.icon10} preview={false} />
    </Button>
  )
}

export const LayoutDateSelect: React.FC<{
  width?: number;
  hidden?: 'dateRange' | 'dateRadio';
  hiddenDateType?: DATE_TYPE[];
  dateTypeDefault?: DATE_TYPE;
  dateFormatDefault?: DATE_FORMAT;
  defaultDate?: Dayjs[];
  reset?: boolean;
  disabledDate?: any;
  disabled?: boolean;
  onChange: (dateStrings: string[]) => void;
}> = ({ width, dateTypeDefault = DATE_TYPE.今天, dateFormatDefault = DATE_FORMAT.日期, reset, onChange, hidden, hiddenDateType = [], defaultDate, disabledDate, disabled }) => {
  const today = dayjs();
  const yesterday = today.add(-1, 'day').startOf('day');
  const thisWeek = today.add(0, 'week').startOf('day');
  const lastWeek = today.add(-1, 'week').startOf('day');
  const lastMonth = today.add(-1, 'month');
  const startOfDay = today.startOf('day');
  const endOfDay = today.endOf('day');
  const yesterdayEnd = today.add(-1, 'day').endOf('day');
  const startOfMonth = today.startOf('month');
  const endOfMonth = today.endOf('month');

  const [dateType, setDateType] = useState<number | null>(dateTypeDefault);
  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);

  useEffect(() => {
    if (defaultDate) {
      setDateRange([defaultDate[0], defaultDate[1]]);
    }
  }, [defaultDate])

  useEffect(() => {
    setDateType(dateTypeDefault);
    dateSwitch(dateTypeDefault);
  }, [reset])

  const onOpenChange = (open: boolean) => {
    if (disabledDate && open) {
      onChange([]);
      setDateRange([null, null]);
    }
  };

  const onCalendarChange = (dates: any, dateStrings: string[]) => {
    if (disabledDate) {
      onChange([...dateStrings]);
    }
  }

  const onDateChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    if (!dates) {
      setDateType(null);
    }
    if (dates) {
      setDateRange([dates[0], dates[1]]);
      onChange([...dateStrings, `${dateType}`]);
      presets.map(preset => dates === preset.value && setDateType(preset.id));
    } else {
      setDateRange([null, null]);
      onChange([]);
    }
  };

  const dateSwitch = (type: DATE_TYPE) => {
    switch (type) {
      case DATE_TYPE.今天:
        setDateRange([startOfDay, endOfDay]);
        onChange([
          startOfDay.format(dateFormatDefault),
          endOfDay.format(dateFormatDefault),
          type.toString(),
        ]);
        break;
      case DATE_TYPE.昨天:
        setDateRange([yesterday, yesterdayEnd]);
        onChange([
          yesterday.format(dateFormatDefault),
          yesterdayEnd.format(dateFormatDefault),
          type.toString(),
        ]);
        break;
      case DATE_TYPE.本週:
        setDateRange([thisWeek.startOf('week'), thisWeek.endOf('week')]);
        onChange([
          thisWeek.startOf('week').format(dateFormatDefault),
          thisWeek.endOf('week').format(dateFormatDefault),
          type.toString(),
        ]);
        break;
      case DATE_TYPE.上週:
        setDateRange([lastWeek.startOf('week'), lastWeek.endOf('week')]);
        onChange([
          lastWeek.startOf('week').format(dateFormatDefault),
          lastWeek.endOf('week').format(dateFormatDefault),
          type.toString(),
        ]);
        break;
      case DATE_TYPE.本月:
        setDateRange([startOfMonth, endOfMonth]);
        onChange([
          startOfMonth.format(dateFormatDefault),
          endOfMonth.format(dateFormatDefault),
          type.toString(),
        ]);
        break;
      case DATE_TYPE.上月:
        setDateRange([lastMonth.startOf('month'), lastMonth.endOf('month')]);
        onChange([
          lastMonth.startOf('month').format(dateFormatDefault),
          lastMonth.endOf('month').format(dateFormatDefault),
          type.toString(),
        ]);
        break;
    }
  }

  useEffect(() => {
    dateSwitch(dateType as number);
  }, [dateType])

  const onDateRadioChange = (e: RadioChangeEvent) => {
    const selectedValue = e.target.value;
    setDateType(selectedValue);
  };

  const presets: {
    label: string,
    value: [dayjs.Dayjs, dayjs.Dayjs],
    id: number
  }[] = [
      {
        label: i18n.t('today'),
        value: [startOfDay, endOfDay],
        id: DATE_TYPE.今天,
      },
      {
        label: i18n.t('yesterday'),
        value: [yesterday, endOfDay],
        id: DATE_TYPE.昨天,
      },
      {
        label: i18n.t('thisWeek'),
        value: [thisWeek.startOf('week'), thisWeek.endOf('week')],
        id: DATE_TYPE.本週,
      },
      {
        label: i18n.t('lastWeek'),
        value: [lastWeek.startOf('week'), lastWeek.endOf('week')],
        id: DATE_TYPE.上週,
      },
      {
        label: i18n.t('thisMonth'),
        value: [startOfMonth, endOfMonth],
        id: DATE_TYPE.本月,
      },
      {
        label: i18n.t('lastMonth'),
        value: [lastMonth.startOf('month'), lastMonth.endOf('month')],
        id: DATE_TYPE.上月,
      },
    ];

  return (
    <>
      <Col style={{ display: `${hidden === 'dateRange' && 'none'}` }}>
        <DatePicker.RangePicker
          style={{ width }}

          showTime={dateFormatDefault === DATE_FORMAT.日期時間}
          format={
            dateFormatDefault === DATE_FORMAT.日期時間
              ? "YYYY-MM-DD HH:mm:ss"
              : "YYYY-MM-DD"
          }

          placeholder={[i18n.t('startTime'), i18n.t('endTime')]}
          defaultValue={dateRange}
          value={dateRange}

          allowClear={false}

          onClick={() => setDateType(null)}
          onOpenChange={onOpenChange}
          onCalendarChange={onCalendarChange}
          onChange={onDateChange}

          changeOnBlur

          disabledDate={disabledDate}
          disabled={disabled}

          presets={
            presets
              .filter(preset => !hiddenDateType.includes(preset.id))
              .map(preset => ({ label: preset.label, value: preset.value }))
          }
        />
      </Col>
      <Col style={{ display: `${hidden === 'dateRadio' && 'none'}` }}>
        <Radio.Group
          optionType="button"
          buttonStyle="solid"
          value={dateType}
          disabled={disabled}
          onChange={onDateRadioChange}
          options={
            Object.keys(DATE_TYPE)
              .filter(v => isNaN(Number(v)))
              .filter(key =>
                !hiddenDateType.includes(Number(DATE_TYPE[key as keyof typeof DATE_TYPE]))
              ).map(key => ({
                value: DATE_TYPE[key as keyof typeof DATE_TYPE],
                label: DATE_TYPE[DATE_TYPE[key as keyof typeof DATE_TYPE]]
              }))
          }
        />
      </Col>
    </>
  )
}

enum NOTIFICATION_TYPE {
  提款通知 = 1,
  存款通知 = 2,
  新用戶通知 = 3,
}

export const Notification: React.FC<{
  open: boolean;
  close: () => void;
  setNotifications: any;
}> = ({ open, close, setNotifications }) => {
  const icon = useIcon();
  const [data, setData] = useState<Notification[]>();
  const [details, setDetails] = useState<Notification>();
  const [read, setRead] = useState<number[]>([]);
  const [childrenDrawer, setChildrenDrawer] = useState(false);

  interface Notification {
    key: number,
    type: number,
    content: string
  }

  useEffect(() => {
    if (data) {
      setNotifications(data?.length - read.length);
    }
  }, [read.length, data, setNotifications])

  // get api
  useEffect(() => {
    const notification = Array.from({ length: 88 }).map((item, index) => (
      {
        key: index,
        type: Math.floor(Math.random() * 2 + 1),
        content: `Agent${index + 1} 於 YYYY-MM-DD hh:mm:ss 申請${NOTIFICATION_TYPE[Math.floor(Math.random() * 2 + 1)]}`
      }
    ))
    setData(notification);
    setNotifications(notification.length);
  }, [])

  // icon
  const handleNotificationIcon = (type: number) => {
    switch (type) {
      case NOTIFICATION_TYPE.存款通知:
        return <SoundFilled />;
      case NOTIFICATION_TYPE.提款通知:
        return <SoundFilled />;
      case NOTIFICATION_TYPE.新用戶通知:
        return <SoundFilled />;
    }
  }

  const handleReadBtn = () => {
    const allRead = data?.map(item => item.key);
    if (allRead) setRead(allRead);
  }

  const handleRead = (key: number) => {
    if (!read.includes(key)) {
      setRead([...read, key])
    }
  }

  const showChildrenDrawer = (key: number) => {
    setChildrenDrawer(true);
    data?.map(item => (
      item.key === key && setDetails(item)
    ))
  };

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };

  return (
    <Drawer headerStyle={{ background: '#1E211D' }} title={
      <Row align="middle" gutter={10}>
        <Col><Image src={icon.notify} preview={false} /></Col>
        <Col className="size-16 color-02">{i18n.t('notification')}</Col>
      </Row>
    } width={450} closable={false} onClose={close} open={open}
      extra={
        <>
          {
            read.length !== data?.length
              ?
              <Button size="small" type="primary" onClick={handleReadBtn} >
                {i18n.t('read')}
              </Button >
              : ''
          }
        </>
      }
    >
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item key={index} onClick={() => {
            handleRead(item.key);
            showChildrenDrawer(item.key);
          }}>
            <List.Item.Meta
              className={read.includes(item.key) ? 'color-03' : ''}
              avatar={handleNotificationIcon(item.type)}
              title={
                <div className={read.includes(item.key) ? 'color-03' : ''}>
                  {NOTIFICATION_TYPE[item.type]}
                </div>
              }
              description={
                <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                  {item.content}
                </div>
              }
            />
          </List.Item>
        )}
      />
      {
        details &&
        <Drawer
          headerStyle={{ background: '#1E211D' }} width={400}
          title={
            <Row align="middle" justify="space-between">
              <Col>
                <Row align="middle" gutter={10}>
                  <Col><NotificationFilled className="size-16 color-01" /></Col>
                  <Col className="size-16 color-02">{i18n.t('notificationDetail')}</Col>
                </Row>
              </Col>
              <Col style={{ cursor: 'pointer' }} onClick={onChildrenDrawerClose}>
                <CloseOutlined className="size-16 color-02" />
              </Col>
            </Row >
          }
          closable={false}
          onClose={onChildrenDrawerClose}
          open={childrenDrawer}
        >
          <Space direction="vertical" className="mr-1 ml-1 mt-2">
            <div className="size-18">
              <span className="font-w-md color-pass">{i18n.t('mainTitle')}：</span>
              <span style={{ marginLeft: 5 }}>{NOTIFICATION_TYPE[details.type]}</span>
            </div>
            <div className="size-18 font-w-md mt-1 color-down">{i18n.t('content')}：</div>
            <div className="size-16">{details.content}</div>
          </Space>
        </Drawer>
      }
    </Drawer>
  )
}

export const LayoutUpdateControl = ({ callback }: {
  callback?: () => void; // import callback function
}) => {

  enum UPDATE_TYPE {
    "15s" = 1,
    "30s" = 2,
    "60s" = 3,
    "300s" = 4,
    "關閉" = 0
  }

  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  const handleUpdate = (type: number) => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }

    switch (type) {
      case UPDATE_TYPE['15s']:
        startInterval(15);
        break;
      case UPDATE_TYPE['30s']:
        startInterval(30);
        break;
      case UPDATE_TYPE['60s']:
        startInterval(60);
        break;
      case UPDATE_TYPE['300s']:
        startInterval(300);
        break;
      case UPDATE_TYPE['關閉']:
        break;
    }
  };

  const startInterval = (seconds: number) => {
    const interval = seconds * 1000;
    if (callback) {
      callback();
      const id = setInterval(() => {
        callback();
      }, interval);
      setIntervalId(id);
    }
  };

  return (
    <Form.Item name="updateType">
      <Row align="middle" gutter={10}>
        <Col>{i18n.t('updateTime')}</Col>
        <Col className="w-6">
          <Select
            placeholder={i18n.t('pleaseSelect')}
            onChange={handleUpdate}
            options={enumToOptions(UPDATE_TYPE)}
          />
        </Col>
      </Row>
    </Form.Item>
  )
}

export const LayoutPagination = ({
  defaultPageSize = 10,
  defaultPageIndex = 1,
  page,
  setPage,
  total,
  hiddenSizeChange = true
}: {
  defaultPageSize?: number;
  defaultPageIndex?: number;
  page?: number[];
  setPage: React.Dispatch<React.SetStateAction<number[]>>;
  total: number;
  hiddenSizeChange?: boolean;
}) => {
  const [current, setCurrent] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<number>(10);

  const onShowSizeChange: PaginationProps['onShowSizeChange']
    = (current, pageSize) => {
      setPage([current, pageSize]);
      setCurrent(current);
      setCurrentPageSize(pageSize);
    };

  return (
    <Row justify="end" className="mt-1">
      <Pagination
        showSizeChanger={hiddenSizeChange}
        total={total}
        onChange={onShowSizeChange}
        current={page ? page[0] : current}
        pageSize={page ? page[1] : currentPageSize}
        defaultPageSize={defaultPageSize}
        showTotal={(total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`}
      />
    </Row>
  )
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: Memo;
  index: number;
  children: React.ReactNode;
}

export const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const ExportExcel = ({ exportExcel }: {
  exportExcel: () => void;
}) => {

  // const exportExcel = () => {
  //   const excel = new Excel();

  //   if (dataSource.length !== 0) {
  //     excel
  //       .addSheet(sheetName)
  //       .addColumns(columns)
  //       .addDataSource(dataSource, {
  //         str2Percent: true
  //       })
  //       .saveAs(`${dayjs().format('YYYYMMDDHHmm')}.xlsx`, "blob")
  //     message.success(i18n.t('downloadSuccess'));
  //   }
  // }

  // NOTE: hover 再美化
  return (
    <Button className='bg-color-06 color-01' onClick={exportExcel}>{i18n.t('exportExcel')}</Button>
  )
}

export const Export = ({ url, sheetName, columns, param }: any) => {

  const auth = useAuth();
  const exportExcel = async () => {

    const query = QueryString.stringify(param, {
      addQueryPrefix: true,
    });

    try {
      await fetch(`${baseURL}${url}${query}`, {
        headers: {
          "Authorization": auth.getToken()
        },
        method: 'GET',
      }).then(res => res.json())
        .then(resData => {
          const data = resData.Data;
          if (data.length !== 0) {
            const excel = new Excel();
            excel
              .addSheet(sheetName)
              .addColumns(columns)
              .addDataSource(data, {
                str2Percent: true
              })
              .saveAs(`${dayjs().format('YYYYMMDDHHmm')}.xlsx`, "blob");
            message.success(i18n.t('downloadSuccess'));
          }
        })
    } catch (error) {
      message.success(i18n.t('listIsNoData'));
    }
  }

  return (
    <Button className='bg-color-06 color-01' onClick={exportExcel}>{i18n.t('exportExcel')}</Button>
  )
}

// 詢問視窗
export const InquiryWindow = ({ isOpen, close, msg, action, isLoading }: any) => {
  const icon = useIcon();

  const handleSubmit = () => {
    action();
    close();
  }

  return (
    <Modal
      open={isOpen}
      onOk={handleSubmit}
      onCancel={close}
      centered
      width={450}
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={icon.icon12} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      footer={
        <Row justify="center">
          <Button className="mt-1" key="cancel" onClick={close}>
            {i18n.t('cancel')}
          </Button>
          <Button className="mt-1" key="confirm" type="primary" loading={isLoading} onClick={handleSubmit}>
            {i18n.t('confirm')}
          </Button>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{msg}？</div>
      </Space>
    </Modal>
  )
}

// 會員等級在列表的顯示
export const MemberLevelTableDisplay = ({ levelName }: any) => {
  const list = levelName?.sort((a: number, b: number) => a - b) || []
  return (
    <>
      {list.length === 0 && i18n.t('all')}
      {list.length > 3
        ? <>
          {
            list.map((item: string, i: number) => (
              <span key={i}>{i <= 2 && `${item}／`}</span>
            ))
          }
          <span>
            <Tooltip placement='right' title={
              <>
                {
                  list.map((item: string, i: number) => (
                    <div key={i}>{i >= 3 && `${item}`}</div>
                  ))
                }
              </>
            }>
              <EllipsisOutlined />
            </Tooltip>
          </span>
        </>
        : <span>{list.join("／")}</span>
      }
    </>
  )
}

// 遊戲商在列表的顯示
export const ProviderTableDisplay = ({ providerName }: any) => {
  const list = providerName?.sort((a: number, b: number) => a - b) || []
  return (
    <>
      {list.length === 0 && i18n.t('all')}
      {list.length > 3
        ? <>
          {
            list.map((item: string, i: number) => {
              return <span key={i}>{i <= 2 && `${item}／`}</span>
              // const provider = item.split('-')[0];
              // const type = item.split('-')[1];
              // return (
              //   <span key={i}>{i <= 2 && `${provider}-${i18n.t(type)}／`}</span>
              // )
            })
          }
          <span>
            <Tooltip placement='right' title={
              <>
                {
                  list.map((item: string, i: number) => {
                    return <div key={i}>{i >= 3 && `${item}`}</div>
                    // const provider = item.split('-')[0];
                    // const type = item.split('-')[1];
                    // return (
                    //   <div key={i}>{i >= 3 && `${provider}-${i18n.t(type)}`}</div>
                    // )
                  })
                }
              </>
            }>
              <EllipsisOutlined />
            </Tooltip>
          </span>
        </>
        : <span>
          {
            list.join("／")
            // list.map((item: string, i: number) => {
            //   const ary = [];
            //   const provider = item.split('-')[0];
            //   const type = item.split('-')[1];
            //   return ary.push(`${provider}-${i18n.t(type)}`)
            // }).join("／")
          }
        </span>
      }
    </>
  )
}

// 上傳圖片
export const UploadImage = ({
  form, name,
  imageData, setImageData,
  url, disabled,
  crop, cropShape = 'rect',
  maxCount = 1, w = 5, h = 4,
  onRemove, button,
  accept = ".jpg, .png",
  fillColor
}: any) => {

  const auth = useAuth();
  const [loading, setlLoading] = useState(false);

  const handleFileListChange = async (data: UploadChangeParam<UploadFile<any>>) => {
    const { fileList } = data;
    setImageData(fileList);
    setlLoading(true);
  };

  useEffect(() => {
    if (imageData && imageData[0] && imageData[0].status === 'done') {
      const newFormData = new FormData();

      imageData.forEach((file: any) => {
        newFormData.append('Media', file.originFileObj as RcFile);
      });

      try {
        const upload = fetch(`${baseURL}${url}`, {
          headers: {
            "Authorization": auth.getToken()
          },
          method: 'POST',
          body: newFormData,
        })
          .then(res => {
            const response: any = res.json();
            if (res.ok) return response;
            else throw response;
          })
          .then(data => {
            if (data.State === 'Success') {
              const ary = data.Data.map((path: any) => ({
                uid: path,
                name: path,
                url: path
              }));
              setImageData(ary);
              setlLoading(false);

              if (form && name) {
                form.setFieldValue(name, url);
                form.validateFields([name]);
              }
            } else throw data;
          })
      } catch (e: any) {
        const msg = RESPONSE_CODE[e.Message as keyof typeof RESPONSE_CODE];
        message.error(msg || i18n.t('processingFailed'));
        setImageData([]);
        setlLoading(false);
      }
    }
  }, [imageData])

  const upload =
    <Upload
      multiple
      accept={accept}
      listType="picture"
      maxCount={maxCount}
      fileList={imageData}
      onChange={handleFileListChange}
      // NOTE: 網址亂填是要修正紅匡 看能不能關閉action
      action={'api/seo/create'}
      showUploadList={{
        showRemoveIcon: false
      }}
      onRemove={onRemove}
    >
      {button ? button : <Button type="primary" disabled={disabled}>{i18n.t('upload')}</Button>}
    </Upload>

  return (
    <Spin spinning={loading}>
      {
        crop
          ?
          <ImgCrop
            cropShape={cropShape}
            fillColor={fillColor}
            aspect={w / h}
            showGrid
            showReset
            resetText={`${i18n.t('reset')}`}
            modalTitle={`${i18n.t('editImage')}`}
          >
            {upload}
          </ImgCrop>
          : (upload)
      }
    </Spin>
  )
}
