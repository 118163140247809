import { DatabaseFilled } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Popover, Row, Select, Spin, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { LayoutDateSelect, LayoutNav, LayoutPagination } from 'components/layout.component';
import { PopupAddPromotionVip, PopupAuditPromotionVip, PopupDetailPromotionVip } from 'components/promotion.component';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TYPE } from 'enum/date';
import { PROMOTION_VIP_STATE } from 'enum/state';
import i18n from 'i18n';
import React, { useState } from 'react';
import { GetMemberlevelRebate } from 'services/memberlevel.service';
import * as common from 'utils/common';

// 會員等級優惠查詢

enum MEMBER_LEVEL_TYPE {
  '會員等級優惠-晉級彩金 ' = 0,
  '會員等級優惠-週禮金 ' = 1,
  '會員等級優惠-月禮金 ' = 2,
}

interface SearchParams {
  Code?: string;
  MemberAccount?: string;
  Status?: number;
  TimeType: number;
  StartDate: string;
  EndDate: string;
}

const PageMain: React.FC = () => {
  const [form] = useForm();
  const [date, setDate] = useState<string[]>();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [reset, setReset] = useState(false);
  const [isModalOpenAdd, setIsModalOpenAdd] = useState(false);
  const [isModalOpenConfirm, setIsModalOpenConfirm] = useState(false);
  const [isModalOpenDetail, setIsModalOpenDetail] = useState(false);
  const [hideColumns, setHideColumns] = useState({
    order: true,
    memberAccount: true,
    recommendedMembers: true,
    transactionType: true,
    amount: true,
    rolloverRate: true,
    settlementDate: true,
    createTime: true,
    claimTime: true,
    status: true
  })

  const defaultDate = 0;
  const [params, setParams] = useState<SearchParams>({
    TimeType: 0,
    StartDate: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    EndDate: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss')
  });
  const { data: MemberlevelRebateList, isValidating, mutate } = GetMemberlevelRebate({
    ...params,
    PageIndex: page[0],
    PageSize: page[1]
  })

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        ...params,
        Code: formData.order,
        MemberAccount: formData.account,
        Status: formData.status,
        TimeType: formData.TimeMode,
        StartDate: date[0],
        EndDate: date[1]
      })
    }
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setPage([1, 10]);
    setReset(!reset);
  }

  const colorChange = (type: number) => {
    switch (type) {
      case PROMOTION_VIP_STATE.已領取:
        return 'color-pass'
      case PROMOTION_VIP_STATE.已派發:
        return 'color-reviewing'
      case PROMOTION_VIP_STATE.未派發:
        return 'color-reject'
    }
  }

  const hideColumnsKeys = Object.keys(hideColumns);
  const hideColumnsValues = Object.values(hideColumns);
  const onChantColumns = (e: any) => {
    setHideColumns({
      ...hideColumns,
      [e.target.name]: e.target.checked
    })
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish} initialValues={{
          TimeMode: defaultDate,
        }}>
          <Row align="middle" gutter={10}>
            <Col className="w-12">
              <Form.Item name="order">
                <Input placeholder={`${i18n.t('order')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="account">
                <Input placeholder={`${i18n.t('memberAccount')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="TimeMode">
                <Select
                  className="w-full"
                  options={[
                    { value: 0, label: `${i18n.t('createTime')}` },
                    { value: 1, label: `${i18n.t('claimTime')}` },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="status">
                <Select
                  className="w-full"
                  placeholder={i18n.t('status')}
                  options={[
                    { value: '', label: `${i18n.t('all')}` },
                    { value: 0, label: i18n.t('distributed') },
                    { value: 1, label: i18n.t('claimed') },
                  ]}
                />
              </Form.Item>
            </Col>
            <LayoutDateSelect width={375} onChange={setDate}
              dateFormatDefault={DATE_FORMAT.日期時間} hiddenDateType={[DATE_TYPE.上週]} reset={reset} />
            <Col>
              <Row align="middle" gutter={[12, 12]}>
                <Col><Button type="primary" htmlType="submit">{i18n.t('search')}</Button></Col>
                <Col><Button onClick={onClear}>{i18n.t('clear')}</Button></Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mt-1">
            <Col span={24}>
              <Spin spinning={isValidating}>
                <Table
                  size="small"
                  dataSource={
                    MemberlevelRebateList ? [
                      {
                        key: 1,
                        totalCount: MemberlevelRebateList.TotalRecord,
                        totalAmount: MemberlevelRebateList.TotalAmount
                      }
                    ] : []
                  }
                  columns={[
                    {
                      title: i18n.t('totalCount'),
                      dataIndex: 'totalCount',
                      align: "right",
                      width: '50%'
                    },
                    {
                      title: i18n.t('totalAmount'),
                      dataIndex: 'totalAmount',
                      align: "right",
                      width: '50%',
                      render: (_, { totalAmount }) => common.toFormatNumber(totalAmount)
                    },
                  ]}
                  pagination={false}
                />
              </Spin>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mt-1" justify="end">
            <Col>
              <Popover content={
                hideColumnsKeys &&
                hideColumnsKeys.map((item, i) => (
                  <div key={i}>
                    <Checkbox name={item} defaultChecked={hideColumnsValues[i]}
                      onChange={onChantColumns}>{i18n.t(item)}</Checkbox>
                  </div>
                ))

              } placement="bottomRight" trigger="click">
                <Button><DatabaseFilled /></Button>
              </Popover>
            </Col>
          </Row>
          <Spin spinning={isValidating}>
            <Table
              size="middle"
              className="mt-1"
              scroll={{ x: 1500 }}
              dataSource={
                MemberlevelRebateList ?
                  MemberlevelRebateList.Data.map((item: MemberlevelRebateInfo) => (
                    {
                      key: item.Id,
                      orderNo: item.Code,
                      memberNo: item.Account,
                      UpAccount: item.UpAccount,
                      AgAccount: item.AgAccount,
                      SaAccount: item.SaAccount,
                      type: item.Type,
                      amount: item.bonus_amount,
                      multiple: item.RewardValidBetTimes,
                      settleTime: item.RewardDate,
                      createTime: item.CreateDate,
                      confirmTime: item.ReceiveDate,
                      status: item.Status
                    }
                  )) : []
              }
              columns={[
                {
                  title: i18n.t('order'),
                  dataIndex: 'orderNo',
                  className: hideColumns.order ? '' : 'hide',
                  width: 250,
                  fixed: 'left',
                },
                {
                  title: i18n.t('memberAccount'),
                  dataIndex: 'memberNo',
                  className: hideColumns.memberAccount ? '' : 'hide',
                  width: 200,
                },
                {
                  title: `${i18n.t('recommendedMembers')}／${i18n.t('agent')}／${i18n.t('generalAgent')}`,
                  width: 250,
                  className: hideColumns.recommendedMembers ? '' : 'hide',
                  render: (_, record) => (
                    <>
                      <span>{record.UpAccount ? record.UpAccount : '-'}</span>
                      <span>／</span>
                      <span>{record.AgAccount ? record.AgAccount : '-'}</span>
                      <span>／</span>
                      <span>{record.SaAccount ? record.SaAccount : '-'}</span>
                    </>
                  )
                },
                {
                  title: i18n.t('transactionType'),
                  dataIndex: 'type',
                  className: hideColumns.transactionType ? '' : 'hide',
                  width: 150,
                  render: (_, { type }) => MEMBER_LEVEL_TYPE[type]
                },
                {
                  title: i18n.t('amount'),
                  dataIndex: 'amount',
                  className: hideColumns.amount ? '' : 'hide',
                  align: 'right',
                  width: 200,
                  render: (_, { amount }) => common.toFormatNumber(amount)
                },
                {
                  title: i18n.t('rolloverRate'),
                  dataIndex: 'multiple',
                  className: hideColumns.rolloverRate ? '' : 'hide',
                  align: 'right',
                  width: 100
                },
                {
                  title: i18n.t('settlementDate'),
                  dataIndex: 'settleTime',
                  className: hideColumns.settlementDate ? '' : 'hide',
                  width: 180,
                  render: (_, { settleTime }) => (
                    settleTime !== "0000-00-00 00:00:00" ? settleTime : '-'
                  )
                },
                {
                  title: i18n.t('createTime'),
                  dataIndex: 'createTime',
                  className: hideColumns.createTime ? '' : 'hide',
                  width: 180,
                  render: (_, { createTime }) => (
                    createTime !== "0000-00-00 00:00:00" ? createTime : '-'
                  )
                },
                {
                  title: i18n.t('claimTime'),
                  dataIndex: 'confirmTime',
                  className: hideColumns.claimTime ? '' : 'hide',
                  width: 180,
                  render: (_, { confirmTime }) => (
                    confirmTime !== "0000-00-00 00:00:00" ? confirmTime : '-'
                  )
                },
                {
                  title: i18n.t('status'),
                  dataIndex: 'status',
                  className: hideColumns.status ? '' : 'hide',
                  fixed: 'right',
                  width: 150,
                  render: (_, { status }) => (
                    <>
                      <div className={colorChange(status)}>
                        {PROMOTION_VIP_STATE[status]}
                      </div>
                    </>
                  )
                },
              ]}
              pagination={false}
            />
            <LayoutPagination total={MemberlevelRebateList ? MemberlevelRebateList.TotalRecord : 0} setPage={setPage} page={page} />
          </Spin>
        </Form>
      </Content>

      <PopupAddPromotionVip isOpen={isModalOpenAdd} close={() => setIsModalOpenAdd(false)} />
      <PopupAuditPromotionVip isOpen={isModalOpenConfirm} close={() => setIsModalOpenConfirm(false)} />
      <PopupDetailPromotionVip isOpen={isModalOpenDetail} close={() => setIsModalOpenDetail(false)} />
    </div>
  );
};

export default PageMain;