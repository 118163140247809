import {
  axiosGetAuth,
  axiosPostAuth,
  baseURL,
  useFetchDataCommon
} from "services";

export const GameCategoryList = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/category/list`, params);
export const GameCategoryOne = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/category/one`, params);
export const GameCategoryUpdate = (data: any) => axiosPostAuth(`${baseURL}/category/update`, data);
