export enum KEY_ID {
  '返水扣款' = 1021,
  '占成入款' = 1023,
  '占成扣款' = 1024,
  '轉出給代理' = 1032,
  '轉出給會員' = 1033,
  '系統派發優惠' = 1015,

  // 未使用
  // '調帳' = 1003,
  // '存款訂單' = 1007,
  // '拒絕存款訂單' = 1012,

  // '優惠' = 1015,
  // '派發優惠訂單' = 1016,
  // '扣除優惠訂單' = 1017,
  // '轉出' = 1034,
  // '找不到轉入記錄' = 2000,
  // '找不到轉出記錄' = 2001,
  // '取消' = 2003,
  // '新增派彩' = 2005,
  // '新增下注' = 2008,
  // '推出' = 2009,
  // '退回' = 2010,
  // '結算後扣款' = 2011,
  // '結算後補款' = 2012,
  // '通吃' = 2013,
  // '新增退款' = 2014,
  // '新增獎金' = 2015,
  // '已取消' = 2016,

  // '升級' = 2020,
  // '升級獎勵' = 2021,
  // '錢包下注' = 2060,

  // '返水扣款' = 1021,
  
  // '占成扣款' = 1024,
  // '代理轉入' = 1031,
  // '轉出給代理' = 1032,
  // '轉出給會員' = 1033,
  // '代理存款' = 1006,
  // '代理提款' = 1011,
}

// 代理錢包紀錄
// 帳目
export enum AGENT_LOG {
  '存款' = 1006,
  '提款' = 1011,
  '提款-成功' = 1013, // 財務審核通過
  '提款-拒絕' = 1012, // 財務審核拒絕
  '返水入款' = 1020,
  '占成入款' = 1023,
  '調帳-添加餘額' = 1001,
  '調帳-扣除餘額' = 1002,
  '代理轉帳-轉入' = 1031,
  '代理轉帳-轉出' = 10312,
  '代理轉帳-扣除餘額' = 1032,
}

// 帳戶紀錄
export enum MEMBER_LOG {
  '下注' = 2002,
  '派彩' = 2004,
  '存款' = 1006,
  '提款' = 1011,
  '提款-拒絕' = 1012,
  '調帳-添加餘額' = 1001,
  '調帳-扣除餘額' = 1002,
  '會員等級優惠' = 2020,
  '系統派發優惠' = 1015,
  '添加優惠' = 1018,
  '扣除優惠' = 1019,
  '返水' = 1020,
  '轉入' = 2018,
  '轉出' = 2017,
  '代理轉帳-添加餘額' = 1031,
  '代理轉帳-扣除餘額' = 1032,
}