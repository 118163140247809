import PagePromotion from "pages/admin/promotion";
import PagePromotionOther from "pages/admin/promotion/add";
import PagePromotionAddMission from "pages/admin/promotion/add-mission";
import PagePromotionBonus from "pages/admin/promotion/bonus";
import PagePromotionDiscount from "pages/admin/promotion/discount";
import PagePromotionEditOther from "pages/admin/promotion/edit-other";
import PagePromotionLevel from "pages/admin/promotion/level";
import PagePromotionMission from "pages/admin/promotion/mission";
import PagePromotionRebate from "pages/admin/promotion/rebate";
import PagePromotionVip from "pages/admin/promotion/vip";

export const promotionModule = [
  { path: '/promotion', Component: PagePromotion },
  { path: '/promotion/bonus', Component: PagePromotionBonus },
  { path: '/promotion/add/mission', Component: PagePromotionAddMission },
  { path: '/promotion/edit/mission/:id', Component: PagePromotionMission },
  { path: '/promotion/other', Component: PagePromotionOther },
  { path: '/promotion/edit-other/:id', Component: PagePromotionEditOther },
  { path: '/promotion/level', Component: PagePromotionLevel },
  { path: '/promotion/discount', Component: PagePromotionDiscount },
  { path: '/promotion/rebate', Component: PagePromotionRebate },
  { path: '/promotion/vip', Component: PagePromotionVip },
]