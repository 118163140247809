import { Button, Col, Descriptions, Form, Input, InputNumber, Row, Select, Tag, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { SelectAgentPermission } from 'components/agent.component';
import { LayoutNav } from 'components/layout.component';
import { REGEX_ACCOUNT } from 'constants/regex';
import { RESPONSE_CODE_AGENT } from 'constants/response';
import { SETTLE_CYCLE } from 'enum/promotion';
import { RESPONSE_TYPE } from 'enum/state';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AgentRebateInfo, CreateAgentAg, FetchTagList, GetAgentOne } from 'services/agent.service';

import { convertedToPercentage02 as $c, convertedToPercentage01 as $g, enumToOptions, verify } from 'utils/common';

// 新增子代
const PageMain: React.FC = () => {

  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [confirm, setConfirm] = useState(false);
  const [tagOptions, setTagOptions] = useState<TagInfo[]>([]);

  const { data: agentSa } = GetAgentOne({ Id: id }, !!id);
  const { data: tagList } = FetchTagList({
    Role: 1,
    Status: 0,
  });

  // 取得上代返水(最大值)
  const [parentRebate, setParentRebate] = useState<any>({
    BonusCostRate: 100,
    CashFlowFeeRate: 100,
  });
  const { data: parentRebateInfo } = AgentRebateInfo({ AgentId: id }, !!id);
  useEffect(() => {
    if (parentRebateInfo) {
      setParentRebate({
        BonusCostRate: $g(parentRebateInfo.BonusCostRate),
        CashFlowFeeRate: $g(parentRebateInfo.CashFlowFeeRate),
      })
    }
  }, [parentRebateInfo]);

  useEffect(() => {
    resetFormValues();
  }, [agentSa]);

  useEffect(() => {

    if (!tagList) return;
    setTagOptions(tagList);
  }, [tagList]);

  const resetFormValues = () => {

    if (!agentSa) return;

    form.setFieldsValue({
      agentSaAccount: agentSa.Account,
      tag: undefined,
      commissionCycle: agentSa.CommissionSettleCycle,
      revenueCycle: agentSa.RevenueShareSettleCycle,
    });
  }

  const [permissions, setPermissions] = useState([]);
  const onFinish = async (formData: any) => {
    if (!REGEX_ACCOUNT.test(formData.account) || typeof formData.account[0] !== 'string') {
      return message.error(i18n.t('accountFormatError'));
    }
    if (formData.password !== formData.confirmPassword) {
      return message.error(i18n.t('incorrectPasswordConfirmation'));
    }
    if (
      !formData.commission || !formData.feeRate
    ) return message.error(i18n.t('pleaseFillInAllRequiredFieldsFirst'));
    if (
      !$c(formData.commission) ||
      !$c(formData.feeRate)
    ) return message.error(i18n.t('contentOnlyNumber'));
    if (
      parseFloat(formData.commission) < 0 ||
      parseFloat(formData.feeRate) < 0 ||
      parseFloat(formData.commission) > parentRebate.BonusCostRate ||
      parseFloat(formData.feeRate) > parentRebate.CashFlowFeeRate
    ) return message.error(i18n.t('discountAndPaymentRateCannotExceedParent'))

    const apiResult: ApiResult = await CreateAgentAg({
      AgentAccount: agentSa.Account,
      Account: formData.account,
      Password: formData.password,
      RepeatPassword: formData.confirmPassword,
      TagsId: formData.tag ? formData.tag : [],
      Remark: formData.remark,
      BonusCostRate: parseFloat(formData.commission) / 100,
      CashFlowFeeRate: parseFloat(formData.feeRate) / 100,
      Permission: permissions
    })

    if (apiResult.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('addSuccess'));
      setConfirm(true);
    } else {
      message.error(RESPONSE_CODE_AGENT[apiResult.Message as keyof typeof RESPONSE_CODE_AGENT]);
    }
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish}>
          {/* 基本資料 */}
          <Row align="middle">
            <Col span={24}>
              <Row align="middle" justify="end" gutter={10}>
                {confirm ?
                  <Col><Button onClick={() => navigate('/agent')}>{i18n.t('close')}</Button></Col> :
                  <>
                    <Col><Button onClick={() => navigate('/agent')}>{i18n.t('cancel')}</Button></Col>
                    <Col><Button type="primary" onClick={form.submit}>{i18n.t('confirm')}</Button></Col>
                  </>
                }
              </Row>
            </Col>
            <Col span={24}>
              <Descriptions size="small" layout="horizontal" title={i18n.t('addAgent')} column={{ xs: 8 }} bordered
                labelStyle={{ fontSize: 12, fontWeight: 500 }}>
                <Descriptions.Item label={<><span className="require">*</span>{i18n.t('upperAgentMasterAgent')}</>}>
                  <Form.Item name="agentSaAccount">
                    <Input disabled />
                  </Form.Item>
                </Descriptions.Item>
                {/* 聖經欄位驗證 */}
                <Descriptions.Item label={<><span className="require">*</span>{i18n.t('account')}</>}>
                  <Form.Item name="account" rules={[
                    { required: true, message: `${i18n.t('required')}` },
                    { min: 4, message: `${i18n.t('within4To12Characters')}` },
                    { max: 12, message: `${i18n.t('within4To12Characters')}` },
                  ]}>
                    <Input disabled={confirm} placeholder={`${i18n.t('enter')}`} />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={<><span className="require">*</span>{i18n.t('password')}</>}>
                  <Form.Item name="password" rules={[
                    { required: true, message: `${i18n.t('required')}` },
                    { min: 4, message: `${i18n.t('supportSpecialCharacters')}` },
                    { max: 20, message: `${i18n.t('supportSpecialCharacters')}` },
                  ]}>
                    <Input.Password disabled={confirm} placeholder={`${i18n.t('supportSpecialCharacters')}`} />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={<><span className="require">*</span>{i18n.t('confirmNewPassword')}</>}>
                  <Form.Item name="confirmPassword" rules={[
                    { required: true, message: `${i18n.t('required')}` },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) return Promise.resolve();
                        else return Promise.reject(new Error(`${i18n.t('incorrectPasswordConfirmation')}`));
                      },
                    }),
                  ]}>
                    <Input.Password disabled={confirm} placeholder={`${i18n.t('enter')}`} />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={<><span className="require">*</span>{i18n.t('status')}</>}>
                  <Select
                    className="w-full"
                    disabled
                    value={i18n.t('pendingApproval')}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('agentTag')}>
                  <Form.Item name="tag">
                    <Select
                      disabled={confirm}
                      className="w-full"
                      placeholder={i18n.t('pleaseSelect')}
                      options={
                        tagOptions ?
                          tagOptions.map((option) => ({
                            value: option.Id,
                            label:
                              <Tag style={{ color: option.TextColor, background: option.Color }}>
                                {option.Name}
                              </ Tag>
                          })) : []
                      }
                    />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={<><span className="require">*</span>{i18n.t('promotionCommission')}%</>}>
                  <Form.Item name="commission" rules={verify({ max: parentRebate.BonusCostRate || 100 })}>
                    <InputNumber className="w-full" disabled={confirm} placeholder={`${i18n.t('enter')}`} maxLength={12} />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={<><span className="require">*</span>{i18n.t('paymentFeeRate')}</>}>
                  <Form.Item name="feeRate" rules={verify({ max: parentRebate.CashFlowFeeRate || 100 })}>
                    <InputNumber className="w-full" disabled={confirm} placeholder={`${i18n.t('enter')}`} maxLength={12} />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('remark')}>
                  <Form.Item name="remark">
                    <Input disabled={confirm} placeholder={`${i18n.t('enter')}`} />
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>

          {/* 後台權限 */}
          <Row align="middle" className="mt-1">
            <Col span={24}>
              <Col className="size-16 font-w-md">{i18n.t('backOfficePermissions')}</Col>
              <Col span={24} className="mt-1">
                <SelectAgentPermission permissions={permissions} setPermissions={setPermissions} confirm={confirm} />
              </Col>
            </Col>
          </Row>

          {/* 返水/占成設定 */}
          <Row align="middle" className="mt-1" gutter={30}>
            <Col span={12}>
              <Descriptions size="small" layout="vertical" title={i18n.t('cashbackCommissionSetting')} bordered
                labelStyle={{ fontSize: 12, fontWeight: 500 }}>
                <Descriptions.Item style={{ width: '50%' }} label={<><span className="require">*</span>{i18n.t('cashbackSettlementCycle')}</>}>
                  <Form.Item name="commissionCycle">
                    <Select
                      className="w-full"
                      placeholder={i18n.t('pleaseSelect')}
                      disabled
                      options={enumToOptions(SETTLE_CYCLE)}
                    />
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={12}>
              <Descriptions size="small" layout="vertical" title={i18n.t('cashbackCommissionSetting')} bordered
                labelStyle={{ fontSize: 12, fontWeight: 500 }}>
                <Descriptions.Item style={{ width: '50%' }} label={<><span className="require">*</span>{i18n.t('commissionSettlementCycle')}</>}>
                  <Form.Item name="revenueCycle">
                    <Select
                      className="w-full"
                      placeholder={i18n.t('pleaseSelect')}
                      disabled
                      options={enumToOptions(SETTLE_CYCLE)}
                    />
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;