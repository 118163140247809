import { SOCKET_ON } from "enum/socket";
import useAuth from "hooks/auth.hook";
import useSite from "hooks/site.hook";
import { createContext, useContext, useEffect, useState } from "react";
import { Socket, io } from 'socket.io-client';


export const socketContext = createContext<SocketContext>({});

export const useSocket = () => {
  return useContext(socketContext);
};

export const SocketProvider = ({ children }: {
  children?: any
}) => {

  const { data: $s }: any = useSite();

  const auth = useAuth();
  const [socket, setSocket] = useState<Socket>();
  const [isConnect, setConnect] = useState(false);
  const [stockIds, setStockIds] = useState('');
  const [serviceMessages, setServiceMessages] = useState<any>({});
  const [privateMessage, setPrivateMessage] = useState<any>({});
  const [notification, setNotification] = useState('');
  const [sessionId, setSessionId] = useState<any>();

  // jcgsport.com

  useEffect(() => {
    if ($s) {
      const socket = io(`wss://chat.${$s.Domain}/`, {
        transports: ['websocket'],
        secure: true,
        auth: {
          role: 'Service',
          token: auth.getToken()
        }
      });
      setSocket(socket);
    }
  }, [$s]);

  useEffect(() => {
    if (!socket) return;

    socket.off();
    // 連線
    socket.on(SOCKET_ON.連線, () => {
      console.log('連線成功');
      setConnect(true);
      setStockIds(socket.id);
    });
    // 斷線
    socket.on(SOCKET_ON.斷線, () => {
      console.log('斷線');
      setConnect(false);
    });
    // 未登入
    socket.on(SOCKET_ON.未登入, () => {
      console.log('未登入');
    });
    // 客服訊息
    socket.on(SOCKET_ON.客服訊息, (msg) => {
      const message = JSON.parse(msg);
      setServiceMessages(message);
      console.log(SOCKET_ON.客服訊息, message);
    });
    // 私人訊息
    socket.on(SOCKET_ON.私人訊息, (msg) => {
      const message = JSON.parse(msg);
      setPrivateMessage(message);
      console.log(SOCKET_ON.私人訊息, message);
    });
    // 客服通知
    socket.on(SOCKET_ON.客服通知, (msg) => {
      const message = JSON.parse(msg);
      setSessionId(message.SessionId);
      setNotification(`${message.Notification}-${new Date()}`);
      console.log(SOCKET_ON.客服通知, message);
    });
    // 連線失敗
    socket.on(SOCKET_ON.連線錯誤, (msg) => {
      console.log(msg);
    });

    // return () => {
    //   socket.disconnect();
    // };

  }, [socket, stockIds]);

  return (
    <socketContext.Provider value={{
      socket,
      isConnect,
      serviceMessages,
      privateMessage,
      notification,
      sessionId
    }}>
      {children}
    </socketContext.Provider>
  )
}