import { GlobalOutlined, MoreOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Form, Image, Popover, Row, Select, Spin, Tabs, Tree, message } from 'antd';
import ImgCrop from 'antd-img-crop';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import { DirectoryTreeProps } from 'antd/es/tree';
import { UploadChangeParam } from 'antd/es/upload';
import Upload from 'antd/es/upload/Upload';
import { Collapse, UploadFile } from 'antd/lib';
import { RcFile } from 'antd/lib/upload';
import { PopupCloseChat, PopupOfflineChat, PopupTransferChat } from 'components/chat.component';
import { RESPONSE_CODE_CHAT } from 'constants/response';
import { useSocket } from 'contexts/socket.context';
import dayjs from 'dayjs';
import { ROLE, SOCKET_ON } from 'enum/socket';
import { RESPONSE_TYPE } from 'enum/state';
import useAudio from 'hooks/audio.hook';
import useAuth from 'hooks/auth.hook';
import useIcon from 'hooks/icon.hook';
import useSite from 'hooks/site.hook';
import { Howl } from 'howler';
import Cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';
import { ChatList, MessageList } from "react-chat-elements";
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { baseURL } from 'services';
import { GetLanguageList } from 'services/account.service';
import { ChatAllOnlineAccount, ChatAllQuickReply, ChatCloseList, ChatHistory, ChatOpenList, ChatPendingList, ChatRoomCustomerInfo, ChatSend, ChatServiceAnswer, ChatUpdateRead, ChatUserInfo, UpdateChatUserStatus } from 'services/chat.service';
import { transferTimeFormat } from 'utils/common';

enum CHAT_TYPE {
  等待中 = '1',
  我的對話 = '2',
  同事對話 = '3'
}
enum SERVICE_STATUS {
  在線 = 1,
  離線 = 0
}
enum SERVICE_NOTIFY {
  開啟通知 = 1,
  關閉通知 = 0
}
enum CHAT_ROOM {
  自己 = 1,
  同事 = 2
}

// 線上客服
const PageMain: React.FC = () => {

  const { data: $s } = useSite();

  const { DirectoryTree } = Tree;
  const icon = useIcon();
  const audio = useAudio();
  const auth = useAuth();
  const socket = useSocket();
  const [form] = useForm();
  const initialValues = {
    notify: 1,
    lang: Cookies.get('lang-online-service') || document.documentElement.lang
  }
  const [tabActiveKey, setTabActiveKey] = useState('1');
  const [processTime, setProcessTime] = useState('');
  const [page, setPage] = useState<number[]>([1, 20]);
  const [inputValue, setInputValue] = useState('');
  const [transferInfo, setTransferInfo] = useState<any[]>([]);
  const [account, setAccount] = useState<string | null>('');
  const [serviceNotify, setServiceNotify] = useState(true);
  const [isOpenCloseChat, setIsOpenCloseChat] = useState(false);
  const [isOpenOfflineChat, setIsOpenOfflineChat] = useState(false);
  const [isOpenTransferChat, setIsOpenTransferChat] = useState(false);
  const [adminState, setAdminState] = useState<number | null>(null);
  const [firstLoading, setFirstLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chatType, setChatType] = useState(CHAT_TYPE.等待中);
  const { data: LanguageList } = GetLanguageList();
  const { t, i18n } = useTranslation();
  const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
  const [sendType, setSendType] = useState(0);
  const messageListReferance = useRef();
  const [sessionId, setSessionId] = useState<string>('');
  const [serviceMessage, setServiceMessage] = useState<any>([]);
  const [isContact, setIsContact] = useState(true);

  // 音效
  const contactAudio = new Howl({
    src: [audio.audio02],
    volume: serviceNotify ? 1 : 0
  })
  const messageAudio = new Howl({
    src: [audio.audio01],
    volume: serviceNotify ? 1 : 0
  })

  // 客服聊天歷史紀錄
  const {
    data: ChatServiceHistory,
    isValidating: chatServiceHistoryLoading,
    mutate: refreshChatServiceHistory
  } = ChatHistory({
    SessionId: sessionId,
    StartDate: processTime,
    EndDate: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
    PageIndex: page[0],
    PageSize: page[1]
  }, !!sessionId)
  useEffect(() => {
    if (ChatServiceHistory) {
      setServiceMessage([
        ...ChatServiceHistory.Data
          .sort((a: ChatMessageInfo, b: ChatMessageInfo) => (
            dayjs(a.SendTime).valueOf() - dayjs(b.SendTime).valueOf()
          ))
          .map((item: ChatMessageInfo, i: number) => (
            {
              key: i,
              position: item.SenderRole === ROLE.客服 ? 'right' : 'left',
              type: item.Type === 0 ? 'text' : 'photo',
              avatar: item.SenderRole !== ROLE.客服 ? icon.avatar04 : '',
              title: item.SenderAccount ? item.SenderAccount : `${i18n.t('visitor')} ${item.SessionId}`,
              titleColor: item.SenderRole === ROLE.客服 ? '#df9620' : '#5858d4',
              text: item.Type === 0 ? item.Content : '',
              date: item.SendTime,
              dateString: transferTimeFormat(item.SendTime),
              data: {
                uri: item.Content,
                width: 200,
                height: 200
              },
            }
          )),
      ])
    }
  }, [ChatServiceHistory])
  // socket收到客服或私人訊息
  useEffect(() => {
    if (socket.serviceMessages) {
      const service = socket.serviceMessages.SessionId === sessionId;
      if (service) {
        messageAudio.play();
        setShowBackToBottom(true);
        setServiceMessage([
          ...serviceMessage,
          ...[socket.serviceMessages].map((item: ChatMessageInfo) => ({
            position: item.SenderRole === ROLE.客服 ? 'right' : 'left',
            type: item.Type === 0 ? 'text' : 'photo',
            title: item.SenderAccount ? item.SenderAccount : `${i18n.t('visitor')} ${item.SessionId}`,
            titleColor: item.SenderRole === ROLE.客服 ? '#df9620' : '#5858d4',
            text: item.Type === 0 ? item.Content : '',
            date: item.SendTime,
            dateString: transferTimeFormat(item.SendTime),
            data: {
              uri: item.Content,
              width: 200,
              height: 200
            },
          }))
        ])
      }
      refreshOpenMyList();
      refreshOpenColleagueList();
    }
  }, [socket]);
  // socket 收到各類通知
  useEffect(() => {
    const n = socket?.notification?.split('-')[0];
    switch (n) {
      case SOCKET_ON.建立客服服務:
        refreshPendingList();
        break;
      case SOCKET_ON.客服接聽服務:
      case SOCKET_ON.客服轉接服務:
        refreshPendingList();
        refreshOpenMyList();
        refreshOpenColleagueList();
        break;
      case SOCKET_ON.客服結束對話:
        const session = socket?.sessionId
        refreshPendingList();
        refreshOpenMyList();
        refreshOpenColleagueList();
        refreshCloseMyList();
        refreshCloseColleagueList();
        if (isContact && session === sessionId) {
          setIsContact(false);
        }
        break;
    }
  }, [socket.notification]);
  // 取得聊天室使用者資料
  const {
    data: UserInfo,
    isValidating: userInfoLoading,
    mutate: refreshUserInfo
  } = ChatUserInfo();
  useEffect(() => {
    if (UserInfo) {
      form.setFieldsValue({
        serviceState: UserInfo.IsOnline
      })
    }
  }, [UserInfo])
  // 快捷回覆
  const {
    data: QuickReply,
    isValidating: quickReplyLoading,
    mutate: refreshQuickReply } = ChatAllQuickReply();
  // 等待中列表
  const {
    data: PendingList,
    isValidating: pendingListLoading,
    mutate: refreshPendingList } = ChatPendingList();
  // 對話中列表-我的對話
  const {
    data: OpenMyList,
    isValidating: openMyListLoading,
    mutate: refreshOpenMyList
  } = ChatOpenList({ Type: CHAT_ROOM.自己 });
  // 對話中列表-同事對話
  const {
    data: OpenColleagueList,
    isValidating: openColleagueListLoading,
    mutate: refreshOpenColleagueList
  } = ChatOpenList({ Type: CHAT_ROOM.同事 });
  // 已結束對話-我的對話
  const {
    data: CloseMyList,
    isValidating: closeMyListLoading,
    mutate: refreshCloseMyList
  } = ChatCloseList({ Type: CHAT_ROOM.自己 });
  // 已結束對話-同事對話
  const {
    data: CloseColleagueList,
    isValidating: closeColleagueListLoading,
    mutate: refreshCloseColleagueList
  } = ChatCloseList({ Type: CHAT_ROOM.同事 });
  // 目前上線可轉接的帳號
  const {
    data: ChatOnlineAccount,
    isValidating: onlineAccountLoading,
    mutate: refreshOnlineAccount
  } = ChatAllOnlineAccount({
    IsOnline: SERVICE_STATUS.在線,
    PageIndex: 1,
    PageSize: 999
  });
  // 更新所有的列表
  const refreshAll = () => {
    refreshPendingList();
    refreshOpenMyList();
    refreshOpenColleagueList();
    refreshCloseMyList();
    refreshCloseColleagueList();
    refreshOnlineAccount();
  }
  // 聊天室用戶訊息
  const {
    data: ChatRoomInfo,
    isValidating: chatRoomLoading,
    mutate: refreshChatRoomInfo
  } = ChatRoomCustomerInfo({ SessionId: sessionId }, !!sessionId);
  // handle 上線下線狀態
  const serviceStatus = (type: boolean) => {
    switch (type) {
      case true:
        return {
          background: '#58DA57',
          width: 10,
          height: 10,
          borderRadius: '100%',
          border: '1px solid #58DA57'
        }
      case false:
        return {
          background: '#D9D9D9',
          width: 10,
          height: 10,
          borderRadius: '100%',
          border: '1px solid #D9D9D9'
        }
    }
  }
  // 更新客服上下線狀態
  useEffect(() => {
    if (UserInfo) {
      setAdminState(UserInfo.IsOnline);
    }
  }, [UserInfo]);
  const handleServiceState = async (status: number) => {
    if (OpenMyList.length !== 0) {
      form.setFieldValue('serviceState', SERVICE_STATUS.在線);
      return setIsOpenOfflineChat(true);
    }
    try {
      const response = await UpdateChatUserStatus({
        IsOnline: status
      })
      if (response.State === RESPONSE_TYPE.成功) {
        if (status === SERVICE_STATUS.在線) {
          message.success(i18n.t('updateSuccess'));
        } else if (status === SERVICE_STATUS.離線) {
          message.success(i18n.t('operationSuccess'));
        }
        setAdminState(status);
        refreshUserInfo();
        refreshOnlineAccount();
      } else {
        message.error(RESPONSE_CODE_CHAT[response.Message as keyof typeof RESPONSE_CODE_CHAT]);
      }
    } catch (error) {
      return;
    }
  }
  // handle 切換語系
  const handleChangeLang = async (lang: string) => {
    await i18n.changeLanguage(lang);
    form.setFieldValue('lang', lang);
    Cookies.set('lang-online-service', lang);
    // window.location.reload();
  }
  // handle 開啟關閉通知
  const handleServiceNotify = async (notify: number) => {
    switch (notify) {
      case SERVICE_NOTIFY.開啟通知:
        setServiceNotify(true);
        break;
      case SERVICE_NOTIFY.關閉通知:
        setServiceNotify(false);
        break;
    }
  }
  // handle Tab
  const onTabClick = (key: string) => {
    switch (key) {
      case '1':
        setChatType(CHAT_TYPE.等待中);
        setTabActiveKey('1');
        break;
      case '2':
        setChatType(CHAT_TYPE.我的對話);
        setTabActiveKey('2');
        break;
      case '3':
        setChatType(CHAT_TYPE.同事對話);
        setTabActiveKey('3');
        break;
    }
    setSessionId('');
  }
  // 點擊聊天列表
  const handleOpenSession =
    async (type: string, id: string, role: number, account: string, ProcessTime?: string, NoCode?: string) => {
      setIsContact(true);
      switch (type) {
        case CHAT_TYPE.等待中:
          setProcessTime(ProcessTime || dayjs().format('YYYY-MM-DD HH:mm:ss'));
          setSessionId(id);
          setAccount(role === ROLE.訪客 ? id : account);
          break;
        case CHAT_TYPE.我的對話:
          try {
            const response = await ChatUpdateRead({ SessionId: id });
            if (response.State === RESPONSE_TYPE.成功) {
              refreshOpenMyList();
              refreshOpenColleagueList();
            } else {
              return;
            }
            setPage([1, 20]);
            setSessionId(id);
            setAccount(role === ROLE.訪客 ? id : account);
            if (ProcessTime) {
              setProcessTime(ProcessTime);
            }
          } catch (error) {
            return;
          }
          break;
        case CHAT_TYPE.同事對話:
          try {
            setPage([1, 20]);
            setSessionId(id);
            setAccount(role === ROLE.訪客 ? id : account);
            if (ProcessTime) {
              setProcessTime(ProcessTime);
            }
          } catch (error) {
            return;
          }
      }
    }
  // handle 快捷回覆
  const onDoubleClick: DirectoryTreeProps['onDoubleClick'] = (keys, info) => {
    // navigator.clipboard.writeText(`${info.title}`);
    // message.success(i18n.t('copySuccess'));
    setInputValue(`${info.title}`);
  }
  // 轉接給其他客服
  const handleTransfer = (e: string) => {
    const info = e.split(',');
    setTransferInfo(info)
    form.setFieldValue('transfer', null);
    setIsOpenTransferChat(true);
  }
  // 連線聊天室
  const handleConnect = async () => {
    if (sessionId) {
      try {
        const response = await ChatServiceAnswer({
          SessionId: sessionId
        })
        if (response.State === RESPONSE_TYPE.成功) {
          setChatType(CHAT_TYPE.我的對話);
          setTabActiveKey('2');
          setServiceMessage([]);
          contactAudio.play();
          message.success(i18n.t('connectionSuccess'));
          refreshAll();
        } else {
          message.error(RESPONSE_CODE_CHAT[response.Message as keyof typeof RESPONSE_CODE_CHAT]);
        }
      } catch (error) {
        return;
      }
    }
  }
  // 傳送訊息
  const [isEnterPressed, setIsEnterPressed] = useState(false);
  const [showBackToBottom, setShowBackToBottom] = useState(false);
  const chatWindowRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const chatWindow = chatWindowRef.current;
    if (chatWindow) {
      setTimeout(() => {
        chatWindow.scrollTop = chatWindow.scrollHeight;
      }, 300);
    }
  }, [sessionId]);
  const handleScroll = () => {
    const chatWindow = chatWindowRef.current;
    if (chatWindow) {
      const isAtBottom =
        chatWindow.scrollTop + chatWindow.clientHeight === chatWindow.scrollHeight;
      const isAtTop = chatWindow.scrollTop === 0;
      if (isAtBottom) {
        setShowBackToBottom(false);
      }
      if (isAtTop) {
        setPage([1, page[1] + 20]);
      }
    }
  }
  const scrollToBottom = (delay = 300) => {
    const chatWindow = chatWindowRef.current;
    if (chatWindow) {
      setTimeout(() => {
        chatWindow.scrollTop = chatWindow.scrollHeight;
      }, delay);
    }
  };
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (isEnterPressed) {
        onSend();
        setIsEnterPressed(false);
      } else {
        setIsEnterPressed(true);
        setTimeout(() => {
          setIsEnterPressed(false);
        }, 300);
      }
    }
  };
  const onSend = async () => {
    if (inputValue) {
      try {
        const response = await ChatSend({
          SessionId: sessionId,
          Type: sendType,
          Content: inputValue
        })
        if (response.State === RESPONSE_TYPE.成功) {
          setPage([1, page[1] + 1]);
          setInputValue('');
          scrollToBottom();
          setSendType(0);
          setFileList([]);
          form.setFieldsValue({
            fileList: []
          })
          refreshOpenMyList();
          refreshOpenColleagueList();
        }
      } catch (error) {
        return;
      }
    }
  }
  // 上傳圖片
  const handleFileListChange = async (info: UploadChangeParam<UploadFile<any>>) => {
    setIsLoading(true);
    setInputValue('');
    const { fileList: newFileList } = info;
    const newFormData = new FormData();

    newFileList.forEach((file) => {
      newFormData.append('Media', file.originFileObj as RcFile);
    });

    form.setFieldsValue({
      fileList: newFileList
    })

    setFileList(newFileList);

    const upload = await fetch(`${baseURL}/upload/images/public/message`, {
      headers: {
        "Authorization": auth.getToken()
      },
      method: 'POST',
      body: newFormData,
    }).then((response) => {
      if (response.ok) {
        return response.json()
      }
    }).then(data => data)
    setInputValue(upload.Data[0]);
    setSendType(1);
    setIsLoading(false);
  };

  // 關閉瀏覽器客服離線
  useEffect(() => {
    window.onbeforeunload = () => {
      UpdateChatUserStatus({
        IsOnline: SERVICE_STATUS.離線
      })
    }
  }, []);

  return (
    <div className="m-1" style={{ height: '1vh' }}>
      <Helmet>
        <link rel="icon" href={$s && $s.Logo1} type="image/x-icon" />
      </Helmet >
      <Form form={form} initialValues={initialValues} colon={false}>
        <Row gutter={5}>
          <Col span={6} style={{ minHeight: 900 }}>
            <Row gutter={[12, 12]}>
              <Col span={24} >
                <Spin spinning={firstLoading}>
                  <Row align="middle" justify="space-between">
                    <Col>
                      <Row align="middle" gutter={10}>
                        <Col>
                          <Avatar size={35} src={UserInfo ? UserInfo.Avatar : Cookies.get('defaultAvatar')} />
                        </Col>
                        <Col>
                          <div style={serviceStatus(UserInfo && !!UserInfo.IsOnline)} />
                        </Col>
                        <Col>
                          {UserInfo && UserInfo.PermissionName} - {UserInfo && (UserInfo.NickName ? UserInfo.NickName : UserInfo.Account)}
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row align="middle" gutter={10}>
                        <Col>
                          <Button type="primary" loading={userInfoLoading}
                            onClick={handleConnect} disabled={tabActiveKey !== '1' || adminState === SERVICE_STATUS.離線}>
                            {i18n.t('wiring')}
                          </Button>
                        </Col>
                        <Col>
                          <Popover trigger="click" title={
                            `${UserInfo && UserInfo.PermissionName} - ${UserInfo && (UserInfo.NickName ? UserInfo.NickName : UserInfo.Account)}`
                          } content={
                            <Row style={{ width: 200 }} gutter={[12, 12]}>
                              <Col span={24}>
                                <Form.Item name="serviceState"
                                  label={<div style={serviceStatus(UserInfo && !!UserInfo.IsOnline)} />}>
                                  <Select
                                    bordered={false}
                                    className="w-full"
                                    onChange={handleServiceState}
                                    options={[
                                      { value: SERVICE_STATUS.在線, label: i18n.t('online') },
                                      { value: SERVICE_STATUS.離線, label: i18n.t('offline') },
                                    ]}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Form.Item name="notify" label={
                                  <Image className="center" src={serviceNotify ? icon.bell01 : icon.bell02} preview={false} />
                                }>
                                  <Select
                                    bordered={false}
                                    className="w-full"
                                    onChange={handleServiceNotify}
                                    options={[
                                      { value: 1, label: i18n.t('enableNotifications') },
                                      { value: 0, label: i18n.t('disableNotifications') },
                                    ]}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Form.Item name="lang" label={<GlobalOutlined />}>
                                  <Select
                                    bordered={false}
                                    className="w-full"
                                    onChange={handleChangeLang}
                                    options={
                                      LanguageList ?
                                        LanguageList
                                          // 暫時只顯示簡中與繁中
                                          .filter((item: LanguageList) => item.Code === 'zh-TW' || item.Code === 'zh-CN')
                                          .map((item: LanguageList) => (
                                            { value: item.Code, label: i18n.t(item.Code) }
                                          )) : []
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          }>
                            <MoreOutlined style={{ fontSize: 20 }} />
                          </Popover>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Spin>
              </Col>
              <Col span={24}>
                <Tabs centered tabBarGutter={100} items={[
                  {
                    key: '1',
                    label: <div>{i18n.t('waiting')}({PendingList ? PendingList.length : 0})</div>,
                  },
                  {
                    key: '2',
                    label: <div >{i18n.t('myConversation')}</div>,
                  },
                  {
                    key: '3',
                    label: <div >{i18n.t('colleagueConversation')}</div>,
                  },
                ]} defaultActiveKey={tabActiveKey} onTabClick={onTabClick} activeKey={tabActiveKey} />
              </Col>
              <Col span={24} style={{ maxHeight: 700, minHeight: 700, overflowY: "auto" }}>
                <Spin spinning={firstLoading}>
                  {
                    chatType === CHAT_TYPE.等待中 && tabActiveKey === '1' &&
                      PendingList ? PendingList.map((item: ChatPendingList) => (
                        <ChatList
                          key={item.Id}
                          {...(item as any)}
                          className="chat-list"
                          onClick={e => handleOpenSession(CHAT_TYPE.等待中, e.id as string, item.CustomerRole, item.CustomerAccount)}
                          dataSource={[
                            {
                              id: item.Id,
                              avatar: icon.avatar04,
                              title: item.CustomerRole === ROLE.訪客 ? `${i18n.t('visitor')}${item.Id}` : item.CustomerAccount,
                              date: item.CreateDate,
                              dateString: item.CreateDate,
                              className: `${sessionId === item.Id && 'selected-chat'}`
                            }
                          ]}
                        />
                      )) : []
                  }
                </Spin>
                <Spin spinning={firstLoading}>
                  {
                    chatType === CHAT_TYPE.我的對話 && tabActiveKey === '2' &&
                    <Collapse defaultActiveKey={['1']} items={[
                      {
                        key: 1,
                        label: i18n.t('currentConversation'),
                        style: { maxHeight: 340, overflowY: "auto" },
                        children: (
                          OpenMyList ? OpenMyList.map((item: ChatPendingList) => (
                            <ChatList
                              key={item.Id}
                              {...(item as any)}
                              onClick={e => handleOpenSession(CHAT_TYPE.我的對話, e.id as string, item.CustomerRole, item.CustomerAccount, item.ProcessTime)}
                              className="chat-list"
                              dataSource={[
                                {
                                  id: item.Id,
                                  avatar: icon.avatar04,
                                  title: item.CustomerRole === ROLE.訪客 ? `${i18n.t('visitor')}${item.Id}` : item.CustomerAccount,
                                  subtitle: item.LatestMessageType === 0 ? item.LatestMessage : i18n.t('sentAnImage'),
                                  date: item.LatestMessageTime,
                                  dateString: transferTimeFormat(item.LatestMessageTime),
                                  className: `${sessionId === item.Id && 'selected-chat'}`,
                                  unread: item.UnreadCount
                                }
                              ]}
                            />
                          )) : []
                        )
                      },
                      {
                        key: 2,
                        label: i18n.t('conversationEnded'),
                        style: { maxHeight: 340, overflowY: "auto" },
                        children: (
                          CloseMyList ? CloseMyList.map((item: ChatPendingList) => (
                            <ChatList
                              key={item.Id}
                              {...(item as any)}
                              className="chat-list"
                              onClick={e => handleOpenSession(CHAT_TYPE.我的對話, e.id as string, item.CustomerRole, item.CustomerAccount, item.ProcessTime)}
                              dataSource={[
                                {
                                  id: item.Id,
                                  avatar: icon.avatar04,
                                  title: item.CustomerRole === ROLE.訪客 ? `${i18n.t('visitor')}${item.Id}` : item.CustomerAccount,
                                  subtitle: item.LatestMessageType === 0 ? item.LatestMessage : i18n.t('sentAnImage'),
                                  date: item.LatestMessageTime,
                                  dateString: transferTimeFormat(item.LatestMessageTime),
                                  unread: item.UnreadCount
                                }
                              ]}
                            />
                          )) : []
                        )
                      },
                    ]} />
                  }
                </Spin>
                <Spin spinning={firstLoading}>
                  {
                    chatType === CHAT_TYPE.同事對話 && tabActiveKey === '3' &&
                    <Collapse defaultActiveKey={['1']} items={[
                      {
                        key: 1,
                        label: i18n.t('currentConversation'),
                        style: { maxHeight: 340, overflowY: "auto" },
                        children: (
                          OpenColleagueList ? OpenColleagueList.map((item: ChatPendingList) => (
                            <ChatList
                              key={item.Id}
                              {...(item as any)}
                              className="chat-list"
                              onClick={e => handleOpenSession(CHAT_TYPE.同事對話, e.id as string, item.CustomerRole, item.CustomerAccount, item.ProcessTime, item.NoCode)}
                              dataSource={[
                                {
                                  id: item.Id,
                                  avatar: icon.avatar04,
                                  title: item.CustomerAccount,
                                  subtitle: item.LatestMessageType === 0 ? item.LatestMessage : i18n.t('sentAnImage'),
                                  date: item.LatestMessageTime,
                                  dateString: transferTimeFormat(item.LatestMessageTime),
                                }
                              ]}
                            />
                          )) : []
                        )
                      },
                      {
                        key: 2,
                        label: i18n.t('conversationEnded'),
                        style: { maxHeight: 340, overflowY: "auto" },
                        children: (
                          CloseColleagueList ? CloseColleagueList.map((item: ChatPendingList) => (
                            <ChatList
                              key={item.Id}
                              {...(item as any)}
                              className="chat-list"
                              onClick={e => handleOpenSession(CHAT_TYPE.同事對話, e.id as string, item.CustomerRole, item.CustomerAccount, item.ProcessTime, item.NoCode)}
                              dataSource={[
                                {
                                  id: item.Id,
                                  avatar: icon.avatar04,
                                  title: item.CustomerRole === ROLE.訪客 ? `${i18n.t('visitor')}${item.Id}` : item.CustomerAccount,
                                  subtitle: item.LatestMessageType === 0 ? item.LatestMessage : i18n.t('sentAnImage'),
                                  date: item.LatestMessageTime,
                                  dateString: transferTimeFormat(item.LatestMessageTime),
                                  unread: item.UnreadCount
                                }
                              ]}
                            />
                          )) : []
                        )
                      },
                    ]} />
                  }
                </Spin>
              </Col>
            </Row>
          </Col>
          <Col span={12} style={{ minHeight: 900, maxHeight: 900 }}>
            <Spin spinning={firstLoading}>
              <Row>
                {
                  sessionId && (chatType === CHAT_TYPE.我的對話 || chatType === CHAT_TYPE.同事對話) &&
                  <>
                    <Col span={24}>
                      <Card
                        bodyStyle={{
                          height: 600,
                          padding: '10px 0px',
                          background: '#F9F9F9'
                        }}
                        title={
                          <Row align="middle" justify="space-between">
                            <Col>{account}</Col>
                            <Col>
                              <Form.Item name="transfer" className="w-12">
                                <Select
                                  loading={onlineAccountLoading}
                                  className="w-full"
                                  placeholder={i18n.t('transfer')}
                                  onChange={handleTransfer}
                                  onDropdownVisibleChange={() => refreshOnlineAccount()}
                                  disabled={chatType === CHAT_TYPE.同事對話 || adminState === SERVICE_STATUS.離線 || !isContact}
                                  options={
                                    ChatOnlineAccount ?
                                      ChatOnlineAccount
                                        .filter((item: ChatAccountList) => UserInfo.AccountId !== item.AccountId)
                                        .map((item: ChatAccountList) => (
                                          {
                                            value: `${item.AccountId},${item.NickName},${item.PermissionName}`,
                                            label: `${item.PermissionName} - ${item.NickName ? item.NickName : item.Account}`,
                                          }
                                        )) : []
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        }
                      >
                        <div ref={chatWindowRef} style={{
                          height: 580,
                          minHeight: 580,
                          maxHeight: 580,
                          overflowY: 'auto',
                          scrollBehavior: 'smooth',
                          position: 'relative'
                        }} onScroll={handleScroll}>
                          <MessageList
                            className='message-list'
                            referance={messageListReferance}
                            dataSource={serviceMessage}
                            lockable={true}
                            downButton={true}
                            downButtonBadge={10}
                            sendMessagePreview={true}
                          />
                          <div style={{
                            display: `${showBackToBottom ? '' : 'none'}`,
                            textAlign: 'center',
                            position: 'sticky',
                            bottom: 0,
                          }}>
                            <div style={{
                              display: 'inline-block',
                              padding: '2px 5px',
                              border: '.5px solid #e3e3e3',
                              borderRadius: 8,
                              cursor: 'pointer'
                            }} onClick={() => scrollToBottom(0)}>
                              {i18n.t('newMessage')}
                            </div>
                          </div>
                        </div>
                      </Card>
                    </Col>
                    <Col span={24}
                      style={{
                        minHeight: 200,
                        maxHeight: 200,
                        border: '.5px solid #e3e3e3',
                        borderRadius: '0px 0px 10px 10px'
                      }}>
                      <Row>
                        <Col span={24}>
                          <TextArea
                            maxLength={500}
                            autoSize={{ minRows: 5, maxRows: 5 }}
                            placeholder={isContact ? `${i18n.t('enter')}` : `${i18n.t('conversationEnded')}`}
                            bordered={false}
                            disabled={chatType === CHAT_TYPE.同事對話 || !isContact}
                            value={inputValue}
                            onChange={e => setInputValue(e.target.value)}
                            onKeyDown={e => handleKeyDown(e)}
                          />
                        </Col>
                        <Col span={24} className="mt-1">
                          <Row align="middle" justify="space-between">
                            <Col className='ml-1'>
                              <Form.Item name="fileList" valuePropName="fileList">
                                <ImgCrop
                                  aspect={1}
                                  showGrid
                                  showReset
                                  resetText={`${i18n.t('reset')}`}
                                  modalTitle={`${i18n.t('editImage')}`}
                                >
                                  <Upload
                                    multiple
                                    disabled={isLoading || chatType === CHAT_TYPE.同事對話 || !isContact}
                                    accept=".jpg, .png"
                                    maxCount={1}
                                    fileList={fileList}
                                    onChange={handleFileListChange}
                                    onRemove={() => {
                                      setFileList([]);
                                      setInputValue('');
                                      setSendType(0);
                                    }}
                                  >
                                    <Image style={{ cursor: 'pointer' }} src={icon.uploadPhotoMessage} preview={false} />
                                  </Upload>
                                </ImgCrop>
                              </Form.Item>
                            </Col>
                            <Col>
                              <Row gutter={[12, 12]}>
                                <Col><Button type="primary" danger loading={isLoading} disabled={chatType === CHAT_TYPE.同事對話 || !isContact} onClick={() => setIsOpenCloseChat(true)}>
                                  {i18n.t('endConversation')}
                                </Button></Col>
                                <Col><Button type="primary" className='mr-1' disabled={chatType === CHAT_TYPE.同事對話 || !isContact} onClick={onSend} loading={isLoading}>{i18n.t('send')}</Button></Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="end">
                            <Col className="color-03 size-12">（{i18n.t('doubleClickEnterToSend')}）</Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </>
                }
              </Row>
            </Spin>
          </Col>
          <Col span={6} style={{ minHeight: 900 }}>
            <Row>
              <Col span={24}>
                <Card title={i18n.t('userInfo')} loading={chatRoomLoading}>
                  <Row>
                    <Col span={12} className="color-03 size-12">
                      <div>
                        {i18n.t('system')}：{ChatRoomInfo && (chatType === CHAT_TYPE.我的對話 || chatType === CHAT_TYPE.同事對話) ? ChatRoomInfo.System.os : '-'}
                      </div>
                      <div>
                        {i18n.t('role')}：{ChatRoomInfo && (chatType === CHAT_TYPE.我的對話 || chatType === CHAT_TYPE.同事對話) ? ROLE[ChatRoomInfo.Role] : '-'}
                      </div>
                      <div>
                        {i18n.t('nickname')}／{i18n.t('id')}：{ChatRoomInfo && (chatType === CHAT_TYPE.我的對話 || chatType === CHAT_TYPE.同事對話) ? `${ChatRoomInfo.NickName || '-'}／${ChatRoomInfo.NoCode || '-'}` : '-'}
                      </div>
                      <div>
                        {i18n.t('registrationTime')}：{ChatRoomInfo && (chatType === CHAT_TYPE.我的對話 || chatType === CHAT_TYPE.同事對話) ? `${ChatRoomInfo.RegisterTime || '-'}` : '-'}
                      </div>
                      <div>
                        {i18n.t('lastBetTime')}：{ChatRoomInfo && (chatType === CHAT_TYPE.我的對話 || chatType === CHAT_TYPE.同事對話) ? `${ChatRoomInfo.LastBetTime || '-'}` : '-'}
                      </div>
                    </Col>
                    <Col span={12} className="color-03 size-12">
                      <div>
                        {i18n.t('IP')}：{ChatRoomInfo && (chatType === CHAT_TYPE.我的對話 || chatType === CHAT_TYPE.同事對話) ? ChatRoomInfo.IP : '-'}
                      </div>
                      <div>
                        {i18n.t('account')}：{ChatRoomInfo && (chatType === CHAT_TYPE.我的對話 || chatType === CHAT_TYPE.同事對話) ? ChatRoomInfo.Account : '-'}
                      </div>
                      <div>
                        {i18n.t('memberLevel')}：{ChatRoomInfo && (chatType === CHAT_TYPE.我的對話 || chatType === CHAT_TYPE.同事對話) ? `${ChatRoomInfo.Level || '-'}` : '-'}
                      </div>
                      <div>
                        {i18n.t('lastDepositTime')}：{ChatRoomInfo && (chatType === CHAT_TYPE.我的對話 || chatType === CHAT_TYPE.同事對話) ? `${ChatRoomInfo.LastDepositTime || '-'}` : '-'}
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={24}>
                <Card title={
                  <Row align="middle" justify="space-between">
                    <Col>{i18n.t('quickReply')}</Col>
                  </Row>
                } loading={quickReplyLoading}>
                  <Row style={{ maxHeight: 600, overflowY: "auto" }}>
                    <Col span={24}>
                      <DirectoryTree
                        onDoubleClick={onDoubleClick}
                        treeData={
                          QuickReply ?
                            [...new Set(QuickReply.map((item: QuickReplyList) => item.CategoryName))]
                              .map((CategoryName, i) => (
                                {
                                  key: i,
                                  title: `${CategoryName}`,
                                  children: [
                                    ...QuickReply.filter((item: QuickReplyList) =>
                                      item.CategoryName === CategoryName)
                                      .map((item: QuickReplyList) => (
                                        {
                                          key: `${i}-${item.Id}`,
                                          title: item.Content,
                                          isLeaf: true,
                                          style: {
                                            whiteSpace: 'nowrap', overflow: 'hidden',
                                            textOverflow: 'ellipsis', maxWidth: 400,
                                            display: 'inline-block'
                                          }
                                        }
                                      ))
                                  ]
                                }
                              )) : []
                        }
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row >
      </Form >
      {/* Modal */}
      <PopupOfflineChat isOpen={isOpenOfflineChat} close={() => setIsOpenOfflineChat(false)} setChatType={setChatType} setTabActiveKey={setTabActiveKey} />
      <PopupCloseChat isOpen={isOpenCloseChat} close={() => setIsOpenCloseChat(false)}
        sessionId={sessionId} setSessionId={setSessionId} refresh={refreshAll} setIsContact={setIsContact} />
      <PopupTransferChat isOpen={isOpenTransferChat} close={() => setIsOpenTransferChat(false)}
        sessionId={sessionId} setSessionId={setSessionId} transferInfo={transferInfo} refresh={refreshAll} />
    </div >
  );
};

export default PageMain;