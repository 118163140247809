import { Button, Checkbox, Col, Form, Input, Row, Select, Spin, Table, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { LayoutNav } from 'components/layout.component';
import { RESPONSE_CODE } from 'constants/response';
import { CHECKBOX_DISPLAY, PERMISSION_STATE, RESPONSE_TYPE } from 'enum/state';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GetPermissionOne, GetPermissionOperationList, PostPermissionUpdate } from 'services/permission.service';
import { enumToOptions } from 'utils/common';

// 編輯權限

enum PERMISSION {
  客服審核 = 1,
  主管審核 = 2,
  財務審核 = 3,
}

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  // 全部權限
  const [allList, setAllList] = useState<any>([]);
  const { data: operationList } = GetPermissionOperationList({});
  // 建立權限tree物件
  useEffect(() => {
    if (operationList) {
      const data: any = [];
      operationList.Data.forEach((item: any) => {
        const management = data.find((x: any) => x.key === item.ManagementId);
        if (management) {
          management.child.push({ key: item.OperationId, name: item.OperationName })
        } else {
          data.push({
            MenuId: item.MenuId, key: item.ManagementId, name: item.ManagementName, rowSpan: 0, child: [
              { key: item.OperationId, name: item.OperationName }
            ]
          });
          const menu = data.find((x: any) => x.MenuId === item.MenuId);
          if (menu) menu.rowSpan++
        }
      });
      setAllList(data);
    }
  }, [operationList]);

  // 我的權限
  const { data: one, mutate } = GetPermissionOne({ Id: id }, !!id);
  useEffect(() => {
    if (one && one.Data && allList.length > 0) {
      form.setFieldsValue({
        Name: one.Data.Name,
        Remark: one.Data.Remark,
        Status: one.Data.Status,
      })

      updateCheckStateOne();
    }
  }, [one, allList]);

  const menui18n: any = {
    2: i18n.t('member'),
    3: i18n.t('financial'),
    4: i18n.t('promotion'),
    5: i18n.t('agent'),
    6: i18n.t('frontendPage'),
    7: i18n.t('statisticalReports'),
    8: i18n.t('chatRoom'),
    1: i18n.t('advanced'),
  }

  const managementi18n: any = {
    14: i18n.t('memberList'),
    15: `${i18n.t('memberList')}-${i18n.t('basicInformation')}`,
    16: `${i18n.t('memberList')}-${i18n.t('gameProviderData')}`,
    17: `${i18n.t('memberList')}-${i18n.t('contactInformation')}`,
    18: `${i18n.t('memberList')}-${i18n.t('bankCardList')}`,
    19: `${i18n.t('memberList')}-${i18n.t('rebateList')}`,
    20: i18n.t('accountRecords'),
    21: i18n.t('realNameVerification'),
    22: i18n.t('withdrawalInfoAudit'),
    23: i18n.t('instantMessages'),
    24: i18n.t('memberIPCheck'),
    25: `${i18n.t('memberIPCheck')}-${i18n.t('IPBlockManagement')}`,
    26: i18n.t('gameIPCheck'),

    27: i18n.t('withdrawalQuery'),
    28: i18n.t('depositQuery'),
    29: i18n.t('adjustmentQuery'),
    30: `${i18n.t('offlineDepositWithdraw')}-${i18n.t('withdrawal')}`,
    31: `${i18n.t('offlineDepositWithdraw')}-${i18n.t('withdrawal')}-${i18n.t('virtualCurrency')}`,
    32: `${i18n.t('offlineDepositWithdraw')}-${i18n.t('withdrawal')}-${i18n.t('total')}`,
    33: `${i18n.t('offlineDepositWithdraw')}-${i18n.t('deposit')}`,
    34: `${i18n.t('offlineDepositWithdraw')}-${i18n.t('deposit')}-${i18n.t('virtualCurrency')}`,
    35: `${i18n.t('offlineDepositWithdraw')}-${i18n.t('deposit')}-${i18n.t('total')}`,
    36: `${i18n.t('paymentManagement')}-${i18n.t('withdrawal')}`,
    37: `${i18n.t('paymentManagement')}-${i18n.t('withdrawal')}-${i18n.t('total')}`,
    38: `${i18n.t('paymentManagement')}-${i18n.t('deposit')}`,
    39: `${i18n.t('paymentManagement')}-${i18n.t('deposit')}-${i18n.t('total')}`,

    40: i18n.t('promotionManagement'),
    41: i18n.t('memberLevelSetting'),
    42: i18n.t('promotionQuery'),
    43: i18n.t('rebatePromotionQuery'),
    44: i18n.t('memberLevelPromotionQuery'),

    45: i18n.t('generalAgentList'),
    46: `${i18n.t('generalAgentList')}-${i18n.t('edit')}`,
    47: `${i18n.t('generalAgentList')}-${i18n.t('settlementReports')}`,
    48: `${i18n.t('generalAgentList')}-${i18n.t('dailyReport')}`,
    49: i18n.t('agentWithdrawalQuery'),
    50: i18n.t('agentDepositQuery'),
    51: i18n.t('agentAdjustmentQuery'),
    52: i18n.t('agentBankCardQuery'),
    53: i18n.t('agentReportAudit'),
    54: i18n.t('agentWalletRecord'),
    55: i18n.t('proxyTag'),

    56: i18n.t('frontendText'),
    57: i18n.t('carouselImage'),
    58: i18n.t('popupSetting'),
    59: i18n.t('SEOSetting'),

    60: i18n.t('bettingReports'),
    61: i18n.t('dataOverview'),
    62: i18n.t('operationReports'),
    63: i18n.t('gameStatisticalAnalysis'),
    64: i18n.t('agentStatisticalAnalysis'),
    65: i18n.t('agentPromotionStatistical'),
    66: i18n.t('memberRanking'),
    67: i18n.t('winAndLossReports'),

    68: i18n.t('chatRoomLog'),
    69: `${i18n.t('chatRoomSettings')}-${i18n.t('accountLookup')}`,
    70: `${i18n.t('chatRoomSettings')}-${i18n.t('quickReply')}`,
    71: `${i18n.t('chatRoomSettings')}-${i18n.t('featureSettings')}`,

    1: i18n.t('platformSettings'),
    2: `${i18n.t('platformSettings')}-${i18n.t('gameData')}`,
    3: `${i18n.t('platformSettings')}-${i18n.t('gameProviderSorting')}`,
    4: `${i18n.t('platformSettings')}-${i18n.t('gameList')}`,
    5: `${i18n.t('platformSettings')}-${i18n.t('gameProviderCashbackSettings')}`,
    6: `${i18n.t('platformSettings')}-${i18n.t('uploadLicense')}`,

    7: i18n.t('permissionManagement'),
    8: `${i18n.t('permissionManagement')}-${i18n.t('backOfficeAccount')}`,
    9: i18n.t('announcementSettings'),
    10: `${i18n.t('announcementSettings')}-${i18n.t('systemAnnouncement')}`,
    11: i18n.t('maintenanceSettings'),
    12: i18n.t('gameManagement'),
    13: i18n.t('operationRecords'),
  }

  const operationi18n: any = {
    // 會員
    10101: i18n.t('view'),
    10102: i18n.t('add'),
    10103: `${i18n.t('remark')}-${i18n.t('edit')}`,
    10104: i18n.t('export'),

    10201: i18n.t('edit'),
    10202: i18n.t('view'),
    10203: `${i18n.t('remark')}-${i18n.t('edit')}`,
    10204: `${i18n.t('remark')}-${i18n.t('view')}`,
    10205: `${i18n.t('remark')}-${i18n.t('delete')}`,
    // 10206: `${i18n.t('remark')}-${i18n.t('view')}`,

    10301: i18n.t('view'),
    10302: i18n.t('edit'),
    10303: i18n.t('batchSetting'),

    10401: i18n.t('view'),
    10402: i18n.t('add'),
    10403: i18n.t('edit'),
    10404: i18n.t('delete'),

    10501: i18n.t('view'),
    // 10502: i18n.t('detail'),
    10503: i18n.t('add'),
    10504: i18n.t('delete'),
    10505: i18n.t('edit'),

    10601: i18n.t('view'),
    10602: i18n.t('edit'),

    10701: i18n.t('view'),
    // 10702: i18n.t('withdraw'),
    // 10703: i18n.t('balance'),

    10801: i18n.t('view'),
    // 10802: i18n.t('detail'),
    10803: i18n.t('add'),
    10804: i18n.t('audit'),
    10805: i18n.t('edit'),

    10901: i18n.t('view'),
    // 10902: i18n.t('detail'),
    10903: i18n.t('audit'),

    11001: i18n.t('view'),
    11002: i18n.t('add'),
    // 11003: i18n.t('detail'),

    11101: i18n.t('view'),
    // 11102: i18n.t('detail'),
    11103: i18n.t('blockIP'),
    11104: i18n.t('unblockIP'),
    11105: i18n.t('freezeMember'),

    11201: i18n.t('view'),
    11202: i18n.t('add'),
    11203: i18n.t('delete'),
    11204: i18n.t('edit'),

    11301: i18n.t('view'),
    11302: i18n.t('add'),

    // 財務
    20101: i18n.t('view'),
    // 20102: i18n.t('detail'),
    // 20103: `${i18n.t('detail')}-${i18n.t('withdrawalInformation')}`,
    20104: `${i18n.t('audit')}-${PERMISSION[PERMISSION.客服審核]}`,
    // 20105: `${i18n.t('audit')}-${PERMISSION_FINANCE[PERMISSION_FINANCE.客服拒絕]}`,
    20106: `${i18n.t('audit')}-${PERMISSION[PERMISSION.主管審核]}`,
    // 20107: `${i18n.t('audit')}-${PERMISSION_FINANCE[PERMISSION_FINANCE.主管拒絕]}`,
    20108: `${i18n.t('audit')}-${PERMISSION[PERMISSION.財務審核]}`,
    // 20109: `${i18n.t('audit')}-${PERMISSION_FINANCE[PERMISSION_FINANCE.財務拒絕]}`,
    20110: i18n.t('export'),

    20201: i18n.t('view'),
    // 20202: i18n.t('detail'),
    20203: `${i18n.t('audit')}-${PERMISSION[PERMISSION.財務審核]}`,
    // 20204: i18n.t('audit'),
    20205: i18n.t('export'),

    20301: i18n.t('view'),
    // 20302: i18n.t('detail'),
    20303: i18n.t('add'),
    20304: `${i18n.t('audit')}-${i18n.t('pass')}`,
    // 20305: `${i18n.t('audit')}-${i18n.t('reject')}`,
    20306: i18n.t('export'),

    20401: i18n.t('add'),
    20402: i18n.t('edit'),
    20403: i18n.t('view'),
    // 20404: i18n.t('view'),
    20405: i18n.t('delete'),

    20501: i18n.t('add'),
    20502: i18n.t('edit'),
    20503: i18n.t('detail'),
    20504: i18n.t('view'),
    20505: i18n.t('delete'),

    20601: i18n.t('view'),

    20701: i18n.t('view'),
    20702: i18n.t('add'),
    20703: i18n.t('edit'),
    20704: i18n.t('detail'),
    20705: i18n.t('delete'),

    20801: i18n.t('view'),
    20802: i18n.t('add'),
    20803: i18n.t('edit'),
    // 20804: i18n.t('detail'),
    20805: i18n.t('delete'),

    20901: i18n.t('view'),

    21001: i18n.t('view'),
    // 21002: i18n.t('detail'),
    21003: i18n.t('edit'),

    21101: i18n.t('view'),

    21201: i18n.t('view'),
    // 21202: i18n.t('detail'),
    21203: i18n.t('edit'),

    21301: i18n.t('view'),

    // 優惠 
    30101: i18n.t('view'),
    30102: i18n.t('add'),
    30103: i18n.t('edit'),
    30104: i18n.t('delete'),
    // 30105: `${i18n.t('dailyCheckIn')}-${i18n.t('add')}`,
    // 30106: `${i18n.t('dailyCheckIn')}-${i18n.t('edit')}`,
    // 30107: `${i18n.t('dailyCheckIn')}-${i18n.t('detail')}`,
    // 30108: `${i18n.t('dailyCheckIn')}-${i18n.t('edit')}`,
    // 30109: `${i18n.t('dailyCheckIn')}-${i18n.t('detail')}`,
    // 30110: i18n.t('detail'),

    30201: i18n.t('view'),
    30202: i18n.t('add'),
    30203: i18n.t('edit'),
    30204: i18n.t('delete'),

    30301: i18n.t('view'),
    30302: i18n.t('add'),
    30303: i18n.t('audit'),
    // 30304: i18n.t('detail'),

    30401: i18n.t('view'),
    30402: i18n.t('audit'),
    // 30403: `${i18n.t('audit')}-${i18n.t('reject')}`,

    30501: i18n.t('view'),

    // 代理
    40101: i18n.t('view'),
    40102: i18n.t('export'),

    40201: i18n.t('view'),
    40202: `${i18n.t('add')}-${i18n.t('generalAgent')}`,
    40203: `${i18n.t('add')}-${i18n.t('agent')}`,
    40204: i18n.t('edit'),
    40205: i18n.t('manualVerification'),
    // 40206: i18n.t('cashbackCommissionSetting'),
    40207: i18n.t('currencyExchange'),

    40301: i18n.t('view'),
    // 40302: i18n.t('commissionRateReport'),

    40401: i18n.t('view'),
    // 40402: i18n.t('commissionRateReport'),

    40501: i18n.t('view'),
    40502: i18n.t('detail'),
    // 40503: `${i18n.t('detail')}-${i18n.t('withdrawalInformation')}`,
    40504: `${i18n.t('audit')}-${PERMISSION[PERMISSION.客服審核]}`,
    // 40505: `${i18n.t('audit')}-${PERMISSION_FINANCE[PERMISSION_FINANCE.客服拒絕]}`,
    40506: `${i18n.t('audit')}-${PERMISSION[PERMISSION.主管審核]}`,
    // 40507: `${i18n.t('audit')}-${PERMISSION_FINANCE[PERMISSION_FINANCE.主管拒絕]}`,
    40508: `${i18n.t('audit')}-${PERMISSION[PERMISSION.財務審核]}`,
    // 40509: `${i18n.t('audit')}-${PERMISSION_FINANCE[PERMISSION_FINANCE.財務拒絕]}`,
    40510: i18n.t('export'),

    40601: i18n.t('view'),
    // 40602: i18n.t('detail'),
    40603: `${i18n.t('audit')}-${PERMISSION[PERMISSION.財務審核]}`,
    // 40604: `${i18n.t('audit')}-${i18n.t('reject')}`,
    40605: i18n.t('export'),

    40701: i18n.t('view'),
    // 40702: i18n.t('detail'),
    40703: i18n.t('add'),
    40704: i18n.t('audit'),
    // 40705: `${i18n.t('audit')}-${i18n.t('reject')}`,
    40706: i18n.t('export'),

    40801: i18n.t('view'),
    // 40802: i18n.t('detail'),
    40803: i18n.t('delete'),

    40901: i18n.t('view'),
    // 40902: i18n.t('rebateReport'),
    // 40903: i18n.t('commissionRateReport'),
    // 40904: `${i18n.t('rebateReport')}-${i18n.t('detail')}`,
    // 40905: `${i18n.t('commissionRateReport')}-${i18n.t('detail')}`,
    40906: i18n.t('manualDistribution'),
    // 40907: `${i18n.t('commissionRateReport')}-${i18n.t('manualDistribution')}`,
    40908: i18n.t('batchDistribution'),
    // 40909: `${i18n.t('commissionRateReport')}-${i18n.t('batchDistribution')}`,

    41001: i18n.t('view'),
    41002: i18n.t('export'),

    41101: i18n.t('view'),
    41102: i18n.t('detail'),
    41103: i18n.t('edit'),
    41104: i18n.t('add'),
    41105: `${i18n.t('agentAccount')}-${i18n.t('detail')}`,
    41106: `${i18n.t('agentAccount')}-${i18n.t('edit')}`,

    // 前台頁面
    50101: i18n.t('view'),
    50102: i18n.t('add'),
    50103: i18n.t('delete'),
    50104: i18n.t('edit'),
    // 50105: `${i18n.t('subTitle')}-${i18n.t('view')}`,
    // 50106: `${i18n.t('subTitle')}-${i18n.t('add')}`,
    // 50107: `${i18n.t('subTitle')}-${i18n.t('edit')}`,
    // 50108: `${i18n.t('subTitle')}-${i18n.t('delete')}`,
    // 50109: `${i18n.t('subTitle')}-${i18n.t('detail')}`,

    50201: i18n.t('add'),
    50203: i18n.t('edit'),
    50204: i18n.t('delete'),
    50205: i18n.t('view'),

    50301: i18n.t('view'),
    50302: i18n.t('edit'),

    50401: i18n.t('add'),
    50402: i18n.t('edit'),
    50403: i18n.t('view'),
    // 50404: i18n.t('detail'),
    50405: i18n.t('delete'),

    // 統計報表
    60101: i18n.t('view'),
    // 60102: i18n.t('view'),

    // 60201: i18n.t('view'),
    // 60202: i18n.t('totalDepositAmount'),
    // 60203: i18n.t('totalWithdrawalFee'),
    // 60204: i18n.t('recharge'),
    // 60205: i18n.t('withdrawal'),
    // 60206: i18n.t('totalWithdrawalAmount'),
    // 60207: i18n.t('losingGames'),
    // 60208: i18n.t('profitableGames'),
    // 60209: i18n.t('profitableMembersTop20'),
    // 60210: i18n.t('losingMembersTop20'),
    // 60211: i18n.t('gameProfit'),
    // 60212: i18n.t('activeMembers'),
    // 60213: i18n.t('bonusAmount'),
    // 60214: i18n.t('newUsers'),

    60301: i18n.t('view'),
    60302: i18n.t('export'),

    60401: i18n.t('view'),
    60402: i18n.t('export'),

    60501: i18n.t('view'),
    // 60502: i18n.t('agent'),
    // 60503: i18n.t('member'),
    // 60504: i18n.t('game'),

    60601: i18n.t('view'),
    // 60602: i18n.t('agent'),
    // 60603: i18n.t('member'),
    // 60604: i18n.t('promotion'),

    60701: i18n.t('view'),
    60702: i18n.t('export'),

    60801: i18n.t('view'),
    60802: i18n.t('export'),

    // 聊天室
    70101: i18n.t('view'),
    // 70102: i18n.t('detail'),
    70103: i18n.t('privateMessage'),

    70201: i18n.t('view'),
    70202: i18n.t('edit'),

    70301: i18n.t('view'),
    70302: `${i18n.t('add')}-${i18n.t('type')}`,
    70303: `${i18n.t('add')}-${i18n.t('content')}`,
    70304: i18n.t('edit'),
    70305: i18n.t('delete'),

    70401: i18n.t('view'),
    70402: i18n.t('edit'),

    // 進階
    '00101': i18n.t('view'),
    '00102': i18n.t('edit'),

    '00201': i18n.t('view'),
    '00202': i18n.t('edit'),

    '00301': i18n.t('view'),
    '00302': i18n.t('edit'),

    '00401': i18n.t('view'),
    '00402': i18n.t('edit'),

    '00501': i18n.t('view'),
    // '00502': i18n.t('add'),
    '00503': i18n.t('edit'),

    '00601': i18n.t('view'),
    '00602': i18n.t('add'),
    '00603': i18n.t('edit'),
    '00604': i18n.t('delete'),

    '00701': i18n.t('view'),
    // '00702': i18n.t('detail'),
    '00703': i18n.t('add'),
    '00704': i18n.t('edit'),
    '00705': i18n.t('delete'),

    '00801': i18n.t('view'),
    '00802': i18n.t('add'),
    '00803': i18n.t('edit'),
    '00804': i18n.t('modificationPassword'),

    '00901': i18n.t('view'),
    '00902': i18n.t('edit'),
    '00903': i18n.t('add'),
    '00904': i18n.t('delete'),

    '01001': i18n.t('view'),
    '01002': i18n.t('add'),
    '01003': i18n.t('edit'),
    '01004': i18n.t('delete'),

    '01101': i18n.t('view'),
    '01102': i18n.t('add'),
    '01103': i18n.t('edit'),
    '01104': i18n.t('delete'),

    '01201': i18n.t('view'),
    '01202': i18n.t('edit'),

    '01301': i18n.t('view'),
  }

  const onFinish = async (formData: any) => {
    setLoading(true);

    const permissionCode: any = [];
    allList.forEach((management: any) => {
      management.child.forEach((item: any) => {
        if (formData[`cb-${item.key}`]) permissionCode.push(item.key);
      })
    })

    const response = await PostPermissionUpdate({
      Id: id,
      Name: formData.Name,
      Remark: formData.Remark,
      Status: formData.Status,
      PermissionCode: permissionCode,
    })
    if (response.State === RESPONSE_TYPE.成功) {
      mutate();
      message.success(i18n.t('saveSuccess'));

      // navigate('/advanced/permission_name');
    } else {
      if (permissionCode.length === 0) {
        message.error(i18n.t('permissionsNotChecked'));
      } else {
        const msg = RESPONSE_CODE[response.Message as keyof typeof RESPONSE_CODE];
        message.error(msg || i18n.t('processingFailed'));
      }
    }

    setLoading(false);
  };

  const [menuCheckState, setMenuCheckState] = useState<any>({});
  const updateCheckState = async (record: any, level: any, checked: any) => {
    setLoading(true);

    const fieldsValue: any = {};

    let menuTotal = 0
    let menuSum = 0
    let managementSum = 0

    allList.forEach((page: any) => {
      managementSum = 0;

      if (page.MenuId === record.MenuId) {
        menuTotal = menuTotal + page.child.length

        page.child.forEach((item: any) => {
          // 計算勾選數量
          if (
            (level === 'menu' && checked) ||
            (page.key === record.key && (
              (level === 'management' && checked) ||
              (level === 'operation' && form.getFieldValue(`cb-${item.key}`))
            ))
          ) {
            managementSum++
            menuSum++
          }
          else if (
            page.key !== record.key && (level === 'management' || level === 'operation') && form.getFieldValue(`cb-${item.key}`)
          ) {
            menuSum++
          }
          // 改變勾選狀態
          if ((level === 'management' && page.key === record.key) || level === 'menu') {
            fieldsValue[`cb-${item.key}`] = checked;
          }
        })

        if (((level === 'management' || level === 'operation') && page.key === record.key) || level === 'menu') {
          if (managementSum === 0) menuCheckState[`management-${page.key}`] = CHECKBOX_DISPLAY.空
          else if (managementSum > 0 && managementSum < page.child.length) menuCheckState[`management-${page.key}`] = CHECKBOX_DISPLAY.方塊
          else if (managementSum === page.child.length) menuCheckState[`management-${page.key}`] = CHECKBOX_DISPLAY.打勾
        }
      }
    })

    if (menuSum === 0) menuCheckState[`menu-${record.MenuId}`] = CHECKBOX_DISPLAY.空
    else if (menuSum > 0 && menuSum < menuTotal) menuCheckState[`menu-${record.MenuId}`] = CHECKBOX_DISPLAY.方塊
    else if (menuSum === menuTotal) menuCheckState[`menu-${record.MenuId}`] = CHECKBOX_DISPLAY.打勾
    form.setFieldsValue({ ...fieldsValue })
    setMenuCheckState({ ...menuCheckState })

    setLoading(false);
  };
  const updateCheckStateOne = async () => {
    const fieldsValue: any = {};
    const permissionCode = JSON.parse(one.Data.PermissionCode);

    let menuTotal = 0
    let menuSum = 0
    let managementSum = 0

    let menuId = allList[0]?.MenuId;

    allList.forEach((page: any, i: number) => {
      managementSum = 0;
      menuTotal = menuTotal + page.child.length;

      // management
      page.child.forEach((item: any) => {
        // 計算勾選數量
        if (permissionCode.includes(String(item.key))) {
          managementSum++
          menuSum++
          fieldsValue[`cb-${item.key}`] = true;
        }
      })
      if (managementSum === 0) menuCheckState[`management-${page.key}`] = CHECKBOX_DISPLAY.空
      else if (managementSum > 0 && managementSum < page.child.length) menuCheckState[`management-${page.key}`] = CHECKBOX_DISPLAY.方塊
      else if (managementSum === page.child.length) menuCheckState[`management-${page.key}`] = CHECKBOX_DISPLAY.打勾

      // menu
      if ((allList[i + 1]?.MenuId !== menuId) || !allList[i + 1]) {
        if (menuSum === 0) menuCheckState[`menu-${page.MenuId}`] = CHECKBOX_DISPLAY.空
        else if (menuSum > 0 && menuSum < menuTotal) menuCheckState[`menu-${page.MenuId}`] = CHECKBOX_DISPLAY.方塊
        else if (menuSum === menuTotal) menuCheckState[`menu-${page.MenuId}`] = CHECKBOX_DISPLAY.打勾

        menuId = allList[i + 1]?.MenuId;
        menuSum = 0;
        menuTotal = 0;
      }
    })
    form.setFieldsValue({ ...fieldsValue })
    setMenuCheckState({ ...menuCheckState })

    setLoading(false)
  };

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1 pb-2">
        <Spin spinning={loading}>
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Row>
              {/* 名稱 */}
              <Col span={24}>
                <Row justify="space-between">
                  <Col>
                    <Row gutter={[12, 12]}>
                      <Col span={20}>
                        <Form.Item name="Name" label={i18n.t('permissionName')} rules={[{ required: true }]}>
                          <Input className="w-26" placeholder={`${i18n.t('enter')}`} />
                        </Form.Item>
                      </Col>
                      <Col span={20}>
                        <Form.Item name="Remark" label={i18n.t('description')}>
                          <Input.TextArea className="w-26" placeholder={`${i18n.t('enter')}`} autoSize={{ minRows: 2, maxRows: 1 }} />
                        </Form.Item>
                      </Col>
                      <Col span={20}>
                        <Form.Item name="Status" className="w-26" label={i18n.t('status')} rules={[{ required: true }]}>
                          <Select
                            placeholder={`${i18n.t('pleaseSelect')}`}
                            options={enumToOptions(PERMISSION_STATE)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Button className="mr-1" onClick={() => navigate('/advanced/permission_name')}>{i18n.t('cancel')}</Button>
                    <Button type="primary" onClick={form.submit}>{i18n.t('confirm')}</Button>
                  </Col>
                </Row>
              </Col>
              {/* 權限 */}
              <Col className="size-16 font-w-md mt-1">{i18n.t('backOfficePermissions')}</Col>
              <Col span={24} className="mt-1">
                <Table
                  size="middle"
                  bordered
                  dataSource={allList}
                  columns={[
                    {
                      title: <><span className="require">*</span>{i18n.t('page')}</>,
                      width: '25%',
                      onCell: (record: any) => ({ rowSpan: record.rowSpan || 0 }),
                      render: (_, record: any) =>
                        <Checkbox
                          indeterminate={menuCheckState[`menu-${record.MenuId}`] === CHECKBOX_DISPLAY.方塊}
                          checked={menuCheckState[`menu-${record.MenuId}`] === CHECKBOX_DISPLAY.打勾}
                          onChange={e => updateCheckState(record, 'menu', e.target.checked)}
                        >{menui18n[record.MenuId] ? menui18n[record.MenuId]/* + `(${record.MenuId})`*/ : record.MenuId}</Checkbox>,
                    },
                    {
                      className: 'size-12',
                      width: '25%',
                      render: (_, record: any) =>
                        <Checkbox
                          indeterminate={menuCheckState[`management-${record.key}`] === CHECKBOX_DISPLAY.方塊}
                          checked={menuCheckState[`management-${record.key}`] === CHECKBOX_DISPLAY.打勾}
                          onChange={e => updateCheckState(record, 'management', e.target.checked)}
                        >{managementi18n[record.key] ? managementi18n[record.key]/* + `(${record.key})`*/ : record.key}</Checkbox>
                    },
                    {
                      title: i18n.t('permissions'),
                      width: '50%',
                      render: (_, record) => record.child.map((item: any) =>
                        <Form.Item key={item.key} name={`cb-${item.key}`} valuePropName="checked" style={{ display: 'inline-block' }}>
                          <Checkbox
                            onChange={e => updateCheckState(record, 'operation', e.target.checked)}
                          >{operationi18n[item.key] ? operationi18n[item.key] + `(${item.key})` : item.key}</Checkbox>
                        </Form.Item>
                      )
                    },
                  ]}
                  pagination={false}
                />
              </Col>
            </Row>
          </Form>
        </Spin>
      </Content>
    </div>
  );
};

export default PageMain;