import { ArrowDownOutlined } from "@ant-design/icons";
import { Button, Col, Descriptions, Form, Image, Input, Modal, Row, Select, Space, Spin, message } from "antd";
import { UploadFile } from "antd/lib";
import { useForm } from "antd/lib/form/Form";
import { RESPONSE_CODE_CHAT } from "constants/response";
import dayjs from "dayjs";
import { ROLE } from "enum/socket";
import { RESPONSE_TYPE } from "enum/state";
import useIcon from "hooks/icon.hook";
import i18n from "i18n";
import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";
import { MessageList } from "react-chat-elements";
import { ChatEnd, ChatHistory, ChatTransfer, CreateQuickReplyCategory, CreateQuickReplyContent, DeleteChatQuickReply, UpdateChatAccount, UpdateChatQuickReply } from "services/chat.service";
import { UploadImage } from "./layout.component";

// 聊天室設置 帳號編輯

enum AVATAR_TYPE {
  自訂頭像 = 1,
  預設頭像 = 0
}

export const PopupEditChatAccount = ({ isOpen, close, data, refresh }: any) => {
  const [form] = useForm();
  const [avatarType, setAvatarType] = useState(AVATAR_TYPE.預設頭像);
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);

  useEffect(() => {
    if (data && isOpen) {
      form.setFieldsValue({
        permission: data.permissionName,
        account: data.backOfficeAccount,
        nickname: data.chatNickname,
        haveAvatar: data.Avatar ? 1 : 0,
      })
      setImageData([
        {
          uid: data.Avatar,
          name: data.Avatar,
          url: data.Avatar,
        },
      ])
      setAvatarType(data.Avatar ? AVATAR_TYPE.自訂頭像 : AVATAR_TYPE.預設頭像);
    }
  }, [data, isOpen])

  const handleUpload = (key: number) => setAvatarType(key);

  const onFinish = async (formData: any) => {
    const response = await UpdateChatAccount({
      AccountId: data.AccountId,
      Nickname: formData.nickname,
      Avatar:
        avatarType === AVATAR_TYPE.預設頭像
          ? ''
          : (imageData ? imageData[0].url : data.Avatar)
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('editSuccess'));
      onClose();
      refresh();
    } else {
      message.error(RESPONSE_CODE_CHAT[response.Message as keyof typeof RESPONSE_CODE_CHAT]);
    }
  }

  const onClose = () => {
    form.resetFields();
    setImageData([])
    setAvatarType(data.Avatar ? AVATAR_TYPE.自訂頭像 : AVATAR_TYPE.預設頭像);
    close();
  }

  return (
    <Modal
      title={i18n.t('edit')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="permission" label={i18n.t('backOfficePermissions')} required
              rules={[{ required: true }]}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="account" label={i18n.t('account')} required
              rules={[{ required: true }]}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="nickname" label={i18n.t('chatNickname')} required
              rules={[{ required: true, type: 'string', min: 4, max: 20 }]}>
              <Input placeholder={`${i18n.t('4To20Characters')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="haveAvatar" label={i18n.t('customerServiceAvatar')} required>
              <Select
                className="full"
                onChange={handleUpload}
                options={[
                  { value: AVATAR_TYPE.預設頭像, label: i18n.t('systemDefault') },
                  { value: AVATAR_TYPE.自訂頭像, label: i18n.t('customization') },
                ]}
              />
            </Form.Item>
          </Col>
          {
            avatarType === AVATAR_TYPE.自訂頭像 &&
            <Col span={24}>
              <Form.Item name="Avatar" valuePropName="fileList" label={i18n.t('image')} rules={[{ required: !imageData[0] }]}>
                <UploadImage
                  name="Avatar"
                  url={'/upload/images/public/message'}
                  imageData={imageData}
                  setImageData={setImageData}
                  w={100}
                  h={100}
                  form={form}
                  crop
                />
              </Form.Item>
              <div className="color-03 size-12">{i18n.t('onlyJpgOrPngFiles')}<span className="require">{i18n.t('imageMobileVersionWidth100pxHeight100px')}</span>{i18n.t('allowedAndSizeNotExceed500kb')}</div>
            </Col>
          }
          {
            avatarType === AVATAR_TYPE.預設頭像 &&
            <Col span={24}>
              <Image src={Cookies.get('defaultAvatar')} width={50} height={50} style={{ borderRadius: '50%' }} preview={false} />
            </Col>
          }
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit" loading={loading}>{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

// 空的
export const PopupDeleteChatAccount = ({ isOpen, close }: {
  isOpen: boolean;
  close: () => void;
}) => {
  const icon = useIcon();

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={icon.icon12} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button className="mt-1" key="cancel" onClick={close}>{i18n.t('cancel')}</Button>
          <Button className="mt-1" key="confirm" type="primary">{i18n.t('confirm')}</Button>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmDelete')}？</div>
      </Space>
    </Modal>
  )
}

// 空的
export const PopupDeleteChatReply = ({ isOpen, close, id, refresh }: {
  isOpen: boolean;
  close: () => void;
  id: number | null;
  refresh: () => void;
}) => {
  const icon = useIcon();

  const onDelete = async () => {
    try {
      const response = await DeleteChatQuickReply({
        Id: id
      })
      if (response.State === RESPONSE_TYPE.成功) {
        message.success(i18n.t('deleteSuccess'));
        close();
        refresh();
      } else {
        message.error(RESPONSE_CODE_CHAT[response.Message as keyof typeof RESPONSE_CODE_CHAT]);
      }
    } catch (error) {
      return;
    }
  }

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={icon.icon12} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button className="mt-1" key="cancel" onClick={close}>{i18n.t('cancel')}</Button>
          <Button className="mt-1" key="confirm" type="primary" onClick={onDelete}>{i18n.t('confirm')}</Button>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmDelete')}？</div>
      </Space>
    </Modal>
  )
}

export const PopupAddChatReply = ({ isOpen, close, refresh }: any) => {
  const [form] = useForm();
  const onFinish = async (value: {
    category: string;
  }) => {
    try {
      const response = await CreateQuickReplyCategory({
        Name: value.category
      })
      if (response.State === RESPONSE_TYPE.成功) {
        message.success(i18n.t('addSuccess'));
        onClose();
      } else {
        message.error(RESPONSE_CODE_CHAT[response.Message as keyof typeof RESPONSE_CODE_CHAT]);
      }
    } catch (error) {
      return;
    }
  }

  const onClose = () => {
    form.resetFields();
    close();
    refresh();
  }

  return (
    <Modal
      title={i18n.t('add')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
    >
      <Form layout="vertical" form={form} initialValues={{}} onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="category" label={i18n.t('typeName')} required
              rules={[{ required: true, type: 'string', min: 4, max: 20 }]}>
              <Input placeholder={`${i18n.t('4To20Characters')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export const PopupAddChatReplyContent = ({ isOpen, close, Category, refresh }: {
  isOpen: boolean;
  close: () => void;
  Category: ChatQuickReplyCategory[];
  refresh: () => void;
}) => {
  const [form] = useForm();
  const onFinish = async (value: {
    categoryName: number;
    content: string;
  }) => {
    try {
      const response = await CreateQuickReplyContent({
        CategoryId: value.categoryName,
        Content: value.content
      })
      if (response.State === RESPONSE_TYPE.成功) {
        message.success(i18n.t('addSuccess'));
        onClose();
      } else {
        message.error(RESPONSE_CODE_CHAT[response.Message as keyof typeof RESPONSE_CODE_CHAT]);
      }
    } catch (error) {
      return;
    }
  }

  const onClose = () => {
    form.resetFields();
    close();
    refresh();
  }

  return (
    <Modal
      title={i18n.t('add')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
    >
      <Form layout="vertical" form={form} initialValues={{}} onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="categoryName" label={i18n.t('typeName')} required
              rules={[{ required: true }]}>
              <Select
                className="full"
                placeholder={i18n.t('pleaseSelect')}
                options={
                  Category ?
                    Category.map((item) => (
                      { value: item.Id, label: item.Name }
                    )) : []
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="content" label={i18n.t('content')} required
              rules={[{ required: true, type: 'string', min: 4 }]}>
              <Input.TextArea
                size="middle"
                showCount
                maxLength={200}
                minLength={4}
                placeholder={`${i18n.t('4To200Characters')}`}
                autoSize={{ minRows: 3, maxRows: 3 }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export const PopupEditChatReply = ({ isOpen, close, Category, editData, refresh }: {
  isOpen: boolean;
  close: () => void;
  Category: ChatQuickReplyCategory[];
  editData: any;
  refresh: () => void;
}) => {
  const [form] = useForm();

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        category: editData.categoryId,
        content: editData.content
      })
    }
  }, [Category, editData]);

  const onFinish = async (value: {
    category: number;
    content: string;
  }) => {
    try {
      const response = await UpdateChatQuickReply({
        Id: editData.key,
        CategoryId: value.category,
        Content: value.content
      })
      if (response.State === RESPONSE_TYPE.成功) {
        message.success(i18n.t('editSuccess'));
        onClose();
      } else {
        message.error(RESPONSE_CODE_CHAT[response.Message as keyof typeof RESPONSE_CODE_CHAT]);
      }
    } catch (error) {
      return;
    }
  }

  const onClose = () => {
    close();
    refresh();
  }

  return (
    <Modal
      title={i18n.t('edit')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="category" label={i18n.t('typeName')} required
              rules={[{ required: true }]}>
              <Select
                className="full"
                options={
                  Category ?
                    Category.map(item => (
                      { value: item.Id, label: item.Name }
                    )) : []
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="content" label={i18n.t('content')} required
              rules={[{ required: true, type: 'string', min: 4, max: 200 }]}>
              <Input.TextArea
                size="middle"
                showCount
                maxLength={200}
                autoSize={{ minRows: 3, maxRows: 3 }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export const PopupChatHistoryDetail = ({ isOpen, close, role, account, ProcessTime, sessionId, refresh }: {
  isOpen: boolean;
  close: () => void;
  role: number | null;
  account: string | null;
  ProcessTime: string | null;
  sessionId: string | null;
  refresh: () => void;
}) => {
  const icon = useIcon();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [messageHistory, setMessageHistory] = useState<any>([]);
  const messageListReferance = useRef()
  const { data: Chat, isValidating, mutate } = ChatHistory({
    SessionId: sessionId,
    StartDate: ProcessTime,
    EndDate: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    PageIndex: page[0],
    PageSize: page[1]
  }, !!sessionId);

  useEffect(() => {
    if (Chat) {
      setMessageHistory([
        ...Chat.Data
          .sort((a: ChatMessageInfo, b: ChatMessageInfo) => (
            dayjs(a.SendTime).valueOf() - dayjs(b.SendTime).valueOf()
          )).map((item: ChatInfo) => (
            {
              key: item.Id,
              position: item.SenderRole === ROLE.客服 ? 'right' : 'left',
              avatar: item.SenderRole !== ROLE.客服 ? icon.avatar04 : '',
              type: item.Type === 0 ? 'text' : 'photo',
              title: item.SenderAccount,
              text: item.Type === 0 ? item.Content : '',
              date: item.SendTime,
              dateString: dayjs(item.SendTime).format('hh:mm'),
              data: {
                uri: item.Content,
                width: 200,
                height: 200
              }
            }
          )),
      ])
    }
  }, [Chat]);

  const onClose = () => {
    setPage([1, 10]);
    close();
    refresh();
  }
  // 上滑載入更多歷史訊息
  const [showBackToBottom, setShowBackToBottom] = useState(false);
  const chatWindowRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const chatWindow = chatWindowRef.current;
    if (chatWindow) {
      setTimeout(() => {
        chatWindow.scrollTop = chatWindow.scrollHeight;
      }, 300);
    }
  }, [sessionId]);
  const handleScroll = () => {
    const chatWindow = chatWindowRef.current;
    if (chatWindow) {
      const isAtBottom =
        chatWindow.scrollTop + chatWindow.clientHeight === chatWindow.scrollHeight;
      const isAtTop = chatWindow.scrollTop === 0;
      if (isAtBottom) {
        setShowBackToBottom(false);
      } else {
        setShowBackToBottom(true);
      }
      if (isAtTop) {
        setPage([1, page[1] + 10]);
      }
    }
  }
  const scrollToBottom = (delay = 300) => {
    const chatWindow = chatWindowRef.current;
    if (chatWindow) {
      setTimeout(() => {
        chatWindow.scrollTop = chatWindow.scrollHeight;
      }, delay);
    }
  };

  return (
    <Modal
      title={i18n.t('detail')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={900}
      footer={
        <Row justify="center">
          <Button type="primary" onClick={onClose}>{i18n.t('close')}</Button>
        </Row>
      }
    >
      <Spin spinning={isValidating}>
        <Descriptions size="small" bordered labelStyle={{ width: 150 }}>
          <Descriptions.Item label={i18n.t('sendMessageRole')}>
            {ROLE[role as number]}
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('account')}>
            {role === ROLE.訪客 ? '-' : account}
          </Descriptions.Item>
        </Descriptions>
        <div ref={chatWindowRef} style={{
          height: 600,
          minHeight: 600,
          maxHeight: 600,
          overflowY: 'auto',
          border: '.5px solid #e3e3e3',
          scrollBehavior: 'smooth',
          position: 'relative'
        }} onScroll={handleScroll}>
          {/* <div className="color-03 size-12 mt-1" style={{
            display: 'flex',
            justifyContent: 'center',
          }}>
            <span style={{
              border: '.5px solid #e3e3e3',
              borderRadius: 10,
              padding: '2px 10px',
            }}>
              {ProcessTime ? ProcessTime?.split(' ')[0] : ''}
            </span>
          </div> */}
          <MessageList
            className='message-list mt-1'
            referance={messageListReferance}
            dataSource={messageHistory}
            lockable={true}
            downButton={true}
            downButtonBadge={10}
            sendMessagePreview={true}
          />
          <div style={{
            display: `${showBackToBottom ? '' : 'none'}`,
            textAlign: 'center',
            position: 'sticky',
            bottom: 0,
          }}>
            <div style={{
              display: 'inline-block',
              padding: '2px 5px',
              border: '.5px solid #e3e3e3',
              borderRadius: 8,
              cursor: 'pointer',
              marginBottom: 5
            }} onClick={() => scrollToBottom(0)}>
              <ArrowDownOutlined />
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  )
}

export const PopupCloseChat = ({ isOpen, close, sessionId, setSessionId, refresh, setIsContact }: {
  isOpen: boolean;
  close: () => void;
  sessionId: string;
  setSessionId: any;
  refresh: () => void;
  setIsContact: any;
}) => {
  const icon = useIcon();
  const [isLoading, setIsLoading] = useState(false);

  // 結束對話
  const onEnd = async () => {
    setIsLoading(true);
    try {
      const response = await ChatEnd({
        SessionId: sessionId,
      })
      if (response.State === RESPONSE_TYPE.成功) {
        setSessionId('');
        setIsContact(false);
        close();
      } else {
        message.error(RESPONSE_CODE_CHAT[response.Message as keyof typeof RESPONSE_CODE_CHAT]);
      }
    } catch (error) {
      return;
    }
    setIsLoading(false);
  }

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={icon.icon12} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button className="mt-1" onClick={close}>{i18n.t('cancel')}</Button>
          <Button className="mt-1" type="primary"
            loading={isLoading} onClick={onEnd}>{i18n.t('confirm')}</Button>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmEndCurrentConversation')}?</div>
      </Space>
    </Modal>
  )
}

export const PopupOfflineChat = ({ isOpen, close, setChatType, setTabActiveKey }: {
  isOpen: boolean;
  close: () => void;
  setChatType: any;
  setTabActiveKey: any;
}) => {
  const icon = useIcon();

  enum CHAT_TYPE {
    等待中 = '1',
    我的對話 = '2',
    同事對話 = '3'
  }

  const onClose = () => {
    setChatType(CHAT_TYPE.我的對話);
    setTabActiveKey('2')
    close();
  }

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={icon.errorIcon} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('error')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button type="primary" className="mt-1" onClick={onClose}>{i18n.t('close')}</Button>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('pleaseEndCurrentConversationBeforeGoingOffline')}</div>
      </Space>
    </Modal>
  )
}

// 轉接給其他客服
export const PopupTransferChat = ({ isOpen, close, sessionId, setSessionId, transferInfo, refresh }: {
  isOpen: boolean;
  close: () => void;
  sessionId: string;
  setSessionId: any;
  transferInfo: any[];
  refresh: () => void;
}) => {
  const icon = useIcon();
  const [isLoading, setIsLoading] = useState(false);

  const onTransfer = async () => {
    setIsLoading(true);
    try {
      const response = await ChatTransfer({
        SessionId: sessionId,
        AccountId: transferInfo[0]
      })
      if (response.State === RESPONSE_TYPE.成功) {
        message.success(i18n.t('transferSuccess'));
        setSessionId('');
        close();
      } else {
        message.error(RESPONSE_CODE_CHAT[response.Message as keyof typeof RESPONSE_CODE_CHAT]);
      }
    } catch (error) {
      return;
    }
    setIsLoading(false);
  }

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={icon.icon12} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button className="mt-1" onClick={close}>{i18n.t('cancel')}</Button>
          <Button className="mt-1" type="primary"
            loading={isLoading} onClick={onTransfer}>{i18n.t('confirm')}</Button>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmTransferTo')} {transferInfo ? transferInfo[2] : '-'} - {transferInfo[1] ? transferInfo[1] : Cookies.get('defaultNickname')} ?</div>
      </Space>
    </Modal>
  )
}

// 使用者關閉瀏覽器
export const PopupUserCloseBrowser = ({ isOpen, close, setChatType, setTabActiveKey }: {
  isOpen: boolean;
  close: () => void;
  setChatType: any;
  setTabActiveKey: any;
}) => {
  const icon = useIcon();

  enum CHAT_TYPE {
    等待中 = '1',
    我的對話 = '2',
    同事對話 = '3'
  }

  const onClose = () => {
    setChatType(CHAT_TYPE.我的對話);
    setTabActiveKey('2')
    close();
  }

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={icon.icon12} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('error')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button type="primary" className="mt-1" onClick={onClose}>{i18n.t('close')}</Button>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('pleaseEndCurrentConversationBeforeGoingOffline')}</div>
      </Space>
    </Modal>
  )
}