import { Button, Col, Form, Row, Spin, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import 'chart.js/auto';
import { Export, LayoutDateSelect, LayoutNav } from 'components/layout.component';
import { ExcelColumns } from 'constants/excel';
import dayjs from 'dayjs';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { GetReportGameProvider } from 'services/report.service';
import * as common from 'utils/common';

// 遊戲統計分析

interface SearchParams {
  StartDate: string;
  EndDate: string;
}

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();
  
  const [form] = useForm();
  const [date, setDate] = useState<string[]>();
  const [reset, setReset] = useState(false);
  const [params, setParams] = useState<SearchParams>({
    StartDate: date ? date[0] : dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    EndDate: date ? date[1] : dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  });
  const { data: Report, isValidating, mutate } = GetReportGameProvider({
    ...params,
  });

  // 圖表設定
  const config = {
    staticPlot: false,
    responsive: true,
    displaylogo: false,
    showEditInChartStudio: false,
  };
  // 取得圖表資訊
  const [reportData, setReportData] = useState<{
    dataSource?: {
      label?: string;
      betMemberCount?: number;
      betCount?: number;
      validBetAmount?: number;
      betAmount?: number;
    }[]
  }>({});
  useEffect(() => {
    if (Report && Report.Data) {
      const data = Report.Data.map((item: ReportGame, i: number) => (
        {
          label: item.ProviderCode,
          betMemberCount: item.TotalBetMemberCount,
          betCount: item.TotalBetCount,
          validBetAmount: item.TotalValidBetAmount,
          betAmount: item.TotalBetAmount
        }
      ))
      setReportData({
        dataSource: data
      });
    }
  }, [Report])

  // 投注人數圖表
  const [betPieData, setBetPieData] = useState<{ data: any[], layout: {} }>({
    data: [],
    layout: {}
  });
  useEffect(() => {
    setBetPieData({
      data: [
        {
          type: 'pie',
          values: reportData.dataSource?.map(item => item.betMemberCount),
          labels: reportData.dataSource?.map(item => item.label),
          text: reportData.dataSource?.map(item => `${i18n.t('bettingMembers')}：${item.betMemberCount}`),
          hoverinfo: "label+text+percent",
          textinfo: "label+percent",
          textposition: "outside",
          automargin: true,
          sort: false,
        }
      ],
      layout: {
        height: 600,
        width: 600,
        showlegend: false,
      }
    });
  }, [reportData]);

  // 投注筆數圖表
  const [totalBetPieData, setTotalBetPieData] = useState<{ data: any[], layout: {} }>({
    data: [],
    layout: {}
  });
  useEffect(() => {
    setTotalBetPieData({
      data: [
        {
          type: 'pie',
          values: reportData.dataSource?.map(item => item.betCount),
          labels: reportData.dataSource?.map(item => item.label),
          text: reportData.dataSource?.map(item => `${i18n.t('totalBets')}：${item.betCount}`),
          hoverinfo: "label+text+percent",
          textinfo: "label+percent",
          textposition: "outside",
          automargin: true,
          sort: false
        }
      ],
      layout: {
        height: 600,
        width: 600,
        showlegend: false,
      }
    });
  }, [reportData]);

  // 有效投注圖表
  const [validBetPieData, setValidBetPieData] = useState<{ data: any[], layout: {} }>({
    data: [],
    layout: {}
  });
  useEffect(() => {
    setValidBetPieData({
      data: [
        {
          type: 'pie',
          values: reportData.dataSource?.map(item => item.validBetAmount),
          labels: reportData.dataSource?.map(item => item.label),
          text: reportData.dataSource?.map(item => `${i18n.t('validBet')}：${common.toFormatNumber(item.validBetAmount)}`),
          hoverinfo: "label+text+percent",
          textinfo: "label+percent",
          textposition: "outside",
          automargin: true,
          sort: false
        }
      ],
      layout: {
        height: 600,
        width: 600,
        showlegend: false,
      }
    });
  }, [reportData]);

  // 總投注圖表
  const [totalPieAmountBarData, setTotalBetAmountPieData] = useState<{ data: any[], layout: {} }>({
    data: [],
    layout: {}
  });
  useEffect(() => {
    setTotalBetAmountPieData({
      data: [
        {
          type: 'pie',
          values: reportData.dataSource?.map(item => item.betAmount),
          labels: reportData.dataSource?.map(item => item.label),
          text: reportData.dataSource?.map(item => `${i18n.t('totalBetAmount')}：${common.toFormatNumber(item.betAmount)}`),
          hoverinfo: "label+text+percent",
          textinfo: "label+percent",
          textposition: "outside",
          automargin: true,
          sort: false
        }
      ],
      layout: {
        height: 600,
        width: 600,
        showlegend: false,
      }
    });
  }, [reportData]);

  const onFinish = (formData: any) => {
    if (date) {
      setParams({
        StartDate: `${date[0]} 00:00:00`,
        EndDate: `${date[1]} 23:59:59`
      })
    }
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Form form={form} onFinish={onFinish}>
          <Row gutter={[12, 12]}>
            {/* 搜尋列 */}
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <LayoutDateSelect width={375} onChange={setDate} reset={reset} />
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            {/* 圖表數據 */}
            <Col span={24} >
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <Spin spinning={isValidating}>
                    <div className="size-20">{i18n.t('bettingMembers')}</div>
                    <Row align="middle" justify="center">
                      <Plot layout={betPieData.layout} data={betPieData.data} config={config as any} />
                    </Row>
                  </Spin>
                </Col>
                <Col span={12}>
                  <Spin spinning={isValidating}>
                    <div className="size-20">{i18n.t('totalBets')}</div>
                    <Row align="middle" justify="center">
                      <Plot layout={totalBetPieData.layout} data={totalBetPieData.data} config={config as any} />
                    </Row>
                  </Spin>
                </Col>
                <Col span={12}>
                  <Spin spinning={isValidating}>
                    <div className="size-20">{i18n.t('validBet')}</div>
                    <Row align="middle" justify="center">
                      <Plot layout={validBetPieData.layout} data={validBetPieData.data} config={config as any} />
                    </Row>
                  </Spin>
                </Col>
                <Col span={12}>
                  <Spin spinning={isValidating}>
                    <div className="size-20">{i18n.t('totalBetAmount')}</div>
                    <Row align="middle" justify="center">
                      <Plot layout={totalPieAmountBarData.layout} data={totalPieAmountBarData.data}
                        config={config as any} />
                    </Row>
                  </Spin>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              {$p.includes('60402') && <Export url={'/report/provider/list'} param={{ ...params }} 
                sheetName={i18n.t('gameStatisticalAnalysis')} columns={ExcelColumns.ReportGame} />}
            </Col>
            {/* 搜尋數據 */}
            <Col span={24}>
              <Spin spinning={isValidating}>
                <Table
                  size="middle"
                  bordered
                  scroll={{ y: 500 }}
                  dataSource={
                    Report ?
                      Report.Data.map((item: ReportGame, i: number) => (
                        {
                          key: i,
                          provider: item.ProviderCode,
                          betAccount: item.TotalBetMemberCount,
                          betAmount: item.TotalBetCount,
                          validBetTotal: item.TotalValidBetAmount,
                          betTotal: item.TotalBetAmount,
                          payoutsTotal: item.TotalSettleAmount,
                          winAndLoss: item.TotalWinLossAmount
                        }
                      )) : []
                  }
                  columns={[
                    {
                      key: 1,
                      title: i18n.t('gameProvider'),
                      className: 'size-12',
                      align: 'center',
                      children: [
                        {
                          title: () => (<>{i18n.t('total')}</>),
                          className: 'size-12',
                          align: 'center',
                          width: 250,
                          dataIndex: 'provider',
                        }
                      ],
                    },
                    {
                      key: 2,
                      title: i18n.t('bettingMembers'),
                      className: 'size-12',
                      align: 'right',
                      sorter: (a, b) => a.betAccount - b.betAccount,
                      defaultSortOrder: 'ascend',
                      children: [
                        {
                          title: () => Report ? Report.TotalBetMemberCount : 0,
                          className: 'size-12',
                          align: 'right',
                          dataIndex: 'betAccount',
                        }
                      ],
                    },
                    {
                      key: 3,
                      title: i18n.t('totalBets'),
                      className: 'size-12',
                      align: 'right',
                      sorter: (a, b) => a.betAmount - b.betAmount,
                      defaultSortOrder: 'ascend',
                      children: [
                        {
                          title: () => Report ? Report.TotalBetCount : 0,
                          className: 'size-12',
                          align: 'right',
                          dataIndex: 'betAmount',

                        }
                      ],
                    },
                    {
                      key: 4,
                      title: i18n.t('validBet'),
                      className: 'size-12',
                      align: 'right',
                      sorter: (a, b) => a.validBetTotal - b.validBetTotal,
                      defaultSortOrder: 'ascend',
                      children: [
                        {
                          title: () => common.toFormatNumber(Report ? Report.TotalValidBetAmount : 0),
                          className: 'size-12',
                          align: 'right',
                          dataIndex: 'validBetTotal',
                          render: (_, { validBetTotal }) => (<>{common.toFormatNumber(validBetTotal)}</>)
                        }
                      ],
                    },
                    {
                      key: 5,
                      title: i18n.t('totalBetAmount'),
                      className: 'size-12',
                      align: 'right',
                      sorter: (a, b) => a.betTotal - b.betTotal,
                      defaultSortOrder: 'ascend',
                      children: [
                        {
                          title: () => common.toFormatNumber(Report ? Report.TotalBetAmount : 0),
                          className: 'size-12',
                          align: 'right',
                          dataIndex: 'betTotal',
                          render: (_, { betTotal }) => (<>{common.toFormatNumber(betTotal)}</>)
                        }
                      ],
                    },
                    {
                      key: 6,
                      title: i18n.t('payoutTotal'),
                      className: 'size-12',
                      align: 'right',
                      sorter: (a, b) => a.payoutsTotal - b.payoutsTotal,
                      defaultSortOrder: 'ascend',
                      children: [
                        {
                          title: () => common.toFormatNumber(Report ? Report.TotalSettleAmount : 0),
                          className: 'size-12',
                          align: 'right',
                          dataIndex: 'payoutsTotal',
                          render: (_, { payoutsTotal }) => (<>{common.toFormatNumber(payoutsTotal)}</>)
                        }
                      ],
                    },
                    {
                      key: 7,
                      title: i18n.t('companyWinLoss'),
                      className: 'size-12',
                      align: 'right',
                      sorter: (a, b) => a.winAndLoss - b.winAndLoss,
                      defaultSortOrder: 'ascend',
                      children: [
                        {
                          title: () => (
                            <div className={common.numColor03(Report ? Report.TotalWinLossAmount : 0)}>
                              {common.toFormatNumber(Report ? Report.TotalWinLossAmount : 0)}
                            </div>
                          ),
                          className: 'size-12',
                          align: 'right',
                          dataIndex: 'winAndLoss',
                          render: (_, { winAndLoss }) => (
                            <div className={common.numColor03(winAndLoss)}>
                              {common.toFormatNumber(winAndLoss)}
                            </div>
                          )
                        }
                      ],
                    },
                  ]}
                  pagination={false}
                />
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;