import { Button, Col, Form, Image, Input, Modal, Row, Select, Space, message } from 'antd';
import { UploadFile } from 'antd/es/upload';
import { RESPONSE_CODE_MEMBER } from 'constants/response';
import { CONTACT_INFO, ENABLE, RESPONSE_TYPE, SOURCE_ROLE } from 'enum/state';
import useIcon from 'hooks/icon.hook';
import i18n from 'i18n';
import { useEffect, useState } from 'react';
import { CreateContact, DeleteContact, ModifyContact } from 'services/member.service';
import { enumToOptions } from 'utils/common';
import { UploadImage } from './layout.component';

export const PopupContact = ({ isOpen, id, contact, close, mutate }: any) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (contact && isOpen) {
      form.setFieldsValue({
        SourceRole: contact.SourceRole,
        SourceAccount: contact.SourceAccount,
        ContactInfo: Number(contact.ContactInfo),
        ContactContent: contact.ContactContent,
        Status: contact.Status,
      })
      contact.Photo && setImageData([
        {
          uid: contact.Photo,
          name: contact.Photo,
          url: contact.Photo,
        },
      ])
    }
  }, [contact, isOpen]);

  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);
  const handleSubmit = async (formData: any) => {
    let apiResult: any = {}
    const send: any = {
      SourceRole: formData.SourceRole,
      SourceAccount: formData.SourceAccount,
      ContactInfo: String(formData.ContactInfo),
      ContactContent: formData.ContactContent,
      Status: formData.Status,
      Photo: imageData[0] ? [imageData[0].url] : [],
    }
    if (!contact) {
      send.MemberId = id;
      apiResult = await CreateContact(send);
    }
    else {
      send.Id = contact?.Id;
      apiResult = await ModifyContact(send);
    }

    if (apiResult.State === RESPONSE_TYPE.成功) {
      message.success(!contact ? i18n.t('addSuccess') : i18n.t('updateSuccess'));
      handleCancel();
      mutate();
    } else {
      message.error(RESPONSE_CODE_MEMBER[apiResult.Message as keyof typeof RESPONSE_CODE_MEMBER]);
    }
  }

  const handleCancel = () => {
    form.resetFields();
    setImageData([]);
    close();
  }

  return (
    <Modal
      title={!contact ? i18n.t('add') : i18n.t('edit')}
      open={isOpen}
      onCancel={handleCancel}
      centered
      width={450}
      footer={false}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit} initialValues={{
        ContactInfo: CONTACT_INFO.Line,
        Status: ENABLE.啟用,
      }}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="SourceRole" label={i18n.t('source')} rules={[{ required: true }]}>
              <Select className="w-full" placeholder={`${i18n.t('pleaseSelect')}`} options={enumToOptions(SOURCE_ROLE)} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="SourceAccount" label={i18n.t('account')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('inputAccount')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="ContactInfo" label={i18n.t('contact')} rules={[{ required: true }]}>
              <Select className="w-full" options={enumToOptions(CONTACT_INFO)} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="ContactContent" label={i18n.t('content')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('enterContent')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Status" label={i18n.t('status')} rules={[{ required: true }]}>
              <Select className="w-full" options={enumToOptions(ENABLE)} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Photo" valuePropName="fileList" label={i18n.t('image')}>
              <UploadImage
                name="Photo"
                url={'/upload/images/public/contact'}
                imageData={imageData}
                setImageData={setImageData}
              />
            </Form.Item>
            <div className="color-03 size-12">{i18n.t('onlyJpgOrPngFilesAllowedAndSizeNotExceed500kb')}</div>
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={handleCancel}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

// 有的
export const PopupDeleteContact = ({ isOpen, id, close, mutate }: any) => {

  const icon = useIcon();

  const handleSubmit = async () => {

    const apiResult: ApiResult = await DeleteContact({
      Id: id,
    });

    if (apiResult.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('deleteSuccess'));
      mutate();
      close();
    } else {
      message.error(RESPONSE_CODE_MEMBER[apiResult.Message as keyof typeof RESPONSE_CODE_MEMBER]);
    }
  }

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={icon.icon12} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onOk={handleSubmit}
      onCancel={close}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button className="mt-1" key="cancel" onClick={close}>{i18n.t('cancel')}</Button>
          <Button className="mt-1" key="confirm" type="primary" onClick={handleSubmit}>{i18n.t('confirm')}</Button>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmDelete')}?</div>
      </Space>
    </Modal>
  )
}