import { Button, Col, Form, Input, Row, Select, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { Export, LayoutDateSelect, LayoutNav, LayoutPagination } from 'components/layout.component';
import { ExcelColumns } from 'constants/excel';
import dayjs from 'dayjs';
import { AGENT_LOG } from 'enum/cashlog';
import { DATE_FORMAT, DATE_TYPE } from 'enum/date';
import { SEARCH_DATE_TYPE } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { GetAgentCashlogList } from 'services/agent.service';
import * as common from 'utils/common';

// 代理錢包紀錄

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();
  const { isCashVersion: $sc } = useSite();
  const [form] = useForm();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [reset, setReset] = useState(false);
  const [date, setDate] = useState<string[]>();

  const defaultDate = SEARCH_DATE_TYPE.建立時間;
  const [params, setParams] = useState<any>({
    StartDate: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    EndDate: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  });
  const { data: recordList, isValidating, mutate } = GetAgentCashlogList({
    ...params,
    PageIndex: page[0],
    PageSize: page[1]
  });

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        Code: formData.Code,
        Account: formData.Account,
        KeyId: formData.KeyId === AGENT_LOG['代理轉帳-轉出'] ? AGENT_LOG['代理轉帳-轉入'] : formData.KeyId,
        StartDate: date[0],
        EndDate: date[1]
      })
    }
    mutate();
  }

  const onClear = () => {
    setReset(!reset);
    setPage([1, 10]);
    form.resetFields();
  }

  const columns: any = [
    {
      dataIndex: 'Code',
      title: i18n.t('order'),
      fixed: 'left',
      width: 290,
    },
    {
      dataIndex: 'AgentAccount',
      title: $sc ? i18n.t('agentAccount') : i18n.t('withdrawalAccount'),
      fixed: 'left',
      width: 150,
      render: (val: any) => val || '-'
    },
    {
      dataIndex: 'KeyId',
      title: i18n.t('transactionType'),
      fixed: 'left',
      width: 150,
      // render: (val: any) => AGENT_LOG[val]
      render: (val: any, { Amount }: any) => (
        val === String(AGENT_LOG['代理轉帳-轉入']) && Amount < 0 ? AGENT_LOG['10312'] : AGENT_LOG[val as any]
      )
    },
    {
      dataIndex: 'TargetAccount',
      title: i18n.t('collectionAccount'),
      fixed: 'left',
      width: 150,
      render: (val: any) => val || '-'
    },
    {
      dataIndex: 'BeforeAmount',
      title: i18n.t('amountBeforeTransaction'),
      align: 'right',
      width: 210,
      render: (val: any) => common.toFormatNumber(val)
    },
    {
      dataIndex: 'Amount',
      title: i18n.t('transactionAmount'),
      align: 'right',
      width: 210,
      render: (val: any) => <div className={common.numColor03(val)}>{common.toFormatNumber(val)}</div>
    },
    {
      dataIndex: 'AfterAmount',
      title: i18n.t('amountAfterTransaction'),
      align: 'right',
      width: 210,
      render: (val: any) => common.toFormatNumber(val)
    },
    {
      dataIndex: 'CreateDate',
      title: i18n.t('createTime'),
      width: 180
    },
    {
      dataIndex: 'UpdateDate',
      title: i18n.t('updateTime'),
      width: 180
    },
    {
      dataIndex: 'Remark',
      title: i18n.t('remark'),
    },
  ].filter(item => !($sc && item.dataIndex === 'TargetAccount'))

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish} initialValues={{
          TimeMode: defaultDate
        }}>
          <Row align="middle" gutter={[12, 12]}>
            <Col className="w-12">
              <Form.Item name="Code">
                <Input placeholder={`${i18n.t('order')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="Account">
                <Input placeholder={`${$sc ? i18n.t('agentAccount') : i18n.t('withdrawalAccount')}`} />
              </Form.Item>
            </Col>
            {!$sc && <Col className="w-12">
              <Form.Item name="CollectionAccount">
                <Input placeholder={`${i18n.t('collectionAccount')}`} />
              </Form.Item>
            </Col>}
            <Col className="w-12">
              <Form.Item name="KeyId">
                <Select
                  className="w-full"
                  placeholder={i18n.t('allTransactionTypes')}
                  options={[
                    { value: '', label: i18n.t('allTransactionTypes') },
                    ...common.enumToOptions(AGENT_LOG),
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]} className="mt-1">
            <Col className="w-12">
              {/* 還是鎖的 */}
              <Form.Item name="TimeMode">
                <Select
                  className="w-full"
                  disabled
                  options={[
                    { value: SEARCH_DATE_TYPE.建立時間, label: `${i18n.t('createTime')}` },
                    { value: SEARCH_DATE_TYPE.審核時間, label: `${i18n.t('auditTime')}` },
                  ]}
                />
              </Form.Item>
            </Col>
            <LayoutDateSelect width={375} onChange={setDate} reset={reset}
              hiddenDateType={[DATE_TYPE.上週]} dateFormatDefault={DATE_FORMAT.日期時間} />
            <Col>
              <Button type="primary" className="mr-1" htmlType="submit">{i18n.t('search')}</Button>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
          <Row className="mt-1">
            {
              $p.includes('41002') &&
              <Col>
                <Export url={'/agent/cashlog/list'} param={{
                  ...params, PageSize: recordList && recordList.TotalRecord, PageIndex: 1
                }} sheetName={i18n.t('agentWalletRecord')} columns={ExcelColumns.AgentWalletRecord} />
              </Col>
            }
          </Row>
        </Form>
        <Table
          loading={isValidating}
          size="middle"
          className="mt-1"
          scroll={{ x: 1500 }}
          dataSource={recordList?.Data.map((item: any) => ({ key: item.Id, ...item }))}
          columns={columns}
          pagination={false}
        />
        <LayoutPagination total={recordList ? recordList.TotalRecord : 0} setPage={setPage} page={page} />
      </Content>
    </div>
  );
};

export default PageMain;