import {
  axiosGetAllData,
  axiosGetAuth,
  axiosPostAuth,
  baseURL,
  useFetchDataCommon
} from "services";


export const ChatSetting = () => useFetchDataCommon(axiosGetAuth, `${baseURL}/chatsetting/setting`);
export const UpdateChatSetting = (data: any) => axiosPostAuth(`${baseURL}/chatsetting/setting/update`, data);
export const ChatAllQuickReply = () => useFetchDataCommon(axiosGetAuth, `${baseURL}/chatsetting/cannedresponse/all/list`);

export const ChatQuickReplyCategory = () => useFetchDataCommon(axiosGetAuth, `${baseURL}/chatsetting/cannedresponse/category/list`);
export const ChatQuickReplyList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/chatsetting/cannedresponse/list`, params);
export const CreateQuickReplyContent = (data: any) => axiosPostAuth(`${baseURL}/chatsetting/cannedresponse/create`, data);
export const CreateQuickReplyCategory = (data: any) => axiosPostAuth(`${baseURL}/chatsetting/cannedresponse/category/create`, data);
export const UpdateChatQuickReply = (data: any) => axiosPostAuth(`${baseURL}/chatsetting/cannedresponse/update`, data);
export const DeleteChatQuickReply = (data: any) => axiosPostAuth(`${baseURL}/chatsetting/cannedresponse/delete`, data);

export const ChatAccountList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/chatsetting/account/list`, params);
export const UpdateChatAccount = (data: any) => axiosPostAuth(`${baseURL}/chatsetting/account/update`, data);

export const ChatServiceHistoryList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/chathistory/service/session/list`, params);
export const ChatPrivateHistoryList = (params: any, allow?: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/chathistory/private/session/message/list`, params, allow);

export const ChatHistory = (params: any, allow: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/chathistory/service/session/message/list`, params, allow);

export const ChatAllOnlineAccount = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/chatsetting/account/list`, params);
export const ChatPendingList = () => useFetchDataCommon(axiosGetAuth, `${baseURL}/chatroom/service/pending/list`);
export const ChatOpenList = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/chatroom/service/open/list`, params);
export const ChatCloseList = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/chatroom/service/close/list`, params);
export const ChatUserInfo = () => useFetchDataCommon(axiosGetAuth, `${baseURL}/chatsetting/account/info`);
export const UpdateChatUserStatus = (data: any) => axiosPostAuth(`${baseURL}/chatsetting/account/online/update`, data);

export const ChatRoomInfo = (sessionId: string) => useFetchDataCommon(axiosGetAuth, `${baseURL}/chatroom/service/session/info`, sessionId);

export const ChatRoomCustomerInfo = (params: any, allow: boolean) => useFetchDataCommon(axiosGetAuth, `${baseURL}/chatroom/service/session/customer/info`, params, allow);

export const ChatServiceAnswer = (params: any) => axiosPostAuth(`${baseURL}/chatroom/service/session/answer`, params);
export const ChatUpdateRead = (params: any) => axiosPostAuth(`${baseURL}/chatroom/service/message/count/update`, params);
export const ChatSend = (params: any) => axiosPostAuth(`${baseURL}/chatroom/service/message/send`, params);
export const ChatEnd = (params: any) => axiosPostAuth(`${baseURL}/chatroom/service/session/end`, params);
export const ChatTransfer = (params: any) => axiosPostAuth(`${baseURL}/chatroom/service/session/changeanswer`, params);
