import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Spin, Table, Tooltip } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { LayoutDateSelect, LayoutNav, LayoutPagination } from 'components/layout.component';
import { GameCategorySelect } from 'components/member.component';
import { PopupBetInfo, ViewBetList } from 'components/report.component';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TYPE } from 'enum/date';
import { REPORT_STATE } from 'enum/state';
import i18n from 'i18n';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Provider } from 'services/provider.service';
import { FetchBetList } from 'services/report.service';
import { toFormatNumber as $f, enumToOptions, numColor01 } from 'utils/common';

// 投注報表

const PageMain: React.FC = () => {

  const { account } = useParams();
  
  const [form] = Form.useForm();
  const [date, setDate] = useState<any[]>();
  const [reset, setReset] = useState<boolean>(false);
  const [isOpenInfoModal, setIsOpenInfoModal] = useState(false);
  const [betId, setBetId] = useState(0);

  const [page, setPage] = useState<number[]>([1, 10]);
  const [betSearchParam, setBetSearchParam] = useState<any>({
    MemberAccount: account || '',
    EndTimeStart: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    EndTimeEnd: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss')
  });
  const { data, mutate, isValidating } = FetchBetList({
    ...betSearchParam,
    PageSize: page[1],
    PageIndex: page[0],
  }, true);

  const search = (formData: any) => {
    setPage([1, page[1]]);

    const param: BetSearchParam = {
      RoundId: formData.RoundId,
      MemberAccount: formData.MemberAccount,
      AgentAccount: formData.AgentAccount,
    };

    if (formData.ProviderCode !== 0) {
      param.ProviderCode = formData.ProviderCode;
    }

    if (formData.CategoryCode !== 0) {
      param.CategoryCode = formData.CategoryCode;
    }

    if (formData.Status !== 0) {
      param.Status = formData.Status;
    }

    switch (formData.TimeMode) {
      case 0:
        param.EndTimeStart = date?.[0] || dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss');
        param.EndTimeEnd = date?.[1] || dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss');
        break;
      case 1:
        param.BetTimeStart = date?.[0] || dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss');
        param.BetTimeEnd = date?.[1] || dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss');
        break;
    }
    setBetSearchParam(param);
    mutate();
  };

  const onClear = () => {
    form.resetFields();
    setPage([1, 10]);
    setReset(!reset);
  }

  const handleBetInfo = (betId: number) => {
    setIsOpenInfoModal(true);
    setBetId(betId);
  }

  const disabledDate = (current: any) => {
    if (!date || !date[0]) return false;
    else return date[0] && current.diff(date[0], 'days') >= 7;
  };

  // 遊戲商
  const { data: provider } = Provider();

  return (
    <div id="container">
      <LayoutNav />
      <Content className="p-2">
        <Form form={form} onFinish={search} initialValues={{ 
          TimeMode: 0,
          MemberAccount: account || '',
        }}>
          <Row align="middle" gutter={[12, 12]}>
            <Col className='w-12'>
              <Form.Item name="RoundId">
                <Input placeholder={`${i18n.t('roundNumber')}`} />
              </Form.Item>
            </Col>
            <Col className='w-12'>
              <Form.Item name="MemberAccount">
                <Input placeholder={`${i18n.t('memberAccount')}`} />
              </Form.Item>
            </Col>
            <Col className='w-12'>
              <Form.Item name="AgentAccount">
                <Input placeholder={`${i18n.t('agentAccount')}`} />
              </Form.Item>
            </Col>
            <Col className='w-12'>
              <Form.Item name="ProviderCode">
                <Select
                  placeholder={i18n.t('gameProvider')}
                  options={
                    provider &&
                    [
                      { value: '', label: i18n.t('all') },
                      ...provider.map((item: ProviderType) => ({
                        value: item.ProviderCode,
                        label: item.ProviderCode
                      }))
                    ]
                  }
                />
              </Form.Item>
            </Col>
            <Col className='w-12'>
              <Form.Item name="CategoryCode">
                <GameCategorySelect name="CategoryCode" form={form} />
              </Form.Item>
            </Col>
          </Row>
          <Row className="mt-1" align="middle" gutter={[12, 12]}>
            <Col className='w-12'>
              <Form.Item name="Status">
                <Select
                  style={{ width: '100%' }}
                  placeholder={`${i18n.t('status')}`}
                  options={[
                    { value: '', label: i18n.t('all') },
                    ...enumToOptions(REPORT_STATE)
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className='w-12'>
              <Form.Item name="TimeMode">
                <Select
                  style={{ width: '100%' }}
                  options={[
                    { value: 0, label: i18n.t('settlementTime') },
                    { value: 1, label: i18n.t('betTime') },
                  ]}
                />
              </Form.Item>
            </Col>
            <LayoutDateSelect 
              width={372} dateFormatDefault={DATE_FORMAT.日期時間} hiddenDateType={[DATE_TYPE.本月, DATE_TYPE.上月]}
              disabledDate={disabledDate} onChange={setDate} reset={reset} 
            />
            <Col>
              <Tooltip placement="top" className="size-12" title={i18n.t('singleQueryInterval7Days')}>
                <InfoCircleFilled />
              </Tooltip>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
        </Form>
        <Row className="mt-1 mb-1" gutter={[10, 10]}>
          <Col span={24}>
            <Table
              loading={isValidating}
              size="middle"
              dataSource={
                data ?
                  [
                    {
                      key: 1,
                      TotalRecord: data?.TotalRecord,
                      TotalBetAmount: data?.TotalBetAmount,
                      TotalValidBetAmount: data?.TotalValidBetAmount,
                      TotalWinLossAmount: data?.TotalWinLossAmount,
                    }
                  ] : []
              }
              columns={[
                {
                  dataIndex: 'TotalRecord',
                  title: i18n.t('betCount'),
                  className: 'size-12',
                  align: 'right',
                  width: '25%',
                  render: (val) => $f(val, 0)
                },
                {
                  dataIndex: 'TotalBetAmount',
                  title: i18n.t('totalBet'),
                  className: 'size-12',
                  align: 'right',
                  width: '25%',
                  render: (val) => $f(val)
                },
                {
                  dataIndex: 'TotalValidBetAmount',
                  title: i18n.t('validBet'),
                  className: 'size-12',
                  align: 'right',
                  width: '25%',
                  render: (val) => $f(val)
                },
                {
                  dataIndex: 'TotalWinLossAmount',
                  title: i18n.t('memberWinLoss'),
                  className: 'size-12',
                  align: 'right',
                  width: '25%',
                  render: (val) => <span className={numColor01(val)}>{$f(val)}</span>
                },
              ]}
              pagination={false}
            />
          </Col>
        </Row>
        <Spin spinning={isValidating}>
          <ViewBetList data={data} handleBetInfo={handleBetInfo} />
          <LayoutPagination total={data?.TotalRecord} setPage={setPage} page={page} />
        </Spin>
        <PopupBetInfo isOpen={isOpenInfoModal} close={() => setIsOpenInfoModal(false)} betId={betId} />
      </Content>
    </div>
  );
};

export default PageMain;