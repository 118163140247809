import { Button, Col, Row, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { PopupDeleteMaintenance, PopupMaintenance } from 'components/advanced.component';
import { LayoutNav, LayoutPagination } from 'components/layout.component';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { GetProvidermaintaintenanceList } from 'services/providermaintaintenance.service';

// 系統維護

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();

  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [data, setData] = useState<{
    key: number;
    sort: number;
    provider: string;
    date: {
      start: string;
      end: string;
    }
    GameProviderId: number;
  } | null>(null);
  const [id, setId] = useState<number | null>(null);
  const [page, setPage] = useState<number[]>([1, 10]);
  const { data: ProviderList, isValidating, mutate } = GetProvidermaintaintenanceList({
    PageIndex: page[0],
    PageSize: page[1]
  })

  const onAdd = (data: any) => {
    setIsOpenAdd(true);
    setData(data);
  }

  const onDelete = (id: number) => {
    setIsOpenDelete(true);
    setId(id);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Row gutter={[0, 12]}>
          {$p.includes('01102') && <Col span={24}>
            <Row align="middle" gutter={10}>
              <Col>
                <Button type="primary" onClick={() => onAdd(null)}>{i18n.t('add')}</Button>
              </Col>
            </Row>
          </Col>}
          <Col span={24}>
            <Table
              loading={isValidating}
              size="middle"
              dataSource={
                ProviderList ?
                  ProviderList.Data.map((item: Providermaintaintenance) => (
                    {
                      key: item.Id,
                      sort: item.Position,
                      provider: item.ProviderName,
                      date: {
                        start: item.StartDate,
                        end: item.EndDate
                      },
                      GameProviderId: item.GameProviderId
                    }
                  )) : []
              }
              columns={[
                {
                  title: i18n.t('sort'),
                  dataIndex: 'sort',
                  align: 'center',
                  width: 80
                },
                {
                  title: i18n.t('gameProvider'),
                  dataIndex: 'provider',
                },
                {
                  title: `${i18n.t('startTime')} - ${i18n.t('endTime')}`,
                  dataIndex: 'date',
                  defaultSortOrder: "ascend",
                  // sorter: (a, b) => {
                  //   const dateA = dayjs(a.date.start);
                  //   const dateB = dayjs(b.date.start);
                  //   return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
                  // },
                  render: (_, { date }) => <>{date.start} - {date.end}</>
                },
                {
                  title: i18n.t('operation'),
                  width: 100,
                  render: (_, record) => (
                    <>
                      {$p.includes('01103') && <Button type="link" onClick={() => onAdd(record)}>
                        {i18n.t('edit')}
                      </Button>}
                      {$p.includes('01104') && <Button type="link" onClick={() => onDelete(record.key)}>
                        {i18n.t('delete')}
                      </Button>}
                    </>
                  )
                },
              ]}
              pagination={false}
            />
            <LayoutPagination total={ProviderList ? ProviderList.TotalRecord : 0} setPage={setPage} page={page} />
          </Col>
        </Row>

        <PopupMaintenance isOpen={isOpenAdd} close={() => setIsOpenAdd(false)} data={data} mutate={mutate} />
        <PopupDeleteMaintenance isOpen={isOpenDelete} close={() => setIsOpenDelete(false)} id={id} mutate={mutate} />
      </Content>
    </div>
  );
};

export default PageMain;