import PageReport from "pages/admin/report";
import PageReportAgent from "pages/admin/report/agent";
import PageReportBusiness from "pages/admin/report/business";
import PageReportGame from "pages/admin/report/game";
import PageReportGGR from "pages/admin/report/ggr";
import PageReportLadder from "pages/admin/report/ladder";
import PageReportPromotion from "pages/admin/report/promotion";
import PageHome from 'pages/home';

export const reportModule = [
  { path: '/report/:account?', Component: PageReport },
  { path: '/report/summary', Component: PageHome },
  { path: '/report/business', Component: PageReportBusiness },
  { path: '/report/game', Component: PageReportGame },
  { path: '/report/agent', Component: PageReportAgent },
  { path: '/report/promotion', Component: PageReportPromotion },
  { path: '/report/ladder', Component: PageReportLadder },
  { path: '/report/ggr', Component: PageReportGGR },
]