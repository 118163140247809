import { message } from 'antd';
import axios from 'axios';
import { RESPONSE_CODE } from 'constants/response';
import { RESPONSE_TYPE } from 'enum/state';
import i18n from 'i18n';
import QueryString from 'qs';
import useSWR from 'swr';
import { axiosAuth, axiosAuthWithoutToast, axiosToken, axiosWithAuth } from 'utils/axios';
import * as common from 'utils/common';

export const baseURL = "/api";

export const axiosGet = (url: string) => axios.get(url).then(res => res.data);
export const axiosGetAuth = (url: string) => axiosAuth.get(url).then(res => res.data.Data).catch(() => { });
export const axiosGetAllData = (url: string) => axiosAuth.get(url).then(res => res.data).catch(() => { })
export const axiosGetAuthWithoutToast = (url: string) => axiosAuthWithoutToast.get(url).then(res => res.data).catch(() => { });
export const axiosGetWithAuth = (url: string) => axiosWithAuth.get(url).then(res => res.data);
export const axiosPost = (url: string, data: any) => axios.post(url, data).then(res => res.data);
export const axiosPostAuth = (url: string, data: any) => axiosAuth.post(url, data).then(res => res.data);
export const axiosPostToken = (url: string, data: any) => axiosToken.post(url, data).then(res => res.data).catch(() => { });
export const axiosPutAuth = (url: string, data?: any) => axiosAuth.put(url, data).then(res => res.data);
export const axiosDeleteAuth = (url: string) => axiosAuth.delete(url).then(res => res.data);

// 取得單筆資料或列表
export const useFetchData = (fetcher: any, url: string, params: any = {}, rows: number = 0, allow: boolean = true, needFresh: boolean = false) => {

  params = common.removeEmpty(params);
  if (rows) params = { ...params, PageSize: rows, PageIndex: 1 };

  const query = QueryString.stringify(
    params,
    { addQueryPrefix: true, }
  );

  return useSWR(allow ? (url + query) : null, fetcher, { revalidateOnFocus: false });
}

// 通用型
export const useFetchDataCommon = (fetcher: any, url: string, params: any = {}, allow: boolean = true) => {

  // params = common.removeEmpty(params);

  const query = QueryString.stringify(
    params,
    { addQueryPrefix: true, }
  );

  return useSWR(allow ? url + query : null, fetcher, { revalidateOnFocus: false });
}

export const $post = async (
  { url = '', send = {}, success = () => {}, fail =() => {} }, 
  setLoading = (b : boolean) => {}
) => {
  setLoading(true);

  const response = await axiosPostAuth(`${baseURL}${url}`, send)
  if (response.State === RESPONSE_TYPE.成功) {
    success();
  } else {
    const msg = RESPONSE_CODE[response.Message as keyof typeof RESPONSE_CODE];
    message.error(msg || i18n.t('processingFailed'));
    fail();
  }

  setLoading(false);
}
