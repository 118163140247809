
// 站台資料

import i18n from "i18n";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { SiteSettingInfo } from "services/account.service";

export interface SettingInfo {
  Id: number;
  SiteName:	string;	   // 站台名稱
  Currency: string;    // 幣別
  Language: string;    // 語系
  Type:	number;        // 0: 現金版 | 1: 信用版
  Status:	number;      // 狀態
  Logo1: string;       // 70px X 70px圖片path
  Logo2: string;       //	324px X 70px圖片path
  WhiteList: string;   // 系統白名單
  CreateDate: string;  // 建立時間
  PhonePrefix: string; // 預設國碼
}

const useSite = () => {

  const { data, mutate, isValidating } = SiteSettingInfo();
  const _data: SettingInfo = data

  useEffect(() => {
    if (data) {
      Cookies.set('platform-name', data.SiteName);
      Cookies.set('logo01', data.Logo1);
      Cookies.set('logo02', data.Logo2);
    }
    document.title = `${Cookies.get('platform-name')} - ${i18n.t('webmasterDashboard')}` || i18n.t('antWebmasterDashboard');
  }, [data])
  
  return {
    data: _data,
    mutate,
    isValidating,
    isCashVersion: data?.Type === 0
  }
};

export default useSite;
