import { Image, Tag } from 'antd';
import icon02 from 'assets/image/account.svg';
import bell01 from 'assets/image/bell-01.svg';
import bell02 from 'assets/image/bell-02.svg';
import icon15 from 'assets/image/deposit.png';
import icon05 from 'assets/image/deposit_amount.svg';
import errorIcon from 'assets/image/erroricon.png';
import icon07 from 'assets/image/game_profit.svg';
import icon10 from 'assets/image/icon-10.svg';
import icon11 from 'assets/image/icon-11.svg';
import icon12 from 'assets/image/icon-12.png';
import icon01 from 'assets/image/language.svg';
import logo01 from 'assets/image/logo-01.png';
import logo02 from 'assets/image/logo-02.png';
import logout from 'assets/image/logout.svg';
import icon09 from 'assets/image/loss.svg';
import mailbox from 'assets/image/mailbox.svg';
import icon13 from 'assets/image/news.svg';
import notify from 'assets/image/notify.svg';
import offline from 'assets/image/offline.svg';
import online from 'assets/image/online.svg';
import icon03 from 'assets/image/password.svg';
import icon08 from 'assets/image/profit.svg';
import icon14 from 'assets/image/promotion.png';
import systemGameLogo from 'assets/image/system defalt_Game icon.png';
import icon06 from 'assets/image/total_deposit.png';
import icon17 from 'assets/image/total_fee.png';
import icon16 from 'assets/image/total_withdraw.png';
import uploadPhotoMessage from 'assets/image/uploadPhotoMessage.svg';
import icon04 from 'assets/image/user_quantity.svg';
import icon18 from 'assets/image/withdraw.png';
import { AGENT_STATE, AGENT_WITHDRAW_STATE, MEMBER_FREEZE, MEMBER_STATE } from 'enum/state';
import i18n from 'i18n';
// demo
import avatar04 from 'assets/image/avatar-default.png';
import demo01 from 'assets/image/demo-01.png';
import avatar01 from 'assets/image/demo-avatar-01.png';
import avatar02 from 'assets/image/demo-avatar-02.svg';
import avatar03 from 'assets/image/demo-avatar-03.png';
import demoCarousel from 'assets/image/demo-carousel.svg';
import demoGameLogo01 from 'assets/image/demo-game-logo.png';
import demoGraph01 from 'assets/image/demo-graph-01.png';
import demoGraph02 from 'assets/image/demo-graph-02.png';
import demoGraph03 from 'assets/image/demo-graph-03.png';
import demoGraph04 from 'assets/image/demo-graph-04.png';
import demoGraph05 from 'assets/image/demo-graph-05.png';
// NOTE: 圖片直接import就好 不用放這邊

const useIcon = () => {

  const memberStateIcon = (state: number) => {
    switch (state) {
      default:
      case MEMBER_STATE.pendingApproval:
        return (
          <Tag
            className='w-3 border-rd-02 border-none center'
            style={{ background: '#DCDEE2', color: '#535A6C' }}
          >
            {i18n.t('pendingApproval')}
          </Tag>
        )
      case MEMBER_STATE.pass:
        return (
          <Tag
            className='w-3 border-rd-02 border-none center'
            style={{ background: '#4AA4EA', color: '#fff' }}>
            {i18n.t('pass')}
          </Tag>
        )
      case MEMBER_FREEZE.normal:
        return (
          <Tag
            className='w-3 border-rd-02 border-none center'
            style={{ background: '#4AA4EA', color: '#fff' }}>
            {i18n.t('normal')}
          </Tag>
        )
      case MEMBER_FREEZE.frozen:
        return (
          <Tag
            className='w-3 border-rd-02 border-none center'
            style={{ background: '#E7716D', color: '#fff' }}>
            {i18n.t('frozen')}
          </Tag>
        )
    }
  }

  const agentStateIcon = (state: number) => {
    switch (state) {
      default:
      case AGENT_STATE.pendingApproval:
        return (
          <Tag
            className='w-3 border-rd-02 border-none center'
            style={{ background: '#DCDEE2', color: '#535A6C' }}
          >
            {i18n.t('pendingApproval')}
          </Tag>
        )
      case AGENT_STATE.normal:
        return (
          <Tag
            className='w-3 border-rd-02 border-none center'
            style={{ background: '#4AA4EA', color: '#fff' }}>
            {i18n.t('normal')}
          </Tag>
        )
      case AGENT_STATE.frozen:
        return (
          <Tag
            className='w-3 border-rd-02 border-none center'
            style={{ background: '#E7716D', color: '#fff' }}>
            {i18n.t('frozen')}
          </Tag>
        )
    }
  }

  const AgentWithdrawState = (type: number, date?: string, admin?: string) => {
    switch (type) {
      // color-withdraw-padding-review
      case AGENT_WITHDRAW_STATE.待派發: default:
        return <Tag className='color-withdraw-padding' style={{ cursor: date ? 'pointer' : '' }}>
          {`${date} ${AGENT_WITHDRAW_STATE[type]}`}
        </Tag>
      case AGENT_WITHDRAW_STATE.派發中:
        return <Tag className='color-withdraw-reviewing'>
          {`${date} 由 ${admin} ${AGENT_WITHDRAW_STATE[type]}`}
        </Tag>
      case AGENT_WITHDRAW_STATE.批次通過:
        return <Tag className='color-withdraw-pass' style={{ cursor: 'pointer' }}>
          {AGENT_WITHDRAW_STATE[type]}
        </Tag>
    }
  }

  return {
    //demo
    avatar01,
    avatar02,
    avatar03,
    avatar04,
    demoGraph01,
    demoGraph02,
    demoGraph03,
    demoGraph04,
    demoGraph05,
    demoCarousel,
    demoGameLogo01,
    demo01,
    systemGameLogo,
    //
    bell01,
    bell02,
    icon01,
    icon02,
    icon03,
    icon04,
    icon05,
    icon06,
    icon07,
    icon08,
    icon09,
    icon10,
    icon11,
    icon12,
    icon13,
    icon14,
    icon15,
    icon16,
    icon17,
    icon18,
    logo01,
    logo02,
    mailbox,
    notify,
    logout,
    errorIcon,
    uploadPhotoMessage,
    MemberStateTag: (state: number) => memberStateIcon(state),
    AgentStateTag: (state: number) => agentStateIcon(state),
    WithdrawStateTag: (type: number, date?: string, admin?: string) => AgentWithdrawState(type, date, admin),
    StatusTag: (isOnline: boolean) => <Image className='center' src={isOnline ? online : offline} preview={false} />,
  };
};

export default useIcon;
