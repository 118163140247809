import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Descriptions, Modal, Row, Table, Tooltip } from 'antd';
import { REPORT_STATE } from 'enum/state';
import i18n from 'i18n';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetAgentOne } from 'services/agent.service';
import { FetchBetDetail, GetReportBetAgList, GetReportBonusstatAgList } from 'services/report.service';
import * as common from 'utils/common';

interface ReportGGRType {
  ReportDate: string;
  TotalBetMemberCount: number;
  TotalBetCount: number;
  TotalBetAmount: number;
  TotalValidBetAmount: number;
  TotalSettleAmount: number;
  TotalWinLossAmount: number;
}

// 輸贏報表 月展開
export const ViewReportGGR = ({ data, date }: any) => {
  const navigate = useNavigate();

  // 展開按鈕設定
  const expandableSa = {
    rowExpandable: (record: any) => record.rowExpandable,
    expandedRowRender: () => <></>,
    // 展開子代
    onExpand: (open: boolean, record: any) => {
      if (open) expand(record)
      else backto(record)
    },
  };
  
  // 點開
  const expand = (record: any) => {
    setReportDate('');
    setTimeout(() => {
      setReportDate(record.ReportDate);
    });
  }

  // 收合
  const backto = (record: any) => {
    setReportDate('');
  }

  const [list, setList] = useState<any>([]);
  const [reportDate, setReportDate] = useState<any>('');
  useEffect(() => {
    if (data && data.Data) {
      setReportDate('')
      
      const obj: any = {}
      data.Data.forEach((item: any) => {
        const d = item.ReportDate.split('-');
        const name = `${d[0]}-${d[1]}`
        if (obj[name]) {
          obj[name].TotalBetAmount += item.TotalBetAmount;
          obj[name].TotalBetCount += item.TotalBetCount;
          obj[name].TotalBetMemberCount += item.TotalBetMemberCount;
          obj[name].TotalSettleAmount += item.TotalSettleAmount;
          obj[name].TotalValidBetAmount += item.TotalValidBetAmount;
          obj[name].TotalWinLossAmount += item.TotalWinLossAmount;
        } else {
          obj[name] = JSON.parse(JSON.stringify(item))
          obj[name].ReportDate = name
          obj[name].rowExpandable = true
        }
      });
      setList(Object.values(obj))
    }
  }, [data]);

  const columns: any = [
    {
      key: 1,
      dataIndex: 'ReportDate',
      title: i18n.t('date'),
      className: 'size-12',
      width: 200,
    },
    {
      key: 2,
      dataIndex: 'TotalBetCount',
      title: i18n.t('betCounts'),
      className: 'size-12',
      width: 200,
      align: 'right'
    },
    {
      key: 3,
      dataIndex: 'TotalBetAmount',
      title: i18n.t('betAmount'),
      className: 'size-12',
      width: 200,
      align: 'right',
      render: (val: any) => common.toFormatNumber(val)
    },
    {
      key: 4,
      dataIndex: 'TotalSettleAmount',
      title: i18n.t('payout'),
      className: 'size-12',
      width: 200,
      align: 'right',
      render: (val: any) => common.toFormatNumber(val)
    },
    {
      key: 5,
      dataIndex: 'TotalWinLossAmount',
      title: i18n.t('totalWinLossAmounts'),
      className: 'size-12',
      width: 200,
      align: 'right',
      render: (val: any) => <div className={common.numColor01(val)}>{common.toFormatNumber(val)}</div>
    },
    // NOTE: 跳轉列優化
    // {
    //   key: 6,
    //   title: i18n.t('operation'),
    //   className: 'size-12',
    //   width: 120,
    //   align: 'center',
    //   render: (_: any, record: any) => (DATE_TYPE[date[2]] && record.rowExpandable) &&
    //     <Button className="size-12" type="link" onClick={() => navigate(`/${date[2]}`)}>{i18n.t('detail')}</Button>
    // }
  ]

  return (
    <>
      <Table
        size="middle"
        expandable={{ ...expandableSa, expandedRowKeys: [reportDate] }}
        dataSource={
          list?.reverse().map((item: ReportGGRType, i: number) => ({ key: item.ReportDate, ...item }))
            .filter((item: ReportGGRType) => item.ReportDate.includes(reportDate) || !reportDate)
        }
        columns={columns}
        pagination={false}
      />
      {!!reportDate && 
      <Table
        size="middle"
        expandable={{ ...expandableSa, expandedRowKeys: [reportDate] }}
        dataSource={
          data?.Data?.reverse().map((item: ReportGGRType, i: number) => ({ key: item.ReportDate, ...item }))
            .filter((item: ReportGGRType) => item.ReportDate.includes(reportDate))
        }
        columns={columns}
        pagination={false}
      />}
    </>
  )
}

export const ViewBetList = ({ data, handleBetInfo }: {
  data: {
    State: string;
    Data: Bet[];
    TotalRecord: number;
  }
  handleBetInfo: (betId: number) => void;
}) => {

  return (
    <Table
      scroll={{ x: 1500 }}
      dataSource={data?.Data?.map((item: Bet) => ({ key: item.Id, ...item }))}
      columns={[
        {
          dataIndex: 'RoundId',
          title: i18n.t('roundNumber'),
          className: 'size-12',
          fixed: 'left',
          width: 250
        },
        {
          dataIndex: 'MemberAccount',
          title: i18n.t('memberAccount'),
          className: 'size-12',
          width: 120,
        },
        {
          dataIndex: 'AGAccount',
          title: i18n.t('agent'),
          className: 'size-12',
          width: 120,
        },
        {
          dataIndex: 'ProviderCode',
          title: i18n.t('gameProviderCategory'),
          className: 'size-12',
          width: 130,
          render: (val, { CategoryCode }) => <span>{val}／{i18n.t(CategoryCode)}</span>
        },
        {
          dataIndex: 'GameName',
          title: i18n.t('gameName'),
          className: 'size-12',
          width: 150,
        },
        {
          dataIndex: 'BetAmount',
          title: i18n.t('betAmount'),
          className: 'size-12',
          align: 'right',
          width: 120,
          render: (val) => common.toFormatNumber(val)
        },
        {
          dataIndex: 'ValidBetAmount',
          title: i18n.t('validBet'),
          className: 'size-12',
          align: 'right',
          width: 120,
          render: (val) => common.toFormatNumber(val)
        },
        {
          dataIndex: 'SettleAmount',
          title: () => (
            <>
              <Tooltip placement="top" className="size-12" title={i18n.t('betAmountAndMemberWinLoss')}>
                <InfoCircleFilled />
              </Tooltip>
              <span style={{ marginLeft: 5 }}>{i18n.t('payout')}</span>
            </>
          ),
          className: 'size-12',
          align: 'right',
          width: 120,
          render: (val) => common.toFormatNumber(val)
        },
        {
          dataIndex: 'WinLossAmount',
          title: i18n.t('memberWinLoss'),
          className: 'size-12',
          align: 'right',
          width: 120,
          render: (val) => <span className={common.numColor01(val)}>{common.toFormatNumber(val)}</span>
        },
        {
          dataIndex: 'Status',
          title: i18n.t('status'),
          className: 'size-12',
          width: 100,
          render: (val) => REPORT_STATE[val]
        },
        {
          dataIndex: 'BetTime',
          title: i18n.t('betTime'),
          className: 'size-12',
          width: 160,
        },
        {
          dataIndex: 'EndTime',
          title: i18n.t('settlementTime'),
          className: 'size-12',
          width: 160,
          render: (val) => val || '-'
        },
        {
          dataIndex: 'EndTime',
          title: i18n.t('cancellationTime'),
          className: 'size-12',
          width: 160,
          render: (val, { Status }) => (Status === REPORT_STATE.未結算撤單 || Status === REPORT_STATE.已結算撤單) ? val : '-'
        },
        {
          title: i18n.t('operation'),
          className: 'size-12',
          fixed: 'right',
          width: 80,
          render: (_, record) => (
            <Button size="small" type="link" onClick={() => handleBetInfo(record.key)}>{i18n.t('detail')}</Button>
          )
        },
      ]}
      pagination={false}
    />
  )
}
export const PopupBetInfo = ({ isOpen, close, betId }: any) => {
  const { data: bet } = FetchBetDetail(betId);

  return (
    <Modal
      title={i18n.t('detail')}
      open={isOpen}
      onCancel={close}
      centered
      width={850}
      footer={
        <Row justify="center">
          <Button key="cancel" type="primary" onClick={close}>{i18n.t('close')}</Button>
        </Row>
      }
    >
      <Descriptions size="small" column={2} bordered
        labelStyle={{ width: 110, fontSize: 12, fontWeight: 500 }} contentStyle={{ fontSize: 12 }}>
        <Descriptions.Item label={i18n.t('roundNumber')}>
          {bet?.RoundId}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('betAmount')}>
          {common.toFormatNumber(bet?.BetAmount)}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('memberAccount')}>
          {bet?.MemberAccount}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('validBet')}>
          {common.toFormatNumber(bet?.ValidBetAmount)}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('agent')}>
          {bet?.AGAccount}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('payout')}>
          {bet?.SettleAmount ? common.toFormatNumber(bet?.SettleAmount) : '-'}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('gameProvider')}>
          {bet?.ProviderCode}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('memberWinLoss')}>
          <div className={common.numColor01(bet?.WinLossAmount)}>
            {bet?.WinLossAmount ? common.toFormatNumber(bet?.WinLossAmount) : '-'}
          </div>
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('gameCategory')}>
          {i18n.t(bet?.CategoryCode)}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('status')}>
          {REPORT_STATE[bet?.Status]}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('gameName')}>
          {bet?.GameName}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('settlementTime')}>
          {bet?.EndTime || '-'}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('betTime')}>
          {bet?.BetTime}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('betType')}>
          -
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('details')} style={{ maxWidth: 150 }}>
          {
            bet?.Detail &&
            Object.entries(JSON.parse(bet?.Detail)).map((key, i) => (
              <div key={i}>{`${key.toString().replace(',', '：')}`}</div>
            ))
          }
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  )
}



// 代理統計分析 代理展開
export const ReportAgentList = ({ agentSaData, columns, params, setParams, isValidating }: any) => {

  useEffect(() => {
    if (agentSaData && agentSaData.Data) {
      setThreeAgent(agentSaData.Data)
    }
  }, [agentSaData]);
  
  // 展開按鈕設定
  const expandableSa = {
    rowExpandable: (record: any) => !params.AgentAccount && (record.up_count > record.bet_count),
    expandedRowRender: () => <></>,
    // 展開子代
    onExpand: (open: boolean, record: any) => {
      if (open) expand(record)
      else backto(record)
    },
  };

  // 點開
  const expand = (record: any) => {
    setExpandAccount(record.Account);
    setThreeAgentKey([record.id]);
  }

  // 收合
  const backto = (record: any) => {
    if (record.Level === 0) {
      setThreeAgent(agentSaData.Data)
      setThreeAgentKey([])
      setExpandAccount('');
    } else {
      setParentId(record.AGId)
    }    
  }
  const [parentId, setParentId] = useState<any>(0);
  const { data: parent } = GetAgentOne({ Id: parentId }, !!parentId);
  useEffect(() => {
    if (parent) {
      setExpandAccount(parent.Account);
      setThreeAgentKey([parent.Id])

      setParentId(0)
    }
  }, [parent]);

  const [threeAgentKey, setThreeAgentKey] = useState<any>([]);
  const [threeAgent, setThreeAgent] = useState<any>([]);

  const [expandAccount, setExpandAccount] = useState('');
  const { data: threeList, isValidating: threeValidating } = GetReportBetAgList({
    ...params,
    AgentAccount: expandAccount
  }, !!expandAccount);
  useEffect(() => {
    if (threeList && threeList.Data) {
      setThreeAgent(threeList.Data);
    }
  }, [threeList]);

  return (
    <Table
      size='middle'
      loading={threeValidating || isValidating}
      columns={columns}
      dataSource={threeAgent?.map((item: any) => ({ ...item, key: item.id }))}
      expandable={{ ...expandableSa, expandedRowKeys: threeAgentKey }}
      pagination={{
        showSizeChanger: true,
        showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
        total: agentSaData?.TotalRecord,
        current: params.PageIndex,
      }}
      onChange={p => setParams({ ...params, PageIndex: p.current, PageSize: p.pageSize })}
    />
  )
}

// 代理優惠統計 代理展開
export const ReportPromotionList = ({ agentSaData, columns, params, setParams, isValidating }: any) => {

  useEffect(() => {
    if (agentSaData && agentSaData.Data) {
      setThreeAgent(agentSaData.Data)
    }
  }, [agentSaData]);
  
  // 展開按鈕設定
  const expandableSa = {
    rowExpandable: (record: any) => !params.AgentAccount && (record.up_count > record.bonus_count),
    expandedRowRender: () => <></>,
    // 展開子代
    onExpand: (open: boolean, record: any) => {
      if (open) expand(record)
      else backto(record)
    },
  };

  // 點開
  const expand = (record: any) => {
    setExpandAccount(record.Account);
    setThreeAgentKey([record.id])
  }

  // 收合
  const backto = (record: any) => {
    if (record.Level === 0) {
      setThreeAgent(agentSaData.Data)
      setThreeAgentKey([])
      setExpandAccount('');
    } else {
      setParentId(record.AGId)
    }    
  }
  const [parentId, setParentId] = useState<any>(0);
  const { data: parent } = GetAgentOne({ Id: parentId }, !!parentId);
  useEffect(() => {
    if (parent) {
      setExpandAccount(parent.Account);
      setThreeAgentKey([parent.Id])

      setParentId(0)
    }
  }, [parent]);

  const [threeAgentKey, setThreeAgentKey] = useState<any>([]);
  const [threeAgent, setThreeAgent] = useState<any>([]);

  const [expandAccount, setExpandAccount] = useState('');
  const { data: threeList, isValidating: threeValidating } = GetReportBonusstatAgList({
    ...params,
    AgentAccount: expandAccount
  });
  useEffect(() => {
    if (threeList && threeList.Data) {
      setThreeAgent(threeList.Data);
    }
  }, [threeList]);

  return (
    <Table
      size='middle'
      loading={threeValidating || isValidating}
      columns={columns}
      dataSource={threeAgent?.map((item: any) => ({ ...item, key: item.id }))}
      expandable={{ ...expandableSa, expandedRowKeys: threeAgentKey }}
      pagination={{
        showSizeChanger: true,
        showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
        total: agentSaData?.TotalRecord,
        current: params.PageIndex,
      }}
      onChange={p => setParams({ ...params, PageIndex: p.current, PageSize: p.pageSize })}
    />
  )
}