import {
  axiosGetAllData,
  axiosGetAuth,
  axiosPostAuth,
  baseURL,
  useFetchDataCommon
} from "services";

export const GetAdjustticketMemberList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/adjustticket/member/list`, params);
export const GetAdjustticketOne = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/adjustticket/one`, params);

export const CreateAdjust = (data: any) => axiosPostAuth(`${baseURL}/adjustticket/member/create`, data);
export const OrderPass = (params: any) => axiosPostAuth(`${baseURL}/adjustticket/member/pass`, params)
export const OrderReject = (params: any) => axiosPostAuth(`${baseURL}/adjustticket/member/reject`, params)

export const AdjustAgentList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/adjustticket/agent/list`, params);
export const CreateAgentAdjust = (data: any) => axiosPostAuth(`${baseURL}/adjustticket/agent/create`, data);
export const AgentOrderPass = (params: any) => axiosPostAuth(`${baseURL}/adjustticket/agent/pass`, params)
export const AgentOrderReject = (params: any) => axiosPostAuth(`${baseURL}/adjustticket/agent/reject`, params)
