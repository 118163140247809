import { Button, Col, Form, Input, Row, Switch, Table, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { PopupDeleteAnnouncement } from 'components/advanced.component';
import { LayoutNav, LayoutPagination } from 'components/layout.component';
import { RESPONSE_CODE_CREATE_ANNOUNCEMENT } from 'constants/response';
import { RESPONSE_TYPE } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetContentSystemList, PostContentsSystemUpdate } from 'services/contents.service';

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();

  const navigate = useNavigate();
  const [form] = useForm();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [id, setId] = useState<number | null>(null);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [params, setParams] = useState({
    Title: '',
  });
  const { data: SystemAnnouncement, isValidating, mutate } = GetContentSystemList({
    ...params,
    PageIndex: page[0],
    PageSize: page[1]
  })

  const search = (formData: any) => {
    setParams({
      ...params,
      Title: formData.title
    })
    mutate();
  }

  const handleUpdateStatus = async (record: any, state: boolean) => {
    const response = await PostContentsSystemUpdate({
      Id: record.key,
      Title: record.title,
      Content: record.internalContent,
      Position: record.sort,
      PublishDate: record.publicationTime,
      Status: state ? 1 : 0
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('updateSuccess'));
      mutate();
    } else {
      message.error(RESPONSE_CODE_CREATE_ANNOUNCEMENT[response.Message as keyof typeof RESPONSE_CODE_CREATE_ANNOUNCEMENT]);
    }
  }

  const onClear = () => {
    form.resetFields();
    setPage([1, 10]);
  }

  const handleDelete = (id: number) => {
    setIsOpenDelete(true);
    setId(id);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={search}>
          <Row gutter={[0, 12]}>
            <Col span={24}>
              <Row align="middle" gutter={10}>
                <Col>
                  <Form.Item name="title">
                    <Input className="w-12" placeholder={`${i18n.t('title')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit" loading={isValidating}>{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            {$p.includes('01002') && <Col span={24}>
              <Row align="middle" gutter={10}>
                <Col>
                  <Button type="primary" onClick={() => navigate('/advanced/announcement/system/add')}>
                    {i18n.t('add')}
                  </Button>
                </Col>
              </Row>
            </Col>}
            <Col span={24}>
              <Table
                loading={isValidating}
                size="middle"
                dataSource={
                  SystemAnnouncement ?
                    SystemAnnouncement.Data.map((item: Contents) => (
                      {
                        key: item.Id,
                        sort: item.Position,
                        title: item.Title,
                        internalContent: item.Content,
                        publicationTime: item.PublishDate,
                        status: item.Status,
                      }
                    )) : []
                }
                columns={[
                  {
                    title: i18n.t('sort'),
                    dataIndex: 'sort',
                    align: 'center',
                    width: 50
                  },
                  {
                    title: i18n.t('title'),
                    dataIndex: 'title',
                    width: 300
                  },
                  {
                    title: i18n.t('internalContent'),
                    dataIndex: 'internalContent',
                    width: 600,
                    ellipsis: true,
                    render: (_, { internalContent }) => (
                      <div style={{ maxHeight: 100 }} dangerouslySetInnerHTML={{ __html: internalContent }} />
                    )
                  },
                  {
                    title: i18n.t('publicationTime'),
                    dataIndex: 'publicationTime',
                    width: 150
                  },
                  {
                    title: i18n.t('status'),
                    width: 80,
                    render: (_, record) => (
                      <Switch 
                        loading={isValidating} 
                        checked={record.status} 
                        onClick={v => handleUpdateStatus(record, v)} 
                        disabled={!$p.includes('01003')}
                      />
                    )
                  },
                  {
                    title: i18n.t('operation'),
                    width: 150,
                    render: (_, record) => (
                      <>
                        {$p.includes('01003') && <Button className="size-12" type="link"
                          onClick={() => navigate(`/advanced/announcement/system/edit/${record.key}`)}>
                          {i18n.t('edit')}
                        </Button>}
                        {$p.includes('01004') && <Button className="size-12" type="link" onClick={() => handleDelete(record.key)}>
                          {i18n.t('delete')}
                        </Button>}
                      </>
                    )
                  },
                ]}
                pagination={false}
              />
              <LayoutPagination total={SystemAnnouncement ? SystemAnnouncement.TotalRecord : 0} setPage={setPage} page={page} />
            </Col>
          </Row>
        </Form>
      </Content>
      {/* Modal */}
      <PopupDeleteAnnouncement isOpen={isOpenDelete} close={() => setIsOpenDelete(false)} id={id} mutate={mutate} />
    </div>
  );
};

export default PageMain;