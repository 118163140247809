import {
  axiosGetAuth,
  baseURL,
  useFetchDataCommon
} from "services";

export const GetWithdrawmerchantList = (params?: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/withdrawmerchant/list`, params);
export const GetWithdrawmerchantOfflineList = (params?: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/withdrawmerchant/offline/list`, params);
export const DepositOptions = () => useFetchDataCommon(axiosGetAuth, `${baseURL}/withdrawmerchant/name/list`);
export const GetWithdrawmerchant = (params?: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/withdrawmerchant/brank/resolve/list`, params);
export const GetCryptoList = (params?: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/withdrawmerchant/crypto/resolve/list`, params);