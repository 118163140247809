import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { ViewMemberWithdrawList, ViewMemberWithdrawOverview } from 'components/finance.component';
import { Export, LayoutDateSelect, LayoutNav, LayoutPagination, LayoutUpdateControl } from 'components/layout.component';
import { ExcelColumns } from 'constants/excel';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TYPE } from 'enum/date';
import { SEARCH_DATE_TYPE, WITHDRAW_STATE } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { MemberWithdrawList } from 'services/withdrawticket.service';
import { enumToOptions } from 'utils/common';

// 財務：提款查詢

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();

  const [form] = Form.useForm();
  const [reset, setReset] = useState(false);
  const [date, setDate] = useState<string[]>();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [startDate, setStartDate] = useState('CreateStartDate');
  const [EndDate, setEndDate] = useState('CreateEndDate');

  const [params, setParams] = useState<any>({
    CreateStartDate: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    CreateEndDate: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  });
  const { data: withdrawList, isValidating, mutate } = MemberWithdrawList({
    ...params,
    PageIndex: page[0],
    PageSize: page[1],
  })

  const handleDateType = (type: number) => {
    switch (type) {
      case SEARCH_DATE_TYPE.建立時間:
        setStartDate('CreateStartDate');
        setEndDate('CreateEndDate')
        break;
      case SEARCH_DATE_TYPE.審核時間:
        setStartDate('AuditedStartDate');
        setEndDate('AuditedEndDate')
        break;
    }
  }

  const onFinish = (formData: any) => {
    if (date) {
      setParams({
        Code: formData.code,
        Account: formData.account,
        UpMemberAccount: formData.upMemberAccount,
        AGAccount: formData.agAccount,
        Status: formData.status,
        [startDate]: date[0],
        [EndDate]: date[1],
      })
    }
    mutate();
  };

  const onClear = () => {
    setPage([1, 10]);
    setReset(!reset);
    form.resetFields();
    setStartDate('CreateStartDate');
    setEndDate('CreateEndDate');
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish} initialValues={{
          TimeMode: SEARCH_DATE_TYPE.建立時間
        }}>
          <Row align="middle" gutter={10}>
            <Col className="w-12">
              <Form.Item name="code">
                <Input placeholder={`${i18n.t('order')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="account">
                <Input placeholder={`${i18n.t('memberAccount')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="status">
                <Select
                  className="w-full"
                  placeholder={i18n.t('status')}
                  options={[
                    { value: '', label: `${i18n.t('all')}` },
                    ...enumToOptions(WITHDRAW_STATE)
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="upMemberAccount">
                <Input placeholder={`${i18n.t('recommendedMembers')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="agAccount">
                <Input placeholder={`${i18n.t('agentAccount')}`} />
              </Form.Item>
            </Col>
          </Row>
          <Row className="mt-1" justify="space-between">
            <Col>
              <Row align="middle" gutter={[12, 12]}>
                <Col className="w-12">
                  <Form.Item name="TimeMode">
                    <Select
                      className="w-full"
                      onChange={handleDateType}
                      options={[
                        { value: SEARCH_DATE_TYPE.建立時間, label: `${i18n.t('createTime')}` },
                        { value: SEARCH_DATE_TYPE.審核時間, label: `${i18n.t('auditTime')}` },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <LayoutDateSelect width={375} onChange={setDate} dateFormatDefault={DATE_FORMAT.日期時間}
                  hiddenDateType={[DATE_TYPE.上週]} reset={reset} />
                <Col>
                  <Row align="middle" gutter={[12, 12]}>
                    <Col>
                      <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                    </Col>
                    <Col>
                      <Button onClick={onClear}>{i18n.t('clear')}</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col>
              <LayoutUpdateControl callback={mutate} />
            </Col>
          </Row>
        </Form>
        <Row className="mt-1">
          <Col span={24}>
            <Spin spinning={isValidating}>
              <ViewMemberWithdrawOverview data={withdrawList} />
            </Spin>
          </Col>
        </Row>
        {$p.includes('20110') && <Row className="mt-1">
          <Col>
            <Export url={'/withdrawticket/member/list'} param={{
              ...params, PageSize: withdrawList && withdrawList.TotalRecord, PageIndex: 1
            }} sheetName={i18n.t('withdrawalQuery')} columns={ExcelColumns.MemberWithdraw} />
          </Col>
        </Row>}
        <Row className="mt-1">
          <Col span={24}>
            <Spin spinning={isValidating}>
              <ViewMemberWithdrawList data={withdrawList} mutate={mutate} />
              <LayoutPagination total={withdrawList ? withdrawList.TotalRecord : 0} setPage={setPage} />
            </Spin>
          </Col>
        </Row>
      </Content>
    </div >
  );
};

export default PageMain;