import {
  axiosGetAllData,
  axiosGetAuth,
  axiosPostAuth,
  baseURL,
  useFetchDataCommon
} from "services";

// 會員CRUD
export const CreateMemberBank = (data: any) => axiosPostAuth(`${baseURL}/withdrawmethod/member/create`, data);
export const UpdateMemberBank = (data: any) => axiosPostAuth(`${baseURL}/withdrawmethod/member/update`, data);
export const DeleteMemberBank = (data: any) => axiosPostAuth(`${baseURL}/withdrawmethod/member/delete`, data);

export const PostWithdrawmethodMemberBankCreate = (data: any) => axiosPostAuth(`${baseURL}/withdrawmethod/member/bank/create`, data);
export const PostWithdrawmethodUpdateStatus = (data: any) => axiosPostAuth(`${baseURL}/withdrawmethod/update/status`, data);

// 代理CRUD
export const GetWithdrawmethodAgentList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/withdrawmethod/agent/list`, params);
export const PostWithdrawmethodAgentUpdate = (data: any) => axiosPostAuth(`${baseURL}/withdrawmethod/agent/update`, data);
export const PostWithdrawmethodAgentDelete = (data: any) => axiosPostAuth(`${baseURL}/withdrawmethod/agent/delete`, data);

export const BankAgentList = () => useFetchDataCommon(axiosGetAuth, `${baseURL}/withdrawmethod/agent/list`);
export const GetWithdrawmethodMemberList = (params: any, allow: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/withdrawmethod/member/list`, params, allow);
export const GetWithdrawmethodOne = (params: any, allow: boolean) => useFetchDataCommon(axiosGetAuth, `${baseURL}/withdrawmethod/one`, params, allow);
export const WithdrawmethodVerifyList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/withdrawmethod/list`, params);
export const PostWithdrawmethodAgentVerify = (data: any) => axiosPostAuth(`${baseURL}/withdrawmethod/agent/verify`, data);
export const PostWithdrawmethodMemberVerify = (data: any) => axiosPostAuth(`${baseURL}/withdrawmethod/member/verify`, data);
export const GetGatewayList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/gateway/all/list`, params);
