import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Col, Descriptions, Form, Input, Popover, Row, Select, Table, Tooltip, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { LayoutNav, LayoutTabMember } from 'components/layout.component';
import { RESPONSE_CODE } from 'constants/response';
import { ENABLE, RESPONSE_TYPE } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AgentRebateInfo } from 'services/agent.service';

import { FetchMemberDetail } from 'services/member.service';
import { PostMemberoperationparamsStatusUpdate, RebateSetting, RebateSettingList } from 'services/memberoperationparams.service';
import * as common from 'utils/common';
import { convertedToPercentage01 as $g, verify } from 'utils/common';

// 會員返水

interface RebateType {
  key: number;
  game: string;
  rebate: number;
  name: string;
  agentRebate: number;
}

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();
  const [form] = useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: member } = FetchMemberDetail({ Id: id }, !!id);
  const { data: agent } = AgentRebateInfo({ AgentId: member?.AGId }, !!member?.AGId);
  const { data: rebateSettingList, isValidating } = RebateSettingList({ MemberId: id });
  const [data, setData] = useState<RebateType[]>([]);
  useEffect(() => {
    if (rebateSettingList && agent) {
      setData([
        {
          key: 1,
          game: i18n.t('EGAMES'),
          name: 'EGamesCommissionRate',
          rebate: rebateSettingList.EGamesCommissionRate,
          agentRebate: agent.EGamesCommissionRate
        },
        {
          key: 2,
          game: i18n.t('LIVE'),
          name: 'LiveCommissionRate',
          rebate: rebateSettingList.LiveCommissionRate,
          agentRebate: agent.LiveCommissionRate
        },
        {
          key: 3,
          game: i18n.t('SPORTS'),
          name: 'SportsCommissionRate',
          rebate: rebateSettingList.SportsCommissionRate,
          agentRebate: agent.SportsCommissionRate
        },
        {
          key: 4,
          game: i18n.t('ESPORTS'),
          name: 'ESportsCommissionRate',
          rebate: rebateSettingList.ESportsCommissionRate,
          agentRebate: agent.ESportsCommissionRate
        },
        {
          key: 5,
          game: i18n.t('POKER'),
          name: 'PokerCommissionRate',
          rebate: rebateSettingList.PokerCommissionRate,
          agentRebate: agent.PokerCommissionRate
        },
        {
          key: 6,
          game: i18n.t('FISHING'),
          name: 'FishingCommissionRate',
          rebate: rebateSettingList.FishingCommissionRate,
          agentRebate: agent.FishingCommissionRate
        },
        {
          key: 7,
          game: i18n.t('COCKFIGHT'),
          name: 'CockfightCommissionRate',
          rebate: rebateSettingList.CockfightCommissionRate,
          agentRebate: agent.CockfightCommissionRate
        },
        {
          key: 8,
          game: i18n.t('BINGO'),
          name: 'LotteryCommissionRate',
          rebate: rebateSettingList.LotteryCommissionRate,
          agentRebate: agent.LotteryCommissionRate
        },
        {
          key: 9,
          game: i18n.t('SLOT'),
          name: 'SlotCommissionRate',
          rebate: rebateSettingList.SlotCommissionRate,
          agentRebate: agent.SlotCommissionRate
        }
      ])
    }
  }, [rebateSettingList, agent])
  useEffect(() => {
    if (rebateSettingList) {
      form.setFieldsValue({
        rebateState: rebateSettingList.Status,
        rebatePeriod: rebateSettingList.CommissionSettleCycle,
        EGamesCommissionRate: $g(rebateSettingList.EGamesCommissionRate),
        LiveCommissionRate: $g(rebateSettingList.LiveCommissionRate),
        SportsCommissionRate: $g(rebateSettingList.SportsCommissionRate),
        ESportsCommissionRate: $g(rebateSettingList.ESportsCommissionRate),
        PokerCommissionRate: $g(rebateSettingList.PokerCommissionRate),
        FishingCommissionRate: $g(rebateSettingList.FishingCommissionRate),
        CockfightCommissionRate: $g(rebateSettingList.CockfightCommissionRate),
        LotteryCommissionRate: $g(rebateSettingList.LotteryCommissionRate),
        SlotCommissionRate: $g(rebateSettingList.SlotCommissionRate)
      })
      setStatusDisabled(rebateSettingList.Status === ENABLE.停用)
    }
  }, [rebateSettingList])

  const handleSetAllRebate = (value: string) => {
    if (common.decimalNumber(value)) {
      form.setFieldsValue({
        EGamesCommissionRate: value,
        LiveCommissionRate: value,
        SportsCommissionRate: value,
        ESportsCommissionRate: value,
        PokerCommissionRate: value,
        FishingCommissionRate: value,
        CockfightCommissionRate: value,
        LotteryCommissionRate: value,
        SlotCommissionRate: value
      })
    }
  }

  const [isAllDisabled, setIsAllDisabled] = useState(false);
  const [statusDisabled, setStatusDisabled] = useState(false);
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const save = async (formData: any) => {
    const response = await RebateSetting({
      MemberId: id,
      EGamesCommissionRate: parseFloat(formData.EGamesCommissionRate) / 100,
      SlotCommissionRate: parseFloat(formData.SlotCommissionRate) / 100,
      LiveCommissionRate: parseFloat(formData.LiveCommissionRate) / 100,
      SportsCommissionRate: parseFloat(formData.SportsCommissionRate) / 100,
      ESportsCommissionRate: parseFloat(formData.ESportsCommissionRate) / 100,
      PokerCommissionRate: parseFloat(formData.PokerCommissionRate) / 100,
      FishingCommissionRate: parseFloat(formData.FishingCommissionRate) / 100,
      CockfightCommissionRate: parseFloat(formData.CockfightCommissionRate) / 100,
      LotteryCommissionRate: parseFloat(formData.LotteryCommissionRate) / 100,
    })
    if (response.State === RESPONSE_TYPE.成功) {
      await PostMemberoperationparamsStatusUpdate({
        MemberId: id,
        Status: formData.rebateState
      })
      message.success(i18n.t('operationSuccess'));
      setIsAllDisabled(true);
      setIsOpenPopover(false);
    } else {
      const msg = RESPONSE_CODE[response.Message as keyof typeof RESPONSE_CODE];
      message.error(msg || i18n.t('processingFailed'));
    }
  }

  return (
    <div id="container">
      <LayoutNav id={member ? member.Id : 0} account={member ? member.Account : ''} />
      <Content className="pr-2 pl-2 pb-2">
        <LayoutTabMember activeKey="5" id={member ? member.Id : 0} />
        <Form form={form} onFinish={save}>
          <Row gutter={[12, 12]}>
            <Col span={24} className="flex-end">
              {
                isAllDisabled
                  ? <Button onClick={() => navigate('/member')}>{i18n.t('close')}</Button>
                  : <>
                    <Button onClick={() => navigate('/member')} className="mr-1">{i18n.t('cancel')}</Button>
                    {
                      $p.includes('10602') &&
                      <Button htmlType="submit" type="primary">{i18n.t('confirm')}</Button>
                    }
                  </>
              }
            </Col>
            <Col span={12}>
              <Descriptions layout="vertical" bordered size="small">
                <Descriptions.Item style={{ width: '50%' }} label={
                  <Row align="middle" gutter={6}>
                    <Col>
                      <><span className="require">*</span>{i18n.t('rebatePeriod')}</>
                    </Col>
                    <Col>
                      <Tooltip placement="top" className="size-12" title={i18n.t('sameAsUpperAgent')}>
                        <InfoCircleFilled className="center" />
                      </Tooltip>
                    </Col>
                  </Row>
                }>
                  <Form.Item name="rebatePeriod">
                    <Select
                      style={{ width: '100%' }}
                      disabled
                      options={[
                        { value: 0, label: i18n.t('dailyStatement') },
                        { value: 1, label: i18n.t('weeklyStatement') },
                        { value: 2, label: i18n.t('monthlySettlement') },
                      ]}
                    />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item style={{ width: '50%' }} label={<><span className="require">*</span>{i18n.t('status')}</>}>
                  <Form.Item name="rebateState">
                    <Select
                      style={{ width: '100%' }}
                      placeholder={`${i18n.t('pleaseSelect')}`}
                      disabled={isAllDisabled || !$p.includes('10602')}
                      options={common.enumToOptions(ENABLE)}
                      onChange={val => setStatusDisabled(val === ENABLE.停用)}
                    />
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={24} className="text-end">
              <Popover placement="bottomLeft" trigger="click" open={isOpenPopover} content={
                <Row gutter={[12, 12]}>
                  <Col span={24} className="w-6">
                    <Form.Item name="batchSetting">
                      <Input placeholder={`${i18n.t('enter')}`}
                        onChange={(e) => handleSetAllRebate(e.target.value)} onPressEnter={() => setIsOpenPopover(false)} />
                    </Form.Item>
                  </Col>
                  <Col span={24} className="center">
                    <Button size="small" onClick={() => setIsOpenPopover(false)}>
                      {i18n.t('close')}
                    </Button>
                  </Col>
                </Row>
              }>
                <Button type="primary" disabled={isAllDisabled || !$p.includes('10602')}
                  onClick={() => setIsOpenPopover(!isOpenPopover)}>
                  {i18n.t('batchSetting')}
                </Button>
              </Popover>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={12}>
                  <Table
                    loading={isValidating}
                    bordered
                    size="small"
                    dataSource={data?.filter((_, i) => i % 2 === 0)}
                    columns={[
                      {
                        key: 1,
                        dataIndex: 'game',
                        title: <><span className="require">*</span>{i18n.t('gameCategory')}</>,
                        className: 'size-12 color-03',
                        width: '25%',
                        render: (val, record) => `${val} % (max：${$g(record.agentRebate)})`
                      },
                      {
                        key: 2,
                        title: <> <span className="require">*</span>{i18n.t('rebateRate')}%</>,
                        className: 'size-12',
                        width: '25%',
                        render: (_, record) => (
                          <Form.Item name={record.name} rules={verify({ max: $g(record.agentRebate) })}>
                            <Input name={record.name} className="size-12" disabled={isAllDisabled || statusDisabled || !$p.includes('10602')} defaultValue={common.convertedToPercentage02(record.rebate)} />
                          </Form.Item>
                        )
                      },
                    ]}
                    pagination={false}
                  />
                </Col >
                <Col span={12}>
                  <Table
                    loading={isValidating}
                    bordered
                    size="small"
                    dataSource={data && data.filter((_, i) => i % 2 !== 0)}
                    columns={[
                      {
                        key: 1,
                        dataIndex: 'game',
                        title: <> <span className="require">*</span>{i18n.t('gameCategory')}</>,
                        className: 'size-12 color-03',
                        width: '25%',
                        render: (val, record) => `${val} % (max：${$g(record.agentRebate)})`
                      },
                      {
                        key: 2,
                        title: <> <span className="require">*</span>{i18n.t('rebateRate')}%</>,
                        className: 'size-12',
                        width: '25%',
                        render: (_, record) => (
                          <Form.Item name={record.name} rules={verify({ max: $g(record.agentRebate) })}>
                            <Input name={record.name} className="size-12" disabled={isAllDisabled || statusDisabled || !$p.includes('10602')} defaultValue={common.convertedToPercentage02(record.rebate)} />
                          </Form.Item>
                        )
                      },
                    ]}
                    pagination={false}
                  />
                </Col>
              </Row >
            </Col >
          </Row >
        </Form >
      </Content >
    </div >
  );
};

export default PageMain;