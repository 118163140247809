import { Button, Col, Form, Image, Input, InputNumber, Modal, Row, Select, Space, Spin, Switch, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import { UploadFile } from 'antd/lib/upload/interface';
import { RESPONSE_CODE, RESPONSE_CODE_FRONT } from 'constants/response';
import dayjs from 'dayjs';
import { ENABLE, FRONT_COPY_TYPE, RESPONSE_TYPE, SLIDE_TYPE } from 'enum/state';
import useIcon from 'hooks/icon.hook';
import i18n from 'i18n';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { CreateFront, CreateFrontSub, CreateMobileSlide, CreateWebSlide, DeleteFront, DeleteFrontSub, DeleteSlide, GetFrontList, GetFrontSubOne, UpdateFront, UpdateFrontSub, UpdateSlide } from 'services/front.service';
import { enumToOptions, verify } from 'utils/common';
import { UploadImage } from './layout.component';

export const PopupAddFront = ({ isOpen, close, mutate }: any) => {
  const [form] = useForm();

  const onFinish = async (formData: any) => {
    const Lang = Cookies.get('lang');

    const response = await CreateFront({
      Lang,
      Type: formData.type,
      Title: formData.title,
      Position: formData.sort
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('addSuccess'));
      onClose();
      mutate();
    } else {
      message.error(RESPONSE_CODE_FRONT[response.Message as keyof typeof RESPONSE_CODE_FRONT]);
    }

  };

  const onClose = () => {
    form.resetFields();
    close();
  }

  return (
    <Modal
      title={i18n.t('add')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="type" label={i18n.t('category')} rules={[{ required: true }]}>
              <Select
                className="w-full"
                placeholder={`${i18n.t('selectCategory')}`}
                options={enumToOptions(FRONT_COPY_TYPE)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="title" label={i18n.t('mainTitle')} rules={[
              { required: true },
              { max: 100, message: `${i18n.t('exceedsCharacterLimit100')}` }
            ]}>
              <TextArea autoSize={{ minRows: 2, maxRows: 1 }} showCount maxLength={100}
                placeholder={`${i18n.t('enterMainTitle')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="sort" label={i18n.t('sort')} rules={verify({ point: 0 })}>
              <InputNumber className="w-full" placeholder={`${i18n.t('enterSort')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal >
  )
}

export const PopupEditFront = ({ isOpen, close, data, mutate }: any) => {
  const [form] = useForm();

  useEffect(() => {
    if (data && isOpen) {
      form.setFieldsValue({
        type: data.Type,
        title: data.title,
        sort: data.sort
      })
    }
  }, [data, isOpen]);

  const onFinish = async (formData: any) => {

    const response = await UpdateFront({
      Id: data.key,
      Title: formData.title,
      Position: formData.sort
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('editSuccess'));
      onClose();
      mutate();
    } else {
      message.error(RESPONSE_CODE_FRONT[response.Message as keyof typeof RESPONSE_CODE_FRONT]);
    }

  };

  const onClose = () => {
    form.resetFields();
    close();
  }

  return (
    <Modal
      title={i18n.t('edit')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="type" label={i18n.t('category')} rules={[{ required: true }]}>
              <Select
                disabled
                className="w-full"
                placeholder={`${i18n.t('selectCategory')}`}
                options={enumToOptions(FRONT_COPY_TYPE)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="title" label={i18n.t('mainTitle')} rules={[
              { required: true },
              { max: 100, message: `${i18n.t('exceedsCharacterLimit100')}` }
            ]}>
              <TextArea autoSize={{ minRows: 2, maxRows: 1 }} showCount maxLength={100}
                placeholder={`${i18n.t('enterMainTitle')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="sort" label={i18n.t('sort')} rules={verify({ point: 0 })}>
              <InputNumber className="w-full" placeholder={`${i18n.t('enterSort')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal >
  )
}

export const PopupDeleteModal = ({ isOpen, close, id, mutate }: any) => {
  const icon = useIcon();

  const onDelete = async () => {

    const response = await DeleteFront({
      Id: id,
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      mutate();
      close();
    } else {
      message.error(RESPONSE_CODE_FRONT[response.Message as keyof typeof RESPONSE_CODE_FRONT]);
    }

  }

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={icon.icon12} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button className="mt-1" onClick={close}>{i18n.t('cancel')}</Button>
          <Button className="mt-1" type="primary" onClick={onDelete}>{i18n.t('confirm')}</Button>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmDeleteData')}？</div>
      </Space>
    </Modal>
  )
}

export const PopupAddFrontSub = ({ isOpen, close, type, main, mutate }: any) => {
  const [form] = useForm();
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);
  const [isTextOrPhoto, setIsTextOrPhoto] = useState(false);
  const { data: FrontList, isValidating } = GetFrontList({
    Type: type,
    PageIndex: 1,
    PageSize: 999
  })

  useEffect(() => {
    if (main && type) {
      form.setFieldsValue({
        type,
        mainTitle: main
      })
    }
  }, [main, type, FrontList]);

  const onFinish = async (formData: any) => {
    const response = await CreateFrontSub({
      ContentsId: main,
      Title: formData.subTitle,
      Content: !isTextOrPhoto ? formData.content : "",
      Photo: isTextOrPhoto ? imageData[0].url : "",
      Position: formData.sort
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('addSuccess'));
      onClose();
      mutate();
    } else {
      message.error(RESPONSE_CODE_FRONT[response.Message as keyof typeof RESPONSE_CODE_FRONT]);
    }
  };

  const onClose = () => {
    form.setFieldsValue({
      subTitle: null,
      content: null,
      sort: null,
      fileList: null,
    })
    setImageData([]);
    setIsTextOrPhoto(false);
    close();
  }

  return (
    <Modal
      title={i18n.t('add')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
    >
      <Form layout="vertical" form={form} onFinish={onFinish} >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="type" label={i18n.t('category')} rules={[{ required: true }]}>
              <Select
                disabled
                className="w-full"
                placeholder={`${i18n.t('selectCategory')}`}
                options={enumToOptions(FRONT_COPY_TYPE)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="mainTitle" label={i18n.t('mainTitle')} rules={[{ required: true }]}>
              <Select
                disabled
                loading={isValidating}
                className="w-full"
                placeholder={`${i18n.t('pleaseChooseSubtitle')}`}
                options={
                  (FrontList && main && type) ?
                    FrontList.Data.map((item: FrontList) => (
                      { value: item.Id, label: item.Title }
                    )) : []
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="subTitle" label={i18n.t('subTitle')} rules={[
              { required: true },
              { max: 100, message: `${i18n.t('exceedsCharacterLimit100')}` }
            ]}>
              <TextArea autoSize={{ minRows: 2, maxRows: 1 }} showCount maxLength={100}
                placeholder={`${i18n.t('pleaseEnterSubtitle')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            {
              isTextOrPhoto
                ? <>
                  <Form.Item name="Photo" valuePropName="fileList" label={
                    <Row align="middle" gutter={[12, 12]}>
                      <Col>{i18n.t('internalContent')}</Col>
                      <Col><Switch checked={isTextOrPhoto} onChange={setIsTextOrPhoto} /></Col>
                      <Col>{i18n.t('image')}</Col>
                    </Row>
                  }>
                    <UploadImage
                      name="Photo"
                      url={'/upload/images/public/contents'}
                      imageData={imageData}
                      setImageData={setImageData}
                      w={414}
                      h={414}
                      crop
                    />
                  </Form.Item>
                  <div className="color-03 size-12">{i18n.t('onlyJpgOrPngFiles')}<span className="require">{i18n.t('imageMobileVersionWidth414px')}</span>{i18n.t('allowedAndSizeNotExceed500kb')}</div>
                </>
                : <Form.Item name="content" label={
                  <Row align="middle" gutter={[12, 12]}>
                    <Col>{i18n.t('internalContent')}</Col>
                    <Col><Switch checked={isTextOrPhoto} onChange={setIsTextOrPhoto} /></Col>
                    <Col>{i18n.t('image')}</Col>
                  </Row>
                } rules={[
                  { required: true, message: `${i18n.t('required')}` },
                  { max: 1000, message: `${i18n.t('exceedsCharacterLimit1000')}` }
                ]}>
                  <TextArea autoSize={{ minRows: 5, maxRows: 5 }} showCount maxLength={1000}
                    placeholder={`${i18n.t('pleaseEnterContent')}`} />
                </Form.Item>
            }
          </Col>
          <Col span={24}>
            <Form.Item name="sort" label={i18n.t('sort')} rules={verify({ point: 0 })}>
              <InputNumber className="w-full" placeholder={`${i18n.t('enterSort')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">
                  {i18n.t('confirm')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal >
  )
}

export const PopupEditFrontSub = ({ isOpen, close, id, type, main, mutate }: any) => {
  const [form] = useForm();
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);
  const [isTextOrPhoto, setIsTextOrPhoto] = useState(false);
  const { data: FrontList, isValidating } = GetFrontList({
    Type: type,
    PageIndex: 1,
    PageSize: 999
  })
  const { data: FrontSubInfo, mutate: mutateOne, isValidating: loadingInfo } = GetFrontSubOne({ Id: id });
  useEffect(() => {
    if (isOpen) mutateOne()
  }, [isOpen]);
  useEffect(() => {
    if (FrontSubInfo && isOpen) {
      form.setFieldsValue({
        type,
        mainTitle: main,
        subTitle: FrontSubInfo[0].Title,
        content: FrontSubInfo[0].Content,
        sort: FrontSubInfo[0].Position,
      })
      FrontSubInfo[0]?.Photo && setImageData([
        {
          uid: FrontSubInfo[0].Photo,
          name: FrontSubInfo[0].Photo,
          url: FrontSubInfo[0].Photo,
        },
      ]);
      setIsTextOrPhoto(FrontSubInfo[0].Photo);
    }
  }, [main, type, FrontSubInfo, isOpen]);

  const onFinish = async (formData: any) => {
    const response = await UpdateFrontSub({
      Id: id,
      Title: formData.subTitle,
      Content: !isTextOrPhoto ? formData.content : "",
      Photo: isTextOrPhoto ? imageData[0].url : "",
      Position: formData.sort
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('editSuccess'));
      onClose();
      mutate();
    } else {
      message.error(RESPONSE_CODE_FRONT[response.Message as keyof typeof RESPONSE_CODE_FRONT]);
    }
  };

  const onClose = () => {
    form.resetFields();
    setImageData([]);
    close();
  }

  return (
    <Modal
      title={i18n.t('edit')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
    >
      <Form layout="vertical" form={form} onFinish={onFinish} >
        <Spin spinning={loadingInfo}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Form.Item name="type" label={i18n.t('category')} rules={[{ required: true }]}>
                <Select
                  disabled
                  className="w-full"
                  placeholder={`${i18n.t('selectCategory')}`}
                  options={enumToOptions(FRONT_COPY_TYPE)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="mainTitle" label={i18n.t('mainTitle')} rules={[{ required: true }]}>
                <Select
                  disabled
                  loading={isValidating}
                  className="w-full"
                  placeholder={`${i18n.t('pleaseChooseSubtitle')}`}
                  options={
                    (FrontList && main && type) ?
                      FrontList.Data.map((item: FrontList) => (
                        { value: item.Id, label: item.Title }
                      )) : []
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="subTitle" label={i18n.t('subTitle')} rules={[
                { required: true },
                { max: 100, message: `${i18n.t('exceedsCharacterLimit100')}` }
              ]}>
                <TextArea autoSize={{ minRows: 2, maxRows: 1 }} showCount maxLength={100}
                  placeholder={`${i18n.t('pleaseEnterSubtitle')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              {
                isTextOrPhoto
                  ? <>
                    <Form.Item name="Photo" valuePropName="fileList" label={
                      <Row align="middle" gutter={[12, 12]}>
                        <Col>{i18n.t('internalContent')}</Col>
                        <Col><Switch checked={isTextOrPhoto} onChange={setIsTextOrPhoto} /></Col>
                        <Col>{i18n.t('image')}</Col>
                      </Row>
                    }>
                      <UploadImage
                        name="Photo"
                        url={'/upload/images/public/contents'}
                        imageData={imageData}
                        setImageData={setImageData}
                        w={414}
                        h={414}
                        crop
                      />
                    </Form.Item>
                    <div className="color-03 size-12">{i18n.t('onlyJpgOrPngFiles')}<span className="require">{i18n.t('imageMobileVersionWidth414px')}</span>{i18n.t('allowedAndSizeNotExceed500kb')}</div>
                  </>
                  : <Form.Item name="content" label={
                    <Row align="middle" gutter={[12, 12]}>
                      <Col>{i18n.t('internalContent')}</Col>
                      <Col><Switch checked={isTextOrPhoto} onChange={setIsTextOrPhoto} /></Col>
                      <Col>{i18n.t('image')}</Col>
                    </Row>
                  } rules={[
                    { required: true, message: `${i18n.t('required')}` },
                    { max: 1000, message: `${i18n.t('exceedsCharacterLimit1000')}` }
                  ]}>
                    <TextArea autoSize={{ minRows: 5, maxRows: 5 }} showCount maxLength={1000}
                      placeholder={`${i18n.t('pleaseEnterContent')}`} />
                  </Form.Item>
              }
            </Col>
            <Col span={24}>
              <Form.Item name="sort" label={i18n.t('sort')} rules={verify({ point: 0 })}>
                <InputNumber className="w-full" placeholder={`${i18n.t('enterSort')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row justify="center" gutter={[12, 12]}>
                <Col>
                  <Button onClick={onClose}>{i18n.t('cancel')}</Button>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit">
                    {i18n.t('confirm')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </Form>
    </Modal >
  )
}

export const PopupDeleteFrontSubModal = ({ isOpen, close, id, mutate }: any) => {
  const icon = useIcon();

  const onDelete = async () => {

    const response = await DeleteFrontSub({
      Id: id,
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      mutate();
      close();
    } else {
      message.error(RESPONSE_CODE_FRONT[response.Message as keyof typeof RESPONSE_CODE_FRONT]);
    }

  }

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={icon.icon12} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button className="mt-1" onClick={close}>{i18n.t('cancel')}</Button>
          <Button className="mt-1" type="primary" onClick={onDelete}>{i18n.t('confirm')}</Button>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmDeleteData')}？</div>
      </Space>
    </Modal>
  )
}

export const PopupFrontCarousel = ({ isOpen, close, data, mutate }: any) => {
  const [form] = useForm();
  const [isLinkDisabled, setIsLinkDisabled] = useState(true);
  const [uploadType, setUploadType] = useState(SLIDE_TYPE.手機版);
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);

  useEffect(() => {
    if (data && isOpen) {
      form.setFieldsValue({
        Category: data.Category,
        LinkTarget: data.LinkTarget,
        LinkURL: data.LinkURL,
        Status: data.Status,
        Position: data.Position,
        DisplayTime: data.DisplayTime,
      })
      setImageData([{
        uid: data.Photo_1,
        name: data.Photo_1,
        url: data.Photo_1,
      }])
      setIsLinkDisabled(!data.LinkTarget);
      setUploadType(data.Category);
    }
  }, [data, isOpen]);

  const onChangeCategory = (select: number) => {
    setUploadType(select);
    setIsLinkDisabled(true);
    setImageData([]);
  }

  const onChangeLinkTarget = (type: number) => {
    setIsLinkDisabled(!(type === 1));
    form.setFieldValue('LinkURL', '');
  }

  const onFinish = async (formData: any) => {
    const Lang = Cookies.get('lang');
    const send: any = {
      Photo_1: imageData[0].url,
      LinkTarget: formData.LinkTarget,
      LinkURL: formData.LinkURL,
      Status: formData.Status,
      Position: formData.Position,
      DisplayTime: formData.DisplayTime,

    }
    let response: any = {}
    if (!data) {
      send.Lang = Lang;
      send.PublishDate = dayjs().format('YYYY-MM-DD HH:mm:ss');
      switch (formData.Category) {
        case SLIDE_TYPE.手機版:
          response = await CreateMobileSlide(send)
          break;
        case SLIDE_TYPE.電腦版:
          response = await CreateWebSlide(send)
          break;
      }
    } else {
      send.Id = data.key;
      response = await UpdateSlide(send)
    }
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(!data ? i18n.t('addSuccess') : i18n.t('editSuccess'));
      mutate();
      onClose();
    } else {
      const msg = RESPONSE_CODE[response.Message as keyof typeof RESPONSE_CODE];
      message.error(msg || i18n.t('processingFailed'));
    }
  }

  const onClose = () => {
    form.resetFields();
    setImageData([]);
    close();
  }

  return (
    <Modal
      title={!data ? i18n.t('add') : i18n.t('edit')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
    >
      <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{
        Category: 2,
        Position: 0,
      }}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="Category" label={i18n.t('category')} rules={[{ required: true }]}>
              <Select
                disabled={!!data}
                className="w-full"
                onChange={onChangeCategory}
                options={enumToOptions(SLIDE_TYPE)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Photo_1" valuePropName="fileList" label={i18n.t('image')} rules={[{ required: !imageData[0] }]}>
              <UploadImage
                name="Photo_1"
                url={'/upload/images/public/contents'}
                imageData={imageData}
                setImageData={setImageData}
                w={uploadType === SLIDE_TYPE.手機版 ? 540 : 1920}
                h={uploadType === SLIDE_TYPE.手機版 ? 155 : 560}
                form={form}
                crop
              />
              {
                uploadType === SLIDE_TYPE.手機版 &&
                <div className="color-03 size-12">{i18n.t('onlyJpgOrPngFiles')}
                  <span className="require">{i18n.t('imageMobileVersionWidth540pxHeight155px')}</span>
                  <span>{i18n.t('allowedAndSizeNotExceed500kb')}</span>
                </div>
              }
              {
                uploadType === SLIDE_TYPE.電腦版 &&
                <div className="color-03 size-12">{i18n.t('onlyJpgOrPngFiles')}
                  <span className="require">{i18n.t('imageMobileVersionWidth1920pxHeight560px')}</span>
                  <span>{i18n.t('allowedAndSizeNotExceed500kb')}</span>
                </div>
              }
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="LinkTarget" label={i18n.t('openLinkInNewWindow')} rules={[{ required: true }]}>
              <Select
                className="w-full"
                placeholder={`${i18n.t('pleaseSelect')}`}
                onChange={onChangeLinkTarget}
                options={[
                  { value: 1, label: i18n.t('yes') },
                  { value: 0, label: i18n.t('no') },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="LinkURL" label={i18n.t('linkURL')} rules={[{ required: !isLinkDisabled }]}>
              <Input placeholder={`${i18n.t('enterURL')}`} disabled={isLinkDisabled} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Status" label={i18n.t('status')} rules={[{ required: true }]}>
              <Select
                className="w-full"
                placeholder={`${i18n.t('pleaseSelect')}`}
                options={enumToOptions(ENABLE)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Position" label={i18n.t('sort')} rules={verify({ point: 0 })}>
              <InputNumber className="w-full" placeholder={`${i18n.t('enterSort')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="DisplayTime" label={i18n.t('imageDisplayTime')} rules={[{ required: true }]}>
              <Select
                className="w-full"
                placeholder={`${i18n.t('pleaseSelect')}`}
                options={[
                  { value: 5, label: `5${i18n.t('second')}` },
                  { value: 10, label: `10${i18n.t('second')}` },
                  { value: 15, label: `15${i18n.t('second')}` },
                  { value: 20, label: `20${i18n.t('second')}` },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row align="middle" justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
// 空的
export const PopupDeleteCarouselModal = ({ isOpen, close, id, mutate }: any) => {
  const icon = useIcon();

  const onDelete = async () => {

    const response = await DeleteSlide({
      Id: id
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      mutate();
      close();
    } else {
      message.error(RESPONSE_CODE_FRONT[response.Message as keyof typeof RESPONSE_CODE_FRONT]);
    }

  }

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={icon.icon12} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button className="mt-1" key="cancel" onClick={close}>{i18n.t('cancel')}</Button>
          <Button className="mt-1" key="confirm" type="primary" onClick={onDelete}>{i18n.t('confirm')}</Button>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmDeleteData')}？</div>
      </Space>
    </Modal>
  )
}
