import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { LayoutDateSelect, LayoutNav, LayoutPagination } from 'components/layout.component';
import { ViewVerificationList } from 'components/verification.component';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TYPE } from 'enum/date';
import { SEARCH_DATE_TYPE, VERIFICATION_STATE } from 'enum/state';
import i18n from 'i18n';
import React, { useState } from 'react';
import { FetchVerificationList } from 'services/kycticket.service';
import { enumToOptions } from 'utils/common';

// 實名驗證

const PageMain: React.FC = () => {
  const [form] = useForm();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [starDate, setStarDate] = useState('CreateDateStartDate');
  const [endDate, setEndDate] = useState('CreateDateEndDate');
  const [date, setDate] = useState<string[]>();
  const [reset, setReset] = useState(false);

  const [params, setParams] = useState<any>({
    Status: 0,
    CreateDateStartDate: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    CreateDateEndDate: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  });
  const { data: verificationList, mutate, isValidating } = FetchVerificationList({
    ...params,
    PageSize: page[1],
    PageIndex: page[0]
  });

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        Account: formData.memberAccount || '',
        Status: formData.status,
        [starDate]: date[0],
        [endDate]: date[1],
        PageSize: page[1],
        PageIndex: page[0]
      })
    }
  }

  const handleSearchDateType = (type: number) => {
    switch (type) {
      case SEARCH_DATE_TYPE.建立時間:
        setStarDate('CreateDateStartDate');
        setEndDate('CreateDateEndDate');
        break;
      case SEARCH_DATE_TYPE.審核時間:
        setStarDate('AuditedTimeStartDate');
        setEndDate('AuditedTimeEndDate');
        break;
    }
  }

  const onClear = () => {
    setPage([1, 10]);
    setReset(!reset);
    form.resetFields();
    setStarDate('CreateDateStartDate');
    setEndDate('CreateDateEndDate');
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="pr-2 pl-2 pb-2">
        {/* 搜尋列 */}
        <Form onFinish={onFinish} form={form} initialValues={{
          TimeMode: SEARCH_DATE_TYPE.建立時間,
        }}>
          <Row align="middle" gutter={[12, 12]}>
            <Col>
              <Form.Item name="memberAccount">
                <Input className="w-12" placeholder={`${i18n.t('memberAccount')}`} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item className="w-12" name="status">
                <Select
                  placeholder={i18n.t('status')}
                  options={[
                    { value: "", label: `${i18n.t('all')}` },
                    ...enumToOptions(VERIFICATION_STATE)
                  ]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item className="w-12" name="TimeMode">
                <Select
                  placeholder={i18n.t('pleaseSelect')}
                  onChange={v => handleSearchDateType(v)}
                  options={enumToOptions(SEARCH_DATE_TYPE)}
                />
              </Form.Item>
            </Col>
            <LayoutDateSelect onChange={setDate}
              dateFormatDefault={DATE_FORMAT.日期時間} hiddenDateType={[DATE_TYPE.上週]} reset={reset} />
            <Col>
              <Button htmlType="submit" type="primary" loading={!verificationList}>{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
        </Form>
        {/* 搜尋結果 */}
        <Row className="mt-1">
          <Col span={24}>
            <Spin spinning={isValidating}>
              <ViewVerificationList data={verificationList} mutate={mutate} />
              <LayoutPagination total={verificationList && verificationList.TotalRecord} setPage={setPage} />
            </Spin>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default PageMain;