import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Card, Col, Divider, Image, Row, Select, Space, Spin, Table, Tooltip } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { ColumnsType } from 'antd/es/table';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Tooltip as ChartTooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
} from 'chart.js';
import { LayoutDateSelect, LayoutNav } from 'components/layout.component';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TYPE } from 'enum/date';
import useIcon from 'hooks/icon.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GetAgentAllList } from 'services/agent.service';
import {
  GetReportActivityList,
  GetReportActivityMember,
  GetReportBonusAmount,
  GetReportDeficitGame,
  GetReportDeficitMember,
  GetReportDepositInfo,
  GetReportFirstDeposit,
  GetReportOnlineMembers,
  GetReportProfitGame,
  GetReportProfitInfo,
  GetReportProfitMember,
  GetReportTotalDepositAmount,
  GetReportTotalWithdrawAmount,
  GetReportWithdrawInfo
} from 'services/report.service';
import * as common from 'utils/common';
import { toFormatNumber as $f } from 'utils/common';

// 首頁: 數據總覽

// 圖表函式庫
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ChartTooltip,
  Legend,
  PointElement,
  LineElement,
);

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  const icon = useIcon();
  const [isLoading, setIsLoading] = useState(true);

  const { dateType } = useParams();
  const _dateType = dateType ? parseInt(dateType) : null;

  const [Date, setDate] = useState([
    dayjs().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
    _dateType || DATE_TYPE.本月
  ]);
  const handleDateChange = (dates: string[]) => {
    setIsLoading(true)
    setDate(dates);
  }

  // 取得線上人數
  const { data: OnlineMembers, isValidating } = GetReportOnlineMembers();

  // 總代列表
  const [SAgentId, setSAgentId] = useState<string>('');
  const { data: agentList } = GetAgentAllList();
  // const { data: agentList } = GetAgentSaList({
  //   PageIndex: 1,
  //   PageSize: 999,
  //   CreateDateStartTime: '2022-01-01 00:00:00',
  //   CreateDateEndTime: '2036-01-01 00:00:00',
  // });
  const handleSAgentIdChange = (newVal: string) => {
    setIsLoading(true)
    setSAgentId(newVal)
  }

  // 每個 card 的 api
  const [newUsers, setNewUsers] = useState<{ [propName: string]: number }>({});
  const _newUsers = GetReportActivityMember({ DateType: Date[2], SAgentId });
  useEffect(() => {

    // NOTE: 要寫全資料載入偵測
    // NOTE: 有值、無值、載入時要維持區域等大不跳動
    setTimeout(() => {
      setIsLoading(false)
    }, 800);

    if (_newUsers.data) setNewUsers(_newUsers.data)
  }, [_newUsers.data]);

  const [firstDeposit, setFirstDeposit] = useState<{ [propName: string]: number }>({});
  const dataFirstDeposit = GetReportFirstDeposit({ DateType: Date[2], SAgentId });
  useEffect(() => {
    if (dataFirstDeposit.data) setFirstDeposit(dataFirstDeposit.data)
  }, [dataFirstDeposit.data]);

  const [totalDeposit, setTotalDeposit] = useState<{ [propName: string]: number }>({});
  const _totalDeposit = GetReportTotalDepositAmount({ DateType: Date[2], SAgentId });
  useEffect(() => {
    if (_totalDeposit.data) setTotalDeposit(_totalDeposit.data)
  }, [_totalDeposit.data]);

  const [bonusAmount, setBonusAmount] = useState<{ [propName: string]: number }>({});
  const _bonusAmount = GetReportBonusAmount({ DateType: Date[2], SAgentId });
  useEffect(() => {
    if (_bonusAmount.data) setBonusAmount(_bonusAmount.data)
  }, [_bonusAmount.data]);

  const [depositInfo, setDepositInfo] = useState<{ [propName: string]: number }>({});
  const _depositInfo = GetReportDepositInfo({ DateType: Date[2], SAgentId });
  useEffect(() => {
    if (_depositInfo.data) setDepositInfo(_depositInfo.data)
  }, [_depositInfo.data]);

  const [withdrawAmount, setWithdrawAmount] = useState<{ [propName: string]: number }>({});
  const _withdrawAmount = GetReportTotalWithdrawAmount({ DateType: Date[2], SAgentId });
  useEffect(() => {
    if (_withdrawAmount.data) setWithdrawAmount(_withdrawAmount.data)
  }, [_withdrawAmount.data]);

  // 調帳扣除
  const [withdrawInfo, setWithdrawInfo] = useState<{ [propName: string]: number }>({});
  const _withdrawInfo = GetReportWithdrawInfo({ DateType: Date[2], SAgentId });
  useEffect(() => {
    if (_withdrawInfo.data) setWithdrawInfo(_withdrawInfo.data)
  }, [_withdrawInfo.data]);

  const [profitInfo, setProfitInfo] = useState<{ [propName: string]: number }>({});
  const _profitInfo = GetReportProfitInfo({ DateType: Date[2], SAgentId });
  useEffect(() => {
    if (_profitInfo.data) setProfitInfo(_profitInfo.data)
  }, [_profitInfo.data]);

  // 排行區

  const [profitGame, setProfitGame] = useState<RankProvider[]>([]);
  const _profitGame = GetReportProfitGame({ DateType: Date[2], SAgentId });
  useEffect(() => {
    if (_profitGame.data) {
      const data = _profitGame.data.constructor === Array ? _profitGame.data : []
      const ary = data.map((item: any, i: number) => ({
        key: i,
        rank: i + 1,
        provider: item.ProviderName,
        amount: $f(item.TotalWinLossAmount),
      }))
      setProfitGame(ary)
    }
  }, [_profitGame.data]);

  const [deficitGame, setDeficitGame] = useState<RankProvider[]>([]);
  const _deficitGame = GetReportDeficitGame({ DateType: Date[2], SAgentId });
  useEffect(() => {
    if (_deficitGame.data) {
      const data = _deficitGame.data.constructor === Array ? _deficitGame.data : []
      const ary = data.map((item: any, i: number) => ({
        key: i,
        rank: i + 1,
        provider: item.ProviderName,
        amount: $f(item.TotalWinLossAmount * -1),
      }))
      setDeficitGame(ary)
    }
  }, [_deficitGame.data]);

  const [profitMember, setProfitMember] = useState<RankAccount[]>([]);
  const _profitMember = GetReportProfitMember({ DateType: Date[2], SAgentId });
  useEffect(() => {
    if (_profitMember.data) {
      const data = _profitMember.data.constructor === Array ? _profitMember.data : []
      const ary = data.map((item: any, i: number) => ({
        key: i,
        rank: i + 1,
        account: item.MemberAccount,
        recommendAccount: item.UpMemberAccount,
        agent: item.SAAccount,
        sales: item.AGAccount,
        amount: $f(item.TotalWinLossAmount),
      }))
      setProfitMember(ary)
    }
  }, [_profitMember.data]);

  // 虧損會員
  const [deficitMember, setDeficitMember] = useState<RankAccount[]>([]);
  const _deficitMember = GetReportDeficitMember({ DateType: Date[2], SAgentId });
  useEffect(() => {
    if (_deficitMember.data) {
      const data = _deficitMember.data.constructor === Array ? _deficitMember.data : []
      const ary = data.map((item: any, i: number) => ({
        key: i,
        rank: i + 1,
        account: item.MemberAccount,
        recommendAccount: item.UpMemberAccount,
        agent: item.SAAccount,
        sales: item.AGAccount,
        amount: $f(item.TotalWinLossAmount * -1),
      }))
      setDeficitMember(ary)
    }
  }, [_deficitMember.data]);

  // 列表標題
  const rankProfitProvider: ColumnsType<RankProvider> = [
    // {
    //   key: 1,
    //   title: i18n.t('profit'),
    //   dataIndex: 'rank',
    //   className: 'size-12',
    //   sorter: (a, b) => a.rank - b.rank,
    //   defaultSortOrder: "ascend",
    //   width: 80
    // },
    {
      key: 2,
      title: i18n.t('gameProviderCategory'),
      dataIndex: "provider",
      className: 'size-12',
    },
    {
      key: 3,
      title: i18n.t('profitAmount'),
      dataIndex: "amount",
      className: 'size-12',
      align: 'right',
      render: (val) => <span className={common.numColor01(val)}>{val}</span>
    },
  ]
  const rankLossProvider: ColumnsType<RankProvider> = [
    // {
    //   key: 1,
    //   title: i18n.t('loss'),
    //   dataIndex: 'rank',
    //   className: 'size-12',
    //   sorter: (a, b) => a.rank - b.rank,
    //   defaultSortOrder: "ascend",
    //   width: 80
    // },
    {
      key: 2,
      title: i18n.t('gameProviderCategory'),
      dataIndex: "provider",
      className: 'size-12',
    },
    {
      key: 3,
      title: i18n.t('lossAmount'),
      dataIndex: "amount",
      className: 'size-12',
      align: 'right',
      render: (val) => <span className={common.numColor01(val)}>{val}</span>
    },
  ]
  const rankProfitAccount: ColumnsType<RankAccount> = [
    {
      key: 1,
      title: `${i18n.t('account')}`,
      // title: `${i18n.t('ranking')}／${i18n.t('account')}`,
      dataIndex: "account",
      className: 'size-12',
      width: '30%',
      // sorter: (a, b) => a.rank - b.rank,
      defaultSortOrder: "ascend",
      render: (_, { account }) => <Link to={`/member/${account}`}>{account}</Link>
    },
    {
      key: 2,
      title: `${i18n.t('recommendedMembers')}／${i18n.t('agent')}／${i18n.t('generalAgent')}`,
      className: 'size-12',
      width: '40%',
      render: (_, record) => (
        <span> {record.UpMemberAccount || '-'}／{record.agent}／{record.sales}</span>
      )
    },
    {
      key: 3,
      title: i18n.t('profitAmount'),
      dataIndex: "amount",
      className: 'size-12',
      width: '30%',
      align: 'right',
      render: (_, record) => (
        <span className={common.numColor01(_)}>{_}</span>
      )
    },
  ]
  const rankLossAccount: ColumnsType<RankAccount> = [
    {
      key: 1,
      title: `${i18n.t('account')}`,
      // title: `${i18n.t('ranking')}／${i18n.t('account')}`,
      dataIndex: "account",
      className: 'size-12',
      width: '25%',
      // sorter: (a, b) => a.rank - b.rank,
      defaultSortOrder: "ascend",
      render: (_, { account }) => <Link to={`/member/${account}`}>{account}</Link>
    },
    {
      key: 2,
      title: `${i18n.t('recommendedMembers')}／${i18n.t('agent')}／${i18n.t('generalAgent')}`,
      className: 'size-12',
      width: '45%',
      render: (_, record) => (
        <span>{record.UpMemberAccount || '-'}／{record.agent}／{record.sales}</span>
      )
    },
    {
      key: 3,
      title: i18n.t('lossAmount'),
      dataIndex: "amount",
      className: 'size-12',
      width: '30%',
      align: 'right',
      render: (_, record) => (
        <span className={common.numColor01(_)}>{_}</span>
      )
    },
  ]

  // 圖表設定
  const [chartOptions, setChartOptions] = useState<any>({
    responsive: true,
    scales: {
      y: {
        ticks: {
          stepSize: 1
        }
      }
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
    },
  });
  // 取得圖表資訊
  const [reportActivityList, setReportActivityList] = useState<TableAttributes>({});
  const { data: _reportActivityList } = GetReportActivityList({
    SAgentId,
    StartDate: Date[0],
    EndDate: Date[1],
  });
  useEffect(() => {
    if (_reportActivityList && _reportActivityList.Data) {
      const data = _reportActivityList.Data.map((item: any, i: number) => {
        const _date = item.days.split('T')
        const _days = _date[0].split('-')

        return {
          labels: `${_days[1]}-${_days[2]}`,
          data1: item.NewMemberCount,
          data2: item.ActiveMemberCount,
          data3: item.TotalMemberCount,
          data4: item.TotalWinLossAmount,
        };
      })
      setReportActivityList({
        dataSource: data,
        total: _reportActivityList.TotalRecord
      })
    } else {
      setReportActivityList({
        dataSource: [],
        total: 0
      })
    }
  }, [_reportActivityList]);
  // 左圖表
  const [barData, setBarData] = useState<any>({});
  useEffect(() => {
    setBarData({
      labels: reportActivityList.dataSource?.map(a => a.labels),
      datasets: [
        {
          label: i18n.t('newUsers'),
          data: reportActivityList.dataSource?.map(a => a.data1),
          backgroundColor: '#AFC8EA',
        },
        {
          label: i18n.t('activeMembers'),
          data: reportActivityList.dataSource?.map(a => a.data2),
          backgroundColor: '#CBEAD9',
        },
        {
          label: i18n.t('bettingMembers'),
          data: reportActivityList.dataSource?.map(a => a.data3),
          backgroundColor: '#E79F9C',
        },
      ],
    });
  }, [reportActivityList]);
  // 遊戲盈利
  const [lineData, setLineData] = useState<any>({});
  useEffect(() => {
    setLineData({
      labels: reportActivityList.dataSource?.map(a => a.labels),
      datasets: [
        {
          label: i18n.t('gameProfit'),
          data: reportActivityList.dataSource?.map(a => a.data4),
          borderColor: '#4874AD',
          backgroundColor: '#4874AD',
        },
      ],
    });
  }, [reportActivityList.dataSource]);

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Row align="middle" gutter={[0, 16]}>
          {/* 帳號與日期選擇 */}
          <Col span={24}>
            <Row align="middle" justify="space-between">
              <Col>
                <Row gutter={[10, 10]} align="middle">
                  <Col>
                    <Select
                      showSearch
                      className="w-12"
                      placeholder={`${i18n.t('agent')}`}
                      options={[
                        { value: '', label: `${i18n.t('all')}` },
                        ...((agentList && agentList.Data) || []).map((item: any) => (
                          { value: item.Id, label: item.Account }
                        ))
                      ]}
                      onChange={handleSAgentIdChange}
                    />
                  </Col>
                  {/* 時間區間 */}
                  <Col>
                    <LayoutDateSelect 
                      dateTypeDefault={(_dateType || DATE_TYPE.本月)}
                      dateFormatDefault={DATE_FORMAT.日期時間} 
                      hiddenDateType={[DATE_TYPE.上週]} 
                      hidden="dateRange"
                      onChange={handleDateChange}
                      />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row justify="end" align="middle">
                  <Button style={{ background: '#F7AE4F' }} className="color-01 mr-1"
                    onClick={() => navigate('/report/game')}>
                    {i18n.t('gameStatisticalAnalysis')}
                  </Button>
                  <Button style={{ background: '#4FCAB4' }} className="color-01 mr-1"
                    onClick={() => navigate('/report/business')}>
                    {i18n.t('operationReports')}
                  </Button>
                </Row>
              </Col>
            </Row>
          </Col>
          {/* 在線人數 */}
          <Col span={24}>
            <Row align="middle">
              <Col className="size-16">{i18n.t('dataOverview')}</Col>
              <Spin spinning={isValidating}>
                <Col className="online-num center">{i18n.t('onlineMembers')} {
                  OnlineMembers ? $f(OnlineMembers.Member, 0) : 0
                }</Col>
              </Spin>
            </Row>
          </Col>
          {/* 第一排 */}
          <Col span={24}>
            <Row align="middle" gutter={12} justify="space-between">
              {/* 新用戶數 */}
              <Col span={10}>
                <Card className="card-shadow" loading={isLoading}>
                  <Row align="middle" justify="space-around">
                    <Col><Image src={icon.icon04} preview={false} /></Col>
                    <Col>
                      {/* 新用戶數 */}
                      <Space direction="vertical" align="center">
                        <div className="size-14 color-03">{i18n.t('newUsers')}</div>
                        <div className="size-28 color-04 font-w-md">{$f(newUsers.NewMemberCount, 0)}</div>
                      </Space>
                    </Col>
                    <Divider className="h-3" type="vertical" />
                    <Col>
                      {/* 活躍人數 */}
                      <Space direction="vertical" align="center">
                        <div className="size-14 color-03">
                          <Row align="middle">
                            <Col>{i18n.t('activeMembers')}</Col>
                            <Col>
                              <Tooltip placement="top" title={i18n.t('membersWithBets')}>
                                <InfoCircleFilled style={{ marginLeft: 2 }} />
                              </Tooltip>
                            </Col>
                          </Row>
                        </div>
                        <div className="size-28 color-04 font-w-md">{$f(newUsers.ActiveMemberCount, 0)}</div>
                      </Space>
                    </Col>
                  </Row>
                </Card>
              </Col>
              {/* 首存金額 */}
              <Col span={14}>
                <Card className="card-shadow" loading={isLoading}>
                  <Row align="middle" justify="space-around">
                    <Col><Image src={icon.icon05} preview={false} /></Col>
                    <Col>
                      <Space direction="vertical" align="center">
                        <div className="size-14 color-03">{i18n.t('firstDepositAmount')}</div>
                        <div className="size-28 color-04 font-w-md">{$f(firstDeposit.TotalFirstDepositAmount)}</div>
                      </Space>
                    </Col>
                    <Divider className="h-3" type="vertical" />
                    <Col>
                      <Space direction="vertical" align="center">
                        <div className="size-14 color-03">{i18n.t('firstDepositMembers')}</div>
                        <div className="size-28 color-04 font-w-md">{$f(firstDeposit.TotalFirstTimeDepositMemberCount, 0)}</div>
                      </Space>
                    </Col>
                    <Divider className="h-3" type="vertical" />
                    <Col>
                      <Space direction="vertical" align="center">
                        <div className="size-14 color-03">{i18n.t('redepositMembers')}</div>
                        <div className="size-28 color-04 font-w-md">{$f(firstDeposit.TotalSecondTimeDepositMemberCount, 0)}</div>
                      </Space>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
          {/* 彩卡區 */}
          <Col span={24}>
            <Row align="middle" justify="space-between" gutter={16}>
              {/* 彩卡區 */}
              <Col span={16}>
                <Row align="middle" gutter={[0, 12]}>
                  {/* 上排 */}
                  <Col span={24}>
                    <Row align="middle" gutter={12}>
                      {/* 總存款金額 */}
                      <Col span={8}>
                        <Card className="card-shadow card-bg-01" loading={isLoading}
                          bodyStyle={{ minHeight: 150, maxHeight: 150 }}>
                          <Row className="color-01" justify="space-between" align="middle">
                            <Col span={8}><Image src={icon.icon06} preview={false} /></Col>
                            <Col span={16} className="text-end">
                              <Row justify="end">
                                <Col span={24}>
                                  <Row align="middle" gutter={5} justify="end">
                                    <Col className="size-14 font-w-md">{i18n.t('totalDepositAmount')}</Col>
                                  </Row>
                                </Col>
                                <Col span={24}>
                                  <Row align="middle" justify="end">
                                    <Col span={24} className="size-22">{$f(totalDeposit.TotalDepositAmount)}</Col>
                                  </Row>
                                </Col>
                                <Col span={24} className="mt-1">
                                  <Space direction="horizontal" className="size-12" size={2}>
                                    <div>{$f(totalDeposit.TotalMemberCount, 0)} {i18n.t('people')}｜</div>
                                    <div>{$f(totalDeposit.TotalDepositRecordsCount, 0)} {i18n.t('counts')}</div>
                                  </Space>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      {/* 優惠金額 */}
                      <Col span={8}>
                        <Card className="card-shadow card-bg-02" loading={isLoading}
                          bodyStyle={{ minHeight: 150, maxHeight: 150 }}>
                          <Row className="color-01" justify="space-between" align="middle">
                            <Col span={8}><Image src={icon.icon14} preview={false} /></Col>
                            <Col span={16} className="text-end">
                              <Row justify="end">
                                <Col span={24}>
                                  <Row align="middle" gutter={5} justify="end">
                                    <Col className="size-14 font-w-md">{i18n.t('promotionAmount')} /</Col>
                                    <Col className="size-14 font-w-md">{i18n.t('adjustmentPromotion')}</Col>
                                  </Row>
                                </Col>
                                <Col span={24}>
                                  <Row align="middle" justify="end">
                                    <Col span={24} className="size-18">{$f(bonusAmount.TotalBonusAmount)} /</Col>
                                    <Col span={24} className="size-18" style={{ paddingRight: '0.6rem' }}>
                                      {$f(bonusAmount.TotalBonusAdjustAmount)}
                                    </Col>
                                  </Row>
                                </Col>
                                <Col span={24} className="size-12">
                                  <Row align="middle" justify="end">
                                    <Col className="size-12 text-end">
                                      <div>
                                        <span>{$f(bonusAmount.TotalBonusMemberCounts, 0)} {i18n.t('people')}｜</span>
                                        <span>{$f(bonusAmount.TotalBonusRecords, 0)} {i18n.t('counts')}／</span>
                                      </div>
                                    </Col>
                                    <Col className="size-12 text-end">
                                      <div>
                                        <span>{$f(bonusAmount.TotalBonusAdjustMemberCounts, 0)} {i18n.t('people')}｜</span>
                                        <span>{$f(bonusAmount.TotalBonusAdjustRecords, 0)} {i18n.t('counts')}</span>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      {/* 調帳添加*/}
                      <Col span={8}>
                        <Card className="card-shadow card-bg-03" bodyStyle={{ minHeight: 150, maxHeight: 150 }} loading={isLoading}>
                          <Row className="color-01" justify="space-between" align="middle">
                            <Col span={8}><Image src={icon.icon15} preview={false} /></Col>
                            <Col span={16} className="text-end">
                              <Row justify="end">
                                <Col span={24}>
                                  <Row align="middle" gutter={5} justify="end">
                                    <Col className="size-14 font-w-md">{i18n.t('adjustmentAccounting')}</Col>
                                  </Row>
                                </Col>
                                <Col span={24}>
                                  <Row align="middle" justify="end">
                                    <Col span={24} className="size-22">{$f(depositInfo.TotalAdjustPlusAmount)}</Col>
                                  </Row>
                                </Col>
                                <Col span={24} className="mt-1">
                                  <Space direction="horizontal" className="size-12" size={2}>
                                    <div>{$f(depositInfo.TotalAdjustPlusMemberCount, 0)} {i18n.t('people')}｜</div>
                                    <div>{$f(depositInfo.TotalAdjustPlusRecordsCount, 0)} {i18n.t('counts')}</div>
                                  </Space>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  {/* 下排 */}
                  <Col span={24}>
                    <Row align="middle" justify="space-around" gutter={12}>
                      {/* 總出款金額 */}
                      <Col span={8}>
                        <Card className="card-shadow card-bg-04" loading={isLoading}
                          bodyStyle={{ minHeight: 150, maxHeight: 150 }}>
                          <Row className="color-01" align="middle">
                            <Col span={8}><Image src={icon.icon16} preview={false} /></Col>
                            <Col span={16} className="text-end">
                              <Row justify="end">
                                <Col span={24}>
                                  <Row align="middle" gutter={5} justify="end">
                                    <Col className="size-14 font-w-md">{i18n.t('totalWithdrawalAmount')}</Col>
                                  </Row>
                                </Col>
                                <Col span={24}>
                                  <Row align="middle" justify="end">
                                    <Col span={24} className="size-22">{$f(withdrawAmount.TotalWithdrawAmount)}</Col>
                                  </Row>
                                </Col>
                                <Col span={24} className="mt-1">
                                  <Space direction="horizontal" className="size-12" size={2}>
                                    <div>{$f(withdrawAmount.TotalMemberCount, 0)} {i18n.t('people')}｜</div>
                                    <div>{$f(withdrawAmount.TotalWithdrawRecordsCount, 0)} {i18n.t('counts')}</div>
                                  </Space>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      {/* 總出款手續費 */}
                      <Col span={8}>
                        <Card className="card-shadow card-bg-05" loading={isLoading}
                          bodyStyle={{ minHeight: 150, maxHeight: 150 }}>
                          <Row className="color-01" justify="space-between" align="middle">
                            <Col span={8}><Image src={icon.icon17} preview={false} /></Col>
                            <Col span={16} className="text-end">
                              <Row justify="end">
                                <Col span={24}>
                                  <Row align="middle" gutter={5} justify="end">
                                    <Col className="size-14 font-w-md">{i18n.t('totalWithdrawalFee')}</Col>
                                  </Row>
                                </Col>
                                <Col span={24}>
                                  <Row align="middle" justify="end">
                                    <Col span={24} className="size-22">{$f(withdrawAmount.TotalWithdrawHandlingFeeAmount)}</Col>
                                  </Row>
                                </Col>
                                <Col span={24} className="mt-1">
                                  <Space direction="horizontal" className="size-12" size={4}>
                                    <div>{$f(withdrawAmount.TotalMemberCount, 0)} {i18n.t('people')}｜</div>
                                    <div>{$f(withdrawAmount.TotalWithdrawRecordsCount, 0)} {i18n.t('counts')}</div>
                                  </Space>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      {/* 調帳扣除 */}
                      <Col span={8}>
                        <Card className="card-shadow card-bg-06" loading={isLoading}
                          bodyStyle={{ minHeight: 150, maxHeight: 150 }}>
                          <Row className="color-01" justify="space-between" align="middle">
                            <Col span={8}><Image src={icon.icon18} preview={false} /></Col>
                            <Col span={16} className="text-end">
                              <Row justify="end">
                                <Col span={24}>
                                  <Row align="middle" gutter={5} justify="end">
                                    <Col className="size-14 font-w-md">{i18n.t('adjustmentDeduction')}</Col>
                                  </Row>
                                </Col>
                                <Col span={24}>
                                  <Row align="middle" justify="end">
                                    <Col span={24} className="size-22">{$f(withdrawInfo.TotalAdjustMinusAmount)}</Col>
                                  </Row>
                                </Col>
                                <Col span={24} className="mt-1">
                                  <Space direction="horizontal" className="size-12" size={2}>
                                    <div>{$f(withdrawInfo.TotalMemberCount, 0)} {i18n.t('people')}｜</div>
                                    <div>{$f(withdrawInfo.TotalAdjustMinusRecordsCount, 0)} {i18n.t('counts')}</div>
                                  </Space>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              {/* 遊戲盈利 */}
              <Col span={8}>
                <Card className="card-shadow" loading={isLoading}
                  bodyStyle={{ paddingLeft: 5, paddingRight: 5, paddingTop: 1, paddingBottom: 1 }}>
                  <Row>
                    <Col span={24} className="pr-1 pl-1 pt-1">
                      <Row align="middle" justify="space-between">
                        <Col><Image src={icon.icon07} preview={false} /></Col>
                        <Col className="text-end">
                          <Space direction="vertical">
                            <div className="size-14 color-03">{i18n.t('gameProfit')}</div>
                            <div className="size-30 font-w-md color-04">{$f(profitInfo.TotalWinLossAmount)}</div>
                          </Space>
                        </Col>
                      </Row>
                    </Col>
                    <Divider style={{ marginTop: 14, marginBottom: 14 }} />
                    <Col span={24} className="pr-1 pl-1 pb-1">
                      <Row>
                        <Col span={24}>
                          <Row align="middle" justify="space-between">
                            <Col className="size-14 color-03">{i18n.t('bettingMembers')}</Col>
                            <Col className="size-22 font-w-sm color-04">{$f(profitInfo.TotalMemberCount, 0)}</Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row align="middle" justify="space-between">
                            <Col className="size-14 color-03">{i18n.t('totalBets')}</Col>
                            <Col className="size-22 font-w-sm color-04">{$f(profitInfo.TotalBetCount, 0)}</Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row align="middle" justify="space-between">
                            <Col className="size-14 color-03">{i18n.t('totalBetAmount')}</Col>
                            <Col className="size-22 font-w-sm color-04">{$f(profitInfo.TotalBetAmount)}</Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row align="middle" justify="space-between">
                            <Col className="size-14 color-03">{i18n.t('validBet')}</Col>
                            <Col className="size-22 font-w-sm color-04">{$f(profitInfo.TotalValidBetAmount)}</Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row align="middle" justify="space-between">
                            <Col className="size-14 color-03">{i18n.t('totalPayout')}</Col>
                            <Col className="size-22 font-w-sm color-04">{$f(profitInfo.TotalSettleAmount)}</Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
          {/* 數據圖表 */}
          <Col span={24}>
            <Row gutter={12}>
              <Col span={24}>
                <Card className="card-shadow" loading={isLoading}>
                  <div>
                    <Row>
                      <Col span={12}><Bar options={chartOptions} data={barData} /></Col>
                      <Col span={12}><Line options={chartOptions} data={lineData} /></Col>
                    </Row>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
          {/* 排行 */}
          <Col span={24}>
            <Row align="top" justify="space-between" gutter={12}>
              {/* 盈利遊戲: 資料庫是會員角度 故站長盈虧串api故意對調 */}
              <Col span={6}>
                <Card className="card-shadow" loading={isLoading}
                  title={
                    <Row align="middle" gutter={10}>
                      <Col><Image src={icon.icon08} preview={false} /></Col>
                      <Col className="size-16">{i18n.t('profitableGames')}</Col>
                    </Row>
                  }
                  headStyle={{ background: '#FF5252', color: '#fff' }}
                  bodyStyle={{ padding: 0 }}>
                  <Table
                    pagination={false}
                    columns={rankProfitProvider}
                    dataSource={deficitGame}
                    scroll={{ y: 270 }}
                  />
                </Card>
              </Col>
              {/* 虧損遊戲: 資料庫是會員角度 故站長盈虧串api故意對調 */}
              <Col span={6}>
                <Card className="card-shadow" loading={isLoading}
                  title={
                    <Row align="middle" gutter={10}>
                      <Col><Image src={icon.icon09} preview={false} /></Col>
                      <Col className="size-16">{i18n.t('losingGames')}</Col>
                    </Row>
                  }
                  headStyle={{ background: '#00DA8B', color: '#fff' }}
                  bodyStyle={{ padding: 0 }}>
                  <Table
                    pagination={false}
                    columns={rankLossProvider}
                    dataSource={profitGame}
                    scroll={{ y: 270 }}
                  />
                </Card>
              </Col>
              {/* 盈利會員 */}
              <Col span={6}>
                <Card className="card-shadow" loading={isLoading}
                  title={
                    <Row align="middle" gutter={10}>
                      <Col><Image src={icon.icon08} preview={false} /></Col>
                      <Col className="size-16">{i18n.t('profitableMembersTop20')}</Col>
                    </Row>
                  }
                  headStyle={{ background: '#FF5252', color: '#fff' }}
                  bodyStyle={{ padding: 0 }}>
                  <Table
                    pagination={false}
                    columns={rankProfitAccount}
                    dataSource={profitMember}
                    scroll={{ y: 270 }}
                  />
                </Card>
              </Col>
              {/* 虧損會員 */}
              <Col span={6}>
                <Card className="card-shadow" loading={isLoading}
                  title={
                    <Row align="middle" gutter={10}>
                      <Col><Image src={icon.icon09} preview={false} /></Col>
                      <Col className="size-16">{i18n.t('losingMembersTop20')}</Col>
                    </Row>
                  }
                  headStyle={{ background: '#00DA8B', color: '#fff' }}
                  bodyStyle={{ padding: 0 }}>
                  <Table
                    pagination={false}
                    columns={rankLossAccount}
                    dataSource={deficitMember}
                    scroll={{ y: 270 }}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </div >
  );
};

export default PageMain;
