import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Table, Tooltip } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { LayoutDateSelect, LayoutNav } from 'components/layout.component';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TYPE } from 'enum/date';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GetAgentOne, GetAgentReportCommissionList, GetAgentReportRevenueshareList } from 'services/agent.service';
import * as common from 'utils/common';
import { toFormatNumber as $f } from 'utils/common';

// 每日報表

interface DataType01 {
  AgentAccount: string;
  AgentLevel: number;
  CategoryName: string;
  ValidBetAmount: number;
  WinLoseAmount: number;
  Commission: number;
  DownlineCommission: number;
  RevenueShare: number;
  DownlineRevenueShare: number;
  AgentBonusCostAmount: number;
  AgentCashFlowFeeAmount: number;
  DownlineAgentBonusCostAmount: number;
  DownlineAgentCashFlowFeeAmount: number;
  DownlineProfits: number;
  AgentProfits: number;
  AgentCostAmount: number;
  DownlineCostAmount: number;
  TotalCostAmount: number;
  Detail?: DataType01[]
}

interface DataType02 {
  AgentId: number;
  AgentAccount: string;
  AgentLevel: number;
  CategoryName: string;
  ValidBetAmount: number;
  WinLoseAmount: number;
  Commission: number;
  DownlineCommission: number;
  RevenueShare: number;
  DirectMemberCommission: number;
  DownlineRevenueShare: number;
  Detail: DataType02[]
}

interface SearchType {
  AgentId?: number | string;
  StartDate: string;
  EndDate: string;
}

enum TYPE {
  占成報表 = 1,
  返水報表 = 2
}

const PageMain: React.FC = () => {

  const { id } = useParams();
  const [tableType, setTableType] = useState(TYPE.占成報表);

  const [form] = useForm();
  const [date, setDate] = useState<string[]>();
  const [reset, setReset] = useState(false);

  const [params, setParams] = useState<SearchType>({
    StartDate: dayjs().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
    EndDate: dayjs().endOf('week').format('YYYY-MM-DD HH:mm:ss'),
    AgentId: id
  });
  const { data: revenueshareList, mutate: mutateRevenueshare, isValidating: revenueshareValidating } = GetAgentReportRevenueshareList(params);
  const { data: commissionList, mutate: mutateCommission, isValidating: commissionValidating } = GetAgentReportCommissionList(params);
  const [account, setAccount] = useState('');

  const { data: agentInfo } = GetAgentOne({ Id: id }, !!id);
  useEffect(() => {
    if (agentInfo && id) {
      form.setFieldsValue({
        agentAccount: agentInfo.Account
      })
      setAccount(agentInfo.Account)
    }
  }, [agentInfo])

  const search = (formData: any) => {
    if (date) {
      setParams({
        ...params,
        StartDate: `${date[0]}`,
        EndDate: `${date[1]}`
      })
      mutateRevenueshare()
      mutateCommission()
    }
  }

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
  }
  return (
    <div id="container">
      <LayoutNav account={account} />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={search} initialValues={{ 
          type: TYPE.占成報表
        }}>
          <Row gutter={[16, 16]} className="pb-1">
            <Col span={3}>
              <Form.Item name="agentAccount">
                <Input placeholder={`${i18n.t('masterAgentOrAgentAccount')}`} disabled />
              </Form.Item>
            </Col>
            <Tooltip placement="top" title={i18n.t('onlyCalculateAccountingDataUpToYesterday235959')}>
              <InfoCircleFilled />
            </Tooltip>
            <Col span={3}>
              <Form.Item name="type">
                <Select
                  className="w-full"
                  onChange={setTableType}
                  options={[
                    { value: 1, label: `${i18n.t('commissionRateReport')}` },
                    { value: 2, label: `${i18n.t('rebateReport')}` },
                  ]}
                />
              </Form.Item>
            </Col>
            <LayoutDateSelect onChange={setDate} dateFormatDefault={DATE_FORMAT.日期時間}
              dateTypeDefault={DATE_TYPE.本週} hiddenDateType={[DATE_TYPE.今天]} reset={reset} />
            <Col>
              <Button type="primary" htmlType="submit" className="mr-1">{i18n.t('search')}</Button>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
          {
            tableType === TYPE.占成報表 &&
            <Table
              loading={revenueshareValidating}
              scroll={{ x: 1500 }}
              size="middle"
              className="mt-1"
              dataSource={
                revenueshareList?.map((item: DataType01, i: number) => ({
                  ...item,
                  key: item.AgentAccount,
                  // children: item.Detail
                }))
              }
              columns={[
                {
                  dataIndex: 'AgentAccount',
                  title: i18n.t('account'),
                  fixed: 'left',
                  width: 200,
                  render: (val, { AgentLevel, CategoryName }) => {
                    const word = common.agentLevelWord(AgentLevel);
                    return CategoryName === 'ALL' 
                      ? 
                      <span style={{ margin: '2px 8px', display: 'inline-block' }}>
                        {word}／{val}
                      </span>
                      : ''
                  }
                },
                {
                  dataIndex: 'CategoryName',
                  title: i18n.t('gameCategory'),
                  fixed: 'left',
                  width: 120,
                  render: (val) => i18n.t(`${val}`)
                },
                {
                  dataIndex: 'ValidBetAmount',
                  title: i18n.t('validBet'),
                  align: 'right',
                  width: 150,
                  render: (val) => $f(val)
                },
                {
                  dataIndex: 'WinLoseAmount',
                  title: i18n.t('totalMemberWinLoss'),
                  align: 'right',
                  width: 150,
                  render: (val) => <div className={common.numColor01(val)}>{$f(val)}</div>
                },
                {
                  dataIndex: 'DirectMemberWinLoseAmount',
                  title: i18n.t('directMemberWinLoss'),
                  align: 'right',
                  width: 150,
                  render: (val) => <div className={common.numColor01(val)}>{$f(val)}</div>
                },
                {
                  dataIndex: 'TotalCost',
                  title: i18n.t('totalCost'),
                  align: 'right',
                  width: 150,
                  render: (val) => $f(val)
                },
                {
                  dataIndex: 'DownlineRevenueShare',
                  title: i18n.t('subordinateCommissionRate'),
                  align: 'right',
                  width: 150,
                  render: (val) => <div className={common.numColor01(val)}>{$f(val)}</div>
                },
                {
                  dataIndex: 'DownlineAgentCost',
                  title: i18n.t('subordinateCost'),
                  align: 'right',
                  width: 150,
                  render: (val) => $f(val)
                },
                {
                  dataIndex: 'DownlineAgentProfit',
                  title: i18n.t('subordinateProfit'),
                  align: 'right',
                  width: 150,
                  render: (val) => <div className={common.numColor01(val)}>{$f(val)}</div>
                },
                {
                  dataIndex: 'RevenueShare',
                  title: i18n.t('myCommissionRate'),
                  align: 'right',
                  width: 150,
                  render: (val) => <div className={common.numColor01(val)}>{$f(val)}</div>
                },
                {
                  dataIndex: 'AgentCost',
                  title: i18n.t('myCost'),
                  align: 'right',
                  width: 150,
                  render: (val) => $f(val)
                },
                {
                  dataIndex: 'AgentProfit',
                  fixed: 'right',
                  title:
                    <>
                      <Tooltip placement="top" title={i18n.t('myEarningsEqualsMyShareMinusMyCostPositiveAgentProfitNegativeAgentLoss')}>
                        <InfoCircleFilled />
                      </Tooltip>
                      <span style={{ paddingLeft: 3 }}>{i18n.t('myProfit')}</span>
                    </>
                  ,
                  align: 'right',
                  width: 150,
                  render: (val) => <div className={common.numColor05(val)}>{$f(val)}</div>
                },
              ]}
              pagination={false}
            />
          }
          {
            tableType === TYPE.返水報表 &&
            <Table
              loading={commissionValidating}
              scroll={{ x: 1500 }}
              size="middle"
              className="mt-1"
              dataSource={
                commissionList?.map((item: DataType02, i: number) => ({
                  ...item,
                  key: item.AgentAccount,
                  // children: item.Detail
                }))
              }
              columns={[
                {
                  dataIndex: 'AgentAccount',
                  title: i18n.t('account'),
                  fixed: 'left',
                  width: 200,
                  render: (val, { AgentLevel, CategoryName }) => {
                    const word = common.agentLevelWord(AgentLevel);
                    return CategoryName === 'ALL' 
                      ? 
                      <span style={{ margin: '2px 8px', display: 'inline-block' }}>
                        {word}／{val}
                      </span>
                      : ''
                  }
                },
                {
                  dataIndex: 'CategoryName',
                  title: i18n.t('gameCategory'),
                  fixed: 'left',
                  width: 120,
                  render: (val) => i18n.t(`${val}`)
                },
                {
                  dataIndex: 'ValidBetAmount',
                  title: i18n.t('totalEffectiveBets'),
                  align: 'right',
                  width: 300,
                  render: (val) => $f(val)
                },
                {
                  dataIndex: 'DirectMemberValidBetAmount',
                  title: i18n.t('directMemberEffectiveBets'),
                  align: 'right',
                  width: 150,
                  render: (val) => $f(val)
                },
                {
                  dataIndex: 'WinLoseAmount',
                  title: i18n.t('winLoss'),
                  align: 'right',
                  width: 300,
                  render: (val) => <div className={common.numColor01(val)}>{$f(val)}</div>
                },
                {
                  dataIndex: 'TotalCommission',
                  title: i18n.t('totalRebate'),
                  align: 'right',
                  width: 300,
                  render: (val) => $f(val)
                },
                {
                  dataIndex: 'DownlineCommission',
                  title: i18n.t('subordinateCashback'),
                  align: 'right',
                  width: 150,
                  render: (val) => $f(val)
                },
                {
                  dataIndex: 'DirectMemberCommission',
                  title: i18n.t('directMemberCashback'),
                  align: 'right',
                  width: 150,
                  render: (val) => $f(val)
                },
                {
                  dataIndex: 'Commission',
                  fixed: 'right',
                  title: i18n.t('myRebate'),
                  align: 'right',
                  width: 150,
                  render: (val) => <div className={common.numColor05(val)}>{$f(val)}</div>
                },
              ]}
              pagination={false}
            />
          }
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;