import {
  axiosGetAllData,
  axiosGetAuth,
  axiosPostAuth,
  baseURL,
  useFetchDataCommon
} from "services";

export const GetMemberlevelList = () => useFetchDataCommon(axiosGetAuth, `${baseURL}/memberlevel/list`);
export const CreateMemberlevel = (data: any) => axiosPostAuth(`${baseURL}/memberlevel/create`, data);
export const ModifyMemberlevel = (data: any) => axiosPostAuth(`${baseURL}/memberlevel/update`, data);
export const DeleteMemberlevel = (data: any) => axiosPostAuth(`${baseURL}/memberlevel/delete`, data);
export const GetMemberlevelRebate = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/memberlevel/reward/list`, params);
