import { Button, Col, Form, Row, Select, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { LayoutDateSelect, LayoutNav, LayoutPagination } from 'components/layout.component';
import { ViewMessageList } from 'components/message.component';
import dayjs, { Dayjs } from 'dayjs';
import { DATE_FORMAT, DATE_TYPE } from 'enum/date';
import { MESSAGE_STATE } from 'enum/state';
import i18n from 'i18n';
import React, { useState } from 'react';
import { GetMailList } from 'services/mail.service';
import { enumToOptions } from 'utils/common';

// 即時訊息

interface SearchParams {
  IsRead: number | string | null;
  StartDate: Dayjs | string | undefined;
  EndDate: Dayjs | string | undefined;
  PageSize?: number;
  PageIndex?: number;
}

const PageMain: React.FC = () => {
  const [form] = useForm();
  const [date, setDate] = useState<string[]>();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [reset, setReset] = useState(false);
  const [params, setParams] = useState<SearchParams>({
    IsRead: null,
    StartDate: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    EndDate: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  });
  const { data: messageData, isValidating, mutate } = GetMailList({
    ...params,
    PageSize: page[1],
    PageIndex: page[0]
  });

  const initialValues = {
    statusType: '',
  }
  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        IsRead: formData.statusType,
        StartDate: date[0],
        EndDate: date[1],
        PageSize: page[1],
        PageIndex: page[0]
      })
    }
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setPage([1, 10]);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="p-2">
        {/* 搜尋列 */}
        <Form form={form} onFinish={onFinish} initialValues={initialValues}>
          <Row align="middle" gutter={10}>
            <Col>
              <Form.Item className="w-12" name="statusType">
                <Select
                  placeholder={i18n.t('pleaseSelect')}
                  options={[
                    { value: "", label: `${i18n.t('all')}` },
                    ...enumToOptions(MESSAGE_STATE)
                  ]}
                />
              </Form.Item>
            </Col>
            <LayoutDateSelect onChange={setDate}
              dateFormatDefault={DATE_FORMAT.日期時間} hiddenDateType={[DATE_TYPE.上週]} reset={reset} />
            <Col>
              <Button htmlType="submit" type="primary" loading={isValidating}>{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
        </Form>
        {/* 搜尋結果 */}
        <Row className="mt-1">
          <Col span={24}>
            <Spin spinning={isValidating}>
              <ViewMessageList data={messageData} mutate={mutate} />
              <LayoutPagination total={messageData && messageData.TotalRecord} setPage={setPage} page={page} />
            </Spin>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default PageMain;