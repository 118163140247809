import { Button, Col, Image, Row, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { PopupDeleteCarouselModal, PopupFrontCarousel } from 'components/front.component';
import { LayoutNav } from 'components/layout.component';
import { ENABLE, SLIDE_TYPE } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { GetSlideList } from 'services/front.service';

// 輪播圖

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();
  
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [key, setKey] = useState<number | null>(null);
  const [data, setData] = useState<any>(null);
  const { data: CarouselList, isValidating, mutate } = GetSlideList();

  const stateColor = (state: number) => {
    switch (state) {
      case ENABLE.啟用:
        return 'color-pass'
      case ENABLE.停用:
        return 'color-reject'
    };
  };

  const onEdit = (record: any) => {
    setData(record);
    setIsOpenAddModal(true);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            {$p.includes('50201') && <Button type="primary" onClick={() => onEdit(null)}>{i18n.t('add')}</Button>}
          </Col>
          <Col span={24}>
            <Table
              loading={isValidating}
              size="middle"
              dataSource={CarouselList?.map((item: FrontCarousel) => ({ key: item.Id, ...item }))}
              columns={[
                {
                  dataIndex: 'Position',
                  title: i18n.t('sort'),
                  align: 'center',
                  width: 80
                },
                {
                  dataIndex: 'Category',
                  title: i18n.t('type'),
                  width: 200,
                  render: (val) => SLIDE_TYPE[val]
                },
                {
                  dataIndex: 'Photo_1',
                  title: i18n.t('image'),
                  render: (val) => <Image src={val} width={70} height={65} />
                },
                {
                  dataIndex: 'LinkTarget',
                  title: i18n.t('openLinkInNewWindow'),
                  width: 100,
                  render: (val) => val ? i18n.t('yes') : i18n.t('no')
                },
                {
                  dataIndex: 'LinkURL',
                  title: i18n.t('linkURL'),
                },
                {
                  dataIndex: 'Status',
                  title: i18n.t('status'),
                  width: 100,
                  render: (val) =>
                    <div className={stateColor(val)}>{ENABLE[val]}</div>
                },
                {
                  dataIndex: 'DisplayTime',
                  title: i18n.t('imageDisplayTime'),
                  width: 100,
                  render: (val) => <>{val} {i18n.t('second')}</>
                },
                {
                  dataIndex: 'CreateDate',
                  title: i18n.t('createTime'),
                  width: 160,
                },
                {
                  dataIndex: 'PublishDate',
                  title: i18n.t('publicationTime'),
                  width: 160,
                },
                {
                  title: i18n.t('operation'),
                  width: 160,
                  render: (_, record) => (
                    <>
                      {$p.includes('50203') && <Button type="link" onClick={() => {
                        onEdit(record)
                      }}>
                        {i18n.t('edit')}
                      </Button>}
                      {$p.includes('50204') && <Button type="link" onClick={() => {
                        setKey(record.key);
                        setIsOpenDeleteModal(true);
                      }}>
                        {i18n.t('delete')}
                      </Button>}
                    </>
                  )
                }
              ]}
              pagination={false}
            />
          </Col>
        </Row>
      </Content>
      <PopupFrontCarousel isOpen={isOpenAddModal} close={() => setIsOpenAddModal(false)} data={data} mutate={mutate} />
      <PopupDeleteCarouselModal isOpen={isOpenDeleteModal} close={() => setIsOpenDeleteModal(false)} id={key} mutate={mutate} />
    </div >
  );
};

export default PageMain;