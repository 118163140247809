import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { PopupAgentAddAdjust, ViewAdjustAgentList, ViewAdjustAgentOverview } from 'components/adjust.component';
import { LayoutDateSelect, LayoutNav, LayoutPagination } from 'components/layout.component';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TYPE } from 'enum/date';
import { AGENT_ADJUST_STATE, SEARCH_DATE_TYPE } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { AdjustAgentList } from 'services/adjustticket.service';
import { enumToOptions } from 'utils/common';

// 代理調帳

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();
  const [form] = useForm();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [reset, setReset] = useState<boolean>(false);
  const [date, setDate] = useState<string[]>();
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const defaultParams = {
    Code: null,
    AgentAccount: null,
    SAAccoung: null,
    Status: null,
  };

  const defaultDate = SEARCH_DATE_TYPE.建立時間;
  const [agentListParams, setAgentListParams] = useState<any>({
    CreateDateStartTime: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    CreateDateEndTime: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  })
  const { data: agentList, mutate, isValidating } = AdjustAgentList({
    ...agentListParams,
    PageIndex: page[0],
    PageSize: page[1],
  });

  const search = (formData: any) => {
    const timeMode = form.getFieldValue('TimeMode') || defaultDate;
    const type = timeMode === defaultDate ? 'Create' : 'Audited';
    setPage([1, page[1]]);
    setAgentListParams({
      Code: formData.Code,
      AgentAccount: formData.AgentAccount,
      SAAccoung: formData.SAAccoung,
      Status: formData.Status,
      [`${type}DateStartTime`]: date ? date[0] : dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      [`${type}DateEndTime`]: date ? date[1] : dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    })
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setPage([1, 10]);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="p-2">
        {/* 搜尋列 */}
        <Form form={form} onFinish={search} initialValues={{
          TimeMode: defaultDate,
        }}>
          <Row align="middle" gutter={16}>
            <Col className='w-12'>
              <Form.Item name="Code">
                <Input placeholder={`${i18n.t('orderNumber')}`} />
              </Form.Item>
            </Col>
            <Col className='w-12'>
              <Form.Item name="AgentAccount">
                <Input placeholder={`${i18n.t('agentAccount')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="SAAccoung">
                <Input placeholder={`${i18n.t('masterAgent')}`} />
              </Form.Item>
            </Col>
            <Col className='w-12'>
              <Form.Item name="Status">
                <Select
                  style={{ width: '100%' }}
                  placeholder={`${i18n.t('status')}`}
                  options={[
                    { value: '', label: i18n.t('all') },
                    ...enumToOptions(AGENT_ADJUST_STATE)
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="mt-1" align="middle" gutter={16}>
            <Col className='w-12' >
              <Form.Item name="TimeMode">
                <Select
                  className="w-full"
                  placeholder={i18n.t('pleaseSelect')}
                  options={[
                    { value: SEARCH_DATE_TYPE.建立時間, label: `${i18n.t('createTime')}` },
                    { value: SEARCH_DATE_TYPE.審核時間, label: `${i18n.t('auditTime')}` },
                  ]}
                />
              </Form.Item>
            </Col>
            <LayoutDateSelect onChange={setDate} width={370}
              dateFormatDefault={DATE_FORMAT.日期時間} hiddenDateType={[DATE_TYPE.上週]} reset={reset} />
            <Col>
              <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
        </Form>
        {/* 搜尋數據 */}
        <Row className="mt-1" gutter={[10, 16]}>
          {/* 總計 */}
          <Col span={24}>
            <Spin spinning={isValidating}>
              <ViewAdjustAgentOverview data={agentList} />
            </Spin>
          </Col>
          {/* 新增按鈕 */}
          {
            $p.includes('40703') &&
            <Col span={24}>
              <Button type="primary" onClick={() => setIsOpenAddModal(true)}>{i18n.t('add')}</Button>
            </Col>
          }
          {/* 列表 */}
          <Col span={24}>
            <Spin spinning={isValidating}>
              <ViewAdjustAgentList data={agentList} mutate={mutate} />
              <LayoutPagination total={agentList && agentList.TotalRecord} page={page} setPage={setPage} />
            </Spin>
          </Col>
        </Row>
        <PopupAgentAddAdjust isOpen={isOpenAddModal} close={() => setIsOpenAddModal(false)} mutate={mutate} />
      </Content>
    </div>
  );
};

export default PageMain;