import { Button, Col, Image, Modal, Row, Space, message } from 'antd';
import { RESPONSE_CODE_MEMBER_PROVIDER_STATUS } from 'constants/response';
import { ENABLE, RESPONSE_TYPE } from 'enum/state';
import useIcon from 'hooks/icon.hook';
import i18n from 'i18n';
import { useState } from 'react';
import { UpdateAllMemberProvider } from 'services/memberprovidersetting.service';

// 有的
export const PopupStatusModal = ({ isOpen, close, id, type, mutate }: any) => {
  const icon = useIcon();
  const [isLoading, setIsLoading] = useState(false);

  const onConfirm = async () => {
    let response;

      setIsLoading(true);
      switch (type) {
        case ENABLE.啟用:
          response = await UpdateAllMemberProvider({
            MemberId: id,
            Status: 1
          })
          break;
        case ENABLE.停用:
          response = await UpdateAllMemberProvider({
            MemberId: id,
            Status: 0
          })
          break;
      }
      if (response.State === RESPONSE_TYPE.成功) {
        message.success(i18n.t('operationSuccess'));
        setIsLoading(false);
        mutate();
        close();
      } else {
        message.error(RESPONSE_CODE_MEMBER_PROVIDER_STATUS[response.Message as keyof typeof RESPONSE_CODE_MEMBER_PROVIDER_STATUS]);
      }

  }

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={icon.icon12} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button className="mt-1" key="cancel" onClick={close}>{i18n.t('cancel')}</Button>
          <Button className="mt-1" key="confirm" type="primary" loading={isLoading} onClick={onConfirm}>{i18n.t('confirm')}</Button>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmUpdateBatch')}?</div>
        <div className="color-03">*{i18n.t('thisActionMayTakeSomeTime')}</div>
      </Space>
    </Modal>
  )
}