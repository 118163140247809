import { Button, Col, Form, Image, Input, Row, Select, Table, Tag } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { PopupBlockGameIpModal, PopupGameIpCommentModal } from 'components/ip.component';
import { CopyButton, LayoutDateSelect, LayoutNav, LayoutPagination } from 'components/layout.component';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TYPE } from 'enum/date';
import useIcon from 'hooks/icon.hook';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { BlockIpGameList } from 'services/game.service';
import * as common from 'utils/common';

// 遊戲IP檢核 > IP封鎖管理

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();
  const icon = useIcon();
  const [form] = useForm();
  const [date, setDate] = useState<string[]>();
  const [reset, setReset] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [isOpenCommentModal, setIsOpenCommentModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [comment, setComment] = useState<string>('');
  const [id, setId] = useState<number | null>(null);
  const [editData, setEditData] = useState<BlockIpGame>();
  const [params, setParams] = useState<any>({
    StartDate: date ? date[0] : dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    EndDate: date ? date[1] : dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  })
  const { data: GameIpList, isValidating, mutate } = BlockIpGameList({
    ...params,
    PageIndex: page[0],
    PageSize: page[1]
  })

  const onEditNote = (text: string, memberId: number) => {
    setComment(text);
    setId(memberId);
    setIsOpenCommentModal(true);
  };

  const onBlock = (record: BlockIpGame) => {
    setEditData(record);
    setIsOpenEditModal(true);
  };

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        ...params,
        IP: formData.ip,
        MemberAccount: formData.memberAccount,
        StartDate: date[0],
        EndDate: date[1]
      })
    }
    mutate();
  }

  const onClear = () => {
    setPage([1, 10]);
    form.resetFields();
    setReset(!reset);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Form form={form} onFinish={onFinish} initialValues={{
          TimeMode: 0,
        }}>
          <Row gutter={[12, 12]}>
            {/* 搜尋列 */}
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  <Form.Item name="memberAccount" >
                    <Input className="w-12" placeholder={`${i18n.t('memberAccount')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="ip">
                    <Input className="w-12" placeholder={`${i18n.t('IP')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="TimeMode" className="w-12">
                    <Select
                      disabled
                      className="w-full"
                      options={[
                        { value: 0, label: `${i18n.t('betTimes')}` },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <LayoutDateSelect width={395} onChange={setDate} dateFormatDefault={DATE_FORMAT.日期時間}
                  hiddenDateType={[DATE_TYPE.上週]} reset={reset} />
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            {/* 搜尋結果 */}
            <Col span={24}>
              <Table
                loading={isValidating}
                size="small"
                dataSource={
                  GameIpList ?
                    GameIpList.Data.map((item: BlockIpGame, index: number) => (
                      {
                        key: index,
                        account: item.Account,
                        recommendAccount: item.UpAccount,
                        agent: item.AgAccount,
                        GameTypeSummary: item.GameTypeSummary,
                        CreateDate: item.CreateDate,
                        RegisterIP: item.RegisterIP,
                        TotalCount: item.TotalCount,
                        LastLogDate: item.LastLogDate,
                        LastLogIP: item.LastLogIP,
                        LastLogCount: item.LastLogCount,
                        Remark: item.Remark,
                        MemberId: item.MemberId
                      }
                    )) : []
                }
                columns={[
                  {
                    title: i18n.t('memberAccount'),
                    dataIndex: 'account',
                    width: 200,
                    // fixed: 'left',
                    render: (_, { account }) => (
                      <Row align="middle">
                        <span className="size-12">{account}</span>
                        <span><CopyButton text={account} /></span>
                      </Row>
                    )
                  },
                  {
                    title: i18n.t('recommendedMembers'),
                    dataIndex: 'recommendAccount',
                    width: 200,
                  },
                  {
                    title: i18n.t('agent'),
                    dataIndex: 'agent',
                    width: 200,
                  },
                  {
                    title: `${i18n.t('gameProvider')} - ${i18n.t('gameCategory')}`,
                    width: 200,
                    ellipsis: true,
                    render: (_, record) => {
                      return (
                        <>
                          {
                            Object.keys(record.GameTypeSummary).map(key => (
                              <Row key={key}>
                                <Col span={4} className='size-12'>{key}</Col>
                                <Col span={6}>
                                  <Row>
                                    {
                                      record.GameTypeSummary[key].map((category: string, i: number) => (
                                        <Col key={i} span={24} className='size-12'>{i18n.t(category)}</Col>
                                      ))
                                    }
                                  </Row>
                                </Col>
                              </Row>
                            ))
                          }
                        </>
                      )
                    }
                  },
                  {
                    title: i18n.t('creationTimeIP'),
                    width: 200,
                    render: (_, record) => (
                      <>
                        <div>{record.CreateDate}</div>
                        <div>{record.RegisterIP}</div>
                      </>
                    )
                  },
                  {
                    title: i18n.t('duplicateIPCount'),
                    align: 'center',
                    width: 100,
                    render: (_, record) => (
                      <Tag className={`${common.numColor02(record.TotalCount)}`} >
                        {record.TotalCount}
                      </Tag>
                    )
                  },
                  {
                    title: i18n.t('lastLoginTimeIP'),
                    width: 200,
                    render: (_, record) => (
                      <>
                        <div>{record.LastLogDate}</div>
                        <div>{record.LastLogIP}</div>
                      </>
                    )
                  },
                  {
                    title: i18n.t('duplicateIPCount'),
                    align: 'center',
                    width: 100,
                    render: (_, record) => (
                      <Tag className={`${common.numColor02(record.LastLogCount)}`} >
                        {record.LastLogCount}
                      </Tag>
                    )
                  },
                  {
                    title: i18n.t('remark'),
                    ellipsis: true,
                    render: (_, record) => (
                      <>
                        <div>{record.Remark}</div>
                        {$p.includes('10103') && 
                        <Button
                          className="center"
                          size="small"
                          type="link"
                          onClick={() => onEditNote(record.Remark, record.MemberId)}
                        >
                          <Image className="center" src={icon.icon11} preview={false} />
                        </Button>}
                      </>
                    )
                  },
                  {
                    title: i18n.t('operation'),
                    align: 'center',
                    width: 130,
                    // fixed: 'right',
                    render: (_, record) => (
                      $p.includes('11302') &&
                      <Button type="link" onClick={() => onBlock(record as BlockIpGame)}>{i18n.t('block')}</Button>
                    )
                  },
                ]}
                pagination={false}
              />
              <LayoutPagination total={GameIpList ? GameIpList.TotalRecord : 0} setPage={setPage} page={page} />
            </Col>
          </Row>
        </Form>
      </Content>

      <PopupGameIpCommentModal isOpen={isOpenCommentModal} close={() => setIsOpenCommentModal(false)}
        commentHistory={comment} id={id} mutate={mutate} />
      <PopupBlockGameIpModal isOpen={isOpenEditModal} close={() => setIsOpenEditModal(false)} data={editData} mutate={mutate} />
    </div >
  );
};

export default PageMain;