import {
  axiosGetAuth,
  axiosPostAuth,
  baseURL,
  useFetchDataCommon
} from "services";

export const RebateSettingList = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/memberoperationparams/one`, params);
export const RebateSetting = (data: any) => axiosPostAuth(`${baseURL}/memberoperationparams/update`, data)
export const PostMemberoperationparamsStatusUpdate = (params: any) => axiosPostAuth(`${baseURL}/memberoperationparams/status/update`, params)
