import { Button, Col, Image, Row, Spin, Switch, Table, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { PopupContact, PopupDeleteContact } from 'components/contact.component';
import { LayoutNav, LayoutTabMember } from 'components/layout.component';
import { RESPONSE_CODE_MEMBER } from 'constants/response';
import { CONTACT_INFO, RESPONSE_TYPE, SOURCE_ROLE } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FetchMemberDetail, FetchcContactList, ModifyContact } from 'services/member.service';

// 會員列表 聯絡資訊

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();
  const { id } = useParams();
  const [activeId, setActiveId] = useState(0);
  const [activeContact, setActiveContact] = useState<any>(null);
  const [isOpenAddContactModal, setIsOpenAddContactModal] = useState(false);
  const [isOpenDeleteContactModal, setIsOpenDeleteContactModal] = useState(false);

  const { data, mutate, isValidating } = FetchcContactList({ MemberId: id, }, !!id);
  const { data: member } = FetchMemberDetail({ Id: id }, !!id);

  const handleClickAdd = () => {
    setActiveId(member.Id);
    setActiveContact(null);
    setIsOpenAddContactModal(true);
  }

  const handleClickEdit = (contact: any) => {
    setActiveContact(contact);
    setIsOpenAddContactModal(true);
  }

  const closePopup = () => {
    setIsOpenAddContactModal(false);
  }

  const handleClickDelete = (id: number) => {
    setActiveId(id);
    setIsOpenDeleteContactModal(true);
  }

  const handleChangeStatus = async (contact: Contact, checked: boolean) => {
    const apiResult: ApiResult = await ModifyContact({
      Id: contact.Id,
      SourceRole: contact.SourceRole,
      SourceAccount: contact.SourceAccount,
      ContactInfo: contact.ContactInfo,
      ContactContent: contact.ContactContent,
      Status: checked ? 1 : 0,
      Photo: contact.Photo ? [contact.Photo] : [],
    });
    if (apiResult.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('updateSuccess'));
      mutate();
    } else {
      message.error(RESPONSE_CODE_MEMBER[apiResult.Message as keyof typeof RESPONSE_CODE_MEMBER]);
    }
  }

  return (
    <div id="container">
      <LayoutNav id={member ? member.Id : 0} account={member ? member.Account : ''} />
      <Content className="pr-2 pl-2 pb-2">
        <LayoutTabMember activeKey="3" id={member ? member.Id : 0} />
        <Row>
          {
            $p.includes('10402') &&
            <Col span={24}>
              <Button type="primary" onClick={handleClickAdd}>{i18n.t('add')}</Button>
            </Col>
          }
          <Col span={24}>
            <Spin spinning={isValidating}>
              <Table
                className="mt-1"
                size="small"
                dataSource={data?.Data.map((item: Contact) => ({ ...item, key: item.Id }))}
                columns={[
                  {
                    dataIndex: 'SourceRole',
                    title: i18n.t('source'),
                    className: 'size-12',
                    width: 150,
                    render: (val) => SOURCE_ROLE[val]
                  },
                  {
                    dataIndex: 'SourceAccount',
                    title: i18n.t('account'),
                    className: 'size-12',
                    width: 200,
                  },
                  {
                    dataIndex: 'Status',
                    title: i18n.t('status'),
                    className: 'size-12',
                    width: 150,
                    render: (_: any, record: Contact) => (
                      <Switch
                        checked={!!record.Status}
                        onChange={(checked: boolean) => handleChangeStatus(record, checked)}
                        disabled={!$p.includes('10403')}
                      />
                    )
                  },
                  {
                    dataIndex: 'ContactInfo',
                    title: i18n.t('contactType'),
                    className: 'size-12',
                    width: 150,
                    render: (val) => CONTACT_INFO[val]
                  },
                  {
                    dataIndex: 'ContactContent',
                    title: i18n.t('content'),
                    className: 'size-12',
                  },
                  {
                    dataIndex: 'Photo',
                    title: i18n.t('image'),
                    className: 'size-12',
                    width: 320,
                    render: (val) => val && <Image src={val} height={40} />
                  },
                  {
                    title: i18n.t('operation'),
                    className: 'size-12',
                    width: 150,
                    render: (_: any, record: Contact) => (
                      <>
                        {
                          $p.includes('10403') &&
                          <Button className="size-12" type="link"
                            onClick={() => handleClickEdit(record)}>
                            {i18n.t('edit')}
                          </Button>
                        }
                        {
                          $p.includes('10404') &&
                          <Button className="size-12" type="link"
                            onClick={() => handleClickDelete(record.key)}>
                            {i18n.t('delete')}
                          </Button>
                        }
                      </>
                    )
                  },
                ]}
                pagination={false}
              />
            </Spin>
          </Col>
          <PopupContact isOpen={isOpenAddContactModal} close={closePopup} id={activeId} contact={activeContact} mutate={mutate} />
          <PopupDeleteContact isOpen={isOpenDeleteContactModal} close={() => setIsOpenDeleteContactModal(false)} id={activeId} mutate={mutate} />
        </Row>
      </Content>
    </div>
  );
};

export default PageMain;