import { Button, Col, Form, Input, Row, Select } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { LayoutDateSelect, LayoutNav, LayoutPagination } from 'components/layout.component';
import { MemberLevelSelect, ViewMemberList } from 'components/member.component';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'enum/date';
import { MEMBER_FREEZE, MEMBER_STATE } from 'enum/state';
import { StoreContext } from "hooks/store.hook";
import i18n from 'i18n';
import React, { useContext, useState } from 'react';
import { FetchMemberList } from 'services/member.service';
import { enumToOptions } from 'utils/common';

enum SEARCH_TYPE {
  phoneNumber = 1,
  email = 2,
  realName = 3,
  idNum = 4
}

const PageMain: React.FC = () => {
  const [form] = Form.useForm();
  const [date, setDate] = useState<string[]>();
  const [reset, setReset] = useState<boolean>(false);

  // 搜尋儲存
  const { useSearchMember } = useContext(StoreContext);
  const [searchMember, setSearchMember] = useSearchMember;

  const [memberSearchParam, SetMemberSearchParam] = useState<any>({
    Type: 0,
    LastLoginStartDate: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    LastLoginEndDate: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    PageIndex: 1,
    PageSize: 10,
  });
  const { data, mutate, isValidating } = FetchMemberList(searchMember || memberSearchParam);

  const handleSearch = (formData: any) => {
    const obj: any = {
      PageIndex: 1,
      PageSize: memberSearchParam.PageSize,
      Type: 0,
      Account: formData.account,
      RefCode: formData.referralCode,
      UPMemberAccount: formData.recommendedMembers,
      AGAccount: formData.agentAccount,
      SAAccount: formData.masterAgent,
      Status: formData.Status,
      IsFreeze: formData.IsFreeze ? (formData.IsFreeze === MEMBER_FREEZE.normal ? 0 : 1) : '',
      LevelId: formData.LevelId
    }
    switch (formData.searchType) {
      case 1:
        obj.PhoneNumber = formData.searchData;
        break;
      case 2:
        obj.Email = formData.searchData;
        break;
      case 3:
        obj.Name = formData.searchData;
        break;
      case 4:
        obj.IDNumber = formData.searchData;
        break;
    }

    switch (formData.TimeMode) {
      case 0:
        obj.LastLoginStartDate = date ? date[0] : dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss');
        obj.LastLoginEndDate = date ? date[1] : dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss');
        break;
      case 1:
        obj.CreateStartDate = date ? date[0] : dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss');
        obj.CreateEndDate = date ? date[1] : dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss');
        break;
    }

    SetMemberSearchParam(obj);
    setSearchMember(obj)
    mutate();
  };

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    SetMemberSearchParam({
      ...memberSearchParam,
      PageSize: 10,
      PageIndex: 1,
    })
  };

  const handlePageChange = ([current, pageSize]: any) => {
    const param = searchMember || memberSearchParam
    SetMemberSearchParam({ ...param, PageIndex: current, PageSize: pageSize })
    setSearchMember({ ...param, PageIndex: current, PageSize: pageSize })
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="pr-2 pl-2 pb-2">
        <Form form={form} onFinish={handleSearch} initialValues={{
          TimeMode: 0,
          searchType: 1
        }}>
          <Row align="middle" gutter={[12, 12]}>
            {/* 三排搜尋 */}
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col className="w-12">
                  <Form.Item name="account">
                    <Input placeholder={`${i18n.t('memberAccount')}`} />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="IsFreeze">
                    <Select
                      placeholder={i18n.t('status')}
                      options={[
                        { value: '', label: i18n.t('all') },
                        ...enumToOptions(MEMBER_FREEZE)
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="Status">
                    <Select
                      placeholder={i18n.t('audit')}
                      options={[
                        { value: '', label: i18n.t('all') },
                        ...enumToOptions(MEMBER_STATE)
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="LevelId">
                    <MemberLevelSelect name="LevelId" form={form} />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="searchType">
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      onSelect={() => form.resetFields(['searchData'])}
                      options={enumToOptions(SEARCH_TYPE)}
                    />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="searchData">
                    <Input placeholder={`${i18n.t('enter')}`} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col className="w-12">
                  <Form.Item name="referralCode">
                    <Input placeholder={`${i18n.t('referralCode')}`} />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="recommendedMembers">
                    <Input placeholder={`${i18n.t('recommendedMembers')}`} />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="agentAccount">
                    <Input placeholder={`${i18n.t('agentAccount')}`} />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="masterAgent">
                    <Input placeholder={`${i18n.t('masterAgent')}`} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col className="w-12">
                  <Form.Item name="TimeMode">
                    <Select
                      options={[
                        { value: 0, label: `${i18n.t('lastLoginTime')}` },
                        { value: 1, label: `${i18n.t('createTime')}` },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <LayoutDateSelect width={375} onChange={setDate} dateFormatDefault={DATE_FORMAT.日期時間} reset={reset} hidden="dateRadio" />
                <Col>
                  <Button type="primary" htmlType="submit" className="mr-1">{i18n.t('search')}</Button>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            <ViewMemberList data={data} mutate={mutate} searchParam={searchMember || memberSearchParam} isValidating={isValidating}
            />
          </Row>
          <LayoutPagination total={data?.TotalRecord} setPage={handlePageChange}
            page={[(searchMember || memberSearchParam).PageIndex, (searchMember || memberSearchParam).PageSize]} />
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;