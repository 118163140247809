import {
  axiosGetAllData,
  axiosGetAuth,
  baseURL,
  useFetchData,
  useFetchDataCommon
} from "services";

export const GetReportActivityMember = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/report/activity/member`, params);
export const GetReportFirstDeposit = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/report/first/deposit`, params);

export const GetReportTotalDepositAmount = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/report/total/deposit/amount`, params);
export const GetReportBonusAmount = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/report/bonus/amount`, params);
export const GetReportDepositInfo = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/report/deposit/info`, params);
export const GetReportTotalWithdrawAmount = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/report/total/withdraw/amount`, params);
export const GetReportTotalWithdrawHandingfee = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/report/total/withdraw/handingfee`, params);
export const GetReportWithdrawInfo = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/report/withdraw/info`, params);
export const GetReportProfitInfo = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/report/profit/info`, params);

// 首頁: 排行榜
export const GetReportProfitGame = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/report/profit/game`, params);
export const GetReportDeficitGame = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/report/deficit/game`, params);
export const GetReportProfitMember = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/report/profit/member`, params);
export const GetReportDeficitMember = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/report/deficit/member`, params);

export const FetchBetList = (params: any, allow: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/betticket/list`, params, allow);
export const FetchBetSummary = (params: any) => useFetchData(axiosGetAuth, `${baseURL}/betticket/count`, params, undefined, !!params);
export const FetchBetDetail = (betId: number) => useFetchData(axiosGetAuth, `${baseURL}/betticket/info`, { BetId: betId }, undefined, !!betId);
export const GetReportWinloss = (params: any, allow: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/report/winloss`, params, allow);
export const FetchReportBusiness = (params: any) => useFetchData(axiosGetAuth, `${baseURL}/report/business/list`, params, undefined, !!params);

// 首頁: 圖表二合一
export const GetReportActivityList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/report/activity/list`, params);

export const GetReportBonusstatSaList = (params: any, allow?: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/report/bonusstat/sa/list`, params, allow);
export const GetReportBonusstatAgList = (params: any, allow?: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/report/bonusstat/ag/list`, params, allow);
export const GetReportBonusstatMemberList = (params: any, allow?: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/report/bonusstat/member/list`, params, allow);
export const GetReportBonusstatCategoryList = (params: any, allow?: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/report/bonusstat/category/list`, params, allow);

export const GetReportBetSaList = (params: any, allow?: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/report/bet/sa/list`, params, allow);
export const GetReportBetAgList = (params: any, allow?: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/report/bet/ag/list`, params, allow);
export const GetReportBetMemberList = (params: any, allow?: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/report/bet/member/list`, params, allow);
export const GetReportBetGameList = (params: any, allow?: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/report/bet/game/list`, params, allow);

export const GetReportReceiveticketCount = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/report/receiveticket/count`, params);
export const GetRevenueticketSaDateList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/revenueticket/sa/date/list`, params);
export const GetRevenueticketSaList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/revenueticket/sa/list`, params);

export const GetReportMemberRank = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/report/ranking/member/list`, params);
export const GetReportGameProvider = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/report/provider/list`, params);
export const GetReportOnlineMembers = () => useFetchDataCommon(axiosGetAuth, `${baseURL}/report/online/members`)