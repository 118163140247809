import { InfoCircleFilled } from '@ant-design/icons';
import { Breadcrumb, Button, Checkbox, Col, ColorPicker, Form, Image, Input, InputNumber, Modal, Row, Select, Space, Spin, Table, Tag, Tooltip, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { RESPONSE_CODE, RESPONSE_CODE_CHANGELINE, RESPONSE_CODE_TAG } from 'constants/response';
import dayjs from 'dayjs';
import { AGENT_LOG } from 'enum/cashlog';
import { SETTLEMENT, SETTLE_CYCLE } from 'enum/promotion';
import { CHECKBOX_DISPLAY, ENABLE, RESPONSE_TYPE } from 'enum/state';
import useIcon from 'hooks/icon.hook';
import usePerm from 'hooks/permission.hook';
import useSite from 'hooks/site.hook';
import { StoreContext } from 'hooks/store.hook';
import i18n from 'i18n';
import React, { useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChangeAgentLine, GetAgentAgList, GetAgentCashlogList, GetAgentLoginHistory, GetAgentReportCommissionPeriodList, GetAgentReportCommissionSettlementList, GetAgentReportRevenuesharePeriodList, GetAgentReportRevenueshareSettlementList, GetAgentSaList, GetAgentSettleCycleList } from 'services/agent.service';
import { GetCommissionticketSaList, GetRevenueticketSaList, PostCommissionticketSaReceive, PostRevenueticketSaReceive } from 'services/commissionticket.service';
import { CreateTag, GetTagAgentList, GetTagList, GetTagOne, UpdateAgentTag, UpdateTag } from 'services/tag.service';
import { GetWithdrawmethodOne, PostWithdrawmethodAgentDelete } from 'services/withdrawmethod.service';
import * as common from 'utils/common';
import { numColor03 as $c, toFormatNumber as $f, verify } from 'utils/common';
import { LayoutPagination } from './layout.component';

// 總代列表
export const ViewAgentSaList = ({
  refs,
  agentSaData,
  showColumn,
  setPage,
  pageIndex,
  pageSize
}: any) => {
  const { permissionCode: $p } = usePerm();

  useImperativeHandle(refs, () => ({

    refresh: () => {
      resetSa();
    },

    // 子代按鈕
    openAgent: (item: any) => {
      const AGsId = item.AGsId.split('|');
      const parentParentParentId = parseInt(AGsId[AGsId.length - 4]);
      const parentParentId = parseInt(AGsId[AGsId.length - 3]);
      const parentId = parseInt(AGsId[AGsId.length - 2]);

      // 如果上上層是總代
      if (!parentParentParentId) {
        setSaId(parentParentId)
        setOneAgentKey([parentParentId])
        setOneAgent(sa?.Data)
      }
      else {
        setOneAgentKey([parentParentId])
        setOneId(parentParentParentId)
        const ag = oneList?.Data.find((item: any) => String(item.Id) === String(parentParentId))
        if (ag) {
          ag.key = String(parentParentId)
          setOneAgentKey([parentParentId])
          setOneAgent([ag])
        }
      }

      // 如果上層是總代
      if (!parentParentId) {
        setOneAgentKey([])
        setOneAgent([])

        setSaId(parentId)
        setTwoAgentKey([parentId])
        setTwoAgent(sa?.Data)
      }
      else {
        setTwoAgentKey([parentId])
        setTwoId(parentParentId)
        const ag = twoList?.Data.find((item: any) => String(item.Id) === String(parentId))
        if (ag) {
          ag.key = String(parentId)
          setTwoAgentKey([parentId])
          setTwoAgent([ag])
        }
      }

      setThreeAgentKey([item.Id]);
      setThreeAgent([item])
      setThreeParameter({ ...threeParameter, AgentId: parentId, PageIndex: 1 });

      refAgentLevelBread.current?.startAskBread();
    },

  }));

  const icon = useIcon();
  const navigate = useNavigate();

  const { data: TagList } = GetTagList();

  const header = [
    {
      dataIndex: 'Account',
      title: i18n.t('account'),
      width: 140,
      render: (_: any, record: Agent) => {
        const di = record.AGsId.split('|').length - 1;
        const word = common.agentLevelWord(di);
        return `${word} / ${record.Account}`
      }
    },
    {
      dataIndex: 'TagsId',
      title: i18n.t('agentTag'),
      width: 90,
      render: (_: any, record: Agent) => (
        JSON.parse(record.TagsId).length > 0 ?
          <>
            {
              TagList?.Data?.filter((item: any) => JSON.parse(record.TagsId).includes(item.Id))
                .map((item: any) =>
                  <Tag style={{
                    color: item.TextColor,
                    background: item.Color,
                    minWidth: 80,
                    minHeight: 20,
                    textAlign: 'center',
                  }}>
                    {item.Name}
                  </ Tag>
                )
            }
          </>
          : '-'
      )
    },
    {
      dataIndex: 'RefCode',
      title: i18n.t('referralCode'),
      width: 100,
    },
    {
      dataIndex: 'DownlineAgentCount',
      title: i18n.t('teamAgentCount'),
      className: 'text-right',
      width: 80,
      render: (_: any, record: Agent) => (
        <Tooltip placement="right" title={`直屬：${record.DownlineAgentCount} + 下線：${record.InDownlineAgentCount}`}>
          {$f(record.DownlineAgentCount + record.InDownlineAgentCount, 0)}
        </Tooltip>
      )
    },
    {
      dataIndex: 'DownlineMemberCount',
      title: i18n.t('teamMemberCount'),
      className: 'text-right',
      width: 80,
      render: (_: any, record: Agent) => (
        <Tooltip placement="right" title={`直屬：${record.DownlineMemberCount} + 下線：${record.InDownlineMemberCount}`}>
          {$f(record.DownlineMemberCount + record.InDownlineMemberCount, 0)}
        </Tooltip>
      )
    },
    {
      dataIndex: 'Balance',
      title: i18n.t('walletBalance'),
      className: 'text-right',
      width: 120,
      render: (_: any, record: Agent) => $f(record.Balance)
    },
    {
      dataIndex: 'Status',
      title: i18n.t('status'),
      width: 100,
      render: (val: any) => (
        <Row justify="center" align="middle">
          {icon.AgentStateTag(val)}
        </Row>
      )
    },
    {
      dataIndex: 'LastLoginDate',
      title: i18n.t('lastLoginTime'),
      width: 140,
      render: (val: any, { Account }: any) => val && Account !== 'company' ? val : '-'
    },
    {
      dataIndex: 'CreateDate',
      title: i18n.t('createTime'),
      width: 140,
      render: (val: any) => val || '-'
    },
    {
      dataIndex: 'IP',
      title: i18n.t('IP'),
      width: 100,
      render: (_: any, record: Agent) => (
        record.IP ? <Button type="link" onClick={() => {
          setAgentId(record.Id);
          setOpenIPHistory(true);
        }}>{record.IP}</Button> : '-'
      )
    },
    {
      dataIndex: 'CommissionSettleCycle',
      title: i18n.t('rebate'),
      width: 60,
      render: (_: any, record: Agent) => {
        const title = <>
          <div>賓果：0.1000％</div>
          <div>電子：0.1000％</div>
          <div>棋牌：0.1000％</div>
          <div>體育：0.1000％</div>
          <div>真人：0.1000％</div>
          <div>電競：0.1000％</div>
          <div>補魚：0.1000％</div>
          <div>彩票：0.1000％</div>
          <div>鬥雞：0.1000％</div>
        </>;

        return (
          // <Row justify="center" align="middle">
          //   <Tooltip placement="right" title={title}>
          //     {SETTLE_CYCLE[record.CommissionSettleCycle]}
          //   </Tooltip>
          // </Row>
          <span>{SETTLE_CYCLE[record.CommissionSettleCycle]}</span>
        )
      }
    },
    {
      dataIndex: 'RevenueShareSettleCycle',
      title: i18n.t('commissionRate'),
      width: 60,
      render: (_: any, record: Agent) => {
        const title = <>
          <div>賓果：0.1000％</div>
          <div>電子：0.1000％</div>
          <div>棋牌：0.1000％</div>
          <div>體育：0.1000％</div>
          <div>真人：0.1000％</div>
          <div>電競：0.1000％</div>
          <div>補魚：0.1000％</div>
          <div>彩票：0.1000％</div>
          <div>鬥雞：0.1000％</div>
        </>;

        return (
          // <Row justify="center" align="middle">
          //   <Tooltip placement="right" title={title}>
          //     {SETTLE_CYCLE[record.RevenueShareSettleCycle]}
          //   </Tooltip>
          // </Row>
          <span>{SETTLE_CYCLE[record.RevenueShareSettleCycle]}</span>
        )
      }
    },
    {
      dataIndex: 'Operation',
      title: i18n.t('operation'),
      width: 260,
      render: (_: any, record: Agent) => {
        return (
          <>
            {
              $p.includes('40201') &&
              <Button type="link" onClick={() => navigate(`/agent/edit/${record.Id}`)}>{i18n.t('edit')}</Button>
            }
            {
              $p.includes('40301') &&
              <Button type="link" onClick={() => navigate(`/agent/settle/${record.Id}`)}>{i18n.t('settlementReports')}</Button>
            }
            {
              $p.includes('40401') &&
              <Button type="link" onClick={() => navigate(`/agent/daily/${record.Id}`)}>{i18n.t('dailyReport')}</Button>
            }
            <Button type="link" onClick={() => {
              setAgentAccount(record.Account);
              setOpenAccounting(true);
            }}>{i18n.t('accounting')}</Button>
            {
              $p.includes('40203') &&
              <Button type="link" onClick={() => navigate(`/agent/add-agent/${record.Id}`)}>{i18n.t('addAgent')}</Button>
            }
            {
              record.Level !== 0 && $p.includes('40207') &&
              <Button type="link" onClick={() => {
                setAgentId(record.Id);
                setAgentAccount(record.Account);
                setAgentLevel(record.Level);
                setOpenExchange(true);
              }}>
                {i18n.t('currencyExchange')}
              </Button>
            }
          </>
        )
      },
    },
  ];

  const expandableSa = {
    rowExpandable: (record: Agent) => (record.DownlineAgentCount + record.InDownlineAgentCount) > 0,
    expandedRowRender: () => <></>,
    // 展開子代
    onExpand: (open: boolean, record: any) => {
      if (open) expand(record)
      else backto(record)
      refAgentLevelBread.current?.updateStream(open, record);
    },
  };

  // 點開
  const expand = (record: any) => {
    setOneAgent(twoAgent)
    setOneAgentKey([twoAgent[0]?.Id])

    setTwoAgent([record])
    setTwoAgentKey([record.Id])

    setThreeParameter({ ...threeParameter, AgentId: 0 });
    setTimeout(() => {
      setThreeParameter({ ...threeParameter, AgentId: record.Id });
    });
    setThreeAgentKey([])
  }

  // 收合
  const backto = (record: any) => {
    const AGsId = record.AGsId.split('|');
    const parentParentId = AGsId[AGsId.length - 3];
    const parentId = parseInt(AGsId[AGsId.length - 2]);

    // 這一層是總代
    if (String(AGsId) === String(record.Id)) {
      resetSa();
    } else {
      setThreeAgentKey([])
      setThreeParameter({ ...threeParameter, AgentId: 0 });
      setTimeout(() => {
        setThreeParameter({ ...threeParameter, AgentId: parentId });
      });

      // 上一層是總代
      if (AGsId.length <= 2) {
        setTwoAgentKey([parentId])
        setSaId(parentId)
        setTwoAgent(sa?.Data)

        setOneAgent([])
      } else {
        setTwoAgentKey([parentId])
        setTwoId(parentParentId)
        const ag = twoList?.Data.find((item: any) => String(item.Id) === String(parentId))
        if (ag) {
          ag.key = String(ag.Id)
          setTwoAgentKey([ag.Id])
          setTwoAgent([ag])
        }

        const sa = agentSaData?.Data.find((item: any) => String(item.Id) === String(parentParentId))
        setOneAgentKey([sa?.Id])
        setOneAgent([sa])
      }
    }
  }

  const resetSa = () => {
    setOneAgent([])
    setTwoAgent([])
    setThreeAgent(agentSaData.Data)
    setThreeAgentKey([])
    setSaExpand([]);

    refAgentLevelBread.current?.setBreadList([]);
  }

  const [oneAgentKey, setOneAgentKey] = useState<any>([]);
  const [twoAgentKey, setTwoAgentKey] = useState<any>([]);
  const [threeAgentKey, setThreeAgentKey] = useState<any>([]);
  const [oneAgent, setOneAgent] = useState<any>([]);
  const [twoAgent, setTwoAgent] = useState<any>([]);
  const [threeAgent, setThreeAgent] = useState<any>([]);

  const [saId, setSaId] = useState(0);
  const { data: sa } = GetAgentSaList({
    AgentId: saId,
    PageSize: 1,
    PageIndex: 1,
    CreateDateStartTime: '2022-01-01 00:00:00',
    CreateDateEndTime: '2036-12-01 00:00:00'
  }, !!saId);
  useEffect(() => {
    if (sa && sa.Data) {
      if (oneAgentKey.length > 0) {
        setOneAgent(sa.Data)
      } else if (twoAgentKey.length > 0) {
        setTwoAgent(sa.Data)
      }
    }
  }, [sa]);

  // 第一層API
  const [oneId, setOneId] = useState(0);
  const { data: oneList, isValidating: oneValidating } = GetAgentAgList({
    AgentId: oneId,
    PageSize: 50,
    PageIndex: 1,
  }, !!oneId);
  useEffect(() => {
    if (oneList && oneList.Data) {
      const ag = oneList.Data.find((item: any) => String(item.Id) === String(oneAgentKey[0]))
      if (ag) {
        ag.key = String(ag.Id)
        setOneAgentKey([ag.Id])
        setOneAgent([ag])
      }
    }
  }, [oneList]);

  // 第二層API
  const [twoId, setTwoId] = useState(0);
  const { data: twoList, isValidating: twoValidating } = GetAgentAgList({
    AgentId: twoId,
    PageSize: 50,
    PageIndex: 1,
  }, !!twoId);
  useEffect(() => {
    if (twoList && twoList.Data) {
      const ag = twoList.Data.find((item: any) => String(item.Id) === String(twoAgentKey[0]))
      if (ag) {
        ag.key = String(ag.Id)
        setTwoAgentKey([ag.Id])
        setTwoAgent([ag])
      }
    }
  }, [twoList]);

  // 第三層API
  const [threeParameter, setThreeParameter] = useState({
    AgentId: 0,
    PageSize: pageSize,
    PageIndex: 1,
  });
  const { data: threeList, isValidating: threeValidating } = GetAgentAgList(threeParameter, !!threeParameter.AgentId);
  useEffect(() => {
    if (threeList && threeList.Data) {
      if (threeAgentKey.length > 0) {
        const ag = twoList?.Data.find((item: any) => String(item.Id) === String(threeAgentKey[0]))
        if (ag) {
          ag.key = String(threeAgentKey[0])
          setThreeAgentKey([])
          setThreeAgent([ag])
        }
      } else {
        setThreeAgent(threeList.Data);
      }
    }
  }, [threeList]);
  useEffect(() => {
    if (agentSaData && agentSaData.Data) {
      setThreeAgent(agentSaData.Data)
    }
  }, [agentSaData]);

  // 換頁
  const clickSetPage = (e: any) => {
    if (twoAgent.length) {
      // 子代換頁
      setThreeParameter({ ...threeParameter, PageIndex: e.current });
      setThreePageIndex(e.current)
      setPage({ current: pageIndex, pageSize: e.pageSize });
    } else {
      // 總代換頁
      setPage(e);
    }
  }

  const [agentId, setAgentId] = useState<number | null>(null);
  const [agentAccount, setAgentAccount] = useState<string | null>(null);
  const [agentLevel, setAgentLevel] = useState<number | null>(null);
  const [isOpenAccounting, setOpenAccounting] = useState(false);
  const [isOpenExchange, setOpenExchange] = useState(false);
  const [isOpenIPHistory, setOpenIPHistory] = useState(false);

  // 記憶展開
  const { useSaExpand, useSaPageIndex } = useContext(StoreContext);
  const [saExpand, setSaExpand] = useSaExpand;
  const [threePageIndex, setThreePageIndex] = useSaPageIndex;

  const initialized = useRef(false)
  useEffect(() => {
    if (saExpand && saExpand.length > 0 && !initialized.current) {
      initialized.current = true;

      if (saExpand.length >= 2) {
        setOneAgent([saExpand[saExpand.length - 2]])
        setOneAgentKey([saExpand[saExpand.length - 2].Id])
      } else {
        setOneAgent([])
        setOneAgentKey([])
      }

      setTwoAgent([saExpand[saExpand.length - 1]])
      setTwoAgentKey([saExpand[saExpand.length - 1].Id])
      setThreeParameter({
        ...threeParameter,
        AgentId: saExpand[saExpand.length - 1].Id,
        PageIndex: threePageIndex || 1
      });
      setThreeAgentKey([])

      refAgentLevelBread.current?.setBreadList(saExpand);
    }
  }, []);

  const refAgentLevelBread = useRef<any>();

  return (
    <>
      {/* 代層麵包 */}
      <AgentLevelBread refs={refAgentLevelBread} threeAgent={threeAgent} resetSa={resetSa} setSaExpand={setSaExpand} backto={backto} />

      {/* 上上代 */}
      {oneAgent && oneAgent[0] &&
        <div>
          <Table
            size='middle'
            loading={oneValidating}
            columns={header.filter(col => showColumn.includes(col.dataIndex || ''))}
            dataSource={oneAgent?.map((item: any) => ({ ...item, key: item.Id }))}
            pagination={false}
            expandable={{ ...expandableSa, expandedRowKeys: oneAgentKey }}
          />
        </div>
      }

      {/* 上代 */}
      {twoAgent && twoAgent[0] &&
        <div>
          <Table
            size='middle'
            loading={twoValidating}
            columns={header.filter(col => showColumn.includes(col.dataIndex || ''))}
            dataSource={twoAgent?.map((item: any) => ({ ...item, key: item.Id }))}
            pagination={false}
            expandable={{ ...expandableSa, expandedRowKeys: twoAgentKey }}
          />
        </div>
      }

      {/* 這代 */}
      {<div>
        <Table
          size='middle'
          loading={threeValidating}
          columns={header.filter(col => showColumn.includes(col.dataIndex || ''))}
          dataSource={threeAgent?.map((item: any) => ({ ...item, key: item.Id }))}
          pagination={{
            showSizeChanger: true,
            showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
            total: (twoAgent?.length && threeList?.TotalRecord) || agentSaData?.TotalRecord,
            current: twoAgent?.length ? threeParameter.PageIndex : pageIndex,
            pageSize: twoAgent?.length ? threeParameter.PageSize : pageSize
          }}
          onChange={clickSetPage}
          expandable={{ ...expandableSa, expandedRowKeys: threeAgentKey }}
        />
      </div>
      }

      <PopupIPHistory isOpen={isOpenIPHistory} close={() => setOpenIPHistory(false)} AgentId={agentId} />
      <PopupAccounting isOpen={isOpenAccounting} close={() => setOpenAccounting(false)} AgentAccount={agentAccount} />
      <PopupExchange isOpen={isOpenExchange} close={() => setOpenExchange(false)} AgentId={agentId}
        Account={agentAccount} Level={agentLevel} refresh={resetSa}
      />
    </>
  )
}
// 代理等級麵包屑
export const AgentLevelBread = ({ refs, resetSa, setSaExpand, backto, threeAgent }: any) => {
  useImperativeHandle(refs, () => ({

    startAskBread: startAskBread,
    updateStream: updateStream,
    setBreadList: setBreadList

  }));

  // 自動問出麵包串
  const startAskBread = () => {
    setBreadList([]);
    setAskSaId(0);
    setGoalAgId(0);
    setAskAgId(0);

    setTimeout(() => {
      if (threeAgent && threeAgent[0]) {

        const AGsId = threeAgent[0].AGsId?.split('|');
        const parentParentId = parseInt(AGsId[AGsId.length - 3]);
        const parentId = parseInt(AGsId[AGsId.length - 2]);

        if (parentParentId) {
          setGoalAgId(parentId)
          setAskAgId(parentParentId)
        } else {
          setAskSaId(parentId)
        }
      }
    });
  }

  const [breadList, setBreadList] = useState<any>([]);
  const [goalAgId, setGoalAgId] = useState(0);
  const [askAgId, setAskAgId] = useState(0);
  const { data: askAgList, isValidating } = GetAgentAgList({
    AgentId: askAgId,
    PageSize: 50,
    PageIndex: 1,
  }, !!askAgId);
  useEffect(() => {
    if (askAgList && askAgList.Data) {
      const ag = askAgList.Data.find((item: any) => String(item.Id) === String(goalAgId))
      if (ag) {
        breadList.unshift(ag)
        setBreadList([...breadList]);

        const AGsId = ag.AGsId?.split('|');
        const parentParentId = parseInt(AGsId[AGsId.length - 3]);
        const parentId = parseInt(AGsId[AGsId.length - 2]);
        if (parentParentId) {
          setGoalAgId(parentId)
          setAskAgId(parentParentId)
        } else {
          setAskSaId(parentId)
        }
      }
    }
  }, [askAgList]);
  const [askSaId, setAskSaId] = useState(0);
  const { data: askSaList } = GetAgentSaList({
    AgentId: askSaId,
    PageSize: 1,
    PageIndex: 1,
    CreateDateStartTime: '2022-01-01 00:00:00',
    CreateDateEndTime: '2036-12-01 00:00:00'
  }, !!askSaId);
  useEffect(() => {
    if (askSaList && askSaList.Data && askSaList.Data[0]) {
      breadList.unshift(askSaList.Data[0])
      setBreadList([...breadList]);
      setSaExpand(breadList);
    }
  }, [askSaList]);

  const updateStream = (open: boolean, record: any) => {
    if (open) {
      breadList.push(record)
    }
    else {
      const index = breadList.findIndex((item: any) => item.Id === record.Id);
      if (index !== -1) breadList.length = index;
    }
    setBreadList([...breadList]);
    setSaExpand(breadList);
  }

  const breadcrumbItems = new Map();
  breadcrumbItems.set('/', [
    { title: <a onClick={() => resetSa()}>{i18n.t('generalAgentList')}</a> },
    ...breadList.map((item: any, i: number) => {
      const word = common.agentLevelWord(item.Level);
      return {
        title: <a onClick={() => breadClick(breadList[i + 1])}>{`${word} / ${item.Account}`}</a>
      }
    })
  ]);

  // 點擊麵包屑
  const breadClick = (child: any) => {
    if (child) {
      backto(child);
      updateStream(false, child);
    }
  }

  return (
    <Spin spinning={isValidating}>
      <Breadcrumb className="pt-1" separator=">" items={breadcrumbItems.get('/')} />
    </Spin>
  )
}

// 代理權限
export const SelectAgentPermission = ({ agent, permissions, setPermissions, confirm, disabled }: any) => {
  useEffect(() => {
    if (agent) {
      updateCheckStateOne();
    }
  }, [agent]);

  const { data: $s, isCashVersion: $sc } = useSite();

  const allList: any = [
    {
      key: '1', MenuName: i18n.t('team'), name: i18n.t('member'), rowSpan: 2, child: [
        { key: '0101', name: i18n.t('view') },
        { key: '0102', name: `${i18n.t('add')}／${i18n.t('edit')}` },
        { key: '0103', name: i18n.t('remark') },
        { key: '0104', name: i18n.t('transferAdd') },
        { key: '0105', name: i18n.t('transferDeduction') },
        { key: '0106', name: i18n.t('cashFlowRecord') },
      ]
    },
    {
      key: '2', MenuName: i18n.t('team'), name: i18n.t('agent'), child: [
        { key: '0201', name: i18n.t('view') },
        { key: '0202', name: `${i18n.t('add')}／${i18n.t('edit')}` },
        { key: '0203', name: i18n.t('export') },
        { key: '0204', name: i18n.t('remark') },
      ]
    },
    {
      key: '3', MenuName: i18n.t('wallet'), name: i18n.t('wallet'), rowSpan: 1, child: [
        { key: '0301', name: i18n.t('view') },
        { key: '0302', name: i18n.t('recharge') },
        { key: '0303', name: i18n.t('transferMoney') },
        { key: '0304', name: i18n.t('withdraw') },
      ]
    },
  ]

  const [form] = useForm();

  const [menuCheckState, setMenuCheckState] = useState<any>({});
  const updateCheckState = (record: any, level: any, checked: any, itemKey?: any) => {
    const fieldsValue: any = {};

    let menuTotal = 0
    let menuSum = 0
    let managementSum = 0

    allList.forEach((page: any) => {
      managementSum = 0;

      if (page.MenuName === record.MenuName) {
        menuTotal = menuTotal + page.child.length

        page.child.forEach((item: any) => {
          // 計算勾選數量
          if (
            (level === 'menu' && checked) ||
            (page.name === record.name && (
              (level === 'management' && checked) ||
              (level === 'operation' && form.getFieldValue(`cb-${item.key}`))
            ))
          ) {
            managementSum++
            menuSum++
          }
          else if (
            page.name !== record.name && (level === 'management' || level === 'operation') && form.getFieldValue(`cb-${item.key}`)
          ) {
            menuSum++
          }
          // 改變勾選狀態
          if ((level === 'management' && page.name === record.name) || level === 'menu') {
            fieldsValue[`cb-${item.key}`] = checked;

            const index = permissions.indexOf(item.key);
            if (!checked && index > -1) permissions.splice(index, 1);
            else if (checked && index === -1) permissions.push(item.key);
          } else if (level === 'operation' && item.key === itemKey) {
            const index = permissions.indexOf(item.key);
            if (!checked && index > -1) permissions.splice(index, 1);
            else if (checked && index === -1) permissions.push(item.key);
          }
        })

        if (((level === 'management' || level === 'operation') && page.name === record.name) || level === 'menu') {
          if (managementSum === 0) menuCheckState[page.name] = CHECKBOX_DISPLAY.空
          else if (managementSum > 0 && managementSum < page.child.length) menuCheckState[page.name] = CHECKBOX_DISPLAY.方塊
          else if (managementSum === page.child.length) menuCheckState[page.name] = CHECKBOX_DISPLAY.打勾
        }
      }
    })

    if (menuSum === 0) menuCheckState[record.MenuName] = CHECKBOX_DISPLAY.空
    else if (menuSum > 0 && menuSum < menuTotal) menuCheckState[record.MenuName] = CHECKBOX_DISPLAY.方塊
    else if (menuSum === menuTotal) menuCheckState[record.MenuName] = CHECKBOX_DISPLAY.打勾
    form.setFieldsValue({ ...fieldsValue })
    setMenuCheckState({ ...menuCheckState })
    setPermissions(permissions)
  };
  const updateCheckStateOne = () => {
    const fieldsValue: any = {};
    const permissionCode = JSON.parse(agent.Permission);
    const permissions: any = [];

    let menuTotal = 0
    let menuSum = 0
    let managementSum = 0

    let menuName = allList[0].MenuName;

    allList.forEach((page: any, i: number) => {
      managementSum = 0;
      menuTotal = menuTotal + page.child.length;

      // management
      page.child.forEach((item: any) => {
        // 計算勾選數量
        if (permissionCode.includes(item.key)) {
          managementSum++
          menuSum++
          fieldsValue[`cb-${item.key}`] = true;
          permissions.push(item.key);
        }
      })
      if (managementSum === 0) menuCheckState[page.name] = CHECKBOX_DISPLAY.空
      else if (managementSum > 0 && managementSum < page.child.length) menuCheckState[page.name] = CHECKBOX_DISPLAY.方塊
      else if (managementSum === page.child.length) menuCheckState[page.name] = CHECKBOX_DISPLAY.打勾

      // menu
      if ((allList[i + 1]?.MenuName !== menuName) || !allList[i + 1]) {
        if (menuSum === 0) menuCheckState[menuName] = CHECKBOX_DISPLAY.空
        else if (menuSum > 0 && menuSum < menuTotal) menuCheckState[menuName] = CHECKBOX_DISPLAY.方塊
        else if (menuSum === menuTotal) menuCheckState[menuName] = CHECKBOX_DISPLAY.打勾

        menuName = allList[i + 1]?.MenuName;
        menuSum = 0;
        menuTotal = 0;
      }
    })
    form.setFieldsValue({ ...fieldsValue })
    setMenuCheckState({ ...menuCheckState })
    setPermissions(permissions)
  };

  return (
    <Form form={form}>
      <Table
        className="w-full"
        size="middle"
        bordered
        dataSource={allList}
        columns={[
          {
            key: 1,
            title: <><span className="require">*</span>{i18n.t('page')}</>,
            width: '25%',
            onCell: (record, index) => ({ rowSpan: record.rowSpan || 0 }),
            render: (_, record: any) =>
              <Checkbox
                indeterminate={menuCheckState[record.MenuName] === CHECKBOX_DISPLAY.方塊}
                checked={menuCheckState[record.MenuName] === CHECKBOX_DISPLAY.打勾}
                onChange={e => updateCheckState(record, 'menu', e.target.checked)}
                disabled={confirm || disabled}
              >{record.MenuName}</Checkbox>,
          },
          {
            key: 2,
            className: 'size-12',
            width: '25%',
            render: (_, record: any) =>
              <Checkbox
                indeterminate={menuCheckState[record.name] === CHECKBOX_DISPLAY.方塊}
                checked={menuCheckState[record.name] === CHECKBOX_DISPLAY.打勾}
                onChange={e => updateCheckState(record, 'management', e.target.checked)}
                disabled={confirm || disabled}
              >{record.name || record.ManagementName}</Checkbox>
          },
          {
            key: 3,
            title: i18n.t('permissions'),
            width: '50%',
            render: (_, record) => record.child.map((item: any) =>
              <Form.Item key={item.key} name={`cb-${item.key}`} valuePropName="checked" style={{ display: 'inline-block' }}>
                <Checkbox
                  onChange={e => updateCheckState(record, 'operation', e.target.checked, item.key)}
                  disabled={confirm || disabled}
                >{`${item.name}`}</Checkbox>
              </Form.Item>
            )
          },
        ]}
        pagination={false}
      />
    </Form>
  )
}

// 結算報表
export const ViewAgentCommissionReport = ({ id }: any) => {
  const [period, setPeriod] = useState<string>("");

  const { data: agentCommissionReport, isValidating }: any = GetAgentReportCommissionSettlementList({
    Period: period,
    AgentId: id
  }, !!period);

  return (
    <>
      <Row gutter={[0, 10]} className="pb-1">
        <Col span={24} className="size-14 font-w-md mt-1">
          {i18n.t('rebateReport')}
        </Col>
        <Col span={4}>
          <SelectCycleDate setPeriod={setPeriod} id={id} mode={SETTLEMENT.返水} />
        </Col>
      </Row>
      <Table
        loading={isValidating}
        size="middle"
        dataSource={
          agentCommissionReport?.map((item: AgentCommissionReport, i: number) => ({ ...item, key: i }))
        }
        columns={[
          {
            dataIndex: 'AgentAccount',
            title: i18n.t('account'),
            width: 200,
            render: (val, { AgentLevel, CategoryName }) => {
              const word = common.agentLevelWord(AgentLevel);
              return CategoryName === 'ALL'
                ?
                <span style={{ margin: '2px 8px', display: 'inline-block' }}>
                  {word}／{val}
                </span>
                : ''
            }
          },
          {
            dataIndex: 'CategoryName',
            title: i18n.t('gameCategory'),
            width: 150,
            render: (val) => i18n.t(`${val}`)
          },
          {
            dataIndex: 'ValidBetAmount',
            title: i18n.t('totalEffectiveBets'),
            align: 'right',
            width: 200,
            render: (val) => $f(val)
          },
          {
            dataIndex: 'DirectMemberValidBetAmount',
            title: i18n.t('directMemberEffectiveBets'),
            align: 'right',
            width: 200,
            render: (val) => $f(val)
          },
          {
            dataIndex: 'WinLoseAmount',
            title: i18n.t('totalMemberWinLoss'),
            align: 'right',
            width: 200,
            render: (val) => <div className={common.numColor01(val)}>{$f(val)}</div>
          },
          {
            dataIndex: 'TotalCommission',
            title: i18n.t('totalRebate'),
            align: 'right',
            width: 200,
            render: (val) => $f(val)
          },
          {
            dataIndex: 'DownlineCommission',
            title: i18n.t('subordinateCashback'),
            width: 200,
            align: 'right',
            render: (val) => $f(val)
          },
          {
            dataIndex: 'DirectMemberCommission',
            title: i18n.t('directMemberCashback'),
            width: 200,
            align: 'right',
            render: (val) => $f(val)
          },
          {
            dataIndex: 'Commission',
            title: i18n.t('myRebate'),
            width: 200,
            align: 'right',
            render: (val) => <div className={common.numColor05(val)}>{$f(val)}</div>
          },
        ]}
        pagination={false}
      />
    </>
  )
}
export const ViewAgentRevenueshareReport = ({ id }: any) => {
  const [period, setPeriod] = useState<string>("");

  const { data: agentRevenueshareReport, isValidating } = GetAgentReportRevenueshareSettlementList({
    Period: period,
    AgentId: id
  }, !!period);

  return (
    <>
      <Row gutter={[0, 10]} className="pt-1 pb-1">
        <Col span={24} className="size-14 font-w-md mt-1">
          {i18n.t('commissionRateReport')}
        </Col>
        <Col span={4}>
          <SelectCycleDate setPeriod={setPeriod} id={id} mode={SETTLEMENT.占成} />
        </Col>
      </Row>
      <Table
        loading={isValidating}
        size="middle"
        pagination={false}
        dataSource={
          agentRevenueshareReport?.map((item: AgentRevenueshareReport, i: number) => ({ ...item, key: i }))
        }
        columns={[
          {
            key: 1,
            dataIndex: 'AgentAccount',
            title: i18n.t('account'),
            className: 'size-12',
            width: 200,
            render: (val, { AgentLevel, CategoryName }) => {
              const word = common.agentLevelWord(AgentLevel);
              return CategoryName === 'ALL'
                ?
                <span style={{ margin: '2px 8px', display: 'inline-block' }}>
                  {word}／{val}
                </span>
                : ''
            }
          },
          {
            key: 2,
            dataIndex: 'CategoryName',
            title: i18n.t('gameCategory'),
            className: 'size-12',
            width: 150,
            render: (val) => i18n.t(`${val}`)
          },
          {
            key: 3,
            dataIndex: 'ValidBetAmount',
            title: i18n.t('totalEffectiveBets'),
            className: 'size-12',
            align: 'right',
            width: 150,
            render: (val) => $f(val)
          },
          {
            key: 4,
            dataIndex: 'WinLoseAmount',
            title: i18n.t('totalMemberWinLoss'),
            className: 'size-12',
            align: 'right',
            width: 150,
            render: (val) => <div className={common.numColor01(val)}>{$f(val)}</div>
          },
          {
            key: 41,
            dataIndex: 'DirectMemberWinLoseAmount',
            title: i18n.t('directMemberWinLoss'),
            className: 'size-12',
            align: 'right',
            width: 150,
            render: (val) => <div className={common.numColor01(val)}>{$f(val)}</div>
          },
          {
            key: 5,
            dataIndex: 'TotalCost',
            title: i18n.t('totalCost'),
            className: 'size-12',
            align: 'right',
            width: 150,
            render: (val) => $f(val)
          },
          {
            key: 6,
            dataIndex: 'DownlineRevenueShare',
            title: i18n.t('subordinateCommissionRate'),
            className: 'size-12',
            align: 'right',
            width: 150,
            render: (val) => <div className={common.numColor01(val)}>{$f(val)}</div>
          },
          {
            key: 7,
            dataIndex: 'DownlineAgentCost',
            title: i18n.t('subordinateCost'),
            className: 'size-12',
            align: 'right',
            width: 150,
            render: (val) => $f(val)
          },
          {
            key: 8,
            dataIndex: 'DownlineAgentProfit',
            title: i18n.t('subordinateProfit'),
            className: 'size-12',
            align: 'right',
            width: 150,
            render: (val) => $f(val)
          },
          {
            key: 9,
            dataIndex: 'RevenueShare',
            title: i18n.t('myCommissionRate'),
            className: 'size-12',
            align: 'right',
            width: 150,
            render: (val) => <div className={common.numColor01(val)}>{$f(val)}</div>
          },
          {
            key: 10,
            dataIndex: 'AgentCost',
            title: i18n.t('myCost'),
            className: 'size-12',
            align: 'right',
            width: 150,
            render: (val) => $f(val)
          },
          {
            key: 11,
            dataIndex: 'AgentProfit',
            width: 150,
            title:
              <Row align="middle" justify="end" gutter={5}>
                <Col>
                  <Tooltip placement="top" title={
                    i18n.t('myEarningsEqualsMyShareMinusMyCostPositiveAgentProfitNegativeAgentLoss')
                  }>
                    <InfoCircleFilled />
                  </Tooltip>
                </Col>
                <Col className="size-12">{i18n.t('myProfit')}</Col>
              </Row>,
            className: 'size-12',
            align: 'right',
            render: (val) => <div className={common.numColor05(val)}>{$f(val)}</div>
          },
        ]} />
    </>
  )
}
// 結算週期下拉表
export const SelectCycleDate = ({ setPeriod, id, mode }: any) => {
  // const [dateList, setDateList] = useState<string[]>([]);

  const { data: commissionList } = GetAgentReportCommissionPeriodList({
    AgentId: id
  }, mode === SETTLEMENT.返水);

  const { data: revenueshareList } = GetAgentReportRevenuesharePeriodList({
    AgentId: id
  }, mode === SETTLEMENT.占成);

  // useEffect(() => {
  //   const list: string[] = [];
  //   let startDate: Date = new Date();
  //   startDate = setHours(startDate, 0);
  //   startDate = setMinutes(startDate, 0);
  //   startDate = setSeconds(startDate, 0);
  //   let endDate: Date = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
  //   startDate = subMonths(startDate, 3);

  //   while (true) {

  //     // 判斷是否加入日期
  //     switch (cycle) {
  //       case SETTLE_CYCLE.日結:
  //         list.push(format(startDate, 'yyyy-MM-dd'));
  //         break;
  //       case SETTLE_CYCLE.週結:
  //         if (isMonday(startDate)) list.push(format(startDate, 'yyyy-MM-dd'));
  //         break;
  //       case SETTLE_CYCLE.月結:
  //         if (startDate.getDate() === 1) list.push(format(startDate, 'yyyy-MM-dd'));
  //         break;
  //     }

  //     // 判斷是否結束
  //     startDate = addDays(startDate, 1);
  //     if (getTime(startDate) > getTime(endDate)) break;
  //   }

  //   setDateList(list);
  // }, [cycle])

  return (
    <Select
      placeholder={i18n.t('selectDate')}
      className="w-full"
      options={(commissionList || revenueshareList)?.map((item: any) => ({ value: item.Period }))}
      onChange={setPeriod}
    />
  )
}

// 登入記錄
export const PopupIPHistory = ({ isOpen, close, AgentId }: {
  isOpen: boolean;
  close: () => void;
  AgentId: number | null;
}) => {
  const { data: IPHistory, isValidating } = GetAgentLoginHistory({ AgentId });

  return (
    <Modal
      title={`${i18n.t('loginRecords')}`}
      open={isOpen}
      onCancel={close}
      width={600}
      centered
      footer={
        <Row justify="center">
          <Button type="primary" key="cancel" onClick={close}>{i18n.t('close')}</Button>
        </Row>
      }
    >
      <Spin spinning={isValidating}>
        <Table
          size="middle"
          bordered
          scroll={{ y: 400 }}
          dataSource={
            IPHistory ?
              IPHistory.map((item: AgentLoginIPHistory) => (
                {
                  key: item.Id,
                  date: item.CreateDate,
                  ip: item.IP
                }
              )) : []
          }
          columns={[
            {
              title: i18n.t('loginTime'),
              dataIndex: 'date',
              width: 140
            },
            {
              title: i18n.t('IP'),
              dataIndex: 'ip',
              width: 140
            },
          ]}
          pagination={false}
        />
      </Spin>
    </Modal>
  )
}

// 帳目
export const PopupAccounting = ({ isOpen, close, AgentAccount }: any) => {
  const [page, setPage] = useState<number[]>([1, 10]);
  const { data: cashlog, isValidating } = GetAgentCashlogList({
    Account: AgentAccount,
    StartDate: dayjs().add(-1, 'month').startOf('day').format('YYYY-MM-DD 00:00:00'),
    EndDate: dayjs().format('YYYY-MM-DD 23:59:59'),
    PageIndex: page[0],
    PageSize: page[1]
  }, !!AgentAccount);

  return (
    <Modal
      title={`${i18n.t('accounting')} (${i18n.t('last30Days')})`}
      open={isOpen}
      centered
      onCancel={close}
      footer={false}
      width={1000}
    >
      <Table
        loading={isValidating}
        size="small"
        dataSource={cashlog?.Data.map((item: WalletRecordTYpe) => ({ ...item, key: item.Id }))}
        columns={[
          {
            dataIndex: 'UpdateDate',
            title: i18n.t('transactionTime'),
            width: 200,
          },
          {
            dataIndex: 'KeyId',
            title: i18n.t('type'),
            width: 150,
            render: (val) => AGENT_LOG[val] || val
          },
          {
            dataIndex: 'TargetAccount',
            title: i18n.t('agentMemberAccount'),
            width: 150,
            render: (val) => val || '-'
          },
          {
            dataIndex: 'BeforeAmount',
            title: i18n.t('previousBalance'),
            align: 'right',
            width: 200,
            render: (val) => $f(val)
          },
          {
            dataIndex: 'Amount',
            title: i18n.t('transactionAmount'),
            align: 'right',
            width: 200,
            render: (val) => <div className={$c(val)}>{$f(val)}</div>
          },
          {
            dataIndex: 'AfterAmount',
            title: i18n.t('BalanceAfterModification'),
            align: 'right',
            width: 200,
            render: (val) => $f(val)
          },
        ]}
        pagination={false}
      />
      <LayoutPagination total={cashlog ? cashlog.TotalRecord : 0} setPage={setPage} page={page} />
    </Modal>
  )
}

// 換線
export const PopupExchange = ({ isOpen, close, AgentId, Account, Level, refresh }: any) => {
  const icon = useIcon();
  const [form] = useForm();
  const { data: Options, isValidating } = GetAgentSettleCycleList({ AgentId })

  const onFinish = async (formData: any) => {

    const response = await ChangeAgentLine({
      AgentId,
      TargetAgentAccount: formData.changeAgentAccount
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('updateSuccess'));
      refresh();
      onClose();
    } else {
      message.error(RESPONSE_CODE_CHANGELINE[response.Message as keyof typeof RESPONSE_CODE_CHANGELINE]);
    }

  };

  const onClose = () => {
    close();
    form.resetFields();
  }

  return (
    <Modal title={`${Level}${i18n.t('agency')}／${Account}／${i18n.t('currencyExchange')}`}
      open={isOpen}
      centered
      onCancel={onClose}
      footer={false}
      width={400}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Row align="middle">
              <Image className="center" src={icon.icon12} width={16} height={16} preview={false} />
              <span style={{ marginLeft: 5 }}>{i18n.t('considerationsForCurrencyExchange')}：</span>
            </Row>
            <div className="pl-2">1.{i18n.t('beforeProceedingWithCurrencyExchangeTheSettlementPeriodForAgentsMustBeConsistentBeforeAndAfterTheExchange')}</div>
            <div className="pl-2">2.{i18n.t('accountSearchWillOnlyListAgentsWithTheSameSettlementPeriod')}</div>
          </Col>
          <Col span={24}>
            <Form.Item name="changeAgentAccount" label={i18n.t('account')} required
              rules={[{ required: true }]}>
              <Select
                loading={isValidating}
                showSearch
                className="w-full"
                placeholder={i18n.t('pleaseSelect')}
                options={
                  Options ?
                    Options.map((item: AgentSettleCycle) => (
                      { value: item.Account, label: item.Account }
                    )) : []
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row align="middle" justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

// 詳情銀行卡
export const PopupAgentBankDetail = ({ isOpen, close, currentItem, mutate }: any) => {
  const [form] = useForm();

  const { data: bank } = GetWithdrawmethodOne({ Id: currentItem.Id }, !!currentItem.Id);

  useEffect(() => {
    if (bank && isOpen) {
      form.setFieldsValue({
        bankName: bank.BankName,
        openingBranch: bank.Branch,
        accountNumber: bank.Account,
        accountName: bank.Name,
        bankProvince: bank.State,
        city: bank.City,
        PhonePrefix: bank.PhonePrefix.replace('+', ''),
        PhoneNumber: bank.PhoneNumber,

        currencyType: bank.Crypto,
        networkType: bank.CryptoType,
        address: bank.CryptoAddress,
        qrcode: JSON.parse(bank.Photo)[0]
      })
    }
  }, [bank, isOpen])

  return (
    <Modal
      title={i18n.t('detail')}
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button key="cancel" onClick={close}>{i18n.t('cancel')}</Button>
        </Row>
      }
    >
      <Form layout="vertical" form={form}>
        {
          bank && bank.BankName &&
          <>
            <Form.Item name="bankName" label={i18n.t('bankName')} required>
              <Input disabled />
            </Form.Item>
            <Form.Item name="openingBranch" label={i18n.t('openingBranch')} required>
              <Input disabled />
            </Form.Item>
            <Form.Item name="accountNumber" label={i18n.t('accountNumber')} required>
              <Input disabled />
            </Form.Item>
          </>
        }
        {
          bank && bank.BankName
            ? <>
              <Form.Item name="accountName" label={i18n.t('accountName')} required>
                <Input disabled />
              </Form.Item>
              <Form.Item name="bankProvince" label={i18n.t('bankProvince')}>
                <Input disabled />
              </Form.Item>
              <Form.Item name="city" label={i18n.t('city')}>
                <Input disabled />
              </Form.Item>
              <Form.Item name="PhonePrefix" label={i18n.t('countryCode')}>
                <Input disabled addonBefore={'+'} />
              </Form.Item>
              <Form.Item name="PhoneNumber" label={i18n.t('phoneNumber')}>
                <Input disabled />
              </Form.Item>
            </>
            : <>
              <Form.Item name="currencyType" label={i18n.t('currencyType')} required>
                <Input disabled />
              </Form.Item>
              <Form.Item name="networkType" label={i18n.t('networkType')} required>
                <Input disabled />
              </Form.Item>
              <Form.Item name="address" label={i18n.t('address')} required>
                <Input disabled />
              </Form.Item>
              {form.getFieldValue('qrcode') && 
              <Form.Item name="qrcode" label={i18n.t('QR Code')}>
                <Image height={100} src={form.getFieldValue('qrcode')}/>
              </Form.Item>}
            </>
        }
      </Form>
    </Modal>
  )
}
// 有的
export const PopupDeleteAgentBank = ({ isOpen, close, currentItem, mutate }: any) => {
  const icon = useIcon();
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (formData: any) => {
    const response = await PostWithdrawmethodAgentDelete({
      Id: currentItem.Id,
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('deleteSuccess'));
      close();
      mutate();
    }
  };

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={icon.icon12} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button className="mt-1" key="cancel" onClick={close}>{i18n.t('cancel')}</Button>
          <Button className="mt-1" key="confirm" type="primary" loading={isLoading} onClick={onFinish}>{i18n.t('confirm')}</Button>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmDelete')}？</div>
      </Space>
    </Modal>
  )
}

// 返水/占成審核
export const PopupPaddingReview = ({ isOpen, close, currentBill, mode, mutate }: any) => {
  const { permissionCode: $p } = usePerm();

  const icon = useIcon();
  const [reviewList, setReviewList] = useState([]);

  const { data: _commissionticketList, isValidating: commissionticketValidating } = GetCommissionticketSaList({
    SAId: currentBill.SAId,
    Period: currentBill.Period
  }, (!!currentBill.SAId && mode === '1'));

  const { data: _revenueticket, isValidating: revenueticketValidating } = GetRevenueticketSaList({
    SAId: currentBill.SAId,
    Period: currentBill.Period
  }, (!!currentBill.SAId && mode === '2'));

  useEffect(() => {
    if ((_commissionticketList || _revenueticket) && isOpen) {
      const _list = mode === '1' ? _commissionticketList : _revenueticket
      const list = _list?.Data.map((item: any) => ({
        ...item,
        key: item.AgentId,
        RealAmount: item.Amount,
        Remark: ''
      }))
      setReviewList(list)

      list.forEach((item: any) => {
        form.setFieldValue(`RealAmount-${item.AgentId}`, item.Amount);
      });
    }
  }, [_commissionticketList, _revenueticket, isOpen]);

  // 聖經錯誤
  const onFinish = async (formData: any) => {
    const send = {
      SAId: currentBill.SAId,
      Period: currentBill.Period,
    }
    const resultData = reviewList.map((item: any) => ({
      Id: item[mode === '1' ? 'CommissionTicketId' : 'RevenueTicketId'],
      Code: item[mode === '1' ? 'CommissionTicketCode' : 'RevenueTicketCode'],
      Amount: item.Amount,
      RealAmount: formData[`RealAmount-${item.AgentId}`],
      Remark: formData[`Remark-${item.AgentId}`],
    }))
    let response = null;
    if (mode === '1') {
      response = await PostCommissionticketSaReceive({
        ...send,
        CommissionTicketResultData: resultData
      })
    } else {
      response = await PostRevenueticketSaReceive({
        ...send,
        RevenueTicketResultData: resultData
      })
    }
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      onClose();
      mutate();
    } else {
      const msg = RESPONSE_CODE[response.Message as keyof typeof RESPONSE_CODE];
      message.error(msg || i18n.t('processingFailed'));
    }
  };

  const [form] = useForm();

  const onClose = () => {
    form.resetFields();
    close();
  }

  const $editPermissions = $p.includes('40906');

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      width={1200}
      centered
      title={
        <Row align="middle" gutter={10}>
          <Col>{currentBill.SAAccount}</Col>
          <Col>{icon.WithdrawStateTag(0, currentBill.Period)}</Col>
        </Row>
      }
      footer={
        <Row justify="center">
          {
          $editPermissions 
            ? <Button type="primary" onClick={form.submit}>{i18n.t('pass')}</Button>
            : <Button onClick={onClose}>{i18n.t('close')}</Button>
          }
        </Row>
      }
    >
      <Row>
        <Col className="mt-1" span={24}>
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Table
              size="middle"
              // scroll={(data?.length > 5) ? { y: 350 } : undefined}
              loading={commissionticketValidating || revenueticketValidating}
              dataSource={reviewList}
              columns={[
                {
                  key: 1,
                  dataIndex: 'AgentAccount',
                  title: `${i18n.t('account')}／${i18n.t('proxyTag')}`,
                  className: 'size-12',
                  width: '50%',
                  render: (val, { Level, TagsId }) => {
                    const word = common.agentLevelWord(Level);
                    return `${word} / ${val}`
                  },
                },
                {
                  key: 2,
                  dataIndex: 'Amount',
                  title: i18n.t('originalAmount'),
                  className: 'size-12',
                  render: (val) => $f(val)
                },
                {
                  key: 3,
                  dataIndex: 'RealAmount',
                  title: <><span className="require">*</span><span>{i18n.t('distributionAmount')}</span></>,
                  className: 'size-12',
                  width: 200,
                  render: (val, { AgentId }) => (
                    <Form.Item name={`RealAmount-${AgentId}`} rules={verify({ point: 4, negative: true })}>
                      <Input defaultValue={val} placeholder={`${i18n.t('enter')}`} disabled={!$editPermissions} />
                    </Form.Item>
                  ),
                },
                {
                  key: 4,
                  dataIndex: 'Remark',
                  title: i18n.t('remark'),
                  className: 'size-12',
                  width: 200,
                  render: (val, { AgentId }) => (
                    <Form.Item name={`Remark-${AgentId}`}>
                      <Input defaultValue={val} placeholder={`${i18n.t('enter')}`} disabled={!$editPermissions} />
                    </Form.Item>
                  ),
                },
              ]}
              pagination={false}
            />
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}

// 代理標籤
export const PopupAddAgentTag = ({ isOpen, close, mutate }: any) => {
  const [form] = useForm();
  const initialValues = {
    tagName: null,
    isShowTagName: true,
    textColor: '#FFFFFF',
    color: '#000000',
    sort: null,
    description: null,
    status: 1
  }
  const [showTextColor, setShowTextColor] = useState('#FFFFFF');
  const [showColor, setShowColor] = useState('#000000');
  const [title, setTitle] = useState<string>('');
  const [showTitle, setShowTitle] = useState(true);

  const onTextColorChange = (hex: string) => {
    setShowTextColor(hex);
    form.setFieldValue('textColor', hex);
  }

  const onColorChange = (hex: string) => {
    setShowColor(hex);
    form.setFieldValue('color', hex);
  }

  const onFinish = async (formData: any) => {

    const response = await CreateTag({
      Name: formData.tagName,
      IsShowName: formData.isShowTagName ? 1 : 0,
      TextColor: formData.textColor,
      Color: formData.color,
      Description: formData.description,
      Position: formData.sort,
      Status: formData.status
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('addSuccess'));
      onClose();
      mutate();
    } else {
      message.error(RESPONSE_CODE_TAG[response.Message as keyof typeof RESPONSE_CODE_TAG]);
    }

  }

  const onClose = () => {
    close();
    form.resetFields();
  }

  return (
    <Modal
      title={i18n.t('add')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
    >
      <Form layout="vertical" form={form} onFinish={onFinish} initialValues={initialValues} >
        <Row gutter={[0, 10]}>
          <Col span={24}>
            <Form.Item name="tagName" label={
              <Row align="middle" gutter={12}>
                <Col>{i18n.t('tagName')}</Col>
                <Col>
                  <Tag className="center"
                    style={
                      showTextColor || showColor
                        ? {
                          color: showTextColor,
                          background: showColor,
                          minWidth: 80,
                          minHeight: 25
                        } : {}
                    }>
                    {showTitle && title ? title : (showTitle) ? i18n.t('tagName') : ''}
                  </Tag>
                </Col>
              </Row>
            } rules={[{ required: true, message: `${i18n.t('enter')}` }]}>
              <Input placeholder={`${i18n.t('tagName')}`} onChange={e => setTitle(e.target.value)}
                maxLength={20} showCount />
            </Form.Item>
            <Form.Item name="isShowTagName" valuePropName="checked"
              rules={[{ required: true, message: `${i18n.t('enter')}` }]}>
              <Checkbox onClick={() => setShowTitle(!showTitle)}>
                {i18n.t('showTagName')}
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="textColor" label={i18n.t('tagFontColor')}
              rules={[{ required: true, message: `${i18n.t('enter')}` }]}>
              <ColorPicker className="w-full" onChange={e => onTextColorChange(e.toHexString())} showText />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="color" label={i18n.t('tagBackgroundColor')}
              rules={[{ required: true, message: `${i18n.t('enter')}` }]}>
              <ColorPicker className="w-full" onChange={e => onColorChange(e.toHexString())} showText />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="sort" label={i18n.t('sort')} rules={verify({ point: 0 })}>
              <InputNumber className="w-full" placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="description" label={i18n.t('description')}>
              <Input.TextArea
                size="middle"
                autoSize={{ minRows: 2, maxRows: 1 }}
                maxLength={100}
                showCount
                placeholder={`${i18n.t('enterDescription')}`}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="status" label={i18n.t('status')} required
              rules={[{ required: true, message: `${i18n.t('enter')}` }]}>
              <Select options={common.enumToOptions(ENABLE)} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col><Button onClick={onClose}>{i18n.t('cancel')}</Button></Col>
              <Col><Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button></Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal >
  )
}
export const PopupEditAgentTag = ({ isOpen, close, id, mutate }: any) => {
  const { permissionCode: $p } = usePerm();
  const [form] = useForm();
  const [showTextColor, setShowTextColor] = useState('#FFFFFF');
  const [showColor, setShowColor] = useState('#000000');
  const [title, setTitle] = useState<string>('');
  const [showTitle, setShowTitle] = useState(true);

  const { data: TagInfo, mutate: mutateOne, isValidating } = GetTagOne({ Id: id })

  useEffect(() => {
    if ( isOpen) mutateOne()
  }, [isOpen]);
  useEffect(() => {
    if (TagInfo && isOpen) {
      form.setFieldsValue({
        tagName: TagInfo.Name,
        isShowTagName: TagInfo.IsShowName,
        textColor: TagInfo.TextColor,
        color: TagInfo.Color,
        sort: TagInfo.Position,
        description: TagInfo.Description,
        status: TagInfo.Status
      })
      setShowTextColor(TagInfo.TextColor);
      setShowColor(TagInfo.Color);
      setTitle(TagInfo.Name);
      setShowTitle(TagInfo.IsShowName);
    }
  }, [TagInfo, isOpen]);

  const onTextColorChange = (hex: string) => {
    setShowTextColor(hex);
    form.setFieldValue('textColor', hex);
  }

  const onColorChange = (hex: string) => {
    setShowColor(hex);
    form.setFieldValue('color', hex);
  }

  const onFinish = async (formData: any) => {
    const response = await UpdateTag({
      Id: id,
      Name: formData.tagName,
      IsShowName: formData.isShowTagName ? 1 : 0,
      TextColor: formData.textColor,
      Color: formData.color,
      Description: formData.description,
      Position: formData.sort,
      Status: formData.status
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('editSuccess'));
      onClose();
      mutate();
    } else {
      message.error(RESPONSE_CODE_TAG[response.Message as keyof typeof RESPONSE_CODE_TAG]);
    }
  }

  const onClose = () => {
    close();
    form.resetFields();
  }

  const $editPermissions = $p.includes('41103');

  return (
    <Modal
      title={i18n.t('edit')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
    >
      <Spin spinning={isValidating}>
        <Form layout="vertical" form={form} onFinish={onFinish} >
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Form.Item name="tagName" label={
                <Row align="middle" gutter={12}>
                  <Col>{i18n.t('tagName')}</Col>
                  <Col>
                    <Tag className="center"
                      style={
                        showTextColor || showColor
                          ? {
                            color: showTextColor,
                            background: showColor,
                            minWidth: 80,
                            minHeight: 25
                          } : {}
                      }>
                      {showTitle && title ? title : (showTitle) ? i18n.t('tagName') : ''}
                    </Tag>
                  </Col>
                </Row>
              } rules={[{ required: true, message: `${i18n.t('enter')}` }]}>
                <Input 
                  placeholder={`${i18n.t('tagName')}`} 
                  maxLength={20} 
                  showCount 
                  onChange={e => setTitle(e.target.value)}
                  disabled={!$editPermissions} 
                />
              </Form.Item>
              <Form.Item name="isShowTagName" valuePropName="checked" rules={[{ required: true, message: `${i18n.t('enter')}` }]}>
                <Checkbox onClick={() => setShowTitle(!showTitle)} disabled={!$editPermissions}>
                  {i18n.t('showTagName')}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="textColor" label={i18n.t('tagFontColor')} rules={[{ required: true, message: `${i18n.t('enter')}` }]}>
                <ColorPicker className="w-full" onChange={e => onTextColorChange(e.toHexString())} showText
                  disabled={!$editPermissions} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="color" label={i18n.t('tagBackgroundColor')} rules={[{ required: true, message: `${i18n.t('enter')}` }]}>
                <ColorPicker className="w-full" onChange={e => onColorChange(e.toHexString())} showText
                  disabled={!$editPermissions} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="sort" label={i18n.t('sort')} rules={verify({ point: 0 })}>
                <InputNumber className="w-full" placeholder={`${i18n.t('enter')}`} disabled={!$editPermissions} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="description" label={i18n.t('description')}>
                <Input.TextArea
                  size="middle"
                  placeholder={`${i18n.t('enterDescription')}`}
                  maxLength={100}
                  autoSize={{ minRows: 2, maxRows: 1 }}
                  showCount
                  disabled={!$editPermissions}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="status" label={i18n.t('status')} rules={[{ required: true, message: `${i18n.t('enter')}` }]}>
                <Select options={common.enumToOptions(ENABLE)} disabled={!$editPermissions} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row justify="center" gutter={[12, 12]}>
                <Col><Button onClick={onClose}>{i18n.t('cancel')}</Button></Col>
                {
                $p.includes('41103') &&
                <Col><Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button></Col>
                }
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal >
  )
}
export const PopupAgentTagList = ({ isOpen, close, id, mutate }: any) => {
  enum UPDATE_TAG_TYPE {
    刪除 = 1,
    新增 = 2
  }
  const { permissionCode: $p } = usePerm();
  const [input, setInput] = useState('');
  const [page, setPage] = useState<number[]>([1, 5]);
  const [showTextColor, setShowTextColor] = useState('#FFFFFF');
  const [showColor, setShowColor] = useState('#000000');
  const [title, setTitle] = useState<string>('');
  const [showTitle, setShowTitle] = useState(true);
  const [isOpenDismissModal, setIsOpenDismissModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [agentId, setAccountId] = useState<number | null>(null);
  
  const { data: AgentList, isValidating, mutate: mutateAg } = GetTagAgentList({
    TagsId: id,
    PageIndex: page[0],
    PageSize: page[1]
  });
  
  const { data: TagInfo } = GetTagOne({ Id: id });

  useEffect(() => {
    if (TagInfo) {
      setShowTextColor(TagInfo.TextColor);
      setShowColor(TagInfo.Color);
      setTitle(TagInfo.Name);
      setShowTitle(TagInfo.IsShowName === 1);
    }
  }, [TagInfo]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => setSelectedRowKeys(newSelectedRowKeys);

  const onSetAgentTag = async () => {
    const response = await UpdateAgentTag({
      Account: input,
      Tags: [id],
      Type: UPDATE_TAG_TYPE.新增
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('addSuccess'));
      mutate();
      mutateAg();
      setInput('');
    } else {
      message.error(RESPONSE_CODE_TAG[response.Message as keyof typeof RESPONSE_CODE_TAG]);
    }
  }

  const onDelete = async (id: number) => {
    setIsOpenDismissModal(true);
    setAccountId(id);
  }

  const onClose = () => {
    close();
    setInput('');
    setSelectedRowKeys([]);
  }

  const onDeleteRefresh = () => {
    mutate();
    mutateAg();
  }

  const $editPermissions = $p.includes('41106');

  return (
    <Modal
      title={
        <Row align="middle" gutter={[12, 12]}>
          <Col style={{ fontSize: 16 }}>{i18n.t('agentAccount')}</Col>
          {/* <Col>
            <Tag className="center"
              style={
                showTextColor || showColor
                  ? {
                    color: showTextColor,
                    background: showColor,
                    minWidth: 80,
                    minHeight: 25
                  } : {}
              }>
              {showTitle && title ? title : (showTitle) ? i18n.t('tagName') : ''}
            </Tag>
          </Col> */}
        </Row>
      }
      open={isOpen}
      onCancel={onClose}
      width={500}
      centered
      footer={
        <Row justify="center">
          <Button onClick={onClose}>{i18n.t('close')}</Button>
        </Row>
      }
    >
      <Spin spinning={isValidating}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Row align="middle">
              <Col span={12}>
                {i18n.t('tagAgent')} : {AgentList ? AgentList.TotalRecord : 0}
              </Col>
              <Col span={12}>
                {i18n.t('agentTotal')} : {AgentList ? AgentList.AgentTotal : 0}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row align="middle" justify="space-between">
              <Col span={18}>
                <Input 
                  placeholder={`${i18n.t('agentAccount')}`} 
                  value={input}
                  onChange={e => setInput(e.target.value)} 
                  disabled={!$editPermissions} 
                />
              </Col>
              <Col span={5}>
                <Button disabled={!input || !$editPermissions} onClick={onSetAgentTag} >
                  {i18n.t('setTag')}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Button 
              type="primary" danger
              disabled={selectedRowKeys.length === 0 || !$editPermissions}
              onClick={() => setIsOpenDismissModal(true)}
            >
              {i18n.t('batchRelease')}
            </Button>
          </Col>
          <Col span={24}>
            <Table
              size="small"
              rowSelection={{
                selectedRowKeys,
                onChange: onSelectChange,
              }}
              dataSource={
                AgentList ?
                  AgentList.Data.map((item: AgentTagInfo) => (
                    {
                      key: item.Id,
                      account: item.Account
                    }
                  )) : []
              }
              columns={[
                {
                  title: i18n.t('agentAccount'),
                  dataIndex: 'account',
                },
                {
                  title: i18n.t('operation'),
                  width: 100,
                  render: (_, record) =>
                    <Button 
                      className="size-12" 
                      type="link" 
                      onClick={() => onDelete(record.key)} 
                      disabled={selectedRowKeys.length !== 0 || !$editPermissions}
                    >
                      {i18n.t('removeTag')}
                    </Button>
                },
              ]}
              pagination={false}
            />
            <LayoutPagination total={AgentList ? AgentList.TotalRecord : 0} setPage={setPage}
              page={page} defaultPageSize={5} />
          </Col>
        </Row>
      </Spin>
      {/* Modal */}
      <PopupDismissTag isOpen={isOpenDismissModal} close={() => setIsOpenDismissModal(false)}
        selected={selectedRowKeys} agentId={agentId} tagId={id} mutate={onDeleteRefresh} setSelectedRowKeys={setSelectedRowKeys} />
    </Modal >
  )
}
export const PopupDismissTag = ({ isOpen, close, selected, agentId, tagId, mutate, setSelectedRowKeys }: any) => {
  enum UPDATE_TAG_TYPE {
    刪除 = 1,
    新增 = 2
  }

  const icon = useIcon();

  const onDelete = async () => {
    let response: any;
    if (selected?.length !== 0) {
      // 批次解除
      selected?.map(async (agentId: React.Key) => {

        await UpdateAgentTag({
          Id: agentId,
          Tags: [tagId],
          Type: UPDATE_TAG_TYPE.刪除
        })
        mutate();
      })
      message.success(i18n.t('operationSuccess'));
      setSelectedRowKeys([]);
      close();
    } else {

      // 單一解除
      response = await UpdateAgentTag({
        Id: agentId,
        Tags: [tagId],
        Type: UPDATE_TAG_TYPE.刪除
      })
      if (response.State === RESPONSE_TYPE.成功) {
        message.success(i18n.t('operationSuccess'));
        close();
        mutate();
      } else {
        message.error(RESPONSE_CODE_TAG[response.Message as keyof typeof RESPONSE_CODE_TAG]);
      }

    }
  }

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={icon.icon12} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button className="mt-1" onClick={close}>{i18n.t('cancel')}</Button>
          <Button className="mt-1" type="primary" onClick={onDelete}>{i18n.t('confirm')}</Button>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{selected ? `${i18n.t('confirmBatchReleaseAgentTag')} ?` : `${i18n.t('confirmReleaseAgentTag')} ?`}</div>
      </Space>
    </Modal>
  )
}
