import { DatabaseFilled } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Popover, Row, Select, Space, Spin, Tag } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Content } from 'antd/es/layout/layout';
import { ViewAgentSaList } from 'components/agent.component';
import { Export, LayoutDateSelect, LayoutNav } from 'components/layout.component';
import { ExcelColumns } from 'constants/excel';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'enum/date';
import { AGENT_STATE } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import { StoreContext } from "hooks/store.hook";
import i18n from 'i18n';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetAgentList, GetAgentSaList } from 'services/agent.service';
import { agentLevelWord, enumToOptions } from 'utils/common';

// 總代

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();
  const navigate = useNavigate();
  const [showColumn, setShowColumn] = useState<string[]>([]);

  const viewAgentSaList = useRef<any>();

  // 存搜尋聖經
  const { useSearchAgent, useAgentList } = useContext(StoreContext);
  const [searchAgent, setSearchAgent] = useSearchAgent;
  const [agentListTemp, setAgentListTemp] = useAgentList;

  const [date, setDate] = useState<string[]>();
  const [reset, setReset] = useState<boolean>(false);
  const [form] = Form.useForm();

  const defaultDate = '1';
  const [timeMode, setTimeMode] = useState<any>(defaultDate);
  const [agentSaParam, setAgentSaParam] = useState<any>({
    PageIndex: 1,
    PageSize: 10,
    CreateDateStartTime: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    CreateDateEndTime: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  })
  const [findAll, setFindAll] = useState(false);
  const { data: agentSaData, mutate: mutateSa, isValidating } = GetAgentSaList(searchAgent || agentSaParam);
  // 子代小標籤
  const [agentList, setAgentList] = useState<any>();
  const { data: _agentList, mutate: mutateAg, isValidating: allValidating } = GetAgentList({
    ...(searchAgent || agentSaParam),
    PageSize: 999
  }, !!findAll);
  useEffect(() => {
    if (_agentList) {
      setAgentList(_agentList)
      setAgentListTemp(_agentList)
    }
  }, [_agentList]);

  const search = (formData: any) => {
    // const timeMode = form.getFieldValue('TimeMode') || defaultDate;
    const type = timeMode === defaultDate ? 'Create' : 'Login';
    const send: any = {
      Account: formData.Account,
      RefCode: formData.RefCode,
      Status: formData.Status,
      PageIndex: 1,
      PageSize: agentSaParam.PageSize,
      // [`${type}DateStartTime`]: date ? date[0] : dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      // [`${type}DateEndTime`]: date ? date[1] : dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    }
    if (timeMode !== '3') {
      send[`${type}DateStartTime`] = date ? date[0] : dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss')
      send[`${type}DateEndTime`] =  date ? date[1] : dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss')
    }
    setAgentSaParam(send);
    setSearchAgent(send);
    mutateAll();
    viewAgentSaList.current?.refresh();
    const condition1 = [AGENT_STATE.frozen, AGENT_STATE.pendingApproval].includes(formData.Status)
    const condition2 = formData.Account && formData.Account !== ''
    const condition3 = formData.RefCode && formData.RefCode !== ''
    setFindAll(condition1 || condition2 || condition3)
  }

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setAgentSaParam({
      ...agentSaParam,
      PageSize: 10,
      PageIndex: 1,
    })
    setTimeMode(defaultDate);
  }

  const handlePageChange = (pagination: any) => {
    const param = searchAgent || agentSaParam
    setAgentSaParam({ ...param, PageIndex: pagination.current, PageSize: pagination.pageSize })
    setSearchAgent({ ...param, PageIndex: pagination.current, PageSize: pagination.pageSize })
  }

  const openAgent = (item: any) => {
    viewAgentSaList.current?.openAgent(item);
  }

  const mutateAll = () => {
    mutateSa();
    mutateAg();
  }

  const onChangeColumn = (name: string, checked: boolean) => {
    if (checked) {
      setShowColumn([
        ...showColumn,
        name,
      ])
    } else {
      setShowColumn(showColumn.filter(item => item !== name));
    }
  };

  useEffect(() => {
    setShowColumn([
      'Account',
      'TagsId',
      'RefCode',
      'DownlineAgentCount',
      'DownlineMemberCount',
      'Balance',
      'Status',
      'LastLoginDate',
      'CreateDate',
      'IP',
      'CommissionSettleCycle',
      'RevenueShareSettleCycle',
      'Operation',
    ]);
    if (agentListTemp) setAgentList(agentListTemp)
  }, []);

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Form form={form} onFinish={search} initialValues={{
          TimeMode: defaultDate
        }}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col className="w-12">
                  <Form.Item name="Account">
                    <Input placeholder={`${i18n.t('account')}`} />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="RefCode">
                    <Input placeholder={`${i18n.t('referralCode')}`} />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="Status">
                    <Select
                      className="w-full"
                      placeholder={i18n.t('status')}
                      options={[
                        { value: '', label: i18n.t('all') },
                        ...enumToOptions(AGENT_STATE)
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col className="w-12">
                  <Form.Item name="TimeMode">
                    <Select
                      className="w-full"
                      options={[
                        { value: '1', label: `${i18n.t('createTime')}` },
                        { value: '2', label: `${i18n.t('lastLoginTime')}` },
                        { value: '3', label: `${i18n.t('noTimeLimit')}` },
                      ]}
                      onChange={setTimeMode}
                    />
                  </Form.Item>
                </Col>
                <LayoutDateSelect width={375} onChange={setDate}
                  dateFormatDefault={DATE_FORMAT.日期時間} reset={reset} 
                  disabled={timeMode === '3'} />
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              {/* 找子代小按鈕 */}
              {findAll &&
                <Spin spinning={allValidating}>
                  <Space size={[0, 8]} wrap>
                    {agentList?.Data.map((item: any) => {
                      const di = item.AGsId.split('|').length - 1;
                      const word = agentLevelWord(di);
                      return <Tag key={item.Id} color="#87d068" onClick={() => openAgent(item)} style={{ cursor: 'pointer' }}>
                        {`${word} / ${item.Account}`}
                      </Tag>
                    })}
                  </Space>
                </Spin>
              }
            </Col>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col>
                  <Row align="middle" gutter={[12, 12]}>
                    {
                      $p.includes('40202') &&
                      <Col>
                        <Button type="primary" onClick={() => navigate('/agent/add')}>{i18n.t('add')}</Button>
                      </Col>
                    }
                    {
                      $p.includes('40102') &&
                      <Col>
                        <Export url={'/agent/sa/list'} param={{
                          ...agentSaParam, PageSize: agentSaData && agentSaData.TotalRecord, PageIndex: 1
                        }} sheetName={i18n.t('generalAgentList')} columns={ExcelColumns.AgentList} />
                      </Col>
                    }
                  </Row>
                </Col>
                <Col>
                  {/* 欄位顯示 */}
                  <Popover
                    content={<>
                      <div className="w-8">
                        <div>
                          <Checkbox
                            checked={showColumn.includes('Account')}
                            onChange={(e: CheckboxChangeEvent) => onChangeColumn('Account', e.target.checked)}
                          >{i18n.t('account')}
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            checked={showColumn.includes('TagsId')}
                            onChange={(e: CheckboxChangeEvent) => onChangeColumn('TagsId', e.target.checked)}
                          >{i18n.t('agentTag')}
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            checked={showColumn.includes('RefCode')}
                            onChange={(e: CheckboxChangeEvent) => onChangeColumn('RefCode', e.target.checked)}
                          >{i18n.t('referralCode')}
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            checked={showColumn.includes('DownlineAgentCount')}
                            onChange={(e: CheckboxChangeEvent) => onChangeColumn('DownlineAgentCount', e.target.checked)}
                          >{i18n.t('teamAgentCount')}
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            checked={showColumn.includes('DownlineMemberCount')}
                            onChange={(e: CheckboxChangeEvent) => onChangeColumn('DownlineMemberCount', e.target.checked)}
                          >{i18n.t('teamMemberCount')}
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            checked={showColumn.includes('Balance')}
                            onChange={(e: CheckboxChangeEvent) => onChangeColumn('Balance', e.target.checked)}
                          >{i18n.t('walletBalance')}
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            checked={showColumn.includes('Status')}
                            onChange={(e: CheckboxChangeEvent) => onChangeColumn('Status', e.target.checked)}
                          >{i18n.t('status')}
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            checked={showColumn.includes('LastLoginDate')}
                            onChange={(e: CheckboxChangeEvent) => onChangeColumn('LastLoginDate', e.target.checked)}
                          >{i18n.t('lastLoginTime')}
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            checked={showColumn.includes('CreateDate')}
                            onChange={(e: CheckboxChangeEvent) => onChangeColumn('CreateDate', e.target.checked)}
                          >{i18n.t('createTime')}
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            checked={showColumn.includes('IP')}
                            onChange={(e: CheckboxChangeEvent) => onChangeColumn('IP', e.target.checked)}
                          >{i18n.t('IP')}
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            checked={showColumn.includes('CommissionSettleCycle')}
                            onChange={(e: CheckboxChangeEvent) => onChangeColumn('CommissionSettleCycle', e.target.checked)}
                          >{i18n.t('rebate')}
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            checked={showColumn.includes('RevenueShareSettleCycle')}
                            onChange={(e: CheckboxChangeEvent) => onChangeColumn('RevenueShareSettleCycle', e.target.checked)}
                          >{i18n.t('commissionRate')}
                          </Checkbox>
                        </div>
                      </div>
                    </>}
                    placement="bottomRight"
                    trigger="click"
                  >
                    <Button><DatabaseFilled /></Button>
                  </Popover>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Spin spinning={isValidating}>
                <ViewAgentSaList refs={viewAgentSaList}
                  agentSaData={agentSaData} showColumn={showColumn} setPage={handlePageChange}
                  pageIndex={agentSaParam?.PageIndex} pageSize={agentSaParam?.PageSize}
                />
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;