import { Button, Col, Form, Input, Modal, Row, Spin, Table, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { RESPONSE_CODE_MAIL } from 'constants/response';
import { MESSAGE_STATE, RESPONSE_TYPE } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import { useEffect, useState } from 'react';
import { FetchMailDetail, PostMailCreate } from 'services/mail.service';

export const ViewMessageList = ({ data, mutate }: any) => {
  const { permissionCode: $p } = usePerm();
  const [id, setId] = useState<number | undefined>();
  const [isOpenModal, setIsOpenModal] = useState(false);

  // 狀態顏色
  const stateColor = (state: number) => {
    switch (state) {
      case MESSAGE_STATE.已讀取:
        return 'color-pass'
      case MESSAGE_STATE.未讀取:
        return 'color-reject'
      default: return ''
    }
  }

  const handleInfo = (key: number) => {
    setIsOpenModal(true);
    setId(key);
  };

  return (
    <>
      <Row className="mb-1">
        {
          $p.includes('11002') &&
          <Col span={24}>
            <Button type="primary" onClick={() => handleInfo(0)}>
              {i18n.t('add')}
            </Button>
          </Col>
        }
      </Row>
      <Table
        size="middle"
        scroll={{ x: 1500 }}
        dataSource={data?.Data?.map((item: any) => ({ key: item.Id, ...item }))}
        columns={[
          {
            key: 1,
            dataIndex: 'Title',
            title: i18n.t('subject'),
            className: 'size-12',
            width: 300,
            fixed: 'left'
          },
          {
            key: 2,
            dataIndex: 'Content',
            title: i18n.t('content'),
            className: 'size-12',
            width: 400,
          },
          {
            key: 4,
            dataIndex: 'SenderAccount',
            title: i18n.t('creator'),
            className: 'size-12',
            width: 200
          },
          {
            key: 5,
            dataIndex: 'CreateDate',
            title: i18n.t('createAndSendTime'),
            className: 'size-12',
            width: 150,
          },
          {
            key: 8,
            dataIndex: 'IsRead',
            title: i18n.t('status'),
            className: 'size-12',
            width: 100,
            render: (val) => <div className={stateColor(val)}>{MESSAGE_STATE[val]}</div>
          },
          {
            key: 5,
            dataIndex: 'ReadTime',
            title: i18n.t('readTime'),
            className: 'size-12',
            width: 150,
            render: (val) => val !== '0000-00-00 00:00:00' ? val : '-'
          },
          {
            key: 9,
            title: i18n.t('operation'),
            className: 'size-12',
            width: 100,
            fixed: 'right',
            render: (_, record) => (
              <Button className="size-12" type="link" onClick={() => handleInfo(record.key)}>{i18n.t('detail')}</Button>
            )
          },
        ]}
        pagination={false}
      />
      <PopupMessage isOpen={isOpenModal} close={() => setIsOpenModal(false)} mutate={mutate} id={id} />
    </>
  )
}

// 新增即時訊息
export const PopupMessage = ({ isOpen, close, mutate, id }: any) => {
  const [form] = useForm();
  const { data: mailInfo } = FetchMailDetail({
    Id: id
  }, !!id)

  useEffect(() => {
    if (mailInfo) {
      form.setFieldsValue({
        title: mailInfo.Title,
        content: mailInfo.Content,
        recipient: mailInfo.MemberAccount
      })
    }
  }, [mailInfo]);

  const [loading, setLoading] = useState(false);
  const onFinish = async (formData: any) => {
    // 聖經post
    // $post({
    //   url: '/mail/create',
    //   send: {
    //     Account: formData.recipient,
    //     Title: formData.title,
    //     Content: formData.content
    //   },
    //   success: () => {
    //     message.success(i18n.t('addSuccess'));
    //     onClose();
    //     mutate();
    //   }
    // }, setLoading)
    try {
      const response = await PostMailCreate({
        Account: formData.recipient,
        Title: formData.title,
        Content: formData.content
      })
      if (response.State === RESPONSE_TYPE.成功) {
        message.success(i18n.t('addSuccess'));
        onClose();
        mutate();
      } else {
        message.error(RESPONSE_CODE_MAIL[response.Message as keyof typeof RESPONSE_CODE_MAIL]);
      }
    } catch (error) {
      return;
    }
  }

  const onClose = () => {
    form.resetFields();
    close();
  }

  return (
    <Modal
      title={!id ? i18n.t('add') : i18n.t('detail')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Form.Item name="title" label={i18n.t('subject')} rules={[{ required: true }]}>
                <Input placeholder={`${i18n.t('enterSubject')}`} disabled={!!id} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="content" label={i18n.t('content')} rules={[{ required: true }]}>
                <Input placeholder={`${i18n.t('enterContent')}`} disabled={!!id} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="recipient" label={i18n.t('recipient')} rules={[{ required: true }]}>
                <Input placeholder={`${i18n.t('enter')}`} disabled={!!id} />
              </Form.Item >
            </Col>
            <Col span={24}>
              <Row justify="center" className="mt-1" gutter={[12, 12]}>
                <Col>
                  <Button key="cancel" onClick={onClose}>{i18n.t('cancel')}</Button>
                </Col>
                {
                  !id &&
                  <Col>
                    <Button key="confirm" htmlType="submit" type="primary">{i18n.t('sendMessage')}</Button>
                  </Col>
                }
              </Row>
            </Col>
          </Row>
        </Spin>
      </Form>
    </Modal>
  )
}
