import {
  axiosGetAllData,
  axiosGetAuth,
  axiosPostAuth,
  baseURL,
  useFetchDataCommon
} from "services";

export const GetContentsList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/contents/list`, params);
export const GetContentsInfo = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/contents/one`, params)

export const PostContentsAnnouncementCreate = (data: any) => axiosPostAuth(`${baseURL}/contents/announcement/create`, data);
export const PostContentsDelete = (data: any) => axiosPostAuth(`${baseURL}/contents/delete`, data);
export const PostContentsAnnouncementUpdate = (data: any) => axiosPostAuth(`${baseURL}/contents/announcement/update`, data);

export const PostContentsSystemCreate = (data: any) => axiosPostAuth(`${baseURL}/contents/system/create`, data);
export const PostContentsSystemUpdate = (data: any) => axiosPostAuth(`${baseURL}/contents/system/update`, data);

export const GetContentsPublishList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/contents/system/publish/list`, params);
export const GetContentSystemList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/contents/system/list`, params);

export const GetLicenseList = () => useFetchDataCommon(axiosGetAllData, `${baseURL}/contents/license/list`);
export const CreateLicense = (data: any) => axiosPostAuth(`${baseURL}/contents/license/create`, data);
export const UpdateLicense = (data: any) => axiosPostAuth(`${baseURL}/contents/license/update`, data);
export const DeleteLicense = (data: any) => axiosPostAuth(`${baseURL}/contents/license/delete`, data);