export enum PROMOTION_CATEGORY {
  推薦獎勵 = 1,
  每日簽到 = 2,
  其他優惠 = 99
}
export enum PROMOTION_STATE {
  已上架 = 1,
  未上架 = 0,
}
export enum SETTLE_CYCLE {
  日結 = 0,
  週結 = 1,
  月結 = 2
}
export enum MERCHANT_TYPE {
  入款 = 1,
  出款 = 2
}
export enum SETTLEMENT {
  返水 = 1,
  占成 = 2
}
