import { Button, Col, Form, Input, Row, Table, Tabs, TabsProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { LayoutDateSelect, LayoutNav } from 'components/layout.component';
import { GameCategorySelect } from 'components/member.component';
import { ReportAgentList } from 'components/report.component';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'enum/date';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { GetReportBetAgList, GetReportBetGameList, GetReportBetMemberList } from 'services/report.service';
import * as common from 'utils/common';
import { toFormatNumber as $f, agentLevelWord } from 'utils/common';

// 代理統計分析
// NOTE: 同步BO代理優惠統計, 代理統計分析, AG成本報表

enum TAB {
  代理 = '1',
  會員 = '2',
  遊戲 = '3'
}

const PageMain: React.FC = () => {
  const [activeKey, setActiveKey] = useState<string>(TAB.代理);
  const [form] = useForm();
  
  const items: TabsProps['items'] = [
    {
      key: TAB.代理,
      label: <div className="w-5 text-center">{i18n.t('agent')}</div>
    },
    {
      key: TAB.會員,
      label: <div className="w-5 text-center">{i18n.t('member')}</div>
    },
    {
      key: TAB.遊戲,
      label: <div className="w-5 text-center">{i18n.t('game')}</div>
    },
  ];

  const onTabClick = (key: string) => {
    setParams({ ...params, PageIndex: 1 });
    setActiveKey(key);
    updateTotal(key);
  }

  const [total, setTotal] = useState({
    key: 1,
    TotalCount: 0,
    TotalBetAmount: 0,
    TotalWinLoss: 0,
  });
  const updateTotal = (key: any) => {
    switch (key) {
      case TAB.代理:
        setTotal({
          key: 1,
          TotalCount: _saList?.TotalCount,
          TotalBetAmount: _saList?.TotalBetAmount,
          TotalWinLoss: _saList?.TotalWinLoss,
        })
        break;
      case TAB.會員:
        setTotal({
          key: 1,
          TotalCount: _memberList?.TotalCount,
          TotalBetAmount: _memberList?.TotalBetAmount,
          TotalWinLoss: _memberList?.TotalWinLoss,
        })
        break;
      case TAB.遊戲:
        setTotal({
          key: 1,
          TotalCount: _gameList?.TotalCount,
          TotalBetAmount: _gameList?.TotalBetAmount,
          TotalWinLoss: _gameList?.TotalWinLoss,
        })
        break;
    }
  }

  const [date, setDate] = useState<string[]>();
  const [reset, setReset] = useState<boolean>(false);
  const [params, setParams] = useState<any>({
    StartDate: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    EndDate: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    PageIndex: 1,
    PageSize: 10,
  });
  const { data: _saList, mutate: mutateSa, isValidating: saValidating } = GetReportBetAgList(params);
  const { data: _memberList, mutate: mutateMember, isValidating: memberValidating } = GetReportBetMemberList(params);
  const { data: _gameList, mutate: mutateGame, isValidating: gameValidating } = GetReportBetGameList(params);
  useEffect(() => {
    updateTotal(activeKey)
  }, [_saList, _memberList, _gameList]);

  const search = (formData: any) => {
    setParams({
      StartDate: date ? date[0] : dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      EndDate: date ? date[1] : dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      PageIndex: 1,
      PageSize: params.PageSize,
      AgentAccount: formData.AgentAccount,
      MemberAccount: formData.MemberAccount,
      CategoryId: formData.CategoryId,
    })
    mutateSa()
    mutateMember()
    mutateGame()
  }

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setParams({
      ...params,
      PageSize: 10,
      PageIndex: 1,
    })
  }

  const searchAgent = (agent: any, tab: any) => {
    setActiveKey(tab);
    setParams({
      ...params,
      PageIndex: 1,
      AgentAccount: agent.Account,
      MemberAccount: null,
      CategoryId: null,
    })
    form.setFieldsValue({
      AgentAccount: agent.Account,
      MemberAccount: null,
      CategoryId: null,
    });
  };

  const searchMember = (member: any, tab: any) => {
    setActiveKey(tab);
    setParams({
      ...params,
      PageIndex: 1,
      AgentAccount: null,
      MemberAccount: member.MemberAccount,
      CategoryId: null,
    })
    form.setFieldsValue({
      AgentAccount: null,
      MemberAccount: member.Account,
      CategoryId: null,
    });
  };

  const columns: any = [
    {
      key: 1,
      dataIndex: 'Account',
      title: i18n.t('agentAccount'),
      className: 'size-12',
      width: 300,
      render: (val: any, record: any) => {
        const word = agentLevelWord(record.Level);
        return `${word} / ${val}`
      }
    },
    {
      key: 2,
      dataIndex: 'up_count',
      title: i18n.t('teamBetCount'),
      className: 'size-12',
      align: 'right',
      width: 200,
      // sorter: (a, b) => a.teamBet - b.teamBet,
      render: (val: any) => $f(val, 0)
    },
    {
      key: 3,
      dataIndex: 'up_BetAmount',
      title: i18n.t('teamValidBets'),
      className: 'size-12',
      align: 'right',
      width: 200,
      // sorter: (a, b) => a.teamValidBet - b.teamValidBet,
      render: (val: any) => $f(val)
    },
    {
      key: 4,
      dataIndex: 'up_WinLossAmount',
      title: i18n.t('teamWinLossAmount'),
      className: 'size-12',
      align: 'right',
      width: 200,
      // sorter: (a, b) => a.teamWinAndLoss - b.teamWinAndLoss,
      render: (val: any) => <div className={common.numColor01(val)}>{$f(val)}</div>
    },
    {
      key: 5,
      dataIndex: 'bet_count',
      title: i18n.t('betCounts'),
      className: 'size-12',
      align: 'right',
      width: 200,
      // sorter: (a, b) => a.betTotal - b.betTotal,
      render: (val: any) => $f(val, 0)
    },
    {
      key: 6,
      dataIndex: 'ValidBetAmount',
      title: i18n.t('validBet'),
      className: 'size-12',
      align: 'right',
      width: 200,
      // sorter: (a, b) => a.validBetTotal - b.validBetTotal,
      render: (val: any) => $f(val)
    },
    {
      key: 7,
      dataIndex: 'WinLossAmount',
      title: i18n.t('totalWinLossAmounts'),
      className: 'size-12',
      align: 'right',
      width: 200,
      // sorter: (a, b) => a.winAndLoss - b.winAndLoss,
      render: (val: any) => <div className={common.numColor01(val)}>{$f(val)}</div>
    },
    {
      key: 8,
      title: i18n.t('operation'),
      className: 'size-12',
      width: 200,
      render: (_: any, record: any) => (
        <>
          <Button className="size-12" type="link" onClick={() => searchAgent(record, TAB.會員)}>
            {i18n.t('viewMember')}
          </Button>
          <Button className="size-12" type="link" onClick={() => searchAgent(record, TAB.遊戲)}>
            {i18n.t('viewGame')}
          </Button>
        </>
      )
    },
  ]

  return (
    <div id="container">
      <LayoutNav />
      <Content className="p-2">
        {/* 搜尋列 */}
        <Form form={form} onFinish={search}>
          <Row align="middle" gutter={[12, 12]}>
            <Col className='w-11'>
              <Form.Item name="AgentAccount">
                <Input placeholder={`${i18n.t('agentAccount')}`} />
              </Form.Item>
            </Col>
            <Col className='w-11'>
              <Form.Item name="MemberAccount">
                <Input placeholder={`${i18n.t('memberAccount')}`} />
              </Form.Item>
            </Col>
            <Col className='w-12'>
              <Form.Item name="CategoryId">
                <GameCategorySelect name="CategoryId" form={form} />
              </Form.Item>
            </Col>
            <LayoutDateSelect dateFormatDefault={DATE_FORMAT.日期時間} onChange={setDate} reset={reset} />
            <Col>
              <Button type="primary" htmlType="submit" loading={saValidating}>{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
        </Form>
        {/* 數據總覽 */}
        <Row className="mt-1" gutter={[10, 10]}>
          <Col span={24}>
            <Table
              size="small"
              dataSource={[total]}
              columns={[
                {
                  key: 1,
                  dataIndex: 'TotalCount',
                  title: i18n.t('totalBetCount'),
                  className: 'size-12',
                  align: 'right',
                  width: '33%',
                  render: (val) => $f(val, 0)
                },
                {
                  key: 2,
                  dataIndex: 'TotalBetAmount',
                  title: i18n.t('totalValidBet'),
                  className: 'size-12',
                  align: 'right',
                  width: '33%',
                  render: (val) => $f(val)
                },
                {
                  key: 3,
                  dataIndex: 'TotalWinLoss',
                  title: i18n.t('totalWinLossAmount'),
                  className: 'size-12',
                  align: 'right',
                  width: '34%',
                  render: (val) => <div className={common.numColor01(val)}>{$f(val)}</div>
                },
              ]}
              pagination={false}
            />
          </Col>
        </Row>
        <Tabs className="mt-1" activeKey={activeKey} items={items} onTabClick={onTabClick} />
        {/* 代理 */}
        {
          activeKey === TAB.代理 &&
          <Row className="mt-1" gutter={[10, 10]}>
            <Col span={24}>

              <ReportAgentList 
                agentSaData={_saList} isValidating={saValidating} 
                columns={columns} params={params} setParams={setParams} />
              
              {false && <Table
                size="middle"
                loading={saValidating}
                dataSource={_saList?.Data?.map((item: any) => ({ key: item.id, ...item }))}
                columns={columns}
                pagination={{
                  showSizeChanger: true,
                  showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
                  total: _saList?.TotalRecord,
                  current: params.PageIndex,
                  pageSize: params.PageSize
                }}
                onChange={p => setParams({ ...params, PageIndex: p.current, PageSize: p.pageSize })}
              />}
            </Col>
          </Row>
        }
        {/* 會員 */}
        {
          activeKey === TAB.會員 &&
          <Row className="mt-1" gutter={[10, 10]}>
            <Col span={24}>
              <Table
                size="middle"
                loading={memberValidating}
                dataSource={_memberList?.Data.map((item: any) => ({ key: item.id, ...item }))}
                columns={[
                  {
                    key: 1,
                    dataIndex: 'Account',
                    title: i18n.t('memberAccount'),
                    className: 'size-12',
                    width: 300,
                  },
                  {
                    key: 2,
                    dataIndex: 'up_count',
                    title: i18n.t('teamBetCount'),
                    className: 'size-12',
                    align: 'right',
                    width: 200,
                    // sorter: (a, b) => a.teamBet - b.teamBet,
                    render: (val) => $f(val, 0)
                  },
                  {
                    key: 3,
                    dataIndex: 'up_BetAmount',
                    title: i18n.t('teamValidBets'),
                    className: 'size-12',
                    align: 'right',
                    width: 200,
                    // sorter: (a, b) => a.teamValidBet - b.teamValidBet,
                    render: (val) => $f(val)
                  },
                  {
                    key: 4,
                    dataIndex: 'up_WinLossAmount',
                    title: i18n.t('teamWinLossAmount'),
                    className: 'size-12',
                    align: 'right',
                    width: 200,
                    // sorter: (a, b) => a.teamWinAndLoss - b.teamWinAndLoss,
                    render: (val) => <div className={common.numColor01(val)}>{$f(val)}</div>
                  },
                  {
                    key: 5,
                    dataIndex: 'bet_count',
                    title: i18n.t('betCounts'),
                    className: 'size-12',
                    align: 'right',
                    width: 200,
                    // sorter: (a, b) => a.betTotal - b.betTotal,
                    render: (val) => $f(val, 0)
                  },
                  {
                    key: 6,
                    dataIndex: 'ValidBetAmount',
                    title: i18n.t('validBet'),
                    className: 'size-12',
                    align: 'right',
                    width: 200,
                    // sorter: (a, b) => a.validBetTotal - b.validBetTotal,
                    render: (val) => $f(val)
                  },
                  {
                    key: 7,
                    dataIndex: 'WinLossAmount',
                    title: i18n.t('totalWinLossAmounts'),
                    className: 'size-12',
                    align: 'right',
                    width: 200,
                    // sorter: (a, b) => a.winAndLoss - b.winAndLoss,
                    render: (val) => <div className={common.numColor01(val)}>{$f(val)}</div>
                  },
                  {
                    key: 8,
                    title: i18n.t('operation'),
                    className: 'size-12',
                    width: 200,
                    render: (_, record) => (
                      <Button className="size-12" type="link" onClick={() => searchMember(record, TAB.遊戲)}>
                        {i18n.t('viewGame')}
                      </Button>
                    )
                  },
                ]}
                pagination={{
                  showSizeChanger: true,
                  showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
                  total: _memberList?.TotalRecord,
                  current: params.PageIndex,
                  pageSize: params.PageSize
                }}
                onChange={p => setParams({ ...params, PageIndex: p.current, PageSize: p.pageSize })}
              />
            </Col>
          </Row>
        }
        {/* 遊戲 */}
        {
          activeKey === TAB.遊戲 &&
          <Row className="mt-1" gutter={[10, 10]}>
            <Col span={24}>
              <Table
                size="middle"
                loading={gameValidating}
                dataSource={_gameList?.Data.map((item: any) => ({ key: item.GameProvider, ...item }))}
                columns={[
                  {
                    key: 1,
                    dataIndex: 'GameProvider',
                    title: i18n.t('gameCategoryGameName'),
                    className: 'size-12',
                    width: 900,
                  },
                  {
                    key: 2,
                    dataIndex: 'bet_count',
                    title: i18n.t('betCounts'),
                    className: 'size-12',
                    align: 'right',
                    width: 200,
                    // sorter: (a, b) => a.betTotal - b.betTotal,
                    render: (val) => $f(val, 0)
                  },
                  {
                    key: 3,
                    dataIndex: 'ValidBetAmount',
                    title: i18n.t('validBet'),
                    className: 'size-12',
                    align: 'right',
                    width: 200,
                    // sorter: (a, b) => a.validBetTotal - b.validBetTotal,
                    render: (val) => $f(val)
                  },
                  {
                    key: 4,
                    dataIndex: 'WinLossAmount',
                    title: i18n.t('totalWinLossAmounts'),
                    className: 'size-12',
                    align: 'right',
                    width: 200,
                    // sorter: (a, b) => a.winAndLoss - b.winAndLoss,
                    render: (val) => <div className={common.numColor01(val)}>{$f(val)}</div>
                  },
                ]}
                pagination={{
                  showSizeChanger: true,
                  showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
                  total: _gameList?.TotalRecord,
                  current: params.PageIndex,
                  pageSize: params.PageSize
                }}
                onChange={p => setParams({ ...params, PageIndex: p.current, PageSize: p.pageSize })}
              />
            </Col>
          </Row>
        }
      </Content>
    </div>
  );
};

export default PageMain;