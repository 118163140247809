import { Button, Col, Form, Image, Input, Modal, Row, Switch, Table, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { RESPONSE_CODE, RESPONSE_CODE_BANKCARD } from "constants/response";
import { MEMBER_BANK_STATE, MEMBER_WITHDRAW, MODE, RESPONSE_TYPE, VIRTUAL_STATE } from "enum/state";
import usePerm from "hooks/permission.hook";
import i18n from "i18n";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FetchMemberDetail } from "services/member.service";
import { DeleteMemberBank, GetWithdrawmethodMemberList, GetWithdrawmethodOne, PostWithdrawmethodMemberBankCreate, PostWithdrawmethodUpdateStatus, UpdateMemberBank } from "services/withdrawmethod.service";
import * as common from 'utils/common';
import { InquiryWindow } from "./layout.component";

// 會員銀行卡列表
export const ViewBank = ({ id }: any) => {
  const { permissionCode: $p } = usePerm();
  const [isOpenAddBankModal, setIsOpenAddBankModal] = useState(false);
  const [isOpenDeleteBankModal, setIsOpenDeleteBankModal] = useState(false);
  const [currentCard, setCurrentCard] = useState<any>({});
  const [mode, setMode] = useState(0);

  const { data, mutate, isValidating } = GetWithdrawmethodMemberList({
    MemberId: id,
  }, !!id);

  const handleEditState = async (record: any) => {
    // 狀態不是啟用
    const response = await PostWithdrawmethodUpdateStatus({
      Id: record.Id,
      Status: record.Status === VIRTUAL_STATE.啟用 ? VIRTUAL_STATE.停用 : VIRTUAL_STATE.啟用,
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      mutate();
    } else {
      const msg = RESPONSE_CODE[response.Message as keyof typeof RESPONSE_CODE];
      message.error(msg || i18n.t('processingFailed'));
    }
  }

  const handleDelete = (record: any) => {
    setIsOpenDeleteBankModal(true);
    setCurrentCard(record);
  }

  const submitDelete = async () => {
    const apiResult: ApiResult = await DeleteMemberBank({ Id: currentCard.Id });
    if (apiResult.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('deleteSuccess'));
      mutate();
    } else {
      message.error(RESPONSE_CODE_BANKCARD[apiResult.Message as keyof typeof RESPONSE_CODE_BANKCARD]);
    }
  }

  return (
    <>
      <Col span={24}>
        {
          $p.includes('10503') &&
          <Button type="primary" onClick={() => {
            setMode(MODE.新增);
            setCurrentCard({});
            setIsOpenAddBankModal(true);
          }}>{i18n.t('addBankCard')}</Button>
        }
      </Col>
      <Col span={24} className="mt-1">
        <Table
          loading={isValidating}
          size="middle"
          dataSource={
            data?.Data?.filter((item: any) => item.GatewayCode === MEMBER_WITHDRAW.銀行卡)
              .map((item: Bank) => ({ key: item.Id, ...item }))
          }
          columns={[
            {
              dataIndex: 'BankName',
              title: i18n.t('bankName'),
              className: 'size-12',
              width: 180,
              render: (val) => val || '-'
            },
            {
              dataIndex: 'Name',
              title: i18n.t('accountName'),
              className: 'size-12',
              width: 180,
            },
            {
              dataIndex: 'BankAccount',
              title: i18n.t('accountNumber'),
              className: 'size-12',
              width: 180,
            },
            {
              dataIndex: 'PhoneNumber',
              title: `${i18n.t('countryCode')} - ${i18n.t('phoneNumber')}`,
              className: 'size-12',
              width: 180,
              render: (val, { PhonePrefix }) => `${PhonePrefix} - ${val}`
            },
            {
              dataIndex: 'Branch',
              title: i18n.t('openingBranch'),
              className: 'size-12',
              width: 200,
              render: (val) => val || '-'
            },
            {
              dataIndex: 'State',
              title: i18n.t('bankProvince'),
              className: 'size-12',
              width: 200,
              render: (val) => val || '-'
            },
            {
              dataIndex: 'City',
              title: i18n.t('city'),
              className: 'size-12',
              width: 200,
              render: (val) => val || '-'
            },
            {
              dataIndex: 'Status',
              title: i18n.t('status'),
              className: 'size-12',
              width: 130,
              render: (val, record) => (
                <>
                  <Switch checked={val === VIRTUAL_STATE.啟用}
                    onChange={v => handleEditState(record)} disabled={val === -1 || val === 0 || !$p.includes('10505')} />
                </>
              )
            },
            {
              dataIndex: 'Status',
              title: i18n.t('verificationStatus'),
              className: 'size-12',
              width: 130,
              render: (val) =>
                <div className={common.stateColorBank(val)}>{MEMBER_BANK_STATE[val]}</div>
            },
            {
              title: i18n.t('operation'),
              className: 'size-12',
              width: 130,
              render: (_, record) => (
                <>
                  <Button className="size-12" type="link" onClick={() => {
                    setMode(MODE.詳情);
                    setCurrentCard(record);
                    setIsOpenAddBankModal(true);
                  }}>
                    {i18n.t('detail')}
                  </Button>
                  {
                    $p.includes('10504') &&
                    <Button className="size-12" type="link" onClick={() => handleDelete(record)}>
                      {i18n.t('delete')}
                    </Button>
                  }
                </>
              )
            },
          ]}
          pagination={false}
        />
        <PopupBankModal isOpen={isOpenAddBankModal} close={() => setIsOpenAddBankModal(false)} mutate={mutate} mode={mode} bankId={currentCard.Id} />
        <InquiryWindow isOpen={isOpenDeleteBankModal} close={() => setIsOpenDeleteBankModal(false)}
          msg={`${i18n.t('confirmDeletion')} ${currentCard.BankName} ${i18n.t('isData')}`} action={submitDelete} />
      </Col>
    </>
  )
}
// 新增詳情
export const PopupBankModal = ({ isOpen, close, mutate, mode, bankId }: any) => {
  const { id } = useParams();
  const [form] = useForm();
  const { data: member } = FetchMemberDetail({ Id: id }, !!id);

  const disabled = mode === MODE.詳情

  const { data } = GetWithdrawmethodOne({ Id: bankId }, !!bankId && (mode === MODE.編輯 || mode === MODE.詳情));
  useEffect(() => {
    if (data && isOpen) {
      form.setFieldsValue({
        BankName: data.BankName,
        Account: data.Account,
        Name: data.Name,
        Branch: data.Branch,
        State: data.State,
        City: data.City,
        PhonePrefix: data.PhonePrefix ? data.PhonePrefix.replace('+', '') : '',
        PhoneNumber: data.PhoneNumber,
      });
    }
  }, [data, isOpen]);

  const handleSubmit = async (formData: any) => {
    const send: any = {
      BankName: formData.BankName,
      Account: formData.Account,
      Name: formData.Name,
      Branch: formData.Branch,
      State: formData.State,
      City: formData.City,
      PhonePrefix: formData.PhonePrefix ? `+${formData.PhonePrefix}` : '',
      PhoneNumber: formData.PhoneNumber,
    }
    let apiResult: any = {};
    if (mode === MODE.新增) {
      send.OwnerId = member.Id;
      apiResult = await PostWithdrawmethodMemberBankCreate(send);
    } else {
      send.Id = data.Id;
      apiResult = await UpdateMemberBank(send);
    }
    if (apiResult.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('updateSuccess'));
      handleCancel();
      mutate();
    } else {
      message.error(RESPONSE_CODE_BANKCARD[apiResult.Message as keyof typeof RESPONSE_CODE_BANKCARD]);
    }
  }

  const handleCancel = () => {
    close();
    setTimeout(() => {
      form.resetFields();
    }, 800);
  }

  return (
    <Modal
      title={mode === MODE.新增 ? i18n.t('addBankCard') : i18n.t('bankCard')}
      open={isOpen}
      onCancel={handleCancel}
      centered
      width={450}
      footer={false}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="BankName" label={i18n.t('bankName')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('enter')}`} disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Account" label={i18n.t('accountNumber')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('enter')}`} disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Name" label={i18n.t('bankAccountName')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('enter')}`} disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Branch" label={i18n.t('openingBranch')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('enter')}`} disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="State" label={i18n.t('bankProvince')}>
              <Input placeholder={`${i18n.t('enter')}`} disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="City" label={i18n.t('city')}>
              <Input placeholder={`${i18n.t('enter')}`} disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={9}>
                <Form.Item name="PhonePrefix" label={i18n.t('phoneNumber')}
                  rules={common.verify({ point: 7, required: false })}>
                  <Input autoComplete="off" disabled={disabled} placeholder={`${i18n.t('enterCountryCode')}`} maxLength={50} addonBefore={'+'} />
                </Form.Item>
              </Col>
              <Col span={15}>
                <Form.Item name="PhoneNumber" label={' '}>
                  <Input disabled={disabled} placeholder={`${i18n.t('enterPhoneNumber')}`} maxLength={50} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center" className="pt-1">
          <Button onClick={handleCancel}>{i18n.t('cancel')}</Button>
          {
            !disabled &&
            <Button className="ml-1" type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
          }
        </Row>
      </Form>
    </Modal>
  )
}

// 會員虛擬貨幣列表
export const ViewVirtualCurrency = ({ id }: any) => {
  const { permissionCode: $p } = usePerm();
  const [isOpenAddBankModal, setIsOpenAddBankModal] = useState(false);
  const [isOpenDeleteBankModal, setIsOpenDeleteBankModal] = useState(false);
  const [currentCard, setCurrentCard] = useState<any>({});
  const [mode, setMode] = useState(0);

  const { data, mutate, isValidating } = GetWithdrawmethodMemberList({
    MemberId: id,
  }, !!id);

  const handleEdit = (record: any) => {
    setIsOpenAddBankModal(true);
    setCurrentCard(record);
    setMode(MODE.編輯);
  }

  const handleEditState = async (record: any) => {
    // 狀態不是啟用
    const response = await PostWithdrawmethodUpdateStatus({
      Id: record.Id,
      Status: record.Status === VIRTUAL_STATE.啟用 ? VIRTUAL_STATE.停用 : VIRTUAL_STATE.啟用,
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      mutate();
    } else {
      const msg = RESPONSE_CODE[response.Message as keyof typeof RESPONSE_CODE];
      message.error(msg || i18n.t('processingFailed'));
    }
  }

  const handleDelete = (record: any) => {
    setIsOpenDeleteBankModal(true);
    setCurrentCard(record);
  }

  const submitDelete = async () => {
    const apiResult: ApiResult = await DeleteMemberBank({ Id: currentCard.Id });
    if (apiResult.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('deleteSuccess'));
      mutate();
    } else {
      message.error(RESPONSE_CODE_BANKCARD[apiResult.Message as keyof typeof RESPONSE_CODE_BANKCARD]);
    }
  }

  return (
    <>
      <Col span={24} className="mt-1">
        <Table
          loading={isValidating}
          size="middle"
          dataSource={
            data?.Data?.filter((item: any) => item.GatewayCode === MEMBER_WITHDRAW.虛擬貨幣)
              .map((item: Bank) => ({ key: item.Id, ...item }))
          }
          columns={[
            {
              dataIndex: 'Crypto',
              title: i18n.t('currencyType'),
              className: 'size-12',
              width: 360,
            },
            {
              dataIndex: 'CryptoType',
              title: i18n.t('networkType'),
              className: 'size-12',
              width: 360,
            },
            {
              dataIndex: 'CryptoAddress',
              title: i18n.t('address'),
              className: 'size-12',
              width: 400,
            },
            {
              dataIndex: 'Photo',
              title: i18n.t('QRCode'),
              className: 'size-12',
              width: 200,
              render: (val) => (val && JSON.parse(val)[0]) 
                ? <Image className="center" src={JSON.parse(val)[0]} width={25} height={25} /> : '-'
            },
            {
              dataIndex: 'Status',
              title: i18n.t('status'),
              className: 'size-12',
              width: 130,
              render: (val, record) =>
                <Switch checked={val === VIRTUAL_STATE.啟用}
                  onChange={v => handleEditState(record)} disabled={val === -1 || val === 0 || $p.includes('10505')} />
            },
            {
              dataIndex: 'Status',
              title: i18n.t('verificationStatus'),
              className: 'size-12',
              width: 130,
              render: (val) =>
                <div className={common.stateColorBank(val)}>{MEMBER_BANK_STATE[val]}</div>
            },
            {
              title: i18n.t('operation'),
              className: 'size-12',
              width: 130,
              render: (_, record) => (
                <>
                  <Button className="size-12" type="link" onClick={() => {
                    setMode(MODE.詳情);
                    setCurrentCard(record);
                    setIsOpenAddBankModal(true);
                  }}>
                    {i18n.t('detail')}
                  </Button>
                  {
                    $p.includes('10504') &&
                    <Button className="size-12" type="link" onClick={() => handleDelete(record)}>
                      {i18n.t('delete')}
                    </Button>
                  }
                </>
              )
            },
          ]}
          pagination={false}
        />
        <PopupVirtualModal isOpen={isOpenAddBankModal} close={() => setIsOpenAddBankModal(false)} mutate={mutate} mode={mode} bankId={currentCard.Id} />
        <InquiryWindow isOpen={isOpenDeleteBankModal} close={() => setIsOpenDeleteBankModal(false)}
          msg={`${i18n.t('confirmDeletion')} ${currentCard.Crypto} ${i18n.t('isData')}`} action={submitDelete} />
      </Col>
    </>
  )
}

// 虛擬貨幣新增詳情
export const PopupVirtualModal = ({ isOpen, close, mutate, mode, bankId }: any) => {

  const { id } = useParams();
  const [form] = useForm();

  const disabled = mode === MODE.詳情

  const { data } = GetWithdrawmethodOne({ Id: bankId }, !!bankId && (mode === MODE.編輯 || mode === MODE.詳情));
  useEffect(() => {
    if (data && isOpen) {
      form.setFieldsValue({
        Crypto: data.Crypto,
        CryptoType: data.CryptoType,
        CryptoAddress: data.CryptoAddress,
      });
    }
  }, [data, isOpen]);

  // BO沒有新增虛擬貨幣
  const handleSubmit = async (formData: any) => {
    //   const send: any = {

    //   }
    //   let apiResult: any = {};
    //   if (mode === MODE.新增) {
    //     send.OwnerId = member.Id;
    //     apiResult = await PostWithdrawmethodMemberBankCreate(send);
    //   } else {
    //     send.Id = data.Id;
    //     apiResult = await UpdateMemberBank(send);
    //   }
    //   if (apiResult.State === RESPONSE_TYPE.成功) {
    //     message.success(i18n.t('updateSuccess'));
    //     handleCancel();
    //     mutate();
    //   } else {
    //     message.error(RESPONSE_CODE_BANKCARD[apiResult.Message as keyof typeof RESPONSE_CODE_BANKCARD]);
    //   }
  }

  const handleCancel = () => {
    close();
    setTimeout(() => {
      form.resetFields();
    }, 800);
  }

  return (
    <Modal
      title={mode === MODE.新增 ? i18n.t('addBankCard') : i18n.t('virtualCurrency')}
      open={isOpen}
      onCancel={handleCancel}
      centered
      width={450}
      footer={false}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="Crypto" label={i18n.t('currencyType')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('enter')}`} disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="CryptoType" label={i18n.t('networkType')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('enter')}`} disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="CryptoAddress" label={i18n.t('address')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('enter')}`} disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" className="pt-1">
          <Button onClick={handleCancel}>{i18n.t('cancel')}</Button>
          {
            !disabled &&
            <Button className="ml-1" type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
          }
        </Row>
      </Form>
    </Modal>
  )
}
