import {
  axiosGetAllData,
  axiosPostAuth,
  baseURL,
  useFetchDataCommon
} from "services";

export const GetProvidermaintaintenanceList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/providermaintenance/list`, params);
export const PostProvidermaintaintenanceDelete = (data: any) => axiosPostAuth(`${baseURL}/providermaintenance/delete`, data);
export const PostProvidermaintaintenanceCreate = (data: any) => axiosPostAuth(`${baseURL}/providermaintenance/create`, data);
export const PostProvidermaintaintenanceUpdate = (data: any) => axiosPostAuth(`${baseURL}/providermaintenance/update`, data);
