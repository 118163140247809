import { Button, Col, Form, Row, Select, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { LayoutDateSelect, LayoutNav, LayoutPagination } from 'components/layout.component';
import { ViewPromotionList } from 'components/promotion.component';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TYPE } from 'enum/date';
import { PROMOTION_CATEGORY, PROMOTION_STATE } from 'enum/promotion';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BonusList, BonusOption } from 'services/bonus.service';
import { enumToOptions } from 'utils/common';

// 優惠活動管理

interface SearchParams {
  Name?: string;
  Category?: number;
  Type?: number;
  Status?: number;
  TimeCondition?: number;
  StartDate?: string;
  EndDate?: string;
  DateType?: number | undefined;
  PageSize?: number;
  PageIndex?: number;
}

interface PromotionType {
  Id: number,
  Name: string
}

enum PROMOTION_TIME {
  活動時間 = 0,
  建立時間 = 1
}

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  const { permissionCode: $p } = usePerm();
  const [form] = useForm();
  const [reset, setReset] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [date, setDate] = useState<string[]>();
  const [params, setParams] = useState<SearchParams>({
    TimeCondition: PROMOTION_TIME.活動時間,
    StartDate: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    EndDate: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    DateType: DATE_TYPE.今天,
  })
  const { data: BonusOptions, isValidating: optionLoading } = BonusOption();
  const { data: promotion, mutate, isValidating } = BonusList({
    ...params,
    PageSize: page[1],
    PageIndex: page[0],
  });

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        ...params,
        Name: formData.promotionName,
        Category: formData.category,
        Status: formData.status,
        TimeCondition: formData.TimeCondition,
        StartDate: date[0],
        EndDate: date[1]
      })
    }
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setPage([1, 10]);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish} initialValues={{
          TimeCondition: PROMOTION_TIME.活動時間,
        }}>
          <Row gutter={[16, 16]} className="pb-1">
            <Col>
              <Form.Item className="w-12" name="promotionName">
                <Select
                  showSearch
                  loading={optionLoading}
                  placeholder={i18n.t('promotionName')}
                  options={
                    BonusOptions
                      ? BonusOptions.map((option: PromotionType) => (
                        { value: option.Name, label: option.Name }
                      ))
                      : [{ value: '', label: i18n.t('currentlyNoOngoingPromotions') }]
                  }
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item className="w-12" name="category">
                <Select
                  placeholder={i18n.t('category')}
                  options={enumToOptions(PROMOTION_CATEGORY)}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item className="w-12" name="status">
                <Select
                  placeholder={i18n.t('status')}
                  options={[
                    { value: '', label: i18n.t('all') },
                    ...enumToOptions(PROMOTION_STATE)
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="pb-1">
            <Col>
              <Form.Item className="w-12" name="TimeCondition">
                <Select options={enumToOptions(PROMOTION_TIME)} />
              </Form.Item>
            </Col>
            <LayoutDateSelect width={400} onChange={setDate} reset={reset}
              dateFormatDefault={DATE_FORMAT.日期時間} hiddenDateType={[DATE_TYPE.上週]} />
            <Col>
              <Button type="primary" className="mr-1" htmlType="submit">{i18n.t('search')}</Button>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
        </Form>
        <Row gutter={[16, 16]} className="pb-1">
          {
            $p.includes('30102') && <>
            <Col><Button type="primary" onClick={() => navigate('/promotion/other')}>{i18n.t('add')}</Button></Col>
            <Col><Button type="primary" onClick={() => navigate('/promotion/add/mission')}>{i18n.t('dailyCheckIn')}</Button></Col>
            </>
          }
        </Row>
        {/* 列表 */}
        <Spin spinning={isValidating}>
          <ViewPromotionList data={promotion} mutate={mutate} />
          <LayoutPagination total={promotion ? promotion.TotalRecord : 0} page={page} setPage={setPage} />
        </Spin>
      </Content>
    </div>
  );
};

export default PageMain; 