import { message } from "antd";
import dayjs from "dayjs";
import Decimal from 'decimal.js';
import { IP_STATE, MEMBER_BANK_STATE, STATE, WITHDRAW_STATE } from "enum/state";
import i18n from "i18n";

export function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text);
  message.success(i18n.t('copySuccess'));
}

export function limitStrLength(str: string, length: number) {
  return str.length > length ? str.substring(0, length) : str;
}

export function removeEmpty(obj: any) {
  Object.keys(obj).forEach(
    (k) => (obj[k] === "" || obj[k] === undefined) && delete obj[k]
  );
  return obj;
}

export function replaceChar(val: any) {
  return val.replace(/[^\d]+/g, "");
}

// 銀行末四碼
export function displayLastFourCharacters(input: string) {
  if (input.length > 0) {
    var lastFourCharacters = input.substring(input.length - 4);
    return lastFourCharacters;
  } else {
    return ''
  }
}

// 只能是數字
export function containsOnlyNumbers(input: string): boolean {
  const nonDigitRegex = /[^0-9.]/;
  return !nonDigitRegex.test(input);
}

export function decimalNumber(input: string): boolean {
  const regex = /^\d+(\.\d{1,2})?$/;
  return regex.test(input)
}

export function toFormatNumber(num: any, fraction: number = 4): string {
  const userLocale = navigator.language;
  const toNum = isNaN(num) ? 0 : Number(num);
  const formattedNumber = new Intl.NumberFormat(userLocale, {
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction,
  }).format(toNum);
  return formattedNumber;
}

export function replaceForNumber(val: any) {
  return val.replace(/[^\d.-]+/g, "");
}

export function toNumber(val: any) {
  return isNaN(val) ? 0 : Number(val);
}

export function leftPad(str: string, len: number, char: string) {
  while (str.length < len) {
    str = char + str;
  }
  return str;
}

// 正的是黑色
export function numColor01(input: any) {
  let num = input;
  if (typeof num === "string") {
    num = num.replaceAll(',', '');
  }
  if (Number(num) > 0)
    return 'color-up'
  else if (Number(num) < 0)
    return 'color-down'
  else
    return 'unchange'
}

export function numColor02(num: any) {
  if (Number(num) <= IP_STATE.安全)
    return 'color-safe'
  else if (Number(num) <= IP_STATE.警示)
    return 'color-warn'
  else if (Number(num) >= IP_STATE.高危險)
    return 'color-danger'
}

// 正的是綠色
export function numColor03(input: any) {
  let num = input;
  if (typeof num === "string") {
    num = num.replaceAll(',', '');
  }
  if (Number(num) < 0)
    return 'color-down-0'
  else if (Number(num) > 0)
    return 'color-up-0'
  else
    return 'color-04'
}

export function numColor04(type: number) {
  switch (type) {
    case WITHDRAW_STATE.通過:
      return 'color-pass'
    case WITHDRAW_STATE.拒絕:
    case WITHDRAW_STATE.失敗:
      return 'color-reject'
    case WITHDRAW_STATE.待主管審核:
    case WITHDRAW_STATE.待客服審核:
    case WITHDRAW_STATE.待財務審核:
    case WITHDRAW_STATE.財務審核中:
      return 'color-reviewing'
    case WITHDRAW_STATE.處理中:
    case WITHDRAW_STATE.支付端等待支付:
      return 'color-padding'
  }
}

// 標籤式
export function numColor05(input: any) {
  let num = input;
  if (typeof num === "string") {
    num = num.replaceAll(',', '');
  }
  if (Number(num) < 0)
    return 'color-down-tag'
  else if (Number(num) > 0)
    return 'color-up-tag'
  else
    return 'color-tag'
}

// 狀態顏色
export function stateColor(state: number) {
  switch (state) {
    case STATE.通過:
      return 'color-pass'
    case STATE.拒絕:
      return 'color-reject'
    case STATE.待審核:
      return 'color-padding'
    default: return ''
  }
}

export function stateColorBank(state: number) {
  switch (state) {
    case MEMBER_BANK_STATE.待驗證:
      return 'color-padding';
    case MEMBER_BANK_STATE["驗證成功-開啟"]:
      return 'color-pass';
    case MEMBER_BANK_STATE["驗證成功-關閉"]:
      return 'color-pass';
    case MEMBER_BANK_STATE.驗證失敗:
      return 'color-reject';
    case MEMBER_BANK_STATE.刪除:
      return 'color-down-0';
  }
}

export function parseJson(stringArray: string) {
  return JSON.parse(stringArray) as number[] | string[];
}

// 轉換成百分比 1 => 100
export function convertedToPercentage01(num: number | string): string {
  let decimalValue;

  if (typeof num === 'string') {
    const parsedValue = parseFloat(num);
    decimalValue = new Decimal(parsedValue);
  }

  decimalValue = new Decimal(num);

  return decimalValue.mul(100).toFixed(2).toString();
}

// 轉換成百分比 100 => 1
export function convertedToPercentage02(num: number | string): string {
  let decimalValue;
  if (typeof num === 'string') {
    const parsedValue = parseFloat(num);
    if (!isNaN(parsedValue)) {
      decimalValue = new Decimal(parsedValue).toDP(2);
    } else {
      throw new Error('Invalid input: Not a valid number or string that can be parsed into a number.');
    }
  } else if (typeof num === 'number') {
    decimalValue = new Decimal(num).toDP(2);
  } else {
    throw new Error('Invalid input: Input must be a number or a string that can be parsed into a number.');
  }

  return decimalValue.div(100).toString();
}

// 圓餅圖顏色
export function generateBrightColorsArray(length: number): string[] {
  const letters: string = '0123456789ABCDEF';
  const brightColors: string[] = [];

  while (brightColors.length < length) {
    let color: string = '#';
    for (let i: number = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }

    // Check if the color is bright enough (lightness > 0.5)
    const rgb = parseInt(color.slice(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    if (brightness > 127.5) {
      brightColors.push(color);
    }
  }

  return brightColors;
}

// 欄位驗證
export function verify({
  required = true,
  point = 2,
  max = null,
  min = null,
  negative = false,
  zero = true
}: any) {

  return [
    { required: required, message: `${i18n.t('required')}` },
    {
      validator: async (record: any, value: any) => {

        let regex = new RegExp('^-?\\d{1,9}(\\.\\d{0,0})?$');
        let msg = i18n.t('greaterThanOrEqualTo0IntegerOnly');

        if (point === 2) {
          regex = new RegExp('^-?\\d{1,9}(\\.\\d{1,2})?$');
          msg = i18n.t('greaterThanOrEqualTo0SupportsUpToTwoDecimalPlaces');
        }
        else if (point === 4) {
          regex = new RegExp('^-?\\d{1,9}(\\.\\d{1,4})?$');
          msg = i18n.t('greaterThanOrEqualTo0SupportsUpToFourDecimalPlaces');
        } else if (point === 5) {
          regex = new RegExp('[a-zA-Z]{1}[a-zA-Z0-9]{3,11}$');
          msg = i18n.t('englishAndNumbersWithin4To12Characters');
        } else if (point === 6) {
          regex = new RegExp('[a-zA-Z0-9*_-]{4,20}$');
          msg = i18n.t('supportSpecialCharacters');
        } else if (point === 7) {
          regex = new RegExp('^[0-9]+$');
          msg = i18n.t('invalidFormat')
        }

        // 只能是數字
        if (isNaN(value) && value && point !== 5 && point !== 6 && point !== 7) {
          return Promise.reject(i18n.t('invalidFormat'));
        }

        // 不能為0
        if (!zero && Number(value) === 0) {
          return Promise.reject(i18n.t('amountMustBeGreaterThanZero'));
        }

        // 正數
        if (!negative && Number(value) < 0) {
          return Promise.reject(i18n.t('contentOnlyNumber'));
        }

        // 小數點
        if (value && !regex.test(value)) {
          return Promise.reject(i18n.t('invalidFormat'));
        }

        // 超過最大
        if (max !== null && parseFloat(value) > parseFloat(max as string)) {
          return Promise.reject(i18n.t('rateMax'));
        }
        // 限制最小
        if (min !== null && parseFloat(value) < parseFloat(min as string)) {
          return Promise.reject(i18n.t('cannotBeBelowMinimumValue'));
        }

        return Promise.resolve();
      }
    }
  ];
}

// enum 轉成下拉選單
export function enumToOptions(e: any) {
  return Object.keys(e).filter(v => isNaN(Number(v))).map((k: any) => ({ value: e[k], label: i18n.t(k) }))
}

// 顯示幾代與代理名稱
export function agentLevelWord(level: any) {
  return `${level === 0 ? i18n.t('totals') : level}${i18n.t('agents')}`;
}

export function transferTimeFormat(time: number) {
  return dayjs(time * 1000).format('YYYY-MM-DD HH:mm:ss');
}