import { Button, Col, Form, Row, Spin, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { Export, LayoutDateSelect, LayoutNav } from 'components/layout.component';
import { ViewReportGGR } from 'components/report.component';
import { ExcelColumns } from 'constants/excel';
import dayjs from 'dayjs';
import { DATE_TYPE } from 'enum/date';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { GetReportWinloss } from 'services/report.service';
import * as common from 'utils/common';

// 輸贏報表

interface SearchParams {
  StartTime?: string;
  EndTime?: string;
  DateType?: any;
}

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();

  const [form] = useForm();
  const [date, setDate] = useState<string[]>();
  const [reset, setReset] = useState<boolean>(false);

  const [params, setParams] = useState<SearchParams>({
    StartTime: date ? `${date[0]} 00:00:00` : dayjs().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
    EndTime: date ? `${date[1]} 23:59:59` : dayjs().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
  })
  const { data, mutate, isValidating } = GetReportWinloss({
    ...params,
  }, true);
  const onFinish = (formData: any) => {
    if (date) {
      setParams({
        ...params,
        StartTime: date ? `${date[0]} 00:00:00` : dayjs().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        EndTime: date ? `${date[1]} 23:59:59` : dayjs().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
      })
    }
    mutate();
  }

  const onClear = () => {
    setReset(!reset);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="p-2">
        {/* 搜尋列 */}
        <Form form={form} onFinish={onFinish}>
          <Row align="middle" gutter={[12, 12]}>
            <LayoutDateSelect width={300} dateTypeDefault={DATE_TYPE.本月} onChange={setDate} reset={reset} />
            <Button className="ml-1 mr-1" type="primary" htmlType="submit">{i18n.t('search')}</Button>
            <Button onClick={onClear}>{i18n.t('clear')}</Button>
          </Row>
        </Form>
        {/* 數據總覽 */}
        <Col className="mt-1" span={24}>
          <Row justify="space-between">
            {$p.includes('60802') && <Export url={'/report/winloss'} param={{
              StartTime: date ? `${date[0]} 00:00:00` : dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
              EndTime: date ? `${date[1]} 23:59:59` : dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            }} sheetName={i18n.t('winAndLossReports')} columns={ExcelColumns.GGRList} />}
          </Row>
        </Col>
        <Row className="mt-1" gutter={[10, 10]}>
          <Col span={24}>
            <Table
              loading={isValidating}
              size="middle"
              dataSource={
                [{
                  key: 1,
                  TotalBetCount: data?.TotalRecord?.TotalBetCount,
                  TotalBetAmount: data?.TotalRecord?.TotalBetAmount,
                  TotalWinLossAmount: data?.TotalRecord?.TotalWinLossAmount
                }]
              }
              columns={[
                {
                  key: 1,
                  dataIndex: 'TotalBetCount',
                  title: i18n.t('totalBetCount'),
                  className: 'size-12',
                  align: 'right',
                  render: (val) => common.toFormatNumber(val, 0)
                },
                {
                  key: 2,
                  dataIndex: 'TotalBetAmount',
                  title: i18n.t('totalBetAmounts'),
                  className: 'size-12',
                  align: 'right',
                  render: (val) => common.toFormatNumber(val)
                },
                {
                  key: 3,
                  dataIndex: 'TotalWinLossAmount',
                  title: i18n.t('totalWinLossAmount'),
                  className: 'size-12',
                  align: 'right',
                  render: (val) => <div className={common.numColor01(val)}>{common.toFormatNumber(val)}</div>
                },
              ]}
              pagination={false}
            />
          </Col>
        </Row>
        <Row className="mt-1">
          <Col span={24}>
            <Spin spinning={isValidating}>
              <ViewReportGGR data={data} date={date} />
            </Spin>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default PageMain;