import { ConfigProvider, message } from "antd";
import en_US from 'antd/locale/en_US';
import ja_JP from 'antd/locale/ja_JP';
import pt_BR from 'antd/locale/pt_BR';
import th_TH from 'antd/locale/th_TH';
import vi_VN from 'antd/locale/vi_VN';
import zh_CN from 'antd/locale/zh_CN';
import zh_TW from 'antd/locale/zh_TW';
import { MainLayout } from "components/layout.component";
import { SocketProvider } from "contexts/socket.context";
import { StoreProvider } from "hooks/store.hook";
import Cookies from "js-cookie";
import { advancedModule } from 'modules/advanced.module';
import { agentModule } from 'modules/agent.module';
import { chatModule } from 'modules/chat.module';
import { financeModule } from 'modules/finance.module';
import { frontModule } from 'modules/front.module';
import { memberModule } from 'modules/member.module';
import { promotionModule } from 'modules/promotion.module';
import { reportModule } from 'modules/report.module';
import { seoModule } from 'modules/seo.module';
import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { RouterProvider, createHashRouter } from "react-router-dom";
import {
  Slide,
  ToastContainer,
  ToastContainerProps,
  toast,
} from "react-toastify";
import "./assets/css/main.css";
import Page404 from "./pages/404";
import PageLogin from "./pages/admin/login";
import PageModifyPassword from "./pages/admin/modify-password";
import PageError from "./pages/error";
import PageHome from './pages/home';

enum LANG {
  英文 = 'en-US',
  繁中 = 'zh-TW',
  簡中 = 'zh-CN',
  日文 = 'ja-JP',
  泰文 = 'th-TH',
  越文 = 'vi-VN',
  葡萄牙文 = 'pt-BR',
}

const Local = (lang: string) => {
  switch (lang) {
    case LANG.英文:
      return en_US;
    case LANG.繁中:
      return zh_TW;
    case LANG.簡中:
      return zh_CN;
    case LANG.日文:
      return ja_JP;
    case LANG.泰文:
      return th_TH;
    case LANG.越文:
      return vi_VN;
    case LANG.葡萄牙文:
      return pt_BR;
  }
}

const toastConfig: ToastContainerProps = {
  transition: Slide,
  position: toast.POSITION.TOP_CENTER,
  autoClose: 1400,
  limit: 1,
  closeOnClick: true,
  draggable: false,
  hideProgressBar: true,
  pauseOnHover: false,
};

message.config({
  top: 20,
  duration: 2,
  maxCount: 3,
  rtl: true,
  prefixCls: 'my-message',
})

const router = createHashRouter([
  {
    path: "/",
    children: [
      { path: '/:dateType?', Component: PageHome },
      { path: '/login/:errorCode?', Component: PageLogin },
      { path: '/password', Component: PageModifyPassword },
      ...memberModule,
      ...financeModule,
      ...promotionModule,
      ...agentModule,
      ...frontModule,
      ...reportModule,
      ...advancedModule,
      ...chatModule,
      ...seoModule,
      { path: '/error', Component: PageError },
      { path: "*", Component: Page404 },
    ],
  },
]);

ReactModal.setAppElement("#root");

const App: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [userLang, setUserLang] = useState(Cookies.get('lang') || document.documentElement.lang);

  useEffect(() => {
    const lang = Cookies.get('lang') || document.documentElement.lang;
    setUserLang(lang);
  }, [userLang, Cookies.get('lang')]);

  return (
    <MainLayout>
      <StoreProvider>
        <SocketProvider>
          <ConfigProvider locale={Local(userLang)}>
            {contextHolder}
            <ToastContainer {...toastConfig} />
            <RouterProvider router={router} />
          </ConfigProvider>
        </SocketProvider>
      </StoreProvider>
    </MainLayout>
  );
};

export default App;

// test 自動化部屬