import { Button, Col, Descriptions, Form, Image, Input, InputNumber, Modal, Row, Select, Space, Spin, Table, UploadFile, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { RESPONSE_CODE_ADD_DEPOSIT, RESPONSE_CODE_AGENT_WITHDRAW, RESPONSE_CODE_FINANCE, RESPONSE_CODE_MERCHANT, RESPONSE_CODE_WITHDRAW_UPDATE } from "constants/response";
import { MERCHANT_TYPE } from "enum/promotion";
import { DEPOSIT, DEPOSIT_STATE, ENABLE, MODE, RESPONSE_TYPE, STATE, TRANSFER_SETTING_STATUS, TRANSFER_TYPE, VERIFICATION_STATE, WITHDRAW, WITHDRAW_STATE, WITHDRAW_STATE_TYPE, WITHDRAW_TYPE } from "enum/state";
import useAuth from "hooks/auth.hook";
import useIcon from "hooks/icon.hook";
import usePerm from "hooks/permission.hook";
import { StoreContext } from "hooks/store.hook";
import i18n from "i18n";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddDepositBank, AddDepositCrypto, AddWithdrawBank, AddWithdrawCrypto, DeleteDeposit, DeleteWithdraw, DepositOfflineOne, EditDepositBank, EditDepositCrypto, EditWithdrawBank, EditWithdrawCrypto, GetDepositmerchantOne, GetWithdrawmerchantOne, PostDepositmerchantUpdate, PostWithdrawmerchantUpdate, WithdrawalOfflineOne } from "services/depositMerchant.service";
import { DepositDetail, RejectDeposit, ResolveDeposit } from "services/finance.service";
import { GetCryptoList, GetWithdrawmerchant } from "services/withdrawmerchant.service";
import { GetWithdrawmethodOne, PostWithdrawmethodMemberVerify } from "services/withdrawmethod.service";
import { AgentWithdrawInfo, AuditorPass, AuditorReject, FinancePass, FinanceReject, FinanceWithdrawInfo, ServicePass, ServiceReject } from "services/withdrawticket.service";
import * as common from 'utils/common';
import { verify } from 'utils/common';
import { CopyButton, InquiryWindow, MemberLevelTableDisplay, UploadImage } from "./layout.component";
import { MemberLevelSelect } from "./member.component";

const colorChange = (type: number) => {
  switch (type) {
    case TRANSFER_SETTING_STATUS.開啟:
      return 'color-pass'
    case TRANSFER_SETTING_STATUS.關閉:
      return 'color-reject'
    case TRANSFER_SETTING_STATUS.刪除:
      return 'color-padding'
  }
}

// 會員提款資訊審核列表
export const ViewWithdrawList = ({ data, mutate }: any) => {
  const { permissionCode: $p } = usePerm();
  const [activeId, setActiveId] = useState(0);
  const [isOpenReviewModal, setIsOpenReviewModal] = useState(false);

  // 狀態顏色
  const stateColor = (state: number) => {
    switch (state) {
      case WITHDRAW_STATE_TYPE.驗證成功開啟:
        return 'color-pass'
      case WITHDRAW_STATE_TYPE.拒絕:
        return 'color-reject'
      case WITHDRAW_STATE_TYPE.待審核:
        return 'color-reviewing'
      default: return 'color-padding'
    }
  }

  const openDetail = (id: number) => {
    setIsOpenReviewModal(true);
    setActiveId(id);
  }

  return (
    <>
      <Table
        scroll={{ x: 1500 }}
        size="middle"
        dataSource={data?.Data?.map((item: any) => ({ key: item.Id, ...item }))}
        columns={[
          {
            dataIndex: 'MemberAccount',
            title: i18n.t('memberAccount'),
            fixed: "left",
            width: 150
          },
          {
            dataIndex: 'RealName',
            title: i18n.t('realName'),
            width: 150,
            render: (val) => val || '-'
          },
          {
            dataIndex: 'Name',
            title: i18n.t('accountName'),
            width: 150
          },
          {
            dataIndex: 'PhoneNumber',
            title: i18n.t('phoneNumber'),
            width: 200,
            render: (val, { PhonePrefix }) => `${PhonePrefix}-${val}`
          },
          {
            dataIndex: 'Account',
            title: i18n.t('accountNumber'),
            width: 200,
            render: (val) => val || '-'
          },
          {
            dataIndex: 'CreateDate',
            title: i18n.t('createTime'),
            width: 200
          },
          {
            dataIndex: 'VerifiedDate',
            title: i18n.t('auditTime'),
            width: 200,
            render: (val) => val === '0000-00-00 00:00:00' ? '-' : val
          },
          {
            dataIndex: 'Status',
            title: i18n.t('status'),
            width: 200,
            fixed: 'right',
            render: (val, record) => (
              <>
                {
                  val ? <div className={stateColor(val)}>{WITHDRAW_STATE_TYPE[val]}</div>
                    : <div className={stateColor(val)}>{WITHDRAW_STATE_TYPE[WITHDRAW_STATE_TYPE.待審核]}</div>
                }
                {
                  record.OperatorAccount &&
                  <div>
                    {`${record.OperatorAccount} ${Object(WITHDRAW_STATE_TYPE[val])}`}
                  </div>
                }
              </>
            )
          },
          {
            title: i18n.t('operation'),
            width: 100,
            fixed: 'right',
            render: (_, record) => (
              <Button className="size-12" type="link" onClick={() => openDetail(record.key)}>
                {(record.Status === WITHDRAW_STATE_TYPE.待審核 && $p.includes('10903')) ? i18n.t('audit') : i18n.t('detail')}
              </Button>
            )
          },
        ]}
        pagination={false}
      />
      <PopupWithdrawReviewModal isOpen={isOpenReviewModal} close={() => setIsOpenReviewModal(false)} id={activeId} mutate={mutate} />
    </>
  )
}
// 會員提款卡審核詳細
export const PopupWithdrawReviewModal = ({ isOpen, close, id, mutate }: any) => {
  enum ORDER_TYPE {
    銀行 = 1,
    虛擬 = 2
  }
  const { permissionCode: $p } = usePerm();
  const [form] = useForm();
  const [orderType, setOrderType] = useState<ORDER_TYPE>(ORDER_TYPE.銀行);
  const { data: bankInfo, mutate: mutateInfo, isValidating } = GetWithdrawmethodOne({ Id: id }, !!id);
  const photo = JSON.parse(bankInfo?.Photo || '[]');

  useEffect(() => {
    if (isOpen) {
      mutateInfo()
    }
  }, [isOpen]);
  useEffect(() => {
    if (bankInfo) {
      bankInfo.Crypto === ''
        ? setOrderType(ORDER_TYPE.銀行)
        : setOrderType(ORDER_TYPE.虛擬)
    }
  }, [bankInfo]);

  const submit = async (type: number) => {
    const { remark } = form.getFieldsValue();
    const response = await PostWithdrawmethodMemberVerify({
      Id: id,
      Status: type === STATE.通過 ? 1 : -1,
      Remark: remark
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      mutate();
      onClose();
    } else {
      message.error(RESPONSE_CODE_WITHDRAW_UPDATE[response.Message as keyof typeof RESPONSE_CODE_WITHDRAW_UPDATE]);
    }
  }

  const onClose = () => {
    close();
    form.resetFields();
  }

  return (
    <Modal
      title={bankInfo?.Status === WITHDRAW_STATE_TYPE.待審核 ? i18n.t('audit') : i18n.t('detail')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={800}
      footer={false}
    >
      <Form form={form} onFinish={() => submit(STATE.拒絕)}>
        <Spin spinning={isValidating}>
          <Descriptions size="small" layout="horizontal" column={2} bordered
            labelStyle={{ width: 80 }} contentStyle={{ width: 150 }}>
            <Descriptions.Item className="size-12" label={i18n.t('accountName')}>
              {bankInfo?.Name || '-'}
            </Descriptions.Item>
            <Descriptions.Item className="size-12" label={
              orderType === ORDER_TYPE.銀行 ? i18n.t('accountNumber') : i18n.t('currencyType')
            }>
              {orderType === ORDER_TYPE.銀行 ? bankInfo?.Account : bankInfo?.Crypto}
            </Descriptions.Item>
            <Descriptions.Item className="size-12" label={
              orderType === ORDER_TYPE.銀行 ? i18n.t('bankName') : i18n.t('networkType')
            }>
              {orderType === ORDER_TYPE.銀行 ? bankInfo?.BankName : bankInfo?.CryptoType}
            </Descriptions.Item>
            <Descriptions.Item className="size-12" label={
              orderType === ORDER_TYPE.銀行 ? i18n.t('openingBranch') : i18n.t('receivingAddress')
            }>
              {orderType === ORDER_TYPE.銀行 ? bankInfo?.Branch : bankInfo?.CryptoAddress}
            </Descriptions.Item>
            {
              orderType === ORDER_TYPE.銀行 &&
              <>
                <Descriptions.Item className="size-12" label={i18n.t('bankProvince')}>
                  {bankInfo?.State || '-'}
                </Descriptions.Item>
                <Descriptions.Item className="size-12" label={i18n.t('city')}>
                  {bankInfo?.City || '-'}
                </Descriptions.Item>
                <Descriptions.Item className="size-12" label={i18n.t('idNum')}>
                  {bankInfo?.IDNumber || '-'}
                </Descriptions.Item>
                <Descriptions.Item className="size-12" label={i18n.t('realName')}>
                  {bankInfo?.MemberName}
                </Descriptions.Item>
                <Descriptions.Item className="size-12" label={i18n.t('remark')}>
                  {bankInfo?.Status === WITHDRAW_STATE_TYPE.待審核 && $p.includes('10903')
                    ? <Form.Item name="remark" rules={[{ required: true, message: `${i18n.t('rejectionRequiresRemark')}` }]}>
                      <Input.TextArea
                        disabled={bankInfo?.Status !== WITHDRAW_STATE_TYPE.待審核}
                        placeholder={`${i18n.t('enter')}`}
                        maxLength={225}
                        autoSize={{ maxRows: 2, minRows: 4 }}
                      />
                    </Form.Item>
                    : bankInfo?.Remark
                  }
                </Descriptions.Item>
                <Descriptions.Item className="size-12" label={i18n.t('image')}>
                  {
                    photo.length !== 0 &&
                    <Image src={photo[0]} height={100} />
                  }
                </Descriptions.Item>
              </>
            }
            {
              orderType === ORDER_TYPE.虛擬 &&
              <>
                <Descriptions.Item className="size-12" label={i18n.t('QR Code')}>
                  {
                    photo.length !== 0 &&
                    <Image src={photo[0]} height={100} />
                  }
                </Descriptions.Item>
                <Descriptions.Item className="size-12" label={i18n.t('remark')}>
                  {bankInfo?.Status === WITHDRAW_STATE_TYPE.待審核 && $p.includes('10903')
                    ? <Form.Item name="remark" rules={[{ required: true, message: `${i18n.t('rejectionRequiresRemark')}` }]}>
                      <Input.TextArea
                        disabled={bankInfo?.Status !== WITHDRAW_STATE_TYPE.待審核}
                        placeholder={`${i18n.t('enter')}`}
                        maxLength={225}
                        autoSize={{ maxRows: 2, minRows: 4 }}
                      />
                    </Form.Item>
                    : bankInfo?.Remark
                  }
                </Descriptions.Item>
              </>
            }
          </Descriptions>
          <Row justify="center" gutter={[12, 12]}>
            {
              bankInfo?.Status === WITHDRAW_STATE_TYPE.待審核 && $p.includes('10903')
                ? <>
                  <Col>
                    <Button className="mt-1" type="primary" danger htmlType="submit">{i18n.t('reject')}</Button>
                  </Col>
                  <Col>
                    <Button className="mt-1" type="primary"
                      onClick={() => submit(STATE.通過)}>{i18n.t('pass')}</Button>
                  </Col>
                </>
                : <Button className="mt-1" type="primary" onClick={onClose}>{i18n.t('close')}</Button>
            }
          </Row>
        </Spin>
      </Form>
    </Modal>
  )
}


// 會員入款審核
export const PopupDepositAudit = ({ isOpen, close, id, mutate }: any) => {
  const [form] = useForm();
  const { data: depositDetail, isValidating, mutate: RefreshInfo } = DepositDetail({ Id: id });

  useEffect(() => {
    if (isOpen) {
      RefreshInfo()
    }
  }, [isOpen]);

  const handlePass = async () => {
    const { bnRemarks } = form.getFieldsValue();
    const response = await ResolveDeposit({
      Id: depositDetail.Id,
      RealAmount: depositDetail.Amount,
      BnRemarkFromFin: bnRemarks,
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('pass'));
      onClose();
      mutate();
    } else {
      message.error(RESPONSE_CODE_FINANCE[response.Message as keyof typeof RESPONSE_CODE_FINANCE]);
    }
  }

  const handleReject = async () => {
    const { bnRemarks } = form.getFieldsValue();
    const response = await RejectDeposit({
      Id: depositDetail.Id,
      RemarkFromFin: bnRemarks,
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('reject'));
      onClose();
      mutate();
    } else {
      message.error(RESPONSE_CODE_FINANCE[response.Message as keyof typeof RESPONSE_CODE_FINANCE]);
    }
  }

  const onClose = () => {
    close();
    form.resetFields();
  }

  return (
    <Modal title={`${i18n.t('deposit')}`}
      open={isOpen}
      centered={true}
      onCancel={onClose}
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col><Button type="primary" danger onClick={handleReject}>{i18n.t('reject')}</Button></Col>
          <Col><Button type="primary" onClick={handlePass}>{i18n.t('pass')}</Button></Col>
        </Row>
      }
      width={900}
    >
      <Form form={form}>
        <Spin spinning={isValidating}>
          <Descriptions
            bordered
            size="small"
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('order')}>
              {depositDetail && depositDetail.Code}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('memberAccount')}>
              <Row align="middle" justify="space-between">
                <Col> {depositDetail && depositDetail.OwnerAccount}</Col>
                <Col><CopyButton text={depositDetail && depositDetail.OwnerAccount} /></Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label={`${i18n.t('agent')}`}>
              {depositDetail && depositDetail.AgentAccount}
            </Descriptions.Item>
            <Descriptions.Item label={`${i18n.t('memberName')}`}>
              {depositDetail && depositDetail.MemberName || '-'}
            </Descriptions.Item>
          </Descriptions>

          <Descriptions
            bordered
            size="small"
            column={2}
            className="pt-1"
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('depositAmount')}>
              {depositDetail && common.toFormatNumber(depositDetail.Amount)}
            </Descriptions.Item>
            {
              depositDetail && depositDetail.DepositMerchantGatewayCode === DEPOSIT.線下虛擬
                ? <>
                  <Descriptions.Item label={i18n.t('currencyType')}>
                    {depositDetail && depositDetail.OfflineInfo.Crypto}
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('networkType')}>
                    {depositDetail && depositDetail.OfflineInfo.CryptoType}
                  </Descriptions.Item>
                </>
                : <>
                  <Descriptions.Item label={i18n.t('depositMethod')}>
                    {depositDetail ? depositDetail.GatewayName : '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('depositInformation')}>
                    {
                      depositDetail && (
                        depositDetail.DepositMerchantGatewayCode === DEPOSIT.線下虛擬 ||
                        depositDetail.DepositMerchantGatewayCode === DEPOSIT.線下銀行) ?
                        <>
                          <div>{depositDetail && depositDetail.OfflineInfo.Name || '-'}</div>
                          <div>
                            <span>{depositDetail && depositDetail.OfflineInfo.BankName || '-'}</span>／
                            <span>{depositDetail && common.displayLastFourCharacters(depositDetail.OfflineInfo.Account) || '-'}</span>／
                            <span>{depositDetail && depositDetail.OfflineInfo.PhoneNumber || '-'}</span>
                          </div>
                        </>
                        :
                        <>
                          <div>{depositDetail?.MerchantInfo.CustomName || '-'}</div>
                        </>
                    }
                  </Descriptions.Item>
                </>
            }
            <Descriptions.Item label={`${i18n.t('paymentProcessingFee')}／${i18n.t('exchangeRate')}`}>
              {depositDetail && common.toFormatNumber(depositDetail.HandlingFee, 2)}／
              {depositDetail && depositDetail.ExchangeRate !== 0 ? common.toFormatNumber(depositDetail.ExchangeRate, 2) : '-'}
            </Descriptions.Item>
            {
              depositDetail && depositDetail.DepositMerchantGatewayCode === DEPOSIT.線下虛擬 &&
              <>
                <Descriptions.Item label={i18n.t('receivingAddress')} span={2}>
                  {depositDetail && depositDetail.OfflineInfo.CryptoAddress}
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('QR Code')}>
                  {
                    depositDetail && depositDetail.OfflineInfo.QR_Code
                      ? <Image src={depositDetail ? depositDetail.OfflineInfo.QR_Code : ''} height={100} />
                      : '-'
                  }
                </Descriptions.Item>
                <Descriptions.Item label={''}>
                </Descriptions.Item>
              </>
            }
          </Descriptions>

          <Descriptions
            bordered
            size="small"
            column={2}
            className="pt-1"
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('auditor')}>
              {
                depositDetail && depositDetail.Status === DEPOSIT_STATE.成功 && (
                  depositDetail.DepositMerchantGatewayCode === DEPOSIT.三方ATM ||
                  depositDetail.DepositMerchantGatewayCode === DEPOSIT.三方超商 ||
                  depositDetail.DepositMerchantGatewayCode === DEPOSIT.三方銀行
                ) ? i18n.t('system') : (depositDetail?.FinanaceAccount || '-')
              }
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('status')}>
              {depositDetail && DEPOSIT_STATE[depositDetail.Status]}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('createTime')}>
              {depositDetail && depositDetail.CreateDate}
            </Descriptions.Item>
            <Descriptions.Item label={''}>
            </Descriptions.Item>
          </Descriptions>

          <Descriptions
            bordered
            size="small"
            column={2}
            className="pt-1"
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('backOfficeRemarks')}>
              <Form.Item name="bnRemarks">
                <Input placeholder={`${i18n.t('enter')}`} />
              </Form.Item>
            </Descriptions.Item>
            {
              depositDetail && (
                depositDetail.DepositMerchantGatewayCode === DEPOSIT.三方ATM ||
                depositDetail.DepositMerchantGatewayCode === DEPOSIT.三方超商 ||
                depositDetail.DepositMerchantGatewayCode === DEPOSIT.三方銀行 ||
                depositDetail.DepositMerchantGatewayCode === DEPOSIT.線下銀行
              ) &&
              <Descriptions.Item label={i18n.t('certificate')}>
                {
                  depositDetail && depositDetail?.ReceiptPhoto
                    ? <Image className="center" src={depositDetail?.ReceiptPhoto} height={100} />
                    : '-'
                }
              </Descriptions.Item>
            }
          </Descriptions>
        </Spin>
      </Form>
    </Modal>
  )
}
// 會員入款詳細
export const PopupDepositInfo = ({ isOpen, close, id, mutate }: any) => {
  const { permissionCode: $p } = usePerm();
  const { data: depositDetail, isValidating, mutate: RefreshInfo } = DepositDetail({ Id: id });

  useEffect(() => {
    if (isOpen) {
      RefreshInfo()
    }
  }, [isOpen]);

  const handlePass = async () => {
    const response = await ResolveDeposit({
      Id: depositDetail.Id,
      RealAmount: depositDetail.Amount,
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('pass'));
      close();
      mutate();
    } else {
      message.error(RESPONSE_CODE_FINANCE[response.Message as keyof typeof RESPONSE_CODE_FINANCE]);
    }
  }

  const handleReject = async () => {
    const response = await RejectDeposit({
      Id: depositDetail.Id
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('reject'));
      close();
      mutate();
    } else {
      message.error(RESPONSE_CODE_FINANCE[response.Message as keyof typeof RESPONSE_CODE_FINANCE]);
    }
  }

  return (
    <Modal title={i18n.t('detail')}
      open={isOpen}
      centered={true}
      onCancel={close}
      width={900}
      footer={
        <Row justify="center" gutter={[12, 12]}>
          {
            (depositDetail?.Status === DEPOSIT_STATE.付款中 || depositDetail?.Status === DEPOSIT_STATE.失敗) && $p.includes('20203')
              ? <>
                <Col><Button type="primary" danger onClick={handleReject}>{i18n.t('forceFailure')}</Button></Col>
                <Col><Button type="primary" onClick={handlePass}>{i18n.t('forceApproval')}</Button></Col>
              </>
              : <Col><Button type="primary" onClick={close}>{i18n.t('close')}</Button></Col>
          }
        </Row >
      }
    >
      <Spin spinning={isValidating}>
        <Descriptions
          bordered
          size="small"
          column={2}
          labelStyle={{ width: '18%' }}
          contentStyle={{ width: '32%' }}
        >
          <Descriptions.Item label={i18n.t('order')}>
            {depositDetail && depositDetail.Code}
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('memberAccount')}>
            <Row align="middle" justify="space-between">
              <Col> {depositDetail && depositDetail.OwnerAccount}</Col>
              <Col><CopyButton text={depositDetail && depositDetail.OwnerAccount} /></Col>
            </Row>
          </Descriptions.Item>
          <Descriptions.Item label={`${i18n.t('agent')}`}>
            {depositDetail && depositDetail.AgentAccount}
          </Descriptions.Item>
          <Descriptions.Item label={`${i18n.t('memberName')}`}>
            {depositDetail?.MemberNickName || depositDetail?.MemberName || '-'}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          bordered
          size="small"
          column={2}
          className="pt-1"
          labelStyle={{ width: '18%' }}
          contentStyle={{ width: '32%' }}
        >
          <Descriptions.Item label={i18n.t('depositAmount')}>
            {depositDetail && common.toFormatNumber(depositDetail.Amount)}
          </Descriptions.Item>
          {
            depositDetail && depositDetail.DepositMerchantGatewayCode === DEPOSIT.線下虛擬
              ?
              <>
                <Descriptions.Item label={i18n.t('currencyType')}>
                  {depositDetail && depositDetail.OfflineInfo.Crypto}
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('networkType')}>
                  {depositDetail && depositDetail.OfflineInfo.CryptoType}
                </Descriptions.Item>
              </>
              :
              <>
                <Descriptions.Item label={i18n.t('depositMethod')}>
                  {depositDetail ? depositDetail.GatewayName : '-'}
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('depositInformation')}>
                  {
                    depositDetail && (
                      depositDetail.DepositMerchantGatewayCode === DEPOSIT.線下虛擬 ||
                      depositDetail.DepositMerchantGatewayCode === DEPOSIT.線下銀行) ?
                      <>
                        <div>{depositDetail && depositDetail.OfflineInfo.Name || '-'}</div>
                        <div>
                          <span>{depositDetail && depositDetail.OfflineInfo.BankName || '-'}</span>／
                          <span>{depositDetail && common.displayLastFourCharacters(depositDetail.OfflineInfo.Account) || '-'}</span>／
                          <span>{depositDetail && depositDetail.OfflineInfo.PhoneNumber || '-'}</span>
                        </div>
                      </>
                      :
                      <>
                        <div>{depositDetail?.MerchantInfo.CustomName || '-'}</div>
                      </>
                  }
                </Descriptions.Item>
              </>

          }
          <Descriptions.Item label={`${i18n.t('paymentProcessingFee')}／${i18n.t('exchangeRate')}`}>
            {depositDetail && common.toFormatNumber(depositDetail.HandlingFee, 2)}／
            {depositDetail && depositDetail.ExchangeRate !== 0 ? common.toFormatNumber(depositDetail.ExchangeRate, 2) : '-'}
          </Descriptions.Item>
          {
            depositDetail && depositDetail.DepositMerchantGatewayCode === DEPOSIT.線下虛擬 &&
            <>
              <Descriptions.Item label={i18n.t('receivingAddress')} span={2}>
                {depositDetail && depositDetail.OfflineInfo.CryptoAddress}
              </Descriptions.Item>
              <Descriptions.Item label={i18n.t('QR Code')}>
                {
                  depositDetail && depositDetail.OfflineInfo.QR_Code
                    ? <Image src={depositDetail ? depositDetail.OfflineInfo.QR_Code : ''} height={100} />
                    : '-'
                }
              </Descriptions.Item>
              <Descriptions.Item label={''}>
              </Descriptions.Item>
            </>
          }
        </Descriptions>

        <Descriptions
          bordered
          size="small"
          column={2}
          className="pt-1"
          labelStyle={{ width: '18%' }}
          contentStyle={{ width: '32%' }}
        >
          <Descriptions.Item label={i18n.t('auditor')}>
            {
              depositDetail && depositDetail.Status === DEPOSIT_STATE.成功 && (
                depositDetail.DepositMerchantGatewayCode === DEPOSIT.三方ATM ||
                depositDetail.DepositMerchantGatewayCode === DEPOSIT.三方超商 ||
                depositDetail.DepositMerchantGatewayCode === DEPOSIT.三方銀行
              ) ? i18n.t('system') : (depositDetail?.FinanaceAccount || '-')
            }
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('status')}>
            {depositDetail && DEPOSIT_STATE[depositDetail.Status]}
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('createTime')}>
            {depositDetail && depositDetail.CreateDate}
          </Descriptions.Item>
          <Descriptions.Item label={''}>
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          bordered
          size="small"
          column={2}
          className="pt-1"
          labelStyle={{ width: '18%' }}
          contentStyle={{ width: '32%' }}
        >
          <Descriptions.Item label={i18n.t('backOfficeRemarks')}>
            {depositDetail?.BnRemark || ''}
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('certificate')}>
            {
              depositDetail && (
                depositDetail.DepositMerchantGatewayCode === DEPOSIT.三方ATM ||
                depositDetail.DepositMerchantGatewayCode === DEPOSIT.三方超商 ||
                depositDetail.DepositMerchantGatewayCode === DEPOSIT.三方銀行 ||
                depositDetail.DepositMerchantGatewayCode === DEPOSIT.線下銀行
              ) &&
              <Descriptions.Item label={i18n.t('certificate')}>
                {
                  depositDetail && depositDetail?.ReceiptPhoto
                    ? <Image className="center" src={depositDetail?.ReceiptPhoto} height={100} />
                    : '-'
                }
              </Descriptions.Item>
            }
          </Descriptions.Item>
        </Descriptions>
      </Spin>
    </Modal >
  )
}


// 會員出款總計
export const ViewMemberWithdrawOverview = ({ data }: {
  data: {
    State: string;
    TotalAmount: number;
    TotalHandlingFee: number;
    TotalRealAmount: number;
    TotalRecord: number;
  };
}) => {
  return (
    <Table
      size="middle"
      dataSource={
        data ?
          [
            {
              key: 1,
              count: data.TotalRecord,
              WithdrawalTotalAmount: data.TotalAmount,
              totalProcessingFee: data.TotalHandlingFee,
              totalActualWithdrawalAmount: data.TotalRealAmount
            }
          ] : []
      }
      columns={[
        {
          title: i18n.t('count'),
          dataIndex: 'count',
          align: 'right',
          width: '25%',
        },
        {
          title: i18n.t('WithdrawalTotalAmount'),
          dataIndex: 'WithdrawalTotalAmount',
          align: 'right',
          width: '25%',
          render: (_, { WithdrawalTotalAmount }) => common.toFormatNumber(WithdrawalTotalAmount)
        },
        {
          title: i18n.t('totalProcessingFee'),
          dataIndex: 'totalProcessingFee',
          align: 'right',
          width: '25%',
          render: (_, { totalProcessingFee }) => common.toFormatNumber(totalProcessingFee)
        },
        {
          title: i18n.t('totalActualWithdrawalAmount'),
          dataIndex: 'totalActualWithdrawalAmount',
          align: 'right',
          width: '25%',
          render: (_, { totalActualWithdrawalAmount }) => common.toFormatNumber(totalActualWithdrawalAmount)
        },
      ]}
      pagination={false} />
  )
}
// 會員出款查詢
export const ViewMemberWithdrawList = ({ data, mutate }: {
  data: {
    State: string;
    Data: MemberWithdrawListType[];
  },
  mutate: () => void;
}) => {
  const auth = useAuth();
  const admin = auth.getAdmin();
  const { permissionCode: $p } = usePerm();

  const [id, setId] = useState<number | null>(0);
  const [financeId, setFinanceId] = useState<number | null>(0);
  const [orderState, setOrderState] = useState(0);
  const [orderType, setOrderType] = useState<string | null>('');
  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const [isOpenAudit, setIsOpenAudit] = useState(false);
  const [isOpenWithdrawal, setIsOpenWithdrawal] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);

  const showModalConfirm = (id: number, orderState: number) => {
    setId(id);
    setOrderState(orderState);
    setIsOpenAudit(true);
  };

  const showModalDetail = (id: number, GatewayCode: string) => {
    setId(id);
    setOrderType(GatewayCode);
    setIsOpenInfo(true);
  };

  const showModalWithdraw = (id: number, orderState: number, GatewayCode: string) => {
    setFinanceId(id);
    setOrderType(GatewayCode);
    setOrderState(orderState);
    setIsOpenWithdrawal(true);
  };

  const navigate = useNavigate();

  return (
    <>
      <Table
        size="middle"
        scroll={{ x: 1500, y: 500 }}
        dataSource={data?.Data?.map(item => ({ key: item.Id, ...item }))}
        columns={[
          {
            dataIndex: 'Code',
            title: i18n.t('order'),
            width: 250,
            fixed: 'left'
          },
          {
            dataIndex: 'MemberAccount',
            title: i18n.t('memberAccount'),
            width: 180,
            fixed: 'left'
          },
          {
            dataIndex: 'UpMemberAccount',
            title: `${i18n.t('recommendedMembers')} / ${i18n.t('agent')}`,
            width: 200,
            render: (val, record) => (
              <>
                <span>{val || '-'}</span>
                <span> / </span>
                <span>{record.AGAccount || '-'}</span>
              </>
            )
          },
          {
            dataIndex: 'BeforeAmount',
            title: i18n.t('previousBalance'),
            align: 'right',
            width: 200,
            render: (val) => common.toFormatNumber(val)
          },
          {
            dataIndex: 'Amount',
            title: i18n.t('withdrawalAmount'),
            align: 'right',
            width: 200,
            render: (val) => common.toFormatNumber(val)
          },
          {
            dataIndex: 'Amount',
            title: i18n.t('actualWithdrawalAmount'),
            align: 'right',
            width: 200,
            render: (val) => common.toFormatNumber(val)
          },
          {
            dataIndex: 'AfterAmount',
            title: i18n.t('BalanceAfterModification'),
            align: 'right',
            width: 200,
            render: (val) => common.toFormatNumber(val)
          },
          {
            dataIndex: 'HandlingFee',
            title: i18n.t('paymentProcessingFee'),
            align: 'right',
            width: 200,
            render: (val) => common.toFormatNumber(val, 2)
          },
          {
            dataIndex: 'ExchangeRate',
            title: i18n.t('exchangeRate'),
            align: 'right',
            width: 100,
            render: (val) => val ? common.toFormatNumber(val, 2) : '-'
          },
          {
            dataIndex: 'CreateDate',
            title: i18n.t('createTime'),
            width: 150,
          },
          {
            dataIndex: 'AuditedDate',
            title: i18n.t('auditTime'),
            width: 150,
            render: (val) => val !== '0000-00-00 00:00:00' ? val : '-'
          },
          {
            dataIndex: 'Status',
            title: i18n.t('status'),
            width: 150,
            fixed: 'right',
            render: (val, { FinanceAccount, ServiceAccount, AuditorAccount }) => (
              <>
                {/* 狀態 */}
                {
                  <div className={common.numColor04(val)}>{WITHDRAW_STATE[val]}</div>
                }
                {/* 誰做的 */}
                {
                  val === WITHDRAW_STATE.待主管審核 &&
                  <>{i18n.t('from')} {ServiceAccount} {i18n.t('operation')}</>
                }
                {
                  val === WITHDRAW_STATE.待財務審核 &&
                  <>{i18n.t('from')} {AuditorAccount} {i18n.t('operation')}</>
                }
                {(
                  val === WITHDRAW_STATE.通過 ||
                  val === WITHDRAW_STATE.處理中 ||
                  val === WITHDRAW_STATE.支付端等待支付 ||
                  val === WITHDRAW_STATE.財務審核中
                ) &&
                  <>{i18n.t('from')} {FinanceAccount} {i18n.t('operation')}</>
                }
                {
                  val === WITHDRAW_STATE.拒絕 &&
                  <>{i18n.t('from')} {ServiceAccount || AuditorAccount || FinanceAccount} {i18n.t('operation')}</>
                }
              </>
            )
          },
          {
            title: i18n.t('operation'),
            fixed: 'right',
            width: 200,
            render: (_, { Status, key, FinanceAccountId, GatewayCode, MemberAccount }) => (
              <>
                {(
                  (Status === WITHDRAW_STATE.待客服審核 && $p.includes('20104')) ||
                  (Status === WITHDRAW_STATE.待主管審核 && $p.includes('20106'))) &&
                  <Button type="link" onClick={() => showModalConfirm(key, Status)}>
                    {i18n.t('audit')}
                  </Button>
                }
                {(
                  (Status === WITHDRAW_STATE.待財務審核 && $p.includes('20108')) ||
                  (Status === WITHDRAW_STATE.財務審核中 && (FinanceAccountId === admin.Id))) &&
                  <Button type="link" onClick={() => showModalWithdraw(key, Status, GatewayCode)}>
                    {i18n.t('withdrawal')}
                  </Button>
                }
                {(
                  (Status === WITHDRAW_STATE.待客服審核 && !$p.includes('20104')) ||
                  (Status === WITHDRAW_STATE.待主管審核 && !$p.includes('20106')) ||
                  (Status === WITHDRAW_STATE.待財務審核 && !$p.includes('20108')) ||

                  Status === WITHDRAW_STATE.通過 ||
                  Status === WITHDRAW_STATE.拒絕 ||
                  Status === WITHDRAW_STATE.處理中 ||
                  Status === WITHDRAW_STATE.失敗 ||
                  Status === WITHDRAW_STATE.支付端等待支付 ||
                  (Status === WITHDRAW_STATE.財務審核中 && FinanceAccountId !== admin.Id && FinanceAccountId !== 0)) &&
                  <Button type="link" onClick={() => showModalDetail(key, GatewayCode)}>
                    {i18n.t('detail')}
                  </Button>
                }

                {$p.includes('10701') && <Button type="link" onClick={() => navigate(`/member/account/${MemberAccount}`)}>{i18n.t('accountRecords')}</Button>}
                {$p.includes('60101') && <Button type="link" onClick={() => navigate(`/report/${MemberAccount}`)}>{i18n.t('gameRecord')}</Button>}
              </>
            ),
          },
        ]}
        pagination={false}
      />

      {/* 前置審核 */}
      <PopupWithdrawAudit isOpen={isOpenAudit} close={() => setIsOpenAudit(false)} id={id} mutate={mutate} orderState={orderState} />
      {/* 財務出款審核 */}
      <PopupWithdrawal isOpen={isOpenWithdrawal} close={() => setIsOpenWithdrawal(false)} id={financeId} mutate={mutate} orderState={orderState} orderType={orderType} setErrorPopup={setErrorPopup} errorPopup={errorPopup} />
      {/* 明細 */}
      <PopupWithdrawInfo isOpen={isOpenInfo} close={() => setIsOpenInfo(false)} id={id} orderType={orderType} mutate={mutate} />
      {/* 鎖單錯誤訊息視窗 */}
      <PopupOrderLock isOpen={errorPopup} close={() => setErrorPopup(false)} />
    </>
  )
}
// 會員前置審核
export const PopupWithdrawAudit = ({ isOpen, close, id, mutate, orderState }: any) => {
  const [form] = useForm();
  const { data, mutate: RefreshInfo } = AgentWithdrawInfo({ Id: id })
  let response: any = {}

  useEffect(() => {
    if (isOpen) {
      RefreshInfo();
    }
  }, [isOpen]);

  useEffect(() => {
    if (data && isOpen) {
      form.setFieldsValue({
        bnRemarks: data.AuditorAccessLogRemark || data.ServiceAccessLogRemark
      })
    }
  }, [data, isOpen]);

  const handlePass = async () => {
    const { fnRemarks, bnRemarks } = form.getFieldsValue();
    switch (orderState) {
      case WITHDRAW_STATE.待客服審核:
        response = await ServicePass({
          Id: id,
          Remark: bnRemarks,
        })
        if (response.State === RESPONSE_TYPE.成功) {
          message.success(i18n.t('operationSuccess'));
          mutate();
          onClose();
        } else {
          message.error(RESPONSE_CODE_AGENT_WITHDRAW[response.Message as keyof typeof RESPONSE_CODE_AGENT_WITHDRAW]);
          if (response.Message === 'TICKET_STATUS_ILLEGAL') {
            mutate();
            onClose();
          }
        }
        break;
      case WITHDRAW_STATE.待主管審核:
        response = await AuditorPass({
          Id: id,
          Remark: bnRemarks
        })
        if (response.State === RESPONSE_TYPE.成功) {
          message.success(i18n.t('operationSuccess'));
          mutate();
          onClose();
        } else {
          message.error(RESPONSE_CODE_AGENT_WITHDRAW[response.Message as keyof typeof RESPONSE_CODE_AGENT_WITHDRAW]);
          if (response.Message === 'TICKET_STATUS_ILLEGAL') {
            mutate();
            onClose();
          }
        }
        break;
    }
  }

  const handleReject = async () => {
    const { fnRemarks, bnRemarks } = form.getFieldsValue();
    switch (orderState) {
      case WITHDRAW_STATE.待客服審核:
        response = await ServiceReject({
          Id: id,
          FnRemark: fnRemarks,
          BnRemark: bnRemarks
        })
        if (response.State === RESPONSE_TYPE.成功) {
          message.success(i18n.t('operationSuccess'));
          mutate();
          onClose();
        } else {
          message.error(RESPONSE_CODE_AGENT_WITHDRAW[response.Message as keyof typeof RESPONSE_CODE_AGENT_WITHDRAW]);
          if (response.Message === 'TICKET_STATUS_ILLEGAL') {
            mutate();
            onClose();
          }
        }
        break;
      case WITHDRAW_STATE.待主管審核:
        response = await AuditorReject({
          Id: id,
          FnRemark: fnRemarks,
          BnRemark: bnRemarks
        })
        if (response.State === RESPONSE_TYPE.成功) {
          message.success(i18n.t('operationSuccess'));
          mutate();
          onClose();
        } else {
          message.error(RESPONSE_CODE_AGENT_WITHDRAW[response.Message as keyof typeof RESPONSE_CODE_AGENT_WITHDRAW]);
          if (response.Message === 'TICKET_STATUS_ILLEGAL') {
            mutate();
            onClose();
          }
        }
        break;
    }
  }

  const onClose = () => {
    close();
    form.resetFields();
  }

  return (
    <Modal title={i18n.t('audit')}
      open={isOpen}
      onCancel={onClose}
      centered={true}
      width={900}
      footer={false}
    >
      <Form form={form} onFinish={handleReject}>
        <Descriptions
          className="pb-1"
          bordered
          size='small'
          column={2}
          labelStyle={{ width: '18%' }}
          contentStyle={{ width: '32%' }}
        >
          <Descriptions.Item label={i18n.t('order')}>
            {data?.Code}
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('agentAccount')}>
            <Row align="middle" justify="space-between">
              <Col>{data?.MemberAccount}</Col>
              <Col><CopyButton text={data?.MemberAccount} /></Col>
            </Row>
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('memberLevel')}>
            {data?.LevelName}
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('agent')}>
            {data?.AgentAccount}
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('withdrawalAmount')}>
            {common.toFormatNumber(data?.Amount)}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          className="pb-1"
          bordered
          size='small'
          column={2}
          labelStyle={{ width: '18%' }}
          contentStyle={{ width: '32%' }}
        >
          <Descriptions.Item label={i18n.t('auditor')}>
            {data?.Status === WITHDRAW_STATE.待主管審核 &&
              <>{data?.ServiceAccount}</>}
            {data?.Status === WITHDRAW_STATE.待財務審核 &&
              <>{data?.AuditorAccount}</>}
            {data?.Status === WITHDRAW_STATE.通過 &&
              <>{data?.FinanceAccount}</>}
            {data?.Status === WITHDRAW_STATE.支付端等待支付 &&
              <>{data?.FinanceAccount}</>}
            {
              data?.Status === WITHDRAW_STATE.拒絕 &&
              (
                <>{data?.ServiceAccount}</> ||
                <>{data?.AuditorAccount}</> ||
                <>{data?.FinanceAccount}</>
              )
            }
            {
              data?.Status === WITHDRAW_STATE.失敗 &&
              (
                <>{data?.ServiceAccount}</> ||
                <>{data?.AuditorAccount}</> ||
                <>{data?.FinanceAccount}</>
              )
            }
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('status')}>
            <div className={common.numColor04(data?.Status)}>{WITHDRAW_STATE[data?.Status]}</div>
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('createTime')}>{data?.CreateDate}</Descriptions.Item>
        </Descriptions>

        <Descriptions
          bordered
          size='small'
          column={2}
          labelStyle={{ width: '18%' }}
          contentStyle={{ width: '32%' }}
        >
          <Descriptions.Item label={i18n.t('frontDeskRemarks')}>
            <Form.Item name="fnRemarks" rules={[{ required: true, message: `${i18n.t('rejectionRequiresFrontendRemark')}` }]}>
              <Input name="fnRemarks" placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('backOfficeRemarks')}>
            <Form.Item name="bnRemarks">
              <Input placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Descriptions.Item>
        </Descriptions>
        <Row gutter={[12, 12]} align="middle" justify="center"
          className="mt-1">
          <Col>
            <Button type="primary" danger htmlType="submit">
              {i18n.t('reject')}
            </Button>
          </Col>
          <Col>
            <Button type="primary" onClick={handlePass}>{i18n.t('pass')}</Button>
          </Col>
        </Row>
      </Form>
    </Modal >
  )
}
// 會員財務出款審核
export const PopupWithdrawal = ({ isOpen, close, id, mutate, orderType, setErrorPopup, errorPopup }: any) => {
  const [form] = useForm();
  const [submitType, setSubmitType] = useState(VERIFICATION_STATE.通過);
  const [withdrawalChannel, setWithdrawalChannel] = useState<number>(0);
  const [isSelected, setIsSelected] = useState(true);
  const [cryptoParams, setCryptoParams] = useState({
    Crypto: null,
    CryptoType: null,
  });
  const { data, isValidating, mutate: RefreshInfo } = FinanceWithdrawInfo({ Id: id });

  useEffect(() => {
    if (isOpen) {
      RefreshInfo();
    }
  }, [isOpen]);

  useEffect(() => {
    if (data && data.Message === 'ANOTHER_FINANCE_IS_HANDLING') {
      close();
      mutate();
      setErrorPopup(true);
    }
  }, [data])
  useEffect(() => {
    if (data) {
      setCryptoParams({
        Crypto: data.Crypto,
        CryptoType: data.CryptoType,
      })
    }
  }, [data]);
  useEffect(() => {
    if (data && isOpen) {
      form.setFieldsValue({
        bnRemarks: data.AuditorAccessLogRemark
      })
    }
  }, [data, isOpen]);

  const [range, setRange] = useState({
    min: 0,
    max: 0,
    fee: 0,
    rate: 0
  });
  const {
    data: Withdrawmerchant,
    isValidating: WithdrawmerchantLoading,
  } = GetWithdrawmerchant({
    IsOffline: withdrawalChannel,
    TicketId: id
  });
  const {
    data: Crypto,
    isValidating: CryptoLoading,
  } = GetCryptoList({
    Crypto: cryptoParams.Crypto,
    CryptoType: cryptoParams.CryptoType,
    TicketId: id,
  })

  const handleSelect = (selected: string) => {
    setIsSelected(false);
    switch (selected) {
      case WITHDRAW_TYPE.三方:
        setWithdrawalChannel(0);
        break;
      case WITHDRAW_TYPE.線下:
        setWithdrawalChannel(1);
        break;
    }
    form.setFieldsValue({
      withdrawalChannel: null
    })
    setRange({
      min: 0,
      max: 0,
      fee: 0,
      rate: 0,
    })
  }

  const onSelectChannel = (selected: number) => {
    Withdrawmerchant.filter((item: { Id: number }) => (
      item.Id === selected
    )).map((item: { Minimum: number, Maximum: number, HandlingFee: number, Rate: number }) => (
      setRange({ min: item.Minimum, max: item.Maximum, fee: item.HandlingFee, rate: item.Rate })
    ))
  }

  const onSelectCrypto = (selected: number) => {
    Crypto.filter((item: { WithdrawMerchantId: number }) => (
      item.WithdrawMerchantId === selected
    )).map((item: { Minimum: number, Maximum: number, HandlingFee: number, Rate: number }) => (
      setRange({ min: item.Minimum, max: item.Maximum, fee: item.HandlingFee, rate: item.Rate })
    ))
  }

  const handlePass = async () => {
    const { withdrawalType, withdrawalChannel, bnRemarks, crypto } = form.getFieldsValue();
    if (orderType === WITHDRAW.三方虛擬貨幣
      || orderType === WITHDRAW.線下虛擬貨幣) {
      if (!crypto) return setSubmitType(VERIFICATION_STATE.通過);
    } else {
      if (!withdrawalType && !withdrawalChannel) return setSubmitType(VERIFICATION_STATE.通過);
    }
    if (data.Amount > range.max && range.max) {
      return message.error(i18n.t('withdrawalAmountExceedsMaximumLimit'));
    }
    if (data.Amount < range.min) {
      return message.error(i18n.t('withdrawalAmountBelowMinimumLimit'));
    }
    const response = await FinancePass({
      Id: id,
      WithdrawMerchantId: withdrawalChannel || crypto,
      BnRemark: bnRemarks,
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      mutate();
      onClose();
    } else {
      message.error(RESPONSE_CODE_AGENT_WITHDRAW[response.Message as keyof typeof RESPONSE_CODE_AGENT_WITHDRAW]);
      if (response.Message === 'TICKET_STATUS_ILLEGAL') {
        mutate();
        onClose();
      }
    }
  }

  const handleReject = async () => {
    const { fnRemarks, bnRemarks } = form.getFieldsValue();
    if (!fnRemarks) return setSubmitType(VERIFICATION_STATE.拒絕);
    const response = await FinanceReject({
      Id: id,
      FnRemark: fnRemarks,
      BnRemark: bnRemarks
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      mutate();
      onClose();
    } else {
      message.error(RESPONSE_CODE_AGENT_WITHDRAW[response.Message as keyof typeof RESPONSE_CODE_AGENT_WITHDRAW]);
      if (response.Message === 'TICKET_STATUS_ILLEGAL') {
        mutate();
        onClose();
      }
    }
  }

  const onClose = () => {
    close();
    setIsSelected(true);
    form.setFieldsValue({
      bnRemarks: data.AuditorAccessLogRemark,
      fnRemarks: '',
      crypto: null,
      withdrawalType: null,
      withdrawalChannel: null,
    })
    setRange({
      min: 0,
      max: 0,
      fee: 0,
      rate: 0,
    })
  }

  return (
    <Modal title={i18n.t('withdrawal')}
      open={isOpen}
      onCancel={onClose}
      centered={true}
      footer={false}
      width={900}
    >
      <Form form={form}>
        <Spin spinning={isValidating}>
          <Descriptions
            className="pb-1"
            bordered
            size="small"
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('order')}>
              {data?.Code}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('memberAccount')}>
              <Row align="middle" justify="space-between">
                <Col>{data?.MemberAccount}</Col>
                <Col><CopyButton text={data?.MemberAccount} /></Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('memberLevel')}>
              {data?.LevelName}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('agent')}>
              {data?.AgentAccount}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('withdrawalAmount')}>
              {common.toFormatNumber(data?.Amount)}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('totalActualWithdrawal')}>
              {common.toFormatNumber(data?.Amount)}
            </Descriptions.Item>
            {
              (orderType === WITHDRAW.三方虛擬貨幣 || orderType === WITHDRAW.線下虛擬貨幣)
                ?
                <Descriptions.Item label={i18n.t('currencyType')}>
                  {data?.Crypto}
                </Descriptions.Item>
                :
                <Descriptions.Item label={i18n.t('withdrawalType')}>
                  <Form.Item name="withdrawalType" rules={
                    submitType === VERIFICATION_STATE.通過 ?
                      [{ required: true, message: `${i18n.t('required')}` }]
                      : []
                  }>
                    <Select
                      onChange={handleSelect}
                      className="w-full"
                      placeholder={i18n.t('pleaseSelect')}
                      options={[
                        { value: WITHDRAW_TYPE.三方, label: i18n.t('thirdParty') },
                        { value: WITHDRAW_TYPE.線下, label: i18n.t('offlineMethod') },
                      ]}
                    />
                  </Form.Item>
                </Descriptions.Item>
            }
            {
              (orderType === WITHDRAW.三方虛擬貨幣 || orderType === WITHDRAW.線下虛擬貨幣)
                ?
                <Descriptions.Item label={i18n.t('networkType')}>
                  {data?.CryptoType}
                </Descriptions.Item>
                :
                <Descriptions.Item label={i18n.t('withdrawalChannel')}>
                  <Form.Item name="withdrawalChannel" rules={
                    submitType === VERIFICATION_STATE.通過 ?
                      [{ required: true, message: `${i18n.t('required')}` }]
                      : []
                  }>
                    <Select
                      loading={WithdrawmerchantLoading}
                      disabled={isSelected}
                      className="w-full"
                      onChange={onSelectChannel}
                      placeholder={i18n.t('pleaseSelect')}
                      options={
                        Withdrawmerchant ?
                          Withdrawmerchant.map((item: { Id: number, CustomName: string }) => (
                            { value: item.Id, label: `${item.CustomName}` }
                          )) : []
                      }
                    />
                  </Form.Item>
                </Descriptions.Item>
            }
            {
              (orderType === WITHDRAW.三方虛擬貨幣 || orderType === WITHDRAW.線下虛擬貨幣) &&
              <Descriptions.Item label={`${i18n.t('displayName')}-${i18n.t('address')}`}>
                <Form.Item name="crypto" rules={
                  submitType === VERIFICATION_STATE.通過 ?
                    [{ required: true, message: `${i18n.t('required')}` }]
                    : []
                }>
                  <Select
                    loading={CryptoLoading}
                    style={{ maxWidth: 200 }}
                    className="w-full"
                    placeholder={i18n.t('pleaseSelect')}
                    onChange={onSelectCrypto}
                    options={
                      Crypto ?
                        Crypto.map((item: { WithdrawMerchantId: number, CustomName: string, CryptoAddress: string }) => (
                          {
                            value: item.WithdrawMerchantId,
                            label: `${item.CustomName}-${item.CryptoAddress}`
                          }
                        )) : []
                    }
                  />
                </Form.Item>
              </Descriptions.Item>
            }
            <Descriptions.Item label={`${i18n.t('processingFee')}／${i18n.t('exchangeRate')}`}>
              {common.toFormatNumber((data?.Amount * range.rate) + range.fee)} / {
                data?.ExchangeRate || '-'}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('withdrawalAmountLimit')}>
              {common.toFormatNumber(range.min)} - {range.max ? common.toFormatNumber(range.max) : i18n.t('unlimited')}
            </Descriptions.Item>
          </Descriptions>

          <Descriptions
            className="pb-1"
            bordered
            size="small"
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            {
              (orderType === WITHDRAW.三方虛擬貨幣
                || orderType === WITHDRAW.線下虛擬貨幣)
                ? <>
                  <Descriptions.Item label={i18n.t('withdrawalAddress')} span={2}>
                    <Row align="middle" justify="space-between">
                      <Col>{data?.CryptoAddress}</Col>
                      <Col><CopyButton text={data?.CryptoAddress} /></Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('bankAccountName')}>
                    <Row align="middle" justify="space-between">
                      <Col>{data?.Account || '-'}</Col>
                      <Col><CopyButton text={data?.Account} /></Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('QR Code')}>
                    {
                      data && data.Photo && JSON.parse(data?.Photo)[0]
                        ? <Image src={data && data.Photo && JSON.parse(data?.Photo)[0]} height={100} />
                        : '-'
                    }
                  </Descriptions.Item>
                </>
                : <>
                  <Descriptions.Item label={i18n.t('bankName')}>
                    <Row align="middle" justify="space-between">
                      <Col>{data?.BankName}</Col>
                      <Col><CopyButton text={data?.BankName} /></Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('accountNumber')}>
                    <Row align="middle" justify="space-between">
                      <Col>{data?.Account}</Col>
                      <Col><CopyButton text={data?.Account} /></Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('openingBranch')}>
                    <Row align="middle" justify="space-between">
                      <Col>{data?.Branch}</Col>
                      <Col><CopyButton text={data?.Branch} /></Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('bankProvince')}>
                    <Row align="middle" justify="space-between">
                      <Col>{data?.State}</Col>
                      <Col><CopyButton text={data?.State} /></Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('city')}>
                    <Row align="middle" justify="space-between">
                      <Col>{data?.City}</Col>
                      <Col><CopyButton text={data?.City} /></Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('phoneNumber')}>
                    <Row align="middle" justify="space-between">
                      <Col>{`${data?.PhonePrefix}-${data?.PhoneNumber}`}</Col>
                      <Col><CopyButton text={`${data?.PhonePrefix}-${data?.PhoneNumber}`} /></Col>
                    </Row>
                  </Descriptions.Item>
                </>
            }
          </Descriptions>

          <Descriptions
            className="pb-1"
            bordered
            size="small"
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('auditor')}>
              {
                <>
                  {data?.Status === WITHDRAW_STATE.待主管審核 &&
                    <>{data?.ServiceAccount}</>}
                  {data?.Status === WITHDRAW_STATE.待財務審核 &&
                    <>{data?.AuditorAccount}</>}
                  {data?.Status === WITHDRAW_STATE.通過 &&
                    <>{data?.FinanceAccount}</>}
                  {data?.Status === WITHDRAW_STATE.支付端等待支付 &&
                    <>{data?.FinanceAccount}</>}
                  {
                    data?.Status === WITHDRAW_STATE.拒絕 &&
                    (
                      <>{data?.ServiceAccount}</> ||
                      <>{data?.AuditorAccount}</> ||
                      <>{data?.FinanceAccount}</>
                    )
                  }
                  {
                    data?.Status === WITHDRAW_STATE.失敗 &&
                    (
                      <>{data?.ServiceAccount}</> ||
                      <>{data?.AuditorAccount}</> ||
                      <>{data?.FinanceAccount}</>
                    )
                  }
                </>
              }
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('status')}>
              {
                <div className={common.numColor04(data?.Status)}>{WITHDRAW_STATE[data?.Status]}</div>
              }
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('createTime')}>{data?.CreateDate}</Descriptions.Item>
          </Descriptions>

          <Descriptions
            bordered
            size="small"
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('frontDeskRemarks')}>
              <Form.Item name="fnRemarks" rules={
                submitType === VERIFICATION_STATE.拒絕 ?
                  [{ required: true, message: `${i18n.t('rejectionRequiresFrontendRemark')}` }]
                  : []
              }>
                <Input name="fnRemarks" placeholder={`${i18n.t('enter')}`} />
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('backOfficeRemarks')}>
              <Form.Item name="bnRemarks">
                <Input placeholder={`${i18n.t('enter')}`} />
              </Form.Item>
            </Descriptions.Item>
          </Descriptions>
          <Row gutter={[12, 12]} align="middle" justify="center" className="mt-1">
            <Col>
              <Button type="primary" danger htmlType="submit" onClick={handleReject}>{i18n.t('reject')}</Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit" onClick={handlePass}>{i18n.t('pass')}</Button>
            </Col>
          </Row>
        </Spin>
      </Form>
      {/* Modal */}
      <PopupOrderLock isOpen={errorPopup} close={() => setErrorPopup(false)} />
    </Modal>
  )
}
// 會員出款明細
export const PopupWithdrawInfo = ({ isOpen, close, id, orderType, mutate }: any) => {
  const auth = useAuth();
  const { Account } = auth.getAdmin();
  const { data, isValidating, mutate: RefreshInfo } = AgentWithdrawInfo({ Id: id });
  useEffect(() => {
    if (isOpen) {
      RefreshInfo()
    }
  }, [isOpen]);

  const [withdrawalChannel, setWithdrawalChannel] = useState<number>(0);
  const [cryptoParams, setCryptoParams] = useState({
    Crypto: null,
    CryptoType: null,
  });

  useEffect(() => {
    if (data) {
      switch (data?.WithdrawMerchantGatewayCode) {
        case WITHDRAW.三方銀行卡:
        case WITHDRAW.三方虛擬貨幣:
          setWithdrawalChannel(0)
          break;
        case WITHDRAW.線下銀行卡:
        case WITHDRAW.線下虛擬貨幣:
          setWithdrawalChannel(1)
          break;
      }
    }
  }, [data])
  useEffect(() => {
    if (data) {
      setCryptoParams({
        Crypto: data.Crypto,
        CryptoType: data.CryptoType,
      })
    }
  }, [data]);

  const {
    data: Withdrawmerchant,
    isValidating: WithdrawmerchantLoading,
  } = GetWithdrawmerchant({
    IsOffline: withdrawalChannel,
    TicketId: id
  });
  const {
    data: Crypto,
    isValidating: CryptoLoading,
  } = GetCryptoList({
    Crypto: cryptoParams.Crypto,
    CryptoType: cryptoParams.CryptoType,
    TicketId: id,
  })

  const onReject = async () => {
    const response = await FinanceReject({
      Id: data.Id,
      FnRemark: data.FnRemark,
      BnRemark: data.FinanceAccessLogRemark
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      mutate();
      close();
    } else {
      message.error(RESPONSE_CODE_AGENT_WITHDRAW[response.Message as keyof typeof RESPONSE_CODE_AGENT_WITHDRAW]);
    }
  }

  return (
    <Modal title={i18n.t('detail')}
      open={isOpen}
      onCancel={close}
      centered={true}
      width={900}
      footer={
        <Row align="middle" justify="center" gutter={[12, 12]}>
          <Col>
            <Button type="primary" onClick={close}>{i18n.t('close')}</Button>
          </Col>
          {
            (data?.Status === WITHDRAW_STATE.支付端等待支付 ||
              data?.Status === WITHDRAW_STATE.失敗) && (data?.FinanceAccount === Account) &&
            <Col>
              <Button type="primary" danger onClick={onReject}>{i18n.t('forceFailure')}</Button>
            </Col>
          }
        </Row >
      }
    >
      <Spin spinning={isValidating}>
        <Descriptions
          className="pb-1"
          bordered
          size={'small'}
          column={2}
          labelStyle={{ width: '18%' }}
          contentStyle={{ width: '32%' }}
        >
          <Descriptions.Item label={i18n.t('order')}>
            {data?.Code}
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('memberAccount')}>
            <Row align="middle" justify="space-between">
              <Col>{data?.MemberAccount}</Col>
              <Col><CopyButton text={data?.MemberAccount} /></Col>
            </Row>
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('memberLevel')}>
            {data?.LevelName}
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('agent')}>
            {data?.AgentAccount}
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('withdrawalAmount')}>
            {common.toFormatNumber(data?.Amount)}
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('totalActualWithdrawal')}>
            {common.toFormatNumber(data?.Amount)}
          </Descriptions.Item>
          {
            (orderType === WITHDRAW.線下虛擬貨幣 || orderType === WITHDRAW.三方虛擬貨幣)
              ?
              <>
                <Descriptions.Item label={i18n.t('currencyType')}>
                  {data?.Crypto}
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('networkType')}>
                  {data?.CryptoType}
                </Descriptions.Item>
              </>
              : <>
                <Descriptions.Item label={i18n.t('withdrawalType')}>
                  <Select
                    disabled
                    className="w-full"
                    placeholder={i18n.t('pleaseSelect')}
                    value={data?.WithdrawMerchantGatewayCode}
                    options={[
                      { value: WITHDRAW.三方虛擬貨幣, label: i18n.t('thirdParty') },
                      { value: WITHDRAW.三方銀行卡, label: i18n.t('thirdParty') },
                      { value: WITHDRAW.線下虛擬貨幣, label: i18n.t('offlineMethod') },
                      { value: WITHDRAW.線下銀行卡, label: i18n.t('offlineMethod') },
                    ]}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('withdrawalChannel')}>
                  <Select
                    loading={WithdrawmerchantLoading}
                    disabled
                    className="w-full"
                    placeholder={i18n.t('pleaseSelect')}
                    value={data?.WithdrawMerchantId}
                    options={
                      Withdrawmerchant ?
                        Withdrawmerchant.map((item: { Id: number, CustomName: string }) => (
                          { value: item.Id, label: `${item.CustomName}` }
                        )) : []
                    }
                  />
                </Descriptions.Item>
              </>
          }
          {
            (orderType === WITHDRAW.線下虛擬貨幣 || orderType === WITHDRAW.三方虛擬貨幣)
            && <Descriptions.Item label={`${i18n.t('displayName')}-${i18n.t('address')}`}>
              <Form.Item name="crypto">
                <Select
                  disabled
                  loading={CryptoLoading}
                  style={{ maxWidth: 200 }}
                  className="w-full"
                  placeholder={i18n.t('pleaseSelect')}
                  defaultValue={data?.WithdrawMerchantId}
                  options={
                    Crypto ?
                      Crypto.map((item: { WithdrawMerchantId: number, CustomName: string, CryptoAddress: string }) => (
                        {
                          value: item.WithdrawMerchantId,
                          label: `${item.CustomName}-${item.CryptoAddress}`
                        }
                      )) : []
                  }
                />
              </Form.Item>
            </Descriptions.Item>
          }
          <Descriptions.Item label={`${i18n.t('processingFee')}／${i18n.t('exchangeRate')}`}>
            {common.toFormatNumber(data?.HandlingFee)}／{common.toFormatNumber(data?.ExchangeRate)}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          className="pb-1"
          bordered
          size={'small'}
          column={2}
          labelStyle={{ width: '18%' }}
          contentStyle={{ width: '32%' }}
        >
          {
            (orderType === WITHDRAW.線下虛擬貨幣 || orderType === WITHDRAW.三方虛擬貨幣)
              ? <>
                <Descriptions.Item label={i18n.t('withdrawalAddress')} span={2}>
                  <Row align="middle" justify="space-between">
                    <Col>{data?.CryptoAddress}</Col>
                    <Col><CopyButton text={data?.CryptoAddress} /></Col>
                  </Row>
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('bankAccountName')}>
                  <Row align="middle" justify="space-between">
                    <Col>{data?.Account || '-'}</Col>
                    <Col><CopyButton text={data?.Account} /></Col>
                  </Row>
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('QR Code')}>
                  {
                    data && data.Photo && JSON.parse(data?.Photo)[0]
                      ? <Image src={data && data.Photo && JSON.parse(data?.Photo)[0]} height={100} />
                      : '-'
                  }
                </Descriptions.Item>
              </>
              : <>
                <Descriptions.Item label={i18n.t('bankName')}>
                  <Row align="middle" justify="space-between">
                    <Col>{data?.BankName}</Col>
                    <Col><CopyButton text={data?.BankName} /></Col>
                  </Row>
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('accountNumber')}>
                  <Row align="middle" justify="space-between">
                    <Col>{data?.Account}</Col>
                    <Col><CopyButton text={data?.Account} /></Col>
                  </Row>
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('branchName')}>
                  <Row align="middle" justify="space-between">
                    <Col>{data?.Branch}</Col>
                    <Col><CopyButton text={data?.Branch} /></Col>
                  </Row>
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('bankProvince')}>
                  <Row align="middle" justify="space-between">
                    <Col>{data?.State || '-'}</Col>
                    <Col><CopyButton text={data?.State} /></Col>
                  </Row>
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('city')}>
                  <Row align="middle" justify="space-between">
                    <Col>{data?.City || '-'}</Col>
                    <Col><CopyButton text={data?.City} /></Col>
                  </Row>
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('phoneNumber')}>
                  <Row align="middle" justify="space-between">
                    <Col>{`${data?.PhonePrefix}-${data?.PhoneNumber}`}</Col>
                    <Col><CopyButton text={`${data?.PhonePrefix}-${data?.PhoneNumber}`} /></Col>
                  </Row>
                </Descriptions.Item>
              </>
          }
        </Descriptions>

        <Descriptions
          className="pb-1"
          bordered
          size={'small'}
          column={2}
          labelStyle={{ width: '18%' }}
          contentStyle={{ width: '32%' }}
        >
          <Descriptions.Item label={i18n.t('auditor')}>
            {data?.Status === WITHDRAW_STATE.待主管審核 &&
              <>{data?.ServiceAccount}</>}
            {data?.Status === WITHDRAW_STATE.待財務審核 &&
              <>{data?.AuditorAccount}</>}
            {data?.Status === WITHDRAW_STATE.通過 &&
              <>{data?.FinanceAccount}</>}
            {data?.Status === WITHDRAW_STATE.支付端等待支付 &&
              <>{data?.FinanceAccount}</>}
            {
              data?.Status === WITHDRAW_STATE.拒絕 &&
              (
                <>{data?.ServiceAccount}</> ||
                <>{data?.AuditorAccount}</> ||
                <>{data?.FinanceAccount}</>
              )
            }
            {
              data?.Status === WITHDRAW_STATE.失敗 &&
              (
                <>{data?.ServiceAccount}</> ||
                <>{data?.AuditorAccount}</> ||
                <>{data?.FinanceAccount}</>
              )
            }
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('status')}>
            <div className={common.numColor04(data?.Status)}>{WITHDRAW_STATE[data?.Status]}</div>
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('createTime')}>{data?.CreateDate}</Descriptions.Item>
        </Descriptions>

        <Descriptions
          bordered
          size={'small'}
          column={2}
          labelStyle={{ width: '18%' }}
          contentStyle={{ width: '32%' }}
        >
          <Descriptions.Item label={i18n.t('frontDeskRemarks')}>
            <Input value={data?.FnRemark} placeholder={`${i18n.t('enter')}`} disabled />
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('backOfficeRemarks')}>
            <Input value={data && data.FinanceAccessLogRemark} placeholder={`${i18n.t('enter')}`} disabled />
          </Descriptions.Item>
        </Descriptions>
      </Spin>
    </Modal >
  )
}
// 鎖單錯誤訊息視窗
export const PopupOrderLock = ({ isOpen, close }: any) => {
  const icon = useIcon();

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={icon.icon12} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button className="mt-1" onClick={close}>{i18n.t('close')}</Button>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('orderCurrentlyLocked')}</div>
      </Space>
    </Modal>
  )
}



// 銀行卡列表
export const ViewTransferBank = ({ data, mutate, type }: any) => {
  const { permissionCode: $p } = usePerm();
  const navigate = useNavigate();
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const [currentCard, setCurrentCard] = useState<any>({});
  const { useBankName, useBankDisplayName } = useContext(StoreContext);
  const [bankName, setBankName] = useBankName;
  const [displayName, setDisplayName] = useBankDisplayName;

  const submitDelete = async () => {
    let response: any = {}
    switch (type) {
      case TRANSFER_TYPE.入款:
        response = await DeleteDeposit({ Id: currentCard.Id });
        break;
      case TRANSFER_TYPE.出款:
        response = await DeleteWithdraw({ Id: currentCard.Id });
        break;
    }
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      mutate();
    } else {
      message.error(RESPONSE_CODE_ADD_DEPOSIT[response.Message as keyof typeof RESPONSE_CODE_ADD_DEPOSIT]);
    }
  }

  return (
    <>
      {
        $p.includes('20702') && type === TRANSFER_TYPE.入款 &&
        <Button type="primary" className={'mb-1'} onClick={() => {
          setCurrentCard({});
          setIsModalOpenEdit(true)
        }}>{i18n.t('add')}</Button>
      }
      {
        $p.includes('20401') && type === TRANSFER_TYPE.出款 &&
        <Button type="primary" className={'mb-1'} onClick={() => {
          setCurrentCard({});
          setIsModalOpenEdit(true)
        }}>{i18n.t('add')}</Button>
      }
      <Table
        size="middle"
        scroll={{ x: 1500 }}
        dataSource={data?.Data?.map((item: TransferDepositType) => ({ ...item, key: item.Id }))}
        columns={[
          {
            dataIndex: 'Position',
            title: i18n.t('sort'),
            fixed: 'left',
            align: "center",
            width: 50
          },
          {
            dataIndex: 'BankName',
            title: i18n.t('bankName'),
            fixed: 'left',
            width: 180,
          },
          {
            dataIndex: 'CustomName',
            title: i18n.t('displayName'),
            width: 180,
          },
          {
            dataIndex: 'Name',
            title: i18n.t('accountName'),
            width: 130,
          },
          {
            dataIndex: 'Account',
            title: i18n.t('accountNumber'),
            width: 160,
          },
          {
            dataIndex: 'Branch',
            title: i18n.t('branchName'),
            width: 130,
          },
          {
            dataIndex: 'PhoneNumber',
            title: i18n.t('phoneNumber'),
            width: 160,
            render: (val, { PhonePrefix }) => val ? `${PhonePrefix}-${val}` : '-'
          },
          {
            dataIndex: 'State',
            title: i18n.t('bankProvince'),
            width: 130,
            render: (val) => val || '-'
          },
          {
            dataIndex: 'City',
            title: i18n.t('city'),
            width: 130,
            render: (val) => val || '-'
          },
          {
            dataIndex: 'LevelName',
            title: i18n.t('applicableMemberLevel'),
            width: 200,
            render: (val, record: any) => <MemberLevelTableDisplay levelName={val} />
          },
          {
            dataIndex: 'Minimum',
            title: type === TRANSFER_TYPE.入款 ? i18n.t('minimumDepositAmount') : i18n.t('minWithdrawal'),
            align: 'right',
            width: 180,
            render: (val) => common.toFormatNumber(val)
          },
          {
            dataIndex: 'Maximum',
            title: type === TRANSFER_TYPE.入款 ? i18n.t('maximumDepositAmount') : i18n.t('maxWithdrawal'),
            align: 'right',
            width: 180,
            render: (val) => val !== null ? common.toFormatNumber(val) : '-'
          },
          {
            dataIndex: 'Rate',
            title: i18n.t('paymentFeePercentageTransactionFee'),
            align: 'right',
            width: 200,
            render: (val, record) => (
              <>{`${common.convertedToPercentage01(val)} %`} / {common.toFormatNumber(record.HandlingFee, 2)}</>
            )
          },
          {
            dataIndex: 'Status',
            title: i18n.t('status'),
            align: "center",
            width: 100,
            render: (val) => <div className={colorChange(val)}>{TRANSFER_SETTING_STATUS[val]}</div>
          },
          {
            dataIndex: 'QR_Code',
            title: i18n.t('QRcode'),
            className: type === TRANSFER_TYPE.入款 ? '' : 'hide',
            align: 'center',
            width: 100,
            render: (val) => val ? <Image src={val} width={30} height={30} /> : '-'
          },
          {
            dataIndex: 'TotalCount',
            title: i18n.t('totalTransactionsToday'),
            width: 150,
            align: 'right',
            render: (val, record) => (
              <>
                {
                  $p.includes('20901') && type === TRANSFER_TYPE.入款
                    ? <Button type="link" onClick={() => {
                      setBankName(record.BankName);
                      setDisplayName(record.CustomName);
                      navigate(`/finance/transfer-depositTotal/${record.DepositMerchantId}`);
                    }}>
                      {common.toFormatNumber(val, 0)}
                    </Button>
                    : (
                      $p.includes('20601') && type === TRANSFER_TYPE.出款
                        ? <Button type="link" onClick={() => {
                          setBankName(record.BankName);
                          setDisplayName(record.CustomName);
                          navigate(`/finance/transfer-withdrawTotal/${record.WithdrawMerchantId}`);
                        }}>
                          {common.toFormatNumber(val, 0)}
                        </Button>
                        : common.toFormatNumber(val, 0)
                    )
                }
              </>
            )
          },
          {
            dataIndex: 'TotalAmount',
            title: type === TRANSFER_TYPE.入款 ? i18n.t('totalDepositsToday') : i18n.t('totalWithdrawalToday'),
            width: 150,
            align: 'right',
            render: (val, record) => (
              <>
                {
                  $p.includes('20901') && type === TRANSFER_TYPE.入款
                    ? <Button type="link" onClick={() => {
                      setBankName(record.BankName);
                      setDisplayName(record.CustomName);
                      navigate(`/finance/transfer-depositTotal/${record.DepositMerchantId}`);
                    }}>
                      {common.toFormatNumber(val)}
                    </Button>
                    : (
                      $p.includes('20601') && type === TRANSFER_TYPE.出款
                        ? <Button type="link" onClick={() => {
                          setBankName(record.BankName);
                          setDisplayName(record.CustomName);
                          navigate(`/finance/transfer-withdrawTotal/${record.WithdrawMerchantId}`);
                        }}>
                          {common.toFormatNumber(val)}
                        </Button>
                        : common.toFormatNumber(val)
                    )
                }
              </>
            )
          },
          {
            dataIndex: 'BnDescription',
            title: i18n.t('backOfficeRemarks'),
            ellipsis: true,
            width: 200,
            render: (val) => val || '-'
          },
          {
            dataIndex: 'comment',
            title: i18n.t('operation'),
            fixed: 'right',
            width: 100,
            render: (_, record) => (
              <>
                {
                  $p.includes('20703') && type === TRANSFER_TYPE.入款 &&
                  <Button type="link" onClick={() => {
                    setCurrentCard(record);
                    setIsModalOpenEdit(true);
                  }}>{i18n.t('edit')}</Button>
                }
                {
                  $p.includes('20402') && type === TRANSFER_TYPE.出款 &&
                  <Button type="link" onClick={() => {
                    setCurrentCard(record);
                    setIsModalOpenEdit(true);
                  }}>{i18n.t('edit')}</Button>
                }
                {
                  $p.includes('20705') && type === TRANSFER_TYPE.入款 &&
                  <Button type="link" onClick={() => {
                    setCurrentCard(record);
                    setIsModalOpenDelete(true);
                  }}>{i18n.t('delete')}</Button>
                }
                {
                  $p.includes('20405') && type === TRANSFER_TYPE.出款 &&
                  <Button type="link" onClick={() => {
                    setCurrentCard(record);
                    setIsModalOpenDelete(true);
                  }}>{i18n.t('delete')}</Button>
                }
              </>
            ),
          },
        ]}
        pagination={false}
      />

      <PopupBank isOpen={isModalOpenEdit} close={() => setIsModalOpenEdit(false)} mutate={mutate} id={currentCard.Id} type={type} />
      <InquiryWindow isOpen={isModalOpenDelete} close={() => setIsModalOpenDelete(false)}
        msg={`${i18n.t('confirmDeletion')} ${currentCard.CustomName} ${i18n.t('isData')}`} action={submitDelete} />
    </>
  )
}

// 站長銀行卡新增編輯
// 聖經新增編輯
export const PopupBank = ({ isOpen, close, mutate, type, id }: any) => {
  const mode = !id ? MODE.新增 : MODE.編輯;

  const [form] = useForm();
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);

  const { data: DepositBankInfo, isValidating: depositValidating, mutate: mutateDeposit } = DepositOfflineOne({
    Id: id
  }, type === TRANSFER_TYPE.入款);
  const { data: WithdrawalBankInfo, isValidating: withdrawalValidating, mutate: mutateWithdrawal } = WithdrawalOfflineOne({
    Id: id
  }, type === TRANSFER_TYPE.出款);
  useEffect(() => {
    if (isOpen) mutateOne();
  }, [isOpen]);
  useEffect(() => {
    if ((DepositBankInfo || WithdrawalBankInfo) && isOpen) {
      const info = DepositBankInfo || WithdrawalBankInfo;
      const LevelsId = JSON.parse(info.LevelsId);
      form.setFieldsValue({
        BankName: info.BankName,
        CustomName: info.CustomName,
        Name: info.Name,
        Account: info.Account,
        Branch: info.Branch,
        Phone: info.PhoneNumber,
        State: info.State,
        City: info.City,
        Rate: common.convertedToPercentage01(info.Rate),
        HandlingFee: info.HandlingFee,
        LevelsId: LevelsId,
        Minimum: info.Minimum,
        Maximum: info.Maximum,
        Status: info.Status,
        Position: info.Position,
        PhonePrefix: info.PhonePrefix.replace('+', ''),
        FnDescription: info.FnDescription,
        BnDescription: info.BnDescription,
        MerchantId: info.DepositMerchantId || info.WithdrawMerchantId
      });
      info.QR_Code && setImageData([
        {
          uid: info.QR_Code,
          name: info.QR_Code,
          url: info.QR_Code,
        },
      ])
    }
  }, [DepositBankInfo, WithdrawalBankInfo, isOpen]);

  const mutateOne = () => {
    mutateDeposit();
    mutateWithdrawal();
  }

  const onFinish = async (formData: any) => {
    const send: any = {
      BankName: formData.BankName,
      CustomName: formData.CustomName,
      Name: formData.Name,
      Account: formData.Account,
      Branch: formData.Branch,
      PhonePrefix: formData.PhonePrefix ? `+${formData.PhonePrefix}` : '',
      Phone: formData.Phone,
      State: formData.State,
      City: formData.City,
      Rate: common.convertedToPercentage02(formData.Rate),
      HandlingFee: formData.HandlingFee,

      LevelId: formData.LevelsId,
      Minimum: formData.Minimum,
      Maximum: formData.Maximum,
      Status: formData.Status,
      Position: formData.Position,

      FnDescription: formData.FnDescription,
      BnDescription: formData.BnDescription,
    }

    let response: any = {}
    if (type === TRANSFER_TYPE.入款) send.QRCode = imageData[0] ? imageData[0].url : '';
    if (mode === MODE.新增) {
      response = type === TRANSFER_TYPE.入款 ? await AddDepositBank(send) : await AddWithdrawBank(send)
    } else if (mode === MODE.編輯) {
      send.Id = id
      send.MerchantId = formData.MerchantId;
      response = type === TRANSFER_TYPE.入款 ? await EditDepositBank(send) : await EditWithdrawBank(send)
    }

    if (response.State === RESPONSE_TYPE.成功) {
      message.success(mode === MODE.新增 ? i18n.t('addSuccess') : i18n.t('editSuccess'));
      onClose();
      mutate();
    } else {
      message.error(RESPONSE_CODE_ADD_DEPOSIT[response.Message as keyof typeof RESPONSE_CODE_ADD_DEPOSIT]);
    }
  }

  const onClose = () => {
    form.resetFields();
    setImageData([]);
    close();
  }

  return (
    <Modal
      title={mode === MODE.新增 ? i18n.t('add') : i18n.t('edit')}
      open={isOpen}
      onCancel={onClose}
      centered
      footer={false}
      width={450}
    >
      <Spin spinning={depositValidating || withdrawalValidating}>
        <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{
          Status: ENABLE.啟用
        }}>
          <Form.Item className="display-none" name="MerchantId" />
          <Row align="middle" gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item name="BankName" label={i18n.t('bankName')} rules={[{ required: true }]}>
                <Input placeholder={`${i18n.t('enter')}`} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="CustomName" label={i18n.t('displayName')} rules={[{ required: true }]}>
                <Input placeholder={`${i18n.t('enter')}`} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="Name" label={i18n.t('accountName')} rules={[{ required: true }]}>
                <Input placeholder={`${i18n.t('enter')}`} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="Account" label={i18n.t('accountNumber')} rules={[{ required: true }]}>
                <Input placeholder={`${i18n.t('enter')}`} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="Branch" label={i18n.t('branchName')} rules={[{ required: true }]}>
                <Input placeholder={`${i18n.t('enter')}`} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="State" label={i18n.t('bankProvince')} >
                <Input placeholder={`${i18n.t('enter')}`} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="PhonePrefix" label={i18n.t('countryCode')}
                rules={common.verify({ point: 7, required: false })}>
                <Input placeholder={`${i18n.t('enter')}`} addonBefore={'+'} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="Phone" label={i18n.t('phoneNumber')}>
                <Input placeholder={`${i18n.t('enter')}`} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="City" label={i18n.t('city')}>
                <Input placeholder={`${i18n.t('enter')}`} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="Rate" label={i18n.t('cashFlowRate')} rules={verify({ max: 100 })}>
                <InputNumber className="w-full" placeholder={`${i18n.t('enter')}`} addonAfter={'%'} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="HandlingFee" label={i18n.t('processingFee')} rules={verify({ point: 4 })}>
                <InputNumber className="w-full" placeholder={`${i18n.t('enter')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="LevelsId" label={i18n.t('applicableMemberLevel')} rules={[{ required: true }]}>
                <MemberLevelSelect mode="multiple" name="LevelsId" form={form} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="Minimum"
                label={type === TRANSFER_TYPE.入款 ? i18n.t('minimumDepositAmount') : i18n.t('minWithdrawal')}
                rules={verify({ point: 4 })}>
                <InputNumber className="w-full" placeholder={`${i18n.t('enter')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="Maximum"
                label={type === TRANSFER_TYPE.入款 ? i18n.t('maximumDepositAmount') : i18n.t('maxWithdrawal')}
                rules={verify({ point: 4, required: false })}>
                <InputNumber className="w-full" placeholder={`${i18n.t('enter')}`} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="Status" label={i18n.t('status')} rules={[{ required: true }]}>
                <Select className="w-full" options={common.enumToOptions(ENABLE)} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="Position" label={i18n.t('sort')} rules={verify({ point: 0 })}>
                <InputNumber className="w-full" placeholder={`${i18n.t('enter')}`} />
              </Form.Item>
            </Col>
            {type === TRANSFER_TYPE.入款 &&
              <Col span={24}>
                <Form.Item name="QRCode" label={i18n.t('qrCode')}>
                  <UploadImage
                    name="QRCode"
                    url={'/upload/images/public/depositmerchant'}
                    imageData={imageData}
                    setImageData={setImageData}
                  />
                </Form.Item>
                <div className="color-03 size-12">{i18n.t('onlyJpgOrPngFilesAllowedAndSizeNotExceed500kb')}</div>
              </Col>
            }
            <Col span={24}>
              <Form.Item name="BnDescription" label={i18n.t('backOfficeRemarks')}>
                <Input placeholder={`${i18n.t('enter')}`} />
              </Form.Item>
            </Col>
            <Col span={24} className="mt-1">
              <Row align="middle" justify="center" gutter={[12, 12]}>
                <Col>
                  <Button onClick={onClose}>{i18n.t('cancel')}</Button>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

// 虛擬貨幣列表
export const ViewTransferCrypto = ({ data, mutate, type }: {
  data: {
    Data: TransferDepositType[],
    TotalRecord: number;
  },
  mutate: () => void;
  type: TRANSFER_TYPE
}) => {
  const { permissionCode: $p } = usePerm();
  const navigate = useNavigate();
  const { useBankName, useBankDisplayName } = useContext(StoreContext);
  const [bankName, setBankName] = useBankName;
  const [displayName, setDisplayName] = useBankDisplayName;
  const [addCrypto, setAddCrypto] = useState(false);
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const [currentCard, setCurrentCard] = useState<any>({});

  const submitDelete = async () => {
    let response: any = {}
    switch (type) {
      case TRANSFER_TYPE.入款:
        response = await DeleteDeposit({ Id: currentCard.Id });
        break;
      case TRANSFER_TYPE.出款:
        response = await DeleteWithdraw({ Id: currentCard.Id });
        break;
    }
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      mutate();
    } else {
      message.error(RESPONSE_CODE_ADD_DEPOSIT[response.Message as keyof typeof RESPONSE_CODE_ADD_DEPOSIT]);
    }
  }

  return (
    <>
      {
        $p.includes('20702') && type === TRANSFER_TYPE.入款 &&
        <Button type="primary" className={'mb-1'} onClick={() => {
          setCurrentCard({});
          setAddCrypto(true)
        }}>{i18n.t('add')}</Button>
      }
      {
        $p.includes('20401') && type === TRANSFER_TYPE.出款 &&
        <Button type="primary" className={'mb-1'} onClick={() => {
          setCurrentCard({});
          setAddCrypto(true)
        }}>{i18n.t('add')}</Button>
      }
      <Table
        size="middle"
        scroll={{ x: 1500 }}
        dataSource={data?.Data?.map((item: TransferDepositType) => ({ key: item.Id, ...item }))}
        columns={[
          {
            dataIndex: 'Position',
            title: i18n.t('sort'),
            fixed: 'left',
            align: "center",
            width: 50
          },
          {
            dataIndex: 'Crypto',
            title: i18n.t('currencyType'),
            fixed: 'left',
            width: 100,
          },
          {
            dataIndex: 'CryptoType',
            title: i18n.t('networkType'),
            fixed: 'left',
            width: 150,
          },
          {
            dataIndex: 'CustomName',
            title: i18n.t('displayName'),
            fixed: 'left',
            width: 200,
          },
          {
            dataIndex: 'LevelName',
            title: i18n.t('applicableMemberLevel'),
            width: 200,
            render: (val) => <MemberLevelTableDisplay levelName={val} />
          },
          {
            dataIndex: 'Minimum',
            title: type === TRANSFER_TYPE.入款 ? i18n.t('minimumDepositAmount') : i18n.t('minWithdrawal'),
            align: 'right',
            width: 200,
            render: (val) => common.toFormatNumber(val)
          },
          {
            dataIndex: 'Maximum',
            title: type === TRANSFER_TYPE.入款 ? i18n.t('maximumDepositAmount') : i18n.t('maxWithdrawal'),
            align: 'right',
            width: 200,
            render: (val) => val !== null ? common.toFormatNumber(val) : '-'
          },
          {
            title: i18n.t('paymentFeePercentageTransactionFee'),
            align: 'right',
            width: 200,
            render: (_, record) => (
              <>{`${common.convertedToPercentage01(record.Rate)} %`} / {common.toFormatNumber(record.HandlingFee, 2)}</>
            )
          },
          {
            dataIndex: 'ExchangeRate',
            title: i18n.t('exchangeRate'),
            align: 'right',
            width: 150,
            render: (val) => common.toFormatNumber(val, 2)
          },
          {
            dataIndex: 'CryptoAddress',
            title: i18n.t('address'),

            width: 250,
            render: (val) => (
              <Row align="middle" justify="space-between">
                <Col>{val}</Col>
                <Col><CopyButton text={val} /></Col>
              </Row>
            )
          },
          {
            dataIndex: 'QR_Code',
            title: i18n.t('QRcode'),
            className: type === TRANSFER_TYPE.入款 ? '' : 'hide ',
            align: 'center',
            width: 100,
            render: (val) => val ? <Image src={val} width={30} height={30} /> : '-'
          },
          {
            dataIndex: 'TotalCount',
            title: i18n.t('totalTransactionsToday'),
            width: 150,
            align: 'right',
            render: (val, record: any) => (
              <>
                {
                  $p.includes('20901') && type === TRANSFER_TYPE.入款
                    ? <Button type="link" onClick={() => {
                      setBankName(record.Crypto);
                      setDisplayName(record.CustomName);
                      navigate(`/finance/transfer-depositTotal/${record.DepositMerchantId}`);
                    }}>
                      {common.toFormatNumber(val, 0)}
                    </Button>
                    : (
                      $p.includes('20601') && type === TRANSFER_TYPE.出款
                        ? <Button type="link" onClick={() => {
                          setBankName(record.Crypto);
                          setDisplayName(record.CustomName);
                          navigate(`/finance/transfer-withdrawTotal/${record.WithdrawMerchantId}`);
                        }}>
                          {common.toFormatNumber(val, 0)}
                        </Button>
                        : common.toFormatNumber(val, 0)
                    )
                }
              </>
            )
          },
          {
            dataIndex: 'TotalAmount',
            title: type === TRANSFER_TYPE.入款 ? i18n.t('totalDepositsToday') : i18n.t('totalWithdrawalToday'),
            width: 150,
            align: 'right',
            render: (val, record: any) => (
              <>
                {
                  $p.includes('20901') && type === TRANSFER_TYPE.入款
                    ? <Button type="link" onClick={() => {
                      setBankName(record.Crypto);
                      setDisplayName(record.CustomName);
                      navigate(`/finance/transfer-depositTotal/${record.DepositMerchantId}`);
                    }}>
                      {common.toFormatNumber(val)}
                    </Button>
                    : (
                      $p.includes('20601') && type === TRANSFER_TYPE.出款
                        ? <Button type="link" onClick={() => {
                          setBankName(record.Crypto);
                          setDisplayName(record.CustomName);
                          navigate(`/finance/transfer-withdrawTotal/${record.WithdrawMerchantId}`);
                        }}>
                          {common.toFormatNumber(val)}
                        </Button>
                        : common.toFormatNumber(val, 0)
                    )
                }
              </>
            )
          },
          {
            dataIndex: 'Status',
            title: i18n.t('status'),
            align: "center",
            width: 100,
            render: (val) =>
              <div className={colorChange(val)}>{TRANSFER_SETTING_STATUS[val]}</div>
          },
          {
            title: i18n.t('operation'),
            fixed: 'right',
            width: 100,
            render: (_, record) => (
              <>
                {
                  $p.includes('20703') && type === TRANSFER_TYPE.入款 &&
                  <Button type="link" onClick={() => {
                    setCurrentCard(record);
                    setAddCrypto(true);
                  }}>{i18n.t('edit')}</Button>
                }
                {
                  $p.includes('20402') && type === TRANSFER_TYPE.出款 &&
                  <Button type="link" onClick={() => {
                    setCurrentCard(record);
                    setAddCrypto(true);
                  }}>{i18n.t('edit')}</Button>
                }
                {
                  $p.includes('20705') && type === TRANSFER_TYPE.入款 &&
                  <Button type="link" onClick={() => {
                    setCurrentCard(record);
                    setIsModalOpenDelete(true);
                  }}>{i18n.t('delete')}</Button>
                }
                {
                  $p.includes('20405') && type === TRANSFER_TYPE.出款 &&
                  <Button type="link" onClick={() => {
                    setCurrentCard(record);
                    setIsModalOpenDelete(true);
                  }}>{i18n.t('delete')}</Button>
                }
              </>
            ),
          },
        ]}
        pagination={false}
      />
      <PopupCrypto isOpen={addCrypto} close={() => setAddCrypto(false)} mutate={mutate} id={currentCard.Id} type={type} />
      <InquiryWindow isOpen={isModalOpenDelete} close={() => setIsModalOpenDelete(false)}
        msg={`${i18n.t('confirmDeletion')} ${currentCard.CustomName} ${i18n.t('isData')}`} action={submitDelete} />
    </>
  )
}

// 虛擬貨幣新增編輯
export const PopupCrypto = ({ isOpen, close, mutate, type, id }: any) => {
  const mode = !id ? MODE.新增 : MODE.編輯;

  const [form] = useForm();
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);

  const { data: DepositBankInfo, mutate: mutateDeposit } = DepositOfflineOne({
    Id: id
  }, type === TRANSFER_TYPE.入款);
  const { data: WithdrawalBankInfo, mutate: mutateWithdrawal } = WithdrawalOfflineOne({
    Id: id
  }, type === TRANSFER_TYPE.出款);
  useEffect(() => {
    if (isOpen) mutateOne();
  }, [isOpen]);
  useEffect(() => {
    if ((DepositBankInfo || WithdrawalBankInfo) && isOpen) {
      const info = DepositBankInfo || WithdrawalBankInfo;
      const LevelsId = JSON.parse(info.LevelsId);
      form.setFieldsValue({
        Crypto: info.Crypto,
        CryptoType: info.CryptoType,
        CustomName: info.CustomName,
        Rate: common.convertedToPercentage01(info.Rate),
        HandlingFee: info.HandlingFee,
        ExchangeRate: info.ExchangeRate,
        LevelId: LevelsId,
        Minimum: info.Minimum,
        Maximum: info.Maximum,
        CryptoAddress: info.CryptoAddress,
        Status: info.Status,
        Position: info.Position,
        MerchantId: info.DepositMerchantId || info.WithdrawMerchantId
      });
      info.QR_Code && setImageData([
        {
          uid: info.QR_Code,
          name: info.QR_Code,
          url: info.QR_Code,
        },
      ])
    }
  }, [DepositBankInfo, WithdrawalBankInfo, isOpen]);

  const mutateOne = () => {
    mutateDeposit();
    mutateWithdrawal();
  }

  const onFinish = async (formData: any) => {
    const send: any = {
      Crypto: formData.Crypto,
      CryptoType: formData.CryptoType,
      CustomName: formData.CustomName,
      Rate: common.convertedToPercentage02(formData.Rate),
      HandlingFee: formData.HandlingFee,
      ExchangeRate: formData.ExchangeRate,
      LevelId: formData.LevelId,
      Minimum: formData.Minimum,
      Maximum: formData.Maximum,
      CryptoAddress: formData.CryptoAddress,
      Status: formData.Status,
      Position: formData.Position
    }

    let response: any = {}
    if (type === TRANSFER_TYPE.入款) send.QRCode = imageData[0] ? imageData[0].url : '';
    if (mode === MODE.新增) {
      response = type === TRANSFER_TYPE.入款 ? await AddDepositCrypto(send) : await AddWithdrawCrypto(send)
    } else if (mode === MODE.編輯) {
      send.Id = id
      send.MerchantId = formData.MerchantId;
      response = type === TRANSFER_TYPE.入款 ? await EditDepositCrypto(send) : await EditWithdrawCrypto(send)
    }
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(mode === MODE.新增 ? i18n.t('addSuccess') : i18n.t('editSuccess'));
      onClose();
      mutate();
    } else {
      message.error(RESPONSE_CODE_ADD_DEPOSIT[response.Message as keyof typeof RESPONSE_CODE_ADD_DEPOSIT]);
    }
  }

  const onClose = () => {
    form.resetFields();
    setImageData([]);
    close();
  }

  return (
    <Modal
      title={mode === MODE.新增 ? i18n.t('add') : i18n.t('edit')}
      open={isOpen}
      onCancel={onClose}
      centered
      footer={false}
      width={450}
    >
      <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{
        Status: ENABLE.啟用
      }}>
        <Form.Item className="display-none" name="MerchantId" />
        <Row align="middle" gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="Crypto" label={i18n.t('currencyType')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="CryptoType" label={i18n.t('networkType')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="CustomName" label={i18n.t('displayName')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row align="middle" gutter={[12, 12]}>
              <Col span={12}>
                <Form.Item name="Rate" label={i18n.t('cashFlowRate')} rules={verify({ max: 100 })}>
                  <InputNumber className="w-full" placeholder={`${i18n.t('enter')}`} addonAfter={'%'} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="HandlingFee" label={i18n.t('processingFee')} rules={verify({ point: 4 })}>
                  <InputNumber className="w-full" placeholder={`${i18n.t('enter')}`} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Form.Item name="ExchangeRate" label={i18n.t('exchangeRate')} rules={verify({ point: 4 })}>
              <InputNumber className="w-full" placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="LevelId" label={i18n.t('applicableMemberLevel')} rules={[{ required: true }]}>
              <MemberLevelSelect mode="multiple" name="LevelId" form={form} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="Minimum"
              label={type === TRANSFER_TYPE.入款 ? i18n.t('minimumDepositAmount') : i18n.t('minWithdrawal')}
              rules={verify({ point: 4 })}>
              <InputNumber className="w-full" placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="Maximum"
              label={type === TRANSFER_TYPE.入款 ? i18n.t('maximumDepositAmount') : i18n.t('maxWithdrawal')}
              rules={verify({ point: 4, required: false })}>
              <InputNumber className="w-full" placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="CryptoAddress" label={i18n.t('address')}
              rules={[
                { required: true },
                {
                  validator: async (r, value) => {
                    if (/[^\w\d]/g.test(value)) {
                      return Promise.reject(i18n.t('addressCanOnlyContainAlphanumeric'));
                    }
                    return Promise.resolve();
                  }
                }
              ]}>
              <Input placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
          {type === TRANSFER_TYPE.入款 &&
            <Col span={24}>
              <Form.Item name="QRCode" label={i18n.t('qrCode')}>
                <UploadImage
                  name="QRCode"
                  url={'/upload/images/public/depositmerchant'}
                  imageData={imageData}
                  setImageData={setImageData}
                />
              </Form.Item>
              <div className="color-03 size-12">{i18n.t('onlyJpgOrPngFilesAllowedAndSizeNotExceed500kb')}</div>
            </Col>
          }
          <Col span={24}>
            <Row align="middle" gutter={[12, 12]}>
              <Col span={12}>
                <Form.Item name="Status" label={i18n.t('status')} rules={[{ required: true }]}>
                  <Select
                    className="w-full"
                    options={common.enumToOptions(ENABLE)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="Position" label={i18n.t('sort')} rules={verify({ point: 0 })}>
                  <InputNumber className="w-full" placeholder={`${i18n.t('enter')}`} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="mt-1">
            <Row align="middle" justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

// 三方出入款編輯(不用新增)
export const PopupEditMerchant = ({ isOpen, close, id, mutate, type }: any) => {

  const [form] = useForm();

  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);

  const { data: DepositMerchantInfo, mutate: mutateDeposit } = GetDepositmerchantOne({ Id: id }, !!id && type === MERCHANT_TYPE.入款);
  const { data: WithdrawMerchantInfo, mutate: mutateWithdrawal } = GetWithdrawmerchantOne({ Id: id }, !!id && type === MERCHANT_TYPE.出款);

  useEffect(() => {
    if (isOpen) mutateOne();
  }, [isOpen])
  useEffect(() => {
    if ((DepositMerchantInfo || WithdrawMerchantInfo) && isOpen) {
      const info = DepositMerchantInfo || WithdrawMerchantInfo;
      const LevelsId = JSON.parse(info.LevelsId);
      form.setFieldsValue({
        Name: info.Name,
        CustomName: info.CustomName,
        GatewayCode: info.GatewayCode,
        Rate: common.convertedToPercentage01(info.Rate),
        HandlingFee: info.HandlingFee,
        Minimum: info.Minimum,
        Maximum: info.Maximum,
        LevelsId: LevelsId,
        Status: info.Status,
        Position: info.Position
      })
      info.QR_Code && setImageData([
        {
          uid: info.QR_Code,
          name: info.QR_Code,
          url: info.QR_Code,
        },
      ])
    }
  }, [DepositMerchantInfo, WithdrawMerchantInfo, isOpen])

  const mutateOne = () => {
    mutateDeposit();
    mutateWithdrawal();
  }

  const onFinish = async (formData: any) => {
    const send: any = {
      Id: id,
      CustomName: formData.CustomName,
      Rate: Number(common.convertedToPercentage02(formData.Rate)),
      HandlingFee: formData.HandlingFee,
      LevelsId: formData.LevelsId,
      Minimum: formData.Minimum,
      Maximum: formData.Maximum,
      Status: formData.Status,
      Position: formData.Position
    }

    let response: any = {}
    if (type === MERCHANT_TYPE.入款) {
      send.QRCode = imageData[0] ? imageData[0].url : '';
      response = await PostDepositmerchantUpdate(send)
    } else {
      response = await PostWithdrawmerchantUpdate(send)
    }
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('editSuccess'));
      mutate();
      onClose();
    } else {
      message.error(RESPONSE_CODE_MERCHANT[response.Message as keyof typeof RESPONSE_CODE_MERCHANT]);
    }
  }

  const onClose = () => {
    form.resetFields();
    setImageData([]);
    close();
  }

  return (
    <Modal title={i18n.t('edit')}
      open={isOpen}
      onCancel={onClose}
      centered
      footer={false}
      width={450}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row align="middle" gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="Name" label={i18n.t('thirdPartyName')} rules={[{ required: true }]}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="CustomName" label={i18n.t('displayName')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="GatewayCode" label={i18n.t('channelName')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('enter')}`} disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="Rate" label={i18n.t('cashFlowRate')} rules={verify({ max: 100 })}>
              <InputNumber className="w-full" placeholder={`${i18n.t('enter')}`} addonAfter={'%'} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="HandlingFee" label={i18n.t('processingFee')} rules={verify({ point: 4 })}>
              <InputNumber className="w-full" placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="LevelsId" label={i18n.t('applicableMemberLevel')} rules={[{ required: true }]}>
              <MemberLevelSelect mode="multiple" name="LevelsId" form={form} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Minimum" label={i18n.t('minimumDepositAmount')}
              rules={verify({ point: 4 })}>
              <InputNumber className="w-full" placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Maximum" label={i18n.t('maximumDepositAmount')}
              rules={verify({ point: 4, required: false })}>
              <InputNumber className="w-full" placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
          {type === MERCHANT_TYPE.入款 &&
            <Col span={24}>
              <Form.Item name="QRCode" valuePropName="fileList" label={i18n.t('qrCode')}>
                <UploadImage
                  name="QRCode"
                  url={'/upload/images/public/depositmerchant'}
                  imageData={imageData}
                  setImageData={setImageData}
                />
              </Form.Item>
              <div className="color-03 size-12">{i18n.t('onlyJpgOrPngFilesAllowedAndSizeNotExceed500kb')}</div>
            </Col>
          }
          <Col span={24}>
            <Row align="middle" gutter={[12, 12]}>
              <Col span={12}>
                <Form.Item name="Status" label={i18n.t('status')} rules={[{ required: true }]}>
                  <Select
                    className="w-full"
                    options={common.enumToOptions(ENABLE)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="Position" label={i18n.t('sort')} rules={verify({ point: 0 })}>
                  <InputNumber className="w-full" placeholder={`${i18n.t('enter')}`} />
                </Form.Item>
              </Col>
              <Col span={24} className="mt-1">
                <Row align="middle" justify="center" gutter={[12, 12]}>
                  <Col>
                    <Button onClick={onClose}>{i18n.t('cancel')}</Button>
                  </Col>
                  <Col>
                    <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal >
  )
}
