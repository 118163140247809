import {
  axiosGetAllData,
  axiosGetAuth,
  axiosPostAuth,
  baseURL,
  useFetchDataCommon
} from "services";

export const BonusList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/bonus/list`, params);
export const BonusOne = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/bonus/info`, params)
export const CreateBonus = (data: any) => axiosPostAuth(`${baseURL}/bonus/create`, data);
export const CreateCheckInBonus = (data: any) => axiosPostAuth(`${baseURL}/bonus/checkin/create`, data);
export const EditBonus = (data: any) => axiosPostAuth(`${baseURL}/bonus/update`, data);
export const DeleteBonus = (data: any) => axiosPostAuth(`${baseURL}/bonus/delete`, data);
export const RecommendBonus = () => useFetchDataCommon(axiosGetAuth, `${baseURL}/bonus/recommend/info`);
export const EditRecommendBonus = (data: any) => axiosPostAuth(`${baseURL}/bonus/recommend/update`, data);
export const CheckInBonus = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/bonus/checkin/info`, params);
export const EditCheckInBonus = (data: any) => axiosPostAuth(`${baseURL}/bonus/checkin/update`, data);
export const BonusOption = (params?: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/bonus/name/list`, params);
