import {
  axiosGetAllData,
  axiosGetAuth,
  axiosPostAuth,
  baseURL,
  useFetchDataCommon
} from "services";

export const GetSEOList = () => useFetchDataCommon(axiosGetAllData, `${baseURL}/seo/list`);
export const CreateSEO = (data: any) => axiosPostAuth(`${baseURL}/seo/create`, data);
export const GetSEOOne = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/seo/one`, params);
export const UpdateSEO = (data: any) => axiosPostAuth(`${baseURL}/seo/update`, data);
export const DeleteSEO = (data: any) => axiosPostAuth(`${baseURL}/seo/delete`, data);