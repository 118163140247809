import { Button, Col, Descriptions, Form, Input, Row, Select, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { UploadFile } from 'antd/lib';
import { useForm } from 'antd/lib/form/Form';
import { LayoutNav, LayoutTabPlatform, UploadImage } from 'components/layout.component';
import { RESPONSE_CODE_SITE_SETTING } from 'constants/response';
import { ENABLE, RESPONSE_TYPE } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import useSite, { SettingInfo } from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { UpdateSiteSettingInfo } from 'services/account.service';
import { enumToOptions } from 'utils/common';

// 站台設置

enum CURRENCY {
  美元 = 'USD',
  人民幣 = 'CNY',
  歐元 = 'EUR',
}

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();

  const [form] = useForm();
  const [isEditDone, setIsEditDone] = useState(false);
  const [iconData, setIconData] = useState<UploadFile<any>[]>([]);
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);

  const [settingInfo, setSettingInfo] = useState<SettingInfo>();
  const { data: SettingInfo, mutate } = useSite();
  useEffect(() => {
    if (SettingInfo) {
      setSettingInfo(SettingInfo);
    }
  }, [SettingInfo])
  useEffect(() => {
    if (settingInfo) {
      form.setFieldsValue({
        SiteName: settingInfo.SiteName,
        currency: settingInfo.Currency,
        Status: settingInfo.Status,
        CreateDate: settingInfo.CreateDate,
        Type: settingInfo.Type,
        WhiteList: settingInfo.WhiteList
      })
      setIconData([
        {
          uid: settingInfo.Logo1,
          name: settingInfo.Logo1,
          url: settingInfo.Logo1
        },
      ]);
      setImageData([
        {
          uid: settingInfo.Logo2,
          name: settingInfo.Logo2,
          url: settingInfo.Logo2
        },
      ]);
    }
  }, [settingInfo])

  const onFinish = async (value: any) => {
    const response = await UpdateSiteSettingInfo({
      SiteName: value.SiteName,
      Status: value.Status,
      Photo: [
        iconData ? iconData[0].url : settingInfo?.Logo1,
        imageData ? imageData[0].url : settingInfo?.Logo2,
      ],
      WhiteList: value.WhiteList
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      setIsEditDone(false);
      mutate();
    } else {
      message.error(RESPONSE_CODE_SITE_SETTING[response.Message as keyof typeof RESPONSE_CODE_SITE_SETTING]);
    }
  }

  const onCancel = () => {
    setIsEditDone(false);
    if (settingInfo) {
      form.setFieldsValue({
        platformName: settingInfo.SiteName,
        currency: settingInfo.Currency,
        status: settingInfo.Status,
        createTime: settingInfo.CreateDate,
        platformType: settingInfo.Type,
      })
      setIconData([
        {
          uid: settingInfo.Logo1,
          name: settingInfo.Logo1,
          url: settingInfo.Logo1
        },
      ]);
      setImageData([
        {
          uid: settingInfo.Logo2,
          name: settingInfo.Logo2,
          url: settingInfo.Logo2
        },
      ]);
    }
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <LayoutTabPlatform activeKey="1" />
        <Form form={form} onFinish={onFinish}>
          <Row align="middle" gutter={[10, 16]}>
            <Col span={24} style={{ display: "flex", justifyContent: "end" }}>
              {
                isEditDone && <>
                  <Button className="mr-1" onClick={onCancel}>{i18n.t('cancel')}</Button>
                  <Button type="primary" onClick={form.submit}>{i18n.t('confirm')}</Button>
                </>
              }
              {
                !isEditDone && $p.includes('00102') &&
                  <Button type="primary" onClick={() => setIsEditDone(!isEditDone)}>{i18n.t('edit')}</Button>
              }
            </Col>
            {/* 資料欄位 */}
            <Col span={24}>
              <Descriptions size="middle" layout="horizontal" bordered
                labelStyle={{ minWidth: 150, maxWidth: 150 }} contentStyle={{ minWidth: 300, maxWidth: 300 }}>
                {/* 第一排 */}
                <Descriptions.Item label={<><span className="require">*</span>{i18n.t('platformName')}</>}>
                  <Form.Item name="SiteName" rules={[{ required: true }]}>
                    <Input placeholder={`${i18n.t('enterPlatformName')}`} disabled={!isEditDone} />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={<><span className="require">*</span>{i18n.t('currency')}</>}>
                  <Form.Item name="currency" className="w-full" rules={[{ required: true }]}>
                    <Select
                      disabled
                      options={[
                        ...Object.keys(CURRENCY).map((key, i) => (
                          { value: i, label: CURRENCY[key as keyof typeof CURRENCY] }
                        ))
                      ]}
                    />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={<><span className="require">*</span>{i18n.t('status')}</>}>
                  <Form.Item name="Status" className="w-full" rules={[{ required: true }]}>
                    <Select
                      disabled
                      options={enumToOptions(ENABLE)}
                    />
                  </Form.Item>
                </Descriptions.Item>

                {/* 第二排 */}
                <Descriptions.Item label={i18n.t('createTime')}>
                  <Form.Item name="CreateDate">
                    <Input disabled />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={<><span className="require">*</span>{i18n.t('platformType')}</>}>
                  <Form.Item name="Type" rules={[{ required: true }]}>
                    <Select
                      disabled
                      options={[
                        { value: 0, label: i18n.t('cashVersion') },
                        { value: 1, label: i18n.t('creditVersion') },
                      ]}
                    />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="">{ }</Descriptions.Item>

                {/* 第三排 */}
                <Descriptions.Item label={<><span className="require">*</span>Favicon</>}>
                  <Form.Item name="Photo1" rules={[{ required: !iconData[0] }]}>
                    <UploadImage
                      name="Photo1"
                      disabled={!isEditDone}
                      url={'/upload/images/public/sitedata'}
                      imageData={iconData}
                      setImageData={setIconData}
                      w={16}
                      h={16}
                      // crop
                      accept=".ico"
                      form={form}
                    />
                  </Form.Item>
                  <div className="color-03 size-12">{i18n.t('onlyIcoFiles')}<span className="require">{i18n.t('imageMobileVersionWidth16pxHeight16px')}</span>{i18n.t('allowedAndSizeNotExceed1MB')}</div>
                </Descriptions.Item>
                <Descriptions.Item label={<><span className="require">*</span>{i18n.t('tagLogo')}</>}>
                  <Form.Item name="Photo2" rules={[{ required: !imageData[0] }]}>
                    <UploadImage
                      name="Photo2"
                      disabled={!isEditDone}
                      url={'/upload/images/public/contents'}
                      imageData={imageData}
                      setImageData={setImageData}
                      w={342}
                      h={70}
                      // crop
                      // fillColor="#1E211D"
                      form={form}
                    />
                  </Form.Item>
                  <div className="color-03 size-12">{i18n.t('onlyJpgOrPngFiles')}<span className="require">{i18n.t('imageMobileVersionWidth342pxHeight70px')}</span>{i18n.t('allowedAndSizeNotExceed1MB')}</div>
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('systemWhitelist')}>
                  <Form.Item name="WhiteList">
                    <Input.TextArea
                      disabled={!isEditDone}
                      autoSize={{ minRows: 5, maxRows: 3 }}
                      placeholder={`${i18n.t('useSemicolonToSeparate')}`}
                    />
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;