import { Button, Col, Form, Image, Input, Row, Table, Tooltip } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { PopupChatHistoryDetail } from 'components/chat.component';
import { LayoutDateSelect, LayoutNav, LayoutPagination, LayoutTabChatLog } from 'components/layout.component';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TYPE } from 'enum/date';
import { ROLE } from 'enum/socket';
import i18n from 'i18n';
import React, { useState } from 'react';
import { ChatServiceHistoryList } from 'services/chat.service';

// 聊天室紀錄

interface SearchParams {
  Account?: string;
  MemberAccount?: string;
  AgentAccount?: string;
  NoCode?: string;
  StartDate?: string;
  EndDate?: string;
}

const PageMain: React.FC = () => {
  const [form] = useForm();
  const [isOpenDetail, setIsOpenDetail] = useState<boolean>(false);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [sessionTime, setSessionTime] = useState<string | null>(null);
  const [role, setRole] = useState<number | null>(null);
  const [account, setAccount] = useState<string | null>(null);
  const [date, setDate] = useState<string[]>();
  const [reset, setReset] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [params, setParams] = useState<SearchParams>({
    StartDate: date ? date[0] : dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    EndDate: date ? date[1] : dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  });
  const { data: ServiceHistory, isValidating, mutate } = ChatServiceHistoryList({
    ...params,
    PageIndex: page[0],
    PageSize: page[1]
  })

  const onFinish = (value: {
    backOfficeAccount: string;
    memberAccount: string;
    id: string;
    SAaccount: string;
  }) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        Account: value.backOfficeAccount,
        MemberAccount: value.memberAccount,
        AgentAccount: value.SAaccount,
        NoCode: value.id,
        StartDate: date[0],
        EndDate: date[1]
      })
    }
    mutate();
  };

  const onDetail = (key: string, time: string, role: number, account: string) => {
    setSessionId(key);
    setSessionTime(time);
    setRole(role);
    setAccount(account);
    setIsOpenDetail(true);
  }

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setPage([1, 10]);
    mutate();
  };

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Form form={form} initialValues={{}} onFinish={onFinish}>
          <LayoutTabChatLog activeKey='1' />
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  <Form.Item name="backOfficeAccount">
                    <Input className="w-12" placeholder={`${i18n.t('backOfficeAccount')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="memberAccount">
                    <Input className="w-12" placeholder={`${i18n.t('memberAccount')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="id" rules={[
                    { max: 8, message: `${i18n.t('invalidFormat')}` },
                  ]}>
                    <Input className="w-12" placeholder={`${i18n.t('id')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="SAaccount">
                    <Input className="w-12" placeholder={`${i18n.t('masterAgentAndAgentAccount')}`} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <LayoutDateSelect width={395} onChange={setDate} reset={reset}
                  dateFormatDefault={DATE_FORMAT.日期時間} hiddenDateType={[DATE_TYPE.本月, DATE_TYPE.上月]} />
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Table
                loading={isValidating}
                size="middle"
                scroll={{ x: 1500 }}
                dataSource={
                  ServiceHistory ?
                    ServiceHistory.Data.map((item: ChatServiceHistory, i: number) => (
                      {
                        key: item.Id,
                        sendMessageRole: item.CustomerRole,
                        account: item.CustomerAccount,
                        NickName: item.NickName,
                        NoCode: item.NoCode,
                        receiveMessageRole: item.ServicePermissionName,
                        backOfficeAccount: item.ServiceAccount,
                        LatestMessageType: item.LatestMessageType,
                        LatestMessage: item.LatestMessage,
                        inboundTime: item.ProcessTime,
                        ip: item.IP,
                        endTime: item.EndTIme,
                      }
                    )) : []
                }
                columns={[
                  {
                    title: i18n.t('sendMessageRole'),
                    dataIndex: 'sendMessageRole',
                    fixed: 'left',
                    width: 100,
                    render: (_, { sendMessageRole }) => ROLE[sendMessageRole]
                  },
                  {
                    title: i18n.t('account'),
                    fixed: 'left',
                    width: 300,
                    render: (_, record) => (
                      record.sendMessageRole === ROLE.訪客 ? record.key : record.account
                    )
                  },
                  {
                    title: <>{i18n.t('nickname')} / {i18n.t('id')}</>,
                    width: 200,
                    render: (_, record) => <>{record.NickName || '-'}／{record.NoCode || '-'}</>
                  },
                  {
                    title: i18n.t('receiveMessageRole'),
                    dataIndex: 'receiveMessageRole',
                    width: 150,
                  },
                  {
                    title: i18n.t('account'),
                    dataIndex: 'backOfficeAccount',
                    width: 150,
                  },
                  {
                    title: i18n.t('content'),
                    dataIndex: 'content',
                    render: (_, record) => (
                      record.LatestMessageType === 0
                        ? <Tooltip title={record.LatestMessage}>
                          <div style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden', textOverflow: 'ellipsis',
                          }}>
                            {record.LatestMessage}
                          </div>
                        </Tooltip>
                        : <Image src={record.LatestMessage} width={50} height={50} />
                    )
                  },
                  {
                    title: i18n.t('inboundTime'),
                    dataIndex: 'inboundTime',
                    width: 160,
                  },
                  {
                    title: i18n.t('IP'),
                    dataIndex: 'ip',
                    width: 120,
                  },
                  {
                    title: i18n.t('endTime'),
                    dataIndex: 'endTime',
                    width: 160,
                  },
                  {
                    title: i18n.t('operation'),
                    fixed: 'right',
                    width: 100,
                    render: (_, record) => (
                      <Button type="link"
                        onClick={() => onDetail(
                          record.key,
                          record.inboundTime,
                          record.sendMessageRole,
                          record.account
                        )}>
                        {i18n.t('detail')}
                      </Button>
                    )
                  },
                ]}
                pagination={false}
              />
              <LayoutPagination total={ServiceHistory ? ServiceHistory.TotalRecord : 0} setPage={setPage} page={page} />
            </Col>
          </Row>
        </Form>
      </Content>
      {/* Modal */}
      <PopupChatHistoryDetail isOpen={isOpenDetail} close={() => setIsOpenDetail(false)}
        role={role} account={account} ProcessTime={sessionTime} sessionId={sessionId} refresh={mutate} />
    </div>
  );
};

export default PageMain;