import { InfoCircleFilled } from '@ant-design/icons';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Button, Col, Form, Input, InputNumber, Row, Select, Space, Switch, Table, Tooltip, UploadFile, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { LayoutDateSelect, LayoutNav, UploadImage } from 'components/layout.component';
import { RESPONSE_CODE_BONUS } from 'constants/response';
import dayjs, { Dayjs } from 'dayjs';
import { DATE_FORMAT } from 'enum/date';
import { PROMOTION_STATE } from 'enum/promotion';
import { RESPONSE_TYPE } from 'enum/state';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EditRecommendBonus, RecommendBonus } from 'services/bonus.service';
import { enumToOptions, verify } from 'utils/common';

interface BonusRuleType {
  publicity_amount: number;
  validbet_multiple: number;
  valid_member_count: number;
  validbet_amount: number;
  deposit_amount: number;
  Status: number;
}

const PageMain: React.FC = () => {
  const [form] = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState<string[]>();
  const [editorContent, setEditorContent] = useState<string>();
  const [isPopupValue, setIsPopupValue] = useState(false);
  const [defaultDate, setDefaultDate] = useState<Dayjs[]>([]);
  const [bonusRule, setBonusRule] = useState([]);
  const [preValue, setPreValue] = useState<{ [key: string]: number }>({
    // 基準值
    publicityAmount0: 0,
    validMemberCount0: 0,
    validbetAmount0: 0,
    depositAmount0: 0
  });

  const { data: bonusInfo, mutate } = RecommendBonus();
  useEffect(() => {
    if (bonusInfo) {
      const BonusRuleSetting = JSON.parse(bonusInfo.BonusRuleSetting);
      setBonusRule(BonusRuleSetting);
      form.setFieldsValue({
        promotionName: bonusInfo.Name,
        distributionMethod: bonusInfo.SettleMethod || 0,

        publicityAmount1: BonusRuleSetting[0].PublicityAmount,
        publicityAmount2: BonusRuleSetting[1].PublicityAmount,
        publicityAmount3: BonusRuleSetting[2].PublicityAmount,
        publicityAmount4: BonusRuleSetting[3].PublicityAmount,
        publicityAmount5: BonusRuleSetting[4].PublicityAmount,
        publicityAmount6: BonusRuleSetting[5].PublicityAmount,
        publicityAmount7: BonusRuleSetting[6].PublicityAmount,
        publicityAmount8: BonusRuleSetting[7].PublicityAmount,

        validbetMultiple1: BonusRuleSetting[0].ValidBetMultiple,
        validbetMultiple2: BonusRuleSetting[1].ValidBetMultiple,
        validbetMultiple3: BonusRuleSetting[2].ValidBetMultiple,
        validbetMultiple4: BonusRuleSetting[3].ValidBetMultiple,
        validbetMultiple5: BonusRuleSetting[4].ValidBetMultiple,
        validbetMultiple6: BonusRuleSetting[5].ValidBetMultiple,
        validbetMultiple7: BonusRuleSetting[6].ValidBetMultiple,
        validbetMultiple8: BonusRuleSetting[7].ValidBetMultiple,

        validMemberCount1: BonusRuleSetting[0].ValidMemberCount,
        validMemberCount2: BonusRuleSetting[1].ValidMemberCount,
        validMemberCount3: BonusRuleSetting[2].ValidMemberCount,
        validMemberCount4: BonusRuleSetting[3].ValidMemberCount,
        validMemberCount5: BonusRuleSetting[4].ValidMemberCount,
        validMemberCount6: BonusRuleSetting[5].ValidMemberCount,
        validMemberCount7: BonusRuleSetting[6].ValidMemberCount,
        validMemberCount8: BonusRuleSetting[7].ValidMemberCount,

        validbetAmount1: BonusRuleSetting[0].ValidBetAmount,
        validbetAmount2: BonusRuleSetting[1].ValidBetAmount,
        validbetAmount3: BonusRuleSetting[2].ValidBetAmount,
        validbetAmount4: BonusRuleSetting[3].ValidBetAmount,
        validbetAmount5: BonusRuleSetting[4].ValidBetAmount,
        validbetAmount6: BonusRuleSetting[5].ValidBetAmount,
        validbetAmount7: BonusRuleSetting[6].ValidBetAmount,
        validbetAmount8: BonusRuleSetting[7].ValidBetAmount,

        depositAmount1: BonusRuleSetting[0].DepositAmount,
        depositAmount2: BonusRuleSetting[1].DepositAmount,
        depositAmount3: BonusRuleSetting[2].DepositAmount,
        depositAmount4: BonusRuleSetting[3].DepositAmount,
        depositAmount5: BonusRuleSetting[4].DepositAmount,
        depositAmount6: BonusRuleSetting[5].DepositAmount,
        depositAmount7: BonusRuleSetting[6].DepositAmount,
        depositAmount8: BonusRuleSetting[7].DepositAmount,

        status1: BonusRuleSetting[0].Status,
        status2: BonusRuleSetting[1].Status,
        status3: BonusRuleSetting[2].Status,
        status4: BonusRuleSetting[3].Status,
        status5: BonusRuleSetting[4].Status,
        status6: BonusRuleSetting[5].Status,
        status7: BonusRuleSetting[6].Status,
        status8: BonusRuleSetting[7].Status,

        popupDisplay: bonusInfo.IsPopUp,
        redirectToPromotionPage: bonusInfo.IsRedirect,
        status: bonusInfo.Status,
        sort: bonusInfo.Position,
      })
      // 紀錄前值使用
      setPreValue({
        ...preValue,

        publicityAmount1: BonusRuleSetting[0].PublicityAmount,
        publicityAmount2: BonusRuleSetting[1].PublicityAmount,
        publicityAmount3: BonusRuleSetting[2].PublicityAmount,
        publicityAmount4: BonusRuleSetting[3].PublicityAmount,
        publicityAmount5: BonusRuleSetting[4].PublicityAmount,
        publicityAmount6: BonusRuleSetting[5].PublicityAmount,
        publicityAmount7: BonusRuleSetting[6].PublicityAmount,
        publicityAmount8: BonusRuleSetting[7].PublicityAmount,

        validMemberCount1: BonusRuleSetting[0].ValidMemberCount,
        validMemberCount2: BonusRuleSetting[1].ValidMemberCount,
        validMemberCount3: BonusRuleSetting[2].ValidMemberCount,
        validMemberCount4: BonusRuleSetting[3].ValidMemberCount,
        validMemberCount5: BonusRuleSetting[4].ValidMemberCount,
        validMemberCount6: BonusRuleSetting[5].ValidMemberCount,
        validMemberCount7: BonusRuleSetting[6].ValidMemberCount,
        validMemberCount8: BonusRuleSetting[7].ValidMemberCount,

        validbetAmount1: BonusRuleSetting[0].ValidBetAmount,
        validbetAmount2: BonusRuleSetting[1].ValidBetAmount,
        validbetAmount3: BonusRuleSetting[2].ValidBetAmount,
        validbetAmount4: BonusRuleSetting[3].ValidBetAmount,
        validbetAmount5: BonusRuleSetting[4].ValidBetAmount,
        validbetAmount6: BonusRuleSetting[5].ValidBetAmount,
        validbetAmount7: BonusRuleSetting[6].ValidBetAmount,
        validbetAmount8: BonusRuleSetting[7].ValidBetAmount,

        depositAmount1: BonusRuleSetting[0].DepositAmount,
        depositAmount2: BonusRuleSetting[1].DepositAmount,
        depositAmount3: BonusRuleSetting[2].DepositAmount,
        depositAmount4: BonusRuleSetting[3].DepositAmount,
        depositAmount5: BonusRuleSetting[4].DepositAmount,
        depositAmount6: BonusRuleSetting[5].DepositAmount,
        depositAmount7: BonusRuleSetting[6].DepositAmount,
        depositAmount8: BonusRuleSetting[7].DepositAmount,
      })
      setImageData([
        {
          uid: bonusInfo.Photo,
          name: bonusInfo.Photo,
          url: bonusInfo.Photo,
        },
      ])
      setDate([bonusInfo.StartDate, bonusInfo.EndDate, null]);
      setDefaultDate([dayjs(bonusInfo.StartDate), dayjs(bonusInfo.EndDate)]);
      setEditorContent(bonusInfo.Content);
      setIsPopupValue(bonusInfo.IsPopUp !== 1);
    }
  }, [bonusInfo]);

  const onFinish = async (formData: any) => {
    const BonusRuleSetting = [
      {
        DepositAmount: Number(formData.depositAmount1),
        PublicityLevel: 1,
        ValidBetAmount: Number(formData.validbetAmount1),
        PublicityAmount: Number(formData.publicityAmount1),
        ValidBetMultiple: Number(formData.validbetMultiple1),
        ValidMemberCount: Number(formData.validMemberCount1),
        Status: formData.status1 ? 1 : 0
      },
      {
        DepositAmount: Number(formData.depositAmount2),
        PublicityLevel: 2,
        ValidBetAmount: Number(formData.validbetAmount2),
        PublicityAmount: Number(formData.publicityAmount2),
        ValidBetMultiple: Number(formData.validbetMultiple2),
        ValidMemberCount: Number(formData.validMemberCount2),
        Status: formData.status2 ? 1 : 0
      },
      {
        DepositAmount: Number(formData.depositAmount3),
        PublicityLevel: 3,
        ValidBetAmount: Number(formData.validbetAmount3),
        PublicityAmount: Number(formData.publicityAmount3),
        ValidBetMultiple: Number(formData.validbetMultiple3),
        ValidMemberCount: Number(formData.validMemberCount3),
        Status: formData.status3 ? 1 : 0
      },
      {
        DepositAmount: Number(formData.depositAmount4),
        PublicityLevel: 4,
        ValidBetAmount: Number(formData.validbetAmount4),
        PublicityAmount: Number(formData.publicityAmount4),
        ValidBetMultiple: Number(formData.validbetMultiple4),
        ValidMemberCount: Number(formData.validMemberCount4),
        Status: formData.status4 ? 1 : 0
      },
      {
        DepositAmount: Number(formData.depositAmount5),
        PublicityLevel: 5,
        ValidBetAmount: Number(formData.validbetAmount5),
        PublicityAmount: Number(formData.publicityAmount5),
        ValidBetMultiple: Number(formData.validbetMultiple5),
        ValidMemberCount: Number(formData.validMemberCount5),
        Status: formData.status5 ? 1 : 0
      },
      {
        DepositAmount: Number(formData.depositAmount6),
        PublicityLevel: 6,
        ValidBetAmount: Number(formData.validbetAmount6),
        PublicityAmount: Number(formData.publicityAmount6),
        ValidBetMultiple: Number(formData.validbetMultiple6),
        ValidMemberCount: Number(formData.validMemberCount6),
        Status: formData.status6 ? 1 : 0
      },
      {
        DepositAmount: Number(formData.depositAmount7),
        PublicityLevel: 7,
        ValidBetAmount: Number(formData.validbetAmount7),
        PublicityAmount: Number(formData.publicityAmount7),
        ValidBetMultiple: Number(formData.validbetMultiple7),
        ValidMemberCount: Number(formData.validMemberCount7),
        Status: formData.status7 ? 1 : 0
      },
      {
        DepositAmount: Number(formData.depositAmount8),
        PublicityLevel: 8,
        ValidBetAmount: Number(formData.validbetAmount8),
        PublicityAmount: Number(formData.publicityAmount8),
        ValidBetMultiple: Number(formData.validbetMultiple8),
        ValidMemberCount: Number(formData.validMemberCount8),
        Status: formData.status8 ? 1 : 0
      },
    ]
    setLoading(true);
    const response = await EditRecommendBonus({
      Name: formData.promotionName,
      IsPopUp: formData.popupDisplay,
      IsRedirect: formData.redirectToPromotionPage,
      Status: formData.status,
      Content: editorContent,
      Photo: imageData[0] ? imageData[0].url : '',
      StartDate: date ? date[0] : defaultDate[0],
      EndDate: date ? date[1] : defaultDate[1],
      Position: formData.sort,
      BonusRuleSetting
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('editSuccess'));
      mutate();
      navigate('/promotion');
    } else {
      message.error(RESPONSE_CODE_BONUS[response.Message as keyof typeof RESPONSE_CODE_BONUS]);
    }

    setLoading(false);
  };

  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2">
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Form.Item name="promotionName" label={i18n.t('promotionName')} rules={[{ required: true }]}>
                    <Input className="w-12" placeholder={`${i18n.t('enter')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Space size="middle">
                    <Button onClick={() => navigate('/promotion')}>{i18n.t('cancel')}</Button>
                    <Button htmlType="submit" type="primary" loading={loading}>
                      {i18n.t('confirm')}
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col>
                  <Form.Item className="w-12" name="distributionMethod" label={i18n.t('distributionMethod')}
                    rules={[{ required: true }]}>
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      disabled
                      defaultValue={0}
                      options={[
                        { value: 0, label: `${i18n.t('systemDistribution')}` },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Form.Item label={i18n.t('referralBonusRules')} required>
                    <Table
                      size="small"
                      dataSource={
                        bonusInfo ?
                          bonusRule.map((item: BonusRuleType, i) => (
                            {
                              key: i + 1,
                              publicityAmount: item.publicity_amount,
                              validbetMultiple: item.validbet_multiple,
                              validMemberCount: item.valid_member_count,
                              validbetAmount: item.validbet_amount,
                              depositAmount: item.deposit_amount,
                              Status: item.Status
                            }
                          )) : []
                      }
                      columns={[
                        {
                          dataIndex: 'publicityAmount',
                          title: '',
                          align: 'center',
                          render: (_, record) => <>{record.key}</>
                        },
                        {
                          dataIndex: 'publicityAmount',
                          title: i18n.t('recommendationBonus'),
                          render: (_, record) => (
                            <Form.Item name={`publicityAmount${record.key}`} rules={[
                              ...verify({ point: 4 }),
                              {
                                validator: async (r, value) => {
                                  const pre = `publicityAmount${record.key - 1}`;
                                  if (Number(value) > preValue[pre]) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(`${i18n.t('mustBeGreaterThan')}${preValue[pre]}`);
                                }
                              }
                            ]}>
                              <Input type="number" placeholder={`${i18n.t('enter')}`}
                                onChange={e => setPreValue({
                                  ...preValue,
                                  [`publicityAmount${record.key}`]: Number(e.target.value),
                                })} />
                            </Form.Item>
                          ),
                        },
                        {
                          dataIndex: 'publicityAmount',
                          title: i18n.t('rolloverRate'),
                          render: (_, record) => (
                            <Form.Item name={`validbetMultiple${record.key}`} rules={verify({ point: 0 })}>
                              <Input type="number" placeholder={`${i18n.t('enter')}`} />
                            </Form.Item>
                          ),
                        },
                        {
                          title: i18n.t('validMemberCount'),
                          render: (_, record) => (
                            <Form.Item name={`validMemberCount${record.key}`}
                              rules={[
                                ...verify({ point: 0 }),
                                {
                                  validator: async (r, value) => {
                                    const pre = `validMemberCount${record.key - 1}`;
                                    if (Number(value) > preValue[pre]) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(`${i18n.t('mustBeGreaterThan')}${preValue[pre]}`);
                                  }
                                }
                              ]}>
                              <Input type="number" name={`validMemberCount${record.key - 1}`} placeholder={`${i18n.t('enter')}`} addonBefore="≧" onChange={e => setPreValue({
                                ...preValue,
                                [`validMemberCount${record.key}`]: Number(e.target.value),
                              })} />
                            </Form.Item>
                          ),
                        },
                        {
                          title: (
                            <>
                              {i18n.t('minimumRolloverRequirementForValidMembers')}
                              <Tooltip placement="top" title={i18n.t('0MeansNoLimitation')}>
                                <InfoCircleFilled style={{ marginLeft: 3 }} />
                              </Tooltip>
                            </>
                          ),
                          render: (_, record) => (
                            <Form.Item name={`validbetAmount${record.key}`} rules={[
                              ...verify({ point: 0 }),
                              {
                                validator: async (r, value) => {
                                  const pre = `validbetAmount${record.key - 1}`;
                                  if (Number(value) >= preValue[pre]) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(`${i18n.t('mustBeGreaterThanOrEqualTo')}${preValue[pre]}`);
                                }
                              }
                            ]}>
                              <Input type="number" name={`validbetAmount${record.key - 1}`} placeholder={`${i18n.t('enter')}`} addonBefore="≧" onChange={e => setPreValue({
                                ...preValue,
                                [`validbetAmount${record.key}`]: Number(e.target.value),
                              })} />
                            </Form.Item>
                          ),
                        },
                        {
                          title: (
                            <>
                              {i18n.t('minimumDepositRequirementForValidMembers')}
                              <Tooltip placement="top" title={i18n.t('0MeansNoLimitation')}>
                                <InfoCircleFilled style={{ marginLeft: 3 }} />
                              </Tooltip>
                            </>
                          ),
                          render: (_, record) => (
                            <Form.Item name={`depositAmount${record.key}`} rules={[
                              ...verify({ point: 4 }),
                              {
                                validator: async (r, value) => {
                                  const pre = `depositAmount${record.key - 1}`;
                                  if (Number(value) >= preValue[pre]) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(`${i18n.t('mustBeGreaterThanOrEqualTo')}${preValue[pre]}`);
                                }
                              }
                            ]}>
                              <Input type="number" name={`depositAmount${record.key - 1}`} placeholder={`${i18n.t('enter')}`} addonBefore="≧" onChange={e => setPreValue({
                                ...preValue,
                                [`depositAmount${record.key}`]: Number(e.target.value),
                              })} />
                            </Form.Item>
                          ),
                        },
                        {
                          title: i18n.t('status'),
                          render: (_, record) => (
                            <Form.Item name={`status${record.key}`} valuePropName="checked">
                              <Switch onChange={e => {
                                if (e) {
                                  Array.from({ length: record.key }).map((_, i) => (
                                    form.setFieldValue(`status${i + 1}`, true)
                                  ))
                                } else {
                                  Array.from({ length: bonusRule.length - record.key + 1 }).map((_, i) => (
                                    form.setFieldValue(`status${i + record.key}`, false)
                                  ))
                                }
                              }} />
                            </Form.Item>
                          ),
                        },
                      ]}
                      pagination={false}
                      bordered />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col>
                  <Form.Item className="w-12" name="popupDisplay" label={i18n.t('popupDisplay')}
                    rules={[{ required: true }]}>
                    <Select
                      onChange={e => {
                        setIsPopupValue(e === 0);
                        form.setFieldValue('redirectToPromotionPage', 0);
                      }}
                      placeholder={i18n.t('pleaseSelect')}
                      options={[
                        { value: 1, label: `${i18n.t('yes')}` },
                        { value: 0, label: `${i18n.t('no')}` },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item className="w-12" name="redirectToPromotionPage" label={i18n.t('redirectToPromotionPage')}
                    rules={[{ required: true }]}>
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      disabled={isPopupValue}
                      options={[
                        { value: 1, label: `${i18n.t('yes')}` },
                        { value: 0, label: `${i18n.t('no')}` },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col>
                  <Form.Item className="w-12" name="status" label={i18n.t('status')}
                    rules={[{ required: true }]}>
                    <Select
                      className="w-12"
                      placeholder={i18n.t('pleaseSelect')}
                      options={enumToOptions(PROMOTION_STATE)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="sort" label={i18n.t('sort')} rules={verify({ point: 0 })}>
                    <InputNumber className="w-12" placeholder={`${i18n.t('enter')}`} />
                  </Form.Item>
                </Col>
              </Row>

            </Col>
            <Col span={24}>
              <Form.Item name="promotionTime" label={
                `${i18n.t('promotionStartTime')} - ${i18n.t('promotionEndTime')}`
              } rules={[{ required: true }]}>
                <LayoutDateSelect onChange={setDate} hidden="dateRadio" width={395}
                  dateFormatDefault={DATE_FORMAT.日期時間} defaultDate={defaultDate} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="Photo" valuePropName="fileList" label={i18n.t('image')} rules={[{ required: !imageData[0] }]}>
                <UploadImage
                  name="Photo"
                  url={'/upload/images/public/bonus'}
                  imageData={imageData}
                  setImageData={setImageData}
                  form={form}
                  crop
                  w={1920}
                  h={560}
                />
              </Form.Item>
              <div className="color-03 size-12">{i18n.t('onlyJpgOrPngFiles')}<span className="require">{i18n.t('imageMobileVersionWidth1920pxHeight560px')}</span>{i18n.t('allowedAndSizeNotExceed500kb')}</div>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Form.Item name="internalContent" label={i18n.t('internalContent')}
                    rules={[{ required: true }]}>
                    <CKEditor
                      data={editorContent}
                      editor={ClassicEditor}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                      }}
                      onBlur={(event, editor) => {
                        const data = editor.getData();
                        setEditorContent(data);
                      }}
                      onFocus={(event, editor) => {

                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Content >
    </div >
  );
};

export default PageMain;