import { Button, Col, Form, Input, Row, Select, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { ViewAccount, ViewAccountHistory } from 'components/account.component';
import { LayoutDateSelect, LayoutNav } from 'components/layout.component';
import dayjs from 'dayjs';
import { MEMBER_LOG } from 'enum/cashlog';
import { DATE_FORMAT, DATE_TYPE } from 'enum/date';
import i18n from 'i18n';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as common from 'utils/common';

// 帳戶紀錄

const PageMain: React.FC = () => {

  const { account: _account } = useParams();
  useEffect(() => {
    if (_account) {
      form.setFieldsValue({
        Account: _account,
      });
      setAccount(_account)
    }
  }, [_account]);

  // call 元件聖經
  const viewAccount = useRef<any>();
  const viewAccountHistory = useRef<any>();
  const [account, setAccount] = useState<string>();
  const [form] = Form.useForm();
  const [date, setDate] = useState<string[]>();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [reset, setReset] = useState<boolean>(false);
  const [params, setParams] = useState<any>({
    Account: _account || null,
    StartDate: dayjs().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
    EndDate: dayjs().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
  })
  const [withdrawParams, setWithdrawParams] = useState({
    ...params
  });
  const [balanceParams, setBalanceParams] = useState({
    ...params
  });

  const search = (formData: any) => {
    if (!formData.Account) {
      message.error(i18n.t('enterMemberAccount'));
    } else {
      viewAccount.current?.refresh();
      viewAccountHistory.current?.mutate();
    }
    setPage([1, page[1]]);
    setAccount(formData.Account);
    if (date) {
      setParams({
        ...params,
        Account: formData.Account,
        StartDate: date[0],
        EndDate: date[1],
      })
      setWithdrawParams({
        StartDate: date[0],
        EndDate: date[1],
      })
      setBalanceParams({
        StartDate: date[0],
        EndDate: date[1],
      })
    }
  };

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setPage([1, 10]);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="pr-2 pl-2 pb-2">
        {/* 搜尋 */}
        <Form form={form} onFinish={search}>
          <Row align="middle" gutter={[12, 12]}>
            <Col className="w-12">
              <Form.Item name="Account" rules={[{ required: true, message: `${i18n.t('enter')}` }]}>
                <Input placeholder={`${i18n.t('memberAccount')}`} />
              </Form.Item>
            </Col>
            <LayoutDateSelect width={400} onChange={setDate} dateTypeDefault={DATE_TYPE.本月}
              dateFormatDefault={DATE_FORMAT.日期時間} hiddenDateType={[DATE_TYPE.上週]} reset={reset}
            />
            <Col>
              <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
        </Form>
        <ViewAccount refs={viewAccount} account={account} withdrawParams={withdrawParams} balanceParams={balanceParams} />
        <div className="mt-1">
          <Select
            className="w-12"
            defaultValue={''}
            options={[
              { value: '', label: i18n.t('allTransactionTypes') },
              ...common.enumToOptions(MEMBER_LOG)
            ]}
            onChange={(v) => {
              setParams({ ...params, KeyId: v })
              setPage([1, page[1]]);
            }}
          />
        </div>
        <ViewAccountHistory refs={viewAccountHistory} params={params} page={page} setPage={setPage} />
      </Content>
    </div>
  );
};

export default PageMain;