import {
  axiosGetAllData,
  axiosGetAuth,
  axiosPostAuth,
  baseURL,
  useFetchDataCommon
} from "services";

export const GetTagList = () => useFetchDataCommon(axiosGetAllData, `${baseURL}/tag/list`);
export const GetTagOne = (params: any, allow?: boolean) => useFetchDataCommon(axiosGetAuth, `${baseURL}/tag/one`, params, allow);
export const CreateTag = (data: any) => axiosPostAuth(`${baseURL}/tag/create`, data);
export const UpdateTag = (data: any) => axiosPostAuth(`${baseURL}/tag/update`, data);
export const GetTagAgentList = (params: any, allow?: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/agent/tags/list`, params, allow);
export const UpdateAgentTag = (data: any) => axiosPostAuth(`${baseURL}/agent/update/tag`, data);