import {
  axiosGetAllData,
  axiosGetAuth,
  axiosPostAuth,
  baseURL,
  useFetchData,
  useFetchDataCommon
} from "services";

export const FetchWithdrawMemberSummary = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/withdrawticket/member/total`, params, !!params);
export const FetchWithdrawMemberDetail = (id: number) => useFetchDataCommon(axiosGetAuth, `${baseURL}/withdrawticket/one`, { Id: id }, !!id);
export const ResolveWithdrawMemberService = (data: any) => axiosPostAuth(`${baseURL}/withdrawticket/service/resolve`, data);
export const RejectWithdrawMemberService = (data: any) => axiosPostAuth(`${baseURL}/withdrawticket/service/reject`, data);
export const ResolveWithdrawMemberAuditor = (data: any) => axiosPostAuth(`${baseURL}/withdrawticket/auditor/resolve`, data);
export const RejectWithdrawMemberAuditor = (data: any) => axiosPostAuth(`${baseURL}/withdrawticket/auditor/reject`, data);
export const ResolveWithdrawMemberFinance = (data: any) => axiosPostAuth(`${baseURL}/withdrawticket/finance/resolve`, data);
export const RejectWithdrawMemberFinance = (data: any) => axiosPostAuth(`${baseURL}/withdrawticket/finance/reject`, data);

export const GetDepositticketMemberList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/depositticket/member/list`, params)
export const DepositDetail = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/depositticket/one`, params)
export const FetchDepositDetail = (id: number) => useFetchData(axiosGetAuth, `${baseURL}/depositticket/one`, { Id: id }, undefined, !!id);
export const ResolveDeposit = (data: any) => axiosPostAuth(`${baseURL}/depositticket/member/resolve`, data);
export const RejectDeposit = (data: any) => axiosPostAuth(`${baseURL}/depositticket/member/reject`, data);

export const AgentDepositticketList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/depositticket/agent/list`, params);
export const ResolveAgentDeposit = (data: any) => axiosPostAuth(`${baseURL}/depositticket/agent/resolve`, data);
export const RejectAgentDeposit = (data: any) => axiosPostAuth(`${baseURL}/depositticket/agent/reject`, data);
