import { Button, Col, Image, Modal, Row, Space, message } from "antd";
import { RESPONSE_CODE_SEO } from "constants/response";
import { RESPONSE_TYPE } from "enum/state";
import useIcon from "hooks/icon.hook";
import i18n from "i18n";
import { DeleteSEO } from "services/seo.service";

// 空的
export const PopupDeleteSeo = ({ isOpen, close, type, mutate }: any) => {
  const icon = useIcon();

  const onDelete = async () => {

      const response = await DeleteSEO({
        Type: type,
      })
      if (response.State === RESPONSE_TYPE.成功) {
        message.success(i18n.t('operationSuccess'));
        mutate();
        close();
      } else {
        message.error(RESPONSE_CODE_SEO[response.Message as keyof typeof RESPONSE_CODE_SEO]);
      }

  }

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={icon.icon12} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button className="mt-1" onClick={close}>{i18n.t('cancel')}</Button>
          <Button className="mt-1" type="primary" onClick={onDelete}>{i18n.t('confirm')}</Button>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmDelete')}？</div>
      </Space>
    </Modal>
  )
}