import {
  axiosGetAllData,
  axiosGetAuth,
  axiosPostAuth,
  baseURL,
  useFetchDataCommon
} from "services";

export const AgentWithdrawList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/withdrawticket/agent/list`, params);
export const AgentWithdrawInfo = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/withdrawticket/one`, params);
export const FinanceWithdrawInfo = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/withdrawticket/finance/one`, params);
export const MemberWithdrawList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/withdrawticket/member/list`, params);

export const ServicePass = (data: any) => axiosPostAuth(`${baseURL}/withdrawticket/service/resolve`, data);
export const ServiceReject = (data: any) => axiosPostAuth(`${baseURL}/withdrawticket/service/reject`, data);
export const AuditorPass = (data: any) => axiosPostAuth(`${baseURL}/withdrawticket/auditor/resolve`, data);
export const AuditorReject = (data: any) => axiosPostAuth(`${baseURL}/withdrawticket/auditor/reject`, data);
export const FinancePass = (data: any) => axiosPostAuth(`${baseURL}/withdrawticket/finance/resolve`, data);
export const FinanceReject = (data: any) => axiosPostAuth(`${baseURL}/withdrawticket/finance/reject`, data);
