import {
  axiosGetAllData,
  axiosGetAuth,
  axiosPostAuth,
  baseURL,
  useFetchDataCommon
} from "services";

export const GetDepositmerchantOfflineList = (params: any, allow: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/depositmerchant/offline/list`, params, allow);
export const GetWithdrawmerchantOfflineList = (params: any, allow: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/withdrawmerchant/offline/list`, params, allow);

export const DepositOfflineOne = (params: any, allow?: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/depositmerchant/offline/one`, params, allow);
export const WithdrawalOfflineOne = (params: any, allow?: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/withdrawmerchant/offline/one`, params, allow);
export const DepositOfflineThirdList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/depositmerchant/third/list`, params);
export const WithdrawalOfflineThirdList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/withdrawmerchant/third/list`, params);

export const AddDepositBank = (data: any) => axiosPostAuth(`${baseURL}/depositmerchant/offline/bank/create`, data);
export const AddWithdrawBank = (data: any) => axiosPostAuth(`${baseURL}/withdrawmerchant/offline/bank/create`, data);
export const AddDepositCrypto = (data: any) => axiosPostAuth(`${baseURL}/depositmerchant/offline/crypto/create`, data);
export const AddWithdrawCrypto = (data: any) => axiosPostAuth(`${baseURL}/withdrawmerchant/offline/crypto/create`, data);

export const EditDepositBank = (data: any) => axiosPostAuth(`${baseURL}/depositmerchant/offline/bank/update`, data);
export const EditWithdrawBank = (data: any) => axiosPostAuth(`${baseURL}/withdrawmerchant/offline/bank/update`, data);
export const EditDepositCrypto = (data: any) => axiosPostAuth(`${baseURL}/depositmerchant/offline/crypto/update`, data);
export const EditWithdrawCrypto = (data: any) => axiosPostAuth(`${baseURL}/withdrawmerchant/offline/crypto/update`, data);

export const DeleteDeposit = (data: any) => axiosPostAuth(`${baseURL}/depositmerchant/offline/delete`, data);
export const DeleteWithdraw = (data: any) => axiosPostAuth(`${baseURL}/withdrawmerchant/offline/delete`, data);

//三方
export const DepositMerchantName = () => useFetchDataCommon(axiosGetAuth, `${baseURL}/depositmerchant/name/list`);
export const WithdrawMerchantName = () => useFetchDataCommon(axiosGetAuth, `${baseURL}/withdrawmerchant/name/list`);

export const DepositMerchantList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/depositmerchant/list`, params);
export const WithdrawMerchantList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/withdrawmerchant/list`, params);

export const GetDepositmerchantOne = (params: any, allow: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/depositmerchant/one`, params, allow);
export const GetWithdrawmerchantOne = (params: any, allow: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/withdrawmerchant/one`, params, allow);

export const PostDepositmerchantUpdate = (data: any) => axiosPostAuth(`${baseURL}/depositmerchant/update`, data);
export const PostWithdrawmerchantUpdate = (data: any) => axiosPostAuth(`${baseURL}/withdrawmerchant/update`, data);

export const DepositMerchantThirdList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/depositmerchant/third/list`, params);
export const WithdrawalMerchantThirdList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/withdrawmerchant/third/list`, params);
