import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Row, Spin, Table, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Content } from 'antd/es/layout/layout';
import { Export, LayoutDateSelect, LayoutNav } from 'components/layout.component';
import { ExcelColumns } from 'constants/excel';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TYPE } from 'enum/date';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { FetchReportBusiness } from 'services/report.service';
import * as common from 'utils/common';
import { toFormatNumber as $f } from 'utils/common';

const chartOptions = {
  responsive: true,
  scales: {
    y: {
      ticks: {
        stepSize: 1
      }
    }
  },
  plugins: {
    legend: {
      position: 'bottom' as const,
    },
  },
}

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();
  
  const [form] = Form.useForm();
  const [date, setDate] = useState<string[]>();
  const [reset, setReset] = useState<boolean>(false);
  const [isOpenMore, setIsOpenMore] = useState(false);

  const [reportBusinessParam, SetReportBusinessParam] = useState<ReportBusinessParam>({
    StartDate: dayjs().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
    EndDate: dayjs().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
  });
  const { data: reportBusiness, mutate, isValidating } = FetchReportBusiness(reportBusinessParam);

  const [amountData, setAmountData] = useState<any>({ datasets: [] });
  const [newcomerData, setNewcomerData] = useState<any>({ datasets: [] });
  const [betData, setBetData] = useState<any>({ datasets: [] });
  const [profitData, setProfitData] = useState<any>({ datasets: [] });

  useEffect(() => {
    if (reportBusiness) {
      const res = reportBusiness ? reportBusiness.reverse() : [];
      const labels = res.map((item: any) => item.ReportDate);
      setAmountData({
        labels,
        datasets: [
          {
            label: i18n.t('totalDepositAmount'),
            data: res.map((item: any) => item.TotalDepositAmount),
            backgroundColor: '#AFC8EA',
          },
          {
            label: i18n.t('totalAmountWithdrawal'),
            data: res.map((item: any) => item.TotalWithdrawAmount),
            backgroundColor: '#CBEAD9',
          },
        ],
      });
      setNewcomerData({
        labels,
        datasets: [
          {
            label: i18n.t('newUsers'),
            data: res.map((item: any) => item.TotalNewMemberCount),
            backgroundColor: '#AFC8EA',
          },
          {
            label: i18n.t('activeMembers'),
            data: res.map((item: any) => item.TotalActivityMemberCount),
            backgroundColor: '#CBEAD9',
          },
          {
            label: i18n.t('bettingMembers'),
            data: res.map((item: any) => item.TotalActivityMemberCount),
            backgroundColor: '#E79F9C',
          },
        ],
      });
      setBetData({
        labels,
        datasets: [
          {
            label: i18n.t('validBet'),
            data: res.map((item: any) => item.TotalValidBetAmount),
            backgroundColor: '#AFC8EA',
          },
          {
            label: i18n.t('totalBetAmount'),
            data: res.map((item: any) => item.TotalBetAmount),
            backgroundColor: '#CBEAD9',
          },
        ],
      });
      setProfitData({
        labels,
        datasets: [
          {
            label: i18n.t('gameProfit'),
            data: res.map((item: any) => item.TotalWinLossAmount),
            borderColor: '#4874AD',
            backgroundColor: '#4874AD',
          },
        ],
      });
    };
  }, [reportBusiness]);

  const onClear = () => {
    setReset(!reset);
  }

  const handleMore = (e: CheckboxChangeEvent) => {
    setIsOpenMore(e.target.checked)
  }

  const seach = () => {
    SetReportBusinessParam({
      StartDate: date ? date[0] : dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      EndDate: date ? date[1] : dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    })
    mutate()
  };

  const sum = (prop: any) => {
    return reportBusiness?.reduce((a: any, b: any) => a + b[prop], 0);
  };

  return (
    <div id="container">
      <LayoutNav />
      <Content className="p-2">
        {/* 搜尋 */}
        <Form form={form} onFinish={seach}>
          {/* 搜尋列 */}
          <Row align="middle" gutter={20}>
            <LayoutDateSelect onChange={setDate} 
              dateTypeDefault={DATE_TYPE.本月} dateFormatDefault={DATE_FORMAT.日期時間} reset={reset} />
            <Col>
              <Button type="primary" htmlType="submit" className="mr-1">{i18n.t('search')}</Button>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
        </Form>
        {/* 圖表 */}
        <Spin spinning={isValidating}>
          <Row className="mt-1" gutter={[10, 10]}>
            <Col span={12}>
              <Col className="size-16">{i18n.t('financeStatistics')}</Col>
              <Bar options={chartOptions} data={amountData} />
            </Col>
            <Col span={12}>
              <Col className="size-16">{i18n.t('memberActivityStatistics')}</Col>
              <Bar options={chartOptions} data={newcomerData} />
            </Col>
          </Row>
          <Row className="mt-1" gutter={[10, 10]}>
            <Col span={12}>
              <Col className="size-16">{i18n.t('betStatistics')}</Col>
              <Bar options={chartOptions} data={betData} />
            </Col>
            <Col span={12}>
              <Col className="size-16">{i18n.t('profitStatistics')}</Col>
              <Col span={24}><Line options={chartOptions} data={profitData} /></Col>
            </Col>
          </Row>
        </Spin>
        {/* 列表 */}
        <Row className="mt-1" gutter={[10, 10]}>
          <Col span={24}>
            <Row justify="space-between">
              <Col>
                {$p.includes('60302') && <Export url={'/report/business/list'} param={{ ...reportBusinessParam }}
                  sheetName={i18n.t('operationReports')} columns={ExcelColumns.ReportBusiness} />}
              </Col>
              <Col>
                <Button onClick={() => setIsOpenMore(!isOpenMore)}>
                  <Checkbox style={{ marginRight: 5 }} defaultChecked={false} checked={isOpenMore} onChange={handleMore} />
                  {i18n.t('More')}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ marginBottom: '60px' }}>
            <Table
              loading={isValidating}
              size="middle"
              bordered
              dataSource={reportBusiness?.map((item: ReportBusiness) => ({ ...item, key: item.ReportDate }))}
              columns={[
                {
                  title: i18n.t('date'),
                  className: 'size-12',
                  align: 'center',
                  children: [
                    {
                      dataIndex: 'ReportDate',
                      title: i18n.t('total'),
                      className: 'size-12',
                      align: 'right',
                      render: (val) => <div className="text-center">{val}</div>
                    },
                  ]
                },
                {
                  title: i18n.t('newUsers'),
                  className: 'size-12',
                  children: [
                    {
                      dataIndex: 'TotalNewMemberCount',
                      title: $f(sum('TotalNewMemberCount'), 0),
                      align: 'right',
                      className: 'size-12',
                      render: (val) => $f(val, 0)
                    }
                  ]
                },
                {
                  title: i18n.t('activeMembers'),
                  className: 'size-12',
                  children: [
                    {
                      dataIndex: 'TotalActivityMemberCount',
                      title: $f(sum('TotalActivityMemberCount'), 0),
                      align: 'right',
                      className: 'size-12',
                      render: (val) => $f(val, 0)
                    }
                  ]
                },
                {
                  title: () => (
                    <>
                      <Tooltip placement="top" className="size-12" title={i18n.t('totalCountExcludingDuplicates')}>
                        <InfoCircleFilled />
                      </Tooltip>
                      <span style={{ marginLeft: 5 }}>{i18n.t('totalMemberCount')}</span>
                    </>
                  ),
                  className: 'size-12',
                  children: [
                    {
                      dataIndex: 'TotalDepositRecordCount',
                      title: $f(sum('TotalDepositRecordCount'), 0),
                      align: 'right',
                      className: 'size-12',
                      render: (val) => $f(val, 0)
                    }
                  ]
                },
                {
                  title: i18n.t('totalWithdrawalCount'),
                  className: 'size-12',
                  children: [
                    {
                      dataIndex: 'TotalWithdrawMemberCount',
                      title: $f(sum('TotalWithdrawMemberCount'), 0),
                      align: 'right',
                      className: 'size-12',
                      render: (val) => $f(val, 0)
                    }
                  ]
                },
                {
                  title: i18n.t('firstDepositMembers'),
                  className: 'size-12',
                  children: [
                    {
                      dataIndex: 'TotalFirstTimeDepositMemberCount',
                      title: $f(sum('TotalFirstTimeDepositMemberCount'), 0),
                      align: 'right',
                      className: 'size-12',
                      render: (val) => $f(val, 0)
                    }
                  ]
                },
                {
                  title: () => (
                    <>
                      <Tooltip placement="top" className="size-12"
                        title={i18n.t('todayThisWeekAndThisMonthTheNumberOfReturningUsersMayHaveNegativeValues')}>
                        <InfoCircleFilled />
                      </Tooltip>
                      <span style={{ marginLeft: 5 }}>{i18n.t('redepositMembers')}</span>
                    </>
                  ),
                  className: 'size-12',
                  children: [
                    {
                      dataIndex: 'TotalSecondTimeDepositMemberCount',
                      title: $f(sum('TotalSecondTimeDepositMemberCount'), 0),
                      align: 'right',
                      className: 'size-12',
                      render: (val) => $f(val, 0)
                    }
                  ]
                },
                {
                  title: i18n.t('firstDepositAmount'),
                  className: 'size-12',
                  children: [
                    {
                      dataIndex: 'TotalFirstTimeDepositAmount',
                      title: $f(sum('TotalFirstTimeDepositAmount')),
                      align: 'right',
                      className: 'size-12',
                      render: (val) => $f(val)
                    }
                  ],
                },
                {
                  title: i18n.t('totalDepositAmount'),
                  className: 'size-12',
                  children: [
                    {
                      dataIndex: 'TotalDepositAmount',
                      title: $f(sum('TotalDepositAmount')),
                      align: 'right',
                      className: 'size-12',
                      render: (val) => $f(val)
                    }
                  ]
                },
                {
                  title: i18n.t('totalAmountWithdrawal'),
                  className: 'size-12',
                  children: [
                    {
                      dataIndex: 'TotalWithdrawAmount',
                      title: $f(sum('TotalWithdrawAmount')),
                      align: 'right',
                      className: 'size-12',
                      render: (val) => $f(val)
                    }
                  ]
                },
                {
                  title: () => (
                    <>
                      <Tooltip placement="top" className="size-12"
                        title={i18n.t(`totalCountExcludingDuplicates`)}>
                        <InfoCircleFilled />
                      </Tooltip>
                      <span style={{ marginLeft: 5 }}>{i18n.t('bettingMembers')}</span>
                    </>
                  ),
                  className: 'size-12',
                  children: [
                    {
                      dataIndex: 'TotalActivityMemberCount',
                      title: $f(sum('TotalActivityMemberCount'), 0),
                      align: 'right',
                      className: 'size-12',
                      render: (val) => $f(val, 0)
                    }
                  ]
                },
                {
                  title: i18n.t('totalBets'),
                  className: 'size-12',
                  children: [
                    {
                      dataIndex: 'TotalBetCount',
                      title: $f(sum('TotalBetCount'), 0),
                      align: 'right',
                      className: 'size-12',
                      render: (val) => $f(val, 0)
                    }
                  ]
                },
                {
                  title: i18n.t('validBet'),
                  className: 'size-12',
                  children: [
                    {
                      dataIndex: 'TotalValidBetAmount',
                      title: $f(sum('TotalValidBetAmount')),
                      align: 'right',
                      className: 'size-12',
                      render: (val) => $f(val)
                    }
                  ]
                },
                {
                  title: i18n.t('payout'),
                  className: 'size-12',
                  children: [
                    {
                      dataIndex: 'TotalSettleAmount',
                      title: $f(sum('TotalSettleAmount')),
                      align: 'right',
                      className: 'size-12',
                      render: (val) => $f(val)
                    }
                  ]
                },
                {
                  title: i18n.t('gameProfit'),
                  className: 'size-12',
                  children: [
                    {
                      dataIndex: 'TotalWinLossAmount',
                      title: $f(sum('TotalWinLossAmount')),
                      align: 'right',
                      className: 'size-12',
                      render: (val) => <span className={common.numColor01(val)}>{$f(val)}</span>
                    }
                  ]
                },
                {
                  title: i18n.t('monthlyTotalWinLoss'),
                  className: 'size-12',
                  children: [
                    {
                      dataIndex: 'TotalMonthWinLossAmount',
                      title: '-',
                      align: 'right',
                      className: 'size-12',
                      render: (val) => <span className={common.numColor01(val)}>{$f(val)}</span>
                    }
                  ]
                },
                {
                  title: i18n.t('bonusAmount'),
                  className: `size-12 ${!isOpenMore && 'display-none'}`,
                  children: [
                    {
                      dataIndex: 'TotalBonusAmount',
                      title: $f(sum('TotalBonusAmount')),
                      align: 'right',
                      className: `size-12 ${!isOpenMore && 'display-none'}`,
                      render: (val) => $f(val)
                    }
                  ],
                },
                {
                  title: i18n.t('adjustmentAmount'),
                  className: `size-12 ${!isOpenMore && 'display-none'}`,
                  children: [
                    {
                      title: () => {
                        return (
                          <>
                            <div className={common.numColor03(sum('TotalAdjustPlusAmount'))}>
                              {$f(sum('TotalAdjustPlusAmount'))}
                            </div>
                            <div className={common.numColor03(sum('TotalAdjustMinusAmount'))}>
                              {$f(sum('TotalAdjustMinusAmount'))}
                            </div>
                          </>
                        )
                      },
                      align: 'right',
                      className: `size-12 ${!isOpenMore && 'display-none'}`,
                      render: (_, { TotalAdjustPlusAmount, TotalAdjustMinusAmount }) => (
                        <div>
                          <div className={common.numColor03(TotalAdjustPlusAmount)}>{$f(TotalAdjustPlusAmount)}</div>
                          <div className={common.numColor03(TotalAdjustMinusAmount)}>{$f(TotalAdjustMinusAmount)}</div>
                        </div>
                      ),
                    }
                  ]
                },
              ]}
              summary={() => (
                <Table.Summary>
                  <Table.Summary.Row style={{ background: '#FAFAFA' }}>
                    <Table.Summary.Cell index={0} align="right" className="size-12 font-w-md">
                      {i18n.t('total')}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="right" className="size-12 font-w-md">
                      {$f(sum('TotalNewMemberCount'), 0)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="right" className="size-12 font-w-md">
                      {$f(sum('TotalActivityMemberCount'), 0)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="right" className="size-12 font-w-md">
                      {$f(sum('TotalDepositRecordCount'), 0)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="right" className="size-12 font-w-md">
                      {$f(sum('TotalWithdrawMemberCount'), 0)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="right" className="size-12 font-w-md">
                      {$f(sum('TotalFirstTimeDepositMemberCount'), 0)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align="right" className="size-12 font-w-md">
                      {$f(sum('TotalSecondTimeDepositMemberCount'), 0)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align="right" className="size-12 font-w-md">
                      {$f(sum('TotalFirstTimeDepositAmount'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align="right" className="size-12 font-w-md">
                      {$f(sum('TotalDepositAmount'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9} align="right" className="size-12 font-w-md">
                      {$f(sum('TotalWithdrawAmount'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10} align="right" className="size-12 font-w-md">
                      {$f(sum('TotalActivityMemberCount'), 0)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11} align="right" className="size-12 font-w-md">
                      {$f(sum('TotalBetCount'), 0)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={12} align="right" className="size-12 font-w-md">
                      {$f(sum('TotalValidBetAmount'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={13} align="right" className="size-12 font-w-md">
                      {$f(sum('TotalSettleAmount'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={14} align="right" className="size-12 font-w-md">
                      {$f(sum('TotalWinLossAmount'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={15} align="right" className="size-12 font-w-md">
                      {'-'}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={16} align="right" className={`size-12 font-w-md ${!isOpenMore && 'display-none'}`}>
                      {$f(sum('TotalBonusAmount'))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={17} align="right" className={`font-w-md ${!isOpenMore && 'display-none'}`}>
                      <div className={`size-12 ${common.numColor03(sum('TotalAdjustPlusAmount'))}`}>{$f(sum('TotalAdjustPlusAmount'))}</div>
                      <div className={`size-12 ${common.numColor03(sum('TotalAdjustMinusAmount'))}`}>{$f(sum('TotalAdjustMinusAmount'))}</div>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )}
              pagination={false}
            />
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default PageMain;