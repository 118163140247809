import {
  axiosGetAuth,
  axiosPostAuth,
  baseURL,
  useFetchDataCommon
} from "services";

export const ProviderList = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/provider/list`, params);
export const ProviderOpenList = () => useFetchDataCommon(axiosGetAuth, `${baseURL}/provider/open/list`);
export const Provider = () => useFetchDataCommon(axiosGetAuth, `${baseURL}/provider/code/list`);
export const ProviderOne = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/provider/one`, params);
export const ProviderUpdate = (data: any) => axiosPostAuth(`${baseURL}/provider/update`, data);
export const ProviderUpdateStatus = (data: any) => axiosPostAuth(`${baseURL}/provider/status/update`, data);
