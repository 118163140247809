import { Col, Row, Switch, Table, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { LayoutNav } from 'components/layout.component';
import { RESPONSE_CODE_FRONT } from 'constants/response';
import { RESPONSE_TYPE } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React from 'react';
import { GetPopupList, UpdatePopup } from 'services/front.service';

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();
  const { data: PopupList, isValidating, mutate } = GetPopupList();

  const onEditPopup = async (id: number, state: boolean) => {
    const response = await UpdatePopup({
      Id: id,
      IsPopUp: state ? 1 : 0
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      mutate();
    } else {
      message.error(RESPONSE_CODE_FRONT[response.Message as keyof typeof RESPONSE_CODE_FRONT]);
    }
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Row>
          <Col span={24}>
            <Table
              loading={isValidating}
              size="middle"
              dataSource={
                PopupList ?
                  PopupList.map((item: FrontPopup) => (
                    {
                      key: item.Id,
                      theme: item.Item,
                      content: item.Description,
                      updater: item.LastModifyAccount,
                      updateTime: item.UpdateDate,
                      state: item.IsPopUp
                    }
                  )) : []
              }
              columns={[
                {
                  dataIndex: 'theme',
                  title: i18n.t('project'),
                },
                {
                  dataIndex: 'content',
                  title: i18n.t('explanation'),
                },
                {
                  dataIndex: 'updater',
                  title: i18n.t('updater'),
                  width: 200,
                },
                {
                  dataIndex: 'updateTime',
                  title: i18n.t('updateTime'),
                  width: 200,
                },
                {
                  dataIndex: 'state',
                  title: i18n.t('status'),
                  width: 120,
                  render: (_: any, record: any) => (
                    <Switch defaultChecked={record.state} onClick={e => onEditPopup(record.key, e)} />
                  )
                },
              ].filter((item: any) => !(item.dataIndex === 'state' && !$p.includes('50302')))}
              pagination={false}
            />
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default PageMain;