import {
  axiosGetAllData,
  axiosGetAuth,
  axiosPostAuth,
  baseURL,
  useFetchDataCommon
} from "services";

export const GetBonusticketList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/bonusticket/list`, params);
export const CreateBonusTicket = (params: any) => axiosPostAuth(`${baseURL}/bonusticket/create`, params);
export const AdjustBonusTicket = (params: any) => axiosPostAuth(`${baseURL}/bonusticket/audit`, params);
export const UnlockBonusTicket = (params: any) => axiosPostAuth(`${baseURL}/bonusticket/lock`, params);
export const BonusTicketOne = (params: any, allow: boolean) => useFetchDataCommon(axiosGetAuth, `${baseURL}/bonusticket/one`, params, allow);
