import {
  axiosGet,
  axiosGetAllData,
  axiosGetAuth,
  axiosPostAuth,
  baseURL,
  useFetchDataCommon
} from "services";

export const FetchAdminInfo = () => useFetchDataCommon(axiosGetAuth, `${baseURL}/account/self`);
export const GetAccountList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/account/list`, params);
export const PostAccountUpdate = (data: any) => axiosPostAuth(`${baseURL}/account/update`, data);
export const CreateAccount = (data: any) => axiosPostAuth(`${baseURL}/account/create`, data);
export const PostSelfPasswordUpdate = (data: any) => axiosPostAuth(`${baseURL}/account/self/password/update`, data);
export const PostPasswordReset = (data: any) => axiosPostAuth(`${baseURL}/account/password/reset`, data);

export const GetAccesslogList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/accesslog/list`, params);

export const GetLanguageList = () => useFetchDataCommon(axiosGetAuth, `${baseURL}/language/list`);
export const GetLanguage = () => useFetchDataCommon(axiosGet, `/common/language/list`);

export const SiteSettingInfo = () => useFetchDataCommon(axiosGetAuth, `/common/sitedata/info`);
export const UpdateSiteSettingInfo = (params: any) => axiosPostAuth(`${baseURL}/sitedata/update`, params);

export const GetChatroomServiceAccountSelf = (params: any, allow: boolean) => useFetchDataCommon(axiosGetAuth, `${baseURL}/chatroom/service/account/self`, params, allow);

export const GetPermissionOperationSelfList = () => useFetchDataCommon(axiosGetAuth, `${baseURL}/permission/operation/self/list`);
