import { Button, Col, Form, Input, Row, Select, Spin, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { Export, LayoutDateSelect, LayoutNav, LayoutPagination } from 'components/layout.component';
import { ExcelColumns } from 'constants/excel';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TYPE } from 'enum/date';
import { MEMBER_STATE } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetReportMemberRank } from 'services/report.service';
import * as common from 'utils/common';

// 會員排名

interface SearchParams {
  RankingType?: number;
  StartDate: string;
  EndDate: string;
  MemberAccount?: string;
  UpMemberAccount?: string;
  AGAccount?: string;
  SAAccount?: string;
  MemberStatus?: number;
}

enum REPORT_MEMBER_RANK {
  入款 = 1,
  出款 = 2,
  有效投注 = 3,
  會員贏 = 4,
  會員輸 = 5
}

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();
  const navigate = useNavigate();
  const [form] = useForm();
  const initialValues = {
    type: REPORT_MEMBER_RANK.會員贏
  }
  const [page, setPage] = useState<number[]>([1, 10]);
  const [reset, setReset] = useState(false);
  const [date, setDate] = useState<string[]>();
  const [params, setParams] = useState<SearchParams>({
    RankingType: REPORT_MEMBER_RANK.會員贏,
    StartDate: dayjs().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
    EndDate: dayjs().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
  });
  const { data: RankList, isValidating, mutate } = GetReportMemberRank({
    ...params,
    PageIndex: page[0],
    PageSize: page[1]
  })

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        ...params,
        MemberAccount: formData.memberAccount,
        UpMemberAccount: formData.recommendedMembers,
        AGAccount: formData.agentAccount,
        SAAccount: formData.masterAgent,
        MemberStatus: formData.MemberStatus,
        RankingType: formData.type,
        StartDate: date[0],
        EndDate: date[1]
      })
    }
    mutate();
  }

  const onClear = () => {
    setPage([1, 10]);
    setReset(!reset);
    form.resetFields();
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Form form={form} onFinish={onFinish} initialValues={initialValues}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  <Form.Item name="memberAccount">
                    <Input className="w-12" placeholder={`${i18n.t('memberAccount')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="recommendedMembers">
                    <Input className="w-12" placeholder={`${i18n.t('recommendedMembers')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="agentAccount">
                    <Input className="w-12" placeholder={`${i18n.t('agentAccount')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="masterAgent">
                    <Input className="w-12" placeholder={`${i18n.t('masterAgent')}`} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  <Form.Item name="MemberStatus" className="w-12">
                    <Select
                      className="w-full"
                      placeholder={`${i18n.t('audit')}`}
                      options={[
                        { value: '', label: i18n.t('all') },
                        ...common.enumToOptions(MEMBER_STATE)
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="type" className="w-12">
                    <Select
                      className="w-full"
                      options={common.enumToOptions(REPORT_MEMBER_RANK)}
                    />
                  </Form.Item>
                </Col>
                <LayoutDateSelect width={395} onChange={setDate} dateFormatDefault={DATE_FORMAT.日期時間}
                  dateTypeDefault={DATE_TYPE.本月} reset={reset} />
                <Col><Button type="primary" htmlType="submit">{i18n.t('search')}</Button></Col>
                <Col><Button onClick={onClear}>{i18n.t('clear')}</Button></Col>
              </Row>
            </Col>
            <Col span={24}>
              {$p.includes('60702') && <Export url={'/report/ranking/member/list'} param={{
                ...params, PageSize: RankList && RankList.TotalRecord, PageIndex: 1,
              }} sheetName={i18n.t('memberRanking')} columns={ExcelColumns.ReportMemberRank} />}
            </Col>
            <Col span={24}>
              <Spin spinning={isValidating}>
                <Table
                  size="middle"
                  scroll={{ x: 1500 }}
                  dataSource={
                    RankList?.Data?.map((item: ReportMemberRank, index: number) => (
                      {
                        key: index,
                        ladder: index + 1,
                        account: item.MemberAccount,
                        info: {
                          UpMemberAccount: item.UpMemberAccount,
                          AGAccount: item.AGAccount,
                          SAAccount: item.SAAccount,
                        },
                        name: item.MemberName,
                        wallet: item.DepositAmount,
                        withdraw: item.WithdrawAmount,
                        validBet: item.ValidBetAmount,
                        winAndLoss: item.WinLossAmount,
                        MemberId: item.MemberId,
                      }
                    )) 
                  }
                  columns={[
                    {
                      dataIndex: 'ladder',
                      title: i18n.t('ranking'),
                      align: 'center',
                      fixed: 'left',
                      width: 80,
                      // sorter: (a, b) => a.ladder - b.ladder,
                      render: (val) => ((page[0] - 1) * page[1]) + val
                    },
                    {
                      dataIndex: 'account',
                      title: i18n.t('memberAccount'),
                      fixed: 'left',
                      width: 200,
                    },
                    {
                      dataIndex: 'info',
                      title: i18n.t(`${i18n.t('recommendedMembers')}／${i18n.t('agent')}／${i18n.t('generalAgent')}`),
                      width: 300,
                      render: (val, { info }) => (
                        <>
                          <span>{info.UpMemberAccount ? info.UpMemberAccount : '-'}／</span>
                          <span>{info.AGAccount ? info.AGAccount : '-'}／</span>
                          <span>{info.SAAccount ? info.SAAccount : '-'}</span>
                        </>
                      )
                    },
                    {
                      dataIndex: 'name',
                      title: i18n.t('name'),
                      width: 150,
                    },
                    {
                      dataIndex: 'wallet',
                      title: i18n.t('recharge'),
                      align: 'right',
                      width: 200,
                      render: (val) => <div>{common.toFormatNumber(val)}</div>
                    },
                    {
                      dataIndex: 'withdraw',
                      title: i18n.t('withdraw'),
                      align: 'right',
                      width: 200,
                      render: (val) => <div>{common.toFormatNumber(val)}</div>
                    },
                    {
                      dataIndex: 'validBet',
                      title: i18n.t('validBet'),
                      align: 'right',
                      width: 200,
                      render: (val) => <div>{common.toFormatNumber(val)}</div>
                    },
                    {
                      dataIndex: 'winAndLoss',
                      title: i18n.t('memberWinLoss'),
                      align: 'right',
                      width: 200,
                      render: (val) => <div className={common.numColor03(val)}>{common.toFormatNumber(val)}</div>
                    },
                    {
                      title: i18n.t('operation'),
                      width: 200,
                      fixed: 'right',
                      render: (_, record) => (
                        <>
                          <Button className="size-12" type="link"
                            onClick={() => navigate(`/member/info/${record.MemberId}`)}>{i18n.t('detail')}</Button>
                          <Button className="size-12" type="link"
                            onClick={() => navigate(`/member/account/${record.account}`)}>{i18n.t('accountRecord')}</Button>
                        </>
                      )
                    }
                  ]}
                  pagination={false}
                />
                <LayoutPagination total={RankList ? RankList.TotalRecord : 0} setPage={setPage} page={page} />
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content>
    </div >
  );
};

export default PageMain;
