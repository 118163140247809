import { InfoCircleFilled } from '@ant-design/icons';
import { Col, Divider, Row, Table, Tooltip } from 'antd';
import { MEMBER_LOG } from 'enum/cashlog';
import i18n from 'i18n';
import { useEffect, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FetchMemberWithdrawInfo, GetMemberBalanceInfo, GetMemberCashlogList } from 'services/member.service';
import * as common from 'utils/common';
import { toFormatNumber as $f } from 'utils/common';
import { LayoutPagination } from './layout.component';

export const ViewAccount = ({ account, refs, withdrawParams, balanceParams }: any) => {
  useImperativeHandle(refs, () => ({

    refresh: () => {
      mutate();
      mutateBalance();
    }

  }));
  const [systemAccountData, setSystemAccountData] = useState<AccountInfo>({
    WithdrawTotal: [],
    MainTotal: [],
    BetTotal: []
  });

  const { data: memberWithdrawInfo, mutate, isValidating }: any = FetchMemberWithdrawInfo({
    Account: account,
    ...withdrawParams,
  }, !!account);

  const { data: memberBalanceInfo, mutate: mutateBalance, isValidating: isValidatingBalance }: any = GetMemberBalanceInfo({
    Account: account,
    ...balanceParams,
  }, !!account);

  useEffect(() => {
    if (memberWithdrawInfo && memberBalanceInfo) {
      setSystemAccountData({
        WithdrawTotal: memberWithdrawInfo.data ? [
          {
            key: 1,
            withDrawMax: memberWithdrawInfo?.data.MemberMawWithdrawPerDay,
            withDrawPaddingTotal: memberWithdrawInfo?.data.MemberStillWithdrawAmount,
            withDrawProcessingTotal: memberWithdrawInfo?.data.MemberAuditeWithdrawAmount,
            withDrawTotal: memberWithdrawInfo?.data.MemberCompleteWithdrawAmount,
          }
        ] : [],
        MainTotal: memberBalanceInfo.data ? [
          {
            key: 2,
            balance: memberBalanceInfo?.data.Balance,
            shouldValidBetAmount: memberBalanceInfo?.data.ShouldValidBetAmount,

            totalDepositAmount: memberBalanceInfo?.data.TotalDepositAmount,
            totalWithdrawAmount: memberBalanceInfo?.data.TotalWithdrawAmount,
            totalBonusAmount: memberBalanceInfo?.data.TotalBonusAmount,
            increaseTotal: {
              up: memberBalanceInfo?.data.TotalAdjustPlusAmount,
              down: memberBalanceInfo?.data.TotalAdjustMinusAmount,
            }
          }
        ] : [],
        BetTotal: memberBalanceInfo.data ? [
          {
            key: 3,
            winLossAmount: memberBalanceInfo?.data.WinLossAmount,
            validBetAmount: memberBalanceInfo?.data.ValidBetAmount,
            totalCommissionAmount: memberBalanceInfo?.data.TotalCommissionAmount,
          }
        ] : [],
      })
    }
  }, [memberWithdrawInfo, memberBalanceInfo])

  return (
    <Row className="mt-1">
      <Col span={24}>
        <Table
          loading={isValidating}
          size="middle"
          dataSource={systemAccountData.WithdrawTotal || []}
          columns={[
            {
              key: 1,
              dataIndex: 'withDrawMax',
              title: i18n.t('withdrawalLimit'),
              className: 'size-12',
              align: "right",
              width: '20%',
              render: (val) => (
                <div className={common.numColor01(val)}>{$f(val)}</div>
              )
            },
            {
              key: 2,
              dataIndex: 'withDrawPaddingTotal',
              title: i18n.t('todayRemainingWithdrawal'),
              className: 'size-12',
              align: "right",
              width: '20%',
              render: (val) => (
                <div className={common.numColor01(val)}>{$f(val)}</div>
              )
            },
            {
              key: 3,
              dataIndex: 'withDrawProcessingTotal',
              title: i18n.t('withdrawalInProgress'),
              className: 'size-12',
              align: "right",
              width: '20%',
              render: (val) => (
                <div className={common.numColor01(val)}>{$f(val)}</div>
              )
            },
            {
              key: 4,
              dataIndex: 'withDrawTotal',
              title: i18n.t('todayWithdrawn'),
              className: 'size-12',
              align: "right",
              width: '20%',
              render: (val) => (
                <div className={common.numColor01(val)}>{$f(val)}</div>
              )
            }
          ]}
          pagination={false}
        />
      </Col>
      <Col span={24}><Divider /></Col>
      {/* 總餘額 */}
      <Col span={24}>
        <Table
          loading={isValidatingBalance}
          size="middle"
          dataSource={systemAccountData.MainTotal || []}
          columns={[
            {
              key: 1,
              title: i18n.t('balance'),
              dataIndex: 'balance',
              className: 'size-12',
              align: "right",
              width: '20%',
              render: (_, { balance }) => (
                <div className={common.numColor01(balance)}>{$f(balance)}</div>
              )
            },
            {
              key: 2,
              title: i18n.t('expectedValidBet'),
              dataIndex: 'shouldValidBetAmount',
              className: 'size-12',
              align: "right",
              width: '20%',
              render: (_, { shouldValidBetAmount }) => (
                <div className={common.numColor01(shouldValidBetAmount)}>{$f(shouldValidBetAmount)}</div>
              )
            },
          ]}
          pagination={false}
        />
      </Col>
      <Col span={24}><Divider /></Col>
      <Col span={24}>
        <Table
          loading={isValidatingBalance}
          size="middle"
          dataSource={systemAccountData.MainTotal || []}
          columns={[
            {
              key: 3,
              title: i18n.t('totalDeposit'),
              dataIndex: 'totalDepositAmount',
              className: 'size-12',
              align: "right",
              width: '20%',
              render: (_, { totalDepositAmount }) => (
                <div className={common.numColor01(totalDepositAmount)}>{$f(totalDepositAmount)}</div>
              )
            },
            {
              key: 4,
              title: i18n.t('totalWithdrawAmount'),
              dataIndex: 'totalWithdrawAmount',
              className: 'size-12',
              align: "right",
              width: '20%',
              render: (_, { totalWithdrawAmount }) => (
                <div className={common.numColor01(totalWithdrawAmount)}>{$f(totalWithdrawAmount)}</div>
              )
            },
            {
              key: 5,
              title:
                <>
                  {i18n.t('totalPromotion')}
                  <Tooltip placement="top" className="size-12" title={i18n.t('IncludeAllPromotionalAdjustments')}>
                    <InfoCircleFilled />
                  </Tooltip>
                </>,
              dataIndex: 'totalBonusAmount',
              className: 'size-12',
              align: "right",
              width: '20%',
              render: (_, { totalBonusAmount }) => (
                <div className={common.numColor01(totalBonusAmount)}>{$f(totalBonusAmount)}</div>
              )
            },
            {
              key: 6,
              title: `${i18n.t('totalAdjustment')}(+／-)`,
              dataIndex: 'increaseTotal',
              className: 'size-12',
              align: "right",
              width: '20%',
              render: (_, { increaseTotal }) => (
                <>
                  <span className={common.numColor01(increaseTotal.up)}>{$f(increaseTotal.up)}</span>／
                  <span className={common.numColor01(increaseTotal.down)}>{$f(increaseTotal.down)}</span>
                </>
              )
            },
          ]}
          pagination={false}
        />
      </Col>
      <Col span={24}>
        <Table
          size="middle"
          dataSource={systemAccountData.BetTotal || []}
          columns={[
            {
              key: 7,
              title: i18n.t('totalWinLoss'),
              dataIndex: 'winLossAmount',
              className: 'size-12',
              align: "right",
              width: '20%',
              render: (_, { winLossAmount }) => (
                <div className={common.numColor01(winLossAmount)}>{$f(winLossAmount)}</div>
              )
            },
            {
              key: 8,
              title: i18n.t('totalValidBets'),
              dataIndex: 'validBetAmount',
              className: 'size-12',
              align: "right",
              width: '20%',
              render: (_, { validBetAmount }) => (
                <div className={common.numColor01(validBetAmount)}>{$f(validBetAmount)}</div>
              )
            },
            {
              key: 9,
              title:
                <>
                  {i18n.t('totalRebate')}
                  {/* <Tooltip placement="top" className="size-12" title={i18n.t('IncludeCashbackAdjustments')}>
                    <InfoCircleFilled />
                  </Tooltip> */}
                </>,
              dataIndex: 'totalCommissionAmount',
              className: 'size-12',
              align: "right",
              width: '20%',
              render: (_, { totalCommissionAmount }) => (
                <div className={common.numColor01(totalCommissionAmount)}>{$f(totalCommissionAmount)}</div>
              )
            },
            {
              key: 10,
              className: 'size-12',
              align: "right",
              width: '20%',
            },
          ]}
          pagination={false}
        />
      </Col>
    </Row>
  )
}

export const ViewAccountHistory = ({ params, page, setPage, refs }: any) => {

  useImperativeHandle(refs, () => ({

    mutate: () => {
      mutate()
    }

  }));

  const navigate = useNavigate();
  const { data: memberCashlogInfoList, mutate, isValidating } = GetMemberCashlogList({
    ...params,
    PageIndex: page[0],
    PageSize: page[1],
  }, !!params.Account);

  return (
    <>
      <Table
        className="mt-1"
        loading={isValidating}
        size="middle"
        dataSource={
          memberCashlogInfoList && memberCashlogInfoList.Data
            ? memberCashlogInfoList?.Data.map((item: any) => ({ ...item, key: item.Id }))
            : []
        }
        columns={[
          {
            key: 1,
            dataIndex: 'Code',
            title: i18n.t('transactionCode'),
            className: 'size-12',
            align: 'left',
            width: 300,
            // render: (val, { KeyId }) => (
            //   (
            //     KeyId === String(MEMBER_LOG.下注) || KeyId === String(MEMBER_LOG.派彩)
            //     ? <span className='span-link' onClick={() => navigate(`/report/${params.Account}`)}>{val}</span>
            //     : <span>{val}</span>
            //   )
            // )
          },
          {
            key: 2,
            dataIndex: 'CreateDate',
            title: i18n.t('time'),
            className: 'size-12',
            width: 150,
          },
          {
            key: 3,
            dataIndex: 'KeyId',
            title: i18n.t('transactionType'),
            className: 'size-12',
            width: 150,
            render: (val) => MEMBER_LOG[val] || val
          },
          {
            key: 4,
            dataIndex: 'BeforeAmount',
            title: i18n.t('previousBalance'),
            className: 'size-12',
            align: 'right',
            render: (val) => <div className={common.numColor01(val)}>{$f(val)}</div>
          },
          {
            key: 5,
            dataIndex: 'Amount',
            title: i18n.t('previousAmount'),
            className: 'size-12',
            align: 'right',
            render: (val) => <div className={common.numColor01(val)}>{$f(val)}</div>
          },
          {
            dataIndex: 'AfterAmount',
            title: i18n.t('BalanceAfterModification'),
            key: 6,
            className: 'size-12',
            align: 'right',
            render: (val) => <div className={common.numColor01(val)}>{$f(val)}</div>
          }
        ]}
        pagination={false}
      />
      <LayoutPagination total={memberCashlogInfoList ? memberCashlogInfoList?.TotalRecord : 0}
        page={page} setPage={setPage} />
    </>
  )
}