import { DatabaseFilled } from '@ant-design/icons';
import { Button, Checkbox, Col, Divider, Form, Image, Input, Modal, Popover, Row, Select, Space, Table, Tooltip, message } from 'antd';
import { CopyButton, EditableCell, Export } from 'components/layout.component';
import { ExcelColumns } from 'constants/excel';
import { REGEX_ACCOUNT, REGEX_PASSWORD } from 'constants/regex';
import { RESPONSE_CODE_MEMBER } from 'constants/response';
import { MEMBER_FREEZE, MEMBER_STATE, RESPONSE_TYPE } from 'enum/state';
import useIcon from 'hooks/icon.hook';
import usePerm from 'hooks/permission.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetAgentAllList } from 'services/agent.service';
import { GameCategoryList } from 'services/category.service';
import { CreateMember, FetchMemberLoginHistory, PostMemberUpdateStatus } from 'services/member.service';
import { GetMemberlevelList } from 'services/memberlevel.service';
import { DeleteMemo, FetchMemberMemoList, ModifyMemo, PostMemoMemberCreate } from 'services/memo.service';
import { enumToOptions } from 'utils/common';

// 會員列表
export const ViewMemberList = ({ data, mutate, searchParam, isValidating }: {
  data: {
    State: string;
    Data: Member[];
    TotalRecord: number;
  }
  isValidating: any;
  mutate: () => void;
  searchParam: MemberSearchParam;
}) => {
  const { permissionCode: $p } = usePerm();
  const { isCashVersion: $sc } = useSite();

  const navigate = useNavigate();
  const icon = useIcon();
  const [status, setStatus] = useState<number>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenAddMemberModal, setIsOpenAddMemberModal] = useState(false);
  const [isEditCommentModal, setIsEditCommentModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentId, setCurrentId] = useState(0);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => setSelectedRowKeys(newSelectedRowKeys);

  // 修改審核
  const handleOkStatus = async () => {
    handleCancelStatus();
    if (selectedRowKeys.length === 0) return;

    const apiResult: ApiResult = await PostMemberUpdateStatus({
      Ids: selectedRowKeys,
      Status: status,
    });
    if (apiResult.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('updateSuccess'));
      mutate();
    } else {
      message.error(RESPONSE_CODE_MEMBER[apiResult.Message as keyof typeof RESPONSE_CODE_MEMBER]);
    }
  };

  const handleCancelStatus = () => {
    setStatus(undefined);
    setSelectedRowKeys([]);
    setIsModalOpen(false);
  };

  const [columns, setColumns] = useState<any>([]);

  useEffect(() => {
    if ($p) {
      setColumns([
        {
          key: 1,
          dataIndex: 'Account',
          title: i18n.t('memberAccount'),
          className: 'size-12',
          width: 120,
          fixed: 'left',
          show: true,
          render: (val: any, record: any) => (
            <Row justify="start" align="middle">
              <Popover
                title={`${i18n.t('memberInformation')} (${val})`}
                placement="bottomLeft"
                content={() => (
                  <Space style={{ minWidth: 200 }} direction="vertical">
                    <div className='text-start size-12'>
                      <div>{i18n.t('realName')}：{record.Name || '-'}</div>
                      <div>{i18n.t('nickname')}：{record.Nickname || '-'}</div>
                      <div>{i18n.t('id')}：{record.NoCode}</div>
                      <div>{i18n.t('birthday')}：{record.Birth !== '0000-00-00' ? 'Birth' : '-'}</div>
                      <div>{i18n.t('idNum')}：{record.IDNumber || '-'}</div>
                      <div>{i18n.t('phoneNumber')}：{record.PhoneNumber ? `${record.PhonePrefix}-${record.PhoneNumber}` : '-'}</div>
                      <div>{i18n.t('email')}：{record.Email || '-'}</div>
                      {
                        $p.includes('10202') &&
                        <div className='size-12 text-end'>
                          <Button size="small" type="link" onClick={() => navigate(`/member/info/${record.Id}`)}>
                            {i18n.t('detail')}
                          </Button>
                        </div>
                      }
                    </div>
                  </Space>
                )}
              >
                <Button size="small" className="size-12" type="link">{val}</Button>
              </Popover>
              <CopyButton text={val} />
            </Row>
          )
        },
        {
          key: 2,
          dataIndex: 'RefCode',
          title: i18n.t('referralCode'),
          className: 'size-12',
          width: 100,
          show: true,
        },
        {
          key: 3,
          dataIndex: 'UPMemberAccount',
          title: i18n.t('recommendedMembers'),
          className: 'size-12',
          width: 120,
          show: true,
          render: (val: any) => val || '-'
        },
        {
          key: 4,
          dataIndex: 'AGAccount',
          title: i18n.t('agent'),
          className: 'size-12',
          width: 120,
          show: true,
        },
        {
          key: 5,
          dataIndex: 'IsFreeze',
          title: `${i18n.t('online')}／${i18n.t('status')}`,
          className: 'size-12',
          width: 100,
          show: true,
          render: (val: any, { IsOnline }: any) => (
            <Row align="middle">
              {icon.StatusTag(IsOnline)}
              {icon.MemberStateTag(val ? MEMBER_FREEZE.frozen : MEMBER_FREEZE.normal)}
            </Row>
          )
        },
        {
          key: 52,
          dataIndex: 'Status',
          title: i18n.t('audit'),
          className: 'size-12',
          width: 80,
          show: true,
          render: (val: any) => icon.MemberStateTag(val)
        },
        {
          key: 6,
          dataIndex: 'MemberLevelName',
          title: i18n.t('memberLevel'),
          className: 'size-12',
          width: 100,
          show: true,
        },
        {
          key: 7,
          dataIndex: 'CreateDate',
          title: i18n.t('creationTimeIP'),
          className: 'size-12',
          width: 120,
          show: true,
          render: (val: any, { CreateDate, RegisterIP }: any) => (
            <Space direction="vertical">
              <div>{CreateDate || '-'}</div>
              <div>{RegisterIP || '-'}</div>
            </Space>
          )
        },
        {
          key: 8,
          dataIndex: 'LastLoginDate',
          title: i18n.t('lastLoginTimeIP'),
          className: 'size-12',
          width: 120,
          show: true,
          render: (val: any, { LastLoginDate, IP }: any) => (
            <Space direction="vertical">
              <div>{LastLoginDate || '-'}</div>
              <div>{IP || '-'}</div>
            </Space>
          )
        },
        {
          key: 9,
          dataIndex: 'LastDepositTime',
          title: $sc ? i18n.t('lastDepositTime') : `${i18n.t('adjustmentAccounting')}／${i18n.t('adjustmentDeduction')}`,
          className: 'size-12',
          width: 120,
          show: true,
          render: (val: any, { LastDepositGatewayName }: any) => (
            <Space direction="vertical">
              <div>{val === '0000-00-00 00:00:00' ? '-' : val}</div>
              <div>{LastDepositGatewayName || '-'}</div>
            </Space>
          )
        },
        {
          key: 10,
          dataIndex: 'RemarkFromAccount',
          title: i18n.t('remark'),
          className: 'size-12',
          width: 150,
          show: true,
          // FIXME: memo抽取
          render: (comment: any, { Id }: any) => (
            <Space>
              <Space direction="vertical">
                {
                  comment &&
                    comment.length > 20
                    ? <Tooltip placement="top" title={comment}>
                      <span>
                        {
                          comment.split('').map((text: string, i: number) => {
                            let outOf20 = '';
                            if (i <= 20) {
                              outOf20 += text
                            }
                            return outOf20;
                          })
                        }
                        ...
                      </span>
                    </Tooltip>
                    : <span>{comment || '-'}</span>
                }
                {
                  $p.includes('10103') &&
                  <Button
                    className="center"
                    size="small"
                    type="link"
                    onClick={() => {
                      setIsEditCommentModal(true);
                      setCurrentId(Id);
                    }}
                  >
                    <Image className="center" src={icon.icon11} preview={false} />
                  </Button>
                }
              </Space>
            </Space>
          )
        },
        {
          key: 11,
          title: i18n.t('operation'),
          className: 'size-12',
          width: 150,
          fixed: 'right',
          show: true,
          render: (val: any, { Id, Account }: any) => (
            <>
              {
                ($p.includes('10202') || $p.includes('10301') || $p.includes('10401') ||
                  $p.includes('10501') || $p.includes('10601')) &&
                <Button className="size-12" type="link" onClick={() => {
                  if ($p.includes('10202')) {
                    return navigate(`/member/info/${Id}`)
                  } else if ($p.includes('10301')) {
                    return navigate(`/member/provider/${Id}`)
                  } else if ($p.includes('10401')) {
                    return navigate(`/member/contact/${Id}`)
                  } else if ($p.includes('10501')) {
                    return navigate(`/member/bank/${Id}`)
                  } else if ($p.includes('10601')) {
                    return navigate(`/member/rebate/${Id}`)
                  }
                }}>
                  {
                    ($p.includes('10202') || $p.includes('10301') || $p.includes('10401') ||
                      $p.includes('10501') || $p.includes('10601')) ? i18n.t('edit') : i18n.t('detail')
                  }
                </Button>
              }
              {
                $p.includes('10701') &&
                <Button className="size-12" type="link" onClick={() => navigate(`/member/account/${Account}`)}>
                  {i18n.t('accountRecords')}
                </Button>
              }
            </>
          )
        },
      ])
    }
  }, [$p]);

  // 聖經欄位顯示設定
  const updateColumnShow = (key: string, checked: boolean) => {
    columns.find((item: any) => {
      if (item.dataIndex === key) {
        item.show = checked
        return true
      }
    })
    setColumns([...columns]);
  };

  return (
    <>
      <Col span={24}>
        <Row align="middle" justify="space-between">
          {/* 左邊 */}
          <Col>
            <Row gutter={[12, 12]}>
              {
                $p.includes('10201') &&
                <Col>
                  <Select
                    className="w-6"
                    placeholder={i18n.t('audit')}
                    value={status}
                    disabled={selectedRowKeys.length === 0}
                    options={enumToOptions(MEMBER_STATE)}
                    onChange={(status: number) => {
                      setStatus(status)
                      setIsModalOpen(true);
                    }}
                  />
                </Col>
              }
              {
                $p.includes('10102') &&
                <Col>
                  <Button type="primary" onClick={() => setIsOpenAddMemberModal(true)}>{i18n.t('add')}</Button>
                </Col>
              }
              {
                $p.includes('10104') &&
                <Col>
                  <Export url={'/member/list'} param={{
                    ...searchParam, PageSize: data && data.TotalRecord, PageIndex: 1
                  }} sheetName={i18n.t('memberList')} columns={ExcelColumns.MemberList} />
                </Col>
              }
            </Row>
          </Col>
          {/* 右邊 */}
          <Col  >
            <Popover
              placement="bottomRight"
              trigger="click"
              content={
                <div className="w-10">
                  {columns.map((item: any) =>
                    <div key={item.key}>
                      <Checkbox
                        checked={item.show}
                        onChange={e => updateColumnShow(item.dataIndex, e.target.checked)}
                      >
                        {item.title}
                      </Checkbox>
                    </div>
                  )}
                </div>
              }
            >
              <Button><DatabaseFilled /></Button>
            </Popover>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Table
          loading={isValidating}
          size="small"
          // 多選
          rowSelection={($p.includes('10201') &&
            {
              selectedRowKeys,
              onChange: onSelectChange,
            }) as any
          }
          scroll={{ x: '100%' }}
          dataSource={data?.Data?.map((item: Member) => ({ ...item, key: item.Id }))}
          columns={columns.filter((item: any) => item.show) as any}
          pagination={false}
        />
        <PopupMemberCommentModal isOpen={isEditCommentModal} close={() => setIsEditCommentModal(false)} id={currentId} mutate={mutate} />
        <PopupAddMemberModal isOpen={isOpenAddMemberModal} close={() => setIsOpenAddMemberModal(false)} mutate={mutate} />
        {/* 有的 */}
        <Modal title={i18n.t('prompt')} open={isModalOpen} onOk={handleOkStatus} onCancel={handleCancelStatus} okText={i18n.t('confirm')} cancelText={i18n.t('cancel')} centered>
          <p>{i18n.t('confirmUpdateBatch')}</p>
        </Modal>
      </Col>
    </>
  )
}

// 新增備註
export const PopupMemberCommentModal = ({ isOpen, close, id, mutate }: any) => {

  const [form] = Form.useForm();

  const handleSubmit = async (formData: any) => {
    const response = await PostMemoMemberCreate({
      Id: id,
      Noted: formData.remark
    });
    if (response.State === RESPONSE_TYPE.成功) {
      handleCancel();
      mutate();
      message.success(i18n.t('addSuccess'));
    }
  }

  const handleCancel = () => {
    close();
    form.resetFields();
  };

  return (
    <Modal
      title={i18n.t('remark')}
      open={isOpen}
      onCancel={handleCancel}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button className="mt-1" key="cancel" onClick={handleCancel}>{i18n.t('cancel')}</Button>
          <Button className="mt-1" key="confirm" type="primary" onClick={form.submit}>{i18n.t('confirm')}</Button>
        </Row>
      }
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item name="remark" rules={[{ required: true, message: `${i18n.t("enterRemark")}` }]}>
          <Input.TextArea
            size="middle"
            autoSize={{ minRows: 4, maxRows: 4 }}
            maxLength={100}
            showCount
            placeholder={`${i18n.t('enter')}`}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export const PopupMemberLoginHistory = ({ isOpen, close, id, account }: any) => {

  const { data: loginHistory } = FetchMemberLoginHistory({
    MemberId: id,
  }, !!id);

  if (!loginHistory) return <></>;
  return (
    <Modal
      title={`${account} ${i18n.t('loginTime')}`}
      open={isOpen}
      onCancel={close}
      width={600}
      centered
      footer={
        <Row justify="center">
          <Button type="primary" key="cancel" onClick={close}>{i18n.t('close')}</Button>
        </Row>
      }
    >
      <Table
        size="middle"
        pagination={false}
        scroll={{ y: 250 }}
        dataSource={
          loginHistory.map((item: LoginHistory) => {
            return {
              ...item,
              key: item.Id,
            }
          })
        }
        columns={[
          {
            title: i18n.t('loginTime'),
            dataIndex: 'CreateDate',
            className: 'size-12',
            width: 140
          },
          {
            title: i18n.t('IP'),
            dataIndex: 'IP',
            className: 'size-12',
            width: 140
          },
          {
            title: i18n.t('IP Geo Address'),
            dataIndex: 'IPLocation',
            className: 'size-12',
            width: 140
          },
        ]}
        bordered
      />
    </Modal>
  )
}

// 新增會員
export const PopupAddMemberModal = ({ isOpen, close, mutate }: any) => {

  const [form] = Form.useForm();

  const { data: allAgentList, isValidating } = GetAgentAllList();

  const handleSubmit = async (formData: any) => {
    if (!REGEX_ACCOUNT.test(formData.accountAdd) || typeof formData.accountAdd[0] !== 'string') {
      message.error(i18n.t('accountFormatError'));
      return;
    }

    if (!REGEX_PASSWORD.test(formData.password)) {
      message.error(i18n.t('passwordFormatError'));
      return;
    }

    if (formData.nickname?.length !== 0 && (formData.nickname?.length < 4 || formData.nickname?.length > 10)) {
      message.error(i18n.t('nicknameFormatError'));
      return;
    }

    const apiResult: ApiResult = await CreateMember({
      Account: formData.accountAdd,
      Password: formData.password,
      Type: 0,
      AgentAccount: formData.agent,
      NickName: formData.nickname,
    });

    if (apiResult.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('addSuccess'));
      handleCancel();
      mutate();
    } else {
      message.error(RESPONSE_CODE_MEMBER[apiResult.Message as keyof typeof RESPONSE_CODE_MEMBER]);
    }
  }

  const handleCancel = () => {
    form.resetFields();
    close();
  }

  return (
    <Modal
      title={i18n.t('add')}
      open={isOpen}
      onCancel={handleCancel}
      centered
      width={450}
      footer={false}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Row className="mb-1">
          <Col span={24}>
            <Form.Item name="accountAdd" label={i18n.t('account')} rules={[
              { required: true, message: `${i18n.t('required')}` },
              { min: 4, message: `${i18n.t('within4To12Characters')}` },
              { max: 12, message: `${i18n.t('within4To12Characters')}` },
            ]}>
              <Input placeholder={`${i18n.t('within4To12Characters')}`} />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col span={24}>
            <Form.Item name="password" label={i18n.t('password')} rules={[
              { required: true, message: `${i18n.t('required')}` },
              { min: 4, message: `${i18n.t('supportSpecialCharacters')}` },
              { max: 20, message: `${i18n.t('supportSpecialCharacters')}` },
            ]}>
              <Input.Password placeholder={`${i18n.t('supportSpecialCharacters')}`} />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col span={24}>
            <Form.Item name="agent" label={i18n.t('agent')} rules={[
              { required: true, message: `${i18n.t('required')}` }
            ]}>
              <Select
                showSearch
                loading={isValidating}
                className="w-full"
                placeholder={`${i18n.t('pleaseSelect')}`}
                options={
                  allAgentList?.Data?.map((item: { Id: number, Account: string }) => (
                    { value: item.Account, label: item.Account }
                  ))
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col span={24}>
            <Form.Item name="nickname" label={i18n.t('nickname')} rules={[
              { min: 4, message: `${i18n.t('nicknameCharsLimit')}` },
              { max: 12, message: `${i18n.t('nicknameCharsLimit')}` },
            ]}>
              <Input placeholder={`${i18n.t('nicknameCharsLimit')}`} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Button className="mr-1" onClick={handleCancel}>{i18n.t('cancel')}</Button>
          <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
        </Row>
      </Form>
    </Modal>
  )
}

export const ViewMemberMemoList = ({ id }: any) => {
  const { permissionCode: $p } = usePerm();
  const [form] = Form.useForm();
  const [isModalOpenDeleteMemo, setIsModalOpenDeleteMemo] = useState(false);
  const [deleteMemoId, setDeleteMemoId] = useState(0);
  const [editingKey, setEditingKey] = useState(0);

  const { data, mutate, isValidating } = FetchMemberMemoList({
    Id: id
  }, !!id);

  const handleEdit = (record: Memo) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.Id);
  };

  const handleDelete = (id: number) => {
    setDeleteMemoId(id);
    setIsModalOpenDeleteMemo(true);
  }

  const handleOkDelete = async () => {
    const apiResult: ApiResult = await DeleteMemo({
      Id: deleteMemoId,
    });
    if (apiResult.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('deleteSuccess'));
      handleCancelDelete();
      mutate();
    } else {
      message.error(RESPONSE_CODE_MEMBER[apiResult.Message as keyof typeof RESPONSE_CODE_MEMBER]);
    }
  };

  const handleCancelDelete = () => {
    setDeleteMemoId(0);
    setIsModalOpenDeleteMemo(false);
  }

  const handleSave = async (id: number) => {
    const row = (await form.validateFields()) as Memo;
    const apiResult: ApiResult = await ModifyMemo({
      Id: id,
      Noted: row.Note,
    });
    if (apiResult.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('updateSuccess'));
      setEditingKey(0);
      mutate();
    } else {
      message.error(RESPONSE_CODE_MEMBER[apiResult.Message as keyof typeof RESPONSE_CODE_MEMBER]);
    }
  };

  return (
    <>
      <Form form={form} component={false}>
        <div className="size-14 font-w-md">{i18n.t('remarkHistory')}</div>
        <Table
          loading={isValidating}
          className="mt-1"
          size="middle"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={data?.Data.map((item: Memo) => ({ ...item, key: item.Id }))}
          columns={[
            {
              editable: true,
              dataIndex: 'Note',
              title: i18n.t('remark'),
              className: 'size-12',
            },
            {
              dataIndex: 'OperatorAccount',
              title: i18n.t('updater'),
              className: 'size-12',
              width: 300,
            },
            {
              dataIndex: 'UpdateDate',
              title: i18n.t('updateTime'),
              className: 'size-12',
              width: 300,
            },
            {
              title: i18n.t('operation'),
              className: 'size-12',
              width: 160,
              render: (_: any, record: Memo) => {
                return (
                  <>
                    {
                      $p.includes('10203') &&
                      <>
                        {record.Id === editingKey ?
                          <>
                            <Button type="link" onClick={() => handleSave(record.Id)}>{i18n.t('confirm')}</Button>
                            <Button type="link" onClick={() => setEditingKey(0)}>{i18n.t('cancel')}</Button>
                            <Divider type="vertical" />
                          </> :
                          <Button type="link" disabled={editingKey !== 0} onClick={() => handleEdit(record)}>{i18n.t('edit')}</Button>
                        }
                      </>
                    }
                    {
                      $p.includes('10205') &&
                      <Button className="size-12" type="link" onClick={() => handleDelete(record.Id)}>{i18n.t('delete')}</Button>
                    }
                  </>
                )
              },
            },
          ].map((col: any) => {
            if (!col.editable) {
              return col;
            }
            return {
              ...col,
              onCell: (record: Memo) => ({
                record,
                inputType: 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: record.Id === editingKey,
              }),
            };
          })}
          pagination={false}
        />
      </Form>
      {/* 有的 */}
      <Modal
        title={i18n.t('prompt')}
        open={isModalOpenDeleteMemo}
        onOk={handleOkDelete}
        onCancel={handleCancelDelete}
        okText={i18n.t('confirm')}
        cancelText={i18n.t('cancel')}
        centered
      >
        <p>{i18n.t('confirmDelete')}</p>
      </Modal>
    </>
  )
}

export const MemberLevelSelect = ({ name, form, mode, disabled, bonusData, all = true }: {
  name: any;
  form: any
  mode?: any
  disabled?: boolean;
  bonusData?: any;
  all?: boolean;
}) => {
  const { data } = GetMemberlevelList();
  const $s = (val: any) => mode === 'multiple' ? val?.sort((a: number, b: number) => a - b) : val

  return (
    <Select
      value={$s(form.getFieldValue(name))}
      mode={mode}
      disabled={disabled}
      placeholder={mode === 'multiple' ? i18n.t('pleaseSelectMemberLevel') : i18n.t('memberLevel')}
      className="w-full"
      options={
        all
          ? [{ value: '', label: i18n.t('all') },
          ...(bonusData || data || []).map((item: any) => (
            { value: item.Id, label: item.Name }
          ))
          ].filter(item => !(mode === 'multiple' && item.value === ''))
          : [...(bonusData || data || []).map((item: any) => (
            { value: item.Id, label: item.Name }
          ))
          ].filter(item => !(mode === 'multiple' && item.value === ''))
      }
      onChange={val => form.setFieldValue(name, $s(val))}
    />
  )
}

export const GameCategorySelect = ({ name, form, mode, disabled, code }: {
  name: any;
  form: any
  mode?: any
  disabled?: boolean;
  code?: boolean;
}) => {

  const { data } = GameCategoryList({
    Status: 1
  });
  const $s = (val: any) => mode === 'multiple' ? val?.sort((a: number, b: number) => a - b) : val

  return (
    <Select
      value={$s(form.getFieldValue(name))}
      mode={mode}
      disabled={disabled}
      placeholder={i18n.t("gameCategory")}
      className="w-full"
      options={[
        { value: '', label: i18n.t('allGameCategory') },
        ...(data || []).map((item: any) => (
          { value: code ? item.Code : item.Id, label: i18n.t(item.Name) }
        ))
      ].filter(item => !(mode === 'multiple' && item.value === ''))}
      onChange={val => form.setFieldValue(name, $s(val))}
    />
  )
}
