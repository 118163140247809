import { Button, Col, Row, Spin, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { LayoutNav } from 'components/layout.component';
import { PopupDeleteSeo } from 'components/seo.component';
import { SEO_PAGE } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetSEOList } from 'services/seo.service';

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();

  const navigate = useNavigate();
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [type, setType] = useState<number | null>(null);
  const { data: SEOList, isValidating, mutate } = GetSEOList()

  const onDelete = (type: number) => {
    setType(type);
    setIsOpenDelete(true);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            {$p.includes('50401') && <Button type="primary" disabled={SEOList?.Data?.length >= 2}
              onClick={() => navigate('/seo/add')}>
              {i18n.t('add')}
            </Button>}
          </Col>
          <Col span={24}>
            <Spin spinning={isValidating}>
              <Table
                size="small"
                scroll={{ x: 1500 }}
                dataSource={
                  SEOList ?
                    SEOList.Data.map((item: SEOList, i: number) => (
                      {
                        key: i,
                        page: item.Type,
                        title: item.Title,
                        keywords: item.KeyWord,
                        description: item.Description
                      }
                    )) : []
                }
                columns={[
                  {
                    title: i18n.t('page'),
                    dataIndex: 'page',
                    width: 150,
                    render: (_, { page }) => (
                      <div style={{
                        whiteSpace: 'nowrap', maxWidth: 150,
                        overflow: 'hidden', textOverflow: 'ellipsis',
                      }}>
                        {SEO_PAGE[page]}
                      </div>
                    )
                  },
                  {
                    title: i18n.t('title'),
                    dataIndex: 'title',
                    width: 300,
                    render: (_, { title }) => (
                      <div style={{
                        whiteSpace: 'nowrap', maxWidth: 300,
                        overflow: 'hidden', textOverflow: 'ellipsis',
                      }}>
                        {title}
                      </div>
                    )
                  },
                  {
                    title: i18n.t('keywords'),
                    dataIndex: 'keywords',
                    width: 300,
                    render: (_, { keywords }) => (
                      <div style={{
                        whiteSpace: 'nowrap', maxWidth: 300,
                        overflow: 'hidden', textOverflow: 'ellipsis',
                      }}>
                        {keywords}
                      </div>
                    )
                  },
                  {
                    title: i18n.t('description'),
                    dataIndex: 'description',
                    width: 300,
                    render: (_, { description }) => (
                      <div style={{
                        whiteSpace: 'nowrap', maxWidth: 300,
                        overflow: 'hidden', textOverflow: 'ellipsis',
                      }}>
                        {description}
                      </div>
                    )
                  },
                  {
                    title: i18n.t('operation'),
                    width: 100,
                    fixed: 'right',
                    render: (_, record) => (
                      <>
                        {$p.includes('50402') && <Button type="link" onClick={() => navigate(`/seo/edit/${record.page}`)}>
                          {i18n.t('edit')}
                        </Button>}
                        {$p.includes('50405') && <Button type="link" onClick={() => onDelete(record.page)}>
                          {i18n.t('delete')}
                        </Button>}
                      </>
                    )
                  },
                ]}
                pagination={false}
              />
            </Spin>
          </Col>
        </Row>
      </Content>
      {/* Modal */}
      <PopupDeleteSeo isOpen={isOpenDelete} close={() => setIsOpenDelete(false)} type={type} mutate={mutate} />
    </div>
  );
};

export default PageMain;