import { COOKIE } from "constants/cookie";
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import { FetchAdminInfo } from "services/account.service";

const useAuth = () => {
  const isLogin = !!Cookies.get(COOKIE.TOKEN);

  const login = async (token: string) => {
    Cookies.set(COOKIE.TOKEN, token);
  };

  const logout = () => {
    Cookies.remove(COOKIE.TOKEN);
    Cookies.remove(COOKIE.LANG);
    Cookies.remove(COOKIE.FIRST_LOGIN);
    Cookies.remove(COOKIE.PLATFORM_NAME);
    Cookies.remove(COOKIE.LOGO_01);
    Cookies.remove(COOKIE.LOGO_02);
  };

  const getToken = () => {
    return Cookies.get(COOKIE.TOKEN) || '';
  }

  const getAdmin = () => {
    const { data: admin, mutate: mutateAdmin } = FetchAdminInfo();

    const mutate = () => {
      mutateAdmin();
    };

    return {
      ...admin,
      mutate,
    };
  };

  const useCheckLogin = () => {
    const navigate = useNavigate();

    if (!isLogin) {
      logout();
      navigate('/login');
    }
  };

  return {
    isLogin,
    login,
    logout,
    getToken,
    getAdmin,
    useCheckLogin,
  };
};

export default useAuth;
