import { Button, Col, Form, Input, Menu, MenuProps, Row, Select, Switch, Table, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { LayoutNav, LayoutPagination, LayoutTabPlatform } from 'components/layout.component';
import { RESPONSE_CODE_GAME } from 'constants/response';
import { RESPONSE_TYPE } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { GameList, GameUpdate, UpdateGameMulti } from 'services/game.service';
import { ProviderOpenList } from 'services/provider.service';
import { verify } from 'utils/common';

// 站台設置 遊戲清單

interface ProviderType {
  Id: number;
  CategoryId: number;
  CategoryCode: string;
  ProviderName: string;
  ProviderCode: string;
  Status: number;
  Account: string;
  UpdateDate: string;
}

interface GameInfoType {
  key: number;
  Id: number;
  Label: string;
  Name: string;
  Lang: string;
  Photo: string;
  CategoryId: number;
  CategoryCode: string;
  ProviderId: number;
  ProviderCode: string;
  Position: number;
  Code: string;
  FullCode: string;
  Status: number;
  CreateDate: string;
  UpdateDate: string;
  IsMultiGameConsole: number;
  HasCommission: number;
  gameName: string;
}

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();

  const [form] = useForm();
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [dataSource, setDataSource] = useState<any>([])
  const [isEdit, setIsEdit] = useState(false);
  const [editingKey, setEditingKey] = useState<number>(0);
  const { data: provider } = ProviderOpenList();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [providerType, setProviderType] = useState<string[]>([]);
  const { data: GameInfo, mutate } = GameList({
    ProviderCode: providerType && providerType[0],
    CategoryCode: providerType && providerType[1],
    PageSize: page[1],
    PageIndex: page[0]
  });
  const [position, setPosition] = useState<number | null>();
  const [label, setLabel] = useState<number[]>([]);
  const [isMulti, setIsMulti] = useState<number | null>(null);

  useEffect(() => {
    if (GameInfo) {
      setDataSource(
        GameInfo.Data.map((item: any) => ({
          key: item.Id,
          Position: item.Position,
          Name: item.Name,
          gameName: item.Lang,
          Label: item.Label,
          IsMultiGameConsole: item.IsMultiGameConsole
        }))
      )
    }
  }, [GameInfo])

  const handleEdit = (key: number) => {
    setEditingKey(key);
    setIsEdit(true);
  };

  const handleSave = async (info: GameInfoType) => {

    const response = await GameUpdate({
      Id: info.key,
      Position: position ? Number(position) : info.Position,
      Label: label?.length !== 0 ? label : JSON.parse(info.Label),
      IsMultiGameConsole: isMulti !== null ? isMulti : info.IsMultiGameConsole
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('editSuccess'));
      mutate();
      setIsEdit(false);
      setEditingKey(0);
      setLabel([]);
      setPosition(null);
      setIsMulti(null);
    } else {
      message.error(RESPONSE_CODE_GAME[response.Message as keyof typeof RESPONSE_CODE_GAME]);
    }

  }
  const onCancel = (info: GameInfoType) => {
    const data: number[] = JSON.parse(info.Label);
    const select: number[] = data?.filter((item: number) => item !== 0)
    if (info.key === editingKey) {
      setIsEdit(false);
      setEditingKey(0);
      setLabel([]);
      setPosition(null);
      setIsMulti(null);
      form.setFieldValue(`tags${info.key}`, select);
      form.setFieldValue(`isMulti${info.key}`, info.IsMultiGameConsole);
      form.setFieldValue(`sort${info.key}`, info.Position);
    }
  }

  const onSetGameMulti = async (id: number, status: boolean) => {
    try {
      const response = await UpdateGameMulti({
        Id: id,
        IsMultiGameConsole: status ? 1 : 0
      })
      if (response.State === RESPONSE_TYPE.成功) {
        message.success(i18n.t('editSuccess'));
        mutate();
      } else {
        message.error(RESPONSE_CODE_GAME[response.Message as keyof typeof RESPONSE_CODE_GAME]);
      }
    } catch (error) {
      return;
    }
  }

  //Menu
  const onOpenChange: MenuProps['onOpenChange'] = (keys) => (
    setOpenKeys([keys[keys.length - 1]])
  );
  const onClick: MenuProps['onClick'] = (e) => {
    const key = e.keyPath[0].split(',');
    setProviderType([key[0], key[1]]);
    setPage([1, page[1]]);
  };
  type MenuItem = Required<MenuProps>['items'][number];
  function getItem(
    label: React.ReactNode,
    key: React.Key | string[],
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }
  const items: MenuProps['items'] = provider ?
    Array.from(new Set(provider.map((item: ProviderType) => item.ProviderCode)))
      .map((providerName, i) => (
        getItem(`${providerName}`, i, undefined, [
          ...provider.map((item: ProviderType) => (
            item.ProviderCode === providerName
            && getItem(i18n.t(item.CategoryCode), [item.ProviderCode, item.CategoryCode])
          ))
        ])
      ))
    : [];

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Form form={form}>
          <LayoutTabPlatform activeKey="5" />
          <Row gutter={[12, 12]}>
            <Col span={4}>
              <Menu
                id="gameMenu"
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                onClick={onClick}
                mode="inline"
                items={items}
              />
            </Col>
            <Col span={20}>
              <Table
                loading={!GameInfo}
                size="middle"
                dataSource={dataSource || []}
                columns={[
                  {
                    dataIndex: 'frontSort',
                    title: i18n.t('frontSort'),
                    width: 100,
                    // sorter: (a, b) => a.Position - b.Position,
                    render: (_: any, record: any) => (
                      <>
                        {
                          (isEdit && record.key === editingKey)
                            ?
                            <Form.Item name={`sort${record.key}`}
                              rules={verify({ point: 0, required: false })}>
                              <Input defaultValue={record.Position}
                                onChange={e => setPosition(Number(e.target.value))} />
                            </Form.Item>
                            : record.Position
                        }
                      </>
                    )
                  },
                  {
                    dataIndex: 'gameName',
                    title: i18n.t('gameName'),
                    width: 300,
                    render: (_: any, { gameName, Name }: any) => {
                      const UserLang = Cookies.get('lang');
                      const LangNameAry = JSON.parse(gameName);
                      const DefaultName = Name;
                      const foundItem = LangNameAry.find((item: { lang: string }) => item.lang === UserLang);
                      const displayedName = foundItem ? foundItem.name : DefaultName;
                      return <>{displayedName}</>
                    }
                  },
                  {
                    dataIndex: 'supportMultipleDevices',
                    title: i18n.t('supportMultipleDevices'),
                    align: 'center',
                    width: 100,
                    render: (_: any, record: any) => (
                      <Form.Item name={`isMulti${record.key}`} valuePropName="checked">
                        <Switch
                          disabled={!$p.includes('00402')}
                          defaultChecked={!!record.IsMultiGameConsole}
                          onChange={e => {
                            setIsMulti(e ? 1 : 0);
                            onSetGameMulti(record.key, e);
                          }}
                        />
                      </Form.Item>
                    )
                  },
                  {
                    dataIndex: 'gameSetting',
                    title:
                      <>
                        {i18n.t('gameSetting')}
                        <span className="color-03">({i18n.t('selectableMultiple')})</span>
                      </>,
                    width: 300,
                    render: (_: any, record: any) => {
                      const data: number[] = JSON.parse(record.Label);
                      const select: number[] = data?.filter((item: number) => item !== 0);
                      return (
                        <Form.Item name={`tags${record.key}`} >
                          <Select
                            disabled={record.key !== editingKey}
                            mode="tags"
                            tokenSeparators={[',']}
                            defaultValue={select}
                            className="w-full"
                            placeholder={`${i18n.t('pleaseSelect')}`}
                            onChange={setLabel}
                            options={[
                              { value: 1, label: i18n.t('newGame') },
                              { value: 2, label: i18n.t('hotGames') },
                              { value: 3, label: i18n.t('recommend') },
                            ]}
                          />
                        </Form.Item>
                      )
                    }
                  },
                  {
                    dataIndex: 'operation',
                    title: i18n.t('operation'),
                    width: 100,
                    fixed: 'right',
                    align: 'center',
                    render: (_: any, record: any) => (
                      <>{
                        (isEdit && record.key === editingKey)
                          ?
                          <>
                            <Button type="link" onClick={() => handleSave(record)}>
                              {i18n.t('done')}
                            </Button>
                            <Button type="link" onClick={() => onCancel(record)}>
                              {i18n.t('cancel')}
                            </Button>
                          </>
                          :
                          <Button type="link" onClick={() => handleEdit(record.key)}>
                            {i18n.t('edit')}
                          </Button>
                      }</>
                    )
                  }
                ].filter((item: any) => !(item.dataIndex === 'operation' && !$p.includes('00402'))) as any}
                pagination={false}
              />
              <LayoutPagination total={GameInfo && GameInfo.TotalRecord} setPage={setPage} page={page} />
            </Col>
          </Row>
        </Form>
      </Content>
    </div >
  );
};

export default PageMain;