import {
  axiosGetAllData,
  axiosPostAuth,
  baseURL,
  useFetchData,
  useFetchDataCommon
} from "services";

export const FetchMemberMemoList = (params: any, allow: boolean) => useFetchData(axiosGetAllData, `${baseURL}/memo/member/list`, params, 1000, allow, true);
export const GetMemoAgentList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/memo/agent/list`, params);

export const ModifyMemo = (data: any) => axiosPostAuth(`${baseURL}/memo/update`, data);
export const DeleteMemo = (data: any) => axiosPostAuth(`${baseURL}/memo/delete`, data);

export const PostMemoMemberCreate = (data: any) => axiosPostAuth(`${baseURL}/memo/member/create`, data);
export const PostMemoAgentCreate = (data: any) => axiosPostAuth(`${baseURL}/memo/agent/create`, data);
