import {
  axiosGetAllData,
  axiosGetAuth,
  axiosPostAuth,
  baseURL,
  useFetchData,
  useFetchDataCommon
} from "services";

// 找總代
export const GetAgentSaList = (params: any, allow?: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/agent/sa/list`, params, allow);
// 找子代
export const GetAgentAgList = (params: any, allow?: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/agent/ag/list`, params, allow);
// 找所有代理
export const GetAgentList = (params: any, allow?: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/agent/list`, params, allow);
export const GetAgentAllList = () => useFetchDataCommon(axiosGetAllData, `${baseURL}/agent/all/list`);

// 取得代理
export const GetAgentOne = (params: any, allow: boolean) => useFetchDataCommon(axiosGetAuth, `${baseURL}/agent/one`, params, allow);
export const GetAgentOnelite = (params: any, allow: boolean) => useFetchDataCommon(axiosGetAuth, `${baseURL}/agent/onelite`, params, allow);
export const FetchAgentDetail = (params: any, allow: boolean) => useFetchDataCommon(axiosGetAuth, `${baseURL}/agent/one`, params, allow);

export const FetchAgentDepositSummary = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/depositticket/agent/total`, params);
export const AgentDepositReject = (data: any) => axiosPostAuth(`${baseURL}/depositticket/agent/reject`, data);
export const AgentDepositResolve = (data: any) => axiosPostAuth(`${baseURL}/depositticket/agent/resolve`, data);
export const CreateAgentSa = (data: any) => axiosPostAuth(`${baseURL}/agent/sa/create`, data);
export const CreateAgentAg = (data: any) => axiosPostAuth(`${baseURL}/agent/ag/create`, data);
export const FetchTagList = (params: any) => useFetchData(axiosGetAuth, `${baseURL}/tag/list`, params, 1000);
export const PostAgentUpdate = (data: any) => axiosPostAuth(`${baseURL}/agent/update`, data);
export const VerifyAgent = (data: any) => axiosPostAuth(`${baseURL}/agent/manual/verify`, data);

export const GetAgentReportCommissionList = (params: any, allow?: boolean) => useFetchDataCommon(axiosGetAuth, `${baseURL}/agent/report/commission/list`, params, allow);
export const GetAgentReportRevenueshareList = (params: any, allow?: boolean) => useFetchDataCommon(axiosGetAuth, `${baseURL}/agent/report/revenueshare/list`, params, allow);

export const GetAgentReportCommissionSettlementList = (params: any, allow?: boolean) => useFetchDataCommon(axiosGetAuth, `${baseURL}/agent/report/commission/settlement/list`, params, allow);
export const GetAgentReportRevenueshareSettlementList = (params: any, allow?: boolean) => useFetchDataCommon(axiosGetAuth, `${baseURL}/agent/report/revenueshare/settlement/list`, params, allow);

export const GetAgentReportCommissionPeriodList = (params: any, allow: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/agent/report/commission/period/list`, params, allow);
export const GetAgentReportRevenuesharePeriodList = (params: any, allow: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/agent/report/revenueshare/period/list`, params, allow);

export const GetAgentCashlogList = (params: any, allow?: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/agent/cashlog/list`, params, allow);
export const GetAgentLoginHistory = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/agent/login/history`, params);
export const GetAgentSettleCycleList = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/agent/settlecycle/list`, params);
export const ChangeAgentLine = (data: any) => axiosPostAuth(`${baseURL}/agent/changeagent`, data);

export const AgentSettleCycleMemberList = (params: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/agent/settlecycle/member/list`, params);

export const AgentRebateInfo = (params: any, allow: boolean = true) => useFetchDataCommon(axiosGetAuth, `${baseURL}/agentoperationparams/one`, params, allow);
export const AgentRebateUpdate = (data: any) => axiosPostAuth(`${baseURL}/agentoperationparams/update`, data);
