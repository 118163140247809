import { Button, Col, Descriptions, Form, Image, Input, Modal, Row, Select, Spin, Table, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { RESPONSE_CODE_AGENT_WITHDRAW, RESPONSE_CODE_FINANCE } from "constants/response";
import { DEPOSIT, DEPOSIT_STATE, RESPONSE_TYPE, VERIFICATION_STATE, WITHDRAW, WITHDRAW_STATE, WITHDRAW_TYPE } from "enum/state";
import useAuth from "hooks/auth.hook";
import usePerm from "hooks/permission.hook";
import i18n from "i18n";
import { useEffect, useState } from "react";
import { DepositDetail, RejectAgentDeposit, ResolveAgentDeposit } from "services/finance.service";
import { GetCryptoList, GetWithdrawmerchant } from "services/withdrawmerchant.service";
import { AgentWithdrawInfo, AuditorPass, AuditorReject, FinancePass, FinanceReject, FinanceWithdrawInfo, ServicePass, ServiceReject } from "services/withdrawticket.service";
import * as common from 'utils/common';
import { PopupOrderLock } from "./finance.component";
import { CopyButton } from "./layout.component";

// 代理入款審核詳細
export const PopupAgentDeposit = ({ isOpen, close, id, mutate }: any) => {
  const { permissionCode: $p } = usePerm();
  const [form] = useForm();
  const { data: depositDetail, mutate: mutateOne, isValidating } = DepositDetail({ Id: id });

  useEffect(() => {
    if (isOpen) {
      mutateOne()
    }
  }, [isOpen]);
  useEffect(() => {
    if (depositDetail && isOpen) {
      form.setFieldsValue({
        bnRemarks: depositDetail.BnRemark
      })
    }
  }, [depositDetail, isOpen]);

  const handlePass = async () => {
    const { bnRemarks } = form.getFieldsValue();
    const response = await ResolveAgentDeposit({
      Id: depositDetail.Id,
      RealAmount: depositDetail.Amount,
      BnRemarkFromFin: bnRemarks
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('pass'));
      onClose();
      mutate();
    } else {
      message.error(RESPONSE_CODE_FINANCE[response.Message as keyof typeof RESPONSE_CODE_FINANCE]);
    }
  }

  const handleReject = async () => {
    const { bnRemarks } = form.getFieldsValue();
    const response = await RejectAgentDeposit({
      Id: depositDetail.Id,
      RemarkFromFin: bnRemarks
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('reject'));
      onClose();
      mutate();
    } else {
      message.error(RESPONSE_CODE_FINANCE[response.Message as keyof typeof RESPONSE_CODE_FINANCE]);
    }
  }

  const onClose = () => {
    close();
  }

  return (
    <Modal title={depositDetail?.Status === DEPOSIT_STATE.待財務審核 ? `${i18n.t('deposit')}` : `${i18n.t('detail')}`}
      open={isOpen}
      centered={true}
      onCancel={onClose}
      footer={
        <Row justify="center" gutter={[12, 12]}>
          { depositDetail?.Status === DEPOSIT_STATE.待財務審核 && $p.includes('40603')
            ? <>
              <Col><Button type="primary" danger onClick={handleReject}>{i18n.t('reject')}</Button></Col>
              <Col><Button type="primary" onClick={handlePass}>{i18n.t('pass')}</Button></Col>
            </>
            :
           (depositDetail?.Status === DEPOSIT_STATE.付款中 || depositDetail?.Status === DEPOSIT_STATE.失敗) && $p.includes('40603')
            ? <>
              <Col><Button type="primary" danger onClick={handleReject}>{i18n.t('forceFailure')}</Button></Col>
              <Col><Button type="primary" onClick={handlePass}>{i18n.t('forceApproval')}</Button></Col>
            </>
            : <Col><Button type="primary" onClick={close}>{i18n.t('close')}</Button></Col>
          }
        </Row>
      }
      width={900}
    >
      <Form form={form}>
        <Spin spinning={isValidating}>
          <Descriptions
            bordered
            size="small"
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('order')}>
              {depositDetail?.Code}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('agentAccount')}>
              <Row align="middle" justify="space-between">
                <Col> {depositDetail?.OwnerAccount}</Col>
                <Col><CopyButton text={depositDetail?.OwnerAccount} /></Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label={`${i18n.t('agentName')}`}>
              {depositDetail?.AgentNickName || depositDetail?.AgentName || '-'}
            </Descriptions.Item>
            <Descriptions.Item>
            </Descriptions.Item>
          </Descriptions>

          <Descriptions
            bordered
            size="small"
            column={2}
            className="pt-1"
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('depositAmount')}>
              {common.toFormatNumber(depositDetail?.Amount)}
            </Descriptions.Item>
            {
              depositDetail && depositDetail.DepositMerchantGatewayCode === DEPOSIT.線下虛擬
                ?
                <>
                  <Descriptions.Item label={i18n.t('currencyType')}>
                    {depositDetail && depositDetail.OfflineInfo.Crypto}
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('networkType')}>
                    {depositDetail && depositDetail.OfflineInfo.CryptoType}
                  </Descriptions.Item>
                </>
                :
                <>
                  <Descriptions.Item label={i18n.t('depositMethod')}>
                    {depositDetail ? depositDetail.GatewayName : '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('depositInformation')}>
                    {
                      depositDetail && (
                        depositDetail.DepositMerchantGatewayCode === DEPOSIT.線下虛擬 ||
                        depositDetail.DepositMerchantGatewayCode === DEPOSIT.線下銀行) ?
                        <>
                          <div>{depositDetail && depositDetail.OfflineInfo.Name || '-'}</div>
                          <div>
                            <span>{depositDetail && depositDetail.OfflineInfo.BankName || '-'}</span>／
                            <span>{depositDetail && common.displayLastFourCharacters(depositDetail.OfflineInfo.Account) || '-'}</span>／
                            <span>{depositDetail && depositDetail.OfflineInfo.PhoneNumber || '-'}</span>
                          </div>
                        </>
                        :
                        <>
                          <div>{depositDetail?.MerchantInfo.CustomName || '-'}</div>
                        </>
                    }
                  </Descriptions.Item>
                </>

            }
            <Descriptions.Item label={`${i18n.t('paymentProcessingFee')}／${i18n.t('exchangeRate')}`}>
              {depositDetail && common.toFormatNumber(depositDetail.HandlingFee, 2)}／
              {depositDetail && depositDetail.ExchangeRate !== 0 ? common.toFormatNumber(depositDetail.ExchangeRate, 2) : '-'}
            </Descriptions.Item>
            {
              depositDetail && depositDetail.DepositMerchantGatewayCode === DEPOSIT.線下虛擬 &&
              <>
                <Descriptions.Item label={i18n.t('receivingAddress')} span={2}>
                  {depositDetail && depositDetail.OfflineInfo.CryptoAddress}
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('QR Code')}>
                  {
                    depositDetail && depositDetail.OfflineInfo.QR_Code
                      ? <Image src={depositDetail ? depositDetail.OfflineInfo.QR_Code : ''} height={100} />
                      : '-'
                  }
                </Descriptions.Item>
                <Descriptions.Item label={''}>
                </Descriptions.Item>
              </>
            }
          </Descriptions>

          <Descriptions
            bordered
            size="small"
            column={2}
            className="pt-1"
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('auditor')}>
              {
                depositDetail && depositDetail.Status === DEPOSIT_STATE.成功 && (
                  depositDetail.DepositMerchantGatewayCode === DEPOSIT.三方ATM ||
                  depositDetail.DepositMerchantGatewayCode === DEPOSIT.三方超商 ||
                  depositDetail.DepositMerchantGatewayCode === DEPOSIT.三方銀行
                ) ? i18n.t('system') : (depositDetail?.FinanaceAccount || '-')
              }
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('status')}>
              {DEPOSIT_STATE[depositDetail?.Status]}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('createTime')}>
              {depositDetail?.CreateDate}
            </Descriptions.Item>
            <Descriptions.Item>
            </Descriptions.Item>
          </Descriptions>

          <Descriptions
            bordered
            size="small"
            column={2}
            className="pt-1"
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('backOfficeRemarks')}>
              {depositDetail?.Status !== DEPOSIT_STATE.待財務審核
                ? depositDetail?.BnRemark || '-'
                :
                <Form.Item name="bnRemarks">
                  <Input placeholder={`${i18n.t('enter')}`} disabled={!$p.includes('40603')} />
                </Form.Item>
              }
            </Descriptions.Item>
            {
              depositDetail && (
                depositDetail.DepositMerchantGatewayCode === DEPOSIT.三方ATM ||
                depositDetail.DepositMerchantGatewayCode === DEPOSIT.三方超商 ||
                depositDetail.DepositMerchantGatewayCode === DEPOSIT.三方銀行 ||
                depositDetail.DepositMerchantGatewayCode === DEPOSIT.線下銀行
              ) &&
              <Descriptions.Item label={i18n.t('certificate')}>
                {
                  depositDetail && depositDetail?.ReceiptPhoto
                    ? <Image className="center" src={depositDetail?.ReceiptPhoto} height={100} />
                    : '-'
                }
              </Descriptions.Item>
            }
          </Descriptions>
        </Spin>
      </Form>
    </Modal>
  )
}


// 代理提款總計
export const ViewAgentWithdrawOverview = ({ data }: {
  data: {
    State: string;
    TotalAmount: number;
    TotalHandlingFee: number;
    TotalRealAmount: number;
    TotalRecord: number;
  };
}) => {

  return (
    <Table
      className="mt-1"
      size="middle"
      dataSource={[{
        count: data?.TotalRecord,
        WithdrawalTotalAmount: data?.TotalAmount,
        totalProcessingFee: data?.TotalHandlingFee,
        totalActualWithdrawalAmount: data?.TotalRealAmount
      }]}
      columns={[
        {
          title: i18n.t('count'),
          dataIndex: 'count',
          align: 'right',
          width: '25%',
        },
        {
          title: i18n.t('WithdrawalTotalAmount'),
          dataIndex: 'WithdrawalTotalAmount',
          align: 'right',
          width: '25%',
          render: (_, { WithdrawalTotalAmount }) => common.toFormatNumber(WithdrawalTotalAmount)
        },
        {
          title: i18n.t('totalProcessingFee'),
          dataIndex: 'totalProcessingFee',
          align: 'right',
          width: '25%',
          render: (_, { totalProcessingFee }) => common.toFormatNumber(totalProcessingFee)
        },
        {
          title: i18n.t('totalActualWithdrawalAmount'),
          dataIndex: 'totalActualWithdrawalAmount',
          align: 'right',
          width: '25%',
          render: (_, { totalActualWithdrawalAmount }) => common.toFormatNumber(totalActualWithdrawalAmount)
        },
      ]}
      pagination={false}
    />
  )
}
// 代理提款列表
export const ViewAgentWithdrawList = ({ data, mutate }: {
  data: {
    State: string;
    Data: AgentWithdrawListType[];
  },
  mutate: () => void;
}) => {
  const auth = useAuth();
  const admin = auth.getAdmin();
  const { permissionCode: $p } = usePerm();
  const [id, setId] = useState<number | null>(0);
  const [financeId, setFinanceId] = useState<number | null>(0);
  const [orderState, setOrderState] = useState<number>(0);
  const [orderType, setOrderType] = useState<string | null>('');
  const [isModalOpenConfirm, setIsModalOpenConfirm] = useState(false);
  const [isModalOpenDetail, setIsModalOpenDetail] = useState(false);
  const [isModalOpenWithdraw, setIsModalOpenWithdraw] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);

  const showModalConfirm = (id: number, orderState: number) => {
    setId(id);
    setOrderState(orderState);
    setIsModalOpenConfirm(true);
  };

  const showModalDetail = (id: number, GatewayCode: string) => {
    setId(id);
    setOrderType(GatewayCode);
    setIsModalOpenDetail(true);
  };

  const showModalWithdraw = (id: number, orderState: number, GatewayCode: string) => {
    setFinanceId(id);
    setOrderState(orderState);
    setOrderType(GatewayCode);
    setIsModalOpenWithdraw(true);
  };

  return (
    <>
      <Table
        size="middle"
        scroll={{ x: 1500 }}
        dataSource={data?.Data?.map(item => ({ ...item, key: item.Id }))}
        columns={[
          {
            dataIndex: 'Code',
            title: i18n.t('order'),
            width: 250,
            fixed: 'left'
          },
          {
            dataIndex: 'AgentAccount',
            title: i18n.t('account'),
            width: 200,
            fixed: 'left',
            render: (val: any, { AgentLevel }: any) => {
              const word = common.agentLevelWord(AgentLevel);
              return `${word} / ${val}`
            }
          },
          {
            dataIndex: 'SAAccount',
            title: i18n.t('generalAgent'),
            width: 200,
          },
          {
            dataIndex: 'BeforeAmount',
            title: i18n.t('previousBalance'),
            align: 'right',
            width: 200,
            render: (val) => common.toFormatNumber(val)
          },
          {
            dataIndex: 'Amount',
            title: i18n.t('withdrawalAmount'),

            align: 'right',
            width: 200,
            render: (val) => common.toFormatNumber(val)
          },
          {
            dataIndex: 'Amount',
            title: i18n.t('actualWithdrawalAmount'),
            align: 'right',
            width: 200,
            render: (val) => common.toFormatNumber(val)
          },
          {
            dataIndex: 'AfterAmount',
            title: i18n.t('BalanceAfterModification'),

            align: 'right',
            width: 200,
            render: (val) => common.toFormatNumber(val)
          },
          {
            dataIndex: 'HandlingFee',
            title: i18n.t('paymentProcessingFee'),
            align: 'right',
            width: 200,
            render: (val) => val ? common.toFormatNumber(val, 2) : '-'
          },
          {
            dataIndex: 'ExchangeRate',
            title: i18n.t('exchangeRate'),
            align: 'right',
            width: 100,
            render: (val) => val ? common.toFormatNumber(val, 2) : '-'
          },
          {
            dataIndex: 'CreateDate',
            title: i18n.t('createTime'),
            width: 150,
          },
          {
            dataIndex: 'AuditedDate',
            title: i18n.t('auditTime'),
            width: 150,
            render: (val) => val === '0000-00-00 00:00:00' ? '-' : val
          },
          {
            dataIndex: 'Status',
            title: i18n.t('status'),
            width: 180,
            fixed: 'right',
            render: (val, { FinanceAccount, ServiceAccount, AuditorAccount }) => (
              <>
                {/* 狀態 */}
                {
                  <div className={common.numColor04(val)}>{WITHDRAW_STATE[val]}</div>
                }
                {/* 誰做的 */}
                {
                  val === WITHDRAW_STATE.待主管審核 &&
                  <>{i18n.t('from')} {ServiceAccount} {i18n.t('operation')}</>
                }
                {
                  val === WITHDRAW_STATE.待財務審核 &&
                  <>{i18n.t('from')} {AuditorAccount} {i18n.t('operation')}</>
                }
                {
                  val === WITHDRAW_STATE.財務審核中 &&
                  <>{i18n.t('from')} {FinanceAccount} {i18n.t('operation')}</>
                }
                {(
                  val === WITHDRAW_STATE.通過 ||
                  val === WITHDRAW_STATE.處理中 ||
                  val === WITHDRAW_STATE.支付端等待支付) &&
                  <>{i18n.t('from')} {FinanceAccount} {i18n.t('operation')}</>
                }
                {
                  val === WITHDRAW_STATE.拒絕 &&
                  <>{i18n.t('from')} {ServiceAccount || AuditorAccount || FinanceAccount} {i18n.t('operation')}</>
                }
              </>
            )
          },
          {
            title: i18n.t('operation'),
            fixed: 'right',
            width: 100,
            render: (_, { Status, key, FinanceAccountId, GatewayCode }: any) => (
              <>
                {(
                 (Status === WITHDRAW_STATE.待客服審核 && $p.includes('40504')) ||
                 (Status === WITHDRAW_STATE.待主管審核 && $p.includes('40506'))) &&
                  <Button type="link" onClick={() => showModalConfirm(key, Status)}>
                    {i18n.t('audit')}
                  </Button>
                }
                {(
                 (Status === WITHDRAW_STATE.待財務審核 && $p.includes('40508')) ||
                 (Status === WITHDRAW_STATE.財務審核中 && (FinanceAccountId === admin.Id))) &&
                  <Button type="link" onClick={() => showModalWithdraw(key, Status, GatewayCode)}>
                    {i18n.t('withdrawal')}
                  </Button>
                }
                {((
                 (Status === WITHDRAW_STATE.待客服審核 && !$p.includes('40504')) ||
                 (Status === WITHDRAW_STATE.待主管審核 && !$p.includes('40506')) ||
                 (Status === WITHDRAW_STATE.待財務審核 && !$p.includes('40508')) ||

                  Status === WITHDRAW_STATE.通過 ||
                  Status === WITHDRAW_STATE.拒絕 ||
                  Status === WITHDRAW_STATE.處理中 ||
                  Status === WITHDRAW_STATE.失敗 ||
                  Status === WITHDRAW_STATE.支付端等待支付 ||
                 (Status === WITHDRAW_STATE.財務審核中 && FinanceAccountId !== admin.Id)) && 
                  $p.includes('40502')) &&
                  
                  <Button type="link" onClick={() => showModalDetail(key, GatewayCode)}>
                    {i18n.t('detail')}
                  </Button>
                }
              </>
            ),
          },
        ]}
        pagination={false}
      />
      {/* 前置審核 */}
      <PopupAgentWithdrawAudit isOpen={isModalOpenConfirm} close={() => setIsModalOpenConfirm(false)} id={id} orderState={orderState} mutate={mutate} />
      {/* 財務出款審核 */}
      <PopupAgentWithdrawWithdrawal isOpen={isModalOpenWithdraw} close={() => setIsModalOpenWithdraw(false)} id={financeId} orderState={orderState} mutate={mutate} orderType={orderType} setErrorPopup={setErrorPopup} errorPopup={errorPopup} />
      {/* 明細 */}
      <PopupAgentWithdrawDetail isOpen={isModalOpenDetail} close={() => setIsModalOpenDetail(false)} id={id} orderType={orderType} mutate={mutate} />
      {/* 鎖單錯誤訊息視窗 */}
      <PopupOrderLock isOpen={errorPopup} close={() => setErrorPopup(false)} />
    </>
  )
}
// 代理前置審核
export const PopupAgentWithdrawAudit = ({ isOpen, close, id, orderState, mutate }: any) => {
  const [form] = useForm();
  const { data, isValidating, mutate: RefreshInfo } = AgentWithdrawInfo({ Id: id });
  let response: any = {}

  useEffect(() => {
    if (isOpen) {
      RefreshInfo()
    }
  }, [isOpen]);
  useEffect(() => {
    if (data && isOpen) {
      form.setFieldsValue({
        bnRemarks: data.AuditorAccessLogRemark || data.ServiceAccessLogRemark
      })
    }
  }, [data, isOpen]);

  const handlePass = async () => {
    const { fnRemarks, bnRemarks } = form.getFieldsValue();
    switch (orderState) {
      case WITHDRAW_STATE.待客服審核:
        response = await ServicePass({
          Id: id,
          Remark: bnRemarks
        })
        if (response.State === RESPONSE_TYPE.成功) {
          message.success(i18n.t('operationSuccess'));
          mutate();
          onClose();
        } else {
          message.error(RESPONSE_CODE_AGENT_WITHDRAW[response.Message as keyof typeof RESPONSE_CODE_AGENT_WITHDRAW]);
          if (response.Message === 'TICKET_STATUS_ILLEGAL') {
            mutate();
            onClose();
          }
        }
        break;
      case WITHDRAW_STATE.待主管審核:
        response = await AuditorPass({
          Id: id,
          Remark: bnRemarks
        })
        if (response.State === RESPONSE_TYPE.成功) {
          message.success(i18n.t('operationSuccess'));
          mutate();
          onClose();
        } else {
          message.error(RESPONSE_CODE_AGENT_WITHDRAW[response.Message as keyof typeof RESPONSE_CODE_AGENT_WITHDRAW]);
          if (response.Message === 'TICKET_STATUS_ILLEGAL') {
            mutate();
            onClose();
          }
        }
        break;
    }
  }

  const handleReject = async () => {
    const { fnRemarks, bnRemarks } = form.getFieldsValue();
    switch (orderState) {
      case WITHDRAW_STATE.待客服審核:
        response = await ServiceReject({
          Id: id,
          FnRemark: fnRemarks,
          BnRemark: bnRemarks
        })
        if (response.State === RESPONSE_TYPE.成功) {
          message.success(i18n.t('operationSuccess'));
          mutate();
          onClose();
        } else {
          message.error(RESPONSE_CODE_AGENT_WITHDRAW[response.Message as keyof typeof RESPONSE_CODE_AGENT_WITHDRAW]);
          if (response.Message === 'TICKET_STATUS_ILLEGAL') {
            mutate();
            onClose();
          }
        }
        break;
      case WITHDRAW_STATE.待主管審核:
        response = await AuditorReject({
          Id: id,
          FnRemark: fnRemarks,
          BnRemark: bnRemarks
        })
        if (response.State === RESPONSE_TYPE.成功) {
          message.success(i18n.t('operationSuccess'));
          mutate();
          onClose();
        } else {
          message.error(RESPONSE_CODE_AGENT_WITHDRAW[response.Message as keyof typeof RESPONSE_CODE_AGENT_WITHDRAW]);
          if (response.Message === 'TICKET_STATUS_ILLEGAL') {
            mutate();
            onClose();
          }
        }
        break;
    }
  }

  const onClose = () => {
    close();
    form.resetFields();
  }

  return (
    <Modal title={i18n.t('audit')}
      open={isOpen}
      onCancel={onClose}
      centered={true}
      footer={false}
      width={900}
    >
      <Form form={form} onFinish={handleReject}>
        <Spin spinning={isValidating}>
          <Descriptions
            className="pb-1"
            bordered
            size='small'
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('order')}>
              {data?.Code}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('agentAccount')}>
              <Row align="middle" justify="space-between">
                <Col>{data?.AgentAccount}</Col>
                <Col><CopyButton text={data?.AgentAccount} /></Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('withdrawalAmount')}>
              {data && common.toFormatNumber(data?.Amount)}
            </Descriptions.Item>
            <Descriptions.Item label={''}>
            </Descriptions.Item>
          </Descriptions>

          <Descriptions
            className="pb-1"
            bordered
            size='small'
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('auditor')}>
              {data?.Status === WITHDRAW_STATE.待主管審核 &&
                <>{data?.ServiceAccount}</>}
              {data?.Status === WITHDRAW_STATE.待財務審核 &&
                <>{data?.AuditorAccount}</>}
              {data?.Status === WITHDRAW_STATE.通過 &&
                <>{data?.FinanceAccount}</>}
              {data?.Status === WITHDRAW_STATE.支付端等待支付 &&
                <>{data?.FinanceAccount}</>}
              {
                data?.Status === WITHDRAW_STATE.拒絕 &&
                (
                  <>{data?.ServiceAccount}</> ||
                  <>{data?.AuditorAccount}</> ||
                  <>{data?.FinanceAccount}</>
                )
              }
              {
                data?.Status === WITHDRAW_STATE.失敗 &&
                (
                  <>{data?.ServiceAccount}</> ||
                  <>{data?.AuditorAccount}</> ||
                  <>{data?.FinanceAccount}</>
                )
              }
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('status')}>
              <div className={common.numColor04(data?.Status)}>{WITHDRAW_STATE[data?.Status]}</div>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('createTime')}>{data?.CreateDate}</Descriptions.Item>
            <Descriptions.Item label={''}>
            </Descriptions.Item>
          </Descriptions>

          <Descriptions
            bordered
            size='small'
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('frontDeskRemarks')}>
              <Form.Item name="fnRemarks" rules={[{ required: true, message: `${i18n.t('rejectionRequiresFrontendRemark')}` }]}>
                <Input name="fnRemarks" placeholder={`${i18n.t('enter')}`} />
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('backOfficeRemarks')}>
              <Form.Item name="bnRemarks">
                <Input placeholder={`${i18n.t('enter')}`} />
              </Form.Item>
            </Descriptions.Item>
          </Descriptions>

          <Row className="mt-1" gutter={[12, 12]} align="middle" justify="center">
            <Col>
              <Button type="primary" danger htmlType="submit" >
                {i18n.t('reject')}
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={handlePass}>{i18n.t('pass')}</Button>
            </Col>
          </Row>
        </Spin>
      </Form>
    </Modal >
  )
}
// 代理財務出款審核
export const PopupAgentWithdrawWithdrawal = ({ isOpen, close, id, mutate, orderType, setErrorPopup, errorPopup }: any) => {
  const [form] = useForm();
  const [submitType, setSubmitType] = useState(VERIFICATION_STATE.通過);
  const [withdrawalChannel, setWithdrawalChannel] = useState<number>(0);
  const [cryptoParams, setCryptoParams] = useState({
    Crypto: null,
    CryptoType: null,
  });
  const [isSelected, setIsSelected] = useState(true);
  const { data, isValidating, mutate: RefreshInfo } = FinanceWithdrawInfo({ Id: id });

  useEffect(() => {
    if (isOpen) {
      RefreshInfo()
    }
  }, [isOpen]);
  useEffect(() => {
    if (data && data.Message === 'ANOTHER_FINANCE_IS_HANDLING') {
      close();
      mutate();
      setErrorPopup(true);
    }
  }, [data]);
  useEffect(() => {
    if (data) {
      setCryptoParams({
        Crypto: data.Crypto,
        CryptoType: data.CryptoType,
      })
    }
  }, [data]);
  useEffect(() => {
    if (data && isOpen) {
      form.setFieldsValue({
        bnRemarks: data.AuditorAccessLogRemark
      })
    }
  }, [data, isOpen]);

  const [range, setRange] = useState({
    min: 0,
    max: 0,
    fee: 0,
    rate: 0
  });
  const {
    data: Withdrawmerchant,
    isValidating: WithdrawmerchantLoading,
  } = GetWithdrawmerchant({
    IsOffline: withdrawalChannel,
    TicketId: id
  });
  const {
    data: Crypto,
    isValidating: CryptoLoading,
  } = GetCryptoList({
    Crypto: cryptoParams.Crypto,
    CryptoType: cryptoParams.CryptoType,
    TicketId: id,
  })

  const handleSelect = (selected: string) => {
    setIsSelected(false);
    switch (selected) {
      case WITHDRAW_TYPE.三方:
        setWithdrawalChannel(0);
        break;
      case WITHDRAW_TYPE.線下:
        setWithdrawalChannel(1);
        break;
    }
    form.setFieldsValue({
      withdrawalChannel: null
    })
    setRange({
      min: 0,
      max: 0,
      fee: 0,
      rate: 0,
    })
  }

  const onSelectChannel = (selected: number) => {
    Withdrawmerchant.filter((item: { Id: number }) => (
      item.Id === selected
    )).map((item: { Minimum: number, Maximum: number, HandlingFee: number, Rate: number }) => (
      setRange({ min: item.Minimum, max: item.Maximum, fee: item.HandlingFee, rate: item.Rate })
    ))
  }

  const onSelectCrypto = (selected: number) => {
    Crypto.filter((item: { WithdrawMerchantId: number }) => (
      item.WithdrawMerchantId === selected
    )).map((item: { Minimum: number, Maximum: number, HandlingFee: number, Rate: number }) => (
      setRange({ min: item.Minimum, max: item.Maximum, fee: item.HandlingFee, rate: item.Rate })
    ))
  }

  const handlePass = async () => {
    const { withdrawalType, withdrawalChannel, bnRemarks, crypto } = form.getFieldsValue();
    if (orderType === WITHDRAW.三方虛擬貨幣
      || orderType === WITHDRAW.線下虛擬貨幣) {
      if (!crypto) return setSubmitType(VERIFICATION_STATE.通過);
    } else {
      if (!withdrawalType && !withdrawalChannel) return setSubmitType(VERIFICATION_STATE.通過);
    }
    if (data.Amount > range.max) {
      return message.error(i18n.t('withdrawalAmountExceedsMaximumLimit'));
    }
    if (data.Amount < range.min) {
      return message.error(i18n.t('withdrawalAmountBelowMinimumLimit'));
    }
    const response = await FinancePass({
      Id: id,
      WithdrawMerchantId: withdrawalChannel || crypto,
      BnRemark: bnRemarks,
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      mutate();
      onClose();
    } else {
      message.error(RESPONSE_CODE_AGENT_WITHDRAW[response.Message as keyof typeof RESPONSE_CODE_AGENT_WITHDRAW]);
      if (response.Message === 'TICKET_STATUS_ILLEGAL') {
        mutate();
        onClose();
      }
    }
  }

  const handleReject = async () => {
    const { fnRemarks, bnRemarks } = form.getFieldsValue();
    if (!fnRemarks) return setSubmitType(VERIFICATION_STATE.拒絕);
    const response = await FinanceReject({
      Id: id,
      FnRemark: fnRemarks,
      BnRemark: bnRemarks
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      mutate();
      onClose();
    } else {
      message.error(RESPONSE_CODE_AGENT_WITHDRAW[response.Message as keyof typeof RESPONSE_CODE_AGENT_WITHDRAW]);
      if (response.Message === 'TICKET_STATUS_ILLEGAL') {
        mutate();
        onClose();
      }
    }
  }

  const onClose = () => {
    close();
    form.resetFields();
    setRange({
      min: 0,
      max: 0,
      fee: 0,
      rate: 0,
    })
  }

  return (
    <Modal title={i18n.t('withdrawal')}
      open={isOpen}
      onCancel={onClose}
      centered={true}
      width={900}
      footer={false}
    >
      <Form form={form}>
        <Spin spinning={isValidating}>
          <Descriptions
            className="pb-1"
            bordered
            size="small"
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('order')}>
              {data?.Code}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('agentAccount')}>
              <Row align="middle" justify="space-between">
                <Col>{data?.Account}</Col>
                <Col><CopyButton text={data?.Account} /></Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('withdrawalAmount')}>
              {common.toFormatNumber(data?.Amount)}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('totalActualWithdrawal')}>
              {common.toFormatNumber(data?.Amount)}
            </Descriptions.Item>
            {
              (orderType === WITHDRAW.三方虛擬貨幣 || orderType === WITHDRAW.線下虛擬貨幣)
                ?
                <Descriptions.Item label={i18n.t('currencyType')}>
                  {data?.Crypto}
                </Descriptions.Item>
                :
                <Descriptions.Item label={i18n.t('withdrawalType')}>
                  <Form.Item name="withdrawalType" rules={
                    submitType === VERIFICATION_STATE.通過 ?
                      [{ required: true, message: `${i18n.t('required')}` }]
                      : []
                  }>
                    <Select
                      onChange={handleSelect}
                      className="w-full"
                      placeholder={i18n.t('pleaseSelect')}
                      options={[
                        { value: WITHDRAW_TYPE.三方, label: i18n.t('thirdParty') },
                        { value: WITHDRAW_TYPE.線下, label: i18n.t('offlineMethod') },
                      ]}
                    />
                  </Form.Item>
                </Descriptions.Item>
            }
            {
              (orderType === WITHDRAW.三方虛擬貨幣 || orderType === WITHDRAW.線下虛擬貨幣)
                ?
                <Descriptions.Item label={i18n.t('networkType')}>
                  {data?.CryptoType}
                </Descriptions.Item>
                :
                <Descriptions.Item label={i18n.t('withdrawalChannel')}>
                  <Form.Item name="withdrawalChannel" rules={
                    submitType === VERIFICATION_STATE.通過 ?
                      [{ required: true, message: `${i18n.t('required')}` }]
                      : []
                  }>
                    <Select
                      loading={WithdrawmerchantLoading}
                      disabled={isSelected}
                      className="w-full"
                      onChange={onSelectChannel}
                      placeholder={i18n.t('pleaseSelect')}
                      options={
                        Withdrawmerchant ?
                          Withdrawmerchant.map((item: { Id: number, CustomName: string }) => (
                            { value: item.Id, label: `${item.CustomName}` }
                          )) : []
                      }
                    />
                  </Form.Item>
                </Descriptions.Item>
            }
            {
              (orderType === WITHDRAW.三方虛擬貨幣 || orderType === WITHDRAW.線下虛擬貨幣) &&
              <Descriptions.Item label={`${i18n.t('displayName')}-${i18n.t('address')}`}>
                <Form.Item name="crypto" rules={
                  submitType === VERIFICATION_STATE.通過 ?
                    [{ required: true, message: `${i18n.t('required')}` }]
                    : []
                }>
                  <Select
                    loading={CryptoLoading}
                    style={{ maxWidth: 200 }}
                    className="w-full"
                    placeholder={i18n.t('pleaseSelect')}
                    onChange={onSelectCrypto}
                    options={
                      Crypto ?
                        Crypto.map((item: { WithdrawMerchantId: number, CustomName: string, CryptoAddress: string }) => (
                          {
                            value: item.WithdrawMerchantId,
                            label: `${item.CustomName}-${item.CryptoAddress}`
                          }
                        )) : []
                    }
                  />
                </Form.Item>
              </Descriptions.Item>
            }
            <Descriptions.Item label={`${i18n.t('processingFee')}／${i18n.t('exchangeRate')}`}>
              {common.toFormatNumber((data?.Amount * range.rate) + range.fee)} / {
                data?.ExchangeRate || '-'}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('withdrawalAmountLimit')}>
              {common.toFormatNumber(range.min)} - {common.toFormatNumber(range.max)}
            </Descriptions.Item>
          </Descriptions>

          <Descriptions
            className="pb-1"
            bordered
            size="small"
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            {
              (orderType === WITHDRAW.三方虛擬貨幣 || orderType === WITHDRAW.線下虛擬貨幣)
                ? <>
                  <Descriptions.Item label={i18n.t('withdrawalAddress')} span={2}>
                    <Row align="middle" justify="space-between">
                      <Col>{data?.CryptoAddress}</Col>
                      <Col><CopyButton text={data?.CryptoAddress} /></Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('bankAccountName')}>
                    <Row align="middle" justify="space-between">
                      <Col>{data?.Account || '-'}</Col>
                      <Col><CopyButton text={data?.Account} /></Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('QR Code')}>
                    {
                      data && data.Photo && JSON.parse(data?.Photo)[0]
                        ? <Image src={data && data.Photo && JSON.parse(data?.Photo)[0]} height={100} />
                        : '-'
                    }
                  </Descriptions.Item>
                </>
                : <>
                  <Descriptions.Item label={i18n.t('bankName')}>
                    <Row align="middle" justify="space-between">
                      <Col>{data?.BankName}</Col>
                      <Col><CopyButton text={data?.BankName} /></Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('accountNumber')}>
                    <Row align="middle" justify="space-between">
                      <Col>{data?.Account}</Col>
                      <Col><CopyButton text={data?.Account} /></Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('openingBranch')}>
                    <Row align="middle" justify="space-between">
                      <Col>{data?.Branch}</Col>
                      <Col><CopyButton text={data?.Branch} /></Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('bankProvince')}>
                    <Row align="middle" justify="space-between">
                      <Col>{data?.State}</Col>
                      <Col><CopyButton text={data?.State} /></Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('city')}>
                    <Row align="middle" justify="space-between">
                      <Col>{data?.City}</Col>
                      <Col><CopyButton text={data?.City} /></Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('phoneNumber')}>
                    <Row align="middle" justify="space-between">
                      <Col>{`${data?.PhonePrefix}-${data?.PhoneNumber}`}</Col>
                      <Col><CopyButton text={`${data?.PhonePrefix}-${data?.PhoneNumber}`} /></Col>
                    </Row>
                  </Descriptions.Item>
                </>
            }
          </Descriptions>

          <Descriptions
            className="pb-1"
            bordered
            size="small"
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('auditor')}>
              {data?.Status === WITHDRAW_STATE.待主管審核 &&
                <>{data?.ServiceAccount}</>}
              {data?.Status === WITHDRAW_STATE.待財務審核 &&
                <>{data?.AuditorAccount}</>}
              {data?.Status === WITHDRAW_STATE.通過 &&
                <>{data?.FinanceAccount}</>}
              {data?.Status === WITHDRAW_STATE.支付端等待支付 &&
                <>{data?.FinanceAccount}</>}
              {
                data?.Status === WITHDRAW_STATE.拒絕 &&
                (
                  <>{data?.ServiceAccount}</> ||
                  <>{data?.AuditorAccount}</> ||
                  <>{data?.FinanceAccount}</>
                )
              }
              {
                data?.Status === WITHDRAW_STATE.失敗 &&
                (
                  <>{data?.ServiceAccount}</> ||
                  <>{data?.AuditorAccount}</> ||
                  <>{data?.FinanceAccount}</>
                )
              }
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('status')}>
              <div className={common.numColor04(data?.Status)}>{WITHDRAW_STATE[data?.Status]}</div>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('createTime')}>{data?.CreateDate}</Descriptions.Item>
          </Descriptions>

          <Descriptions
            bordered
            size="small"
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('frontDeskRemarks')}>
              <Form.Item name="fnRemarks" rules={
                submitType === VERIFICATION_STATE.拒絕 ?
                  [{ required: true, message: `${i18n.t('rejectionRequiresFrontendRemark')}` }]
                  : []
              }>
                <Input name="fnRemarks" placeholder={`${i18n.t('enter')}`} />
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('backOfficeRemarks')}>
              <Form.Item name="bnRemarks">
                <Input placeholder={`${i18n.t('enter')}`} />
              </Form.Item>
            </Descriptions.Item>
          </Descriptions>
          <Row gutter={[12, 12]} align="middle" justify="center" className="mt-1">
            <Col>
              <Button type="primary" danger htmlType="submit" onClick={handleReject}>{i18n.t('reject')}</Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit" onClick={handlePass}>{i18n.t('pass')}</Button>
            </Col>
          </Row>
        </Spin>
      </Form>
    </Modal>
  )
}
// 代理出款明細
export const PopupAgentWithdrawDetail = ({ isOpen, close, id, orderType, mutate }: any) => {
  const auth = useAuth();
  const { Account } = auth.getAdmin();
  const { data, isValidating, mutate: RefreshInfo } = AgentWithdrawInfo({ Id: id });
  useEffect(() => {
    if (isOpen) {
      RefreshInfo()
    }
  }, [isOpen]);

  const [withdrawalChannel, setWithdrawalChannel] = useState<number>(0);
  const [cryptoParams, setCryptoParams] = useState({
    Crypto: null,
    CryptoType: null,
  });

  useEffect(() => {
    if (data) {
      switch (data?.WithdrawMerchantGatewayCode) {
        case WITHDRAW.三方銀行卡:
        case WITHDRAW.三方虛擬貨幣:
          setWithdrawalChannel(0)
          break;
        case WITHDRAW.線下銀行卡:
        case WITHDRAW.線下虛擬貨幣:
          setWithdrawalChannel(1)
          break;
      }
    }
  }, [data])

  const {
    data: Withdrawmerchant,
    isValidating: WithdrawmerchantLoading,
  } = GetWithdrawmerchant({
    IsOffline: withdrawalChannel,
    TicketId: id
  });
  const {
    data: Crypto,
    isValidating: CryptoLoading,
  } = GetCryptoList({
    Crypto: cryptoParams.Crypto,
    CryptoType: cryptoParams.CryptoType,
    TicketId: id,
  })

  useEffect(() => {
    if (data) {
      setCryptoParams({
        Crypto: data.Crypto,
        CryptoType: data.CryptoType,
      })
    }
  }, [data]);

  const onReject = async () => {
    const response = await FinanceReject({
      Id: data.Id,
      FnRemark: data.FnRemark,
      BnRemark: data.FinanceAccessLogRemark
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      mutate();
      close();
    } else {
      message.error(RESPONSE_CODE_AGENT_WITHDRAW[response.Message as keyof typeof RESPONSE_CODE_AGENT_WITHDRAW]);
    }
  }

  return (
    <Modal
      title={i18n.t('detail')}
      open={isOpen}
      onCancel={close}
      centered={true}
      width={900}
      footer={
        <Row align="middle" justify="center" gutter={[12, 12]}>
          <Col>
            <Button type="primary" onClick={close}>{i18n.t('close')}</Button>
          </Col>
          {
            (data?.Status === WITHDRAW_STATE.支付端等待支付 ||
              data?.Status === WITHDRAW_STATE.失敗) && (data?.FinanceAccount === Account) &&
            <Col>
              <Button type="primary" danger onClick={onReject}>{i18n.t('forceFailure')}</Button>
            </Col>
          }
        </Row>
      }
    >
      <Spin spinning={isValidating}>
        {/* 訂單資訊 */}
        <Descriptions
          className="pb-1"
          bordered
          size={'small'}
          column={2}
          labelStyle={{ width: '18%' }}
          contentStyle={{ width: '32%' }}
        >
          <Descriptions.Item label={i18n.t('order')}>
            {data?.Code}
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('agentAccount')}>
            <Row align="middle" justify="space-between">
              <Col>{data?.AgentAccount}</Col>
              <Col><CopyButton text={data?.AgentAccount} /></Col>
            </Row>
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('withdrawalAmount')}>
            {common.toFormatNumber(data?.Amount)}
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('totalActualWithdrawal')}>
            {common.toFormatNumber(data?.Amount)}
          </Descriptions.Item>
          {
            (orderType === WITHDRAW.線下虛擬貨幣 || orderType === WITHDRAW.三方虛擬貨幣)
              ?
              <>
                <Descriptions.Item label={i18n.t('currencyType')}>
                  {data?.Crypto}
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('networkType')}>
                  {data?.CryptoType}
                </Descriptions.Item>
              </>
              : <>
                <Descriptions.Item label={i18n.t('withdrawalType')}>
                  <Select
                    disabled
                    className="w-full"
                    placeholder={i18n.t('pleaseSelect')}
                    value={data?.WithdrawMerchantGatewayCode}
                    options={[
                      { value: WITHDRAW.三方虛擬貨幣, label: i18n.t('thirdParty') },
                      { value: WITHDRAW.三方銀行卡, label: i18n.t('thirdParty') },
                      { value: WITHDRAW.線下虛擬貨幣, label: i18n.t('offlineMethod') },
                      { value: WITHDRAW.線下銀行卡, label: i18n.t('offlineMethod') },
                    ]}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('withdrawalChannel')}>
                  <Select
                    loading={WithdrawmerchantLoading}
                    disabled
                    className="w-full"
                    placeholder={i18n.t('pleaseSelect')}
                    value={data?.WithdrawMerchantId}
                    options={
                      Withdrawmerchant ?
                        Withdrawmerchant.map((item: { Id: number, CustomName: string }) => (
                          { value: item.Id, label: `${item.CustomName}` }
                        )) : []
                    }
                  />
                </Descriptions.Item>
              </>
          }
          {
            (orderType === WITHDRAW.線下虛擬貨幣 || orderType === WITHDRAW.三方虛擬貨幣)
            && <Descriptions.Item label={`${i18n.t('displayName')}-${i18n.t('address')}`}>
              <Form.Item name="crypto">
                <Select
                  disabled
                  loading={CryptoLoading}
                  style={{ maxWidth: 200 }}
                  className="w-full"
                  placeholder={i18n.t('pleaseSelect')}
                  defaultValue={data?.WithdrawMerchantId}
                  options={
                    Crypto ?
                      Crypto.map((item: { WithdrawMerchantId: number, CustomName: string, CryptoAddress: string }) => (
                        {
                          value: item.WithdrawMerchantId,
                          label: `${item.CustomName}-${item.CryptoAddress}`
                        }
                      )) : []
                  }
                />
              </Form.Item>
            </Descriptions.Item>
          }
          <Descriptions.Item label={`${i18n.t('processingFee')}／${i18n.t('exchangeRate')}`}>
            {common.toFormatNumber(data?.HandlingFee)}／{common.toFormatNumber(data?.ExchangeRate)}
          </Descriptions.Item>
        </Descriptions>

        {/* 提款資訊 */}
        <Descriptions
          className="pb-1"
          bordered
          size={'small'}
          column={2}
          labelStyle={{ width: '18%' }}
          contentStyle={{ width: '32%' }}
        >
          {
            (orderType === WITHDRAW.線下虛擬貨幣 || orderType === WITHDRAW.三方虛擬貨幣)
              ? <>
                <Descriptions.Item label={i18n.t('withdrawalAddress')} span={2}>
                  <Row align="middle" justify="space-between">
                    <Col>{data?.CryptoAddress}</Col>
                    <Col><CopyButton text={data?.CryptoAddress} /></Col>
                  </Row>
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('bankAccountName')}>
                  <Row align="middle" justify="space-between">
                    <Col>{data?.Account || '-'}</Col>
                    <Col><CopyButton text={data?.Account} /></Col>
                  </Row>
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('QR Code')}>
                  {
                    data && data.Photo && JSON.parse(data?.Photo)[0]
                      ? <Image src={data && data.Photo && JSON.parse(data?.Photo)[0]} height={100} />
                      : '-'
                  }
                </Descriptions.Item>
              </>
              : <>
                <Descriptions.Item label={i18n.t('bankName')}>
                  <Row align="middle" justify="space-between">
                    <Col>{data?.BankName}</Col>
                    <Col><CopyButton text={data?.BankName} /></Col>
                  </Row>
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('accountNumber')}>
                  <Row align="middle" justify="space-between">
                    <Col>{data?.Account}</Col>
                    <Col><CopyButton text={data?.Account} /></Col>
                  </Row>
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('branchName')}>
                  <Row align="middle" justify="space-between">
                    <Col>{data?.Branch}</Col>
                    <Col><CopyButton text={data?.Branch} /></Col>
                  </Row>
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('bankProvince')}>
                  <Row align="middle" justify="space-between">
                    <Col>{data?.State || '-'}</Col>
                    <Col><CopyButton text={data?.State} /></Col>
                  </Row>
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('city')}>
                  <Row align="middle" justify="space-between">
                    <Col>{data?.City || '-'}</Col>
                    <Col><CopyButton text={data?.City} /></Col>
                  </Row>
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('phoneNumber')}>
                  <Row align="middle" justify="space-between">
                    <Col>{`${data?.PhonePrefix}-${data?.PhoneNumber}`}</Col>
                    <Col><CopyButton text={`${data?.PhonePrefix}-${data?.PhoneNumber}`} /></Col>
                  </Row>
                </Descriptions.Item>
              </>
          }
        </Descriptions>

        {/* 審核 */}
        <Descriptions
          className="pb-1"
          bordered
          size={'small'}
          column={2}
          labelStyle={{ width: '18%' }}
          contentStyle={{ width: '32%' }}
        >
          <Descriptions.Item label={i18n.t('auditor')}>
            {data?.Status === WITHDRAW_STATE.待主管審核 &&
              <>{data?.ServiceAccount}</>}
            {data?.Status === WITHDRAW_STATE.待財務審核 &&
              <>{data?.AuditorAccount}</>}
            {data?.Status === WITHDRAW_STATE.通過 &&
              <>{data?.FinanceAccount}</>}
            {data?.Status === WITHDRAW_STATE.支付端等待支付 &&
              <>{data?.FinanceAccount}</>}
            {data?.Status === WITHDRAW_STATE.拒絕 && (
              <>{data?.ServiceAccount}</> ||
              <>{data?.AuditorAccount}</> ||
              <>{data?.FinanceAccount}</>
            )}
            {data?.Status === WITHDRAW_STATE.失敗 && (
              <>{data?.ServiceAccount}</> ||
              <>{data?.AuditorAccount}</> ||
              <>{data?.FinanceAccount}</>
            )}
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('status')}>
            <div className={common.numColor04(data?.Status)}>{WITHDRAW_STATE[data?.Status]}</div>
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('createTime')}>
            {data?.CreateDate}
          </Descriptions.Item>
        </Descriptions>

        {/* 備註 */}
        <Descriptions
          bordered
          size={'small'}
          column={2}
          labelStyle={{ width: '18%' }}
          contentStyle={{ width: '32%' }}
        >
          <Descriptions.Item label={i18n.t('frontDeskRemarks')}>
            <Input value={data?.FnRemark} placeholder={`${i18n.t('enter')}`} disabled />
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('backOfficeRemarks')}>
            <Input value={data && (data.FinanceAccessLogRemark || data.AuditorAccessLogRemark || data.ServiceAccessLogRemark)} placeholder={`${i18n.t('enter')}`} disabled />
          </Descriptions.Item>
        </Descriptions>
      </Spin>
    </Modal>
  )
}
