import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Button, Col, DatePicker, DatePickerProps, Form, Input, InputNumber, Row, Select, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { Content } from "antd/es/layout/layout";
import { LayoutNav } from "components/layout.component";
import { RESPONSE_CODE_CREATE_ANNOUNCEMENT } from "constants/response";
import dayjs, { Dayjs } from "dayjs";
import { RESPONSE_TYPE } from "enum/state";
import i18n from "i18n";
import Cookies from "js-cookie";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PostContentsSystemCreate } from "services/contents.service";
import { verify } from "utils/common";

const PageMain = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const initialValues = {
    sort: 0,
    status: 1,
    publicationTime: dayjs()
  }
  const [editorContent, setEditorContent] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState(false);

  const onDateOk: DatePickerProps['onOk'] = (date) => {
    if (date.get('D') === dayjs().get('D') &&
      (date.get('hours') < dayjs().get('hours') || date.get('minutes') < dayjs().get('minutes'))) {
      const resetDate = dayjs().set('years', date.year()).set('D', dayjs().get('D')).set('hours', dayjs().get('hours')).set('minutes', dayjs().get('minutes'));
      form.setFieldValue('publicationTime', dayjs(resetDate));
    }
  }

  const onFinish = async (formData: any) => {
    const Lang = Cookies.get('lang')
    const response = await PostContentsSystemCreate({
      Lang,
      Title: formData.title,
      Content: editorContent,
      Position: formData.sort,
      Status: formData.status,
      PublishDate: dayjs(formData.publicationTime).format('YYYY-MM-DD HH:mm:00')
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('addSuccess'));
      onCancel();
      // setIsDisabled(true);
    } else {
      message.error(RESPONSE_CODE_CREATE_ANNOUNCEMENT[response.Message as keyof typeof RESPONSE_CODE_CREATE_ANNOUNCEMENT]);
    }
  }

  const onCancel = () => {
    form.resetFields();
    navigate('/advanced/announcement_system');
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish} initialValues={initialValues}
          layout="vertical">
          <Row gutter={[12, 8]}>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col>
                  <Form.Item name="title" label={i18n.t('title')} rules={[
                    { required: true },
                    { max: 100, message: `${i18n.t('exceedsCharacterLimit100')}` }
                  ]}>
                    <Input className="w-18" placeholder={`${i18n.t('enter')}`} disabled={isDisabled} />
                  </Form.Item>
                </Col>
                <Col>
                  <Row align="middle" gutter={[12, 12]}>
                    {
                      !isDisabled &&
                      <Col>
                        <Button onClick={onCancel}>{i18n.t('cancel')}</Button>
                      </Col>
                    }
                    {
                      isDisabled
                        ?
                        <Col>
                          <Button onClick={onCancel}>{i18n.t('close')}</Button>
                        </Col>
                        :
                        <Col>
                          <Button type="primary" htmlType="submit">{i18n.t('add')}</Button>
                        </Col>
                    }
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Form.Item name="sort" label={i18n.t('sort')} rules={verify({ point: 0 })}>
                <InputNumber className="w-12" placeholder={`${i18n.t('enter')}`} disabled={isDisabled} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="status" label={i18n.t('status')} rules={[{ required: true }]} className="w-12">
                <Select
                  disabled={isDisabled}
                  placeholder={i18n.t('pleaseSelect')}
                  options={[
                    { value: 1, label: i18n.t('enabled') },
                    { value: 0, label: i18n.t('disable') },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item className="w-12" name="publicationTime" label={i18n.t('publicationTime')}
                rules={[{ required: true }]}>
                <DatePicker
                  disabled={isDisabled}
                  disabledDate={(current: Dayjs) => (current < dayjs().startOf('D'))}
                  disabledTime={(date) => {
                    const today = date?.date() !== dayjs().date();
                    const hours = [...Array(24).keys()];
                    const minutes = [...Array(60).keys()];
                    if (today) {
                      return {
                        disabledHours: () => [],
                        disabledMinutes: () => [],
                      }
                    } else {
                      return {
                        disabledHours: () => hours.filter(h => h < dayjs().hour()),
                        disabledMinutes: () => minutes.filter(m => m < dayjs().minute()),
                      }
                    }
                  }}
                  showTime={{ format: 'HH:mm' }}
                  className="w-full"
                  format={"YYYY-MM-DD HH:mm:00"}
                  onOk={onDateOk}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="internalContent" label={i18n.t('internalContent')} rules={[{ required: true }]}>
                <CKEditor
                  disabled={isDisabled}
                  editor={ClassicEditor}
                  onReady={editor => {

                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                  }}
                  onBlur={(event, editor) => {
                    const data = editor.getData();
                    setEditorContent(data);
                  }}
                  onFocus={(event, editor) => {

                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>
    </div >
  )
}

export default PageMain;