import {
  axiosGetAllData,
  axiosGetAuth,
  axiosPostAuth,
  baseURL,
  useFetchDataCommon
} from "services";

export const GetMailList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/mail/list`, params);
export const FetchMailDetail = (params: any, allow: any) => useFetchDataCommon(axiosGetAuth, `${baseURL}/mail/one`, params, allow);

export const PostMailCreate = (data: any) => axiosPostAuth(`${baseURL}/mail/create`, data);
