import { Button, Col, Form, Image, Input, InputNumber, Modal, Row, Select, Space, Switch, Table, UploadFile, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { RESPONSE_CODE, RESPONSE_CODE_CREATE_ANNOUNCEMENT, RESPONSE_CODE_GAME, RESPONSE_CODE_LICENSE, RESPONSE_CODE_MAINTENANCE, RESPONSE_CODE_PERMISSION } from 'constants/response';
import dayjs, { Dayjs } from 'dayjs';
import { DATE_FORMAT } from 'enum/date';
import { ENABLE, PERMISSION_STATE, RESPONSE_TYPE } from 'enum/state';
import useIcon from 'hooks/icon.hook';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateAccount, PostAccountUpdate, PostPasswordReset } from 'services/account.service';
import { CreateLicense, DeleteLicense, PostContentsDelete, UpdateLicense } from 'services/contents.service';
import { GameUpdateForCC } from 'services/game.service';
import { PostPermissionCreate, PostPermissionUpdate } from 'services/permission.service';
import { ProviderOpenList } from 'services/provider.service';
import { PostProvidermaintaintenanceCreate, PostProvidermaintaintenanceDelete, PostProvidermaintaintenanceUpdate } from 'services/providermaintaintenance.service';
import { SystemInfo } from 'services/systemsetting.service';
import { enumToOptions, verify } from 'utils/common';
import { LayoutDateSelect, UploadImage } from './layout.component';

// 權限
export const ViewPermissionList = ({ data, setParams, params, mutate }: any) => {
  const { permissionCode: $p } = usePerm();
  const navigate = useNavigate();

  const update = async (item: any) => {
    const response = await PostPermissionUpdate({
      Id: item.Id,
      Name: item.Name,
      Remark: item.Remark,
      Status: item.Status === 1 ? 0 : 1,
      PermissionCode: JSON.parse(item.PermissionCode),
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('editSuccess'));
      mutate();
    } else {
      if (JSON.parse(item.PermissionCode).length === 0) {
        message.error(i18n.t('permissionsNotChecked'));
      } else {
        const msg = RESPONSE_CODE[response.Message as keyof typeof RESPONSE_CODE];
        message.error(msg || i18n.t('processingFailed'));
      }
    }
  };

  return (
    <Table
      size="middle"
      scroll={{ x: 1500 }}
      dataSource={data?.Data}
      columns={[
        {
          key: 1,
          className: 'size-12',
          dataIndex: 'Name',
          title: i18n.t('permissionName'),
          width: 300,
          fixed: 'left'
        },
        {
          key: 2,
          dataIndex: 'Remark',
          title: i18n.t('description'),
          className: 'size-12',
          width: 350,
          render: (val) => val || '-'
        },
        {
          key: 3,
          dataIndex: 'Status',
          title: i18n.t('status'),
          className: 'size-12',
          align: 'center',
          width: 100,
          render: (val, record) => <Switch checked={val === PERMISSION_STATE.啟用} onChange={v => update(record)} />
        },
        {
          key: 4,
          dataIndex: 'CreateDate',
          title: i18n.t('createTime'),
          className: 'size-12',
          width: 200,
        },
        {
          key: 5,
          dataIndex: 'LastModifyAccount',
          title: i18n.t('updater'),
          className: 'size-12',
          width: 200,
        },
        {
          key: 6,
          dataIndex: 'UpdateDate',
          title: i18n.t('updateTime'),
          className: 'size-12',
          width: 200,
        },
        {
          key: 7,
          title: i18n.t('operation'),
          className: 'size-12',
          width: 150,
          fixed: 'right',
          render: (_, { Id }) => (
            <>
              {$p.includes('00704') && <Button className="size-12" type="link"
                onClick={() => navigate(`/advanced/permission_name_edit/${Id}`)}
              >{i18n.t('edit')}</Button>}
              {$p.includes('00801') && <Button className="size-12" type="link"
                onClick={() => navigate(`/advanced/permission_backOfficeAccount/${Id}`)}
              >{i18n.t('userAccount')}</Button>}
            </>
          )
        },
      ]}
      pagination={{
        showSizeChanger: true,
        showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
        total: data?.TotalRecord,
        pageSize: params.PageSize
      }}
      onChange={p => setParams({ ...params, PageIndex: p.current, PageSize: p.pageSize })}
    />
  )
}
export const PopupAddPermission = ({ isOpen, close, mutate }: any) => {
  const [form] = useForm();

  const onFinish = async (formData: any) => {

    const response = await PostPermissionCreate({
      Name: formData.Name,
      Remark: formData.Remark,
      Status: formData.Status,
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('addSuccess'));
      onClose();
      mutate();
    } else {
      const msg = RESPONSE_CODE[response.Message as keyof typeof RESPONSE_CODE];
      message.error(msg || i18n.t('processingFailed'));
    }

  };

  const onClose = () => {
    close();
    form.resetFields();
  }

  return (
    <Modal
      title={i18n.t('add')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button className="mt-1" key="cancel" onClick={onClose}>{i18n.t('cancel')}</Button>
          <Button className="mt-1" key="confirm" type="primary" onClick={form.submit}>{i18n.t('confirm')}</Button>
        </Row>
      }
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item name="Name" label={i18n.t('permissionName')} rules={[{ required: true }]}>
          <Input placeholder={`${i18n.t('enter')}`} />
        </Form.Item>
        <Form.Item name="Remark" label={i18n.t('description')}>
          <Input placeholder={`${i18n.t('enter')}`} />
        </Form.Item>
        <Form.Item name="Status" label={i18n.t('status')} rules={[{ required: true }]}>
          <Select
            className="w-12"
            placeholder={`${i18n.t('pleaseSelect')}`}
            options={enumToOptions(PERMISSION_STATE)}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

// 後台帳號
export const ViewPermissionBackOffice = ({ accountList, mutate, setParams, params }: any) => {
  const { permissionCode: $p } = usePerm();
  const [singleAccount, setSingleAccount] = useState({});
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenPassword, setIsOpenPassword] = useState(false);

  const handleEdit = (data: any) => {
    setIsOpenEdit(true);
    setSingleAccount(data);
  }

  const handlePassword = (data: any) => {
    setIsOpenPassword(true);
    setSingleAccount(data);
  }

  const handleEditState = async (record: any) => {
    const response = await PostAccountUpdate({
      Id: record.Id,
      Name: record.Name,
      Status: record.Status === 1 ? 0 : 1,
      Remark: record.Remark,
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      mutate();
    } else {
      const msg = RESPONSE_CODE[response.Message as keyof typeof RESPONSE_CODE];
      message.error(msg || i18n.t('processingFailed'));
    }
  }

  return (
    <>
      <Table
        size="middle"
        scroll={{ x: 1500 }}
        dataSource={accountList?.Data.map((item: any) => ({ key: item.Id, ...item }))}
        columns={[
          {
            key: 1,
            dataIndex: 'PermissionName',
            title: i18n.t('permissionName'),
            className: 'size-12',
            fixed: 'left',
            width: 180
          },
          {
            key: 2,
            dataIndex: 'Account',
            title: i18n.t('backOfficeAccount'),
            className: 'size-12',
            fixed: 'left',
            width: 180
          },
          {
            key: 31,
            dataIndex: 'Name',
            title: i18n.t('nameTitle'),
            className: 'size-12',
            width: 180,
            render: (val) => val || '-'
          },
          {
            key: 3,
            dataIndex: 'Remark',
            title: i18n.t('description'),
            className: 'size-12',
            width: 500,
            render: (val) => val || '-'
          },
          {
            key: 4,
            dataIndex: 'Status',
            title: i18n.t('status'),
            className: 'size-12',
            align: 'center',
            width: 100,
            render: (val, record) =>
              <Switch checked={val === PERMISSION_STATE.啟用} onChange={v => handleEditState(record)} />
          },
          {
            key: 5,
            dataIndex: 'CreateDate',
            title: i18n.t('createTime'),
            className: 'size-12',
            width: 150,
          },
          {
            key: 6,
            dataIndex: 'LastModifyAccount',
            title: i18n.t('updater'),
            className: 'size-12',
            width: 200,
            render: (val) => val || '-'
          },
          {
            key: 7,
            dataIndex: 'UpdateDate',
            title: i18n.t('updateTime'),
            className: 'size-12',
            width: 150,
          },
          {
            key: 8,
            title: i18n.t('operation'),
            className: 'size-12',
            width: 150,
            fixed: 'right',
            render: (_, record) => (
              <>
                {$p.includes('00803') && <Button className="size-12" type="link"
                  onClick={() => handleEdit(record)}>{i18n.t('edit')}</Button>}
                {$p.includes('00804') && <Button className="size-12" type="link"
                  onClick={() => handlePassword(record)}>{i18n.t('changePassword')}</Button>}
              </>
            )
          },
        ]}
        pagination={{
          showSizeChanger: true,
          showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
          total: accountList?.TotalRecord,
          pageSize: params.PageSize
        }}
        onChange={p => setParams({ ...params, PageIndex: p.current, PageSize: p.pageSize })}
      />
      <PopupEditBackOfficeAccount isOpen={isOpenEdit} close={() => setIsOpenEdit(false)} singleAccount={singleAccount} mutate={mutate} />
      <PopupBackOfficeAccountPassword isOpen={isOpenPassword} close={() => setIsOpenPassword(false)} singleAccount={singleAccount} mutate={mutate} />
    </>
  )
}
export const PopupAddBackOfficeAccount = ({ isOpen, close, mutate, permissionList }: any) => {
  const [form] = useForm();

  const onFinish = async (formData: any) => {
    if (formData.Password !== formData.TransactionPassword) {
      return message.error(i18n.t('incorrectPasswordConfirmation'))
    }
    const response = await CreateAccount({
      Account: formData.Account,
      Name: formData.Name,
      Password: formData.Password,
      TransactionPassword: formData.TransactionPassword,
      Remark: formData.Remark,
      PermissionId: `${formData.PermissionId}`,
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      onClose();
      mutate();
    } else {
      message.error(RESPONSE_CODE_PERMISSION[response.Message as keyof typeof RESPONSE_CODE_PERMISSION]);
    }
  }

  const onClose = () => {
    form.resetFields();
    close();
  }

  return (
    <Modal
      title={i18n.t('add')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={
        <Row justify="center" className='mt-1'>
          <Button key="cancel" onClick={onClose}>{i18n.t('cancel')}</Button>
          <Button key="confirm" type="primary" onClick={form.submit}>{i18n.t('confirm')}</Button>
        </Row>
      }
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="Account" label={i18n.t('backOfficeAccount')}
              rules={[...verify({ point: 5 }), { type: 'string', min: 4, max: 12 }]} >
              <Input placeholder={`${i18n.t('englishAndNumbersWithin4To12Characters')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Name" label={i18n.t('nameTitle')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Password" label={i18n.t('password')}
              rules={[...verify({ point: 6 }), { type: 'string', min: 4, max: 20 }]}>
              <Input.Password placeholder={`${i18n.t('supportSpecialCharacters')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="TransactionPassword" label={i18n.t('confirmNewPassword')}
              rules={[...verify({ point: 6 }), { type: 'string', min: 4, max: 20 }]}>
              <Input.Password placeholder={`${i18n.t('supportSpecialCharacters')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Remark" label={i18n.t('description')}>
              <Input placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="PermissionId" label={i18n.t('selectPermissions')} rules={[{ required: true }]}>
              <Select
                className="w-12"
                placeholder={`${i18n.t('pleaseSelect')}`}
                options={permissionList?.Data.map((item: any) => ({
                  value: item.Id, label: item.Name
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
export const PopupEditBackOfficeAccount = ({ isOpen, close, singleAccount, mutate }: any) => {
  const [form] = useForm();

  useEffect(() => {
    if (singleAccount && isOpen) {
      form.setFieldsValue({
        Account: singleAccount.Account,
        Status: singleAccount.Status,
        Remark: singleAccount.Remark,
        Name: singleAccount.Name,
      })
    }
  }, [singleAccount, isOpen])

  const onFinish = async (formData: any) => {
    try {
      const response = await PostAccountUpdate({
        Id: singleAccount.Id,
        Status: formData.Status,
        Remark: formData.Remark,
        Name: formData.Name,
      })
      if (response.State === RESPONSE_TYPE.成功) {
        message.success(i18n.t('operationSuccess'));
        mutate();
        onClose();
      } else {
        message.error(RESPONSE_CODE_PERMISSION[response.Message as keyof typeof RESPONSE_CODE_PERMISSION]);
      }
    } catch (error) {
      message.error(i18n.t('operationError'));
      return
    }
  }

  const onClose = () => {
    form.resetFields();
    close();
  }

  return (
    <Modal
      title={i18n.t('edit')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item name="Account" label={i18n.t('backOfficeAccount')} rules={[{ required: true }]}>
          <Input disabled />
        </Form.Item>
        <Form.Item name="Name" label={i18n.t('nameTitle')} rules={[{ required: true }]}>
          <Input placeholder={`${i18n.t('enter')}`} />
        </Form.Item>
        <Form.Item name="Remark" label={i18n.t('description')}>
          <Input placeholder={`${i18n.t('enter')}`} />
        </Form.Item>
        <Form.Item name="Status" label={i18n.t('status')} rules={[{ required: true }]}>
          <Select
            className="w-12"
            placeholder={`${i18n.t('pleaseSelect')}`}
            options={enumToOptions(PERMISSION_STATE)}
          />
        </Form.Item>
        <Form.Item>
          <Row justify="center" gutter={[12, 12]}>
            <Col>
              <Button className="mt-1" key="cancel" onClick={onClose}>{i18n.t('cancel')}</Button>
            </Col>
            <Col>
              <Button className="mt-1" key="confirm" htmlType="submit" type="primary">{i18n.t('confirm')}</Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  )
}
export const PopupBackOfficeAccountPassword = ({ isOpen, close, singleAccount, mutate }: any) => {
  const [form] = useForm();

  useEffect(() => {
    if (singleAccount && isOpen) {
      form.setFieldsValue({
        account: singleAccount.Account,
      })
    }
  }, [singleAccount, isOpen])

  const onFinish = async (formData: any) => {
    if (formData.password !== formData.confirmNewPassword) {
      return message.error(i18n.t('incorrectPasswordConfirmation'))
    }
    try {
      const response = await PostPasswordReset({
        Id: singleAccount.Id,
        // Name: singleAccount.Account,
        Password: formData.password,
        ConfirmPassword: formData.confirmNewPassword,
        // PermissionId: singleAccount.PermissionId,
        // Status: singleAccount.Status
      })
      if (response.State === RESPONSE_TYPE.成功) {
        message.success(i18n.t('operationSuccess'));
        onCancel();
        mutate();
      } else {
        message.error(RESPONSE_CODE_PERMISSION[response.Message as keyof typeof RESPONSE_CODE_PERMISSION]);
      }
    } catch (error) {
      message.error(i18n.t('operationError'));
      return
    }
  }

  const onCancel = () => {
    form.resetFields();
    close();
  }

  return (
    <Modal
      title={i18n.t('changePassword')}
      open={isOpen}
      onCancel={onCancel}
      centered
      width={450}
      footer={false}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item name="account" label={i18n.t('backOfficeAccount')} rules={[{ required: true }]}>
          <Input disabled />
        </Form.Item>
        <Form.Item name="password" label={i18n.t('password')} rules={[
          { required: true, message: `${i18n.t('required')}` },
          { min: 4, message: `${i18n.t('supportSpecialCharacters')}` },
          { max: 20, message: `${i18n.t('supportSpecialCharacters')}` },
        ]}>
          <Input.Password placeholder={`${i18n.t('supportSpecialCharacters')}`} />
        </Form.Item>
        <Form.Item name="confirmNewPassword" label={i18n.t('confirmNewPassword')} rules={[
          { required: true, message: `${i18n.t('required')}` },
          { min: 4, message: `${i18n.t('supportSpecialCharacters')}` },
          { max: 20, message: `${i18n.t('supportSpecialCharacters')}` },
        ]}>
          <Input.Password placeholder={`${i18n.t('supportSpecialCharacters')}`} />
        </Form.Item>
        <Row justify="center" gutter={[12, 12]}>
          <Col>
            <Form.Item>
              <Button className="mt-1" key="cancel" onClick={onCancel}>{i18n.t('cancel')}</Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button htmlType="submit" className="mt-1" key="confirm" type="primary">{i18n.t('confirm')}</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

interface GameType {
  Id: number;
  Name: string;
  Lang: string;
  Photo: string;
  CategoryId: number;
  CategoryCode: string;
  ProviderId: number;
  ProviderCode: string;
  Code: string;
  FullCode: string;
  Status: number;
  CreateDate: string;
}

interface ProviderType {
  Id: number;
  CategoryId: number;
  CategoryCode: string;
  ProviderName: string;
  ProviderCode: string;
  Status: number;
  Account: string;
  UpdateDate: string;
}

export const ViewGameList = ({ data, mutate }: {
  data: {
    State: string;
    TotalRecord: number;
    Data: GameType[]
  };
  mutate: () => void;
}) => {
  const { permissionCode: $p } = usePerm();

  const icon = useIcon();
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState('');
  const { data: GameUrl } = SystemInfo();

  useEffect(() => {
    if (GameUrl) {
      setUrl(GameUrl.GAME_ICON_URI);
    }
  }, [GameUrl]);

  const handleEditStatus = async (Id: number, state: boolean) => {
    setIsLoading(true);
    const response = await GameUpdateForCC({
      Id,
      Status: state ? 1 : 0,
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      mutate();
    } else {
      message.error(RESPONSE_CODE_GAME[response.Message as keyof typeof RESPONSE_CODE_GAME]);
    }
    setIsLoading(false);
  }

  return (
    <>
      <Table
        size="small"
        scroll={{ x: 1500 }}
        dataSource={data?.Data?.map(item => ({ key: item.Id, ...item }))}
        columns={[
          {
            dataIndex: 'FullCode',
            title: i18n.t('ID'),
            width: 350,
            fixed: 'left',
          },
          {
            dataIndex: 'CategoryCode',
            title: i18n.t('gameCategory'),
            width: 200,
            render: (val) => i18n.t(val)
          },
          {
            dataIndex: 'Lang',
            title: i18n.t('gameName'),
            width: 350,
            render: (val, { Name }) => {
              const UserLang = Cookies.get('lang');
              const LangNameAry = JSON.parse(val);
              const DefaultName = Name;
              const foundItem = LangNameAry.find((item: { lang: string }) => item.lang === UserLang);
              const displayedName = foundItem ? foundItem.name : DefaultName;
              return <>{displayedName}</>
            }
          },
          {
            dataIndex: 'Photo',
            title: i18n.t('Logo'),
            width: 200,
            render: (_, { ProviderCode, Code }) => {
              const UserLang = Cookies.get('lang');
              const GameLogo = `${url}${UserLang}/${ProviderCode}/${Code}.png`
              return (
                <Image src={GameLogo} fallback={icon.systemGameLogo} width={50} height={50} />
              )
            }
          },
          {
            dataIndex: 'Status',
            title: i18n.t('status'),
            width: 150,
            render: (val, record) =>
              <Switch checked={val} loading={isLoading} onChange={v => handleEditStatus(record.key, v)} disabled={!$p.includes('01202')} />
          },
          {
            dataIndex: 'CreateDate',
            title: i18n.t('createTime'),
            width: 200
          },
        ]}
        pagination={false}
      />
    </>
  )
}

export const PopupAddLicense = ({ isOpen, close, mutate }: any) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);

  const onFinish = async (formData: any) => {
    setLoading(true);

    const response = await CreateLicense({
      Lang: Cookies.get('lang'),
      Title: formData.title,
      Photo_1: imageData[0] ? imageData[0].url : '',
      LinkURL: formData.link,
      Position: formData.sort,
      Status: formData.status
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('addSuccess'));
      onClose();
      mutate();
    } else {
      message.error(RESPONSE_CODE_LICENSE[response.Message as keyof typeof RESPONSE_CODE_LICENSE]);
    }

    setLoading(false);
  }

  const onClose = () => {
    close();
    setImageData([]);
    form.resetFields();
  }

  return (
    <Modal
      title={i18n.t('add')}
      centered
      open={isOpen}
      onCancel={onClose}
      width={450}
      footer={false}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}
        initialValues={{
          status: 1,
        }}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="title" label={i18n.t('title')}
              rules={[{ required: true, type: 'string', max: 100 }]}>
              <Input placeholder={`${i18n.t('oneHundredCharacters')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Photo_1" valuePropName="fileList" label={i18n.t('image')} rules={[{ required: !imageData[0] }]}>
              <UploadImage
                name="Photo_1"
                url={'/upload/images/public/contents'}
                imageData={imageData}
                setImageData={setImageData}
                form={form}
                crop
                w={140}
                h={50}
              />
            </Form.Item>
            <div className="color-03 size-12">{i18n.t('onlyJpgOrPngFiles')}
              <span className="require">{i18n.t('imageMobileVersionWidth140pxHeight50px')}</span>
              {i18n.t('allowedAndSizeNotExceed500kb')}
            </div>
          </Col>
          <Col span={24}>
            <Form.Item name="link" label={i18n.t('linkURL')}>
              <Input placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="sort" label={i18n.t('sort')} rules={verify({ point: 0 })}>
              <InputNumber className="w-full" placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="status" label={i18n.t('status')} rules={[{ required: true }]}>
              <Select
                className="w-full"
                options={enumToOptions(ENABLE)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row align="middle" justify="center" gutter={[12, 12]}>
              <Col><Button onClick={onClose}>{i18n.t('cancel')}</Button></Col>
              <Col>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {i18n.t('confirm')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
export const PopupEditLicense = ({ isOpen, close, data, mutate }: {
  isOpen: boolean;
  close: () => void;
  data: {
    key: number;
    lang: string;
    sort: number;
    title: string;
    photo: string;
    url: string;
    status: number;
  }
  mutate: () => void;
}) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);

  useEffect(() => {
    if (data && isOpen) {
      form.setFieldsValue({
        title: data.title,
        link: data.url,
        sort: data.sort,
        status: data.status
      })
      setImageData([
        {
          uid: data.photo,
          name: data.photo,
          url: data.photo,
        },
      ])
    }
  }, [data, isOpen]);

  const onFinish = async (formData: any) => {
    setLoading(true);

    const response = await UpdateLicense({
      Id: data.key,
      Lang: data.lang,
      Title: formData.title,
      Photo_1: imageData ? imageData[0].url : data.photo,
      LinkURL: formData.link,
      Position: formData.sort,
      Status: formData.status
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('editSuccess'));
      onClose();
      mutate();
    } else {
      message.error(RESPONSE_CODE_LICENSE[response.Message as keyof typeof RESPONSE_CODE_LICENSE]);
    }

    setLoading(false);
  }

  const onClose = () => {
    form.resetFields();
    setImageData([]);
    close();
  }

  return (
    <Modal
      title={i18n.t('edit')}
      centered
      open={isOpen}
      onCancel={onClose}
      width={450}
      footer={false}
    >
      <Form layout="vertical" form={form} onFinish={onFinish} >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="title" label={i18n.t('title')}
              rules={[{ required: true, type: 'string', max: 100 }]}>
              <Input placeholder={`${i18n.t('oneHundredCharacters')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Photo_1" valuePropName="fileList" label={i18n.t('image')} rules={[{ required: !imageData[0] }]}>
              <UploadImage
                name="Photo_1"
                url={'/upload/images/public/contents'}
                imageData={imageData}
                setImageData={setImageData}
                form={form}
                crop
                w={140}
                h={50}
              />
            </Form.Item>
            <div className="color-03">{i18n.t('onlyJpgOrPngFiles')}
              <span className="require">{i18n.t('imageMobileVersionWidth140pxHeight50px')}</span>
              {i18n.t('allowedAndSizeNotExceed500kb')}
            </div>
          </Col>
          <Col span={24}>
            <Form.Item name="link" label={i18n.t('linkURL')}>
              <Input placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="sort" label={i18n.t('sort')} rules={verify({ point: 0 })}>
              <InputNumber className="w-full" placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="status" label={i18n.t('status')} rules={[{ required: true }]}>
              <Select
                className="w-full"
                options={enumToOptions(ENABLE)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row align="middle" justify="center" gutter={[12, 12]}>
              <Col><Button onClick={onClose}>{i18n.t('cancel')}</Button></Col>
              <Col>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {i18n.t('confirm')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
export const PopupDeleteLicense = ({ isOpen, close, id, mutate }: any) => {
  const icon = useIcon();
  const [loading, setLoading] = useState(false);

  const onDelete = async () => {
    setLoading(true);

    const response = await DeleteLicense({
      Id: id,
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('deleteSuccess'));
      close();
      mutate();
    } else {
      message.error(RESPONSE_CODE_LICENSE[response.Message as keyof typeof RESPONSE_CODE_LICENSE]);
    }

    setLoading(false);
  }

  const onClose = () => {
    close();
  };

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={icon.icon12} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button className="mt-1" onClick={onClose}>{i18n.t('cancel')}</Button>
          <Button className="mt-1" type="primary" onClick={onDelete} loading={loading}>
            {i18n.t('confirm')}
          </Button>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmDelete')}?</div>
      </Space>
    </Modal>
  )
}

// 有的
export const PopupDeleteAnnouncement = ({ isOpen, close, id, mutate }: any) => {
  const icon = useIcon();
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);

    const response = await PostContentsDelete({
      Id: id
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      onClose();
      mutate();
    } else {
      message.error(RESPONSE_CODE_CREATE_ANNOUNCEMENT[response.Message as keyof typeof RESPONSE_CODE_CREATE_ANNOUNCEMENT]);
    }

    setIsLoading(false);
  }

  const onClose = () => {
    close();
  }

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={icon.icon12} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button className="mt-1" key="cancel" onClick={onClose}>{i18n.t('cancel')}</Button>
          <Button className="mt-1" key="confirm" type="primary"
            onClick={handleDelete} loading={isLoading}>
            {i18n.t('confirm')}
          </Button>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmDelete')}?</div>
      </Space>
    </Modal>
  )
}

// 維護新增編輯
export const PopupMaintenance = ({ isOpen, close, data, mutate }: any) => {
  const [form] = useForm();
  const [date, setDate] = useState<any>();
  const { data: ProviderOptions, isValidating } = ProviderOpenList();
  const [isLoading, setIsLoading] = useState(false);
  const [defaultDate, setDefaultDate] = useState<Dayjs[]>([]);

  useEffect(() => {
    if (data && isOpen) {
      form.setFieldsValue({
        provider: Number(data.GameProviderId),
        dateRange: [data.date.start, data.date.end, '']
      })
      setDate([data.date.start, data.date.end]);
      setDefaultDate([dayjs(data.date.start), dayjs(data.date.end)]);
    }
  }, [data, isOpen])

  const onFinish = async (formData: any) => {
    setIsLoading(true);

    const send: any = {
      ProviderId: formData.provider,
      StartDate: date[0],
      EndDate: date[1]
    }
    let response: any = {}
    if (!data) {
      response = await PostProvidermaintaintenanceCreate(send)
    } else {
      response = await PostProvidermaintaintenanceUpdate({
        Id: data?.key,
        ProviderId: formData.provider,
        StartDate: date[0],
        EndDate: date[1]
      })
    }
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(!date ? i18n.t('addSuccess') : i18n.t('editSuccess'));
      onClose();
      mutate();
    } else {
      const msg = RESPONSE_CODE[response.Message as keyof typeof RESPONSE_CODE];
      message.error(msg || i18n.t('processingFailed'));
    }

    setIsLoading(false);
  }

  const onClose = () => {
    close();
    form.resetFields();
  }

  return (
    <Modal
      title={!data ? i18n.t('add') : i18n.t('edit')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={420}
      footer={false}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item name="provider" label={i18n.t('gameProvider')} rules={[{ required: true }]}>
          <Select
            loading={isValidating}
            placeholder={i18n.t('pleaseSelect')}
            options={
              ProviderOptions?.map((item: ProviderType) => (
                { value: item.Id, label: item.ProviderName }
              ))
            }
          />
        </Form.Item>
        <Form.Item name="dateRange" className="mt-1" label={i18n.t('maintenanceTime')} rules={[{ required: true }]}>
          <LayoutDateSelect defaultDate={defaultDate} onChange={setDate} hidden="dateRadio"
            dateFormatDefault={DATE_FORMAT.日期時間} />
        </Form.Item>
        <Row className="mt-1" justify="center" gutter={[12, 12]}>
          <Col>
            <Button onClick={onClose}>{i18n.t('cancel')}</Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
// 有的
export const PopupDeleteMaintenance = ({ isOpen, close, id, mutate }: any) => {
  const icon = useIcon();
  const [isLoading, setIsLoading] = useState(false);

  const onDelete = async () => {
    setIsLoading(true);

    const response = await PostProvidermaintaintenanceDelete({
      Id: id
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      onClose();
      mutate();
    } else {
      message.error(RESPONSE_CODE_MAINTENANCE[response.Message as keyof typeof RESPONSE_CODE_MAINTENANCE]);
    }

    setIsLoading(false);
  }

  const onClose = () => {
    close();
  }

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={icon.icon12} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button className="mt-1" onClick={onClose}>{i18n.t('cancel')}</Button>
          <Button className="mt-1" type="primary" onClick={onDelete}
            loading={isLoading}>{i18n.t('confirm')}</Button>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmDelete')}?</div>
      </Space>
    </Modal>
  )
}
