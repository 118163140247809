import i18n from "i18n";

// NOTE: 盡量集中 如果有不同語意就用繼承的 { ...RESPONSE_CODE, more }
export const RESPONSE_CODE = {
  unauthorized: i18n.t('notLoggedIn'),
  INCORRECT_ACCOUNT_OR_PASSWORD: `${i18n.t('incorrectUsernameOrPassword')}`,
  INCORRECT_ACCOUNT_FORMAT: i18n.t('incorrectAccountFormat'),
  INCORRECT_NAME: i18n.t('incorrectAccount'),
  INCORRECT_PASSWORD: i18n.t('incorrectUsernameOrPassword'),
  INCORRECT_PASSWORD_FORMAT: `${i18n.t('IncorrectPasswordFormat')}`,
  INCORRECT_PARAMS: i18n.t('incorrectRequest'),
  MORE_THAN_FILE_SIZE_MAX_LIMIT: i18n.t('uploadFailed'),

  NAME_ALEARDY_USE: i18n.t('duplicateName'),

  PERMISSION_DENIED: i18n.t('permissionDenied'),
  ACCOUNT_BLOCKED: i18n.t('accountDisabled'),
  UNAUTHORIZATION: i18n.t('notLoggedIn'),
};

export const RESPONSE_CODE_PERMISSION = {
  INCORRECT_ACCOUNT_FORMAT: i18n.t('incorrectAccountFormat'),
  INCORRECT_PARAMS: i18n.t('incorrectRequest'),
  INCORRECT_NAME: i18n.t('incorrectAccount'),
  DUPLICATE_ACCOUNT: i18n.t('duplicateAccount'),
  DUPLICATE_NAME: i18n.t('duplicateName'),
  INCORRECT_PASSWORD_FORMAT: i18n.t('incorrectPasswordFormat')
}

// NOTE: ONE NOTE 上面也是會漏 目前沒有統一文件
export const RESPONSE_CODE_AGENT = {
  INCORRECT_PARAMS: i18n.t('incorrectRequest'),
  INCORRECT_ACCOUNT_FORMAT: i18n.t('incorrectAccountFormat'),
  INCORRECT_PASSWORD_FORMAT: i18n.t('incorrectPasswordFormat'),
  INCORRECT_REPEAT_PASSWORD: i18n.t('incorrectPasswordFormat'),
  DUPLICATE_ACCOUNT: i18n.t('duplicateAccount'),
  INCORRECT_CONFIRM_PASSWORD: i18n.t('incorrectPasswordConfirmation'),
}

export const RESPONSE_CODE_ADJUST = {
  NOT_FIND_MEMBER: i18n.t('notFoundMember'),
  INCORRECT_ID: i18n.t('incorrectMemberAccount'),
  INCORRECT_PARAMS: i18n.t('incorrectRequest'),
  TICKET_NOT_EXISTS: i18n.t('notFoundOrder'),
  WALLET_NOT_EXISTS: i18n.t('notFoundWallet'),
}

export const RESPONSE_CODE_MEMBER = {
  SOURCE_ACCOUNT_NOT_FOUND: i18n.t('sourceAccountDoesNotExist'),
  INCORRECT_ID: i18n.t('incorrectMemberAccount'),
  INCORRECT_NAME: i18n.t('incorrectRealName'),
  INCORRECT_PARAMS: i18n.t('incorrectRequest'),
  INCORRECT_STATUS: i18n.t('incorrectStatus'),
  INCORRECT_ACCOUNT: i18n.t('incorrectAccount'),
  INCORRECT_DATE_FORMAT: i18n.t('incorrectDate'),
  ITEM_NOT_FOUND: i18n.t('notFound'),
  NOT_FIND_MEMBER: i18n.t('notFoundMember'),
  MEMBER_HAS_BEEN_KYC: i18n.t('memberHasBeenVerify'),
  MEMBER_HAS_NOT_BEEN_VERIFIED: i18n.t('memberHadNotVerify'),
  MEMBER_SUSPENDED: i18n.t('memberFrozen'),
  DUPLICATE_ACCOUNT: i18n.t('duplicateAccount'),
  INCORRECT_ACCOUNT_FORMAT: i18n.t('incorrectAccountFormat'),
  DUPLICATE_IDNumber: i18n.t('duplicateIdNumber'),
  DUPLICATE_Phone: i18n.t('duplicatePhone'),
  DUPLICATE_Email: i18n.t('duplicateEmail'),
}

export const RESPONSE_CODE_PROMOTION = {
  INCORRECT_NAME: '',
  DUPLICATE_ORDERING: i18n.t('levelNameCannotBeDuplicated'),
  DUPLICATE_NAME: i18n.t('levelNameCannotBeDuplicated'),
  INCORRECT_PARAMS: i18n.t('operationError'),
}

export const RESPONSE_CODE_BONUS = {
  BONUS_NOT_AVAILABLE: i18n.t('activityExpired'),
  RULE_FAIL: i18n.t('incorrectReferralBonusRule'),
  DUPLICATE_NAME: i18n.t('duplicatePromotionName'),
  INCORRECT_PARAMS: i18n.t('incorrectRequest'),
  INCORRECT_DATE_FORMAT: i18n.t('incorrectDate'),
  INCORRECT_NAME: i18n.t('incorrectPromotionName'),
  LEVEL_ALERADY_USE: i18n.t('cannotSetDuplicateLevels')
}

export const RESPONSE_CODE_BONUSTICKET_CREATE = {
  INCORRECT_PARAMS: i18n.t('incorrectRequest'),
  NOT_FIND_BONUS: i18n.t('notFindBonus'),
  BONUS_NOT_AVAILABLE: i18n.t('bonusNotAvailable'),
  TICKET_STATUS_ILLEGAL: i18n.t('orderOperationFailed')
}

export const RESPONSE_CODE_GAME = {
  INCORRECT_PARAMS: i18n.t('operationError'),
  INCORRECT_ID: i18n.t('incorrectGame'),
  INCORRECT_NAME: i18n.t('incorrectGameName')
}

export const RESPONSE_CODE_FINANCE = {
  INCORRECT_PARAMS: i18n.t('operationError'),
  INCORRECT_ID: ``,
}

export const RESPONSE_CODE_BANKCARD = {
  ACCOUNT_ALEARDY_USE: i18n.t('duplicateBankCard'),
  INCORRECT_PARAMS: i18n.t('operationError'),
  WITHDRAW_METHOD_PRE_APPROVED_BEFOURE_VERIFICATION: i18n.t('approvedBefoureVerification')
}

export const RESPONSE_CODE_MEMBER_GAME_SETTING = {
  INCORRECT_PARAMS: i18n.t('operationError'),
  INCORRECT_PARAMS1: i18n.t('operationError')
}

export const RESPONSE_CODE_GAME_DATA = {
  INCORRECT_PARAMS: i18n.t('operationError'),
}

export const RESPONSE_CODE_MEMBER_PROVIDER_STATUS = {
  INCORRECT_PARAMS: i18n.t('operationError'),
}

export const RESPONSE_CODE_SITE_SETTING = {
  INCORRECT_PARAMS: i18n.t('operationError'),
}

export const RESPONSE_CODE_AGENT_WITHDRAW = {
  TICKET_STATUS_ILLEGAL: i18n.t('orderStatusChanged'),
  ANOTHER_FINANCE_IS_HANDLING: i18n.t('orderCurrentlyLocked'),
  INCORRECT_PARAMS: i18n.t('operationError'),
  WITHDRAW_MERCHANT_EXECUTE_FAILED: i18n.t('withdrawalMerchantExecutionFailed'),
  WITHDRAW_PROCESS_GOT_ERROR: i18n.t('withdrawalMerchantExecutionError'),
  TICKET_NOT_FOUND: i18n.t('withdrawalOrderDoesNotExist'),
  MERCHANT_NOT_FOUND: i18n.t('withdrawalMerchantDoesNotExist'),
  WITHDRAW_METHOD_NOT_FOUND: i18n.t('withdrawalMethodDoesNotExist'),
  PAYMENT_MERCHANT_GOT_ERROR: i18n.t('withdrawalMethodDoesNotExist'),
}

export const RESPONSE_CODE_REBATE = {
  INCORRECT_PARAMS: i18n.t('operationError'),
  COMMISSION_TICKET_NOT_FIND: i18n.t('notFound'),

}

export const RESPONSE_CODE_CREATE_ANNOUNCEMENT = {
  INCORRECT_PARAMS: i18n.t('operationError'),
  INCORRECT_DATE_FORMAT: i18n.t('publicationTimeError')
}

export const RESPONSE_CODE_MAINTENANCE = {
  INCORRECT_PARAMS: i18n.t('operationError'),
  DUPLICATE_TIME_RANGE: i18n.t('gameProviderMaintenanceTimeCannotOverlap')
}

export const RESPONSE_CODE_WITHDRAW_UPDATE = {
  INCORRECT_PARAMS: i18n.t('operationError'),
}

export const RESPONSE_CODE_ADD_DEPOSIT = {
  INCORRECT_PARAMS: i18n.t('operationError'),
  INCORRECT_ID: i18n.t('processingFailed'),
}

export const RESPONSE_CODE_MERCHANT = {
  INCORRECT_PARAMS: i18n.t('operationError'),
}

export const RESPONSE_CODE_IP = {
  BLOCK_IP_NOT_FIND: i18n.t('thisIpIsUnBlocked'),
  INCORRECT_PARAMS: i18n.t('operationError'),
  DUPLICATE_IP: i18n.t('thisIpIsBlocked')
}

export const RESPONSE_CODE_TAG = {
  DUPLICATE_NAME: i18n.t('duplicateLabelName'),
  INCORRECT_PARAMS: i18n.t('operationError'),
}

export const RESPONSE_CODE_GAME_IP = {
  INCORRECT_PARAMS: i18n.t('operationError'),
}

export const RESPONSE_CODE_FRONT = {
  INCORRECT_PARAMS: i18n.t('operationError'),
}

export const RESPONSE_CODE_SEO = {
  INCORRECT_PARAMS: i18n.t('operationError'),
  TYPE_ALEARDY_USE: i18n.t('duplicatePageSEOSettings')
}

export const RESPONSE_CODE_CHANGELINE = {
  INCORRECT_PARAMS: i18n.t('operationError'),
  INCORRECT_ID: i18n.t(''),
  INCORRECT_ACCOUNT_FORMAT: i18n.t(''),
  DOWNLINE_AGENT_FORBIDDEN: i18n.t('cannotChangeToSubordinateAgency')
}

export const RESPONSE_CODE_LICENSE = {
  INCORRECT_PARAMS: i18n.t('operationError')
}

export const RESPONSE_CODE_CHAT = {
  ACCOUNT_ISONLINE_FALSE: i18n.t('customerServiceNotOnline'),
  INCORRECT_PARAMS: i18n.t('operationError'),
  DUPLICATE_NAME: i18n.t('duplicateCategoryName')
}

export const RESPONSE_CODE_MAIL = {
  NOT_FIND_MEMBER: i18n.t('memberNotFound')
}