import { Button, Col, Form, Row, Select, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { ViewGameList } from 'components/advanced.component';
import { LayoutNav, LayoutPagination } from 'components/layout.component';
import { GameCategorySelect } from 'components/member.component';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { GameOpenList } from 'services/game.service';
import { Provider } from 'services/provider.service';

// 遊戲管理

interface ProviderType {
  ProviderCode: string;
}

const PageMain: React.FC = () => {
  const [form] = useForm();
  const [isRefresh, setIsRefresh] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [params, setParams] = useState({});
  const { data: provider } = Provider();
  const { data: gameList, isValidating, mutate } = GameOpenList({
    ...params,
    PageIndex: page[0],
    PageSize: page[1]
  })

  useEffect(() => {
    if (provider) {
      form.setFieldsValue({
        gameProvider: null,
        gameCategory: null,
      })
    }
  }, [provider])

  const onFinish = (formData: any) => {
    setParams({
      ProviderCode: formData.gameProvider,
      CategoryCode: formData.gameCategory
    });
    setPage([1, page[1]]);
    mutate();
  };

  const onClear = () => {
    form.resetFields();
    setPage([1, 10]);
  }

  const onRefresh = () => {
    setIsRefresh(true);
    mutate();
    const delayRefresh = setInterval(() => {
      setIsRefresh(false);
      clearInterval(delayRefresh);
    }, 3000);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish}>
          <Row align="middle" gutter={12}>
            <Col>
              <Form.Item className="w-12" name="gameProvider">
                <Select
                  placeholder={i18n.t('gameProvider')}
                  // onChange={setProviderId}
                  options={
                    provider &&
                    [
                      { value: '', label: i18n.t('all') },
                      ...provider.map((item: ProviderType) => ({
                        value: item.ProviderCode,
                        label: item.ProviderCode
                      }))
                    ]
                  }
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item className="w-12" name="gameCategory">
                <GameCategorySelect name="gameCategory" form={form} code={true} />
              </Form.Item>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit" loading={isValidating}>
                {i18n.t('search')}
              </Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
            <Col className="mt-1" span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  <Button type="primary" onClick={onRefresh} loading={isRefresh}>{i18n.t('refreshGame')}</Button>
                </Col>
              </Row>
            </Col>
            <Col className="mt-1" span={24}>
              <Spin spinning={isValidating}>
                <ViewGameList data={gameList} mutate={mutate} />
                <LayoutPagination total={gameList && gameList.TotalRecord} page={page} setPage={setPage} />
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;