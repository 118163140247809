import { Col, Row, Switch, Table, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { LayoutNav, LayoutTabPlatform } from 'components/layout.component';
import { RESPONSE_CODE_GAME_DATA } from 'constants/response';
import { RESPONSE_TYPE } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import i18n from 'i18n';
import React from 'react';
import { ProviderOpenList, ProviderUpdateStatus } from 'services/provider.service';

// 站台設置 > 遊戲資料

interface ProviderInfoType {
  Id: number;
  CategoryId: number;
  CategoryCode: string;
  ProviderName: string;
  ProviderCode: string;
  Status: number;
  Account: string;
  UpdateDate: string;
}

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();
  const { data: ProviderInfo, mutate, isValidating } = ProviderOpenList();

  const handleStatus = async (value: boolean, id: number) => {
    const Status = value ? 1 : 0

    const response = await ProviderUpdateStatus({
      Id: id,
      Status
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      mutate();
    } else {
      message.error(RESPONSE_CODE_GAME_DATA[response.Message as keyof typeof RESPONSE_CODE_GAME_DATA]);
    }
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <LayoutTabPlatform activeKey="3" />
        <Row align="middle" gutter={[10, 16]}>
          <Col span={24}>
            <Table
              loading={isValidating}
              size="middle"
              scroll={{ x: 1500 }}
              dataSource={ProviderInfo?.map((item: ProviderInfoType) => ({ ...item, key: item.Id }))}
              columns={[
                {
                  key: 1,
                  dataIndex: 'ProviderCode',
                  title: i18n.t('gameProvider'),
                  className: 'size-12',
                  width: 250,
                  fixed: 'left'
                },
                {
                  key: 2,
                  dataIndex: 'CategoryCode',
                  title: i18n.t('gameCategory'),
                  className: 'size-12',
                  width: 250,
                  render: (val) => i18n.t(val)
                },
                {
                  key: 3,
                  dataIndex: 'Status',
                  title: i18n.t('gameStatus'),
                  className: 'size-12',
                  width: 350,
                  render: (val, { Id }) =>
                    <Switch defaultChecked={val} onChange={v => handleStatus(v, Id)} disabled={!$p.includes('00202')}/>
                },
                {
                  key: 4,
                  dataIndex: 'Account',
                  title: i18n.t('updater'),
                  className: 'size-12',
                  width: 300
                },
                {
                  key: 5,
                  dataIndex: 'UpdateDate',
                  title: i18n.t('updateTime'),
                  className: 'size-12',
                  width: 300
                },
              ]}
              pagination={false}
            />
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default PageMain;