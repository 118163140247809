import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row, Table, Tooltip, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { LayoutNav } from 'components/layout.component';
import { PopupDeleteMemberLevel } from 'components/promotion.component';
import { RESPONSE_CODE_PROMOTION } from 'constants/response';
import { RESPONSE_TYPE } from 'enum/state';
import usePerm from 'hooks/permission.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { CreateMemberlevel, GetMemberlevelList, ModifyMemberlevel } from 'services/memberlevel.service';
import { toFormatNumber as $f, verify } from 'utils/common';

// 會員等級設定

interface LevelInfoType {
  Id: number;
  Name: string;
  Position: number;
  MemberCountAtLevel: number;
  UPCDepositAmount: number;
  UPCValidBetAmount: number;
  LLMaxWithdrawalPerTrans: number;
  LLMaxWithdrawalPerDay: number;
  LUPROneceReward: number;
  LUPRWeekReward: number;
  LUPRMonthReward: number;
  LUPRRewardValidBetTimes: number;
}

interface EditingInfoType {
  Id?: number;
  Name?: string;
  Position?: number;
  MemberCountAtLevel?: number;
  UPCDepositAmount?: number;
  UPCValidBetAmount?: number;
  LLMaxWithdrawalPerTrans?: number;
  LLMaxWithdrawalPerDay?: number;
  LUPROneceReward?: number;
  LUPRWeekReward?: number;
  LUPRMonthReward?: number;
  LUPRRewardValidBetTimes?: number;
}

const PageMain: React.FC = () => {
  const { permissionCode: $p } = usePerm();
  const { isCashVersion: $sc } = useSite();
  
  const [form] = Form.useForm();
  const [activeId, setActiveId] = useState(0);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const [list, setList] = useState<any>([]);
  const { data: memberlevelList, mutate, isValidating } = GetMemberlevelList();
  useEffect(() => {
    if (memberlevelList) {
      setList(memberlevelList);
    }
  }, [memberlevelList]);

  const [editingInfo, setEditingInfo] = useState<EditingInfoType>({});
  const openEdit = (record: Partial<Memberlevel>) => {
    setEditingInfo({ Id: record.Id })
    form.setFieldsValue(record)
  };

  const openAdd = () => {
    const currentPosition = list[list.length - 1].Position;
    const newItem = {
      Name: '',
      Position: currentPosition + 1,
      UPCDepositAmount: 0,
      UPCValidBetAmount: 0,
      LLMaxWithdrawalPerTrans: 0,
      LLMaxWithdrawalPerDay: 0,
      LUPROneceReward: 0,
      LUPRWeekReward: 0,
      LUPRMonthReward: 0,
      LUPRRewardValidBetTimes: 0
    }
    setList([
      ...list,
      newItem
    ])
  }

  const save = async (formData: any) => {
    const currentPosition = list[list.length - 1].Position
    if (formData.Name.length > 10) return message.error(i18n.t('levelNameCannotExceed10Characters'));
    if (!editingInfo.Id) {
      const response = await CreateMemberlevel({
        ...formData,
        Position: currentPosition,
        Description: null,
      })
      if (response.State === RESPONSE_TYPE.成功) {
        message.success(i18n.t('addSuccess'));
        form.resetFields();
        mutate();
      } else {
        message.error(RESPONSE_CODE_PROMOTION[response.Message as keyof typeof RESPONSE_CODE_PROMOTION]);
      }
    } else {
      const apiResult = await ModifyMemberlevel(formData)
      if (apiResult.State === RESPONSE_TYPE.成功) {
        message.success(i18n.t('updateSuccess'));
        setEditingInfo({});
        form.resetFields();
        mutate();
      } else {
        message.error(RESPONSE_CODE_PROMOTION[apiResult.Message as keyof typeof RESPONSE_CODE_PROMOTION]);
      }
    }
  };

  const cancel = () => {
    if (!editingInfo.Id) {
      list.length = list.length - 1;
      setList([...list]);
    } else {
      setEditingInfo({});
    }
    form.resetFields();
  };

  const editMode = !list[list.length - 1]?.Id || !!editingInfo.Id

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2">
        <Row gutter={[16, 16]} className="pb-1">
          {
            $p.includes('30202') &&
            <Col>
              <Button type="primary" onClick={openAdd} disabled={editMode}>{i18n.t('add')}</Button>
            </Col>
          }
        </Row>
        <Row gutter={[16, 16]} className="pb-1">
          <Col className="w-full">
            <Form form={form} component={false} onFinish={save}>
              <Form.Item name="Id" className="display-none" />
              <Table
                loading={isValidating}
                size="small"
                bordered
                dataSource={
                  list.map((item: LevelInfoType) => (
                    {
                      ...item,
                      key: item.Position,
                      Position: item.Position,
                    }
                  ))
                }
                columns={[
                  {
                    key: 1,
                    dataIndex: 'Position',
                    width: 50,
                    align: 'center',
                    render: (val, { Position }) => Position + 1
                  },
                  {
                    key: 2,
                    dataIndex: 'Name',
                    title: i18n.t('levelName'),
                    width: 150,
                    render: (_: any, record: Memberlevel) => (
                      <>
                        {
                          record.Id === editingInfo.Id
                            ? <Form.Item name="Name" rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                              <Input placeholder={`${i18n.t('enter')}`} defaultValue={record.Name} />
                            </Form.Item>
                            : record.Name
                        }
                      </>
                    )
                  },
                  {
                    key: 3,
                    dataIndex: 'MemberCountAtLevel',
                    title: i18n.t('numberOfPeople'),
                    width: 100,
                    align: 'right',
                    render: (val) => $f(val, 0)
                  },
                  {
                    key: 4,
                    dataIndex: 'UPCDepositAmount',
                    title: i18n.t('depositAmountTotal'),
                    width: 200,
                    render: (_: any, record: Memberlevel) => (
                      <>
                        {
                          record.Id === editingInfo.Id
                            ? <Form.Item name="UPCDepositAmount" rules={[
                              ...verify({ point: 4 }),
                              {
                                validator: async (r, value) => {
                                  const pre = list.find((item: LevelInfoType) => item.Position === record.Position - 1);
                                  const nex = list.find((item: LevelInfoType) => item.Position === record.Position + 1) || null;
                                  if (record.Position === 0) return Promise.resolve();
                                  if (!$sc) return Promise.resolve();
                                  if (Number(value) > pre.UPCDepositAmount && (!nex || Number(value) < nex.UPCDepositAmount)) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(() => {
                                    if (nex !== null && (Number(value) > nex.UPCDepositAmount || Number(value) === nex.UPCDepositAmount)) {
                                      return (
                                        nex !== null
                                          ? <p style={{ margin: 0 }}>{`${i18n.t('mustBeLessThan')}${nex.UPCDepositAmount}`}</p>
                                          : <></>
                                      )
                                    } else if (Number(value) < pre.UPCDepositAmount || Number(value) === pre.UPCDepositAmount) {
                                      return <p style={{ margin: 0 }}>{`${i18n.t('mustBeGreaterThan')}${pre.UPCDepositAmount}`}</p>
                                    }
                                  });
                                }
                              }
                            ]}>
                              <Input placeholder={`${i18n.t('enter')}`} />
                            </Form.Item>
                            : $f(record.UPCDepositAmount)
                        }
                      </>
                    )
                  },
                  {
                    key: 5,
                    dataIndex: 'UPCValidBetAmount',
                    title: i18n.t('totalValidBets'),
                    width: 200,
                    render: (_: any, record: Memberlevel) => (
                      <>
                        {
                          record.Id === editingInfo.Id
                            ? <Form.Item name="UPCValidBetAmount" rules={[
                              ...verify({ point: 4 }),
                              {
                                validator: async (r, value) => {
                                  const pre = list.find((item: LevelInfoType) => item.Position === record.Position - 1);
                                  const nex = list.find((item: LevelInfoType) => item.Position === record.Position + 1) || null;
                                  if (record.Position === 0) return Promise.resolve();
                                  if (nex !== null) {
                                    if (Number(value) > pre.UPCValidBetAmount && Number(value) < nex.UPCValidBetAmount) {
                                      return Promise.resolve();
                                    }
                                  } else if (Number(value) > pre.UPCValidBetAmount) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(() => {
                                    if (nex !== null && (Number(value) > nex.UPCValidBetAmount || Number(value) === nex.UPCValidBetAmount)) {
                                      return (
                                        nex !== null
                                          ? <p style={{ margin: 0 }}>{`${i18n.t('mustBeLessThan')}${nex.UPCValidBetAmount}`}</p>
                                          : <></>
                                      )
                                    } else if (Number(value) < pre.UPCValidBetAmount || Number(value) === pre.UPCValidBetAmount) {
                                      return <p style={{ margin: 0 }}>{`${i18n.t('mustBeGreaterThan')}${pre.UPCValidBetAmount}`}</p>
                                    }
                                  });
                                }
                              }
                            ]}>
                              <Input placeholder={`${i18n.t('enter')}`} />
                            </Form.Item>
                            : $f(record.UPCValidBetAmount)
                        }
                      </>
                    )
                  },
                  {
                    key: 6,
                    dataIndex: 'LLMaxWithdrawalPerTrans',
                    title: i18n.t('singleWithdrawalLimit'),
                    width: 200,
                    render: (_: any, record: Memberlevel) => (
                      <>
                        {
                          record.Id === editingInfo.Id
                            ? <Form.Item name="LLMaxWithdrawalPerTrans" rules={[
                              ...verify({ zero: false })
                            ]}>
                              <Input placeholder={`${i18n.t('enter')}`} />
                            </Form.Item>
                            : $f(record.LLMaxWithdrawalPerTrans)
                        }
                      </>
                    )
                  },
                  {
                    key: 7,
                    dataIndex: 'LLMaxWithdrawalPerDay',
                    title: i18n.t('dailyAccumulatedWithdrawalLimit'),
                    width: 200,
                    render: (_: any, record: Memberlevel) => (
                      <>
                        {
                          record.Id === editingInfo.Id
                            ? <Form.Item name="LLMaxWithdrawalPerDay" rules={[
                              ...verify({ zero: false }),
                              {
                                validator: async (r, value) => {
                                  const pre = form.getFieldValue('LLMaxWithdrawalPerTrans')
                                  if (Number(value) >= pre) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(`${i18n.t('mustBeGreaterThanOrEqualTo')}${pre}`);
                                }
                              }
                            ]}>
                              <Input placeholder={`${i18n.t('enter')}`} />
                            </Form.Item>
                            : $f(record.LLMaxWithdrawalPerDay)
                        }
                      </>
                    )
                  },
                  {
                    key: 8,
                    dataIndex: 'LUPROneceReward',
                    width: 120,
                    title: () => (
                      <Tooltip placement="top" className="size-12" title={i18n.t('automaticDistributionWhenConditionsMet')}>
                        <Row align="middle" gutter={3}>
                          <Col className="size-12">{i18n.t('promotionBonus')}</Col>
                          <Col><InfoCircleFilled /></Col>
                        </Row>
                      </Tooltip>
                    ),
                    render: (_: any, record: Memberlevel) => (
                      <>
                        {
                          record.Id === editingInfo.Id
                            ? <Form.Item name="LUPROneceReward" rules={verify({ point: 4 })}>
                              <Input placeholder={`${i18n.t('enter')}`} />
                            </Form.Item>
                            : $f(record.LUPROneceReward)
                        }
                      </>
                    )
                  },
                  {
                    key: 9,
                    dataIndex: 'LUPRWeekReward',
                    width: 120,
                    title: () => (
                      <Tooltip placement="top" className="size-12" title={i18n.t('automaticDistributionWhenConditionsMet')}>
                        <Row align="middle" gutter={3}>
                          <Col className="size-12">{i18n.t('weeklyBonus')}</Col>
                          <Col><InfoCircleFilled /></Col>
                        </Row>
                      </Tooltip>
                    ),
                    render: (_: any, record: Memberlevel) => (
                      <>
                        {
                          record.Id === editingInfo.Id
                            ? <Form.Item name="LUPRWeekReward" rules={verify({ point: 4 })}>
                              <Input placeholder={`${i18n.t('enter')}`} />
                            </Form.Item>
                            : $f(record.LUPRWeekReward)
                        }
                      </>
                    )
                  },
                  {
                    key: 10,
                    dataIndex: 'LUPRMonthReward',
                    width: 120,
                    title: () => (
                      <Tooltip placement="top" className="size-12" title={i18n.t('automaticDistributionWhenConditionsMet')}>
                        <Row align="middle" gutter={3}>
                          <Col className="size-12">{i18n.t('monthlyBonus')}</Col>
                          <Col><InfoCircleFilled /></Col>
                        </Row>
                      </Tooltip>
                    ),
                    render: (_: any, record: Memberlevel) => (
                      <>
                        {
                          record.Id === editingInfo.Id
                            ? <Form.Item name="LUPRMonthReward" rules={verify({ point: 4 })}>
                              <Input placeholder={`${i18n.t('enter')}`} />
                            </Form.Item>
                            : $f(record.LUPRMonthReward)
                        }
                      </>
                    )
                  },
                  {
                    key: 11,
                    width: 120,
                    dataIndex: 'LUPRRewardValidBetTimes',
                    title: i18n.t('rolloverRate'),
                    render: (_: any, record: Memberlevel) => (
                      <>
                        {
                          record.Id === editingInfo.Id
                            ? <Form.Item name="LUPRRewardValidBetTimes" rules={verify({ point: 0 })}>
                              <Input placeholder={`${i18n.t('enter')}`} />
                            </Form.Item>
                            : record.LUPRRewardValidBetTimes
                        }
                      </>
                    )
                  },
                  {
                    key: 12,
                    dataIndex: 'operation',
                    width: 150,
                    title: () => (
                      <Tooltip placement="top" className="size-12" title={i18n.t('deleteMemberLevelMembersWillBeDowngradedToMinimumLevel')}>
                        <Row align="middle" gutter={3}>
                          <Col className="size-12">{i18n.t('operation')}</Col>
                          <Col><InfoCircleFilled /></Col>
                        </Row>
                      </Tooltip>
                    ),
                    render: (_: any, record: Memberlevel, index: number) => {
                      return record.Id === editingInfo.Id ? (
                        // 編輯中
                        <span>
                          <Button type="link" onClick={cancel}>{i18n.t('cancel')}</Button>
                          <Button type="link" onClick={form.submit}>{i18n.t('confirm')}</Button>
                          <Divider type="vertical" />
                          {
                            index !== 0 && !!record.Id &&
                            <Button type="link" onClick={() => {
                              setActiveId(record.Id);
                              setIsOpenDelete(true);
                            }}>{i18n.t('delete')}</Button>
                          }
                        </span>
                      ) : (
                        <>
                          {
                            $p.includes('30203') &&
                            <Button type="link" disabled={editMode}
                              onClick={() => openEdit(record)}>{i18n.t('edit')}</Button>
                          }
                          {
                            index !== 0 && (
                              $p.includes('30204') &&
                              <Button type="link" onClick={() => {
                                setActiveId(record.Id);
                                setIsOpenDelete(true);
                              }}>{i18n.t('delete')}</Button>
                            )
                          }
                        </>
                      );
                    },
                  },
                ]}
                pagination={false}
              />
            </Form>
          </Col>
        </Row>
      </Content>
      <PopupDeleteMemberLevel isOpen={isOpenDelete} close={() => setIsOpenDelete(false)}
        id={activeId} mutate={mutate} setEditingInfo={setEditingInfo} />
    </div >
  );
};

export default PageMain;