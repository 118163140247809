import {
  axiosGetAllData,
  axiosGetAuth,
  axiosPostAuth,
  baseURL,
  useFetchDataCommon
} from "services";

export const GetPermissionList = (params: any, allow?: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/permission/list`, params, allow);
export const GetPermission = () => useFetchDataCommon(axiosGetAuth, `${baseURL}/chatsetting/permission/list`);
export const GetPermissionOne = (params: any, allow?: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/permission/one`, params, allow);

export const GetPermissionOperationList = (params: any, allow?: boolean) => useFetchDataCommon(axiosGetAllData, `${baseURL}/permission/operation/list`, params, allow);
export const PostPermissionCreate = (data: any) => axiosPostAuth(`${baseURL}/permission/create`, data);
export const PostPermissionUpdate = (data: any) => axiosPostAuth(`${baseURL}/permission/update`, data);
