import { Row } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { ViewBank, ViewVirtualCurrency } from 'components/bank.component';
import { LayoutNav, LayoutTabMember } from 'components/layout.component';
import React from 'react';
import { useParams } from 'react-router-dom';
import { FetchMemberDetail } from 'services/member.service';

const PageMain: React.FC = () => {

  const { id } = useParams();
  const { data: member } = FetchMemberDetail({ Id: id }, !!id);

  return (
    <div id="container">
      <LayoutNav id={member ? member.Id : 0} account={member ? member.Account : ''} />
      <Content className="pr-2 pl-2 pb-2">
        <LayoutTabMember activeKey="4" id={member ? member.Id : 0} />
        <Row>
          <ViewBank id={member?.Id} />
          <ViewVirtualCurrency id={member?.Id} />
        </Row>
      </Content>
    </div>
  );
};

export default PageMain;