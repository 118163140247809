import {
  axiosGetAllData,
  axiosPostAuth,
  baseURL,
  useFetchDataCommon
} from "services";

export const CommissionticketList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/commissionticket/list`, params);
export const CommissionticketPass = (params: any) => axiosPostAuth(`${baseURL}/commissionticket/pass`, params);
export const CommissionticketReject = (params: any) => axiosPostAuth(`${baseURL}/commissionticket/reject`, params);
export const GetCommissionticketSaDateList = (params: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/commissionticket/sa/date/list`, params);
export const GetCommissionticketSaList = (params: any, allow?: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/commissionticket/sa/list`, params, allow);
export const GetRevenueticketSaList = (params: any, allow?: any) => useFetchDataCommon(axiosGetAllData, `${baseURL}/revenueticket/sa/list`, params, allow);

export const PostCommissionticketSaReceive = (data: any) => axiosPostAuth(`${baseURL}/commissionticket/sa/receive`, data);
export const PostRevenueticketSaReceive = (data: any) => axiosPostAuth(`${baseURL}/revenueticket/sa/receive`, data);

export const PostCommissionticketPass = (data: any) => axiosPostAuth(`${baseURL}/commissionticket/pass`, data);
export const PostRevenueticketPass= (data: any) => axiosPostAuth(`${baseURL}/revenueticket/pass`, data);
