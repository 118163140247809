import { Button, Col, DatePicker, Form, Image, Input, Modal, Row, Select, Space, Table, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { RESPONSE_CODE_BONUS, RESPONSE_CODE_BONUSTICKET_CREATE, RESPONSE_CODE_PROMOTION, RESPONSE_CODE_REBATE } from "constants/response";
import dayjs from "dayjs";
import { PROMOTION_CATEGORY, PROMOTION_STATE } from "enum/promotion";
import { BONUS_TICKET_TYPE, DISCOUNT_TRANSFER_TYPE, DISCOUNT_TYPE, RESPONSE_TYPE } from "enum/state";
import useIcon from "hooks/icon.hook";
import usePerm from "hooks/permission.hook";
import i18n from "i18n";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BonusOption, DeleteBonus } from "services/bonus.service";
import { AdjustBonusTicket, BonusTicketOne, CreateBonusTicket } from "services/bonusticket.service";
import { CommissionticketPass } from "services/commissionticket.service";
import { DeleteMemberlevel } from "services/memberlevel.service";
import * as common from 'utils/common';
import { verify } from 'utils/common';
import { MemberLevelTableDisplay, ProviderTableDisplay } from "./layout.component";

interface PromotionData {
  Id: number;
  Name: string;
  Status: number;
  StartDate: string;
  EndDate: string;
  Category: number;
  Type: number;
  IsOneTime: number;
  MemberLevelName: string[];
  ProviderName: string[];
  Position: number;
  ApplyMethod: number;
  SettleMethod: number;
  ValidBetTimes: number;
  Remark: string;
  Photo: string;
  Period: number;
  CreateDate: string;
  LevelsId: string[];
}

export enum PROMOTION_CYCLE {
  每日 = 1,
  每週 = 2
}

// 優惠管理列表
export const ViewPromotionList = ({ data, mutate }: {
  data: {
    State: string;
    Data: PromotionData[];
    TotalRecord: number;
  };
  mutate: () => void;
}) => {
  const { permissionCode: $p } = usePerm();
  const navigate = useNavigate();
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [id, setId] = useState(0);

  const handleDelete = (id: number) => {
    setIsOpenDelete(true);
    setId(id);
  }

  const handleEditOther = (id: number, name: string) => {
    navigate(`${'/promotion/edit-other/'}${id}`);
  }

  return (
    <>
      <Table
        size="middle"
        scroll={{ x: 1500 }}
        dataSource={data?.Data?.map((item: PromotionData) => ({ key: item.Id, ...item }))}
        columns={[
          {
            dataIndex: 'Position',
            title: i18n.t('frontSort'),
            align: 'center',
            fixed: 'left',
            width: 80
          },
          {
            dataIndex: 'Name',
            title: i18n.t('promotionName'),
            fixed: 'left',
            width: 350
          },
          {
            dataIndex: 'Category',
            title: i18n.t('category'),
            width: 130,
            render: (val) => PROMOTION_CATEGORY[val]
          },
          {
            dataIndex: 'Period',
            title: i18n.t('cycle'),
            width: 130,
            render: (val) => val ? PROMOTION_CYCLE[val] : '-'
          },
          {
            dataIndex: 'IsOneTime',
            title: i18n.t('oneTimePromotion'),
            width: 130,
            render: (val) => val ? i18n.t('no') : i18n.t('yes')
          },
          {
            dataIndex: 'ProviderName',
            title: i18n.t('applicableGameProvider'),
            width: 400,
            render: (val) => <ProviderTableDisplay providerName={val} />
          },
          {
            dataIndex: 'MemberLevelName',
            title: i18n.t('applicableMemberLevel'),
            width: 200,
            render: (val) => <MemberLevelTableDisplay levelName={val} />
          },
          {
            dataIndex: 'Status',
            title: i18n.t('status'),
            width: 100,
            render: (val) => PROMOTION_STATE[val]
          },
          {
            dataIndex: 'StartDate',
            title: i18n.t('promotionStartTimePromotionEndTime'),
            width: 150,
            render: (val, { StartDate, EndDate }) => <>{`${StartDate} - ${EndDate}`}</>
          },
          {
            dataIndex: 'CreateDate',
            title: i18n.t('createTime'),
            width: 150
          },
          {
            title: i18n.t('operation'),
            fixed: 'right',
            width: 100,
            render: (_, record) => (
              <>
                {
                  $p.includes('30103') &&
                  <Button className="size-12" type="link" onClick={() => {
                    switch (record.Category) {
                      case PROMOTION_CATEGORY.其他優惠:
                        return handleEditOther(record.key, record.Name);
                      case PROMOTION_CATEGORY.推薦獎勵:
                        return navigate('/promotion/bonus');
                      case PROMOTION_CATEGORY.每日簽到:
                        return navigate(`/promotion/edit/mission/${record.key}`);
                    }
                  }}>
                    {i18n.t('edit')}
                  </Button>
                }
                {
                  (record.Category !== PROMOTION_CATEGORY.推薦獎勵 && $p.includes('30104')) &&
                  <Button className="size-12" type="link" onClick={() => handleDelete(record.key)}>
                    {i18n.t('delete')}
                  </Button>
                }
              </>
            ),
          },
        ]}
        pagination={false}
      />
      <PopupDelete isOpen={isOpenDelete} close={() => setIsOpenDelete(false)} id={id} mutate={mutate} />
    </>
  )
}

interface DiscountType {
  Id: number;
  Code: string;
  Account: string;
  LevelName?: string;
  UpMemberAccount?: string;
  Category: number;
  Type: number;
  AGAccount: string;
  SAAccount: string;
  TxnType: number;
  Name: string;
  Amount: number;
  ValidBetTimes: number;
  Period: number;
  IsOneTime: number;
  ProviderName: string[];
  MemberLevelName: number[];
  LevelsId: string[];
  CreateDate: string;
  AuditedTime: string;
  Remark: string;
  Status: number;
}

interface PromotionType {
  Id: number,
  Name: string
}

// 優惠查詢總計
export const ViewDiscountOverview = ({ data }: {
  data: {
    TotalRecord: number;
    Type1_TotalAmount: number;
    Type2_TotalAmount: number;
    Type3_TotalAmount: number;
  };
}) => {

  return (
    <>
      <Table
        size="middle"
        dataSource={
          data &&
          [{
            key: 1,
            count: data.TotalRecord,
            totalPromotions: data.Type3_TotalAmount,
            totalAddedPromotions: data.Type1_TotalAmount,
            totalDeductedPromotions: data.Type2_TotalAmount
          }]
        }
        columns={[
          {
            title: i18n.t('count'),
            dataIndex: 'count',
            align: 'right',
            width: '25%',
            render: (_, { count }) => count ? count : 0
          },
          {
            title: i18n.t('totalPromotions'),
            dataIndex: 'totalPromotions',
            align: 'right',
            width: '25%',
            render: (_, { totalPromotions }) => common.toFormatNumber(totalPromotions)
          },
          {
            title: i18n.t('totalAddedPromotions'),
            dataIndex: 'totalAddedPromotions',
            align: 'right',
            width: '25%',
            render: (_, { totalAddedPromotions }) => common.toFormatNumber(totalAddedPromotions)
          },
          {
            title: i18n.t('totalDeductedPromotions'),
            dataIndex: 'totalDeductedPromotions',
            align: 'right',
            width: '25%',
            render: (_, { totalDeductedPromotions }) => common.toFormatNumber(totalDeductedPromotions)
          },
        ]}
        pagination={false}
      />
    </>
  )
}
// 優惠查詢列表
export const ViewDiscount = ({ data, mutate, setSelectedRowKeys, selectedRowKeys, hideColumns }: {
  data: {
    State: string;
    Data: DiscountType[];
    TotalRecord: number;
  };
  mutate: () => void;
  setSelectedRowKeys: any;
  selectedRowKeys: React.Key[];
  hideColumns: {
    amount: boolean;
    applicableGameProvider: boolean;
    applicableMemberLevel: boolean;
    auditTime: boolean;
    createTime: boolean;
    cycle: boolean;
    memberAccount: boolean;
    memberLevel: boolean;
    oneTimePromotion: boolean;
    order: boolean;
    promotionName: boolean;
    promotionType: boolean;
    recommendedMembers: boolean;
    remark: boolean;
    rolloverRate: boolean;
    status: boolean;
    transactionType: boolean;
    operation: boolean;
  }
}) => {
  const { permissionCode: $p } = usePerm();
  const [isModalOpenDetail, setIsModalOpenDetail] = useState(false);
  const [isModalOpenConfirm, setIsModalOpenConfirm] = useState(false);
  const [order, setOrder] = useState<number>(0);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleStatusColor = (type: number) => {
    switch (type) {
      case DISCOUNT_TYPE.已派發:
      case DISCOUNT_TYPE.已領取:
        return 'color-pass'
      case DISCOUNT_TYPE.待審核:
        return 'color-padding'
      case DISCOUNT_TYPE.已拒絕:
        return 'color-reject'
    }
  }

  const handleDetail = (id: number) => {
    setOrder(id);
    setIsModalOpenDetail(true);
  }

  const handleAdjust = (order: number) => {
    setIsModalOpenConfirm(true);
    setOrder(order);
  }

  return (
    <>
      <Table
        size="middle"
        className="mt-1"
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange,
        }}
        scroll={{ x: 1500 }}
        dataSource={data?.Data?.map(item => ({ key: item.Id, ...item }))}
        columns={[
          {
            key: 0,
            dataIndex: 'Code',
            title: i18n.t('order'),
            className: hideColumns.order ? '' : 'hide',
            fixed: 'left',
            width: 250,
          },
          {
            key: 0,
            dataIndex: 'Account',
            title: i18n.t('memberAccount'),
            className: hideColumns.memberAccount ? '' : 'hide',
            width: 160
          },
          {
            key: 0,
            dataIndex: 'LevelName',
            title: i18n.t('memberLevel'),
            className: hideColumns.memberLevel ? '' : 'hide',
            width: 100
          },
          {
            key: 0,
            dataIndex: 'UpMemberAccount',
            title: `${i18n.t('recommendedMembers')}／${i18n.t('agent')}／${i18n.t('generalAgent')}`,
            className: hideColumns.recommendedMembers ? '' : 'hide',
            width: 250,
            render: (val, record) => `${val || '-'}／${record.AGAccount}／${record.SAAccount}`
          },
          {
            key: 0,
            dataIndex: 'Category',
            title: i18n.t('promotionType'),
            className: hideColumns.promotionType ? '' : 'hide',
            width: 130,
            render: (val) => DISCOUNT_TRANSFER_TYPE[val]
          },
          {
            key: 0,
            dataIndex: 'TxnType',
            title: i18n.t('transactionType'),
            className: hideColumns.transactionType ? '' : 'hide',
            width: 130,
            render: (val) => BONUS_TICKET_TYPE[val]
          },
          {
            key: 0,
            dataIndex: 'Name',
            title: i18n.t(i18n.t('promotionName')),
            className: hideColumns.promotionName ? '' : 'hide',
            width: 300,
            render: (val, { Category, Type }) => (
              <>
                {
                  Category === DISCOUNT_TRANSFER_TYPE.每日簽到 && Type === 2
                    ? `${val}（${i18n.t('totalSignIn')}）` : val
                }
              </>
            )
          },
          {
            key: 0,
            dataIndex: 'Amount',
            title: i18n.t('amount'),
            className: hideColumns.amount ? '' : 'hide',
            width: 200,
            align: 'right',
            render: (val) => common.toFormatNumber(val)
          },
          {
            key: 0,
            dataIndex: 'ValidBetTimes',
            title: i18n.t('rolloverRate'),
            className: hideColumns.rolloverRate ? '' : 'hide',
            align: 'right',
            width: 80,
          },
          {
            key: 0,
            dataIndex: 'Period',
            title: i18n.t('cycle'),
            className: hideColumns.cycle ? '' : 'hide',
            align: 'center',
            width: 150,
            render: (val) => val ? PROMOTION_CYCLE[val] : '-'
          },
          {
            key: 0,
            dataIndex: 'IsOneTime',
            title: i18n.t('oneTimePromotion'),
            className: hideColumns.oneTimePromotion ? '' : 'hide',
            width: 100,
            render: (val) => val ? i18n.t('yes') : i18n.t('no')
          },
          {
            key: 0,
            dataIndex: 'ProviderName',
            title: i18n.t('applicableGameProvider'),
            className: hideColumns.applicableGameProvider ? '' : 'hide',
            width: 400,
            render: (val) => <ProviderTableDisplay providerName={val} />
          },
          {
            key: 0,
            dataIndex: 'MemberLevelName',
            title: i18n.t('applicableMemberLevel'),
            className: hideColumns.applicableMemberLevel ? '' : 'hide',
            width: 200,
            render: (val) => <MemberLevelTableDisplay levelName={val} />
          },
          {
            key: 0,
            dataIndex: 'CreateDate',
            title: i18n.t('createTime'),
            className: hideColumns.createTime ? '' : 'hide',
            width: 160,
          },
          {
            key: 0,
            dataIndex: 'AuditedTime',
            title: i18n.t('auditTime'),
            className: hideColumns.auditTime ? '' : 'hide',
            width: 160,
          },
          {
            key: 0,
            dataIndex: 'Remark',
            title: i18n.t('remark'),
            className: hideColumns.remark ? '' : 'hide',
            width: 160
          },
          {
            key: 0,
            dataIndex: 'Status',
            title: i18n.t('status'),
            className: hideColumns.status ? '' : 'hide',
            fixed: 'right',
            width: 100,
            render: (val) => <div className={handleStatusColor(val)}>{DISCOUNT_TYPE[val]}</div>
          },
          {
            key: 0,
            title: i18n.t('operation'),
            fixed: 'right',
            className: hideColumns.operation ? '' : 'hide',
            width: 100,
            render: (_, record: any) => (
              <>
                {
                  (
                    record.Status === DISCOUNT_TYPE.待審核 && $p.includes('30303')
                  ) &&
                  <Button className="size-12" type="link"
                    onClick={() => handleAdjust(record.key)}>{i18n.t('audit')}</Button>
                }
                {
                  (
                    record.Status === DISCOUNT_TYPE.已派發 ||
                    record.Status === DISCOUNT_TYPE.已領取 ||
                    record.Status === DISCOUNT_TYPE.已拒絕
                  ) &&
                  <Button className="size-12" type="link"
                    onClick={() => handleDetail(record.key)}>{i18n.t('detail')}</Button>
                }
                {/* <Button className="size-12" type="link" onClick={showModalUnlock}>{i18n.t('unlock')}</Button> */}
              </>
            ),
          },
        ]}
        pagination={false}
      />

      <PopupAdjustDiscount isOpen={isModalOpenConfirm} close={() => setIsModalOpenConfirm(false)} mutate={mutate} id={order} />
      <PopupDiscountDetail isOpen={isModalOpenDetail} close={() => setIsModalOpenDetail(false)} mutate={mutate} id={order} />
    </>
  )
}
// 新增審核詳細
export const PopupAddDiscount = ({ isOpen, close, mutate }: any) => {
  const [form] = useForm();
  const { data: BonusOptions, isValidating: BonusLoading } = BonusOption({
    Status: 1
  });

  const onFinish = async (formData: any) => {
    const members = formData.memberAccount.split(',');

    const response = await CreateBonusTicket({
      BonusId: Number(formData.promotionName),
      MemberAccount: [...members],
      TxnType: formData.transactionType,
      Amount: Number(formData.amount),
      ValidBetAmount: Number(formData.rolloverRate),
      Remark: formData.backOfficeRemarks
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('addSuccess'));
      mutate();
      onClose();
    } else {
      const errorData = response.Data;
      message.error(
        <>
          {
            errorData
              ? <Row>
                <Col span={24}>{i18n.t('notFoundAccount')}</Col>
                {
                  errorData &&
                  errorData.map((error: string, i: number) => {
                    const account = error.split(" ")[0];
                    return (
                      <Col span={24} key={i}>{account}</Col>
                    )
                  })
                }
              </Row>
              : RESPONSE_CODE_BONUS[response.Message as keyof typeof RESPONSE_CODE_BONUS]
          }
        </>
      );
    }

  }

  const onClose = () => {
    form.resetFields();
    close();
  }

  return (
    <Modal title={i18n.t('add')}
      open={isOpen}
      centered={true}
      onCancel={onClose}
      footer={false}
      width={500}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[16, 16]} className="pb-1">
          <Col span={24}>
            <Form.Item className="w-full" name="promotionName" label={i18n.t('promotionName')}
              rules={[{ required: true }]}>
              <Select
                loading={BonusLoading}
                showSearch
                placeholder={i18n.t('pleaseSelect')}
                options={
                  BonusOptions
                    ? BonusOptions.map((option: PromotionType) => (
                      { value: `${option.Id}`, label: option.Name }
                    ))
                    : [{ value: '', label: i18n.t('currentlyNoOngoingPromotions') }]
                }
              />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={[16, 16]} className="pb-1">
          <Col span={24}>
            <Form.Item className="w-full" name="payee"
              label={i18n.t('memberAccount')} required rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('pleaseSeparateWithCommasUpTo20Entries')}`} />
            </Form.Item>
          </Col>
        </Row> */}
        <Row gutter={[16, 16]} className="pb-1">
          <Col span={24}>
            <Form.Item name="memberAccount" label={i18n.t('memberAccount')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('pleaseUseCommaToSeparateTheItems')} ${i18n.t('upTo20Entries')}`} />
            </Form.Item>
          </Col>
        </Row>
        {/* {
          addresseeType === MESSAGE_ADDRESSEE.會員等級 &&
          <Row gutter={[16, 16]} className="pb-1">
            <Col span={24}>
              <Form.Item className="w-full" name="memberLevel" label={i18n.t('memberLevel')}
                rules={[{ required: true }]}>
                <Select
                  placeholder={i18n.t('pleaseSelect')}
                  options={[]}
                />
              </Form.Item>
            </Col>
          </Row>
        } */}
        <Row gutter={[16, 16]} className="pb-1">
          <Col span={8}>
            <Form.Item className="w-full" name="transactionType" label={i18n.t('transactionType')}
              rules={[{ required: true }]}>
              <Select
                placeholder={i18n.t('pleaseSelect')}
                options={[
                  { value: 1, label: `${i18n.t('dddPromotion')}` },
                  { value: 2, label: `${i18n.t('deductPromotion')}` },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="amount" label={i18n.t('amount')} rules={common.verify({ point: 4 })}>
              <Input placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="rolloverRate" label={i18n.t('rolloverRate')} rules={common.verify({ point: 0 })}>
              <Input placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="pb-1">
          <Col span={24}>
            <Form.Item name="backOfficeRemarks" label={i18n.t('remark')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mt-1" justify="center" gutter={[12, 12]}>
          <Col><Button onClick={onClose}>{i18n.t('cancel')}</Button></Col>
          <Col>
            <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
export const PopupAdjustDiscount = ({ isOpen, close, mutate, id }: any) => {
  const [form] = useForm();
  const { data, mutate: refresh } = BonusTicketOne({
    BonusTicketId: id
  }, !!id);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        promotionName: data.Name,
        // payee:data
        memberAccount: data.MemberAccount,
        transactionType: data.TxnType,
        amount: data.Amount,
        rolloverRate: data.ValidBetTimes,
        backOfficeRemarks: data.Remark
      })
    }
  }, [data]);

  const handlePass = async () => {
    const remark = form.getFieldValue('backOfficeRemarks');
    if (!remark) return;

    const response = await AdjustBonusTicket({
      BonusTicketId: [data.Id],
      Status: 3,
      Remark: remark
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('pass'));
      refresh();
      mutate();
      onClose();
    } else {
      message.error(RESPONSE_CODE_BONUSTICKET_CREATE[response.Message as keyof typeof RESPONSE_CODE_BONUSTICKET_CREATE]);
    }
  }

  const handleReject = async () => {
    const remark = form.getFieldValue('backOfficeRemarks');
    if (!remark) return;

    const response = await AdjustBonusTicket({
      BonusTicketId: [data.Id],
      Status: 2,
      Remark: remark
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('reject'));
      refresh();
      mutate();
      onClose();
    } else {
      message.error(RESPONSE_CODE_BONUSTICKET_CREATE[response.Message as keyof typeof RESPONSE_CODE_BONUSTICKET_CREATE]);
    }
  }

  const onClose = () => {
    close();
  }

  return (
    <Modal title={i18n.t('audit')}
      open={isOpen}
      onCancel={onClose}
      centered={true}
      footer={false}
      width={500}
    >
      <Form form={form} layout="vertical">
        <Row gutter={[16, 16]} className="pb-1">
          <Col span={24}>
            <Form.Item className="w-full" name="promotionName" label={i18n.t('promotionName')} required>
              <Select disabled options={[]} />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={[16, 16]} className="pb-1">
          <Col span={24}>
            <Form.Item className="w-full" name="payee"
              label={i18n.t('payee')} required>
              <Select
                disabled
                options={[
                  { value: '1', label: `${i18n.t('specificMember')}` },
                ]}
              />
            </Form.Item>
          </Col>
        </Row> */}
        <Row gutter={[16, 16]} className="pb-1">
          <Col span={24}>
            <Form.Item name="memberAccount" label={i18n.t('memberAccount')} required>
              <Input placeholder={`${i18n.t('memberAccount')}`} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="pb-1">
          <Col span={8}>
            <Form.Item className="w-full" name="transactionType" label={i18n.t('transactionType')} required>
              <Select
                disabled
                options={[
                  { value: '', label: `${i18n.t('all')}` },
                  ...common.enumToOptions(BONUS_TICKET_TYPE)
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="amount" label={i18n.t('amount')} required>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="rolloverRate" label={i18n.t('rolloverRate')} rules={verify({ point: 0 })}>
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="pb-1">
          <Col span={24}>
            <Form.Item name="backOfficeRemarks" label={i18n.t('remark')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('enter')}`} />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mt-1" justify="center" gutter={[12, 12]}>
          <Col>
            <Button type="primary" htmlType="submit" danger onClick={handleReject}>
              {i18n.t('reject')}
            </Button>
          </Col>
          <Col><Button type="primary" htmlType="submit" onClick={handlePass}>{i18n.t('pass')}</Button></Col>
        </Row>
      </Form>
    </Modal>
  )
}
export const PopupDiscountDetail = ({ isOpen, close, id }: any) => {
  const [form] = useForm();
  const { data, mutate } = BonusTicketOne({
    BonusTicketId: id
  }, !!id)

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        promotionName:
          data.Category === DISCOUNT_TRANSFER_TYPE.每日簽到 && data.Type === 2
            ? `${data.Name}（${i18n.t('totalSignIn')}）` : data.Name,
        // payee:data
        memberAccount: data.MemberAccount,
        transactionType: data.TxnType,
        amount: data.Amount,
        rolloverRate: data.ValidBetTimes,
        backOfficeRemarks: data.Remark
      })
    }
  }, [data]);

  const onClose = () => {
    close();
  }

  return (
    <Modal title={i18n.t('detail')}
      open={isOpen}
      onCancel={onClose}
      centered={true}
      footer={false}
      width={500}
    >
      <Form form={form} layout="vertical">
        <Row gutter={[16, 16]} className="pb-1">
          <Col span={24}>
            <Form.Item className="w-full" name="promotionName" label={i18n.t('promotionName')} required>
              <Select disabled options={[]} />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={[16, 16]} className="pb-1">
          <Col span={24}>
            <Form.Item className="w-full" name="payee"
              label={i18n.t('payee')} required>
              <Select
                disabled
                options={[
                  { value: '1', label: `${i18n.t('specificMember')}` },
                ]}
              />
            </Form.Item>
          </Col>
        </Row> */}
        <Row gutter={[16, 16]} className="pb-1">
          <Col span={24}>
            <Form.Item name="memberAccount" label={i18n.t('memberAccount')} required>
              <Input placeholder={`${i18n.t('memberAccount')}`} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="pb-1">
          <Col span={8}>
            <Form.Item className="w-full" name="transactionType"
              label={i18n.t('transactionType')} required>
              <Select
                disabled
                options={[
                  { value: '', label: `${i18n.t('all')}` },
                  ...common.enumToOptions(BONUS_TICKET_TYPE)
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="amount" label={i18n.t('amount')} required>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="rolloverRate" label={i18n.t('rolloverRate')} rules={verify({ point: 0 })}>
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="pb-1">
          <Col span={24}>
            <Form.Item name="backOfficeRemarks" label={i18n.t('remark')} required
              rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('enter')}`} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mt-1" justify="center">
          <Button type="primary" onClick={onClose}>{i18n.t('close')}</Button>
        </Row>
      </Form>
    </Modal>
  )
}

// 有的
export const PopupDelete = ({ isOpen, close, id, mutate }: any) => {
  const icon = useIcon();
  const handleDelete = async () => {

    const response = await DeleteBonus({ Id: id });
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('deleteSuccess'));
      close();
      mutate();
    } else {
      message.error(RESPONSE_CODE_BONUS[response.Message as keyof typeof RESPONSE_CODE_BONUS]);
    }

  }

  return (
    <Modal
      centered
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={icon.icon12} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={close}
      width={450}
      footer={
        <Row justify="center">
          <Button className="mt-1" key="cancel" onClick={close}>{i18n.t('cancel')}</Button>
          <Button className="mt-1" key="confirm" type="primary" onClick={handleDelete}>{i18n.t('confirm')}</Button>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmDelete')}？</div>
      </Space>
    </Modal>
  )
}

// 等級優惠 新增審核詳細
export const PopupAddPromotionVip = ({ isOpen, close }: any) => {
  return (
    <Modal title={i18n.t('add')}
      open={isOpen}
      onCancel={close}
      centered={true}
      footer={[
        <Row align="middle" justify="center">
          <Col>
            <Button onClick={close}>{i18n.t('cancel')}</Button>
            <Button type="primary">{i18n.t('confirm')}</Button>
          </Col>
        </Row>
      ]}
      width={600}
    >
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={24}>
          <Row gutter={[12, 12]} justify="space-between">
            <Col span={24}>
              <span className="require">*</span>{i18n.t('payee')}
              <Select
                className="w-full"
                placeholder={i18n.t('pleaseSelect')}
                options={[
                  { value: '1', label: `${i18n.t('specificMember')}` },
                  { value: '2', label: `${i18n.t('allMembers')}` },
                  { value: '3', label: `${i18n.t('memberLevel')}` },
                ]}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={8}>
          <span className="require">*</span>{i18n.t('transactionType')}
          <Select
            className="w-full"
            placeholder={i18n.t('pleaseSelect')}
            options={[
              { value: '1', label: `${i18n.t('dddPromotion')}` },
              { value: '2', label: `${i18n.t('deductPromotion')}` },
            ]}
          />
        </Col>
        <Col span={8}>
          <span className="require">*</span>{i18n.t('amount')}
          <Input placeholder={`${i18n.t('enter')}`} />
        </Col>
        <Col span={8}>
          <span className="require">*</span>{i18n.t('rolloverRate')}
          <Input placeholder={`${i18n.t('enter')}`} />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={24}>
          {i18n.t('backOfficeRemarks')}
          <Input placeholder={`${i18n.t('enter')}`} />
        </Col>
      </Row>
    </Modal>
  )
}
export const PopupAuditPromotionVip = ({ isOpen, close }: any) => {
  return (

    <Modal title={i18n.t('audit')}
      open={isOpen}
      onCancel={close}
      centered={true}
      footer={[
        <>
          <Button onClick={close}>{i18n.t('cancel')}</Button>
          <Button type="primary">{i18n.t('confirm')}</Button>
        </>
      ]}
      width={600}
    >
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={24}>
          <span className="require">*</span>{i18n.t('payee')}
          <Select
            className="w-full"
            placeholder="1"
            disabled
            options={[
              { value: '1', label: `${i18n.t('specificMember')}` },
            ]}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={24}>
          <span className="require">*</span>{i18n.t('memberAccount')}
          <Input placeholder={`${i18n.t('memberAccount')}`} disabled />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={8}>
          <span className="require">*</span>{i18n.t('transactionType')}
          <Select
            className="w-full"
            placeholder="1"
            disabled
            options={[
              { value: '1', label: `${i18n.t('deductPromotion')}` },
            ]}
          />
        </Col>
        <Col span={8}>
          <span className="require">*</span>{i18n.t('amount')}
          <Input placeholder={`${i18n.t('1,000.0000')}`} disabled />
        </Col>
        <Col span={8}>
          <span className="require">*</span>{i18n.t('requiresContinuousSignIn')}
          <Input placeholder={`${i18n.t('enter')}`} disabled />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={24}>
          {i18n.t('backOfficeRemarks')}
          <Input placeholder={`${i18n.t('enter')}`} />
        </Col>
      </Row>
    </Modal>
  )
}
export const PopupDetailPromotionVip = ({ isOpen, close }: any) => {
  return (

    <Modal title={i18n.t('detail')}
      open={isOpen}
      onCancel={close}
      centered={true}
      footer={[
        <>
          <Button type="primary" onClick={close}>{i18n.t('close')}</Button>
        </>
      ]}
      width={600}
    >
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={24}>
          <span className="require">*</span>{i18n.t('payee')}
          <Select
            className="w-full"
            options={[
              { value: '1', label: `${i18n.t('specificMember')}` },
            ]}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={24}>
          <span className="require">*</span>{i18n.t('memberAccount')}
          <Input placeholder={`${i18n.t('memberAccount')}`} disabled />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={8}>
          <span className="require">*</span>{i18n.t('transactionType')}
          <Select
            className="w-full"
            disabled
            options={[
              { value: '1', label: `${i18n.t('deductPromotion')}` },
            ]}
          />
        </Col>
        <Col span={8}>
          <span className="require">*</span>{i18n.t('amount')}
          <Input placeholder={`${i18n.t('1,000.0000')}`} disabled />
        </Col>
        <Col span={8}>
          <span className="require">*</span>{i18n.t('requiresContinuousSignIn')}
          <Input placeholder={`${i18n.t('enter')}`} disabled />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={24}>
          {i18n.t('backOfficeRemarks')}
          <Input placeholder={`${i18n.t('enter')}`} disabled />
        </Col>
      </Row>
    </Modal>
  )
}

// 返水優惠 全部派發
export const PopupAllpassPromotionRebate = ({ isOpen, close, mutate, setAllPass, setSelectedRowKeys }: {
  isOpen: boolean;
  close: () => void;
  mutate: () => void;
  setAllPass: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedRowKeys: any;
}) => {
  const [form] = useForm();
  const [date, setDate] = useState('');

  useEffect(() => {
    setDate(dayjs().format('YYYY-MM-DD'));
  }, [])

  const handleAllPass = async (formData: any) => {
    setAllPass(true);

    const response = await CommissionticketPass({
      Role: 0,
      StartDate: `${date} ${dayjs().startOf('date').format('HH:mm:ss')}`,
      EndDate: `${date} ${dayjs().endOf('date').format('HH:mm:ss')}`,
    })
    if (response.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('operationSuccess'));
      onClose();
      setSelectedRowKeys([]);
      mutate();
    } else {
      message.error(RESPONSE_CODE_REBATE[response.Message as keyof typeof RESPONSE_CODE_REBATE]);
    }

    setAllPass(false);
  }

  const onClose = () => {
    form.resetFields();
    close();
  };

  return (
    <Modal title={i18n.t('distributeAll')}
      open={isOpen}
      centered={true}
      onCancel={onClose}
      footer={false}
      width={400}
    >
      <Form form={form} onFinish={handleAllPass} initialValues={{
        date: dayjs()
      }}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <div>
              <span className="require">*</span>
              <span className="color-03">{i18n.t('distributionTimeOnlyAllowsSelectingOneDay')}</span>
            </div>
            <div>
              <span className="require">*</span>
              <span className="color-03">
                {i18n.t('rejectedDistributedMembersWillNotReceiveCashbackAndWillNotBeDisplayedOnTheMemberEnd')}
              </span>
            </div>
          </Col>
          <Col span={24}>
            <Form.Item name="date" rules={[{ required: true, message: `${i18n.t('pleaseSelect')}` }]}>
              <DatePicker className="w-full" onChange={(date, dateString) => setDate(dateString)} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row align="middle" justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">{i18n.t('distributeAll')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
// 未使用
export const PopupAddPromotionRebate = ({ isOpen, close }: any) => {
  return (

    <Modal title={i18n.t('add')}
      open={isOpen}
      centered={true}
      onCancel={close}
      footer={[
        <Row align="middle" justify="center">
          <Col>
            <Button onClick={close}>{i18n.t('cancel')}</Button>
            <Button type="primary">{i18n.t('confirm')}</Button>
          </Col>
        </Row>
      ]}
      width={600}
    >
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={24}>
          <Row gutter={[12, 12]} justify="space-between">
            <Col span={8}>
              <span className="require">*</span>{i18n.t('payee')}
              <Select
                className="w-full"
                placeholder={i18n.t('pleaseSelect')}
                options={[
                  { value: '1', label: `${i18n.t('specificMember')}` },
                  { value: '2', label: `${i18n.t('allMembers')}` },
                  { value: '3', label: `${i18n.t('memberLevel')}` },
                ]}
              />
            </Col>
            <Col span={8}>
              <span className="require">*</span>{i18n.t('gameProvider')}
              <Select
                className="w-full"
                placeholder={i18n.t('pleaseSelect')}
                options={[
                  { value: '1', label: 'CQ9' },
                  { value: '2', label: 'UnltedGaming' },
                  { value: '3', label: 'Evolution' },
                  { value: '4', label: 'DG' },
                  { value: '5', label: 'SA Gaming' },
                ]}
              />
            </Col>
            <Col>
              <span className="require">*</span>{i18n.t('gameCategory')}
              {/* <Select
                className='w-full'
                placeholder={i18n.t("gameCategory")}
                options={[
                  { value: "all", label: i18n.t('all') },
                ]}
              /> */}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={8}>
          <span className="require">*</span>{i18n.t('transactionType')}
          <Select
            className="w-full"
            placeholder={i18n.t('pleaseSelect')}
            options={[
              { value: '1', label: `${i18n.t('dddPromotion')}` },
              { value: '2', label: `${i18n.t('deductPromotion')}` },
            ]}
          />
        </Col>
        <Col span={8}>
          <span className="require">*</span>{i18n.t('amount')}
          <Input placeholder={`${i18n.t('enter')}`} />
        </Col>
        <Col span={8}>
          <span className="require">*</span>{i18n.t('rolloverRate')}
          <Input placeholder={`${i18n.t('enter')}`} />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={24}>
          {i18n.t('backOfficeRemarks')}
          <Input placeholder={`${i18n.t('enter')}`} />
        </Col>
      </Row>
    </Modal>
  )
}
// 未使用
export const PopupAuditPromotionRebate = ({ isOpen, close }: any) => {
  return (

    <Modal title={i18n.t('audit')}
      open={isOpen}
      onCancel={close}
      centered={true}
      footer={[
        <>
          <Button onClick={close}>{i18n.t('cancel')}</Button>
          <Button type="primary">{i18n.t('confirm')}</Button>
        </>
      ]}
      width={800}
    >
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={24}>
          <span className="require">*</span>{i18n.t('payee')}
          <Select
            className="w-full"
            placeholder="1"
            disabled
            options={[
              { value: '1', label: `${i18n.t('specificMember')}` },
            ]}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={24}>
          <span className="require">*</span>{i18n.t('memberAccount')}
          <Input placeholder={`${i18n.t('memberAccount')}`} disabled />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={8}>
          <span className="require">*</span>{i18n.t('transactionType')}
          <Select
            className="w-full"
            placeholder={i18n.t('deductPromotion')}
            disabled
            options={[
              { value: '1', label: `${i18n.t('deductPromotion')}` },
            ]}
          />
        </Col>
        <Col span={8}>
          <span className="require">*</span>{i18n.t('amount')}
          <Input placeholder={`${i18n.t('1,000.0000')}`} disabled />
        </Col>
        <Col span={8}>
          <span className="require">*</span>{i18n.t('requiresContinuousSignIn')}
          <Input placeholder={`${i18n.t('enter')}`} disabled />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={24}>
          {i18n.t('backOfficeRemarks')}
          <Input placeholder={`${i18n.t('enter')}`} />
        </Col>
      </Row>
    </Modal>
  )
}
// 未使用
export const PopupDetailPromotionRebate = ({ isOpen, close }: any) => {
  return (

    <Modal title={i18n.t('detail')}
      open={isOpen}
      onCancel={close}
      centered={true}
      footer={[
        <Row align="middle" justify="center">
          <Button type="primary" onClick={close}>{i18n.t('close')}</Button>
        </Row>
      ]}
      width={600}
    >
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={24}>
          <Row gutter={[12, 12]} justify="space-between">
            <Col span={8}>
              <span className="require">*</span>{i18n.t('payee')}
              <Select
                className="w-full"
                placeholder={i18n.t('pleaseSelect')}
                options={[
                  { value: '1', label: `${i18n.t('specificMember')}` },
                  { value: '2', label: `${i18n.t('allMembers')}` },
                  { value: '3', label: `${i18n.t('memberLevel')}` },
                ]}
              />
            </Col>
            <Col span={8}>
              <span className="require">*</span>{i18n.t('gameProvider')}
              <Select
                className="w-full"
                placeholder={i18n.t('pleaseSelect')}
                options={[
                  { value: '1', label: 'CQ9' },
                  { value: '2', label: 'UnltedGaming' },
                  { value: '3', label: 'Evolution' },
                  { value: '4', label: 'DG' },
                  { value: '5', label: 'SA Gaming' },
                ]}
              />
            </Col>
            <Col>
              <span className="require">*</span>{i18n.t('gameCategory')}
              {/* <Select
                className='w-full'
                placeholder={i18n.t("gameCategory")}
                options={[
                  { value: "all", label: i18n.t('all') },
                ]}
              /> */}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={24}>
          <span className="require">*</span>{i18n.t('memberAccount')}
          <Input placeholder={`${i18n.t('enter')}`} />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={8}>
          <span className="require">*</span>{i18n.t('transactionType')}
          <Select
            className="w-full"
            placeholder={i18n.t('pleaseSelect')}
            options={[
              { value: '1', label: `${i18n.t('dddPromotion')}` },
              { value: '2', label: `${i18n.t('deductPromotion')}` },
            ]}
          />
        </Col>
        <Col span={8}>
          <span className="require">*</span>{i18n.t('amount')}
          <Input placeholder={`${i18n.t('enter')}`} />
        </Col>
        <Col span={8}>
          <span className="require">*</span>{i18n.t('rolloverRate')}
          <Input placeholder={`${i18n.t('enter')}`} />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={24}>
          {i18n.t('backOfficeRemarks')}
          <Input placeholder={`${i18n.t('enter')}`} />
        </Col>
      </Row>
    </Modal>
  )
}

// 有的
export const PopupDeleteMemberLevel = ({ isOpen, close, id, mutate, setEditingInfo }: any) => {
  const icon = useIcon();

  const onDelete = async () => {
    const apiResult: ApiResult = await DeleteMemberlevel({
      Id: id,
    })
    if (apiResult.State === RESPONSE_TYPE.成功) {
      message.success(i18n.t('deleteSuccess'));
      mutate();
    } else {
      message.error(RESPONSE_CODE_PROMOTION[apiResult.Message as keyof typeof RESPONSE_CODE_PROMOTION]);
    }
    close();
    setEditingInfo({});
  };

  return (
    <Modal
      centered
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={icon.icon12} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={close}
      width={450}
      footer={
        <Row justify="center">
          <Button className="mt-1" key="cancel" onClick={close}>{i18n.t('cancel')}</Button>
          <Button className="mt-1" key="confirm" type="primary" onClick={onDelete}>{i18n.t('confirm')}</Button>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmDelete')}？</div>
      </Space>
    </Modal>
  )
}

