export enum DATE_TYPE {
  今天 = 0,
  昨天 = 1,
  本週 = 2,
  上週 = 3,
  本月 = 4,
  上月 = 5,
}
export enum REPORT_DATE_TYPE {
  今天 = 1,
  昨天 = 2,
  本週 = 3,
  上週 = 4,
}

export enum DATE_FORMAT {
  日期 = "YYYY-MM-DD",
  時間 = "HH:mm:ss",
  日期時間 = "YYYY-MM-DD HH:mm:ss"
}
