// API
export enum RESPONSE_TYPE {
  成功 = "Success",
  失敗 = "Fail",
  錯誤 = "Error",
}

// 狀態
export enum MEMBER_STATE {
  pendingApproval = 0,
  pass = 1,
}
export enum MEMBER_FREEZE {
  normal = 2,
  frozen = 3,
}
export enum AGENT_STATE {
  pendingApproval = 0,
  normal = 1,
  frozen = 2,
}

export enum STATE {
  通過 = 1,
  拒絕 = 2,
  待審核 = 3,
  處理中 = 4
}
export enum VERIFICATION_STATE {
  待審核 = 0,
  通過 = 1,
  拒絕 = 2,
}
export enum AGENT_ADJUST_STATE {
  待審核 = 0,
  通過 = 1,
  拒絕 = 2,
  失敗 = 3,
}

// 出入款
export enum WITHDRAW_STATE {
  待客服審核 = 0,
  待主管審核 = 1,
  待財務審核 = 2,
  財務審核中 = 9,
  處理中 = 3,
  支付端等待支付 = 4,
  通過 = 5,
  失敗 = 6,
  拒絕 = -2,
}
export enum DEPOSIT_STATE {
  待財務審核 = 0,
  通過 = 1,
  拒絕 = 7,
  處理中 = 2,
  付款中 = 3,
  成功 = 5,
  失敗 = 6,
}
export enum PERMISSION_FINANCE {
  客服通過 = 1,
  客服拒絕 = 2,
  主管通過 = 3,
  主管拒絕 = 4,
  財務通過 = 5,
  財務拒絕 = 6,
}

export enum SEARCH_DATE_TYPE {
  建立時間 = 0,
  審核時間 = 1
}

export enum MEMBER_BANK_STATE {
  驗證失敗 = -1,
  待驗證 = 0,
  "驗證成功-開啟" = 1,
  "驗證成功-關閉" = 2,
  刪除 = 9
}
export enum WITHDRAW_STATE_TYPE {
  待審核 = 0,
  驗證成功開啟 = 1,
  驗證成功關閉 = 2,
  拒絕 = -1,
}

export enum MESSAGE_STATE {
  已讀取 = 1,
  未讀取 = 0,
}

export enum IP_STATE {
  高危險 = 8,
  警示 = 7,
  安全 = 1,
}

export enum REPORT_STATE {
  未結算 = 0,
  已結算 = 1,
  未結算撤單 = -1,
  已結算撤單 = 2,
}

export enum FRONT_COPY_TYPE {
  常見問題 = 1,
  理性博彩 = 2,
  服務條款 = 3,
  '我的推廣-推廣教程' = 4,
  '我的推廣-獎勵規則' = 6,
  隱私政策 = 5,
}

// 優惠
export enum DISCOUNT_TRANSFER_TYPE {
  其他優惠 = 99,
  推薦獎勵 = 1,
  每日簽到 = 2,
}
export enum BONUS_TICKET_TYPE {
  添加優惠 = 1,
  扣除優惠 = 2,
  系統派發 = 3
}

// 調帳
export enum ADJUST_TRANSFER_TYPE {
  添加餘額 = 1,
  扣除餘額 = -1
}

export enum ENABLE {
  停用 = 0,
  啟用 = 1
}
export enum PERMISSION_STATE {
  啟用 = 0,
  停用 = 1,
}
export enum VIRTUAL_STATE {
  啟用 = 1,
  停用 = 2
}
export enum TRANSFER_SETTING_STATUS {
  開啟 = 1,
  關閉 = 0,
  刪除 = -1,
}

export enum PROMOTION_VIP_STATE {
  未派發 = 0,
  已派發 = 1,
  已領取 = 2
}
export enum PROMOTION_REBATE_STATE {
  待派發 = 0,
  拒絕 = 2,
  已派發 = 3,
  已領取 = 4
}
export enum DISCOUNT_TYPE {
  待審核 = 0,
  審核中 = 1,
  已拒絕 = 2,
  已派發 = 3,
  已領取 = 4,
}
export enum AGENT_WITHDRAW_STATE {
  待派發 = 0,
  派發中 = 1,
  批次通過 = 3,
}

export enum TRANSFER_TYPE {
  入款 = 0,
  出款 = 1
}

export enum GATEWAY {
  銀行卡 = 1,
  虛擬貨幣 = 2
}
export enum WITHDRAW_TYPE {
  三方 = "WITHDRAW_GATE_BANK",
  線下 = "WITHDRAW_BANK_OFFLINE"
}
export enum WITHDRAW {
  三方銀行卡 = "WITHDRAW_GATE_BANK",
  線下銀行卡 = "WITHDRAW_GATE_BANK_OFFLINE",
  三方虛擬貨幣 = "WITHDRAW_GATE_CRYPTO",
  線下虛擬貨幣 = "WITHDRAW_GATE_CRYPTO_OFFLINE"
}
export enum DEPOSIT {
  三方銀行 = "DEPOSIT_GATE_BANK",
  三方超商 = "DEPOSIT_GATE_CVS",
  三方ATM = "DEPOSIT_GATE_ATM",
  線下銀行 = "DEPOSIT_GATE_BANK_OFFLINE",
  線下虛擬 = "DEPOSIT_GATE_CRYPTO_OFFLINE"
}
export enum MEMBER_WITHDRAW {
  銀行卡 = "WITHDRAW_GATE_BANK",
  虛擬貨幣 = "WITHDRAW_GATE_CRYPTO",
}

export enum MODE {
  新增 = 1,
  編輯 = 2,
  詳情 = 3,
  審核 = 4,
}

export enum CHECKBOX_DISPLAY {
  空 = 0,
  方塊 = 2,
  打勾 = 3,
}

export enum SOURCE_ROLE {
  會員 = 0,
  代理 = 1,
  系統 = 2,
}

export enum CONTACT_INFO {
  Line,
  微信,
  QQ,
  Zalo,
  Messenger,
  Whatsapp,
  Telegram,
}

export enum SEO_PAGE {
  首頁 = 0,
  優惠活動 = 1
}

export enum SLIDE_TYPE {
  電腦版 = 1,
  手機版 = 2,
  // APP版 = 3,
}
