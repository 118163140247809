import i18n from "i18n";
import React from "react";
import { useNavigate } from 'react-router-dom';

const PageMain: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        background: "#00c5b4",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ color: "#f1f8fe", width: "100%" }}>
          <p>{i18n.t("failedToRetrievePageData")}</p>
          <br />
          <div className="py-2"></div>
          <button
            className="button "
            style={{ width: "100%" }}
            type="button"
            onClick={() => navigate(-1)}
          >
            {i18n.t("goBack")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PageMain;
